/* eslint-disable no-param-reassign */
import { CountryCodes } from 'enums/country';
import { IAddress } from 'interfaces/common';

export const googleAutocompleteObject = (
  x: any,
  address: IAddress,
  country: string
) => {
  if (country === CountryCodes.USA) {
    if (x.types[0] === 'street_number' || x.types[0] === 'route') {
      address.addressLine1 += `${x.long_name} `;
    }
    if (x.types[0] === 'locality') {
      address.city = `${x.long_name}`;
    }
    if (x.types[0] === 'administrative_area_level_1') {
      address.state = `${x.short_name}`;
    }
    if (x.types[0] === 'postal_code') {
      address.zip = `${x.long_name}`;
    }

    return address;
  }

  if (country === CountryCodes.CANADA) {
    if (x.types[0] === 'street_number' || x.types[0] === 'route') {
      address.addressLine1 += `${x.long_name} `;
    }
    if (x.types[0] === 'locality') {
      address.city = `${x.long_name}`;
    }
    if (x.types[0] === 'administrative_area_level_1') {
      address.state = `${x.short_name}`;
    }
    if (x.types[0] === 'postal_code') {
      address.zip = `${x.long_name}`;
    }

    return address;
  }

  return {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    lat: '',
    lng: '',
  };
};
