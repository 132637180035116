import { Box, Link, SxProps, Typography, useTheme } from '@mui/material';
import { FormCheckbox } from 'common';
import commonConstants from 'constants/common';
import paymentConstants from 'constants/payment';
import { PaymentMethod } from 'enums/Payment';
import { useAppSelector } from 'stores/hooks';
import { selectGatewayDetail } from 'stores/Payment';

const { PRIVACY_POLICY, TERMS_AND_CONDITION } = commonConstants;

interface IProps {
  wrapperSx?: SxProps;
  paymentMethod: string;
}

const AgreeTerms = ({ wrapperSx, paymentMethod }: IProps) => {
  const theme = useTheme();
  const gatewayDetail = useAppSelector(selectGatewayDetail);

  const gatewayMetadata = gatewayDetail?.gatewayType
    ? paymentConstants.gatewayMetadata[gatewayDetail.gatewayType]
    : null;

  const isNotPoweredByDisplay =
    paymentMethod !== PaymentMethod.CASH &&
    paymentMethod !== PaymentMethod.CHECK &&
    paymentMethod !== PaymentMethod.OTHERS;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...wrapperSx,
      }}
    >
      <FormCheckbox
        data-cy="clients-agree-terms"
        label={
          <Typography variant="body1">
            I agree with{' '}
            <Link href={TERMS_AND_CONDITION} target="_blank" variant="body1">
              terms{' '}
            </Link>{' '}
            and{' '}
            <Link href={PRIVACY_POLICY} target="_blank" variant="body1">
              policy
            </Link>
          </Typography>
        }
        name="agreeTerms"
      />

      {gatewayMetadata?.image &&
        gatewayMetadata?.link &&
        isNotPoweredByDisplay && (
          <Box
            component="a"
            href={gatewayMetadata.link}
            sx={{
              display: 'flex',
              alignItems: 'center',
              ...theme.typography.body2,
              textDecoration: 'none',
              color: theme.palette.text.secondary,
            }}
            target="_blank"
          >
            Powered by
            <Box
              alt="payment-gateway"
              component="img"
              height={gatewayMetadata.imageHeight}
              ml={1}
              src={gatewayMetadata.image}
            />
          </Box>
        )}
    </Box>
  );
};

AgreeTerms.defaultProps = {
  wrapperSx: null,
};

export default AgreeTerms;
