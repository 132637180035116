import React, { ReactNode } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { AddressDisplay } from 'common';
import { IReferralCaseProviderOption } from 'interfaces/cases';
import { formatPhone } from 'utils/common';

import { mapOldAddressImplementation } from '../common';
import ProviderOptionMenu from './ProviderOptionMenu';

interface IMobileProviderOptionProps {
  data: IReferralCaseProviderOption;
}
interface IRowOptionsProps {
  label: string;
  value: string | ReactNode;
}

const RowOptions = ({ label, value }: IRowOptionsProps) => (
  <Stack direction="row" spacing={2}>
    <Typography
      sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}
      width="120px"
    >
      {label}
    </Typography>
    <Typography>{value || 'N/A'}</Typography>
  </Stack>
);

const MobileProviderOption = ({ data }: IMobileProviderOptionProps) => {
  const address = data.address && mapOldAddressImplementation(data.address);

  return (
    <Box alignItems="flex-start" display="flex" justifyContent="space-between">
      <Stack>
        <RowOptions label="Provider Name" value={data?.name} />
        <RowOptions
          label="Address"
          value={<AddressDisplay address={address} />}
        />
        <RowOptions
          label="Contact"
          value={
            <Box component="li" sx={{ listStyle: 'none' }}>
              {!data?.email && !data?.phone && 'N/A'}
              {data?.email && (
                <Box className="text-overflow-ellipses" component="div">
                  <Typography>{data.email}</Typography>
                </Box>
              )}
              {data?.phone && (
                <Box className="text-overflow-ellipses" component="div">
                  <Typography>{formatPhone(data.phone)}</Typography>
                </Box>
              )}
            </Box>
          }
        />

        <RowOptions label="Cost" value={data?.cost} />
      </Stack>
      <Box>
        <ProviderOptionMenu data={data} />
      </Box>
    </Box>
  );
};

export default MobileProviderOption;
