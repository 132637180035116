import React from 'react';

import UserType from 'enums/User';
import { IChangeMembershipSelectedOfferings } from 'interfaces/client-management/manage-membership';
import { useUpdateClientEnrollmentMutation } from 'services/client-management/enrollment';
import {
  resetManageMembershipSelectedOfferings,
  setManageMembershipSelectedOfferings,
} from 'stores/client-management/manage-membership';
import { useAppDispatch } from 'stores/hooks';
import { formatUpdateManageMembership } from 'utils/client-management/enrollment';

import { useGetCurrentSelectedClientAndMembershipDetails } from './useGetCurrentSelectedClientAndMembershipDetails';

export const useHandleInlineDateChange = ({
  setDate,
  setIsEditing,
}: {
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setDate: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const dispatch = useAppDispatch();

  const updateClientEnrollmentMutation = useUpdateClientEnrollmentMutation();

  const {
    membership,
    tenantGroupCode,
    clientType,
    tenantId,
    clientId,
    clientEnrollmentId,
  } = useGetCurrentSelectedClientAndMembershipDetails();

  const handleDate = (value: Partial<IChangeMembershipSelectedOfferings>) => {
    dispatch(setManageMembershipSelectedOfferings(value));
    setDate(value.startDate || value.endDate || '');
  };

  const clearEndDate = () => {
    dispatch(setManageMembershipSelectedOfferings({ endDate: null }));
  };

  const handleCancel = () => {
    dispatch(resetManageMembershipSelectedOfferings());
    setIsEditing(false);
  };

  const handleSave = ({
    startDate,
    endDate,
    status,
  }: {
    startDate?: string;
    endDate: string;
    status: string;
  }) => {
    const payload = formatUpdateManageMembership({
      offering: {
        name: membership?.offerings.name,
        tenantOfferingId: membership?.offerings.id,
        unformattedPrice: membership?.offerings.cost,
        billingInterval: membership?.offerings.billingInterval,
        billingType: membership?.offerings.billingType,
        unformattedRegistrationFee: membership?.offerings.registrationFee,
        hoursPurchased: membership?.offerings.hoursPurchased,
      } as any,
      startDate,
      endDate,
      clientType,
      tenantGroupCode,
      tenantId,
      status,
    });

    updateClientEnrollmentMutation.mutate(
      {
        clientId: clientId!,
        enrollmentId: clientEnrollmentId!,
        data: payload,
        clientType: clientType as UserType,
      },
      {
        onSuccess: () => {
          handleCancel();
        },
      }
    );
  };

  return {
    handleDate,
    clearEndDate,
    handleCancel,
    handleSave,
  };
};
