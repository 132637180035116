import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, SxProps, Typography, TypographyProps } from '@mui/material';

interface IRadioBoxButtonProps {
  label: string;
  isActive: boolean;
  onClick: VoidFunction;
  isDisabled?: boolean;
  width?: string | number;
  showCheckIcon?: boolean;
  sx?: SxProps;
  labelProps?: TypographyProps;
}

/**
 * Radio button that looks more like a rectangular button rather than circular check that the default radio has
 *
 */
export const RadioBoxButton = ({
  label,
  isActive,
  onClick,
  isDisabled,
  width = '100%',
  showCheckIcon,
  labelProps,
  ...other
}: IRadioBoxButtonProps) => (
  <Button
    className={isActive ? 'radioBoxButton--active' : ''}
    color={isActive ? 'primary' : undefined}
    disabled={isDisabled}
    onClick={() => onClick()}
    size="small"
    startIcon={
      isActive && showCheckIcon ? (
        <FontAwesomeIcon icon={faCircleCheck} />
      ) : undefined
    }
    sx={{
      width,
      maxWidth: width,
      color: isActive && !showCheckIcon ? 'primary.main' : 'auto',
      bgcolor: isActive && !showCheckIcon ? 'primary.lighter' : 'auto',
    }}
    tabIndex={0}
    type="button"
    variant="radioBoxButton"
    {...other}
  >
    <Typography
      sx={{
        my: 0,
        mx: 1,
        lineHeight: 0,
        fontSize: 'inherit',
        ...labelProps?.sx,
      }}
    >
      {label}
    </Typography>
  </Button>
);

RadioBoxButton.defaultProps = {
  isDisabled: false,
  width: '100%',
  showCheckIcon: true,
  sx: undefined,
  labelProps: undefined,
};
