import { useNavigate } from 'react-router-dom';

import { DictionaryKeys } from 'constants/languageDictionary';
import ModalKey from 'enums/ModalKey';
import { mapOldAddressImplementation } from 'features/cases/common';
import { selectAuthTenantAssociation } from 'stores/auth';
import { selectCurrentReferralCase } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';
import { selectLangDict } from 'stores/language-dictionary';
import { formatClientIndividualParent } from 'utils/client-management';
import {
  formatCurrency,
  formatDate,
  getFullName,
  isNullOrUndefined,
} from 'utils/common';
import { getTextFromLangDict } from 'utils/languageDictionary';

export const usePatientInformation = () => {
  const navigate = useNavigate();
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const langDict = useAppSelector(selectLangDict);
  const authTenantAssocation = useAppSelector(selectAuthTenantAssociation);

  const data = currentReferralCase?.patient;
  const address =
    data && mapOldAddressImplementation(data?.address || undefined, data);
  const primaryParent = formatClientIndividualParent(data?.parent);

  const patientTerminology = getTextFromLangDict(langDict?.dict, {
    groupCode: authTenantAssocation?.groupCode || '',
    key: DictionaryKeys.PATIENT_TERMINOLOGY,
    tenantCode: authTenantAssocation?.code || '',
  });

  const isPrimaryPatient = !currentReferralCase?.patient?.parent?.parentId;

  const adaptedPatientAccumulator = data?.accumulator
    ? {
        individualMaxDeductible: !isNullOrUndefined(
          data.accumulator.individualMaxDeductible
        )
          ? formatCurrency(data.accumulator.individualMaxDeductible)
          : '-',
        individualUsedDeductible: !isNullOrUndefined(
          data.accumulator.individualUsedDeductible
        )
          ? formatCurrency(data.accumulator.individualUsedDeductible)
          : '-',
        individualOOPLimit: !isNullOrUndefined(
          data.accumulator.individualOOPLimit
        )
          ? formatCurrency(data.accumulator.individualOOPLimit)
          : '-',
        individualOOPUsed: !isNullOrUndefined(
          data.accumulator.individualOOPUsed
        )
          ? formatCurrency(data.accumulator.individualOOPUsed)
          : '-',
        familyMaxDeductible: !isNullOrUndefined(
          data.accumulator.familyMaxDeductible
        )
          ? formatCurrency(data.accumulator.familyMaxDeductible)
          : '-',
        familyUsedDeductible: !isNullOrUndefined(
          data.accumulator.familyUsedDeductible
        )
          ? formatCurrency(data.accumulator.familyUsedDeductible)
          : '-',
        familyOOPUsed: !isNullOrUndefined(data.accumulator.familyOOPUsed)
          ? formatCurrency(data.accumulator.familyOOPUsed)
          : '-',
        familyOOPLimit: !isNullOrUndefined(data.accumulator.familyOOPLimit)
          ? formatCurrency(data.accumulator.familyOOPLimit)
          : '-',
        benefitStartDate: !isNullOrUndefined(data.accumulator.benefitStartDate)
          ? formatDate(data.accumulator.benefitStartDate)
          : '-',
        benefitEndDate: !isNullOrUndefined(data.accumulator.benefitEndDate)
          ? formatDate(data.accumulator.benefitEndDate)
          : '-',
      }
    : undefined;

  const onEditClick = () => {
    navigate(
      `modal?type=${ModalKey.EDIT_CASE_PATIENT}&caseId=${currentReferralCase?.caseId}`
    );
  };
  const onchangePatientClick = () => {
    navigate(
      `modal?type=${ModalKey.CHANGE_PATIENT}&caseId=${currentReferralCase?.caseId}`
    );
  };
  const onEditAccumulatorClick = () => {
    navigate(
      `modal?type=${ModalKey.EDIT_ACCUMULATOR}&caseId=${currentReferralCase?.caseId}`
    );
  };

  return {
    patient: {
      ...data,
      address,
      fullName: getFullName(data?.firstName, data?.lastName, data?.middleName),
      primaryParent,
      formattedAccumulator: adaptedPatientAccumulator,
    },
    langDict: {
      patientTerminology,
    },
    onEditClick,
    onEditAccumulatorClick,
    isPrimaryPatient,
    onchangePatientClick,
  };
};
