import React from 'react';

import { ManageMembershipList } from 'common/manage-membership/ManageMembershipList';
import { IAdaptedClientEnrollmentMembership } from 'interfaces/client-management/enrollment';

export const AddEditMembershipListView = ({
  data,
}: {
  data: IAdaptedClientEnrollmentMembership[];
}) => (
  <ManageMembershipList
    data={data}
    excludeActions
    excludeFilters
    includeMultipleMembershipAdditionalDetails
    isLoading={false}
  />
);
