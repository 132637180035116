import commonConstants from 'constants/common';
import { SETTING_MENU_CODE } from 'constants/settings';
import { LookUpCategory } from 'enums/lookups';
import { IPermission } from 'interfaces/common';
import { ILookUpsTableRow } from 'interfaces/lookups';
import { ISettingMenuData } from 'interfaces/networks';
import { useLookUpQuery } from 'services/lookups';
import { selectMenu } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { sortObjectArrayByNumberProp } from 'utils/sortingUtil';

const matchMenus = (
  availableMenus: ILookUpsTableRow[],
  allowedMenus: IPermission[]
) => {
  const allowedMenuCodes = new Set(allowedMenus.map((menu) => menu.code));

  const restrictedMenu = availableMenus
    .map((menu) => {
      const includedResources = (
        (menu.metaData as ISettingMenuData)?.resources || []
      )
        .map(
          (resourceCode) =>
            allowedMenuCodes.has(resourceCode) &&
            allowedMenus.find((m) => m.code === resourceCode)
        )
        .filter(Boolean);

      if (includedResources.length === 0) {
        return false;
      }
      const sortedIncludedResources = sortObjectArrayByNumberProp(
        includedResources,
        'order',
        false
      );

      return {
        name: menu.name,
        description: menu.description,
        icon: (menu.metaData as ISettingMenuData).icon,
        order: (menu.metaData as ISettingMenuData).order,
        route: sortedIncludedResources[0].routePath,
        children: sortedIncludedResources,
      };
    })
    .filter(Boolean);

  const sortedRestrictedMenu = sortObjectArrayByNumberProp(
    restrictedMenu,
    'order',
    false
  );
  return sortedRestrictedMenu as any; // TODO: add interface
};

export const useGetSettingMenus = () => {
  const menus = useAppSelector(selectMenu);
  const settingMenu =
    menus.find((e) => e.code === SETTING_MENU_CODE)?.children || [];
  const lookupMenus = useLookUpQuery(
    {
      category: LookUpCategory.SETTING_MENU_CATEGORY,
      limit: commonConstants.PAGINATION.MAXIMUM_LIMIT,
    },
    { enabled: true }
  );

  const availableMenus = lookupMenus?.data || [];
  const restrictedMenus = matchMenus(availableMenus, settingMenu);

  return restrictedMenus;
};
