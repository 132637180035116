import { selectAuthUserClient } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

import { useCheckUserIsClient } from './useCheckUserIsClient';

export const useCheckUserIsClientPrimary = () => {
  const userData = useAppSelector(selectAuthUserClient);
  const isUserClient = useCheckUserIsClient();
  return (
    isUserClient &&
    !userData?.relationship?.groupId &&
    !userData?.relationship?.primaryId
  );
};
