import apiRoutes from 'constants/apiRoutes';
import { SelectionOptions } from 'enums/case';
import { CommunicationEventTopic } from 'enums/common';
import { DocumentVisibility } from 'enums/documents';
import { getOffsetFromPageNumber } from 'features/case-layout-new/utils';
import {
  IAddReferralCaseClientPayload,
  IAddReferralCasePayload,
  IAssociatedAssignee,
  IAssociatedGroup,
  IAssociatedPatient,
  IAssociatedRequestee,
  IAssociatedService,
  ICaseConfiguration,
  ICaseGeneralInformationPayload,
  ICaseGroupSelect,
  ICloneCaseOptions,
  IDonorPatientInformation,
  IFetchHistoryFilter,
  IFileBlobResponse,
  IReferralCase,
  IReferralCaseAccess,
  IReferralCaseFilter,
  IReferralCaseList,
  IReferralCaseNoteHistory,
  IReferralCaseNoteSchema,
  IReferralCaseNoteVisibilitySchema,
  IReferralCaseNotifyUserSchema,
  IReferralCasePatientInformationSchema,
  IReferralCasePatientResponse,
  IReferralCaseProviderOption,
  IReferralCaseProviderOptionPayload,
  IReferralCaseRequestedAssignedUserSchema,
  IReferralCaseRequestedChangeTenantSchema,
  IReferralCaseService,
  IReferralCaseServiceInformationForm,
  IReferralCaseShareWithEmailSchema,
  IReferralCaseStatus,
  IReferralCaseStatusResponse,
  IReferralCaseTimelog,
  IReferralCaseTimelogSchema,
  IReferralFileQuery,
  IReferralNote,
  IRequestedTo,
  IShareFileHistoryResponse,
  IStateAverage,
  ITaggableUser,
  ITask,
  ITaskSchema,
  IUpdateCaseInfosResponse,
} from 'interfaces/cases';
import { IClientTableRow } from 'interfaces/client-management';
import { IFilter, INameType } from 'interfaces/common';
import { IDocument, IFileSchema } from 'interfaces/documents';
import {
  ICaseConfigurationSchema,
  IFormConfigurationSchema,
  IFormConfigurationSchemaGender,
} from 'interfaces/enrollment';
import { IResponse } from 'interfaces/http';
import { IService } from 'interfaces/networks/network-offerings';
import { ITenantSettingItem } from 'interfaces/tenant-management/tenant';
import { baseRequest } from 'utils/axios';

import { IReferringPhysicianFormSchema } from '../../interfaces/cases/index';
import { IListResponse } from '../../interfaces/http';

const { cases, tenantManagement } = apiRoutes;

export const getFile = async ({
  caseId,
  fileId,
}: IReferralFileQuery): Promise<IResponse<IFileBlobResponse>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.download.replace(':id', caseId).replace(':fileId', fileId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getCases = async (
  params: IReferralCaseFilter
): Promise<IResponse<IListResponse<IReferralCaseList>>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.list,
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getCase = async (
  id: string
): Promise<IResponse<IReferralCase>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.get.replace(':id', id),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getCasesInfinite = async (
  params: IReferralCaseFilter,
  pageParam: number
): Promise<IListResponse<IReferralCaseList>> => {
  const offset = getOffsetFromPageNumber({
    page: pageParam,
    limit: params.limit,
    offset: params.offset,
  });

  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.list,
    params: {
      ...params,
      offset,
    },
  });

  if (error) {
    throw new Error(error.message);
  }
  return response?.data?.data;
};

export const getTimelog = async (
  id: string
): Promise<IResponse<IReferralCaseTimelog[]>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.timelog.replace(':id', id),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const updateStatus = async (
  caseId: string,
  data: IReferralCaseStatus
): Promise<IResponse<IReferralCaseStatusResponse>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: cases.updateStatus.replace(':id', caseId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const addNote = async (
  caseId: string,
  data: IReferralCaseNoteSchema
): Promise<IResponse<IReferralNote>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: cases.addNote.replace(':id', caseId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
export const addFile = async (
  caseId: string,
  data: IFileSchema[],
  params?: { createdBy?: string }
): Promise<IResponse<IDocument[]>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: cases.addFile.replace(':id', caseId),
    data,
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const shareCaseWithEmail = async (
  caseId: string,
  data: IReferralCaseShareWithEmailSchema
): Promise<IResponse<IReferralNote>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: cases.share.replace(':id', caseId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
export const changeTenant = async (
  caseId: string,
  data: IReferralCaseRequestedChangeTenantSchema
): Promise<IResponse<{ access: IReferralCaseAccess; to: IRequestedTo }>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: cases.forward.replace(':id', caseId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
export const assignUser = async (
  caseId: string,
  data: IReferralCaseRequestedAssignedUserSchema
): Promise<IResponse<{ acknowledged: boolean }>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: cases.assign.replace(':id', caseId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const notifyUser = async (
  caseId: string,
  data: IReferralCaseNotifyUserSchema
): Promise<IResponse<{ success: boolean }>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: cases.notify.replace(':id', caseId),
    data: { eventType: CommunicationEventTopic.CASE_ASSIGNED, eventData: data },
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const deleteFile = async (
  caseId: string,
  fileId: string
): Promise<IResponse<IReferralNote>> => {
  const { response, error } = await baseRequest({
    method: 'DELETE',
    url: cases.deleteFile.replace(':id', caseId).replace(':fileId', fileId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const addReferralCase = async (
  data: IAddReferralCasePayload | IAddReferralCaseClientPayload
): Promise<IResponse<IReferralCase>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: cases.add,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
export const ReferralCase = async (
  data: IAddReferralCasePayload | IAddReferralCaseClientPayload
): Promise<IResponse<IReferralCase>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: cases.add,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const cloneCase = async (
  caseId: string,
  data: ICloneCaseOptions
): Promise<IResponse<IReferralCase>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: cases.clone.replace(':id', caseId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
export const addReferralCaseTimelog = async (
  caseId: string,
  data: IReferralCaseTimelogSchema
): Promise<IResponse<IReferralCaseTimelog>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: cases.timelog.replace(':id', caseId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const updateReferralCaseTimelog = async (
  caseId: string,
  timelogId: string,
  data: IReferralCaseTimelogSchema
): Promise<IResponse<IReferralCaseTimelog>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: cases.updatetimelog
      .replace(':id', caseId)
      .replace(':timelogId', timelogId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const deleteReferralCaseTimelog = async (
  caseId: string,
  timelogId: string
): Promise<IResponse<{ timesheetId: string }>> => {
  const { response, error } = await baseRequest({
    method: 'DELETE',
    url: cases.updatetimelog
      .replace(':id', caseId)
      .replace(':timelogId', timelogId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const updateService = async (
  caseId: string,
  data: IReferralCaseServiceInformationForm[]
): Promise<IResponse<IReferralCaseService[]>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: cases.service.replace(':id', caseId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const updatePatient = async (
  caseId: string,
  data: IReferralCasePatientInformationSchema
): Promise<IResponse<IReferralCasePatientResponse>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: cases.updatePatient.replace(':id', caseId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
export const changePatient = async (
  caseId: string,
  data: IReferralCasePatientInformationSchema
): Promise<IResponse<IReferralCasePatientResponse>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: cases.changePatient.replace(':id', caseId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const updateNote = async (
  caseId: string,
  noteId: string,
  data: IReferralCaseNoteSchema
): Promise<IResponse<IReferralNote>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: cases.updateNote.replace(':id', caseId).replace(':noteId', noteId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const updateVisibilityNote = async (
  caseId: string,
  noteId: string,
  data: IReferralCaseNoteVisibilitySchema
): Promise<IResponse<IReferralNote>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: cases.updateNote.replace(':id', caseId).replace(':noteId', noteId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const deleteNote = async (
  caseId: string,
  noteId: string
): Promise<IResponse<IReferralNote>> => {
  const { response, error } = await baseRequest({
    method: 'DELETE',
    url: cases.deleteNote.replace(':id', caseId).replace(':noteId', noteId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getNoteHistory = async (
  caseId: string,
  noteId: string
): Promise<IResponse<IReferralCaseNoteHistory[]>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.getNoteHistory.replace(':id', caseId).replace(':noteId', noteId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getRemainingTime = async (
  memberId: string
): Promise<IResponse<any>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.remainingTime,
    params: { memberId },
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getTasks = async (
  caseId: string
): Promise<IResponse<IListResponse<ITask>>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.tasks,
    params: { caseId },
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const addTask = async (
  data: ITaskSchema
): Promise<IResponse<IReferralCaseTimelog>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: cases.tasks,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const deleteTask = async (taskId: string): Promise<IResponse<any>> => {
  const { response, error } = await baseRequest({
    method: 'DELETE',
    url: cases.deleteTask.replace(':taskId', taskId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const patchTaskStatus = async (
  taskId: string,
  data: { status: string }
): Promise<IResponse<any>> => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: cases.updateTask.replace(':taskId', taskId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getTasksDetail = async (
  taskId: string
): Promise<IResponse<ITask>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.updateTask.replace(':taskId', taskId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const updateTask = async (
  taskId: string,
  data: ITaskSchema
): Promise<IResponse<ITask>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: cases.updateTask.replace(':taskId', taskId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const addBloodDonors = async (
  caseId: string,
  data: IDonorPatientInformation[]
): Promise<IResponse<IDonorPatientInformation[]>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: cases.addBloodDonors.replace(':id', caseId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
export const deleteBloodDonor = async (
  caseId: string,
  donorId: string
): Promise<IResponse<string>> => {
  const { response, error } = await baseRequest({
    method: 'DELETE',
    url: cases.deleteBloodDonor
      .replace(':id', caseId)
      .replace(':donorId', donorId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
export const deleteReferringProvider = async (
  caseId: string,
  providerId: string
): Promise<IResponse<string>> => {
  const { response, error } = await baseRequest({
    method: 'DELETE',
    url: cases.deleteReferringProvider
      .replace(':id', caseId)
      .replace(':providerId', providerId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
export const addReferringProvider = async (
  caseId: string,
  data:
    | IReferralCaseProviderOptionPayload
    | IReferralCaseProviderOptionPayload[]
): Promise<
  IResponse<IReferralCaseProviderOption | IReferralCaseProviderOption[]>
> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: cases.addReferringProvider.replace(':id', caseId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
export const updateReferringProvider = async (
  caseId: string,
  providerId: string,
  data: IReferralCaseProviderOptionPayload
): Promise<IResponse<IReferralCaseProviderOption>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: cases.updateReferringProvider
      .replace(':id', caseId)
      .replace(':providerId', providerId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
export const updateStateAverage = async (
  caseId: string,
  data: IStateAverage
): Promise<IResponse<IStateAverage>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: cases.updateStateAverage.replace(':id', caseId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const updateSelectedDonor = async (
  caseId: string,
  donorId: string,
  type: SelectionOptions
): Promise<IResponse<string>> => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: cases.updateBloodDonor
      .replace(':id', caseId)
      .replace(':donorId', donorId),
    data: { type },
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
export const updateSelectedProvider = async (
  caseId: string,
  providerId: string,
  params: { isSelected: boolean }
): Promise<IResponse<IReferralCaseProviderOption>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    params,
    url: cases.selectProvider
      .replace(':id', caseId)
      .replace(':providerId', providerId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const addOrEditReferringPhysician = async (
  caseId: string,
  data: IReferringPhysicianFormSchema
): Promise<IResponse<IReferringPhysicianFormSchema>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: cases.addOrEditReferringPhysician.replace(':id', caseId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getCaseGroupSelect = async (
  clientId: string
): Promise<IResponse<ICaseGroupSelect>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.getGroupSelect.replace(':id', clientId),
  });
  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
export const getCaseHistory = async (
  caseId: string,
  fileId: string,
  filters: IFetchHistoryFilter
): Promise<IResponse<IShareFileHistoryResponse[]>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.getHistory.replace(':id', caseId).replace(':fileId', fileId),
    params: filters,
  });
  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const updateGeneralCaseInformation = async (
  caseId: string,
  data: ICaseGeneralInformationPayload
): Promise<IResponse<ICaseGeneralInformationPayload>> => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: cases.updateGeneralInfo.replace(':id', caseId),
    data,
  });
  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const updateCaseInfos = async (
  caseId: string,
  data: { [key: string]: any }
): Promise<IResponse<IUpdateCaseInfosResponse>> => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: cases.updateInfos.replace(':id', caseId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getCaseConfigs = async (
  params: IFilter
): Promise<IResponse<IListResponse<ICaseConfiguration>>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.getConfigs,
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getCaseNotes = async (
  caseId: string
): Promise<IResponse<IReferralNote[]>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.fetchNote.replace(':id', caseId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const updateCaseConfigs = async (
  data: INameType
): Promise<IResponse<IListResponse<ICaseConfiguration>>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: cases.getConfigs,
    data: [data],
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getLatestPatientInfo = async (
  id: string
): Promise<IResponse<IClientTableRow>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.getLatestPatientInfo.replace(':patientId', id),
  });
  if (error) throw new Error(error.message);
  return response?.data;
};

export const setAppointment = async ({
  caseId,
  providerId,
  data,
}: {
  caseId: string;
  providerId: string;
  data: {
    date: string;
  };
}): Promise<IResponse<IReferralCaseProviderOption[]>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: cases.setAppointment
      .replace(':caseId', caseId)
      .replace(':providerId', providerId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const deleteProcedure = async (
  caseId: string,
  procedureId: string
): Promise<IResponse<IService[]>> => {
  const { response, error } = await baseRequest({
    method: 'DELETE',
    url: cases.deleteProcedure
      .replace(':caseId', caseId)
      .replace(':procedureId', procedureId),
  });

  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};

export const getTaggableUsers = async (
  caseId: string,
  params: { keyword?: string; for: 'admin' | 'client' }
): Promise<IResponse<ITaggableUser[]>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.getTaggableUsers.replace(':id', caseId),
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const unshareCase = async (
  caseId: string,
  data: { referenceType: string; referenceId: string }
): Promise<IResponse<IReferralCase>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: cases.unshare.replace(':id', caseId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const fetchCaseAssociatedPatients = async (filters?: {
  keyword: string;
  groupId: string;
}): Promise<IResponse<IAssociatedPatient[]>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.associatedPatients,
    params: filters,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const fetchCaseAssociatedGroups = async (filters?: {
  keyword: string;
  patientId: string;
}): Promise<IResponse<IAssociatedGroup[]>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.associatedGroups,
    params: filters,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const fetchCaseAssociatedAssignees = async (filters?: {
  keyword: string;
}): Promise<IResponse<IAssociatedAssignee[]>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.associatedAssignees,
    params: filters,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const fetchCaseAssociatedRequestees = async (filters?: {
  keyword: string;
}): Promise<IResponse<IAssociatedRequestee[]>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.associatedRequestees,
    params: filters,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const fetchCaseAssociatedServices = async (filters?: {
  keyword: string;
}): Promise<IResponse<IAssociatedService[]>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.associatedServices,
    params: filters,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const setFileVisibility = async ({
  documentId,
  caseId,
  data,
}: {
  documentId: string;
  caseId: string;
  data: { associationId: string; visibility: DocumentVisibility };
}): Promise<IResponse<IAssociatedService[]>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: cases.setFileVisibility
      .replace(':caseId', caseId)
      .replace(':documentId', documentId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const checkCasePermission = async ({
  caseId,
  params,
}: {
  caseId: string;
  params: {
    email?: string;
    type: 'tenant' | 'thirdparty';
    tenantId?: string;
  };
}): Promise<IResponse<boolean>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.checkPermission.replace(':id', caseId),
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const updateCaseConfiguratonSettings = async (
  tenantId: string,
  data: ICaseConfigurationSchema
): Promise<ITenantSettingItem[]> => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: tenantManagement.tenant.caseConfiguration.update.replace(
      ':id',
      tenantId
    ),
    data,
  });
  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};
