import apiRoutes from 'constants/apiRoutes';
import { ILookUpFilter } from 'interfaces/common';
import { IResponse } from 'interfaces/http';
import { ILookUpsTableRow } from 'interfaces/lookups';
import { baseRequest } from 'utils/axios';

const { getAll, create, edit, getOne } = apiRoutes.lookup;

export const getAllLookUp = async (
  params: ILookUpFilter
): Promise<IResponse<ILookUpsTableRow[]>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: getAll,
    params,
  });
  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};

export const createLookUp = async (
  data: ILookUpsTableRow
): Promise<IResponse<ILookUpsTableRow[]>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: create,
    data,
  });
  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};

export const updateLookUp = async (
  data: ILookUpsTableRow,
  id: string
): Promise<IResponse<ILookUpsTableRow[]>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: edit.replace(':id', id),
    data,
  });
  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};

export const getLookUpDetail = async (
  lookUpId: string
): Promise<IResponse<ILookUpsTableRow>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: getOne.replace(':id', lookUpId),
  });
  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};
