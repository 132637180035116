import { FormEvent, useEffect, useState } from 'react';

import { Box, Button, MenuItem, TextField } from '@mui/material';
import AdminUserAutocomplete from 'common/autocomplete/AdminUserAutocomplete';
import AdvancedCaseFilterFormLayout from 'common/layouts/advanced-filter/AdvancedCaseFilterFormLayout';
import { IReferralCaseAdvancedSearchForm } from 'interfaces/cases';
import { IFetchStatusRow } from 'interfaces/status';
import { IAdaptedUserTableRow } from 'interfaces/tenant-management/user';
import {
  changeAdvancedSearch,
  resetAdvancedSearch,
  selectCaseAdvancedSearch,
} from 'stores/cases';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { getFullName } from 'utils/common';
import { getTenantFromToken } from 'utils/jwt';

enum AdvanceFilterType {
  REQUESTED_BY = 'requestedBy',
  REQUESTED_TO = 'requestedTo',
  STATUS = 'status',
}

interface IAdvancedFilterprops {
  onAdvancedSearchFormSubmit: (
    e: FormEvent<HTMLFormElement>,
    formData: IReferralCaseAdvancedSearchForm
  ) => void;
  setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  caseStatus?: IFetchStatusRow[];
}

const AdvancedFilter = ({
  onAdvancedSearchFormSubmit,
  setIsPopoverOpen,
  caseStatus,
}: IAdvancedFilterprops) => {
  const tenant = getTenantFromToken();
  const dispatch = useAppDispatch();
  const advancedSearch = useAppSelector(selectCaseAdvancedSearch);
  const [resetInputField, setResetInputField] = useState(false);

  const [formData, setFormData] = useState<IReferralCaseAdvancedSearchForm>({
    status: '',
    requestedBy: { id: '', name: '' },
    requestedTo: { id: '', name: '' },
    onlyMyCases: false,
  });

  const onChange = (
    key:
      | AdvanceFilterType.REQUESTED_BY
      | AdvanceFilterType.REQUESTED_TO
      | AdvanceFilterType.STATUS,
    value: { id: string; name: string } | string
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  useEffect(() => {
    setFormData({
      status: advancedSearch.status,
      requestedBy: advancedSearch.requestedBy,
      requestedTo: advancedSearch.requestedTo,
    });
  }, [advancedSearch]);

  const handleOnChangeSubmit = (
    name: AdvanceFilterType.REQUESTED_BY | AdvanceFilterType.REQUESTED_TO,
    user: IAdaptedUserTableRow
  ) => {
    onChange(name, {
      id: user.userId,
      name: getFullName(user.demographic.firstName, user.demographic.lastName),
    });
  };
  const handleClear = () => {
    setIsPopoverOpen(false);
    setResetInputField(true);
    dispatch(resetAdvancedSearch());
  };
  const handleInputClear = (
    name: AdvanceFilterType.REQUESTED_BY | AdvanceFilterType.REQUESTED_TO
  ) => {
    dispatch(changeAdvancedSearch({ [name]: { id: '', name: '' } }));
  };

  const isDisabled = !(
    advancedSearch.status !== formData.status ||
    advancedSearch.requestedBy !== formData.requestedBy ||
    advancedSearch.requestedTo !== formData.requestedTo
  );

  return (
    <form
      onSubmit={(e) => {
        setIsPopoverOpen(false);
        onAdvancedSearchFormSubmit(e, formData);
      }}
    >
      <Box>
        <AdvancedCaseFilterFormLayout spacing={0}>
          <TextField
            data-cy="case-advancefilter-status"
            label="Choose Status"
            name="status"
            onChange={(e) => onChange(AdvanceFilterType.STATUS, e.target.value)}
            select
            size="small"
            sx={{ width: '100%' }}
            value={formData.status}
            variant="standard"
          >
            {caseStatus?.length &&
              caseStatus.map(({ name, code }) => (
                <MenuItem
                  data-cy={`case-advancefilter-status-${name}`}
                  key={name}
                  value={code}
                >
                  {name}
                </MenuItem>
              ))}
          </TextField>
        </AdvancedCaseFilterFormLayout>
        <AdvancedCaseFilterFormLayout spacing={0}>
          <AdminUserAutocomplete
            handleInputClear={() =>
              handleInputClear(AdvanceFilterType.REQUESTED_BY)
            }
            handleOnChangeSubmit={(e) =>
              handleOnChangeSubmit(
                AdvanceFilterType.REQUESTED_BY,
                e as IAdaptedUserTableRow
              )
            }
            inputProps={{ 'data-cy': 'case-advancefilter-createdBy' }}
            keyword={formData.requestedBy?.name}
            label="Created by"
            resetInputField={resetInputField}
            resetValueOnClick={false}
            selectedValue={formData?.requestedBy?.id || undefined}
            setResetInputField={setResetInputField}
            tenantId={tenant.tenantId}
            userId={formData.requestedBy?.id}
          />
        </AdvancedCaseFilterFormLayout>
        <AdvancedCaseFilterFormLayout spacing={0}>
          <AdminUserAutocomplete
            handleInputClear={() =>
              handleInputClear(AdvanceFilterType.REQUESTED_TO)
            }
            handleOnChangeSubmit={(e) =>
              handleOnChangeSubmit(
                AdvanceFilterType.REQUESTED_TO,
                e as IAdaptedUserTableRow
              )
            }
            inputProps={{ 'data-cy': 'case-advancefilter-assignedTo' }}
            keyword={formData.requestedTo?.name}
            label="Assigned to"
            resetInputField={resetInputField}
            resetValueOnClick={false}
            selectedValue={formData?.requestedTo?.id || undefined}
            tenantId={tenant.tenantId}
            userId={formData.requestedTo?.id}
          />
        </AdvancedCaseFilterFormLayout>
        <Box display="flex" justifyContent="space-between">
          <Button
            data-cy="case-advancefilter-clear"
            onClick={handleClear}
            size="small"
            type="button"
            variant="outlined"
          >
            Clear
          </Button>
          <Button
            data-cy="case-advancefilter-apply"
            disabled={isDisabled}
            size="small"
            type="submit"
            variant="contained"
          >
            Apply
          </Button>
        </Box>
      </Box>
    </form>
  );
};

AdvancedFilter.defaultProps = {
  caseStatus: null,
};

export default AdvancedFilter;
