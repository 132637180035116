import { DECIMAL_DIGITS } from 'constants/common';
import regex from 'constants/regex';
import validationMessage from 'constants/validationMessage';
import { CountryCodes } from 'enums/country';
import { useCheckFormFieldSettings } from 'hooks/useCheckEnabledFields';
import { getMinValueBasedOnDecimalDigits } from 'utils/common';
import * as Yup from 'yup';

export const useGenderSchema = () => {
  const genderSetting = useCheckFormFieldSettings().required.gender;

  if (genderSetting) {
    return Yup.string().required(validationMessage.SELECT_ONE_OPTION);
  }

  return Yup.string().nullable();
};

export const useBirthDateSchema = () => {
  const dobSetting = useCheckFormFieldSettings().required.dob;

  if (dobSetting) {
    return Yup.string().required();
  }
  return Yup.string().nullable();
};

export const useLastSSNSchema = () => {
  const SSnetting = useCheckFormFieldSettings().required.last4ssn;
  if (SSnetting) {
    return Yup.string().required();
  }

  return Yup.string().nullable();
};
export const useAddressSchema = () => {
  const addressSettings = useCheckFormFieldSettings().required.address;
  if (addressSettings) {
    return Yup.string().required(validationMessage.REQUIRED);
  }

  return Yup.string().nullable();
};
export const useReferredBySchema = () => {
  const referredBySettings = useCheckFormFieldSettings().required.referredBy;

  if (referredBySettings) {
    return Yup.object({
      id: Yup.string().required(),
      name: Yup.string().required(),
    }).required();
  }

  return Yup.object().nullable();
};

export const internationalAddressSchema = Yup.string().when('countryCode', {
  is: (countryCode: string) => countryCode === CountryCodes.USA,
  then: Yup.string()
    .matches(regex.PHONE, {
      excludeEmptyString: true,
      message: validationMessage.INVALID,
    })
    .nullable(),
});

export const internationalAddressHomeSchema = Yup.string()
  .nullable()
  .when('hCode', {
    is: (countryCode: string) => countryCode === CountryCodes.USA,
    then: Yup.string()
      .matches(regex.PHONE, {
        excludeEmptyString: true,
        message: validationMessage.INVALID,
      })
      .nullable(),
  });

export const internationalAddressWorkSchema = Yup.string()
  .nullable()
  .when('wCode', {
    is: (countryCode: string) => countryCode === CountryCodes.USA,
    then: Yup.string()
      .matches(regex.PHONE, {
        excludeEmptyString: true,
        message: validationMessage.INVALID,
      })
      .nullable(),
  });

export const usePhoneSchema = () => {
  const phoneSetting = useCheckFormFieldSettings().required.phone;
  if (phoneSetting) {
    return internationalAddressSchema.required();
  }
  return internationalAddressSchema.nullable();
};

export const useHomePhoneSchema = () => {
  const homePhoneSetting = useCheckFormFieldSettings().required.homePhone;
  if (homePhoneSetting) {
    return internationalAddressHomeSchema.required();
  }
  return internationalAddressHomeSchema.nullable();
};

export const useWorkPhoneSchema = () => {
  const workPhoneSetting = useCheckFormFieldSettings().required.workPhone;
  if (workPhoneSetting) {
    return internationalAddressWorkSchema.required();
  }
  return internationalAddressWorkSchema.nullable();
};

export const useEmailSchema = () => {
  const emailSettings = useCheckFormFieldSettings().required.email;
  const commonSchema = Yup.string().trim().matches(regex.EMAIL, {
    message: validationMessage.INVALID,
    excludeEmptyString: true,
  });
  if (emailSettings) {
    return commonSchema.required();
  }
  return commonSchema.nullable();
};
export const useBloodGroupSchema = () => {
  const bloodGroupSettings = useCheckFormFieldSettings().required.bloodGroup;
  if (bloodGroupSettings) {
    return Yup.string().required();
  }

  return Yup.string().nullable();
};

export const decimalSchema = (data?: { minimumDecimalDigit: number }) =>
  Yup.number().min(
    getMinValueBasedOnDecimalDigits(
      data?.minimumDecimalDigit || DECIMAL_DIGITS.NUMBER
    )
  );
