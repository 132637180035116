import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';

import uiRoutes from 'constants/uiRoutes';
import { setAuthToken } from 'utils/axios';
import { clearLocal, clearSession } from 'utils/storage';

interface Props {
  children: JSX.Element;
}

export const IdleTimerWrapper = ({ children }: Props) => {
  const INACTIVITY_TIMER = Number(process.env.REACT_APP_INACTIVITY_TIMER);

  const navigate = useNavigate();

  const logout = () => {
    clearLocal();
    clearSession();
    setAuthToken('');
    navigate(`/${uiRoutes.auth.login}?inactive=true`, { replace: true });
    window.location.reload();
  };

  const handleOnIdle = () => {
    logout();
  };

  useIdleTimer({
    timeout: 1000 * 60 * INACTIVITY_TIMER,
    onIdle: handleOnIdle,
    name: 'idle-timer',
    crossTab: true,
    leaderElection: true,
    syncTimers: 200, // It takes a number that represents the throttle value in milliseconds for the sync function
  });

  return children;
};
