import { useQueryClient } from 'react-query';

import { referralCasesKeys } from 'services/cases';

import { useRelevantCombinedFilter } from './useRelevantCombinedFilter';

export const useInfiniteRelevantCaseList = () => {
  const { combinedFilters } = useRelevantCombinedFilter();

  const queryClient = useQueryClient();

  const listQueryData = queryClient.getQueriesData(
    referralCasesKeys.list(combinedFilters)
  );

  // The details of the case must be fetched if the list of cases is present.
  const dataCount =
    (listQueryData &&
      listQueryData[0] &&
      listQueryData[0][1] &&
      (listQueryData[0][1] as any)?.pages &&
      (listQueryData[0][1] as any).pages[0]?.count) ||
    0;

  return {
    totalCasesCount: dataCount,
    combinedFilters,
  };
};
