/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { SortOrder } from 'enums/common';
import { SponsorSortBy } from 'enums/timesheet/sponsor';
import { IFilter, SortOrderType } from 'interfaces/common';
import {
  ITimesheetBySponsorAdvancedSearchForm,
  SponsorSortByType,
} from 'interfaces/timesheet/sponsor';
import { RootState } from 'stores';

const { DEFAULT_TABLE_FILTER } = commonConstants;

const advancedSearchDefaultValues = {
  tenantId: '',
  interval: '',
};

interface ISponsorSort {
  sortBy: SponsorSortByType;
  sortOrder: SortOrderType;
}

interface SponsorState {
  filters: IFilter;
  advancedSearch: ITimesheetBySponsorAdvancedSearchForm;
  sort: ISponsorSort;
}

const initialState: SponsorState = {
  filters: { ...DEFAULT_TABLE_FILTER },
  advancedSearch: { ...advancedSearchDefaultValues },
  sort: {
    sortBy: SponsorSortBy.SPONSOR,
    sortOrder: SortOrder.ASC,
  },
};

export const slice = createSlice({
  name: 'timesheetBySponsor',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (state, action: PayloadAction<Partial<IFilter>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },

    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<ITimesheetBySponsorAdvancedSearchForm>>
    ) => {
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },

    resetAdvancedSearch: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
    },
    changeSortByAndOrder: (state, action: PayloadAction<ISponsorSort>) => {
      state.sort = {
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    },
    resetAllFilters: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
      state.filters = {
        ...state.filters,
        ...DEFAULT_TABLE_FILTER,
      };
      state.sort = initialState.sort;
    },
  },
});

// Actions
export const {
  changeFilters,
  resetFilters,
  changeSortByAndOrder,
  changeAdvancedSearch,
  resetAdvancedSearch,
  resetAllFilters,
} = slice.actions;

// Selectors
export const selectTimesheetBySponsorFilter = (state: RootState) =>
  state.timesheetBySponsor.filters;
export const selectTimesheetBySponsorSort = (state: RootState) =>
  state.timesheetBySponsor.sort;

export const selectTimesheetBySponsorAdvancedSearch = (state: RootState) =>
  state.timesheetBySponsor.advancedSearch;
export const selectTimesheetBySponsor = (state: RootState) =>
  state.timesheetBySponsor;

// Reducer
export default slice.reducer;
