import { ButtonProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { pxToRem } from 'utils/mui';

declare module '@mui/material/Button' {
  // interface ButtonPropsSizeOverrides {
  //   extraSmall: true;
  // }

  interface ButtonPropsColorOverrides {
    // Disable colors that are not in use
    secondary: false;
    info: false;
    success: false;
    warning: false;
  }

  interface ButtonPropsVariantOverrides {
    // Custom variants
    radioBoxButton: true;

    // Depreciated variants
    neutral: true;
    container: true;
  }
}

const Button = (theme: Theme) => ({
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true, // No more ripple on buttons for the whole application
    },
  },
  MuiButton: {
    defaultProps: {
      size: 'medium',
      color: 'primary',
    } as ButtonProps,
    variants: [
      {
        /**
         * custom variant 'radioBoxButton'
         *
         * For active state, add the className="radioBoxButton--active"
         *
         */
        props: { variant: 'radioBoxButton' } as ButtonProps,
        style: {
          ...theme.typography.body2,
          borderRadius: theme.customBorderRadius.sm,
          backgroundColor: theme.palette.gray.lighter,
          border: `1px solid ${theme.palette.gray.lighter}`,
          color: theme.palette.gray.darker,
          height: '30px',
          width: '100%',

          '& .MuiButton-startIcon': {
            position: 'absolute',
            left: 8,
            width: 16,
            height: 16,
            color: theme.palette.primary.main,
          },

          '&:hover': {
            border: `1px solid ${theme.palette.gray.main}`,
          },
          '&.radioBoxButton--active': {
            border: `1px solid ${theme.palette.primary.main}`,
          },

          '&:disabled': {
            opacity: 1, // opacity set different to that of normal buttons as the text is dimmed in gray background in less opacity
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        borderRadius: theme.customBorderRadius.sm,
        fontWeight: theme.typography.fontWeightRegular,
        boxShadow: 'none',

        '&:focus': {
          boxShadow: `0px 0px 4px ${theme.palette.primary.main}`, // TODO: Hardcoded value; take it from theme
        },

        '&:disabled': {
          opacity: 0.5,
        },

        // TODO: Depreciated. Remove this class
        '&.physician-radio-button': {
          position: 'relative',
          '& .MuiButton-startIcon': {
            position: 'absolute',
            top: '20px',
            left: '16px',
          },
        },
      },
      contained: {
        '&:hover': {
          boxShadow: theme.customShadows.low,
        },
        '&:active': {
          boxShadow: 'inset 0px 4px 8px #00000029', // TODO: Hardcoded value; take it from theme
        },
      },
      outlined: {
        color: theme.palette.gray.dark,
        border: `1px solid ${theme.palette.gray.main}`,
        backgroundColor: theme.palette.common.white,
        '&:hover': {
          color: theme.palette.gray.dark,
          border: `1px solid ${theme.palette.gray.dark}`,
        },
        '&:active': {
          backgroundColor: theme.palette.gray.lighter,
        },

        // Note: Padding for outlined buttons are set separately to adjust 2px in height due to 1px all round border
        '&.MuiButton-sizeLarge': {
          padding: `${pxToRem(11)} ${pxToRem(32)}`,
        },
        '&.MuiButton-sizeMedium': {
          padding: `${pxToRem(9)} ${pxToRem(16)}`,
        },
        '&.MuiButton-sizeSmall': {
          padding: `${pxToRem(5)} ${pxToRem(8)}`,
        },
      },
      text: {
        color: theme.palette.gray.dark,
        textDecoration: 'underline',
        textDecorationColor: theme.palette.gray.main,
        '&:hover': {
          background: 'transparent',
          color: theme.palette.gray.darker,
          textDecoration: 'underline',
          textDecorationColor: theme.palette.gray.main,
        },
        '&:active': {
          backgroundColor: theme.palette.gray.lighter,
        },
        '&:disabled': {
          textDecoration: 'none',
        },
      },

      containedPrimary: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        '&:hover, &:focus': {
          backgroundColor: theme.palette.secondary.main,
        },
        '&:active': {
          backgroundColor: theme.palette.secondary.dark,
        },
      },

      sizeSmall: {
        ...theme.typography.caption,
        fontWeight: theme.typography.fontWeightMedium,
        padding: `${pxToRem(6)} ${pxToRem(8)}`,
      },
      sizeMedium: {
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightMedium,
        padding: `${pxToRem(10)} ${pxToRem(16)}`,
      },
      sizeLarge: {
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightMedium,
        padding: `${pxToRem(12)} ${pxToRem(32)}`,
      },
      iconSizeSmall: {
        width: theme.spacing(1.5),
        height: theme.spacing(1.5),
        paddingBottom: 1,
        svg: {
          width: '100%',
          height: '100%',
        },
        '&.Mui-Button-startIcon': {
          marginRight: theme.spacing(1),
        },
        '&.Mui-Button-endIcon': {
          marginLeft: theme.spacing(1),
        },
      },
      iconSizeMedium: {
        width: theme.spacing(1.75),
        height: theme.spacing(1.75),
        paddingBottom: 2,
        svg: {
          width: '100%',
          height: '100%',
        },
        '&.Mui-Button-startIcon': {
          marginRight: theme.spacing(1),
        },
        '&.Mui-Button-endIcon': {
          marginLeft: theme.spacing(1),
        },
      },
      iconSizeLarge: {
        width: theme.spacing(2),
        height: theme.spacing(2),
        paddingBottom: 2,
        svg: {
          width: '100%',
          height: '100%',
        },
        '&.Mui-Button-startIcon': {
          marginRight: theme.spacing(1),
        },
        '&.Mui-Button-endIcon': {
          marginLeft: theme.spacing(1),
        },
      },
    },
  },
});

export default Button;
