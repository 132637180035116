import { useQueryClient } from 'react-query';

import { referralCasesKeys } from 'services/cases';
import { selectCurrentPage } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';

import { getTotalPages } from '../utils';
import useCombinedFilter from './useCombinedFilter';

export const useInfiniteCaseList = () => {
  const { combinedFilters } = useCombinedFilter();
  const currentPage = useAppSelector(selectCurrentPage);
  const queryClient = useQueryClient();

  const listQueryData = queryClient.getQueriesData(
    referralCasesKeys.list(combinedFilters)
  );

  // The details of the case must be fetched if the list of cases is present.
  const totalCount =
    (listQueryData &&
      listQueryData[0] &&
      listQueryData[0][1] &&
      (listQueryData[0][1] as any)?.pages &&
      (listQueryData[0][1] as any).pages[0]?.count) ||
    0;

  const checkShowMoreIsDisabled = () => {
    const totalPages = getTotalPages(totalCount, combinedFilters.limit);
    const totalPagesGreaterThanOne = totalPages > 1;
    const currentPageIsEqualToTotalPages = currentPage >= totalPages - 1;
    if (!totalPagesGreaterThanOne || currentPageIsEqualToTotalPages)
      return true;
    if (totalPagesGreaterThanOne && !currentPageIsEqualToTotalPages)
      return false;
    return true;
  };
  return {
    totalCasesCount: totalCount,
    combinedFilters,
    checkShowMoreIsDisabled,
  };
};
