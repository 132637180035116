import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

// TODO: To use Existing userAutoComplete
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Box,
  CircularProgress,
  debounce,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import commonConstants from 'constants/common';
import { IFilter } from 'interfaces/common';
import { IAssignedUserFilter } from 'interfaces/tenant-management/tenant';
import { IAdaptedUserTableRow } from 'interfaces/tenant-management/user';
import { useUsersQuery } from 'services/tenant-management/users';
import { selectAuthTenantAssociation } from 'stores/auth';
import { selectFilteredPeople } from 'stores/calender';
import { useAppSelector } from 'stores/hooks';

const { DEFAULT_TABLE_FILTER } = commonConstants;

interface IUserAutoCompleteProps {
  tenantId: string;
  handleInputClear?: VoidFunction;
  resetInputField?: boolean;
  disabled?: boolean;
  handleOnChangeSubmit: (e: IAdaptedUserTableRow | null) => void;
  label?: string;
  placeholder?: string;
  size?: 'small' | 'medium';
  variant?: 'standard' | 'filled' | 'outlined';
  selectedValue?: string;
  selectedValueName?: string;
  error?: boolean;
  setResetInputField?: React.Dispatch<React.SetStateAction<boolean>>;
  inputProps?: TextFieldProps & { 'data-cy'?: string };
  showEmailOption?: boolean;
  blurOnSelect?: boolean;
  showFilteredOption?: boolean;
  userId?: string;
  keyword?: string;
  resetValueOnClick?: boolean;
  isRequired?: boolean;
  showDropDownIcon?: boolean;
}

const AdminUserAutocomplete = ({
  tenantId,
  keyword = '',
  resetValueOnClick = true,
  label,
  placeholder,
  disabled,
  size,
  variant,
  handleOnChangeSubmit,
  handleInputClear,
  selectedValue,
  selectedValueName,
  error,
  resetInputField,
  setResetInputField,
  inputProps,
  showEmailOption,
  blurOnSelect,
  showFilteredOption,
  userId,
  isRequired,
  showDropDownIcon,
}: IUserAutoCompleteProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [clearData, setClearData] = useState(false);
  const tenantData = useAppSelector(selectAuthTenantAssociation);
  const filteredPeopleFromCalendar =
    useAppSelector(selectFilteredPeople)[tenantData?.tenantId || ''] || [];
  const [value, setValue] = useState<IAdaptedUserTableRow | null>(null);
  const [filters, setFilters] = useState<IFilter & IAssignedUserFilter>({
    ...DEFAULT_TABLE_FILTER,
    tenantId,
  });

  const usersQuery = useUsersQuery(filters, {
    fetchAdminUsers: false,
    enabled: !!filters.keyword || !!userId,
  });

  useEffect(() => {
    // Prepoulate the keyboard which was submitted
    // It will prepopulate the data which was selected and submitted through filter
    // Donot set the keywork on every keyword typped so disable eslint for filters as dependency array
    setFilters({ ...filters, keyword: filters.keyword || keyword });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    if (usersQuery.data?.rows && userId && !clearData) {
      const user = usersQuery.data?.rows.find((item) => item.userId === userId);
      setValue(user ?? null);
      setOpen(false);
    }
    // Disable this eslint since we dont want to run this effect when value changes to null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearData, userId, usersQuery.data?.rows]);

  const handleInputChange = useCallback(
    async (event: SyntheticEvent<Element, Event>, newInputValue: string) => {
      if (event && event?.type === 'click' && resetValueOnClick) {
        // Adding this resetValueOnClick since not having this causes the autopopulate value to set to null when edit or prepopulate
        setValue(null);
      }
      if (setResetInputField) setResetInputField(false);
      if (newInputValue.length > 2 || !newInputValue.length) {
        if (event && event?.type === 'change') {
          setOpen(true);
        }
        setFilters((prevState) => ({
          ...prevState,
          keyword: newInputValue,
        }));
      }
    },
    [resetValueOnClick, setResetInputField]
  );

  const onChange = async (user: IAdaptedUserTableRow | null) => {
    setValue(user);
    handleOnChangeSubmit(user);
    setValue(null);
  };

  const debouncedInputChange = useMemo(
    () => debounce(handleInputChange, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const options = useMemo(
    () => usersQuery?.data?.rows || [],
    [usersQuery?.data?.rows]
  );

  const filteredUser = options.filter(
    (option) =>
      !filteredPeopleFromCalendar.some(
        (filteredPerson) => filteredPerson.userId === option.userId
      )
  );

  useEffect(() => {
    if (selectedValueName) {
      setClearData(true);
      setFilters((prevState) => ({
        ...prevState,
        keyword: selectedValueName,
      }));
    }
  }, [selectedValueName]);

  useEffect(() => {
    if (selectedValue && options && clearData) {
      const v = options.find((e) => e.userId === selectedValue);
      if (v) {
        setValue(v);
      }
      setClearData(false);
    }
    if (resetInputField) setValue(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, resetInputField, selectedValue]);

  useEffect(() => {
    if (selectedValue) {
      const v = options.find((e) => e.userId === selectedValue);
      if (v) {
        setValue(v);
      }
    }
  }, [options, selectedValue]);

  useEffect(() => {
    if (!selectedValue) {
      setValue(null);
    }
  }, [selectedValue]);

  return (
    <Autocomplete
      autoComplete
      blurOnSelect={blurOnSelect}
      disabled={disabled}
      filterOptions={(x) => x}
      filterSelectedOptions
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : `${option.demographic.fullName}`
      }
      id="async-user-autocomplete"
      includeInputInList
      isOptionEqualToValue={(option, newValue) =>
        option.userId === newValue.userId
      }
      loading={usersQuery.isLoading}
      onChange={(
        event: SyntheticEvent<Element, Event>,
        newValue: IAdaptedUserTableRow | null,
        reason: AutocompleteChangeReason
      ) => {
        if (reason === 'clear') {
          onChange(null);
          setValue(null);
        } else if (reason === 'selectOption' && blurOnSelect) {
          setValue(null);
        } else {
          setValue(newValue);
        }
        onChange(newValue || null);
        setOpen(false);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={(
        event: SyntheticEvent<Element, Event>,
        v: string,
        reason: AutocompleteInputChangeReason
      ) => {
        if (reason === 'clear') {
          if (handleInputClear) handleInputClear();
        }
        debouncedInputChange(event, v);
      }}
      open={open}
      openOnFocus={false}
      options={showFilteredOption ? filteredUser : options}
      renderInput={(params) => (
        // You can use different variations of textfield here.
        // Please refer TextfieldOverview.tsx for reference.
        <TextField
          {...params}
          {...inputProps}
          error={error}
          fullWidth
          InputProps={{
            ...params.InputProps,
            ...inputProps?.InputProps,
            endAdornment: (
              <>
                {usersQuery.isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            ...params.inputProps,
            ...inputProps?.inputProps,
          }}
          label={`${isRequired ? `${label} *` : label}`}
          placeholder={placeholder}
          size={size}
          variant={variant}
        />
      )}
      renderOption={(props, option) => (
        /* eslint-disable react/jsx-props-no-spreading */
        <Box
          {...props}
          component="li"
          key={option.userId}
          sx={{
            '&:hover': {
              color: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          <Stack>
            <Typography color="inherit" gutterBottom={false} variant="body2">
              {option.demographic?.fullName}
            </Typography>
            {showEmailOption && (
              <Typography
                color="inherit"
                gutterBottom={false}
                variant="caption"
              >
                {option.demographic?.email}
              </Typography>
            )}
          </Stack>
        </Box>
      )}
      sx={{
        '& .MuiAutocomplete-popupIndicator': {
          visibility: showDropDownIcon ? 'visible' : 'hidden',
        },
      }}
      value={value}
    />
  );
};

AdminUserAutocomplete.defaultProps = {
  label: '',
  placeholder: 'Type name or email of user',
  size: 'small',
  variant: 'standard',
  selectedValue: undefined,
  selectedValueName: undefined,
  handleInputClear: undefined,
  setResetInputField: undefined,
  error: false,
  resetInputField: false,
  disabled: false,
  inputProps: null,
  showEmailOption: false,
  blurOnSelect: false,
  showFilteredOption: false,
  userId: '',
  keyword: '',
  resetValueOnClick: true,
  isRequired: false,
  showDropDownIcon: true,
};

export default AdminUserAutocomplete;
