export enum ReferringAgentStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  NEW = 'New',
}

export enum RefferingAgentSortBy {
  NAME = 'name',
  EMAIL = 'email',
}

export enum ReferralType {
  AGENT = 'agent',
  SOCIAL_PLATFORM = 'socialPlatform',
}
