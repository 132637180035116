import { Controller, useFormContext } from 'react-hook-form';

import {
  FormControlLabel,
  FormGroup,
  Switch,
  SwitchProps,
  SxProps,
  Typography,
} from '@mui/material';

type IProp = {
  name: string;
  label: string;
  labelPlacement?: 'end' | 'top' | 'bottom' | 'start' | undefined;
  isDisabled?: boolean;
  sx?: SxProps;
} & SwitchProps;
const FormSwitch = ({
  name,
  label,
  labelPlacement,
  sx,
  isDisabled,
  ...props
}: IProp) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                {...props}
                {...field}
                checked={field.value}
                disabled={isDisabled}
                onChange={(e, checked) => {
                  field.onChange(e.target.checked);

                  if (props.onChange !== undefined) {
                    props.onChange(e, checked);
                  }
                }}
              />
            }
            label={
              <Typography gutterBottom={false} sx={sx} variant="body2">
                {label}
              </Typography>
            }
            labelPlacement={labelPlacement}
            sx={{
              '&.MuiFormControlLabel-root': {
                ml: 0,
              },
            }}
          />
        </FormGroup>
      )}
    />
  );
};
FormSwitch.defaultProps = {
  labelPlacement: 'start',
  sx: {},
  isDisabled: false,
};

export default FormSwitch;
