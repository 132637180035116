import React from 'react';

import { Box, DialogContent } from '@mui/material';
import {
  MiscellaneousCode,
  TenantSettingStatus,
} from 'enums/tenant-management/tenant';
import MembershipIDCard from 'features/dashboard/membership-id-card/MembershipIDCard';
import { ReferralQRCodeCard } from 'features/dashboard/qr-code/ReferralQRCodeCard';
import { useGetMiscellaneousTenantSettings } from 'hooks/useGetMiscellaneousTenantSettings';
import {
  IAdaptedClientGroup,
  IAdaptedClientIndividual,
} from 'interfaces/client-management';
import { selectEditClientData } from 'stores/client-management';
import { useAppSelector } from 'stores/hooks';

interface IProps {
  clientId: string;
}
const IDCard = ({ clientId }: IProps) => {
  const editClientData = useAppSelector(
    selectEditClientData
  ) as IAdaptedClientGroup & IAdaptedClientIndividual;
  const isQrCodeDisplayEnabled =
    useGetMiscellaneousTenantSettings(MiscellaneousCode.SHOW_QR_CODE) ===
    TenantSettingStatus.ENABLED;

  return (
    <DialogContent className="modal-content modal-content--fixed-height">
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        gap={4}
        justifyContent="center"
      >
        <MembershipIDCard
          clientId={clientId}
          clientName={editClientData.name || ''}
          label="ID Card"
          removeDownloadOption
        />
        {isQrCodeDisplayEnabled && clientId && (
          <ReferralQRCodeCard
            clientId={clientId}
            clientName={editClientData.name || ''}
            removeDownloadOption
          />
        )}
      </Box>
    </DialogContent>
  );
};

export default IDCard;
