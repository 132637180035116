import apiRoutes from 'constants/apiRoutes';
import { IFileBlobResponse, IReferralFileQuery } from 'interfaces/cases';
import {
  IAddTagsResponse,
  IAttachDocumentPayload,
  IConvertToESignResponse,
  IDocument,
  IDocumentFilter,
  IDocumentRenameResponse,
} from 'interfaces/documents';
import { IResponse } from 'interfaces/http';
import { IModalSharePayload } from 'interfaces/share-modal';
import { baseRequest } from 'utils/axios';

const { cases, documents } = apiRoutes;

export const getFile = async ({
  caseId,
  fileId,
}: IReferralFileQuery): Promise<IResponse<IFileBlobResponse>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: cases.download.replace(':id', caseId).replace(':fileId', fileId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getDocuments = async (params: IDocumentFilter) => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: documents.list,
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getDocument = async (
  documentId: string,
  associationId?: string
): Promise<IResponse<IDocument>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: documents.detail.replace(':documentId', documentId),
    ...(associationId && {
      params: { associationId },
    }),
  });
  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};

export const addDocuments = async (
  data: any
): Promise<IResponse<IDocument[]>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: documents.add,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
export const shareDocuments = async (
  documentId: string,
  data: IModalSharePayload
): Promise<IResponse<IDocument[]>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: documents.share.replace(':documentId', documentId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const attachDocuments = async (
  data: IAttachDocumentPayload[]
): Promise<IResponse<IDocument[]>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: documents.attach,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const deleteDocument = async (
  documentId: string,
  associationId: string
): Promise<IResponse<{ documentId: string }>> => {
  const { response, error } = await baseRequest({
    method: 'DELETE',
    url: documents.delete
      .replace(':documentId', documentId)
      .replace(':associationId', associationId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const addTags = async (
  documentId: string,
  data: { tags: string[] }
): Promise<IResponse<IAddTagsResponse>> => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: documents.addTags.replace(':documentId', documentId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const renameDocument = async (
  documentId: string,
  data: { name: string }
): Promise<IResponse<IDocumentRenameResponse>> => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: documents.rename.replace(':documentId', documentId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const convertToESign = async (
  documentId: string,
  data: { name: string },
  progressCallback?: { onUploadProgress?: (progress: any) => void }
): Promise<IResponse<IConvertToESignResponse>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: documents.convertToESign.replace(':documentId', documentId),
    data,
    onUploadProgress: progressCallback?.onUploadProgress,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getESignEditLink = async (
  documentId: string
): Promise<IResponse<{ editUrl: string }>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: documents.getESignEditLink.replace(':documentId', documentId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
