import { toPng } from 'html-to-image';

import { formatDate } from './common';

export const getHtmlSnapShot = async (
  ref: any,
  fileName: string,
  setIsDownloading: (loading: boolean) => void,
  options: {
    width?: number;
    height?: number;
  } = {}
) => {
  if (ref.current === null) {
    return;
  }

  toPng(ref.current, {
    cacheBust: true,
    quality: 1,
    ...options,
  })
    .then((dataUrl: any) => {
      const link = document.createElement('a');
      link.download = `${fileName}_${formatDate(
        new Date().toLocaleDateString()
      )}.jpeg`;
      link.href = dataUrl;
      link.click();
      link.remove();
      setIsDownloading(false);
    })
    .catch(() => {
      // eslint-disable-next-line no-console
      console.log('Error downloading Images');
      setIsDownloading(false);
    });
};
