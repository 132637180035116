import React from 'react';

import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, SxProps, Typography } from '@mui/material';

export const BilledToInfo = ({
  clientName,
  inline,
  typographySx,
}: {
  clientName: string;
  inline?: boolean;
  typographySx?: SxProps;
}) => (
  <Box
    alignItems="center"
    display="flex"
    flexDirection={inline ? 'row' : 'column'}
    justifyContent="center"
  >
    <Box>
      <Box
        component={FontAwesomeIcon}
        icon={faCircleCheck}
        sx={{ color: (theme) => theme.palette.primary.main }}
      />
      <Typography
        color="primary"
        display="inline-block"
        gutterBottom={false}
        ml={1}
        variant="body2"
      >
        Billed to
      </Typography>
    </Box>
    <Typography
      data-cy="payment-billed-to"
      gutterBottom={false}
      sx={{
        ml: 1,
        ...typographySx,
      }}
      textAlign="center"
      variant="body2"
    >
      {clientName}
    </Typography>
  </Box>
);

BilledToInfo.defaultProps = {
  inline: false,
  typographySx: {},
};
