import uiRoutes from 'constants/uiRoutes';
import {
  AffiliateType,
  BloodTypeVerified,
  ClientType,
  OfferingType,
  RelationshipToPrimaryMember,
} from 'enums/client-management';
import { Gender, ReferenceSubType, ReferenceType } from 'enums/common';
import { CountryCodes } from 'enums/country';
import { EnrollmentCode } from 'enums/tenant-management/tenant';
import UserType, { RelationshipUserType } from 'enums/User';
import {
  IAdaptedClient,
  IAdaptedClientGroup,
  IAdaptedClientGroupTableRow,
  IAdaptedClientIndividual,
  IAdaptedClientIndividualTableRow,
  IAdaptedClientTableRow,
  IAddClientDependentSchema,
  IAddClientForm,
  IAddClientGroupMemberDependentSchema,
  IAddClientGroupMemberSchema,
  IAddClientGroupSchema,
  IAddClientPrimarySchema,
  IAdditionalAddClientForm,
  IClient,
  IClientGroupTableRow,
  IClientIndividualTableRow,
  IClientTableRow,
  IEnrollment,
  IRelationshipPayloadClientDependent,
  IRelationshipPayloadClientGroupMember,
  IRelationshipPayloadClientGroupMemberDependent,
} from 'interfaces/client-management';
import {
  IAdaptedClientEnrollmentMembership,
  IClientDashboardSummary,
  IClientEnrollmentMembership,
  IRelationship,
} from 'interfaces/client-management/enrollment';
import { IListResponse, IResponse } from 'interfaces/http';
import { RootState, store } from 'stores';
import {
  adaptedPhoneExtension,
  attachAddressObject,
  capitalizeFirstLetter,
  formatCurrency,
  formatDate,
  formatPhone,
  getContactNumbers,
  getFormattedContactNumber,
  getFullName,
  getSplitNamesFromFullName,
  unformatDate,
  unformatPhone,
} from 'utils/common';
import { formatDateView } from 'utils/moment';
import {
  formatActiveStatus,
  formatBillingInterval,
  formatBillingType,
} from 'utils/tenant-management/tenant';
import { checkIsEnrollmentSettingEnabled } from 'utils/tenantSetting';

import { getBenefitDateRange, getTenantList } from './enrollment';

// Check is Sponsor login
export const checkUserIsClient = (tenantData: any) => {
  const referenceType = tenantData?.tenantAssociation?.referenceType;
  return referenceType === ReferenceType.CLIENT;
};

export const checkUserIsClientGroup = (tenantData: any) => {
  const isUserIsClient = checkUserIsClient(tenantData);

  const referenceSubType = tenantData?.tenantAssociation?.referenceSubType;

  return isUserIsClient && referenceSubType === ReferenceSubType.GROUP;
};

const isClientAffiliateEnabled = checkIsEnrollmentSettingEnabled(
  EnrollmentCode.CLIENT_AFFILIATE
);

export const checkIsClientPrimary = (
  clientData: IAdaptedClient | IAdaptedClientIndividualTableRow | null
) => {
  const isClientPrimary = !(
    clientData?.relationship?.groupId || clientData?.relationship?.primaryId
  );
  return isClientPrimary;
};

export const checkIsClientDependentOrGroupMember = (
  clientData: IAdaptedClient | null
) => {
  const isClientDependentOrGroupMember =
    clientData?.relationship?.groupId || clientData?.relationship?.primaryId;
  return isClientDependentOrGroupMember;
};
export const checkIsClientGroupMember = (clientData: IAdaptedClient | null) =>
  !!clientData?.relationship?.groupId;

export const checkIsClientGroup = (clientData: IAdaptedClient | null) =>
  clientData?.type === UserType.GROUP;

// Checks whether the client is dependent (of primary individual or of group member)
export const checkIsClientDependent = (
  clientData: IAdaptedClient | null
): boolean => !!clientData?.relationship?.primaryId;

export const getGroupIdOrPrimaryId = (
  editClientData: IAdaptedClientGroup | IAdaptedClientIndividual | null
) => {
  const groupIdOrPrimaryId =
    editClientData?.relationship?.groupId ||
    editClientData?.relationship?.primaryId;
  return groupIdOrPrimaryId;
};

export const getGroupNameOrPrimaryName = (
  editClientData: IAdaptedClientGroup | IAdaptedClientIndividual | null
) => {
  const groupIdOrPrimaryName =
    editClientData?.relationship?.groupName ||
    editClientData?.relationship?.primaryName;
  return groupIdOrPrimaryName;
};

const getReferralType = () => {
  if (isClientAffiliateEnabled) {
    return AffiliateType.CLIENT;
  }
  return AffiliateType.AGENT;
};

export const adaptClientGroupList = (
  res: IResponse<IListResponse<IClientGroupTableRow>>
): IResponse<IListResponse<IAdaptedClientGroupTableRow>> => ({
  ...res,
  data: {
    ...res.data,
    rows: res.data?.rows?.map((client) => {
      const countryCode = client?.countryCode;
      const adaptCountryCode = !countryCode ? CountryCodes.USA : countryCode;
      const isCountrySelectedUS = adaptCountryCode === CountryCodes.USA;
      const phoneExtension = !client?.phoneExt ? '+1' : `+${client?.phoneExt}`;

      return {
        ...client,
        name: client.businessName,
        contactFullName: getFullName(
          client?.contactFirstName,
          client?.contactLastName
        ),
        formattedPhone: isCountrySelectedUS // depreciated
          ? `${adaptedPhoneExtension(
            client.phone,
            phoneExtension
          )} ${formatPhone(client.phone)}`
          : `${adaptedPhoneExtension(client.phone, phoneExtension)} ${client.phone
          }`,
        updatedBy: capitalizeFirstLetter(client.updatedBy ?? ''),
        updatedAt: formatDateView(client.updatedAt ?? '') || '-',
        benefit: getBenefitDateRange(
          client?.membership?.startDate ?? '',
          client?.membership?.endDate ?? ''
        ),
        tenantsList: getTenantList(client?.tenants || []),
        membership: {
          ...client.membership,
          status: client.membership?.status ?? client?.status,
          hoursPurchased:
            client.membership?.hoursPurchased ||
            client?.membership?.inventory?.value,
        },
        formattedPhones: getContactNumbers(client.phones || []),
        lastMembershipTransactionStatus:
          client.alerts?.transaction?.membership?.status,
        lastMembershipTransactionDetail: client.alerts?.transaction?.membership,
      };
    }),
  },
});

// keeping these function here if kept at last gives hoisting error

// format parent name if type is group member add bracket or else donot add bracket
const formatParentName = (type: string, parentName?: string) =>
  type ? parentName : `(${parentName})`;

export const formatClientIndividualParent = (
  relationship?: IRelationship,
  membership?: IEnrollment
) => {
  const isRelationshipTypePrimary = relationship?.type === ClientType.PRIMARY;
  const isMembershipOfferingAncillary =
    membership?.offeringType === OfferingType.ANCILLARY;

  const rootState = store.getState();
  const { tenantData } = rootState.auth;

  const isUserClientGroup = checkUserIsClientGroup(tenantData);

  // Condition for Group Member
  if (
    !relationship?.primaryName &&
    isRelationshipTypePrimary &&
    relationship?.groupName &&
    !isUserClientGroup
  ) {
    return ` ${ClientType.PRIMARY} (${relationship.groupName})`;
  }

  if (
    !relationship?.primaryName &&
    isRelationshipTypePrimary &&
    relationship?.groupName &&
    isUserClientGroup
  ) {
    return ` ${ClientType.PRIMARY} `;
  }

  // Condition for Ancillary
  if (
    !relationship?.primaryName &&
    !relationship?.type &&
    !relationship?.groupName &&
    isMembershipOfferingAncillary
  )
    return '-';

  // Condition for Primary Individual
  if (
    !relationship?.primaryName &&
    !relationship?.type &&
    !relationship?.groupName
  )
    return ClientType.PRIMARY;

  if (
    !relationship?.primaryName &&
    !relationship?.groupName &&
    relationship?.type
  )
    return `${relationship?.type}`;

  if (
    !relationship?.primaryName &&
    relationship?.groupName &&
    relationship?.type
  ) {
    return `${relationship?.type} (${relationship.groupName})`;
  }

  // Condition for Groupmember Dependent
  if (
    (relationship.type === RelationshipToPrimaryMember.CHILD ||
      relationship.type === RelationshipToPrimaryMember.SPOUSE ||
      relationship.type === RelationshipToPrimaryMember.OTHERS) &&
    relationship?.groupName &&
    !isUserClientGroup
  ) {
    return `${relationship?.type} of ${formatParentName(
      relationship.type,
      relationship?.primaryName
    )}  (${relationship.groupName})`;
  }
  if (
    (relationship.type === RelationshipToPrimaryMember.CHILD ||
      relationship.type === RelationshipToPrimaryMember.SPOUSE ||
      relationship.type === RelationshipToPrimaryMember.OTHERS) &&
    relationship?.groupName &&
    isUserClientGroup
  ) {
    return `${relationship?.type} of ${formatParentName(
      relationship.type,
      relationship?.primaryName
    )}  `;
  }

  // Condition for Dependent Individual
  if (
    relationship.type === RelationshipToPrimaryMember.CHILD ||
    relationship.type === RelationshipToPrimaryMember.SPOUSE ||
    relationship.type === RelationshipToPrimaryMember.OTHERS
  ) {
    return `${relationship?.type} of ${formatParentName(
      relationship.type,
      relationship?.primaryName
    )}`;
  }

  return `${relationship?.type} ${relationship.primaryName && `(${relationship.primaryName})`
    }`;
};

export const adaptClientIndividualList = (
  res: IResponse<IListResponse<IClientIndividualTableRow>>
): IResponse<IListResponse<IAdaptedClientIndividualTableRow>> => ({
  ...res,
  data: {
    ...res.data,
    rows: res.data?.rows?.map((client) => {
      const countryCode = client?.countryCode;
      const adaptCountryCode = !countryCode ? CountryCodes.USA : countryCode;
      const isCountrySelectedUS = adaptCountryCode === CountryCodes.USA;
      const phoneExtension = !client?.phoneExt ? '+1' : `+${client?.phoneExt}`;
      const splittedNames = getSplitNamesFromFullName(client.name);
      return {
        ...client,
        ...(splittedNames && { ...splittedNames }),
        email: client?.email?.trim(),
        membership: {
          ...client.membership,
          status: client.membership?.status ?? client?.status,
          hoursPurchased:
            client.membership?.hoursPurchased ||
            client?.membership?.inventory?.value,
        },
        name: getFullName(
          client.firstName,
          client?.lastName,
          client.middleName
        ),
        formattedIndividualPhone: isCountrySelectedUS // depreciated
          ? `${adaptedPhoneExtension(
            client.phone,
            phoneExtension
          )} ${formatPhone(client.phone)}`
          : `${adaptedPhoneExtension(client.phone, phoneExtension)} ${client.phone
          }`,
        updatedBy: capitalizeFirstLetter(client?.updatedBy ?? ''),
        updatedAt: formatDateView(client?.updatedAt ?? '') || '-',
        benefit: getBenefitDateRange(
          client?.startDate ?? '',
          client?.endDate ?? ''
        ),
        primary: formatClientIndividualParent(
          client?.relationship,
          client?.membership
        ),
        relationship: {
          ...client.relationship,
          parentName: client.relationship?.primaryName ?? '-',
        },
        tenantsList: getTenantList(client?.tenants || []),
        formattedIndividualCreatedAt:
          formatDateView(client?.created?.date || '') || '-',
        formattedPhones: getContactNumbers(client.phones || []),
        lastMembershipTransactionDetail: client.alerts?.transaction?.membership,
        lastMembershipTransactionStatus:
          client.alerts?.transaction?.membership?.status,
      };
    }),
  },
});

export const adaptClientIndividualOfferingsList = (
  res: IResponse<IClientDashboardSummary>
): IResponse<IClientDashboardSummary> => ({
  ...res,
  data: {
    ...res.data,
    totalExpenses: formatCurrency(res.data.totalExpenses) || '',
    offerings: res.data?.offerings?.map((offering) => ({
      ...offering,
      totalCost: formatCurrency(Number(offering.totalCost)) || '',
      billingInterval:
        offering?.billingInterval &&
        formatBillingInterval(offering?.billingInterval),
      billingType:
        offering?.billingType && formatBillingType(offering?.billingType),
      unformattedBillingInterval: offering?.billingInterval,
      unformattedBillingType: offering?.billingType,
    })),
  },
});

export const adaptClientList = (
  res: IResponse<IListResponse<IClientTableRow>>
): IResponse<IListResponse<IAdaptedClientTableRow>> => ({
  ...res,
  data: {
    ...res.data,
    rows: res.data?.rows?.map((client) => {
      const splittedNames = getSplitNamesFromFullName(client.name);
      return {
        ...client,
        ...(splittedNames && { ...splittedNames }),
        name: getFullName(client.firstName, client.lastName, client.middleName),
        formattedPhone: formatPhone(client.phone), // depreciated
        createdDate: formatDateView(client?.created?.date || '') || '',
        membership: {
          ...client.membership,
          status: client.membership?.status ?? client?.status,
          hoursPurchased:
            client.membership?.hoursPurchased ||
            client?.membership?.inventory?.value,
        },
        formattedPhones: getContactNumbers(client.phones || []),
        lastMembershipTransactionStatus:
          client.alerts?.transaction?.membership?.status,
        lastMembershipTransactionDetail: client.alerts?.transaction?.membership,
      };
    }),
  },
});

export const adaptClientEnrollmentMembership = (
  res: IResponse<IListResponse<IClientEnrollmentMembership>>
): IResponse<IListResponse<IAdaptedClientEnrollmentMembership>> => ({
  ...res,
  data: {
    ...res.data,
    rows: res.data?.rows?.map((item, index) => ({
      ...item,
      formattedTotalCost: formatCurrency(item.offerings.cost) || '',
      formattedRegistrationFee:
        formatCurrency(item.offerings.registrationFee) || '',
      formattedBillingInterval: item?.offerings?.billingInterval
        ? formatBillingInterval(item.offerings.billingInterval)
        : '',
      formattedBillingType: item.offerings?.billingType
        ? formatBillingType(item.offerings.billingType)
        : '',
      isDisabled: index !== 0,
      isActiveStatus: formatActiveStatus(
        item?.startDate,
        item?.endDate,
        item?.status
      ),
    })),
  },
});

export const adaptClient = (
  res: IResponse<IClient>
): IResponse<IAdaptedClient> => ({
  ...res,
  data: {
    ...res.data,
    name: getFullName(
      res.data?.firstName,
      res.data?.lastName,
      res.data?.middleName
    ),
    fullName: getFullName(
      res.data?.firstName,
      res.data?.lastName,
      res.data?.middleName
    ),
    files: res.data?.files?.map((file) => ({
      ...file,
      formattedCreatedDate: formatDateView(file.createdDate) ?? 'N/A',
    })),
    formattedPhone: formatPhone(res.data?.phone),
    unformattedDob: unformatDate(res.data?.dob),
    formattedCreatedAt: formatDateView(res.data?.created?.date || '') ?? '-',
    // Active Tenant -> Tenant where the client is created or where the client has active membership
    activeTenant: res.data.tenants?.find(
      (item) => item.tenantId === res.data.tenantId
    ),
    membership: {
      ...res.data?.membership,
      status: res.data?.membership?.status ?? res.data?.status,
      hoursPurchased:
        res.data?.membership?.hoursPurchased ||
        res.data?.membership?.inventory?.value,
    },
    affiliate: {
      id: res.data.affiliate?.id ?? '',
      name: res.data.affiliate?.name ?? '',
      type: res.data.affiliate?.type ?? '',
      startDate: res.data.affiliate?.startDate ?? '',
    },
    formattedPhones: getContactNumbers(res.data.phones || []),
  },
});

export const formatClientGroupSavePayload = (
  data: IAddClientForm,
  {
    tenantId,
    tenantGroupCode,
    clientId,
    clientEnrollmentId,
  }: IAdditionalAddClientForm
): IAddClientGroupSchema => ({
  type: UserType.GROUP,
  businessName: data.businessName,
  contactFirstName: data.firstName,
  contactMiddleName: data.middleName,
  contactLastName: data.lastName,
  email: data.email,
  phone: data.phone,
  address: attachAddressObject(data),
  tags: data.tags || [],
  note: data.note,
  affiliate: {
    id: data.referredBy?.id,
    name: data.referredBy?.name,
    type: getReferralType(),
  },
  phoneExt: data.phoneExt,
  countryCode: data.countryCode,
  memberId: data.memberId,
  tenantId,
  tenantGroupCode,
  isVip: data?.isVip || false,
  textEnabled: data.textEnabled,
  ...(clientId && { clientId }),
  ...(clientEnrollmentId && { clientEnrollmentId }),
  ...getFormattedContactNumber(data),
});

export const formatClientPrimarySavePayload = (
  data: IAddClientForm,
  {
    tenantId,
    tenantGroupCode,
    clientId,
    clientEnrollmentId,
  }: IAdditionalAddClientForm
): IAddClientPrimarySchema => ({
  type: UserType.INDIVIDUAL,
  firstName: data.firstName,
  middleName: data.middleName,
  lastName: data.lastName,
  lastSSN: data.lastSSN,
  dob: formatDate(data.dob),
  gender: data.gender as Gender,
  email: data.email,
  phone: unformatPhone(data.phone),
  address: attachAddressObject(data),
  note: data.note,
  ...(data.referredBy?.id && {
    affiliate: {
      id: data.referredBy?.id,
      name: data.referredBy?.name,
      type: getReferralType(),
    },
  }),
  tenantId,
  tenantGroupCode,
  bloodGroup: data.bloodGroup,
  bloodTypeVerified:
    data.isBloodTypeVerified && data.bloodGroup
      ? BloodTypeVerified.VERIFIED
      : BloodTypeVerified.UNVERIFIED,
  isVip: data?.isVip || false,
  tags: data.tags || [],
  memberId: data.memberId || '',
  textEnabled: data.textEnabled,
  phoneExt: data.phoneExt,
  countryCode: data.countryCode,
  ...(clientId && { clientId }),
  ...(clientEnrollmentId && { clientEnrollmentId }),
  ...getFormattedContactNumber(data),
});

export const formatClientGroupMemberSavePayload = (
  data: IAddClientForm,
  {
    tenantId,
    tenantGroupCode,
    clientId,
    clientEnrollmentId,
  }: IAdditionalAddClientForm
): IAddClientGroupMemberSchema => {
  const formattedData = formatClientPrimarySavePayload(data, {
    tenantId,
    tenantGroupCode,
    clientId,
    clientEnrollmentId,
  });
  return {
    ...formattedData,
    relationship: {
      groupId: data.groupId,
      groupName: data.groupName,
      groupEnrollmentId: data.groupEnrollmentId,
      type: RelationshipUserType.PRIMARY,
    } as IRelationshipPayloadClientGroupMember,
  };
};

export const formatClientGroupMemberDependentSavePayload = (
  data: IAddClientForm,
  {
    tenantId,
    tenantGroupCode,
    clientId,
    clientEnrollmentId,
  }: IAdditionalAddClientForm
): IAddClientGroupMemberDependentSchema => {
  const formattedData = formatClientPrimarySavePayload(data, {
    tenantId,
    tenantGroupCode,
    clientId,
    clientEnrollmentId,
  });

  return {
    ...formattedData,
    relationship: {
      groupId: data.groupId,
      groupName: data.groupName,
      groupEnrollmentId: data.groupEnrollmentId,
      primaryId: data.primaryId,
      primaryName: data.primaryName,
      primaryEnrollmentId: data.primaryEnrollmentId,
      // type: RelationshipUserType.PRIMARY,
    } as IRelationshipPayloadClientGroupMemberDependent,
  };
};

export const formatClientDependentSavePayload = (
  data: IAddClientForm,
  {
    tenantId,
    tenantGroupCode,
    clientId,
    clientEnrollmentId,
  }: IAdditionalAddClientForm
): IAddClientDependentSchema => {
  const formattedData = formatClientPrimarySavePayload(data, {
    tenantId,
    tenantGroupCode,
    clientId,
    clientEnrollmentId,
  });

  return {
    ...formattedData,
    relationship: {
      type: data.relationship,
      primaryId: data.primaryId,
      primaryName: data.primaryName,
      primaryEnrollmentId: data.primaryEnrollmentId,
      ...(data.groupId && { groupId: data.groupId }),
      ...(data.groupName && { groupName: data.groupName }),
      ...(data.groupEnrollmentId && {
        groupEnrollmentId: data.groupEnrollmentId,
      }),
    } as IRelationshipPayloadClientDependent,
  };
};

export const getClientTypeFromClient = (client: Partial<IClient>) => {
  if (!client) {
    return '';
  }

  const { PRIMARY, SPOUSE, CHILD, OTHERS } = RelationshipToPrimaryMember;

  const isGroup = client.type === UserType.GROUP;

  if (isGroup) {
    return ClientType.GROUP;
  }

  const relationship = client.relationship?.type || '';
  const parentId =
    client.relationship?.groupId || client.relationship?.primaryId;

  // Primary individuals have relationship type 'Primary' but they don't have parent
  // if (relationship === PRIMARY && !parentId) {
  if (!parentId) {
    return ClientType.PRIMARY;
  }

  // Group Members have relationship type 'Primary' and they have parent as well
  if (relationship === PRIMARY && !!client.relationship?.groupId) {
    return ClientType.GROUP_MEMBER;
  }

  // Dependents have relationship type 'Child'/'Spouse'/'Others' and they have parent as well
  if (
    [SPOUSE, CHILD, OTHERS].includes(
      relationship as RelationshipToPrimaryMember
    ) &&
    !!parentId
  ) {
    return ClientType.DEPENDENT;
  }

  // return '';
  // TODO: If none of the conditions match, send PRIMARY to account for those who do not have relationship set
  // (those coming from Website Walk-in)
  // However, this is a temporary solution. Revert back to "return ''" after the data has been updated in db.
  return ClientType.PRIMARY;
};

export const getBaseRouteForClient = (type: UserType) => {
  const routeToUserTypeMap = {
    [UserType.INDIVIDUAL]: uiRoutes.individuals,
    [UserType.GROUP]: uiRoutes.groups,
  };
  return routeToUserTypeMap[type];
};

export const checkIsDependentOfGroupMember = (
  client:
    | IAdaptedClientGroup
    | IAdaptedClientIndividual
    | IAdaptedClientIndividualTableRow
    | IAdaptedClientGroupTableRow
) => !!client?.relationship?.groupId && !!client?.relationship?.primaryId;

/**
 * Get parentId and parentName for client
 *
 * In case of dependent of a group member, `skipPrimaryGroupMember` decides whether to send the group member
 * (i.e. primary of depedent) or to send the company the group member is from
 *
 * @param client
 * @param {skipPrimaryGroupMember}
 * @returns
 */
export const getParentFromClient = (
  client: IAdaptedClientGroup | IAdaptedClientIndividual,
  { skipPrimaryGroupMember }: { skipPrimaryGroupMember: boolean }
): { parentId: string; parentName: string } | null => {
  if (!client) return null;
  const clientType = getClientTypeFromClient(client);
  if (
    clientType !== ClientType.DEPENDENT &&
    clientType !== ClientType.GROUP_MEMBER
  )
    return null;

  if (clientType === ClientType.GROUP_MEMBER) {
    return {
      parentId: client?.relationship?.groupId || '',
      parentName: client?.relationship?.groupName || '',
    };
  }

  const isDependentOfGroupMember = checkIsDependentOfGroupMember(client);

  if (!isDependentOfGroupMember || !skipPrimaryGroupMember) {
    return {
      parentId: client?.relationship?.primaryId || '',
      parentName: client?.relationship?.primaryName || '',
    };
  }

  return {
    parentId: client?.relationship?.groupId || '',
    parentName: client?.relationship?.groupName || '',
  };
};

/**
 * Get tenantId of the active location. Takes tenantId from the auth store
 *
 * For eg: If there are two locations (WA and NR) of a tenant (HCM) and you're currently logged in to WA,
 * then, this util will return tenantId of WA.
 *
 *
 */
export const getLocationSpecificTenantId = () => {
  const rootState: RootState = store.getState();
  return rootState.auth?.tenantData?.tenantAssociation?.tenantId || '';
};

/**
 * Get tenantId of the location where the primary client was created. Takes tenantId from the client data
 *
 * For eg: If there are two locations (WA and NR) of a tenant (HCM). A client was created in WA.
 * Now, even if you're logged in at NR, this util will return tenantId of WA. Same goes for dependent/member of that client.
 *
 */
export const getClientSpecificTenantId = (
  client: IAdaptedClientGroup | IAdaptedClientIndividual | null
) => client?.tenantId || '';
