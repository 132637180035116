import { useEffect } from 'react';

import { Button } from '@mui/material';
import { useCheckUserIsTenantAdmin } from 'hooks/useCheckUserIsTenantAdmin';
import { useCheckUserIsV360Admin } from 'hooks/useCheckUserIsV360Admin';
import { useGetReleaseNote } from 'hooks/useGetReleaseNote';
import { selectLatestReleaseNote, setLatestReleaseNote } from 'stores/auth';
import { useAppDispatch, useAppSelector } from 'stores/hooks';

export const ReleaseNoteButton = () => {
  const isUserTenantAdmin = useCheckUserIsTenantAdmin();
  const isUserV360Admin = useCheckUserIsV360Admin();
  const dispatch = useAppDispatch();

  const {
    releaseNoteDetail: { note },
  } = useGetReleaseNote();
  const latestNote = useAppSelector(selectLatestReleaseNote);
  const releaseNote = isUserV360Admin ? latestNote : note;

  useEffect(() => {
    dispatch(setLatestReleaseNote(note));
  }, [dispatch, note]);

  if (!isUserTenantAdmin || !releaseNote) return null;

  const handleOnClick = () => {
    window.open(`${releaseNote}`, '_blank');
  };

  return (
    <Button onClick={handleOnClick} size="small" variant="outlined">
      Release Note
    </Button>
  );
};
