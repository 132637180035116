import DatePicker from 'common/DatePicker';
import { IMembershipDateProps } from 'interfaces/client-management/manage-membership';
import { useUpdateClientEnrollmentMutation } from 'services/client-management/enrollment';
import { formatDateView } from 'utils/moment';

import { useGetCurrentSelectedClientAndMembershipDetails } from '../hooks/useGetCurrentSelectedClientAndMembershipDetails';
import { useHandleInlineDateChange } from '../hooks/useHandleInlineDateChange';
import { EditableDetailLayout } from './EditableDetailLayout';

export const ChangeApprovedMembershipDate = ({
  setIsEditing,
  setDate,
  date,
  status,
}: IMembershipDateProps) => {
  const { handleCancel, handleDate, handleSave } = useHandleInlineDateChange({
    setIsEditing,
    setDate,
  });

  const { currentSelectedStartDate, membership } =
    useGetCurrentSelectedClientAndMembershipDetails();

  const updateClientEnrollmentMutation = useUpdateClientEnrollmentMutation();

  const handleSaveAction = () => {
    handleSave({
      status,
      startDate: currentSelectedStartDate!,
      endDate: '',
    });
  };

  return (
    <EditableDetailLayout
      handleCancel={handleCancel}
      handleSave={handleSaveAction}
      isLoading={updateClientEnrollmentMutation.isLoading}
      isSaveDisabled={currentSelectedStartDate === membership?.startDate}
    >
      <DatePicker
        inputProps={{
          variant: 'standard',
          'data-cy': `client-membership-cancel-date-input`,
        }}
        label="Start Date"
        onChange={(d) =>
          handleDate({ startDate: formatDateView(d as string) || '' })
        }
        value={date}
      />
    </EditableDetailLayout>
  );
};
