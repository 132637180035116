import { Modal } from 'common';
import { ITenantUser } from 'interfaces/chat';
import { IResponseMessage } from 'interfaces/virtual-visit/comet-chat';

import ChatUploadDocument from './FileUpload';

const ChatUploadDocumentsModal = ({
  item,
  eventId,
  handleClose,
  callBack,
}: {
  item: ITenantUser | null;
  handleClose: () => void;
  callBack: (data: IResponseMessage) => any;
  eventId: string;
}) => (
  <Modal
    handleClose={handleClose}
    icon="faUpload"
    maxWidth="md"
    open
    title="Upload Files"
  >
    <ChatUploadDocument
      callBack={callBack}
      eventID={eventId || ''}
      handleClose={handleClose}
      item={item}
    />
  </Modal>
);

export default ChatUploadDocumentsModal;
