/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FacilityPhysicianToggle } from 'enums/networks';
import {
  INetworkDirectoryFacilityByNetworkDirectoryId,
  INetworkDirectoryFacilityTableRow,
  INetworkDirectoryPhysicianByNetworkDirectoryId,
  INetworkDirectoryPhysicianTableRow,
} from 'interfaces/networks/network-directory';
import {
  INetworkFacilityTableRow,
  ISearchNetworkFacilityTableRow,
} from 'interfaces/networks/network-facility';
import { INetworkOfferingsTableRow } from 'interfaces/networks/network-offerings';
import {
  INetworkPhysicianTableRow,
  ISearchNetworkPhysicianTableRow,
} from 'interfaces/networks/network-physician';
import { ITenantNetworkTableRow } from 'interfaces/tenant-networks';
import { RootState } from 'stores';

interface IState {
  selectedPhysician: INetworkPhysicianTableRow | null;
  selectedFacility: INetworkFacilityTableRow | null;
  selectedOffering: INetworkOfferingsTableRow | null;
  availableNetworks: ITenantNetworkTableRow[] | [];
  selectedNetworkCode: string | null;
  selectedNetworkId: string | null;
  selectedNetworkDirectoryFacility: INetworkDirectoryFacilityTableRow | null;
  selectedNetworkDirectoryPhysician: INetworkDirectoryPhysicianTableRow | null;
  selectedSearchFacility: ISearchNetworkFacilityTableRow[];
  selectedSearchOffering: INetworkOfferingsTableRow[];
  selectedSearchPhysician: ISearchNetworkPhysicianTableRow[];
  editNetworkDirectoryFacility: INetworkDirectoryFacilityByNetworkDirectoryId | null;
  addNetworkDirectoryFacility: INetworkDirectoryFacilityByNetworkDirectoryId | null;
  editNetworkDirectoryPhysician: INetworkDirectoryPhysicianByNetworkDirectoryId | null;
  addNetworkDirectoryPhysician: INetworkDirectoryPhysicianByNetworkDirectoryId | null;
  showDefaultNetwork: boolean;
  facilityPhysicianToggle: string;
}

const initialState: IState = {
  selectedPhysician: null,
  selectedFacility: null,
  selectedOffering: null,
  availableNetworks: [],
  selectedNetworkCode: null,
  selectedNetworkId: null,
  selectedNetworkDirectoryFacility: null,
  selectedNetworkDirectoryPhysician: null,
  selectedSearchFacility: [],
  selectedSearchOffering: [],
  selectedSearchPhysician: [],
  editNetworkDirectoryFacility: null,
  addNetworkDirectoryFacility: null,
  editNetworkDirectoryPhysician: null,
  addNetworkDirectoryPhysician: null,
  showDefaultNetwork: true,
  facilityPhysicianToggle: FacilityPhysicianToggle.FACILITY,
};

export const slice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    setSelectedPhysician: (
      state,
      action: PayloadAction<INetworkPhysicianTableRow>
    ) => {
      state.selectedPhysician = action.payload;
    },
    clearSelectedPhysician: (state) => {
      state.selectedPhysician = null;
    },
    setSelectedFacility: (
      state,
      action: PayloadAction<INetworkFacilityTableRow>
    ) => {
      state.selectedFacility = action.payload;
    },
    setSelectedOffering: (
      state,
      action: PayloadAction<INetworkOfferingsTableRow>
    ) => {
      state.selectedOffering = action.payload;
    },
    clearSelectedOffering: (state) => {
      state.selectedOffering = null;
    },
    clearSelectedFacility: (state) => {
      state.selectedFacility = null;
    },
    setSelectedNetworkDirectoryFacility: (
      state,
      action: PayloadAction<INetworkDirectoryFacilityTableRow>
    ) => {
      state.selectedNetworkDirectoryFacility = action.payload;
    },
    setEditNetworkDirectoryFacility: (state, action: PayloadAction<any>) => {
      state.editNetworkDirectoryFacility = action.payload;
    },
    setAddNetworkDirectoryFacility: (state, action: PayloadAction<any>) => {
      state.addNetworkDirectoryFacility = action.payload;
    },
    clearEditNetworkDirectoryFacility: (state) => {
      state.editNetworkDirectoryFacility = null;
    },
    clearAddNetworkDirectoryFacility: (state) => {
      state.addNetworkDirectoryFacility = null;
    },
    setEditNetworkDirectoryPhysician: (state, action: PayloadAction<any>) => {
      state.editNetworkDirectoryPhysician = action.payload;
    },
    clearEditNetworkDirectoryPhysician: (state) => {
      state.editNetworkDirectoryPhysician = null;
    },
    setAddNetworkDirectoryPhysician: (state, action: PayloadAction<any>) => {
      state.addNetworkDirectoryPhysician = action.payload;
    },
    clearAddNetworkDirectoryPhysician: (state) => {
      state.addNetworkDirectoryPhysician = null;
    },
    setSelectedNetworkDirectoryPhysician: (
      state,
      action: PayloadAction<INetworkDirectoryPhysicianTableRow>
    ) => {
      state.selectedNetworkDirectoryPhysician = action.payload;
    },
    setAvailableNetworks: (
      state,
      action: PayloadAction<ITenantNetworkTableRow[]>
    ) => {
      state.availableNetworks = action.payload;
    },
    setSelectedNetworkCode: (state, action: PayloadAction<string>) => {
      state.selectedNetworkCode = action.payload;
    },
    setSelectedSearchFacility: (
      state,
      action: PayloadAction<ISearchNetworkFacilityTableRow>
    ) => {
      state.selectedSearchFacility = [action.payload];
    },
    setSelectedSearchOffering: (
      state,
      action: PayloadAction<INetworkOfferingsTableRow>
    ) => {
      state.selectedSearchOffering = [action.payload];
    },
    clearSelectedSearchFacility: (state) => {
      state.selectedSearchFacility = [];
    },
    setSelectedSearchPhysician: (
      state,
      action: PayloadAction<ISearchNetworkPhysicianTableRow>
    ) => {
      state.selectedSearchPhysician = [action.payload];
    },
    setShowDefaultNetwork: (state, action: PayloadAction<boolean>) => {
      state.showDefaultNetwork = action.payload;
    },
    setFacilityPhysicianToggle: (state, action: PayloadAction<string>) => {
      state.facilityPhysicianToggle = action.payload;
    },
    clearSelectedSearchPhysician: (state) => {
      state.selectedSearchPhysician = [];
    },
  },
});

// Actions
export const {
  setSelectedPhysician,
  clearSelectedFacility,
  clearSelectedPhysician,
  clearSelectedOffering,
  setSelectedFacility,
  setSelectedOffering,
  setAvailableNetworks,
  setSelectedNetworkCode,
  setSelectedNetworkDirectoryFacility,
  setSelectedNetworkDirectoryPhysician,
  setSelectedSearchFacility,
  clearSelectedSearchFacility,
  setSelectedSearchOffering,
  setSelectedSearchPhysician,
  clearSelectedSearchPhysician,
  setFacilityPhysicianToggle,
  setShowDefaultNetwork,
  setEditNetworkDirectoryFacility,
  setEditNetworkDirectoryPhysician,
  clearEditNetworkDirectoryFacility,
  clearEditNetworkDirectoryPhysician,
  setAddNetworkDirectoryFacility,
  clearAddNetworkDirectoryFacility,
  setAddNetworkDirectoryPhysician,
  clearAddNetworkDirectoryPhysician,
} = slice.actions;

// Selectors
export const selectSelectedFacility = (state: RootState) =>
  state.network.selectedFacility;
export const selectSelectedOffering = (state: RootState) =>
  state.network.selectedOffering;
export const selectSelectedPhysician = (state: RootState) =>
  state.network.selectedPhysician;
export const selectAvailableNetworks = (state: RootState) =>
  state.network.availableNetworks;
export const selectSelectedNetworkCode = (state: RootState) =>
  state.network.selectedNetworkCode;
export const selectSelectedNetworkId = (state: RootState) =>
  state.network.selectedNetworkId;
export const selectSelectedNetworkDirectoryFacility = (state: RootState) =>
  state.network.selectedNetworkDirectoryFacility;
export const selectEditNetworkDirectoryFacility = (state: RootState) =>
  state.network.editNetworkDirectoryFacility;
export const selectAddNetworkDirectoryFacility = (state: RootState) =>
  state.network.addNetworkDirectoryFacility;
export const selectAddNetworkDirectoryPhysician = (state: RootState) =>
  state.network.addNetworkDirectoryPhysician;
export const selectEditNetworkDirectoryPhysician = (state: RootState) =>
  state.network.editNetworkDirectoryPhysician;
export const selectSelectedNetworkDirectoryPhysician = (state: RootState) =>
  state.network.selectedNetworkDirectoryPhysician;
export const selectedSearchFacility = (state: RootState) =>
  state.network.selectedSearchFacility;
export const selectedSearchOffering = (state: RootState) =>
  state.network.selectedSearchOffering;
export const selectedSearchPhysician = (state: RootState) =>
  state.network.selectedSearchPhysician;
export const showDefaultNetwork = (state: RootState) =>
  state.network.showDefaultNetwork;
export const selectFacilityPhysicianToggle = (state: RootState) =>
  state.network.facilityPhysicianToggle;

// Reducer
export default slice.reducer;
