import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import {
  faClose,
  faComment,
  faDownload,
  faEdit,
  faFile,
  faLink,
  faPaperclip,
  faSave,
  faShareNodes,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Box,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { EllipseMenu, EllipseMenuItem, LoadingIndicator } from 'common';
import LoadingDots from 'common/LoadingDots';
import config from 'config';
import commonConstants from 'constants/common';
import toastMessageConstant from 'constants/toastMessage';
import { ShareMode } from 'enums/common';
import { DocumentType, DocumentVisibility } from 'enums/documents';
import ModalKey from 'enums/ModalKey';
import { useCloneCasePreviewConfig } from 'features/case-layout-new/hooks/useCloneCasePreviewConfig';
import useFileUploadLists from 'features/case-layout-new/hooks/useFileUploadLists';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { useCheckUserIsTenantAdmin } from 'hooks/useCheckUserIsTenantAdmin';
import { IDocument } from 'interfaces/documents';
import { useSnackbar } from 'notistack';
import { useSetFileVisibilityMutation } from 'services/cases';
import {
  useAttachDocumentMutation,
  useDeleteDocumentMutation,
  useRenameDocumentMutation,
} from 'services/documents';
import { useFileDownloadMutation } from 'services/files';
import {
  changeDocuments,
  selectCurrentReferralCase,
  selectCurrentReferralCaseDocuments,
  selectCurrentReferralCaseReferenceFile,
} from 'stores/cases';
import { useConfirmationModal } from 'stores/ConfirmationModal';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import {
  downloadFromURI,
  getFileNameWithExtension,
  getFilenameWithoutExtension,
  isBrowserSupportedFile,
} from 'utils/file';
import { UTCToLocalDateViewFormat } from 'utils/moment';

interface IFileList {
  data: IDocument;
  isDownloadAccessEnabled?: boolean;
  isFileReadOnly?: boolean;
}

const FileList = ({
  data,
  isDownloadAccessEnabled,
  isFileReadOnly,
}: IFileList) => {
  const [isRename, setIsRename] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const theme = useTheme();
  const isUserClient = useCheckUserIsClient();
  const isUserTenantAdmin = useCheckUserIsTenantAdmin();
  const isDocumentTypeURI = data.type === DocumentType.URI;

  const [searchParams] = useSearchParams();
  const fileId = searchParams.get('fileId'); // mostly used to implement into public note page

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const renameDocumentMutation = useRenameDocumentMutation();

  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const currentReferralCaseDocuments = useAppSelector(
    selectCurrentReferralCaseDocuments
  );
  const activeFileId = useAppSelector(selectCurrentReferralCaseReferenceFile);

  const attachDocumentMutation = useAttachDocumentMutation();

  const deleteConfirmationModal = useConfirmationModal();

  const {
    isFileCreatedByCurrentUser,
    isOwnerTenantAdmin,
    isFileCreatedInCurrentTenant,
    showFileActionType,
    isReadOnly,
  } = useFileUploadLists({
    data,
    isDownloadAccessEnabled,
  });
  const { isClonedCasePrevFilePreview } = useCloneCasePreviewConfig();

  const fileDownloadMutation = useFileDownloadMutation();
  const deleteDocumentMutation = useDeleteDocumentMutation();
  const setVisibilityMutation = useSetFileVisibilityMutation();

  const handleRename = () => {
    if (!name.trim()) return;
    renameDocumentMutation.mutate(
      {
        documentId: data.documentId,
        associationId: currentReferralCase!.caseId,
        isNotListView: true,
        data: {
          name: isDocumentTypeURI
            ? name.trim()
            : getFileNameWithExtension(data.name, name.trim()),
        },
      },
      {
        onSettled: () => {
          setIsRename(false);
        },
      }
    );
  };

  const onDownloadClick = (file: IDocument, view: string) => {
    fileDownloadMutation.mutate({
      filepath: file.path,
      name: file.name,
      download: { view },
    });
  };

  const DownloadFile = (downloadOptions: string) => {
    onDownloadClick(data, downloadOptions);
  };

  const handleURIError = () => {
    enqueueSnackbar(toastMessageConstant.DOCUMENT.DOWNLOAD_UNSUCCESS, {
      variant: 'error',
    });
  };

  const handleDownload = async (type: string) => {
    if (isDocumentTypeURI) {
      if (type === 'view') {
        window.open(data.path, '_blank', 'noreferrer');
      } else {
        await downloadFromURI({
          name: data.name,
          path: data.path,
          handleError: handleURIError,
        });
      }
    } else {
      const d = {
        status: true,
        view: type,
      };
      DownloadFile(d.view);
    }
  };

  const handleDelete = async () => {
    // await because result depends on confirmation model action
    const result = await deleteConfirmationModal?.openConfirmationModal({
      isAdditionalContentRequired: false,
      title: `Delete File`,
      content: (
        <Typography component="span">
          Are you sure you want to delete this
          <Typography
            component="span"
            sx={{ fontWeight: (t) => t.typography.fontWeightMedium }}
          >
            {' '}
            {data.name}{' '}
          </Typography>
          file?
        </Typography>
      ),
    });
    if (result) {
      deleteConfirmationModal?.changeSubmittingStatus(true);
      deleteDocumentMutation.mutate(
        {
          associationId: currentReferralCase!.caseId,
          documentId: data.documentId,
          type: commonConstants.REFERRAL_CASE,
        },
        {
          onSettled: () => {
            deleteConfirmationModal?.changeSubmittingStatus(false);
          },
        }
      );
    }
  };

  const handleAddToProfile = async () => {
    // await because result depends on confirmation model action
    const result = await deleteConfirmationModal?.openConfirmationModal({
      title: `Add To Profile`,
      content: (
        <Typography component="span">
          Do you want to add this
          <Typography
            component="span"
            sx={{ fontWeight: (t) => t.typography.fontWeightMedium }}
          >
            {' '}
            {data.name}{' '}
          </Typography>
          file?
        </Typography>
      ),
    });
    if (result) {
      deleteConfirmationModal?.changeSubmittingStatus(true);

      if (!currentReferralCase?.patient?.id) return;
      const patientId = currentReferralCase.patient.id!;
      const payload = {
        documentId: data.documentId,
        associationId: patientId,
        type: commonConstants.CLIENT,
      };
      attachDocumentMutation.mutate(
        {
          data: [payload],
          documents: [data],
          associationId: patientId,
        },

        {
          onSuccess(res) {
            if (!currentReferralCaseDocuments) return;

            const updatedDocuments = currentReferralCaseDocuments?.map((e) => {
              const index = res.data?.findIndex(
                (obj) => obj.documentId === e.documentId
              );

              if (index === -1) {
                return e;
              }
              return {
                ...e,
                associations: [res.data[index], ...e.associations],
              };
            });

            dispatch(changeDocuments(updatedDocuments));
          },
          onSettled: () => {
            deleteConfirmationModal?.changeSubmittingStatus(false);
          },
        }
      );
    }
  };
  const [isClientFileExists, setIsClientFileExists] = useState(false);

  const isSaveDisabled = () => {
    if (isDocumentTypeURI) {
      return data.name !== name && name.length;
    }
    return getFilenameWithoutExtension(data.name) !== name && name.length;
  };
  const extensionTrimmedName = () => {
    setName(
      isDocumentTypeURI ? data.name : getFilenameWithoutExtension(data.name)
    );
  };
  const onRenameClick = () => {
    extensionTrimmedName();
    setIsRename(true);
  };
  const onShareClick = () => {
    const encodedURI = encodeURI(
      `modal?type=${ModalKey.SHARE_FILE_MODAL}&caseId=${currentReferralCase?.caseId}&documentId=${data.documentId}&mode=${ShareMode.FILE}&fileName=${data.name}`
    );
    navigate(encodedURI);
  };

  const handleClose = () => {
    setName(data.name);
    setIsRename(false);
  };

  const handleChangeVisibility = ({
    file,
    caseId,
    visibility,
  }: {
    file: IDocument;
    caseId: string;
    visibility: DocumentVisibility;
  }) => {
    setVisibilityMutation.mutate({
      documentId: file.documentId,
      caseId,
      data: {
        associationId: caseId,
        visibility,
      },
    });
  };

  useEffect(() => {
    const isExists = data.associations?.some(
      (e) => e.type === commonConstants.CLIENT
    );
    setIsClientFileExists(isExists);
  }, [data.associations]);

  const isVisibilityPublic =
    data.associations?.find((item) => item.id === currentReferralCase?.caseId)
      ?.visibility === DocumentVisibility.PUBLIC;

  const hasAccessToDelete =
    isOwnerTenantAdmin ||
    isFileCreatedByCurrentUser ||
    (isFileCreatedInCurrentTenant && isUserTenantAdmin);

  const hasAccessToShareToPatient = !isUserClient;

  const downloadOption = (
    <EllipseMenuItem
      dataCy="case-files-download"
      iconElement={
        !fileDownloadMutation.isLoading ? (
          <FontAwesomeIcon icon={faDownload} size="xs" />
        ) : (
          <LoadingIndicator containerHeight="15px" size="1rem" />
        )
      }
      onClick={() => handleDownload('download')}
      text="Download"
    />
  );

  const viewOption = (
    <EllipseMenuItem
      dataCy="case-files-view"
      iconElement={<FontAwesomeIcon icon={faEye} size="xs" />}
      onClick={() => handleDownload('view')}
      text="View"
    />
  );

  const isFileVisibilityStatusVisible =
    isOwnerTenantAdmin && isVisibilityPublic;

  return (
    <Box
      bgcolor={activeFileId === data.documentId ? 'gray.lighter' : 'auto'}
      display="flex"
      justifyContent="flex-start"
      paddingY={1}
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        px: 1,
        pointerEvents: fileDownloadMutation.isLoading ? 'none' : 'auto',
        opacity: fileDownloadMutation.isLoading ? 0.5 : 1,
      }}
      width="100%"
    >
      <FontAwesomeIcon
        icon={isDocumentTypeURI ? faLink : faFile}
        size="lg"
        style={{ color: theme.palette.primary.main, marginRight: 10 }}
      />
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        justifyContent="flex-start"
        sx={{
          '&:hover': {
            '.text-file-glow': {
              color: (t) => t.palette.secondary.main,
              cursor: 'pointer',
            },
          },
        }}
        width="85%"
      >
        {isRename ? (
          <Box alignItems="center" display="flex">
            <TextField
              fullWidth
              inputProps={{ 'data-cy': 'case-file-rename-input' }}
              name="name"
              onChange={(e) => {
                setName(e.target.value);
              }}
              size="small"
              value={name}
              variant="standard"
            />
            <Box alignItems="center" display="flex">
              {isSaveDisabled() ? (
                <IconButton
                  data-cy="case-file-rename-save"
                  onClick={handleRename}
                >
                  <FontAwesomeIcon icon={faSave} size="xs" />
                </IconButton>
              ) : (
                ''
              )}
              <IconButton
                data-cy="case-file-rename-cancel"
                onClick={handleClose}
              >
                <FontAwesomeIcon icon={faClose} size="xs" />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Box alignItems="center" display="flex" gap={1}>
            <Tooltip title={data.name}>
              <Typography
                className="text-file-glow text-overflow-ellipses"
                gutterBottom={false}
                onClick={() => {
                  handleDownload(
                    isBrowserSupportedFile(data.path, data.type)
                      ? 'view'
                      : 'download'
                  );
                }}
                sx={{ fontWeight: (t) => t.typography.fontWeightMedium }}
                variant="body2"
              >
                <Box display="flex">
                  {data.name}
                  {fileDownloadMutation.isLoading && (
                    <LoadingDots padding={1} pl={3} />
                  )}
                </Box>
              </Typography>
            </Tooltip>
            {isFileVisibilityStatusVisible && (
              <Tooltip
                arrow
                placement="top"
                title={
                  isVisibilityPublic
                    ? 'Visibility set to public'
                    : 'Visibility set to private'
                }
              >
                <span>
                  <Box
                    color="text.secondary"
                    component={FontAwesomeIcon}
                    icon={isVisibilityPublic ? faEye : faEyeSlash}
                  />
                </span>
              </Tooltip>
            )}
          </Box>
        )}
        <Typography
          component="p"
          gutterBottom={false}
          sx={{
            overflowWrap: 'break-word !important',
          }}
          variant="body2"
        >
          {`${data?.createdBy?.name} on ${UTCToLocalDateViewFormat(
            data.createdAt as string,
            config.fullDateViewFormat
          )}`}
        </Typography>
        {data?.extraNote && (
          <Alert
            icon={<FontAwesomeIcon icon={faComment} size="xs" />}
            severity="info"
            sx={{
              textAlign: 'left',
            }}
          >
            {data.extraNote}
          </Alert>
        )}
      </Box>
      {isDownloadAccessEnabled && fileId ? (
        <Box display="flex" justifyContent="flex-end" width="10%">
          <Box>
            <EllipseMenu dataCy="case-files-downloadOptions">
              {data.type === DocumentType.URI ? viewOption : downloadOption}
            </EllipseMenu>
          </Box>
        </Box>
      ) : (
        showFileActionType() &&
        !isClonedCasePrevFilePreview && (
          <Box display="flex" justifyContent="flex-end" width="10%">
            <Box>
              <EllipseMenu dataCy="case-files">
                {!isClientFileExists &&
                  !isReadOnly &&
                  currentReferralCase?.patient?.id && (
                    <EllipseMenuItem
                      dataCy="case-files-addToProfile"
                      iconElement={
                        <FontAwesomeIcon icon={faPaperclip} size="xs" />
                      }
                      onClick={handleAddToProfile}
                      text="Add to Profile"
                    />
                  )}
                {data.type === DocumentType.URI ? viewOption : downloadOption}
                {hasAccessToShareToPatient && !isFileReadOnly && (
                  <EllipseMenuItem
                    dataCy="case-files-share"
                    iconElement={
                      <FontAwesomeIcon icon={faShareNodes} size="xs" />
                    }
                    onClick={onShareClick}
                    text="Share"
                  />
                )}
                {hasAccessToDelete && !isFileReadOnly && (
                  <EllipseMenuItem
                    dataCy="case-files-rename"
                    iconElement={<FontAwesomeIcon icon={faEdit} size="xs" />}
                    onClick={onRenameClick}
                    text="Rename"
                  />
                )}
                {isOwnerTenantAdmin && (
                  <EllipseMenuItem
                    dataCy="case-files-change-visibility"
                    disabled={setVisibilityMutation.isLoading}
                    iconElement={
                      <FontAwesomeIcon
                        icon={isVisibilityPublic ? faEyeSlash : faEye}
                        size="xs"
                      />
                    }
                    onClick={() =>
                      handleChangeVisibility({
                        file: data,
                        caseId: currentReferralCase?.caseId || '',
                        visibility: isVisibilityPublic
                          ? DocumentVisibility.PRIVATE
                          : DocumentVisibility.PUBLIC,
                      })
                    }
                    text={
                      isVisibilityPublic ? 'Set as private' : 'Set as public'
                    }
                  />
                )}
                {hasAccessToDelete && !isFileReadOnly && (
                  <EllipseMenuItem
                    dataCy="case-files-delete"
                    iconElement={
                      <FontAwesomeIcon icon={faTrashAlt} size="xs" />
                    }
                    onClick={handleDelete}
                    text="Delete"
                  />
                )}
              </EllipseMenu>
            </Box>
          </Box>
        )
      )}
    </Box>
  );
};

FileList.defaultProps = {
  isDownloadAccessEnabled: false,
  isFileReadOnly: false,
};
export default FileList;
