import { useMemo } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { NoteVisibility } from 'enums/case';
import { useCheckCaseReadOnlyView } from 'features/case-layout-new/hooks/useCheckCaseReadOnlyView';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { useCheckUserIsEmail } from 'hooks/useCheckUserisEmail';
import { useCheckUserIsTenantAdmin } from 'hooks/useCheckUserIsTenantAdmin';
import { IReferralNote } from 'interfaces/cases';
import { selectAuthTenantData } from 'stores/auth';
import {
  changeNotes,
  selectCurrentReferralCase,
  selectCurrentReferralCaseNoteBoxExpanded,
  selectCurrentReferralCaseReference,
} from 'stores/cases';
import { useAppDispatch, useAppSelector } from 'stores/hooks';

import NoteList from './NoteList';

const NoteLists = ({
  noteBoxWidth,
  drawerView,
}: {
  noteBoxWidth: number;
  drawerView: boolean | undefined;
}) => {
  const dispatch = useAppDispatch();

  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const isNoteBoxExpanded = useAppSelector(
    selectCurrentReferralCaseNoteBoxExpanded
  );
  const activeNote = useAppSelector(selectCurrentReferralCaseReference);
  const authTenantData = useAppSelector(selectAuthTenantData);

  const { isReadOnly } = useCheckCaseReadOnlyView({
    includePatientCheck: true,
  });
  const isUserAdmin = useCheckUserIsTenantAdmin();
  const isUserEmail = useCheckUserIsEmail();
  const isUserClient = useCheckUserIsClient();

  const loggedInTenantId = authTenantData?.tenantAssociation?.tenantId;
  const loggedInUserId = authTenantData?.userId;
  const loggedInUserEmail = authTenantData?.email;

  const getNoteHeight = () => {
    if (isNoteBoxExpanded && drawerView) return '512px';
    if (isNoteBoxExpanded) return '580px';
    if (drawerView) return '250px';
    return '320px';
  };

  const handleEditMode = (editData: IReferralNote) => {
    dispatch(
      changeNotes({
        text: editData.note,
        editMode: { status: true, data: editData },
      })
    );
  };

  const filteredCaseNotes = useMemo(() => {
    const caseNotes = currentReferralCase?.notes || [];

    /**
     * Scenario: For admin users of invitee partner/tenant
     */
    if (isReadOnly && isUserAdmin) {
      return (
        caseNotes.filter(
          (note) =>
            note.accessType === NoteVisibility.PUBLIC ||
            note.createdBy?.tenantId === loggedInTenantId ||
            note.tagged?.some((tagged) => tagged.tenantId === loggedInTenantId)
        ) || []
      );
    }

    /**
     * Scenario: client/patient login
     */
    if (isUserClient) {
      return (
        caseNotes.filter(
          (note) =>
            note.accessType === NoteVisibility.PUBLIC ||
            note.createdBy?.id === loggedInUserId ||
            note.tagged?.some(
              (tagged) =>
                tagged.id?.trim().toLowerCase() ===
                loggedInUserEmail?.trim()?.toLowerCase()
            )
        ) || []
      );
    }

    /**
     * Scenario: For third party invitee
     */
    if (isReadOnly && isUserEmail) {
      return (
        caseNotes.filter(
          (note) =>
            note.accessType === NoteVisibility.PUBLIC ||
            note.createdBy?.email?.trim()?.toLowerCase() ===
              loggedInUserEmail?.trim()?.toLowerCase() ||
            note.tagged?.some(
              (tagged) =>
                tagged.id?.toLowerCase() === loggedInUserEmail?.toLowerCase()
            )
        ) || []
      );
    }

    /**
     * Just a double check to hide 'HIDDEN' notes if the above scenarios don't cover the case.
     */
    if (!isUserAdmin) {
      return (
        caseNotes.filter((note) => note.accessType === NoteVisibility.PUBLIC) ||
        []
      );
    }

    /**
     * Scenario: Owner tenant (in which the case is created)
     */
    return caseNotes || [];
  }, [
    currentReferralCase?.notes,
    isUserAdmin,
    isReadOnly,
    isUserEmail,
    isUserClient,
    loggedInTenantId,
    loggedInUserId,
    loggedInUserEmail,
  ]);

  if (!noteBoxWidth) return <> </>;

  return (
    <Stack
      className="scrollbar"
      p={1}
      sx={{
        width: `${noteBoxWidth}px`,
        height: `calc(100vh - ${getNoteHeight()})`,
        overflow: 'hidden auto',
      }}
    >
      {filteredCaseNotes?.length ? (
        filteredCaseNotes.map((note: IReferralNote) => {
          let isActive = false;
          if (note.id === activeNote) isActive = true;
          return (
            <NoteList
              data={note}
              handleEditMode={handleEditMode}
              isActive={isActive}
              key={note.id}
            />
          );
        })
      ) : (
        <Box p={2}>
          <Typography variant="body2">No messages</Typography>
        </Box>
      )}
    </Stack>
  );
};

export default NoteLists;
