export enum TransactionSortBy {
  NAME = 'name',
  STATUS = 'status',
}

export enum TransactionStatus {
  SUCCEEDED = 'Succeeded',
  FAILED = 'Failed',
  REFUNDED = 'Refunded',
  PROCESSING = 'Processing',
}

export enum AddTransactionType {
  MEMBERSHIP = 'Membership',
  ANCILLARY = 'Ancillary',
}

export enum PaymentSummaryID {
  OTHER = 'Other',
}

export enum Action {
  ADD_SERVICE = 'AddService',
}

export enum RefundTypeCode {
  FULL_REFUND = 'FULL_REFUND',
  PARTIAL_REFUND = 'PARTIAL_REFUND',
}

export enum TransactionType {
  REFUND = 'Refund',
  TRANSACTION = 'Transaction',
}

export enum BillingStatus {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
}

export enum AdaptedTransactionStatus {
  PROCESSING = TransactionStatus.PROCESSING,
  SUCCEEDED = TransactionStatus.SUCCEEDED,
  FAILED = TransactionStatus.FAILED,
  REFUND_IN_PROGRESS = 'Refund in progress',
  REFUND_FAILED = 'Refund failed',
  REFUNDED = 'Refunded',
}

export enum TransactionAlertStatus {
  SUCCEEDED = 'Succeeded',
  FAILED = 'Failed',
}
