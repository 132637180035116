import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Typography } from '@mui/material';
import { IAdaptedTenantMembershipOfferingTableRow } from 'interfaces/tenant-management/tenant/tenantMembershipOffering';

import { useGetCurrentSelectedClientAndMembershipDetails } from '../hooks/useGetCurrentSelectedClientAndMembershipDetails';
import { useOptionsListItem } from '../hooks/useOptionsListItem';

export const OptionsListItem = ({
  item,
  handleSelectedMemberOption,
}: {
  item: IAdaptedTenantMembershipOfferingTableRow;
  handleSelectedMemberOption: (
    e: IAdaptedTenantMembershipOfferingTableRow
  ) => void;
}) => {
  const { selectedOffering, membership } =
    useGetCurrentSelectedClientAndMembershipDetails();

  const preSelectedOfferingId =
    selectedOffering?.tenantOfferingId || membership?.offerings?.id || '';

  const { itemRef, offeringId } = useOptionsListItem(
    item,
    preSelectedOfferingId
  );

  const isSelectedAlready =
    selectedOffering?.tenantOfferingId === item.tenantOfferingId;
  const isRecommended = item?.isRecommended;

  return (
    <Grid
      container
      data-cy={`client-modifyMembership-membershipOfferingList-${item.name}`}
      display="flex"
      justifyContent="space-between"
      key={item.id}
      onClick={() => !isSelectedAlready && handleSelectedMemberOption(item)}
      ref={itemRef}
      sx={{
        border: (t) =>
          `1px solid ${
            offeringId === item.tenantOfferingId
              ? t.palette.primary.main
              : t.palette.gray.main
          }`,
        paddingY: '0.3rem',
        borderRadius: '3px',
        cursor: 'pointer',
        marginBottom: '0.5rem',
        ...(offeringId === item.tenantOfferingId && {
          backgroundColor: (t) => t.palette.primary.lighter,
        }),
      }}
    >
      <Grid item position="relative" px={3} xs={8}>
        <Typography color="text.primary" display="inline" variant="body2">
          {offeringId === item.tenantOfferingId && (
            <Box
              color="primary.main"
              component={FontAwesomeIcon}
              icon={faCircleCheck}
              left={6}
              position="absolute"
              top={6}
            />
          )}

          <Typography
            data-cy="membership-name"
            fontWeight="medium"
            gutterBottom={false}
            sx={{ display: 'inline' }}
            variant="body2"
          >
            {item?.name}{' '}
          </Typography>
          {isRecommended && (
            <Typography
              color="primary.main"
              data-cy="membership-recommended"
              display="inline"
              fontWeight="medium"
              gutterBottom={false}
              variant="body2"
            >
              (Recommended)
            </Typography>
          )}
        </Typography>
      </Grid>
      <Grid item paddingRight={1} textAlign="right" xs={4}>
        <Typography color="primary.main" display="block" variant="body2">
          {item.price}{' '}
          <Typography color="text.primary" display="inline" variant="body2">
            {item.formattedBillingType} {item.formattedBillingInterval}
          </Typography>
        </Typography>
        {item.registrationFee && (
          <Typography
            color="gray.dark"
            display="block"
            gutterBottom={false}
            variant="caption"
          >
            {item.registrationFee} registration fee
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
