import { Controller, useFormContext } from 'react-hook-form';

import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormGroup,
} from '@mui/material';

type Props = {
  name: string;
  label: string | JSX.Element;
  sizeSmall?: boolean;
} & CheckboxProps;

const FormCheckbox = ({
  name,
  label,
  sizeSmall,
  onChange,
  ...others
}: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormGroup sx={{ display: 'inline-block' }}>
          <FormControlLabel
            control={
              <Checkbox
                {...others}
                {...field}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                  if (onChange !== undefined) {
                    onChange(e, e.target.checked);
                  }
                }}
              />
            }
            label={label}
            sx={{
              '& .MuiTypography-root': {
                marginBottom: 0,
                // TODO: In Future we need to refactor this hardcoded value for fontSize
                fontSize: sizeSmall ? '12px' : '14px',
                textWrap: 'nowrap',
              },
            }}
          />
        </FormGroup>
      )}
    />
  );
};

FormCheckbox.defaultProps = {
  sizeSmall: false,
};

export default FormCheckbox;
