import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';
import uiRoutes from 'constants/uiRoutes';
import {
  ChangeMembershipActionType,
  ClientEnrollmentStatus,
} from 'enums/client-management';
import ModalKey from 'enums/ModalKey';
import {
  EnrollmentCode,
  TenantSettingStatus,
} from 'enums/tenant-management/tenant';
import { useCheckUserIsClientIndividual } from 'hooks/useCheckUserIsClientIndividual';
import { useGetEnrollmentTenantSettings } from 'hooks/useGetEnrollmentTenantSettings';
import { selectAuthUserClient } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

// TODO: Buy Membership modal is hardcoded for following routes.
// In order to make the route level modal work for every route, we need to make a wrapper page component
// that includes <Outlet /> and <ModalSwitch /> components
const BUY_MEMBERSHIP_ENABLED_ROUTES = [
  uiRoutes.dashboard,
  uiRoutes.individuals,
  uiRoutes.billings.transactions,
  uiRoutes.billings.upcoming,
  uiRoutes.cases.detail,
  uiRoutes.comms.inbox,
  uiRoutes.comms.outbox,
];

export const BuyMembershipButton = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isClientIndividual = useCheckUserIsClientIndividual();
  const userData = useAppSelector(selectAuthUserClient);

  const isModifyMembershipEnabledForClient =
    useGetEnrollmentTenantSettings(
      EnrollmentCode.MODIFY_MEMBERSHIP_OPTION_FOR_CLIENT
    ) === TenantSettingStatus.ENABLED;

  const isApprovedClientIndividual =
    isClientIndividual &&
    userData?.membership?.status === ClientEnrollmentStatus.APPROVED;

  const onBuyMembershipClick = () => {
    const status = userData?.membership?.status;

    navigate(
      `${location.pathname}/modal?type=${ModalKey.CHANGE_MEMBERSHIP}&clientId=${userData?.clientId}&status=${status}&clientEnrollmentId=${userData?.clientEnrollmentId}&step=${ChangeMembershipActionType.ADD}`
    );
  };

  if (
    isApprovedClientIndividual &&
    isModifyMembershipEnabledForClient &&
    BUY_MEMBERSHIP_ENABLED_ROUTES.includes(location.pathname)
  ) {
    return (
      <Button
        data-cy="buy-membership-button-on-the-header"
        onClick={onBuyMembershipClick}
        size="small"
        variant="outlined"
      >
        Buy More
      </Button>
    );
  }

  return null;
};
