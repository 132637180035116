import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { DialogContent } from '@mui/material';
import { LoadingIndicator, ModalFooter } from 'common';
import { BloodTypeVerified, ClientType } from 'enums/client-management';
import { ManageMembershipActionType } from 'enums/client-management/manage-membership';
import { CountryCodes } from 'enums/country';
import { EnrollmentCode } from 'enums/tenant-management/tenant';
import { useCheckUserIsAgent } from 'hooks/useCheckUserIsAgent';
import {
  IAdaptedClientGroup,
  IAdaptedClientIndividual,
  IAddClientForm,
  IClient,
} from 'interfaces/client-management';
import { IResponse } from 'interfaces/http';
import { useGetAddClientSchema } from 'schema/client-management';
import {
  useAddClientMutation,
  useEditClientMutation,
} from 'services/client-management';
import { selectAuthTenantAssociation, selectAuthTenantData } from 'stores/auth';
import {
  selectEditClientData,
  selectParentClient,
  setEditClientData,
} from 'stores/client-management';
import {
  setManageMembershipActionType,
  setManageMembershipClient,
} from 'stores/client-management/manage-membership';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import {
  adaptClient,
  formatClientDependentSavePayload,
  formatClientGroupMemberSavePayload,
  formatClientGroupSavePayload,
  formatClientPrimarySavePayload,
  getClientSpecificTenantId,
  getClientTypeFromClient,
  getLocationSpecificTenantId,
} from 'utils/client-management';
import { getContactNumbers } from 'utils/common';
import { checkIsEnrollmentSettingEnabled } from 'utils/tenantSetting';

import DependentForm from './DependentForm';
import GroupForm from './GroupForm';
import GroupMemberForm from './GroupMemberForm';
import PrimaryForm from './PrimaryForm';
import SelectClientType from './SelectClientType';

const getDefaultValues = (tenantId: string) =>
  ({
    affiliate: {
      id: '',
      name: '',
      type: '',
    },
    clientType: '',
    type: '',
    businessName: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    note: '',
    memberId: '',
    lastSSN: '',
    dob: '',
    gender: '',
    tenantId,
    groupId: '',
    groupName: '',
    groupEnrollmentId: '',
    primaryId: '',
    primaryName: '',
    primaryEnrollmentId: '',
    relationship: '',
    isBloodTypeVerified: false,
    bloodGroup: '',
    sponsorId: '',
    tags: undefined,
    textEnabled: false,
    isVip: false,

    phone: '',
    phoneExt: '1',
    countryCode: CountryCodes.USA,

    home: '',
    homeExt: '1',
    hCode: CountryCodes.USA,

    work: '',
    workExt: '1',
    wCode: CountryCodes.USA,
  } as const);

interface IProps {
  isEditMode: boolean;
  loading: boolean;
  handleCancelButtonClick: VoidFunction;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}

const Information = ({
  isEditMode,
  loading,
  handleCancelButtonClick,
  setActiveTab,
}: IProps): JSX.Element => {
  const editClientData = useAppSelector(selectEditClientData);
  const parentClientData = useAppSelector(selectParentClient);
  const authTenantData = useAppSelector(selectAuthTenantData);

  // TODO: Need to check if `editClientData` is loaded or not
  // Reason: tenantId is not kept in the query key so, if the authTenantId is set before editClientData?.membership?.tenantId
  // is loaded, it won't cause a refetch after editClientData?.membership?.tenantId is loaded.
  const clientSpecificTenantId = editClientData
    ? getClientSpecificTenantId(editClientData)
    : '';

  const locationSpecificTenantId = getLocationSpecificTenantId();

  const activeTenantId = isEditMode
    ? clientSpecificTenantId
    : locationSpecificTenantId;
  const clientEnrollmentId = editClientData?.clientEnrollmentId;

  const clientSchema = useGetAddClientSchema();

  const methods = useForm<IAddClientForm>({
    resolver: yupResolver(clientSchema),
    defaultValues: getDefaultValues(activeTenantId),
  });

  const { watch, reset, setValue } = methods;

  const isSponsorIdEnabled = checkIsEnrollmentSettingEnabled(
    EnrollmentCode.SPONSOR_ID
  );
  const [searchParams] = useSearchParams();
  const clientTypeFromParams = searchParams.get('clientType');
  const parentIdFromParams = searchParams.get('parentId');
  const parentNameFromParams = searchParams.get('parentName');
  const parentEnrollmentIdParams = searchParams.get('parentClientEnrollmentId');
  const groupIdFromParams = searchParams.get('groupId');
  const groupEnrollmentIdFromParams = searchParams.get('groupEnrollmentId');
  const groupNameFromParams = searchParams.get('groupName');
  const clientRelationshipType = searchParams.get('clientRelationship');

  const hideClientType = searchParams.get('hideClientType');

  const dispatch = useAppDispatch();

  const addClientMutation = useAddClientMutation();
  const editClientMutation = useEditClientMutation();

  const isClientAgent = useCheckUserIsAgent();
  const authTenantAssociation = useAppSelector(selectAuthTenantAssociation);
  const clientType = watch('clientType') as any;

  const onSubmit = (submitData: IAddClientForm) => {
    let payload;
    const parentClientId = parentClientData?.tenantId;
    const tenantGroupCode = authTenantData?.tenantAssociation?.groupCode || '';
    const clientId = editClientData?.clientId;

    switch (clientType) {
      case ClientType.GROUP:
        payload = formatClientGroupSavePayload(submitData, {
          tenantId: activeTenantId,
          tenantGroupCode,
          clientId,
          clientEnrollmentId,
        });
        break;

      case ClientType.GROUP_MEMBER: {
        payload = formatClientGroupMemberSavePayload(submitData, {
          tenantId: parentClientId!,
          tenantGroupCode,
          clientId,
          clientEnrollmentId,
        });
        break;
      }

      case ClientType.PRIMARY:
        payload = formatClientPrimarySavePayload(submitData, {
          tenantId: activeTenantId,
          tenantGroupCode,
          clientId,
          clientEnrollmentId,
        });
        break;

      case ClientType.DEPENDENT:
        payload = formatClientDependentSavePayload(submitData, {
          tenantId: parentClientId!,
          tenantGroupCode,
          clientId,
          clientEnrollmentId,
        });
        break;

      default:
        return;
    }

    if (isEditMode) {
      editClientMutation.mutate(
        { clientId: editClientData!.clientId!, data: payload },
        {
          onSuccess: (res) => {
            const adaptedRes = adaptClient(res as IResponse<IClient>);
            dispatch(setEditClientData(adaptedRes.data));
            setActiveTab((prevState) => prevState + 1);

            // to populate the client data in the manage membership with edit mode
            dispatch(setManageMembershipClient(adaptedRes.data));
            dispatch(
              setManageMembershipActionType(
                ManageMembershipActionType.EDIT_MEMBERSHIP
              )
            );
          },
        }
      );

      return;
    }

    addClientMutation.mutate(
      { data: payload },
      {
        onSuccess: (res) => {
          const adaptedRes = adaptClient(res as IResponse<IClient>);
          dispatch(setEditClientData(adaptedRes.data));
          setActiveTab((prevState) => prevState + 1);

          // to populate the client data in the manage membership with edit mode
          dispatch(setManageMembershipClient(adaptedRes.data));
          dispatch(
            setManageMembershipActionType(
              ManageMembershipActionType.EDIT_MEMBERSHIP
            )
          );
        },
      }
    );
  };

  useEffect(() => {
    if (isClientAgent) {
      methods.setValue('referredBy', {
        id: authTenantAssociation?.referenceId || '',
        name: authTenantData?.name || '',
      });
    }

    if (editClientData) {
      const type = getClientTypeFromClient(editClientData);
      const isGroup = type === ClientType.GROUP;
      const isGroupMember = type === ClientType.GROUP_MEMBER;
      const isDependent = type === ClientType.DEPENDENT;

      const phones: any = getContactNumbers(editClientData.phones ?? []);
      const firstName = isGroup
        ? (editClientData as IAdaptedClientGroup).contactFirstName
        : (editClientData as IAdaptedClientIndividual).firstName;
      const middleName = isGroup
        ? (editClientData as IAdaptedClientGroup).contactMiddleName
        : (editClientData as IAdaptedClientIndividual).middleName;
      const lastName = isGroup
        ? (editClientData as IAdaptedClientGroup).contactLastName
        : (editClientData as IAdaptedClientIndividual).lastName;

      reset({
        referredBy: {
          id: (editClientData as IAdaptedClientGroup)?.affiliate?.id ?? '',
          name: (editClientData as IAdaptedClientGroup)?.affiliate?.name ?? '',
        },
        clientType: type ?? '',
        type: editClientData.type ?? '',
        businessName:
          (editClientData as IAdaptedClientGroup).businessName ?? '',
        firstName: firstName ?? '',
        middleName: middleName ?? '',
        lastName: lastName ?? '',
        email: editClientData.email ?? '',
        // phone: editClientData.phone ?? '',
        ...phones,
        addressLine1: editClientData.address?.addressLine1 ?? '',
        addressLine2: editClientData.address?.addressLine2 ?? '',
        city: editClientData.address?.city ?? '',
        state: editClientData.address?.state ?? '',
        zip: editClientData.address?.zip ?? '',
        lat: editClientData.address?.lat ?? '',
        lng: editClientData.address?.lng ?? '',
        note: editClientData.note ?? '',
        memberId: editClientData.memberId ?? '',
        lastSSN: (editClientData as IAdaptedClientIndividual).lastSSN ?? '',
        dob: (editClientData as IAdaptedClientIndividual).unformattedDob ?? '',
        gender: (editClientData as IAdaptedClientIndividual).gender ?? '',
        // parentId is stored in groupId for group member
        groupId: isGroupMember
          ? (editClientData as IAdaptedClientGroup).relationship?.groupId
          : '',
        groupEnrollmentId: isGroupMember
          ? (editClientData as IAdaptedClientGroup).clientEnrollmentId
          : '',
        // parentId is stored in groupId for dependent
        primaryId: isDependent
          ? (editClientData as IAdaptedClientIndividual)?.relationship
              ?.primaryId
          : '',
        tags: editClientData?.tags || [],
        relationship: editClientData.relationship?.type ?? '',
        tenantId: activeTenantId ?? '',
        primaryName: editClientData.relationship?.primaryName ?? '',
        groupName: editClientData.relationship?.groupName ?? '',
        sponsorId: editClientData.sponsorId ?? '',
        bloodGroup:
          (editClientData as IAdaptedClientIndividual).bloodGroup || '',
        textEnabled: editClientData.textEnabled ?? false,
        countryCode:
          (editClientData as IAdaptedClientIndividual)?.countryCode ||
          CountryCodes.USA,
        phoneExt: (editClientData as IAdaptedClientIndividual)?.phoneExt || '1',
        isBloodTypeVerified:
          (editClientData as IAdaptedClientIndividual)?.bloodTypeVerified ===
            BloodTypeVerified.VERIFIED || false,
        isVip: (editClientData as IAdaptedClientIndividual)?.isVip || false,
      });
    }
  }, [
    authTenantAssociation?.referenceId,
    authTenantAssociation?.tenantId,
    authTenantData?.name,
    editClientData,
    isClientAgent,
    methods,
    reset,
    activeTenantId,
  ]);

  /**
   * On add mode, pre-select the clientType based on query param 'clientType'
   *
   */
  useEffect(() => {
    if (
      isEditMode ||
      !clientTypeFromParams ||
      !parentIdFromParams ||
      !parentNameFromParams ||
      !parentEnrollmentIdParams
    ) {
      return;
    }

    if (clientTypeFromParams === ClientType.GROUP_MEMBER.replace(' ', '_')) {
      setValue('clientType', ClientType.GROUP_MEMBER);
      setValue('groupId', parentIdFromParams);
      setValue('groupName', decodeURIComponent(parentNameFromParams));
      setValue(
        'groupEnrollmentId',
        decodeURIComponent(parentEnrollmentIdParams)
      );
    }

    if (clientTypeFromParams === ClientType.DEPENDENT && !groupIdFromParams) {
      setValue('clientType', ClientType.DEPENDENT);
      setValue('primaryId', parentIdFromParams);
      setValue('primaryName', decodeURIComponent(parentNameFromParams));
      setValue(
        'primaryEnrollmentId',
        decodeURIComponent(parentEnrollmentIdParams)
      );
    }

    if (
      clientTypeFromParams === ClientType.DEPENDENT &&
      !!groupIdFromParams &&
      !!groupNameFromParams &&
      !!groupEnrollmentIdFromParams
    ) {
      setValue('clientType', ClientType.DEPENDENT);
      setValue('primaryId', parentIdFromParams);
      setValue('primaryName', decodeURIComponent(parentNameFromParams));
      setValue(
        'primaryEnrollmentId',
        decodeURIComponent(parentEnrollmentIdParams)
      );
      setValue('groupId', groupIdFromParams);
      setValue('groupName', decodeURIComponent(groupNameFromParams));
      setValue('groupEnrollmentId', groupEnrollmentIdFromParams);
    }
  }, [
    isEditMode,
    clientTypeFromParams,
    parentIdFromParams,
    parentNameFromParams,
    setValue,
    parentEnrollmentIdParams,
    groupEnrollmentIdFromParams,
    clientRelationshipType,
    groupIdFromParams,
    groupNameFromParams,
  ]);

  // TODO: need to find a way to dynamically compare enums
  const disableSaveButton = () => {
    if (
      clientType === ClientType.PRIMARY ||
      clientType === ClientType.DEPENDENT ||
      clientType === ClientType.GROUP ||
      clientType === ClientType.GROUP_MEMBER
    )
      return true;
    return false;
  };

  if (loading) {
    return <LoadingIndicator containerHeight="40vh" />;
  }

  // True if the modal is opened from 'Add Dependent' or 'Add Member' menu option
  const isClientTypeSelectedFromParams = !!(
    clientTypeFromParams &&
    parentIdFromParams &&
    parentNameFromParams
  );

  const isClientAutocompleteDisabled =
    isEditMode || isClientTypeSelectedFromParams;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <DialogContent className="modal-content modal-content--fixed-height">
          {!hideClientType && (
            <SelectClientType isDisabled={isClientAutocompleteDisabled} />
          )}
          {clientType === ClientType.GROUP && (
            <GroupForm
              isSponsorIdEnabled={isSponsorIdEnabled}
              showReferringAgent
            />
          )}
          {clientType === ClientType.GROUP_MEMBER && (
            <GroupMemberForm
              isClientAutocompleteDisabled={isClientAutocompleteDisabled}
              isSponsorIdEnabled={isSponsorIdEnabled}
            />
          )}
          {clientType === ClientType.PRIMARY && (
            <PrimaryForm isSponsorIdEnabled={isSponsorIdEnabled} />
          )}
          {clientType === ClientType.DEPENDENT && (
            <DependentForm
              isClientAutocompleteDisabled={isClientAutocompleteDisabled}
              isEditMode={isEditMode}
              isSponsorIdEnabled={isSponsorIdEnabled}
            />
          )}
        </DialogContent>
        <ModalFooter
          cancelButtonType="cancel"
          isCancelRequired={false}
          isDisabled={!disableSaveButton()}
          isLoading={
            addClientMutation.isLoading || editClientMutation.isLoading
          }
          onCancelButtonClick={handleCancelButtonClick}
        />
      </form>
    </FormProvider>
  );
};

Information.defaultValues = {
  loading: false,
};

export default Information;
