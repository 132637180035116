import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useCheckUserIsClientGroup } from 'hooks/useCheckUserIsClientGroup';
import { useClientEnrollmentMembershipQuery } from 'services/client-management/enrollment';
import { selectAuthTenantData } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

import { useGetCurrentSelectedClientAndMembershipDetails } from './useGetCurrentSelectedClientAndMembershipDetails';

export const useGetClientEnrollmentMembership = (propsClientId?: string) => {
  const tenantData = useAppSelector(selectAuthTenantData);

  const [searchParams] = useSearchParams();
  const clientId = searchParams.get('clientId');
  const id = searchParams.get('id'); // this is for add/edit client model

  const { clientId: selectedClientId } =
    useGetCurrentSelectedClientAndMembershipDetails();

  const { data, isLoading, isIdle } = useClientEnrollmentMembershipQuery(
    propsClientId! || clientId! || id! || selectedClientId!,
    {
      enabled: !!propsClientId || !!clientId || !!id || !!selectedClientId,
    }
  );

  const isUserGroup = useCheckUserIsClientGroup();

  const modifiedData = useMemo(() => {
    if (isUserGroup && data?.rows) {
      return data.rows?.filter(
        (e) => e.groupId === tenantData?.tenantAssociation.referenceId
      );
    }
    if (data?.rows) return data.rows;
    return [];
  }, [data, isUserGroup, tenantData?.tenantAssociation.referenceId]);

  return {
    data: modifiedData,
    isLoading,
    isIdle,
  };
};
