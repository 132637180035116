import { useEffect, useRef, useState } from 'react';

import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, DialogContent, IconButton } from '@mui/material';
import { LoadingIndicator, Modal } from 'common';
import LabelBoxCard from 'common/LabelBoxCard';
import { getHtmlSnapShot } from 'utils/html-to-image';

import { QRCodeDownloadView } from './QRCodeDownloadView';

interface IProps {
  openModel: boolean;
  clientId: string;
  clientName: string;
  resetModelOpen: VoidFunction;
}
export const QRCodeDisplayModel = ({
  openModel,
  clientId,
  clientName,
  resetModelOpen,
}: IProps) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadImage = () => {
    if (isDownloading && !clientId) return;
    setIsDownloading(true);
    getHtmlSnapShot(ref, `qr-code-${clientName}` || '', setIsDownloading);
  };

  const handleClose = () => {
    resetModelOpen();
    setOpen(false);
  };
  useEffect(() => {
    setOpen(openModel);
  }, [openModel]);
  return (
    <Modal
      handleClose={handleClose}
      maxWidth="sm"
      open={open}
      title="My Referral Code"
    >
      <DialogContent
        className="scrollbar"
        sx={{ overflowY: 'auto', p: 0, maxHeight: '80vh' }}
      >
        <Box>
          <Box sx={{ position: 'relative' }}>
            <LabelBoxCard label="Download your referral QR code" title="" />
            <Box
              sx={{
                position: 'absolute',
                right: 10,
                top: '50%',
                transform: 'translate(-50%,-50%)',
                zIndex: 999,
              }}
            >
              <IconButton
                disabled={isDownloading}
                onClick={handleDownloadImage}
              >
                {isDownloading ? (
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="center"
                  >
                    <LoadingIndicator containerHeight="15px" size="1rem" />
                  </Box>
                ) : (
                  <Box
                    color="primary.main"
                    component={FontAwesomeIcon}
                    icon={faDownload}
                  />
                )}
              </IconButton>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" margin="auto">
            <Box padding={2} ref={ref}>
              <QRCodeDownloadView clientId={clientId || ''} />
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Modal>
  );
};
