import { useFormContext } from 'react-hook-form';

import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Box, Button, Typography } from '@mui/material';
import {
  selectCheckoutButtonDisabled,
  selectErrorMessage,
  selectFailedPaymentMethod,
} from 'stores/billings/failed';
import { useAppSelector } from 'stores/hooks';

interface IProps {
  title?: string;
  subtitle?: string;
  cardNumber: string;
  cardExpiry: string;
  onEditClick?: VoidFunction;
}

const CardInformationDisplay = ({
  title,
  subtitle,
  cardNumber,
  cardExpiry,
  onEditClick,
}: IProps) => {
  const isCheckoutButtonDisabled = useAppSelector(selectCheckoutButtonDisabled);
  const errorTransactionMessage = useAppSelector(selectErrorMessage);
  const { watch } = useFormContext();

  const paymentMethod = watch('paymentMethod');
  const failedPaymentMethod = useAppSelector(selectFailedPaymentMethod);

  return (
    <>
      {isCheckoutButtonDisabled && failedPaymentMethod === paymentMethod && (
        <Alert severity="error" sx={{ marginY: 0.5 }}>
          {errorTransactionMessage}. Please change your details to proceed
        </Alert>
      )}
      <Box
        alignItems="center"
        color="text.secondary"
        display="flex"
        justifyContent="space-between"
      >
        <Box>
          {title && (
            <Typography
              color="text.secondary"
              fontWeight={(theme) => theme.typography.fontWeightMedium}
              gutterBottom={false}
              mt={2}
              variant="body1"
            >
              {title}
            </Typography>
          )}

          {subtitle && <Typography variant="body1">{subtitle}</Typography>}
        </Box>

        {onEditClick && (
          <Button
            onClick={onEditClick}
            startIcon={<FontAwesomeIcon icon={faEdit} />}
            type="button"
            variant="text"
          >
            Edit Card
          </Button>
        )}
      </Box>
      <Typography variant="body2">
        Card Number &nbsp;:
        <Typography color="text.primary" component="span" variant="body2">
          &nbsp;{cardNumber}
        </Typography>
      </Typography>
      <Typography variant="body2">
        Expiration &nbsp; &nbsp; &nbsp; &nbsp;:
        <Typography color="text.primary" component="span" variant="body2">
          &nbsp;{cardExpiry}
        </Typography>
      </Typography>
    </>
  );
};

CardInformationDisplay.defaultProps = {
  onEditClick: null,
  title: '',
  subtitle: 'Use recently entered card information',
};

export default CardInformationDisplay;
