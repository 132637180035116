import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { IDonorPatientInformation } from 'interfaces/cases';

import BloodDonorTableRow from './BloodDonorTableRow';

interface IBloodDonorDisplayProps {
  donors: IDonorPatientInformation[];
  tableSize?: 'small' | 'medium';
}

export const BloodDonorTable = ({
  donors,
  tableSize,
}: IBloodDonorDisplayProps) => (
  <TableContainer component={Paper}>
    <Table aria-label="simple table" stickyHeader sx={{ minWidth: 450 }}>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Address</TableCell>
          <TableCell>Contact</TableCell>

          <TableCell align="right" />
        </TableRow>
      </TableHead>
      <TableBody>
        {donors.map((row: any) => (
          <BloodDonorTableRow data={row} key={row.id} tableSize={tableSize} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

BloodDonorTable.defaultProps = {
  tableSize: 'small',
};
