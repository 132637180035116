import { useEffect, useState } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';

import { Box, Container, Paper, useMediaQuery, useTheme } from '@mui/material';
import 'assets/scss/_auth-layout.scss';
import CopyrightFooter from 'common/CopyrightFooter';
import Logo from 'common/Logo';
import uiRoutes from 'constants/uiRoutes';
import { useDecodeOTP } from 'services/otp';
import {
  useTenantClientDetailQuery,
  useTenantDetailQuery,
} from 'services/tenant-management/tenant';
import { selectEmailNotesData } from 'stores/email-notes-files';
import { useAppSelector } from 'stores/hooks';

const NoteLayout = ({ children }: { children?: React.ReactNode }) => {
  const location = useLocation();
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const { mutate: decodeOTPMutation } = useDecodeOTP();
  const [searchParams] = useSearchParams();
  const codeLink = searchParams.get('code');
  const [decodedTenantId, setDecodedTenantId] = useState('');

  const tenantId = useAppSelector(selectEmailNotesData)?.tenantId;
  const { data } = useTenantDetailQuery(tenantId || decodedTenantId, {
    enabled: !!tenantId || !!decodedTenantId,
  });

  const client = searchParams.get('client') ?? '';
  const { data: tenantData } = useTenantClientDetailQuery(client, {
    enabled: !!client,
  });

  useEffect(() => {
    const handleDecodeOTPMutation = () => {
      decodeOTPMutation(codeLink!, {
        onSuccess: (r) => {
          setDecodedTenantId(r.data.tenantId);
        },
      });
    };
    if (codeLink) {
      handleDecodeOTPMutation();
    }
  }, [codeLink, decodeOTPMutation]);

  const FULLSCREEN_ON_MOBILE_ROUTES = [
    uiRoutes.notes,
    `${uiRoutes.notes}/modal`,
  ];

  if (
    FULLSCREEN_ON_MOBILE_ROUTES.includes(location.pathname) &&
    isSmallerThanMd
  ) {
    return <Outlet />;
  }

  return (
    <Box className="heroku-img">
      <Container
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '2rem',
        }}
      >
        <Container
          sx={{
            marginY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100',
          }}
        >
          <Logo
            src={tenantData?.metaData?.imageUrl || data?.metaData?.imageUrl}
            sx={{
              height: 60,
              display: 'block',
              marginX: 'auto',
              marginBottom: '1rem',
              marginTop: '4rem',
            }}
          />
          <Paper
            sx={{
              width: '100%',
              maxWidth: '800px',
            }}
          >
            <Box>{children ?? <Outlet />}</Box>
          </Paper>
        </Container>
        <Box sx={{ marginTop: 'auto', textAlign: 'center' }}>
          <CopyrightFooter />
        </Box>
      </Container>
    </Box>
  );
};

NoteLayout.defaultProps = {
  children: undefined,
};

export default NoteLayout;
