import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  faAdd,
  faAngleDown,
  faAngleUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@mui/material';
import PermissionGate from 'common/PermissionGate';
import ModalKey from 'enums/ModalKey';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { selectCurrentReferralCase } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';

import { BloodDonorDisplay } from './BloodDonorDisplay';

const BloodDonors = () => {
  const [expand, setExpand] = useState(true);
  const navigate = useNavigate();
  const isUserClient = useCheckUserIsClient();
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const onChange = (event: React.SyntheticEvent, expanded: boolean) => {
    setExpand(expanded);
  };
  const onAddClick = () => {
    navigate(
      `modal?type=${ModalKey.BLOOD_DONOR}&caseId=${currentReferralCase?.caseId}`
    );
  };
  return (
    <Box position="relative">
      {!isUserClient && (
        <Box
          sx={{
            position: 'absolute',
            top: expand ? '28px' : '6px',
            right: '10px',
            zIndex: 999,
          }}
        >
          <PermissionGate>
            <Button
              onClick={onAddClick}
              startIcon={<FontAwesomeIcon icon={faAdd} />}
              variant="text"
            >
              Add
            </Button>
          </PermissionGate>
        </Box>
      )}

      <Accordion defaultExpanded onChange={onChange}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box display="flex">
              <FontAwesomeIcon
                icon={expand ? faAngleUp : faAngleDown}
                style={{ marginTop: '4px' }}
              />
              <Typography
                color="gray.darker"
                fontWeight="medium"
                gutterBottom={false}
                pl={1}
                variant="body1"
              >
                Blood Donor
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <BloodDonorDisplay />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default BloodDonors;
