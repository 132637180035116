export function sortObjectArray(array: any[], sortBy: string, isDesc = false) {
  if (!array) return [];
  const newArray = array.sort((a: any, b: any) => {
    if (a[sortBy] > b[sortBy]) {
      return 1;
    }
    if (a[sortBy] == null || a[sortBy] === '' || a[sortBy] < b[sortBy]) {
      return -1;
    }
    return -1;
  });
  if (isDesc) newArray.reverse();
  return newArray;
}

export function sortObjectArrayByNumberProp(
  array: any[],
  sortBy: string,
  isDesc = false
) {
  const newArray = array.sort((a: any, b: any) => {
    if (+a[sortBy] > +b[sortBy]) {
      return 1;
    }
    if (+a[sortBy] < +b[sortBy]) {
      return -1;
    }
    return -1;
  });
  if (isDesc) newArray.reverse();
  return newArray;
}

type SortDirection = 'asc' | 'desc';

export function sortByDateProperty<T>(
  property: keyof T,
  direction: SortDirection = 'asc'
): (a: T, b: T) => number {
  return (a: T, b: T): number => {
    const valueA = a[property] as string | undefined;
    const valueB = b[property] as string | undefined;

    const dateA = valueA ? new Date(valueA) : null;
    const dateB = valueB ? new Date(valueB) : null;

    let comparison = 0;
    if (dateA && dateB) {
      comparison = dateA.getTime() - dateB.getTime();
    } else if (!dateA && dateB) {
      comparison = 1;
    } else if (dateA && !dateB) {
      comparison = -1;
    }

    return direction === 'desc' ? comparison * -1 : comparison;
  };
}

// Usage example:
// const sortedSubscriptionsAsc = (subscriptionQuery.data?.rows ?? []).sort(
//   sortByProperty<any>('rawStartDate', 'asc')
// );
// const sortedSubscriptionsDesc = (subscriptionQuery.data?.rows ?? []).sort(
//   sortByProperty<any>('rawStartDate', 'desc')
// );
