const validationMessage = {
  REQUIRED: 'Required',
  INVALID: 'Invalid',
  INVALID_DATE: 'Invalid Date',
  INVALID_LINK: 'Invalid Link',
  INVALID_URL: 'Invalid Url',
  INVALID_TIME: 'Please enter valid time',
  BELOW_18: 'Dependent Must be below {{age}} years',
  VALID_VALUE_RANGE: 'The allowed range is between {{min}} to {{max}}',
  CODE_TAKEN: 'Code already taken',
  FUTURE_DATE_NOT_ALLOWED: 'Date cannot be in the future',
  MAX_CHAR_EXCEEDED: 'Character limit of {{limit}} exceeded',
  ALPHANUMERIC: 'Character must be alphanumeric',
  GREATER_THAN_ZERO: '{{label}} must be greater than 0',
  ONLY_HEX_CODE: 'Only Hex is allowed',
  PASSWORD_NOT_MATCHED:
    'Passwords do not match. Please re-enter your password.',
  PASSWORD_REQUIREMENT:
    'Password must be at least 8 characters including at least one uppercase,one of these special characters #@! and a number',
  ONLY_DIGITS: 'Must be only digits',
  EXACTLY_NINE_DIGITS: 'Must be exactly 9 digits',
  EXACTLY_TEN_DIGITS: 'Must be exactly 10 digits',
  EXACTLY_FOUR_DIGITS: 'Must be exactly 4 digits',
  SELECT_NETWORK: 'Please select network',
  SELECT_ONE_OPTION: 'Please select one of the options above',
  IMAGE_SIZE_LESS_THAN_2MB: 'Image size should be less than 2 Mb',
  INVALID_IMAGE_TYPE: 'Invalid image type. Please select a PNG or JPG file.',
  ROLE_NAME_ALREADY_EXISTS: 'Name already exists, Please enter new name',
  MAX_LENGTH: 'This field must be {{max}} characters or fewer.',
  NO_WHITE_SPACE: 'No white space allowed',
  NO_SPECIAL_CHAR: 'No special character allowed',
};

export default validationMessage;
