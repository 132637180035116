export enum ProfileAdminSortBy {
  NAME = 'name',
  ADDRESS = 'address',
  CONTACT = 'contact',
}

export type ProfileAdminSortByType = ProfileAdminSortBy.NAME;

export enum ContactType {
  PRIMARY = 'primary',
  SUPPORT = 'support',
}

export enum TilesActionType {
  PHONE = 'Phone',
  LINK = 'Link',
}

export enum TileStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}
