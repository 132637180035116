import { useMemo } from 'react';

import { NoteVisibility } from 'enums/case';
import { selectCurrentReferralCase } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';
import { removeDuplicatesFromArrayOfObjects } from 'utils/misc';

import { useCaseOwnerTenant } from './useCaseOwnerTenant';

/**
 * Gets case owner admin users who have note set to public.
 *
 * (Case owner tenant -> tenant in which the case is created)
 *
 */
export const useCaseOwnerAdminsWithPublicNote = () => {
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const patient = currentReferralCase?.patient;

  const caseOwnerTenant = useCaseOwnerTenant();

  const ownerTenantAdmins =
    useMemo(() => {
      if (!currentReferralCase || !caseOwnerTenant) return [];

      const publicNotes = currentReferralCase.notes?.filter(
        (note) => note.accessType === NoteVisibility.PUBLIC
      );

      const admins = publicNotes
        ?.filter(
          (note) =>
            note.createdBy?.tenantId === caseOwnerTenant.referenceId &&
            !!note.createdBy?.id
        )
        ?.map((note) => {
          const isPatient =
            patient?.id === note.createdBy?.id ||
            patient?.email?.trim()?.toLowerCase() ===
              note.createdBy?.email?.trim()?.toLowerCase();

          return {
            email: note.createdBy?.email,
            name: note.createdBy?.name,
            tenantId: caseOwnerTenant.referenceId,
            tenantName: caseOwnerTenant.name,
            type: isPatient ? 'patient' : 'admin', // TODO: Are all users either 'admin' or 'patient'? Could they be 'thirdparty'?
            userId: note.createdBy?.id,
            email_tenantId: `${note.createdBy?.email}____${caseOwnerTenant.referenceId}`,
          };
        });

      return removeDuplicatesFromArrayOfObjects(admins, 'email_tenantId');
    }, [caseOwnerTenant, currentReferralCase, patient]) || [];

  return ownerTenantAdmins;
};
