import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { DialogContent } from '@mui/material';
import { LoadingIndicator, Modal, ModalFooter } from 'common';
import { CountryCodes } from 'enums/country';
import ModalKey from 'enums/ModalKey';
import { useCheckIsSuperAdmin } from 'hooks/useCheckIsSuperAdmin';
import { IAdaptedTenantUser } from 'interfaces/chat';
import {
  IAddUserForm,
  IAddUserSchema,
} from 'interfaces/tenant-management/user';
import { AddUserSchema } from 'schema/tenant-management/user';
import {
  useAddUserMutation,
  useEditUserMutation,
  useUserDetailQuery,
} from 'services/tenant-management/users';
import { selectAuthTenantData } from 'stores/auth';
import { setAddNewtenant } from 'stores/chat';
import { useAppSelector } from 'stores/hooks';
import { unformatDate } from 'utils/common';
import {
  formatTenantChatUserPayload,
  formatUserSavePayload,
} from 'utils/tenant-management/users';

import AddUserForm from './AddUserForm';

const defaultValues = {
  firstName: '',
  lastName: '',
  dob: '',
  gender: '',
  email: '',
  phone: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  phoneExt: '1',
  countryCode: CountryCodes.USA,
  state: '',
  zip: '',
  enableImpersonation: false,
  roles: [],
  association: [],
  isSuperAdmin: false,
};

const AddUserModal = (): JSX.Element => {
  const dispatch = useDispatch();
  const methods = useForm<IAddUserForm>({
    resolver: yupResolver(AddUserSchema),
    defaultValues,
  });

  const association = methods.getValues().association ?? [];
  const isUserSuperAdmin = useCheckIsSuperAdmin(association);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const userId = searchParams.get('id');
  // Add User and Add Admin User both use same modal. It's distinguished by type query param in url
  const isAddAdminUser = searchParams.get('type') === ModalKey.ADD_ADMIN_USER;

  const isAddUser = searchParams.get('type') === ModalKey.ADD_USER;

  const isEditMode = !!userId;

  const authTenantData = useAppSelector(selectAuthTenantData);
  const authTenantId = authTenantData?.tenantAssociation.tenantId;

  const userDetailQuery = useUserDetailQuery(userId!, {
    enabled: !!userId,
  });

  const addUserMutation = useAddUserMutation();
  const editUserMutation = useEditUserMutation();

  const checkIsSuperAdmin = useCheckIsSuperAdmin(
    userDetailQuery?.data?.association || []
  );

  useEffect(() => {
    if (userDetailQuery?.data) {
      const data = userDetailQuery?.data?.demographic;
      const roles = userDetailQuery.data.association.filter(
        (item) => item.tenantId === authTenantId
      );

      methods.reset({
        firstName: data.firstName,
        lastName: data.lastName,
        dob: data.dob ? unformatDate(data.dob) : data.dob,
        gender: data.gender,
        email: data.email,
        phone: data.phone,
        addressLine1: data.address?.addressLine1,
        addressLine2: data.address?.addressLine2,
        city: data.address?.city,
        state: data.address?.state,
        zip: data.address?.zip,
        enableImpersonation:
          userDetailQuery?.data?.enableImpersonation ?? false,
        countryCode:
          userDetailQuery?.data?.demographic?.countryCode || CountryCodes.USA,
        phoneExt: userDetailQuery?.data?.demographic?.phoneExt || '1',
        textEnabled:
          userDetailQuery?.data?.demographic?.textEnabled || undefined,
        middleName: userDetailQuery?.data?.demographic?.middleName,
        association: roles,
        isSuperAdmin: checkIsSuperAdmin,
      });
    }
  }, [userDetailQuery.data, methods, authTenantId, checkIsSuperAdmin]);

  const handleClose = () => {
    navigate(-1);
  };

  const updateUserOnChatList = (body: IAdaptedTenantUser) => {
    dispatch(setAddNewtenant(body));
    handleClose();
  };

  const onSubmit = (data: IAddUserForm) => {
    if (isAddAdminUser && !authTenantId) {
      return;
    }

    let payload: IAddUserSchema = formatUserSavePayload(data);
    if (isAddAdminUser && authTenantId) {
      payload = {
        ...payload,
        tenantId: authTenantId,
        isSuperAdmin: data.isSuperAdmin,
      };
    }

    if (isEditMode) {
      const requestPayload = payload;
      if (isAddUser) delete payload.roles;
      // If the current logged user is not superAdmin and also modified user is super admin..remove roles

      editUserMutation.mutate(
        { userId, data: requestPayload },
        {
          onSuccess: (res) => {
            const body = formatTenantChatUserPayload(res, authTenantData, true);
            // When the new admin user is added than update it on the list
            updateUserOnChatList(body);
          },
        }
      );
      return;
    }

    addUserMutation.mutate(
      { data: payload },
      {
        onSuccess: (res) => {
          const body = formatTenantChatUserPayload(res, authTenantData, false);
          updateUserOnChatList(body);
        },
      }
    );
  };

  const handleCancelButtonClick = () => {
    handleClose();
  };

  return (
    <Modal
      handleClose={handleClose}
      icon={isEditMode ? 'faUserPen' : 'faUserPlus'}
      open
      title={isEditMode ? 'Edit User' : 'Add User'}
    >
      {userDetailQuery.isLoading ? (
        <LoadingIndicator containerHeight="40vh" />
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <DialogContent>
              <AddUserForm
                isAddAdminUser={isAddAdminUser}
                isEditMode={isEditMode}
                isUserSuperAdmin={isUserSuperAdmin}
              />
            </DialogContent>
            <ModalFooter
              isLoading={
                addUserMutation.isLoading || editUserMutation.isLoading
              }
              onCancelButtonClick={handleCancelButtonClick}
            />
          </form>
        </FormProvider>
      )}
    </Modal>
  );
};

export default AddUserModal;
