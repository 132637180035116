import React from 'react';

import { Avatar, Box, Skeleton, Stack } from '@mui/material';

const ReferralCaseNoteFileSkeleton = () => (
  <Box p={2}>
    <Stack direction="row" spacing={1}>
      <Skeleton height={60} width="30%" />
      <Skeleton height={60} width="30%" />
    </Stack>
    <Box paddingTop={2}>
      {Array(3)
        .fill(0)
        .map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={i}>
            <Stack direction="row" spacing={1}>
              <Box>
                <Skeleton sx={{ marginTop: 1 }} variant="circular">
                  <Avatar />
                </Skeleton>
              </Box>
              <Stack spacing={-2} width="100%">
                <Skeleton height={30} width="30%" />
                <Skeleton height={120} width="80%" />
              </Stack>
            </Stack>
          </Box>
        ))}
    </Box>
  </Box>
);

export default ReferralCaseNoteFileSkeleton;
