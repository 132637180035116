import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Box,
  CircularProgress,
  debounce,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import commonConstants from 'constants/common';
import { IProfileAdminFilter } from 'interfaces/common';
import { IProfileAdminUsersRow } from 'interfaces/tenant-management/user';
import { useProfileAdminUsersQuery } from 'services/tenant-management/users';
import { selectAuthTenantData } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { getFullName } from 'utils/common';

const { DEFAULT_TABLE_FILTER } = commonConstants;

interface IUserAutoCompleteProps {
  //   tenantId: string;
  handleInputClear?: VoidFunction;
  resetInputField?: boolean;
  disabled?: boolean;
  handleOnChangeSubmit: (e: IProfileAdminUsersRow) => void;
  label?: string;
  placeholder?: string;
  size?: 'small' | 'medium';
  variant?: 'standard' | 'filled' | 'outlined';
  selectedValue?: string;
  selectedValueName?: string;
  error?: boolean;
  setResetInputField?: React.Dispatch<React.SetStateAction<boolean>>;
  clientId?: string;
  inputProps?: TextFieldProps & { 'data-cy'?: string };
}

const GroupAdminUserAutocomplete = ({
  //   tenantId,
  label,
  placeholder,
  disabled,
  size,
  variant,
  handleOnChangeSubmit,
  handleInputClear,
  selectedValue,
  selectedValueName,
  error,
  resetInputField,
  setResetInputField,
  clientId,
  inputProps,
}: IUserAutoCompleteProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [clearData, setClearData] = useState(false);
  const [value, setValue] = useState<IProfileAdminUsersRow | null>(null);
  const [filters, setFilters] = useState<IProfileAdminFilter>({
    ...DEFAULT_TABLE_FILTER,
  });

  const decodeToken = useAppSelector(selectAuthTenantData);
  const referenceId = decodeToken?.tenantAssociation?.referenceId;

  const usersQuery = useProfileAdminUsersQuery(
    { ...filters, referenceId: clientId ?? referenceId },
    {
      enabled: !!filters.keyword,
    }
  );

  const handleInputChange = useCallback(
    async (event: SyntheticEvent<Element, Event>, newInputValue: string) => {
      if (setResetInputField) setResetInputField(false);
      if (newInputValue.length > 2 || !newInputValue.length) {
        if (!open) {
          setOpen(true);
        }
        setFilters((prevState) => ({ ...prevState, keyword: newInputValue }));
      }
    },
    [open, setResetInputField]
  );

  const onChange = async (user: IProfileAdminUsersRow | null) => {
    if (user) handleOnChangeSubmit(user);
  };

  const debouncedInputChange = useMemo(
    () => debounce(handleInputChange, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const options = useMemo(
    () => usersQuery?.data?.rows || [],
    [usersQuery?.data?.rows]
  );

  useEffect(() => {
    if (selectedValueName) {
      setClearData(true);
      setFilters((prevState) => ({
        ...prevState,
        keyword: selectedValueName,
      }));
    }
  }, [selectedValueName]);

  useEffect(() => {
    if (selectedValue && options && clearData) {
      const v = options.find((e) => e.userId === selectedValue);
      if (v) {
        setValue(v);
      }
      setClearData(false);
    }
    if (resetInputField) setValue(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, resetInputField, selectedValue]);

  return (
    <Autocomplete
      autoComplete
      disabled={disabled}
      filterOptions={(x) => x}
      filterSelectedOptions
      getOptionLabel={(option) =>
        typeof option === 'string'
          ? option
          : `${getFullName(
              option.demographic?.firstName,
              option.demographic.lastName,
              option.demographic.middleName
            )}`
      }
      id="async-user-autocomplete"
      includeInputInList
      isOptionEqualToValue={(option, newValue) =>
        option.userId === newValue.userId
      }
      loading={usersQuery.isLoading}
      onChange={(
        event: SyntheticEvent<Element, Event>,
        newValue: IProfileAdminUsersRow | null,
        reason: AutocompleteChangeReason
      ) => {
        setOpen(false);

        if (reason === 'clear') {
          setValue(null);
        } else {
          setValue(newValue);
        }
        onChange(newValue || null);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={(
        event: SyntheticEvent<Element, Event>,
        v: string,
        reason: AutocompleteInputChangeReason
      ) => {
        if (reason === 'clear') {
          if (handleInputClear) handleInputClear();
        }
        debouncedInputChange(event, v);
      }}
      // onOpen={() => {
      //   setOpen(true);
      // }}
      open={open}
      openOnFocus={false}
      options={options}
      renderInput={(params) => (
        // You can use different variations of textfield here.
        // Please refer TextfieldOverview.tsx for reference.
        <TextField
          {...params}
          {...inputProps}
          error={error}
          fullWidth
          InputProps={{
            ...params.InputProps,
            ...inputProps?.InputProps,
            endAdornment: (
              <>
                {usersQuery.isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            ...params.inputProps,
            ...inputProps?.inputProps,
          }}
          label={label}
          placeholder={placeholder}
          size={size}
          variant={variant}
        />
      )}
      renderOption={(props, option) => (
        /* eslint-disable react/jsx-props-no-spreading */
        <Box
          {...props}
          component="li"
          key={option.userId}
          sx={{
            '&:hover': {
              color: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          <Typography color="inherit" gutterBottom={false} variant="body2">
            {getFullName(
              option.demographic?.firstName,
              option.demographic.lastName,
              option.demographic.middleName
            )}
          </Typography>
        </Box>
      )}
      value={value}
    />
  );
};

GroupAdminUserAutocomplete.defaultProps = {
  label: '',
  placeholder: 'Type name or email of user',
  size: 'small',
  variant: 'standard',
  selectedValue: undefined,
  selectedValueName: undefined,
  handleInputClear: undefined,
  setResetInputField: undefined,
  clientId: undefined,
  error: false,
  resetInputField: false,
  disabled: false,
  inputProps: null,
};

export default GroupAdminUserAutocomplete;
