import { useMemo } from 'react';

import { useCheckUserIsTenantAdmin } from 'hooks/useCheckUserIsTenantAdmin';
import { selectAuthTenantData } from 'stores/auth';
import { selectCurrentReferralCase } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';

import { useCaseOwnerTenant } from './useCaseOwnerTenant';

/**
 * Tagger Admin => Tenant Admins who have tagged the user (me) or other tenant admin of my tenant
 *
 * Case 1: For admins of partner tenant
 * Should provide tenant admins of owner tenant that has tagged any of the tenant admin of partner tenant
 *
 * Case 2: For patient login or third party login
 * Should provide tenant admins of owner tenant and/or invitee tenant that has tagged that specific patient or third party
 *
 */
export const useTaggerAdmin = () => {
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const authUser = useAppSelector(selectAuthTenantData);

  const caseOwnerTenant = useCaseOwnerTenant();
  const isUserAdmin = useCheckUserIsTenantAdmin();

  const loggedInTenantId = authUser?.tenantAssociation?.tenantId;
  const loggedInUserId = authUser?.userId;
  const loggedInEmail = authUser?.email;

  const taggableAdmins =
    useMemo(() => {
      if (!currentReferralCase || !caseOwnerTenant || !loggedInTenantId)
        return [];

      /**
       * No need to proceed further if the loggedIn user is in the owner tenant itself
       * if the user is an admin user.
       * However, for patient and third party, admins from both case owner and invitee, who have
       * tagged should be taggable.
       *
       */
      if (loggedInTenantId === caseOwnerTenant.referenceId && isUserAdmin)
        return [];

      const taggedNotes = currentReferralCase.notes?.filter(
        (note) => !!note.tagged?.length
      );

      let filteredNotes = [];

      if (isUserAdmin) {
        filteredNotes = taggedNotes?.filter(
          (note) =>
            // `tenantId` should be present in any user that has been tagged
            // this implies the logged in admin or any other admin has been tagged by owner tenant admin
            note.tagged?.some(
              (tagged) => tagged.tenantId === loggedInTenantId
            ) &&
            // id should be present to identify tenant admin
            // this filters out other users (like patient and third party)
            !!note.createdBy?.id &&
            // createdBy tenantId should be equal to case owner tenantId
            // this filters out admins other than case owner
            note.createdBy?.tenantId === caseOwnerTenant.referenceId
        );
      } else {
        filteredNotes = taggedNotes?.filter(
          (note) =>
            // `userId` should match to identify the user tagged in the note.
            // Users other than admin (like patient and third party) may not have `userId`
            // So, email is also checked.
            note.tagged?.some(
              (tagged) =>
                tagged.userId === loggedInUserId ||
                tagged.id?.trim()?.toLowerCase() ===
                  loggedInEmail?.trim()?.toLowerCase()
            ) &&
            // id should be present to identify tenant admin
            // this filters out other users (like patient and third party)
            !!note.createdBy?.id
        );
      }

      return filteredNotes?.map((note) => {
        // TODO: Find out why the email is populated in note.createdBy?.id for some admin users.
        const email = note.createdBy?.email || note.createdBy?.id;
        return {
          email,
          name: note.createdBy?.name,
          tenantId: caseOwnerTenant.referenceId,
          tenantName: caseOwnerTenant.name,
          type: 'admin', // Are all users 'admin'? Could they be 'patient' or 'thirdParty'?
          userId: note.createdBy?.id,
          email_tenantId: `${email}____${caseOwnerTenant.referenceId}`,
        };
      });
    }, [
      currentReferralCase,
      caseOwnerTenant,
      loggedInTenantId,
      isUserAdmin,
      loggedInUserId,
      loggedInEmail,
    ]) || [];

  return taggableAdmins;
};
