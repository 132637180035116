import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { CometChat } from '@cometchat-pro/chat';
import { Avatar, Badge, styled, Typography, useTheme } from '@mui/material';
import { TenantConnStatus } from 'enums/chat';
import { ITenantUser } from 'interfaces/chat';
import { selectAuthTenantData } from 'stores/auth';
import { selectTenantConnStatus, setTenantConnStatus } from 'stores/chat';
import { useAppSelector } from 'stores/hooks';
import { getFirstLetterOfFirstAndLastName, getFullName } from 'utils/common';

const TenantAvatar = ({
  item,
  height,
  width,
  variant,
  showBadge,
}: {
  item: ITenantUser | null;
  height?: number;
  width?: number;
  variant?: string;
  showBadge?: boolean;
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const dispatchRef = useRef(dispatch);
  const connectedIds = useAppSelector(selectTenantConnStatus);
  const tenantData = useAppSelector(selectAuthTenantData);
  const isConnected = connectedIds.includes(item?.userId?.toLowerCase() ?? '');
  const isOnline = isConnected || !item;

  useEffect(() => {
    // Set user status to online and offline
    if (item?.status === TenantConnStatus.AVAILABLE) {
      dispatchRef.current(
        setTenantConnStatus({
          status: TenantConnStatus.AVAILABLE,
          userId: item?.userId?.toLowerCase(),
        })
      );
    }
  }, [item?.status, item?.userId]);

  useEffect(() => {
    // Event listener for user when connection status is changed
    CometChat.addUserListener(
      item?.userId ?? '',
      new CometChat.UserListener({
        onUserOnline: (user: CometChat.User) => {
          if (item?.userId?.toLowerCase() === user.getUid()?.toLowerCase()) {
            dispatch(
              setTenantConnStatus({
                status: TenantConnStatus.AVAILABLE,
                userId: user.getUid(),
              })
            );
          }
        },
        onUserOffline: (user: CometChat.User) => {
          if (item?.userId.toLowerCase() === user.getUid()?.toLowerCase()) {
            dispatch(
              setTenantConnStatus({
                status: TenantConnStatus.OFFLINE,
                userId: user.getUid(),
              })
            );
          }
        },
      })
    );
    return () => CometChat.removeUserListener(item?.userId ?? '');
  }, [dispatch, item?.status, item?.userId]);

  // TODO: add according to style guide
  const StyledBadge = styled(Badge)(() => ({
    '& .MuiBadge-badge': {
      position: 'absolute',
      bottom: 5,
      right: 5,
      backgroundColor: isOnline
        ? theme.palette.success.dark
        : theme.palette.gray.lighter,

      boxShadow: `0 0 0 2px ${
        isOnline ? theme.palette.success.main : theme.palette.gray.light
      }`,
    },
  }));

  return (
    <StyledBadge
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      overlap="circular"
      variant={showBadge ? 'dot' : null}
    >
      <Avatar
        sx={{
          alignSelf: 'flex-start',
          width,
          height,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Typography
          fontWeight="medium"
          gutterBottom={false}
          textTransform="uppercase"
          variant={variant}
        >
          {getFirstLetterOfFirstAndLastName(
            getFullName(
              item?.demographic?.firstName ?? tenantData?.firstName,
              item?.demographic?.lastName ?? tenantData?.lastName
            )
          )}
        </Typography>
      </Avatar>
    </StyledBadge>
  );
};

TenantAvatar.defaultProps = {
  height: 36,
  width: 36,
  variant: 'body2',
  showBadge: true,
};

export default TenantAvatar;
