import { useEffect, useState } from 'react';

import {
  faCheck,
  faCheckDouble,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Box, Typography, useTheme } from '@mui/material';
import { MessageStatus } from 'enums/chat';
import { IAdaptedMessage, ITenantUser } from 'interfaces/chat';
import { downloadAttachedFile } from 'utils/virtual-visit';

import TenantAvatar from './Avatar';

export const ChatItem = ({
  isLast,
  item,
  scrollRef,
  user,
}: {
  isLast: boolean;
  item: IAdaptedMessage;
  scrollRef: HTMLDivElement | any;
  user: ITenantUser;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef?.current?.scrollIntoView({});
    }
  }, [scrollRef]);

  return (
    <Box flexDirection="row" my={2} width="100%">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={item?.isSentByMe ? 'flex-end' : 'flex-start'}
      >
        <Box
          bgcolor={
            !item?.isSentByMe
              ? theme.palette.gray.lighter
              : theme.palette.primary.lighter
          }
          color={theme.palette.gray.darker}
          display="flex"
          flexDirection="column"
          p={1}
          sx={{
            borderTopLeftRadius: item?.isSentByMe ? theme.spacing(1) : 0,
            borderTopRightRadius: theme.spacing(1),
            borderBottomLeftRadius: theme.spacing(1),
            borderBottomRightRadius: item?.isSentByMe ? 0 : theme.spacing(1),
          }}
        >
          {item.isTextMessage ? (
            <Typography
              color={theme.palette.gray.dark}
              gutterBottom={false}
              variant="body2"
            >
              {item?.text}
            </Typography>
          ) : (
            <Box
              alignItems="center"
              display="flex"
              gap={1}
              justifyContent="space-between"
            >
              <Box alignItems="center" display="flex">
                <Box mr={1}>
                  <FontAwesomeIcon icon={faPaperclip} />
                </Box>
                <Typography
                  sx={{ wordWrap: 'break-word', maxWidth: 130 }}
                  variant="body1"
                >
                  {item?.fileName}
                </Typography>
              </Box>
              <LoadingButton
                loading={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  downloadAttachedFile(
                    item.text ?? '',
                    item?.fileName ?? '',
                    () => {
                      setIsLoading(false);
                    }
                  );
                }}
                size="small"
                sx={{
                  border: `1px solid ${theme.palette.gray.main} !important`,
                  borderRadius: '4px !important',
                  maxWidth: 100,
                  backgroundColor: `${theme.palette.common.white} !important`,
                }}
                variant="outlined"
              >
                Download File
              </LoadingButton>
            </Box>
          )}
        </Box>
      </Box>
      {isLast && (
        <Box
          display="flex"
          gap={1}
          justifyContent={item.isSentByMe ? 'flex-end' : 'flex-start'}
          mt={0.5}
        >
          {item.isSentByMe && (
            <>
              {item.status === MessageStatus.SENT && (
                <FontAwesomeIcon
                  color={theme.palette.gray.light}
                  icon={faCheck}
                />
              )}
              {item.status === MessageStatus.DELEVIRED && (
                <FontAwesomeIcon
                  color={theme.palette.gray.light}
                  icon={faCheckDouble}
                />
              )}
              {item.status === MessageStatus.UNSENT && (
                <Typography color={theme.palette.error.dark} variant="caption">
                  Message was not sent
                </Typography>
              )}
              {item.status === MessageStatus.SEEN && (
                <TenantAvatar
                  height={20}
                  item={user}
                  showBadge={false}
                  variant="caption"
                  width={20}
                />
              )}
            </>
          )}
          <Typography
            color={theme.palette.gray.main}
            gutterBottom={false}
            mt={0.5}
            textTransform="uppercase"
            variant="caption"
          >
            {item?.time}
          </Typography>
        </Box>
      )}
      {!isLast && (
        <Box
          display="flex"
          justifyContent={item.isSentByMe ? 'flex-end' : 'flex-start'}
          mt={0.5}
          width="100%"
        >
          <Typography
            color={theme.palette.gray.main}
            gutterBottom={false}
            textTransform="uppercase"
            variant="caption"
          >
            {item?.time}
          </Typography>
        </Box>
      )}
      <Box ref={scrollRef} />
    </Box>
  );
};
