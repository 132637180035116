import { ClientEnrollmentStatus } from 'enums/client-management';
import { useClientEnrollmentMembershipQuery } from 'services/client-management/enrollment';
import { selectAuthTenantAssociation } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

import { useCheckUserIsTenantAdmin } from './useCheckUserIsTenantAdmin';

export function useCheckTimeLogModuleIsAccessible() {
  const tenantAssociation = useAppSelector(selectAuthTenantAssociation);

  const clientTd = tenantAssociation?.referenceId;

  const { data } = useClientEnrollmentMembershipQuery(clientTd || '', {
    enabled: !!clientTd,
  });

  const isTenantAdmin = useCheckUserIsTenantAdmin();

  const isTimeSheetModuleAccessible = () => {
    if (isTenantAdmin) return true;

    const membershipData = data?.rows;

    const membershipDataWithPrimaryIdAndGroupIdEmpty = membershipData?.filter(
      (e) => !e.primaryId && !e.groupId
    );

    if (
      membershipDataWithPrimaryIdAndGroupIdEmpty?.length &&
      membershipDataWithPrimaryIdAndGroupIdEmpty?.some(
        (f) =>
          f.status === ClientEnrollmentStatus.APPROVED ||
          f.status === ClientEnrollmentStatus.CANCELLED ||
          f.status === ClientEnrollmentStatus.COMPLETED
      )
    ) {
      return true;
    }
    return false;
  };

  return {
    isTimeSheetModuleAccessible: isTimeSheetModuleAccessible(),
  };
}
