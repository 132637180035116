export enum AffiliateType {
  AGENT = 'Agent',
  CLIENT = 'Client',
}

export enum ClientGroupSortBy {
  NAME = 'name',
  EMAIL = 'email',
  // IN API benefit is used as enrollment status for sorting.
  BENEFIT = 'status',
}

export enum ClientIndividualSortBy {
  NAME = 'name',
  EMAIL = 'email',
  BENEFIT = 'status',
}

export enum ClientEnrollmentStatus {
  ALL = 'All',
  APPROVED = 'Approved',
  CANCELLED = 'Cancelled',
  COMPLETED = 'Completed',
  DECLINED = 'Declined',
  SUBMITTED = 'Submitted',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  PARTIAL = 'Partial',
}

export enum ClientType {
  GROUP = 'Group',
  GROUP_MEMBER = 'Group Member',
  GROUP_MEMBER_DEPENDENT = 'Group Member Dependent',
  PRIMARY = 'Primary',
  DEPENDENT = 'Dependent',
}

export enum RelationshipToPrimaryMember {
  PRIMARY = 'Primary',
  DEPENDENT = 'Dependent',
  SPOUSE = 'Spouse',
  CHILD = 'Child',
  OTHERS = 'Others',
}

export enum OfferingType {
  ANCILLARY = 'Ancillary',
  MEMBERSHIP = 'Membership',
}

export enum AgreementType {
  SLA = 'SLA',
}

export enum ChangeMembershipActionType {
  ADD = 'Add',
  APPROVE = 'Approve',
  CANCEL = 'Cancel',
  CHANGE = 'Change',
  COMPLETE = 'Complete',
  DECLINE = 'Decline',
}

export enum MembershipOfferingVisibility {
  PRIVATE = 'Private',
  PUBLIC = 'Public',
}

export enum ActionFrom {
  CLIENT_LIST = 'client-list',
  CLIENT_LIST_MULTIPLE = 'client-list-multiple',
}

export enum BloodGroup {
  A_POSITIVE = 'A+',
  O_POSITIVE = 'O+',
  B_POSITIVE = 'B+',
  AB_POSITIVE = 'AB+',
  A_NEGATIVE = 'A-',
  O_NEGATIVE = 'O-',
  B_NEGATIVE = 'B-',
  AB_NEGATIVE = 'AB-',
  UNK = 'UNK',
}

export enum BloodTypeVerified {
  VERIFIED = 'Verified',
  UNVERIFIED = 'Unverified',
}
