import { useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';

import MentionTextField from './MentionTextField';
import NoteLists from './NoteLists';

interface INoteProps {
  drawerView: boolean | undefined;
}

const Notes = ({ drawerView }: INoteProps) => {
  const boxRef = useRef<HTMLElement>(null);

  const [noteBoxWidth, setNoteBoxWidth] = useState(0);

  useEffect(() => {
    if (boxRef?.current?.clientWidth)
      setNoteBoxWidth(boxRef.current.clientWidth);
  }, [boxRef]);
  return (
    <Box ref={boxRef}>
      <NoteLists drawerView={drawerView} noteBoxWidth={noteBoxWidth} />
      <MentionTextField noteBoxWidth={noteBoxWidth} />
    </Box>
  );
};

export default Notes;
