import React, { ReactNode } from 'react';

import { Stack } from '@mui/material';

const ReferralCaseListItemLayout = ({ children }: { children: ReactNode }) => (
  <Stack
    padding={1}
    spacing={0.5}
    sx={{
      borderBottom: 1,
      borderColor: 'divider',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: (theme) => theme.palette.gray.lighter,
        span: {
          color: (theme) => theme.palette.secondary.main,
        },
      },
    }}
  >
    {children}
  </Stack>
);

export default ReferralCaseListItemLayout;
