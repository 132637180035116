import React, { createContext, useContext } from 'react';

import config from 'config';
import jwtDecode from 'jwt-decode';
import { io } from 'socket.io-client';
import { getLocal } from 'utils/storage';

export const socket = io(config.socketURL || 'http://localhost:4200', {
  path: '/ws',
  transports: ['websocket'],
  reconnection: true,
});

socket.on('connection', (id) => {
  console.log(`Connected to socket server with id: ${id}`);

  const jwtToken = getLocal('jwtToken');
  const jwtDecoded = jwtDecode(jwtToken);
  const userId = (jwtDecoded as any)?.userId;

  if (!userId) return;

  socket.emit('login', userId);
});
// socket.on('connect_error', () => console.log('Lost connection to socket server'))

const SocketContext = createContext(socket);

export const SocketProvider = ({ children }: { children: React.ReactNode }) => (
  <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
);

export const useSocketContext = () => useContext(SocketContext);
