import React from 'react';

import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';

export const EditableDetailLayout = ({
  children,
  handleSave,
  handleCancel,
  isLoading,
  isSaveDisabled,
}: {
  children: JSX.Element;
  handleSave: VoidFunction;
  handleCancel: VoidFunction;
  isLoading: boolean;
  isSaveDisabled: boolean;
}) => (
  <Box alignItems="flex-end" display="flex" gap={1} width={200}>
    <Box>{children}</Box>
    <Box display="flex" marginBottom={0.5}>
      <Tooltip title="Save">
        <IconButton
          disabled={isLoading || isSaveDisabled}
          onClick={handleSave}
          size="small"
        >
          {isLoading ? (
            <CircularProgress size="1rem" />
          ) : (
            <Box component={FontAwesomeIcon} icon={faSave} size="sm" />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip title="Cancel">
        <IconButton disabled={isLoading} onClick={handleCancel} size="small">
          <Box component={FontAwesomeIcon} icon={faTimes} size="sm" />
        </IconButton>
      </Tooltip>
    </Box>
  </Box>
);
