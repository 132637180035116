import { CometChat } from '@cometchat-pro/chat';
import { layoutConstants } from 'constants/layout';
import messageConstant from 'constants/message';
import { MessageStatus } from 'enums/chat';
import {
  IActiveTenantUser,
  IAdaptedMessage,
  IAdaptedResponseMessage,
  ITenantUser,
} from 'interfaces/chat';
import { IResponseMessage } from 'interfaces/virtual-visit/comet-chat';
import { CometChatService } from 'services/virtual-visit/comet-chat/comet-chat-service';
import { store } from 'stores';
import {
  setLastMessage,
  setRemoveInitialUser,
  setUnreadMessage,
  setUpdateTenantConversationList,
  setUpdateUserOnMsg,
} from 'stores/chat';
import { compareTimeStamp, formatTimeStampToString } from 'utils/moment';

export const uploadFileChat = (context: any, sessionID: string) =>
  CometChatService.sendFileMessageUser(context, sessionID);

export const extractTextFromMessage = (
  textMessage: CometChat.TextMessage
): string | null => {
  if (textMessage instanceof CometChat.TextMessage) {
    const messageDatas = textMessage.getData();
    if (messageDatas) {
      return messageDatas.text;
    }
  }
  return null;
};

export const sortTenants = (data: ITenantUser[]) =>
  data.sort((a, b) => {
    const sentA = a?.lastMessage?.updatedAt;
    const sentB = b?.lastMessage?.updatedAt;

    // Additional priorities as needed
    if (a.isFriend && !b.isFriend) {
      return -1;
    }
    if (!a.isFriend && b.isFriend) {
      return 1;
    }
    if (!a.isFriend && a.isRegistered && (b.isFriend || !b.isRegistered)) {
      return -1;
    }
    if (!b.isFriend && b.isRegistered && (a.isFriend || !a.isRegistered)) {
      return 1;
    }

    if (sentA && sentB) {
      return sentB - sentA;
    }

    if (sentA && !sentB) {
      return -1;
    }

    if (!sentA && sentB) {
      return 1;
    }

    const statusOrder: StatusOrder = { available: 1, offline: 2 };
    const statusA = statusOrder[a.status ?? 'offline'] || 0;
    const statusB = statusOrder[b.status ?? 'offline'] || 0;

    if (statusA !== statusB) {
      return statusA - statusB;
    }

    // If updatedAt is equal or not present, sort alphabetically by name
    const nameA = a.demographic?.firstName.toLowerCase() ?? '';
    const nameB = b.demographic?.firstName.toLowerCase() ?? '';

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

interface StatusOrder {
  offline: number;
  available: number;
  [key: string]: number; // Index signature allowing any string
}

export const sortTenantsWithPriority = (data: ITenantUser[], userId: string) =>
  data.sort((a, b) => {
    const aUserId = a.userId?.toLowerCase();
    const bUserId = b.userId?.toLowerCase();
    const uid = userId?.toLowerCase();
    if (aUserId === uid) {
      return -1;
    }
    if (bUserId === uid) {
      return 1;
    }
    return 0;
  });

export const filterDuplicates = (val: ITenantUser[], tenantId: string) => {
  const uniqueUserIds = new Set();
  const result: ITenantUser[] = [];

  val.forEach((item) => {
    const { userId } = item;

    // Check if the userId is not already in the Set
    if (!uniqueUserIds.has(userId) && userId !== tenantId?.toLowerCase()) {
      uniqueUserIds.add(userId);
      result.push(item);
    }
  });

  return result;
};

// Calculate the height of the chat list window
export const calculateWindowHeight = (
  originalHeight: number,
  percentage: number
) => {
  const reductionFactor = 1 - percentage / 100;
  const newHeight = originalHeight * reductionFactor;
  return newHeight;
};

export const calculateTimeDifference = (value: string | number): string => {
  const isString = typeof value === 'string';

  if (isString) {
    return value;
  }

  const timestampSeconds = Number(value);

  if (Number.isNaN(timestampSeconds)) {
    return '';
  }

  const timestampMilliseconds = timestampSeconds * 1000;

  const date = new Date(timestampMilliseconds);

  if (Number.isNaN(date.getTime())) {
    return '';
  }

  const hours = date.getHours();

  if (hours < 24) {
    // If the value is less than 24 hours, format it as HH:MM AM/PM
    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    return date.toLocaleTimeString('en-US', options);
  }
  // If the value is 24 hours or more, format it as "DD Mon"
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
  };
  return date.toLocaleDateString('en-US', options);
};

export const adaptUserConversation = (
  data: CometChat.Conversation[],
  userId: string,
  displayName: string
) =>
  data?.map((value: any) => ({
    senderId: value.sender?.toLowerCase(),
    receiverId: value.receiver?.toLowerCase(),
    messageId: value.id,
    id: value.id,
    text: value?.data?.text ?? value.data.url,
    isSentByMe: value?.sender?.toLowerCase() === userId.toLowerCase(),
    isTextMessage: value.type === 'text',
    fileName: value?.data?.metadata?.fileName ?? '',
    time: formatTimeStampToString(value.updatedAt ?? ''),
    sender: displayName,
    sentAt: value?.sentAt ?? 0,
    readAt: value?.readAt,
  }));

export const getCurrentTimestamp = (): number => {
  const currentDate = new Date();
  return Math.floor(currentDate.getTime() / 1000);
};

export const mapLastMessage = (
  data: IActiveTenantUser[],
  userLastMessages: any,
  tenantId: string
) =>
  data.map((item: IActiveTenantUser) => {
    const userId = item.conversationId?.toLowerCase() ?? '';
    const isSentByMe =
      tenantId.toLowerCase() ===
      userLastMessages[userId]?.lastMessage.sender?.uid?.toLowerCase();
    const fileMessage = isSentByMe
      ? messageConstant.FILE_SENT
      : messageConstant.FILE_RECEIVED;

    return {
      ...item,
      unreadMessagesCount:
        userLastMessages[userId]?.unreadMessageCount > 0
          ? userLastMessages[userId]?.unreadMessageCount
          : null,
      lastMessage: {
        text:
          userLastMessages[userId]?.lastMessage?.type === 'file'
            ? fileMessage
            : userLastMessages[userId]?.lastMessage?.text || null,
        isSentByMe,
        sentAt: userLastMessages[userId]?.lastMessage?.updatedAt
          ? calculateTimeDifference(
              userLastMessages[userId]?.lastMessage?.updatedAt
            )
          : null,
        updatedAt: userLastMessages[userId]?.lastMessage?.updatedAt,
      },
    };
  });

export const getMaximumThresholdWidth = (
  windowLength: number,
  windowWidth: number,
  thresholdValue: number
) => {
  const { chatListWindowWidth, chatWindowWidth, maximumThresholdValue } =
    layoutConstants.CHAT;

  const threshold =
    windowLength * chatWindowWidth +
    chatListWindowWidth +
    (thresholdValue > 0 ? thresholdValue : maximumThresholdValue);

  if (threshold >= windowWidth) {
    store.dispatch(setRemoveInitialUser());
  }
};

export const groupMessagesByDate = (messages: IResponseMessage[]) => {
  const sortedData = messages.sort((a, b) => a.sentAt - b.sentAt);

  const groupedData = sortedData.reduce((acc, message) => {
    const sentAtDate = new Date(message.sentAt * 1000);
    let formattedDate;

    // Check if the date is today
    if (
      sentAtDate.getDate() === new Date().getDate() &&
      sentAtDate.getMonth() === new Date().getMonth() &&
      sentAtDate.getFullYear() === new Date().getFullYear()
    ) {
      formattedDate = 'Today';
    } else {
      formattedDate = sentAtDate.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
      });
    }

    acc[formattedDate] = acc[formattedDate] || {
      date: formattedDate,
      sentAt: message.sentAt,
      messages: [],
    };
    acc[formattedDate].messages.push(message);
    return acc;
  }, {} as Record<string, { date: string; sentAt: number; messages: IResponseMessage[] }>);

  const resultArray = Object.values(groupedData);

  resultArray.forEach((group) => {
    const lastMessageIndex = group.messages.length - 1;
    group.messages.forEach((message, index) => {
      const messageCopy = {
        ...message,
        isLastMessage:
          index === lastMessageIndex &&
          group === resultArray[resultArray.length - 1],
      };

      messageCopy.status = message?.readAt
        ? MessageStatus.SEEN
        : MessageStatus.DELEVIRED;

      Object.assign(message, messageCopy);
    });
  });

  return resultArray;
};

export const pushMessageToGroup = (
  oldMesssage: IAdaptedResponseMessage[],
  newMessage: IAdaptedMessage
) => {
  let tempMessages = oldMesssage;
  const currentTimeStamp = getCurrentTimestamp();

  // this is the data format for chat messages so if chatmessages is empty append the data first
  if (!tempMessages?.length) {
    tempMessages = [
      { date: 'Today', messages: [newMessage], sentAt: getCurrentTimestamp() },
    ];
  } else {
    const index = tempMessages.findIndex((value: IAdaptedResponseMessage) =>
      compareTimeStamp(value.sentAt, currentTimeStamp)
    );

    if (index !== -1) {
      tempMessages[tempMessages.length - 1].messages.push(newMessage);
    } else {
      tempMessages = [
        ...oldMesssage,
        {
          date: 'Today',
          messages: [newMessage],
          sentAt: getCurrentTimestamp(),
        },
      ];
    }
  }

  return tempMessages;
};

export const getConversation = async (
  conversationId: string,
  tenantId: string,
  displayName: string,
  uid: string
) =>
  new Promise((resolve, reject) => {
    CometChatService.fetchConversationList(conversationId)
      .then((data) => {
        const mapData = adaptUserConversation(
          data.data?.data,
          tenantId,
          displayName ?? ''
        );

        if (mapData.length) {
          const value = data.data?.data.length;
          const lastMessage = data.data?.data[value - 1];

          // when user open the chat box..set last message as read
          CometChat.markAsRead(
            lastMessage.id,
            lastMessage.receiver,
            'user',
            lastMessage.sender
          );
          CometChatService.markMessageAsSeen(uid, tenantId);
          resolve(mapData);
        } else {
          resolve([]);
        }
      })
      .catch(() => {
        reject();
      });
  });

export const updateMessage = (
  senderId: string,
  messageText: string,
  conversationId: string,
  updatedAt: number,
  isWindowOpen: boolean
) => {
  if (isWindowOpen) {
    // update the user in chatlist if new message is received
    store.dispatch(
      setUpdateTenantConversationList({
        userId: senderId,
        conversationId,
        text: messageText ?? '',
        setUser: false,
      })
    );
  }

  // Update the message in chat list if user is not active
  store.dispatch(
    setUpdateUserOnMsg({
      id: senderId,
      message: messageText ?? '',
      updatedAt,
    })
  );
};

export const updateLastMessage = (
  messages: IAdaptedResponseMessage[],
  status: string
) => {
  const tempMessages = messages;
  tempMessages[tempMessages.length - 1].messages[
    tempMessages[tempMessages.length - 1].messages.length - 1
  ].status = status;
  return tempMessages;
};

export const sortByDate = (
  sentA: Date | undefined | null,
  sentB: Date | undefined | null
) => {
  if (sentA && sentB) {
    return new Date(sentB).getTime() - new Date(sentA).getTime();
  }
  if (sentA) {
    return -1;
  }
  if (sentB) {
    return 1;
  }
  return 0;
};

export const fetchUnreadMessage = async () => {
  const unReadCount = await CometChat.getUnreadMessageCountForAllUsers();

  store.dispatch(setUnreadMessage(unReadCount));

  // Get messages for the tenant
  const conversationRequest = new CometChat.ConversationsRequestBuilder()
    .setLimit(50)
    .setConversationType(CometChat.RECEIVER_TYPE.USER)
    .build();

  conversationRequest.fetchNext().then(
    (conversationList) => {
      store.dispatch(setLastMessage(conversationList));
    },
    () => {}
  );
};
