import { ReferenceType } from 'enums/common';
import { selectAuthTenantData } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

export const useCheckUserIsEmail = () => {
  const tenantData = useAppSelector(selectAuthTenantData);

  const referenceType = tenantData?.tenantAssociation?.referenceType;

  const isUserEmail = referenceType === ReferenceType.EMAIL;

  return isUserEmail;
};
