export enum CalenderViewType {
  DAY = 'timeGridDay',
  WEEK = 'timeGridWeek',
  MONTH = 'dayGridMonth',
}
export enum IFrequency {
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
}

export enum IWEEKDAY {
  SUN = 'SU',
  MON = 'MO',
  TUE = 'TU',
  WED = 'WE',
  THU = 'TH',
  FRI = 'FR',
  SAT = 'SA',
}
export enum IWEEKDAYFULL {
  SUN = 'Sunday',
  MON = 'Monday',
  TUE = 'Tuesday',
  WED = 'Wednesday',
  THU = 'Thursday',
  FRI = 'Friday',
  SAT = 'Saturday',
}

export enum EventInterval {
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
}

export enum EMonth {
  Jan = 1,
  Feb = 2,
  Mar = 3,
  Apr = 4,
  May = 5,
  Jun = 6,
  Jul = 7,
  Aug = 8,
  Sep = 9,
  Oct = 10,
  Nov = 11,
  Dec = 12,
}

export enum RecurringMonthType {
  ONLY_THIS_MONTH = 'Only This Month',
  REPEAT_EVERY_MONTH = 'Every Month',
}

export enum INEVER {
  NEVER = 'never',
}
export enum EEventType {
  ALL = 'all',
  APPOINTMENT = 'appointment',
  AVAILABLE = 'available',
  AWAY = 'away',
  VIRTUAL_VISIT = 'virtualvisit',
}

export enum RecurringEventChangeType {
  ALL = 'all',
  SELF = 'self',
  SELF_AND_FOLLOWING = 'self-and-following',
}

export enum RecurringEndType {
  ON = 'on',
  AFTER = 'after',
  NEVER = 'never',
}
