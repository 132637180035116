import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';
import { DictionaryKeys } from 'constants/languageDictionary';
import uiRoutes from 'constants/uiRoutes';
import { Routes } from 'enums/common';
import { useCheckUserIsTenantAdmin } from 'hooks/useCheckUserIsTenantAdmin';
import { useTenantTerminology } from 'hooks/useTenantTerminology';
import { IPermission } from 'interfaces/common';
import { selectActiveMenu } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { getModifiedPermissionWithPatientTerminology } from 'utils/auth';

import HeaderMenu from './HeaderMenu';
import HeaderMenuChild from './HeaderMenuChild';

const HeaderMenuList = () => {
  const activeMenu = useAppSelector(selectActiveMenu);
  const patientTerminology = useTenantTerminology([
    DictionaryKeys.PATIENT_TERMINOLOGY,
  ])._PATIENT_TERMINOLOGY;

  const isTenantAdmin = useCheckUserIsTenantAdmin();

  const isTimeSheetByIndividualSubmenuHidden = (path: string) =>
    path === uiRoutes.timesheet.byClient && isTenantAdmin;

  const updatedMenu = getModifiedPermissionWithPatientTerminology({
    allMenus: activeMenu || [],
    patientTerminology,
  }) as IPermission;

  const updatedMenuChildren = getModifiedPermissionWithPatientTerminology({
    allMenus:
      updatedMenu?.children?.filter(
        (child) => !isTimeSheetByIndividualSubmenuHidden(child?.routePath || '')
      ) || [],
    patientTerminology,
  }) as IPermission[];

  const location = useLocation();
  const isSettingRoutes =
    updatedMenu?.code === Routes.SETTING ||
    location.pathname.includes('/settings');

  const renderedHeaderMenuContainer = (
    <Box display="flex">
      {updatedMenuChildren &&
        updatedMenuChildren.map((menu) => (
          <HeaderMenuChild key={menu.resourceId} menu={menu} />
        ))}
    </Box>
  );

  if (!activeMenu) return <Box />;

  if (updatedMenuChildren && !isSettingRoutes) {
    return (
      <Box display="flex">
        <Box alignItems="center" display="flex">
          <HeaderMenu isSettingRoutes={isSettingRoutes} menu={updatedMenu} />
        </Box>
        {renderedHeaderMenuContainer}
      </Box>
    );
  }

  if (updatedMenu) {
    return (
      <HeaderMenu
        isSettingRoutes={isSettingRoutes}
        menu={updatedMenu as IPermission}
      />
    );
  }

  return <p> &nbsp;</p>;
};

export default HeaderMenuList;
