import commonConstants from 'constants/common';
import { DocumentType } from 'enums/documents';
import { IDocument } from 'interfaces/documents';

export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result?.toString().split(',')[1]);
    reader.onerror = (error) => reject(error);
  });

export const toArrayBuffer = (buf: any) => {
  const ab = new ArrayBuffer(buf.length);
  const view = new Uint8Array(ab);
  return view.map((x, i) => buf[i]);
};

export const getExtensionTypeOfFile = (name: string) =>
  name.slice(name.lastIndexOf('.'));

export const getFilenameWithoutExtension = (name: string) =>
  name.slice(0, name.lastIndexOf('.'));

export const getFileNameWithExtension = (
  originalName: string,
  name: string
) => {
  const extension = getExtensionTypeOfFile(originalName);
  return `${name}${extension}`;
};

export const getMimeType = (filename: string) => {
  const typeOfFile = getExtensionTypeOfFile(filename);
  switch (typeOfFile) {
    case '.doc':
    case '.DOC':
      return 'application/msword';
    case '.pdf':
    case '.PDF':
      return 'application/pdf';
    case '.png':
    case '.PNG':
      return 'image/png';
    case '.jpeg':
    case '.jpg':
    case '.JPEG':
    case '.JPG':
      return 'image/jpeg';
    case '.json':
    case '.JSON':
      return 'application/json';
    case '.docx':
    case '.DOCX':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case '.txt':
    case '.TXT':
      return 'text/plain';
    default:
      return 'text/plain';
  }
};

const handleFileDownload = (path: string, name: string) => {
  const link = document.createElement('a');
  link.href = path;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFromURI = async ({
  name,
  path,
  handleError,
}: {
  name: string;
  path: string;
  handleError: VoidFunction;
}) => {
  const image = await fetch(path).catch((e) => {
    handleFileDownload(path, name);
    handleError();
    return e;
  });
  const imageBlob = await image.blob();
  const imageURL = URL.createObjectURL(imageBlob);
  handleFileDownload(imageURL, name);
};

export function isBrowserSupportedFile(path: string, type?: string) {
  const fileType = getExtensionTypeOfFile(path);
  const isImage = commonConstants.IMAGE_EXTENSION.includes(fileType);
  const isPdf = commonConstants.DOCUMENT_EXTENSION.includes(fileType);
  const isAudio = commonConstants.AUDIO_EXTENSION.includes(fileType);
  const isVideo = commonConstants.VIDEO_EXTENSION.includes(fileType);

  return isImage || isPdf || isAudio || isVideo || type === DocumentType.URI;
}
