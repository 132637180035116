import {
  AuthorizeDotNetBankAccountType,
  GatewayType,
  PaymentEnvironment,
  PaymentMethod,
} from 'enums/Payment';
import UserType from 'enums/User';
import { IClientGroup, IClientIndividual } from 'interfaces/client-management';
import { IListResponse, IResponse } from 'interfaces/http';
import {
  IAdaptedPaymentSource,
  IPaymentForm,
  IPaymentSource,
} from 'interfaces/payment';
import { ITenantPaymentSchema } from 'interfaces/settings/billings';
import { RootState, store } from 'stores';

import { formatDateView } from './moment';

const { CHECKING, SAVINGS, BUSINESS_CHECKING } = AuthorizeDotNetBankAccountType;

const TYPE = 'Client';
const COUNTRY = 'US';

export const formatAddPaymentSourcePayload = ({
  paymentInfo,
  clientData,
  tenantId,
  gatewayType,
}: {
  paymentInfo: IPaymentForm;
  clientData: IClientGroup | IClientIndividual | ITenantPaymentSchema;
  tenantId: string;
  gatewayType: GatewayType;
}) => {
  const isGroup =
    (clientData as IClientGroup | IClientIndividual | ITenantPaymentSchema)
      .type === UserType.GROUP;
  const businessName = isGroup
    ? (clientData as IClientGroup | ITenantPaymentSchema).businessName
    : '';

  const firstName = isGroup
    ? (clientData as IClientGroup)?.contactFirstName
    : (clientData as IClientIndividual).firstName;
  const lastName = isGroup
    ? (clientData as IClientGroup)?.contactLastName
    : (clientData as IClientIndividual).lastName;

  const rootState: RootState = store.getState();
  const paymentStore = rootState.payment;
  const { tenantData } = rootState.auth as any;
  const sourceId =
    tenantData?.tenantAssociation?.source?.id || tenantData?.source?.id || '';

  const payload: any = {
    tenantId,
    reference: {
      id: clientData.clientId || sourceId,
      type: TYPE,
    },
    billingAddress: {
      businessName,
      firstName,
      lastName,
      phone: clientData.phone ?? '',
      email: clientData.email ?? '',
      address: {
        addressLine1: clientData.address?.addressLine1 ?? '',
        addressLine2: clientData.address?.addressLine2 ?? '',
        city: clientData.address?.city ?? '',
        state: clientData.address?.state ?? '',
        zipCode: clientData.address?.zip ?? '',
        country: COUNTRY,
      },
    },
  };

  if (gatewayType === GatewayType.AUTHORIZE_DOT_NET) {
    payload.cardDetail = {
      paymentMethod: paymentInfo.paymentMethod as PaymentMethod,
      token:
        paymentInfo.paymentMethod === PaymentMethod.CARD
          ? paymentInfo.cardToken
          : paymentInfo.bankToken,
      tokenSource: paymentInfo.tokenSource,
      paymentEnvironment: paymentStore.gatewayDetail
        ?.environment as PaymentEnvironment,
      source: gatewayType,
    };
  }

  if (gatewayType === GatewayType.CARD_CONNECT) {
    payload.cardDetail = {
      paymentMethod: paymentInfo.paymentMethod as PaymentMethod,
      expiry:
        paymentInfo.paymentMethod === PaymentMethod.CARD
          ? paymentInfo.cardExpiry
          : undefined,
      token:
        paymentInfo.paymentMethod === PaymentMethod.CARD
          ? paymentInfo.cardToken
          : paymentInfo.bankToken,
      paymentEnvironment: paymentStore.gatewayDetail
        ?.environment as PaymentEnvironment,
      source: gatewayType,
    };
  }

  if (gatewayType === GatewayType.STRIPE) {
    payload.cardDetail = {
      paymentMethod: paymentInfo.paymentMethod as PaymentMethod,
      token:
        paymentInfo.paymentMethod === PaymentMethod.CARD
          ? paymentInfo.cardToken
          : paymentInfo.bankToken,
      paymentEnvironment: paymentStore.gatewayDetail
        ?.environment as PaymentEnvironment,
      source: gatewayType,
    };
  }

  return payload;
};

const convertMonthToDoubleDigit = (month: string) => {
  if (month && month.length === 1) return `0${month}`;
  return month;
};

const convertYearToDoubleDigit = (year: string) => {
  if (year && year.length > 2) return year.slice(-2);
  return year;
};

export const getCardExpiryText = (expiryMonth: string, expiryYear: string) =>
  `${convertMonthToDoubleDigit(expiryMonth)}/${convertYearToDoubleDigit(
    expiryYear
  )}`;

export const getCardNumberDisplayText = (last4Digit: string) =>
  `**** **** **** ${last4Digit}`;

export const getBankAccountNumberDisplayText = (last4Digit: string) =>
  `******${last4Digit}`;

/**
 * Prepare expiry for card connect iframe
 *
 * @param {string} expiryMonth
 * @param {string} expiryYear
 * @returns {string}
 *
 * @example
 *
 * expiryMonth = 01, expiryYear = 2024 -> 20241
 *
 */
export const getExpiryForIFrame = (expiryMonth: string, expiryYear: string) => {
  // expiryMonth = 01 -> month = 1, expiryMonth = 12 -> month = 12
  const month =
    expiryMonth?.length === 2 && expiryMonth.charAt(0) === '0'
      ? expiryMonth.charAt(1)
      : expiryMonth;
  return `${expiryYear}${month}`;
};

export const adaptPaymentSourceList = (
  res: IResponse<IListResponse<IPaymentSource>>
): IResponse<IListResponse<IAdaptedPaymentSource>> => ({
  ...res,
  data: {
    ...res.data,
    rows: res.data?.rows?.map((source) => ({
      ...source,
      formattedCreatedBy: source.createdBy ?? '-',
      formattedCreatedAt: source.createdAt
        ? formatDateView(source.createdAt) ?? '-'
        : '-',
    })),
  },
});

export const validateCardNumber = (data: string) => {
  if (!data) return false;
  return data.length >= 14 && data.length <= 16;
};

export const validateCardExpiry = (data: string) => {
  if (!data) return false;
  return data.length === 4;
};

export const validateCardCVC = (data: string) => {
  if (!data) return false;
  return data.length === 3 || data.length === 4;
};

export const validateBankRoutingNumber = (data: string) => data?.length === 9;

export const validateBankAccountType = (data: string) =>
  [CHECKING, SAVINGS, BUSINESS_CHECKING].includes(
    data as AuthorizeDotNetBankAccountType
  );

export const validateBankAccountNumber = (data: string) =>
  data?.length >= 4 && data?.length <= 17;
