import { useEffect, useRef, useState } from 'react';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { debounce } from 'utils/lodash';

type SearchBarProps = {
  onSearchKeywordChange: (value: string) => void;
  placeholder?: string;
  fullWidth?: boolean;
} & TextFieldProps;

const SearchBar = ({
  onSearchKeywordChange,
  placeholder,
  fullWidth,
  ...textFieldProps
}: SearchBarProps) => {
  const [keyword, setKeyword] = useState('');

  const debouncedSearchHandler = useRef(
    debounce(async (value: string) => {
      if (value.length > 2 || !value.length)
        onSearchKeywordChange(value.trim());
    }, 300)
  ).current;

  const searchHandler = (value: string) => {
    setKeyword(value);
    debouncedSearchHandler(value);
  };

  useEffect(
    () => () => {
      debouncedSearchHandler.cancel();
    },
    [debouncedSearchHandler]
  );

  return (
    <TextField
      className="filled-variant"
      fullWidth={fullWidth}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FontAwesomeIcon icon={faSearch} />
          </InputAdornment>
        ),
      }}
      onChange={(event) => searchHandler(event.target.value)}
      placeholder={placeholder}
      size="small"
      value={keyword}
      {...textFieldProps}
    />
  );
};

SearchBar.defaultProps = {
  placeholder: 'Search',
  fullWidth: false,
};

export default SearchBar;
