import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import { IPermission } from 'interfaces/common';
import { Icons } from 'lib/font-awesome';

interface IProps {
  menu: IPermission;
  isSettingRoutes: boolean;
}

const HeaderMenuParent = ({ menu, isSettingRoutes }: IProps) => {
  // a random icon if icon not present.
  // Needed 'cause Typescript was showing type error
  // Not rendered whatsoever if icon is not present
  const Icon = Icons[menu.icon] ? Icons[menu.icon] : Icons.faFile;

  return (
    <Box alignItems="center" display="flex">
      <Box
        component={FontAwesomeIcon}
        icon={Icon}
        mr={1.5}
        size="lg"
        sx={{ color: (theme) => theme.palette.primary.main }}
      />
      <Typography gutterBottom={false} mt={0.125} variant="body1">
        {menu.name}
      </Typography>
      {menu.children && menu.children.length > 0 && !isSettingRoutes && (
        <Box
          component={FontAwesomeIcon}
          icon={Icons.faChevronRight}
          mx={1.5}
          size="lg"
          sx={{ color: (theme) => theme.palette.gray.main }}
        />
      )}
    </Box>
  );
};

export default HeaderMenuParent;
