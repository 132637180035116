import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip } from '@mui/material';
import {
  MiscellaneousCode,
  TenantSettingStatus,
} from 'enums/tenant-management/tenant';
import { useGetMiscellaneousTenantSettings } from 'hooks/useGetMiscellaneousTenantSettings';

export const VipCrown = () => {
  const isVipSettingsEnabled =
    useGetMiscellaneousTenantSettings(MiscellaneousCode.VIP_CLIENT) ===
    TenantSettingStatus.ENABLED;
  if (!isVipSettingsEnabled) return null;
  return (
    <Tooltip arrow placement="top" title="VIP">
      <Box>
        <Box
          color="secondary.main"
          component={FontAwesomeIcon}
          icon={faCrown}
        />
      </Box>
    </Tooltip>
  );
};
