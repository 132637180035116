import { Box } from '@mui/material';
import { useCheckFetchingOfferingsEnabled } from 'common/manage-membership/hooks/useCheckFetchingOfferingsEnabled';
import { useUpdateGroupDetails } from 'common/manage-membership/hooks/useUpdateGroupDetails';

import { GroupMembership } from './GroupMembership';
import { IndividualMembershipList } from './IndividualMembershipList';

export const MembershipOptions = () => {
  useUpdateGroupDetails();

  const { isGroupOfferingEnabled, isIndividualOfferingEnabled } =
    useCheckFetchingOfferingsEnabled();

  return (
    <Box marginTop={2}>
      {isIndividualOfferingEnabled && <IndividualMembershipList />}
      {isGroupOfferingEnabled && <GroupMembership />}
    </Box>
  );
};
