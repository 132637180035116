import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TabContext, TabList } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { TabPanel } from 'common';
import { useAuthorizedDocumentList } from 'features/case-layout-new/hooks/useAuthorizedDocumentList';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { useDocumentListQuery } from 'services/documents';
import {
  changeDocuments,
  selectCurrentReferralCase,
  selectCurrentReferralCaseReferenceFile,
} from 'stores/cases';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { getAllyPropsForTabs } from 'utils/mui';

import Files from './Files';
import ReferralCaseFileNoteLayout from './layout/ReferralCaseFileNoteLayout';
import Notes from './Notes';
import Tasks from './Tasks';

interface INoteFilesTabProps {
  drawerView?: boolean;
}

const NoteFilesTab = ({ drawerView }: INoteFilesTabProps) => {
  const [searchParams] = useSearchParams();
  const caseTaskId = searchParams.get('taskId');
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState('0');
  const [activeTaskId, setActiveTaskId] = useState('');

  const isUserClient = useCheckUserIsClient();

  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const caseId = currentReferralCase?.caseId || null;
  const activeFileId = useAppSelector(selectCurrentReferralCaseReferenceFile);

  const documentListQuery = useDocumentListQuery(
    {
      associationId: caseId,
    },
    {
      enabled: !!caseId,
    }
  );

  /**
   * Select the file tab if a file is to be highlighted.
   */
  useEffect(() => {
    if (activeFileId) {
      setActiveTab('1');
    }
  }, [activeFileId]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue.toString());
  };

  useEffect(() => {
    if (caseTaskId) {
      setActiveTaskId(caseTaskId);
      setActiveTab('2');
    }
  }, [caseTaskId]);

  const authorizedDocumentList = useAuthorizedDocumentList();

  useEffect(() => {
    if (documentListQuery.data) {
      const data = authorizedDocumentList(documentListQuery.data);
      dispatch(changeDocuments(data));
    }
  }, [authorizedDocumentList, dispatch, documentListQuery.data]);

  return (
    <ReferralCaseFileNoteLayout drawerView={drawerView}>
      <Box>
        <TabContext value={activeTab.toString()}>
          <TabList
            aria-label="referral-case-tabs"
            onChange={handleTabChange}
            value={activeTab.toString()}
          >
            <Tab
              data-cy="case-tab-notes"
              label="Notes"
              {...getAllyPropsForTabs(0)}
              href="#"
              value="0"
            />
            <Tab
              data-cy="case-tab-files"
              label="Files"
              {...getAllyPropsForTabs(1)}
              href="#"
              value="1"
            />
            {!isUserClient && (
              <Tab
                data-cy="case-tab-tasks"
                label="Tasks"
                {...getAllyPropsForTabs(2)}
                href="#"
                value="2"
              />
            )}
          </TabList>
          <TabPanel index={0} value={+activeTab}>
            <Notes drawerView={drawerView} />
          </TabPanel>
          <TabPanel index={1} value={+activeTab}>
            <Files />
          </TabPanel>
          {!isUserClient && (
            <TabPanel index={2} value={+activeTab}>
              <Tasks activeTaskId={activeTaskId} />
            </TabPanel>
          )}
        </TabContext>
      </Box>
    </ReferralCaseFileNoteLayout>
  );
};
NoteFilesTab.defaultProps = {
  drawerView: false,
};
export default NoteFilesTab;
