import { useMemo } from 'react';
import ReactCodeInput from 'react-code-input';

import { useTheme } from '@mui/material';
import commonConstants from 'constants/common';

interface IProps {
  digitLength?: number;
  value: string;
  onChange: (value: string) => void;
  isError?: boolean;
  disabled?: boolean;
}

const CodeInput = ({
  digitLength,
  disabled,
  isError,
  onChange,
  value,
}: IProps) => {
  const theme = useTheme();

  const styles: {
    inputStyle: React.CSSProperties | undefined;
    inputStyleInvalid: React.CSSProperties | undefined;
  } = useMemo(
    () => ({
      inputStyle: {
        margin: '4px',
        outline: 'none',
        width: '60px',
        height: '65px',
        borderRadius: '3px',
        fontSize: '25px',
        fontWeight: 'bold',
        backgroundColor: theme.palette.gray.lighter,
        color: theme.palette.text.primary,
        border: `1px solid`,
        borderColor: theme.palette.gray.main,
        textAlign: 'center',
      },
      inputStyleInvalid: {
        margin: '4px',
        width: '60px',
        height: '65px',
        borderRadius: '3px',
        fontSize: '25px',
        fontWeight: 'bold',
        backgroundColor: 'white',
        color: 'red',
        border: '1px solid red',
        textAlign: 'center',
      },
    }),
    [
      theme.palette.gray.lighter,
      theme.palette.gray.main,
      theme.palette.text.primary,
    ]
  );

  return (
    <ReactCodeInput
      autoFocus
      className="hide-input-number-spin"
      disabled={disabled}
      fields={digitLength}
      inputMode="numeric"
      name="verificationCode"
      type="number"
      {...styles}
      isValid={isError}
      onChange={(val) => onChange(val)}
      value={value}
    />
  );
};

CodeInput.defaultProps = {
  digitLength: commonConstants.TWO_FACTOR_AUTHENTICATION_CODE_DIGITS,
  disabled: false,
  isError: undefined,
};
export default CodeInput;
