import { CometChat } from '@cometchat-pro/chat';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenantConnStatus } from 'enums/chat';
import {
  IActiveTenantUser,
  IAdaptedTenantUser,
  IChatStore,
  IConversationPayload,
  ITenantUser,
} from 'interfaces/chat';
import { IObjectLiteral } from 'interfaces/common';
/* eslint-disable no-param-reassign */
import { RootState } from 'stores';
import {
  calculateTimeDifference,
  getCurrentTimestamp,
  mapLastMessage,
  sortTenants,
  sortTenantsWithPriority,
} from 'utils/chat';
import { getFullName } from 'utils/common';

const initialState: IChatStore = {
  tenantId: '',
  tenantConversationList: [],
  tenantList: [],
  connectedTenantIds: [],
  activeTenantList: [],
  uploadFiles: [],
  isLoading: true,
  lastMessage: null,
  unreadMessages: [],
  listener: false,
  closeWindow: false,
};

export const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setAddNewtenant: (state, action: PayloadAction<IAdaptedTenantUser>) => {
      if (action.payload.isEdit) {
        const findTenantIndex = state.tenantList.findIndex(
          (item) =>
            item.userId?.toLowerCase() === action.payload?.userId?.toLowerCase()
        );
        const findActiveTenantIndex = state.tenantConversationList.findIndex(
          (item) =>
            item.userId?.toLowerCase() === action.payload?.userId?.toLowerCase()
        );

        if (findTenantIndex !== -1) {
          state.tenantList[findTenantIndex] = {
            ...state.tenantList[findTenantIndex],
            demographic: action.payload.demographic,
          };
        }
        if (findActiveTenantIndex !== -1) {
          state.tenantConversationList[findActiveTenantIndex] = {
            ...state.tenantConversationList[findActiveTenantIndex],
            demographic: action.payload.demographic,
          };
        }
      } else {
        const tenantList = sortTenants([...state.tenantList, action.payload]);
        state.tenantList = tenantList;
        state.tenantConversationList = tenantList;
      }
    },
    setTenantList: (state, action: PayloadAction<ITenantUser[]>) => {
      const tenantUserList = sortTenants(action.payload);

      state.tenantConversationList = tenantUserList;
      state.tenantList = tenantUserList;
    },
    setUpdateTenantConversationList: (state, action: PayloadAction<any>) => {
      const userId = action.payload.userId?.toLowerCase();

      const findIndex = state.tenantConversationList.findIndex(
        (item) => item.userId?.toLowerCase() === userId
      );
      const tenantIndex = state.tenantList.findIndex(
        (item) => item.userId?.toLowerCase() === userId
      );
      if (findIndex === -1) {
        let tenantUser = {};

        if (tenantIndex !== -1) {
          tenantUser = state.tenantList[tenantIndex];
        }

        // If the user window is open from new message and user receives the message than update user on chat list
        // If the user window is not open and user receives the message than update user on chat list
        // Since when user is selected from new message..the status of the user is set to friend
        // If user is friend than it will not be shown while searching
        // In that case user update on chatlist will fail
        // So get the index from the activeTenantList which is the chat window for the selected user

        if (findIndex === -1 && action.payload?.setUser) {
          tenantUser = state.activeTenantList[tenantIndex];
        }

        const tenantUserList = sortTenants([
          ...state.tenantConversationList,
          {
            ...tenantUser,
            ...action.payload,
            isFriend: true,
            isRegistered: true,
            fetchMessage: true,
            lastMessage: {
              text:
                action.payload.messageType === 'File'
                  ? 'File'
                  : action.payload.text,
              isSentByMe: true,
              sentAt: calculateTimeDifference(getCurrentTimestamp()),
            },
          },
        ]);
        state.tenantConversationList = tenantUserList;
      }
      // When send message to user set the parameters if parameters are not available
      // This is also used when a user is added from AdminModal directyly and updating the constratis locally
      state.tenantList[tenantIndex] = {
        ...state.tenantList[tenantIndex],
        conversationId: action.payload.conversationId,
        isFriend: true,
        isRegistered: true,
        lastMessage: {
          ...state.tenantList[tenantIndex].lastMessage,
          updatedAt: getCurrentTimestamp(),
        },
      };
    },
    setActiveTenantList: (state, action: PayloadAction<IActiveTenantUser>) => {
      const tenantIndex = state.activeTenantList.findIndex(
        (item) =>
          item.userId?.toLowerCase() === action?.payload?.userId?.toLowerCase()
      );
      if (tenantIndex !== -1) {
        return;
      }
      // add fetchMessage if convertaionId is present...this ensures conversation is fetched only once and avoids fetching other conversations
      const mapTenants = [action.payload, ...state.activeTenantList].map(
        (item) => ({
          ...item,
          fetchMessage: !!item?.conversationId,
        })
      );
      state.activeTenantList = mapTenants;
    },
    setRegisterUser: (state, action: PayloadAction<string>) => {
      const tenantIdIndex = state.activeTenantList.findIndex(
        (item) => item.userId.toLowerCase() === action.payload.toLowerCase()
      );
      // Set the tenant to friend and register
      if (tenantIdIndex !== -1) {
        const tenantToUpdate = state.activeTenantList[tenantIdIndex];
        tenantToUpdate.isRegistered = true;
        tenantToUpdate.isFriend = true;
        state.activeTenantList[tenantIdIndex] = tenantToUpdate;
      }
    },
    setConversationId: (state, action: PayloadAction<IConversationPayload>) => {
      const activeTenantIndex = state.activeTenantList.findIndex(
        (item) =>
          item.userId?.toLowerCase() === action.payload.userId?.toLowerCase()
      );

      const tenantIndex = state.tenantConversationList.findIndex(
        (item) =>
          item.userId?.toLowerCase() === action.payload.userId?.toLowerCase()
      );

      if (
        state.activeTenantList.length === 0 &&
        state.tenantConversationList[tenantIndex]?.conversationId
      ) {
        return;
      }

      if (
        state.activeTenantList.length > 0 &&
        state.tenantConversationList[tenantIndex]?.conversationId &&
        state.activeTenantList[activeTenantIndex]?.conversationId
      ) {
        return;
      }

      if (activeTenantIndex !== -1) {
        // Set the tenant to friend and register..this is need for both users who are active and who are in chat list

        const tenantToUpdate = state.activeTenantList[activeTenantIndex];
        tenantToUpdate.conversationId = action.payload.conversationId;
        tenantToUpdate.isRegistered = true;
        tenantToUpdate.isFriend = true;
        tenantToUpdate.fetchMessage = false;
        state.activeTenantList[activeTenantIndex] = tenantToUpdate;
        state.activeTenantList = [...state.activeTenantList];
      }
      if (tenantIndex !== -1) {
        const tenantToUpdate = state.tenantConversationList[tenantIndex];
        tenantToUpdate.conversationId = action.payload.conversationId;
        tenantToUpdate.isRegistered = true;
        tenantToUpdate.isFriend = true;
        tenantToUpdate.fetchMessage = false;
        state.tenantConversationList[tenantIndex] = tenantToUpdate;
        state.tenantConversationList = [...state.tenantConversationList];
      }
    },
    setUnreadMessage: (state, action: PayloadAction<IObjectLiteral>) => {
      // TODO: change this
      const mappedCount = Object.entries(action.payload).map(
        ([userId, unreadCount]) => ({
          user_id: userId,
          unread_count: unreadCount,
        })
      );

      const mapUnreadMessage = state.tenantConversationList.map((item) => {
        if (action.payload[item.userId] > 0) {
          return { ...item, unreadMessagesCount: action.payload[item.userId] };
        }
        return item;
      });

      state.unreadMessages = mappedCount;
      state.tenantConversationList = mapUnreadMessage;
    },
    setRemoveUnreadMessage: (state, action: PayloadAction<string>) => {
      const index = state.tenantConversationList.findIndex(
        (item) => item.userId?.toLowerCase() === action.payload?.toLowerCase()
      );

      const tenentIndex = state.tenantList.findIndex(
        (item) => item.userId?.toLowerCase() === action.payload?.toLowerCase()
      );

      if (tenentIndex !== -1) {
        state.tenantList[tenentIndex].unreadMessagesCount = null;
      }

      if (index !== -1) {
        const tenantToUpdate = state.tenantConversationList[index];
        tenantToUpdate.unreadMessagesCount = null;
        state.tenantConversationList[index] = tenantToUpdate;
        state.tenantConversationList = [...state.tenantConversationList];
      }
      const removeMessage = state.unreadMessages.filter(
        (item: any) =>
          item.user_id?.toLowerCase() !== action?.payload?.toLowerCase()
      );

      state.unreadMessages = removeMessage;
    },
    setRemoveActiveTenant: (state, action: PayloadAction<string>) => {
      state.activeTenantList = state.activeTenantList.filter(
        (item) => item.userId?.toLowerCase() !== action.payload.toLowerCase()
      );
      if (state.activeTenantList.length === 0) {
        state.listener = !state.listener;
      }
    },
    setTenantConnStatus: (
      state,
      action: PayloadAction<IConversationPayload>
    ) => {
      // This method avoids unnecessary mapping and rerendering

      if (action?.payload?.status === TenantConnStatus.AVAILABLE) {
        state.connectedTenantIds = [
          ...state.connectedTenantIds,
          action.payload?.userId ?? '',
        ];
      } else {
        state.connectedTenantIds = state.connectedTenantIds.filter(
          (item) => item !== action.payload.userId
        );
      }
    },
    setLastMessage: (
      state,
      action: PayloadAction<CometChat.Conversation[]>
    ) => {
      const userLastMessages: any = {};
      action.payload.forEach((item: IConversationPayload | any) => {
        const { conversationId } = item;
        userLastMessages[conversationId] = item;
      });

      // Map unread message count and last message
      const modifiedTenantData = sortTenants(
        mapLastMessage(
          state.tenantConversationList,
          userLastMessages,
          state.tenantId
        )
      );

      const modifiedTenantListData = mapLastMessage(
        state.tenantList,
        userLastMessages,
        state.tenantId
      );

      state.tenantConversationList = modifiedTenantData;
      state.tenantList = modifiedTenantListData;
    },
    setTenantId: (state, action: PayloadAction<string>) => {
      state.tenantId = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setUpdateUserOnMsg: (
      state,
      action: PayloadAction<IConversationPayload>
    ) => {
      const updateTenantList = sortTenantsWithPriority(
        state.tenantConversationList,
        action.payload.id ?? ''
      );
      const tenantListIndex = state.tenantList.findIndex(
        (item) =>
          item.userId?.toLowerCase() === action.payload?.id?.toLowerCase()
      );

      const activeIndex = state.activeTenantList.findIndex(
        (item) =>
          item.userId?.toLowerCase() === action.payload?.id?.toLowerCase()
      );

      const tenantIndex = state.tenantConversationList.findIndex(
        (item) =>
          item.userId?.toLowerCase() === action.payload?.id?.toLowerCase()
      );

      const tenantToUpdate = updateTenantList[tenantIndex];

      tenantToUpdate.unreadMessagesCount = activeIndex !== -1 ? null : 1;
      const updatedAt = calculateTimeDifference(
        action?.payload?.updatedAt ?? 0
      );
      tenantToUpdate.lastMessage = {
        text: action.payload.message,
        isSentByMe: action.payload?.isSentByMe ?? false,
        sentAt: updatedAt,
      };

      state.tenantConversationList[tenantIndex] = tenantToUpdate;
      if (tenantListIndex !== -1) {
        const updateTenant = state.tenantList[tenantListIndex];
        state.tenantList[tenantListIndex] = {
          ...updateTenant,
          lastMessage: {
            ...updateTenant.lastMessage,
            text: action.payload.message,
            isSentByMe: action.payload?.isSentByMe ?? false,
            sentAt: updatedAt,
          },
          unreadMessagesCount: 1,
        };
      }
    },
    setListener: (state) => {
      state.listener = !state.listener;
    },
    setSearchTenant: (state, action: PayloadAction<string>) => {
      let filterTenant: ITenantUser[] = [];

      if (action.payload) {
        const tenantUsers = [...state.tenantList];
        filterTenant = tenantUsers.filter((item) =>
          getFullName(
            item.demographic?.firstName,
            item?.demographic?.lastName,
            item?.demographic?.middleName ?? ''
          )
            ?.toLowerCase()
            .includes(action.payload?.toLowerCase())
        );
      } else {
        filterTenant = sortTenants(state.tenantList);
      }

      state.tenantConversationList = filterTenant;
    },
    setRemoveInitialUser: (state) => {
      state.activeTenantList.pop();
      state.activeTenantList = [...state.activeTenantList];
    },
    setTypingStatus: (state, action) => {
      const findIndex = state.activeTenantList.findIndex(
        (item) => item.userId.toLowerCase() === action.payload.uid
      );
      if (findIndex !== -1) {
        const tenentToUpdate = state.activeTenantList[findIndex];
        tenentToUpdate.typingStatus = {
          userId: '',
          status: action.payload.status,
        };
        state.activeTenantList[findIndex] = tenentToUpdate;
      }
    },
    setUnreadMessageUser: (state, action) => {
      const findIndex = state.activeTenantList.findIndex(
        (item) => item.userId.toLowerCase() === action.payload?.toLowerCase()
      );
      if (findIndex !== -1) {
        const tenentToUpdate = state.activeTenantList[findIndex];
        tenentToUpdate.unreadMessagesCount =
          (tenentToUpdate.unreadMessagesCount ?? 0) + 1;
        state.activeTenantList[findIndex] = tenentToUpdate;
      }
    },
    setRemoveUnreadMessageUser: (state, action) => {
      const findIndex = state.activeTenantList.findIndex(
        (item) => item.userId.toLowerCase() === action.payload?.toLowerCase()
      );
      if (findIndex !== -1) {
        const tenentToUpdate = state.activeTenantList[findIndex];
        tenentToUpdate.unreadMessagesCount = null;
        state.activeTenantList[findIndex] = tenentToUpdate;
      }
    },
    setSelectUpdateTenant: (state, action) => {
      // This uid is generated by uuidV4 not from database
      const findIndex = state.activeTenantList.findIndex(
        (item) => item.userId.toLowerCase() === action.payload.uid
      );
      const tenantIndex = state.tenantList.findIndex(
        (item) => item.userId.toLowerCase() === action.payload.userId
      );

      if (findIndex !== -1) {
        if (action.payload.user) {
          state.activeTenantList.splice(findIndex, 1, action.payload.user);
        }
      }
      if (tenantIndex !== -1) {
        const tenantToUpdate = state.tenantList[tenantIndex];
        tenantToUpdate.isFriend = action.payload?.isFriend ?? false;
        state.tenantList[tenantIndex] = tenantToUpdate;
      }
    },
    setDeleteConversation: (state, action: PayloadAction<string>) => {
      const uid = action.payload;
      const tenantConversationList = state.tenantConversationList.filter(
        (item) => item.userId?.toLowerCase() !== uid
      );
      const activeTenantList = state.activeTenantList.filter(
        (item) => item.userId?.toLowerCase() !== uid
      );

      const findIndex = state.tenantList.findIndex(
        (item) => item.userId?.toLowerCase() === uid
      );

      if (findIndex !== -1) {
        const tenantToUpdate = state.tenantList[findIndex];
        tenantToUpdate.conversationId = '';
        tenantToUpdate.isFriend = false;
        state.tenantList[findIndex] = tenantToUpdate;
      }
      state.activeTenantList = activeTenantList;
      state.tenantConversationList = tenantConversationList;
    },
    setCloseWindow: (state, action: PayloadAction<boolean>) => {
      state.closeWindow = action.payload;
    },
    setSortTenantConversationList: (state, action: PayloadAction<string>) => {
      state.tenantConversationList = sortTenantsWithPriority(
        state.tenantConversationList,
        action.payload
      );
    },
  },
});

// Actions
export const {
  setAddNewtenant,
  setIsLoading,
  setActiveTenantList,
  setTenantList,
  setRegisterUser,
  setConversationId,
  setTenantId,
  setRemoveActiveTenant,
  setTenantConnStatus,
  setUnreadMessage,
  setRemoveUnreadMessage,
  setLastMessage,
  setUpdateUserOnMsg,
  setListener,
  setSearchTenant,
  setRemoveInitialUser,
  setTypingStatus,
  setUnreadMessageUser,
  setRemoveUnreadMessageUser,
  setSelectUpdateTenant,
  setUpdateTenantConversationList,
  setDeleteConversation,
  setCloseWindow,
  setSortTenantConversationList,
} = slice.actions;

// Selectors

export const selectTeanantConversationList = (state: RootState) =>
  state.chat.tenantConversationList;
export const selectTeanantList = (state: RootState) => state.chat.tenantList;
export const selectIsLoading = (state: RootState) => state.chat.isLoading;
export const selectActiveTenantList = (state: RootState) =>
  state.chat.activeTenantList;
export const selectTenantId = (state: RootState) => state.chat.tenantId;
export const selectTenantConnStatus = (state: RootState) =>
  state.chat.connectedTenantIds;
export const selectUnreadMessage = (state: RootState) =>
  state.chat.unreadMessages;
export const selectListener = (state: RootState) => state.chat.listener;
export const selectCloseWindow = (state: RootState) => state.chat.closeWindow;

// Reducer
export default slice.reducer;
