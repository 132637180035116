import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Typography } from '@mui/material';
import commonConstants from 'constants/common';
import toastMessageConstant from 'constants/toastMessage';
import { ShareMode } from 'enums/common';
import { DocumentType, DocumentVisibility } from 'enums/documents';
import ModalKey from 'enums/ModalKey';
import { useCheckUserIsEmail } from 'hooks/useCheckUserisEmail';
import { useCheckUserIsTenantAdmin } from 'hooks/useCheckUserIsTenantAdmin';
import { IDocument } from 'interfaces/documents';
import { useSnackbar } from 'notistack';
import { useSetFileVisibilityMutation } from 'services/cases';
import {
  useAttachDocumentMutation,
  useDeleteDocumentMutation,
  useRenameDocumentMutation,
} from 'services/documents';
import { useFileDownloadMutation } from 'services/files';
import { selectActiveTenant, selectAuthTenantData } from 'stores/auth';
import {
  changeDocuments,
  selectCurrentReferralCase,
  selectCurrentReferralCaseDocuments,
} from 'stores/cases';
import { useConfirmationModal } from 'stores/ConfirmationModal';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import {
  downloadFromURI,
  getFileNameWithExtension,
  getFilenameWithoutExtension,
} from 'utils/file';

import { useCheckCaseReadOnlyView } from './useCheckCaseReadOnlyView';

interface IFileList {
  data: IDocument;
  isDownloadAccessEnabled?: boolean;
}
const useFileUploadLists = ({ data }: IFileList) => {
  const [isRename, setIsRename] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const isUserEmail = useCheckUserIsEmail();
  const isUserTenantAdmin = useCheckUserIsTenantAdmin();
  const isDocumentTypeURI = data.type === DocumentType.URI;

  const authTenantData = useAppSelector(selectAuthTenantData);
  const activeTenant = useAppSelector(selectActiveTenant);

  const [searchParams] = useSearchParams();
  const fileId = searchParams.get('fileId'); // mostly used to implement into public note page

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const renameDocumentMutation = useRenameDocumentMutation();
  const setVisibilityMutation = useSetFileVisibilityMutation();

  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const currentReferralCaseDocuments = useAppSelector(
    selectCurrentReferralCaseDocuments
  );
  const attachDocumentMutation = useAttachDocumentMutation();

  const deleteConfirmationModal = useConfirmationModal();

  const fileDownloadMutation = useFileDownloadMutation();
  const deleteDocumentMutation = useDeleteDocumentMutation();

  const showFileActionType = () => {
    if (isUserEmail) {
      return true;
    }
    return !isRename && !fileId;
  };

  const handleRename = () => {
    if (!name.trim()) return;
    renameDocumentMutation.mutate(
      {
        documentId: data.documentId,
        associationId: currentReferralCase!.caseId || '',
        isNotListView: true,
        data: {
          name: isDocumentTypeURI
            ? name.trim()
            : getFileNameWithExtension(data.name, name.trim()),
        },
      },
      {
        onSettled: () => {
          setIsRename(false);
        },
      }
    );
  };

  const onDownloadClick = (file: IDocument, view: string) => {
    fileDownloadMutation.mutate({
      filepath: file.path,
      name: file.name,
      download: { view },
    });
  };

  const DownloadFile = (downloadOptions: string) => {
    onDownloadClick(data, downloadOptions);
  };

  const handleURIError = () => {
    enqueueSnackbar(toastMessageConstant.DOCUMENT.DOWNLOAD_UNSUCCESS, {
      variant: 'error',
    });
  };

  const handleDownload = async (type: string) => {
    if (isDocumentTypeURI) {
      if (type === 'view') {
        window.open(data.path, '_blank', 'noreferrer');
      } else {
        await downloadFromURI({
          name: data.name,
          path: data.path,
          handleError: handleURIError,
        });
      }
    } else {
      const d = {
        status: true,
        view: type,
      };
      DownloadFile(d.view);
    }
  };

  const handleDelete = async () => {
    // await because result depends on confirmation model action
    const result = await deleteConfirmationModal?.openConfirmationModal({
      isAdditionalContentRequired: false,
      title: `Delete File`,
      content: (
        <Typography component="span">
          Are you sure you want to delete this
          <Typography
            component="span"
            sx={{ fontWeight: (t) => t.typography.fontWeightMedium }}
          >
            {' '}
            {data.name}{' '}
          </Typography>
          file?
        </Typography>
      ),
    });
    if (result) {
      deleteConfirmationModal?.changeSubmittingStatus(true);
      deleteDocumentMutation.mutate(
        {
          associationId: currentReferralCase!.caseId,
          documentId: data.documentId,
          type: commonConstants.REFERRAL_CASE,
        },
        {
          onSettled: () => {
            deleteConfirmationModal?.changeSubmittingStatus(false);
          },
        }
      );
    }
  };

  const handleAddToProfile = async () => {
    // await because result depends on confirmation model action
    const result = await deleteConfirmationModal?.openConfirmationModal({
      title: `Add To Profile`,
      content: (
        <Typography component="span">
          Do you want to add this
          <Typography
            component="span"
            sx={{ fontWeight: (t) => t.typography.fontWeightMedium }}
          >
            {' '}
            {data.name}{' '}
          </Typography>
          file?
        </Typography>
      ),
    });
    if (result) {
      deleteConfirmationModal?.changeSubmittingStatus(true);

      if (!currentReferralCase?.patient?.id) return;
      const patientId = currentReferralCase.patient.id!;
      const payload = {
        documentId: data.documentId,
        associationId: patientId,
        type: commonConstants.CLIENT,
      };
      attachDocumentMutation.mutate(
        {
          data: [payload],
          documents: [data],
          associationId: patientId,
        },

        {
          onSuccess(res) {
            if (!currentReferralCaseDocuments) return;

            const updatedDocuments = currentReferralCaseDocuments?.map((e) => {
              const index = res.data?.findIndex(
                (obj) => obj.documentId === e.documentId
              );

              if (index === -1) {
                return e;
              }
              return {
                ...e,
                associations: [res.data[index], ...e.associations],
              };
            });

            dispatch(changeDocuments(updatedDocuments));
          },
          onSettled: () => {
            deleteConfirmationModal?.changeSubmittingStatus(false);
          },
        }
      );
    }
  };
  const [isClientFileExists, setIsClientFileExists] = useState(false);

  const isSaveDisabled = () => {
    if (isDocumentTypeURI) {
      return data.name !== name && name.length;
    }
    return getFilenameWithoutExtension(data.name) !== name && name.length;
  };
  const extensionTrimmedName = () => {
    setName(
      isDocumentTypeURI ? data.name : getFilenameWithoutExtension(data.name)
    );
  };
  const onRenameClick = () => {
    extensionTrimmedName();
    setIsRename(true);
  };
  const onShareClick = () => {
    const encodedURI = encodeURI(
      `modal?type=${ModalKey.SHARE_FILE_MODAL}&caseId=${currentReferralCase?.caseId}&documentId=${data.documentId}&mode=${ShareMode.FILE}&fileName=${data.name}`
    );
    navigate(encodedURI);
  };
  const handleClose = () => {
    setName(data.name);
    setIsRename(false);
  };
  useEffect(() => {
    const isExists = data.associations?.some(
      (e) => e.type === commonConstants.CLIENT
    );
    setIsClientFileExists(isExists);
  }, [data.associations]);

  const { isReadOnly, ownerTenant } = useCheckCaseReadOnlyView({
    includePatientCheck: true,
  });

  const isFileCreatedByCurrentUser =
    data?.createdBy?.id === authTenantData?.userId;

  const isFileCreatedInCurrentTenant =
    data?.createdBy?.tenantId === activeTenant?.tenantId;

  const handleChangeVisibility = ({
    file,
    caseId,
    visibility,
  }: {
    file: IDocument;
    caseId: string;
    visibility: DocumentVisibility;
  }) => {
    setVisibilityMutation.mutate({
      documentId: file.documentId,
      caseId,
      data: {
        associationId: caseId,
        visibility,
      },
    });
  };

  return {
    isOwnerTenantAdmin: isUserTenantAdmin && ownerTenant,
    isFileCreatedByCurrentUser,
    isFileCreatedInCurrentTenant,
    isReadOnly,
    showFileActionType,
    handleAddToProfile,
    handleClose,
    handleDelete,
    isClientFileExists,
    onRenameClick,
    onShareClick,
    isSaveDisabled,
    handleRename,
    handleDownload,
    fileDownloadMutation,
    deleteConfirmationModal,
    isDocumentTypeURI,
    isRename,
    name,
    setName,
    fileId,
    handleChangeVisibility,
    isSetVisibilityMutationLoading: setVisibilityMutation.isLoading,
  };
};

export default useFileUploadLists;
