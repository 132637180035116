import { useMutation } from 'react-query';

import { generateTinyUrl, validateTinyUrl } from 'api/tiny-url';

export const useTinyUrlMutation = () =>
  useMutation(({ hash }: { hash: string }) => validateTinyUrl(hash));

export const useGenerateTinyUrlMutation = () =>
  useMutation(({ originalUrl }: { originalUrl: string }) =>
    generateTinyUrl(originalUrl)
  );
