import { ReferenceType } from 'enums/common';
import { selectCurrentReferralCase } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';

/**
 * Case Owner Tenant => Tenant in which the case is created.
 * (not the invitee tenant)
 *
 */
export const useCaseOwnerTenant = () => {
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);

  if (!currentReferralCase) return null;

  return currentReferralCase?.access?.find(
    (item) =>
      item.referenceType === ReferenceType.TENANT &&
      !item.sharedBy?.id &&
      !!item.referenceId
  );
};
