import { selectManageMembershipClientMemberships } from 'stores/client-management/manage-membership';
import { useAppSelector } from 'stores/hooks';

export const useCheckGroupMembership = () => {
  const memberships = useAppSelector(selectManageMembershipClientMemberships);

  const groupMembership = memberships?.find((membership) => membership.groupId); // TODO: how to handle multiple group memberships?

  return {
    groupMembership,
  };
};
