import { useSearchParams } from 'react-router-dom';

import commonConstants from 'constants/common';

export const useHandleSelectedCaseId = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentQueryParameters, setSearchParams] = useSearchParams();
  const newQueryParameters: URLSearchParams = new URLSearchParams();

  const changeSelectedCase = (caseId: string) => {
    newQueryParameters.set(commonConstants.CASE_ID_QUERY_PARAMETER, caseId);
    setSearchParams(newQueryParameters);
  };
  return {
    changeSelectedCase,
  };
};
