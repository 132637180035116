import { getCase } from 'api/cases';
import { EmailType } from 'enums/SharedResourceAuthenticator';
import { IEmailToken } from 'interfaces/SharedResourceAuthenticator';
import {
  prepareDataForShareCase,
  prepareDataForShareFile,
  prepareDataForTagNote,
} from 'utils/cases';
import { getFullName } from 'utils/common';
import { setSession } from 'utils/storage';

export const useHandleResourceOTPVerification = ({
  emailToken,
}: {
  emailToken: IEmailToken | null;
}) => {
  const handleOtpVerificationSuccessForTagNote = async (token: IEmailToken) => {
    const response = await getCase(token.metadata.caseId!);
    const casePatient = response.data.patient;
    let oldDecodedData = prepareDataForTagNote(token);
    oldDecodedData = {
      ...oldDecodedData,
      patientId: response.data.patient?.id || '',
      patientName: getFullName(
        casePatient?.firstName,
        casePatient?.lastName,
        casePatient?.middleName
      ),
      requestedDate: response.data.createdDate,
    };

    setSession('messageId', token.metadata.noteId);
    setSession('caseId', token.metadata.caseId);
    setSession('fromEmail', true);
    setSession('emailNotesData', oldDecodedData);
    setSession('decodedDataForOTP', oldDecodedData);
  };

  const handleOtpVerificationSuccessForShareFile = async (
    token: IEmailToken
  ) => {
    const response = await getCase(token.metadata.caseId!);
    const casePatient = response.data.patient;
    let oldDecodedData = prepareDataForShareFile(token);
    oldDecodedData = {
      ...oldDecodedData,
      clientId: response.data.patient?.id || '',
      patientName: getFullName(
        casePatient?.firstName,
        casePatient?.lastName,
        casePatient?.middleName
      ),
      requestedDate: response.data.createdDate,
    };

    setSession('fileId', token.metadata.fileId);
    setSession('caseId', token.metadata.caseId);
    setSession('fromEmail', true);
    setSession('emailNotesData', oldDecodedData);
    setSession('decodedDataForOTP', oldDecodedData);
  };

  const handleOtpVerificationSuccessForShareCase = async (
    token: IEmailToken
  ) => {
    const oldDecodedData = prepareDataForShareCase(token);

    setSession('caseId', token.metadata.caseId);
    setSession('fromEmail', true);
    setSession('emailNotesData', oldDecodedData);
    setSession('decodedDataForOTP', oldDecodedData);
    setSession('guestEmail', token.receiver.email);
    setSession('shareCase', true);
    setSession('isFromLink', true);
  };

  const handleOtpVerificationSuccess = async () => {
    if (!emailToken) return;

    switch (emailToken.type) {
      case EmailType.TAG_NOTE:
        await handleOtpVerificationSuccessForTagNote(emailToken);
        break;
      case EmailType.SHARE_CASE_FILE:
        await handleOtpVerificationSuccessForShareFile(emailToken);
        break;
      case EmailType.SHARE_CASE:
        await handleOtpVerificationSuccessForShareCase(emailToken);
        break;
      default:
    }
  };

  return {
    handleOtpVerificationSuccess,
  };
};
