import { Controller, useFormContext } from 'react-hook-form';

import {
  TextField,
  TextFieldProps,
  Typography,
  TypographyVariant,
} from '@mui/material';
import customRegex from 'constants/regex';

type Props = {
  name: string;
  label: string;
  placeholder?: string;
  showHelperText?: boolean;
  required?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
  width?: string;
  type?: 'text' | 'number' | 'password';
  labelVariant?: TypographyVariant;
} & TextFieldProps;

const FormTextField = ({
  name,
  label,
  showHelperText,
  required,
  placeholder,
  variant,
  width,
  type = 'text',
  helperText,
  labelVariant,
  ...others
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    if (type === 'number') {
      if (others?.inputProps?.inputMode !== 'decimal') {
        return newValue;
      }

      /* checks if only or less than 2 digits after decimal place and must be a positive number */
      if (customRegex.DECIMAL_WITH_TWO_POINT.test(newValue) && +newValue >= 0) {
        return newValue;
      }
      return '';
    }
    return newValue;
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) =>
        variant === 'standard' ? (
          <TextField
            {...others}
            {...field}
            error={!!errors[name]}
            fullWidth
            helperText={
              showHelperText
                ? (errors[name]?.message as React.ReactNode)
                : helperText
            }
            InputProps={{
              ...others.InputProps,
              inputProps:
                type === 'number'
                  ? {
                      min:
                        others.inputProps?.inputMode === 'decimal' ? null : 0,
                      ...others.inputProps,
                    }
                  : others.inputProps,
              type,
              onChange: (event) => {
                const newValue = handleChange(event);
                field.onChange(newValue);
              },
            }}
            label={
              <Typography variant={labelVariant}>{`${label} ${
                required ? '*' : ''
              }`}</Typography>
            }
            placeholder={placeholder}
            size={others?.size ?? 'small'}
            variant="standard"
          />
        ) : (
          <TextField
            {...others}
            {...field}
            className="filled-variant filled-variant--white"
            error={!!errors[name]}
            helperText={
              showHelperText ? (errors[name]?.message as React.ReactNode) : null
            }
            InputProps={{
              ...others.InputProps,
              inputProps:
                type === 'number'
                  ? {
                      min:
                        others.inputProps?.inputMode === 'decimal' ? null : 0,
                      ...others.inputProps,
                    }
                  : others.inputProps,
              type,
              onChange: (event) => {
                const newValue = handleChange(event);
                field.onChange(newValue);
              },
              sx: {
                p: others?.size === 'medium' ? 1 : undefined,
              },
            }}
            placeholder={placeholder}
            size={others?.size ?? 'small'}
            sx={{ width: `${width}px` }}
          />
        )
      }
    />
  );
};

FormTextField.defaultProps = {
  showHelperText: false,
  required: false,
  variant: 'standard',
  width: undefined,
  placeholder: undefined,
  type: 'text',
  labelVariant: 'body2',
};

export default FormTextField;
