import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box as MuiBox,
  Chip as MuiChip,
  ChipProps as MuiChipProps,
  IconButton as MuiIconButton,
} from '@mui/material';

export const Chip = (props: MuiChipProps) => (
  <MuiChip
    {...props}
    deleteIcon={
      <MuiIconButton aria-label="Remove" size="small">
        <MuiBox color="gray.main" component={FontAwesomeIcon} icon={faTimes} />
      </MuiIconButton>
    }
  />
);
