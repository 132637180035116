import React from 'react';

import { Box, MenuItem, Stack, TextField, Typography } from '@mui/material';
import commonConstants from 'constants/common';

const { STATUS } = commonConstants;

interface IProps {
  onChange: (value: string | boolean) => void;
  disabled?: boolean;

  label?: string;
  value?: string;
}
export const SelectMembershipStatusOptions = ({
  onChange,
  disabled,
  label,
  value,
}: IProps) => {
  // const dispatch = useAppDispatch();
  // const selectedStatus = useAppSelector(selectManageMembershipStatus);

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newStatus = event.target.value;

    // dispatch(setManageMembershipStatus(newStatus));
    // Call onChange with the appropriate value

    onChange(newStatus);
  };

  return (
    <Stack alignItems="center" display="flex" flexDirection="row" gap={1} m={1}>
      <Box sx={{ textAlign: 'right' }}>
        <Typography variant="caption">{label}</Typography>
      </Box>
      <Box minWidth={70}>
        <TextField
          data-cy="client-manageMembership-membership-options"
          disabled={disabled}
          fullWidth
          onChange={handleStatusChange}
          select
          size="small"
          value={value}
          variant="standard"
        >
          {STATUS.map((statusOption) => (
            <MenuItem
              data-cy={`client-manageMembership-membership-options-${statusOption}`}
              key={statusOption}
              value={statusOption}
            >
              {statusOption}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </Stack>
  );
};

SelectMembershipStatusOptions.defaultProps = {
  disabled: false,
  label: 'Show',
  value: STATUS[0],
};
