import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CircularProgress,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import {
  AddressDisplay,
  EllipseMenu,
  EllipseMenuItem,
  GenderDobLast4SSNDisplay,
  ListDisplay,
} from 'common';
import { SelectionOptions } from 'enums/case';
import { IDonorPatientInformation } from 'interfaces/cases';
import {
  useDeleteBloodDonorMutation,
  useSelectedBloodDonorsMutation,
} from 'services/cases';
import { selectCurrentReferralCase } from 'stores/cases';
import { useConfirmationModal } from 'stores/ConfirmationModal';
import { useAppSelector } from 'stores/hooks';
import { formatPhone, getFullName } from 'utils/common';

interface IProps {
  data: IDonorPatientInformation;
  tableSize?: 'small' | 'medium';
}

const BloodDonorTableRow = ({ data, tableSize }: IProps): JSX.Element => {
  const fullName = getFullName(data.firstName, data.lastName, data.middleName);
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);

  const confirmationModal = useConfirmationModal();
  const deleteBloodDonorMutation = useDeleteBloodDonorMutation();
  const selectedBloodDonorsMutation = useSelectedBloodDonorsMutation();

  const onSelectClick = () => {
    if (currentReferralCase?.caseId) {
      selectedBloodDonorsMutation.mutate(
        {
          donorId: data.uuid || '',
          caseId: currentReferralCase.caseId!,
          type: SelectionOptions.SELECTED,
        },
        {
          onSettled: () => {
            confirmationModal?.changeSubmittingStatus(false);
          },
        }
      );
    }
  };

  const onDeleteClick = async () => {
    const result = await confirmationModal?.openConfirmationModal({
      title: `Delete Blood Donor`,
      isAdditionalContentRequired: false,
      content: (
        <Typography component="span">
          {`Are you sure you want to delete this donor ${fullName}? This process is irreversible. If you are not sure, you can cancel.`}
        </Typography>
      ),
    });

    if (result && currentReferralCase?.caseId) {
      confirmationModal?.changeSubmittingStatus(true);

      deleteBloodDonorMutation.mutate(
        { donorId: data.uuid || '', caseId: currentReferralCase.caseId! },
        {
          onSettled: () => {
            confirmationModal?.changeSubmittingStatus(false);
          },
        }
      );
    }
  };

  const showSelectOption = () => {
    const isAlreadyOptionSelected = currentReferralCase?.options?.some(
      (e) => e.isSelected === true
    );
    if (data.isSelected || isAlreadyOptionSelected) return false;
    return true;
  };

  return (
    <TableRow className="row row--with-collapsible-row" key={data.id}>
      <TableCell className="vertical-align-top" component="th" scope="data">
        {fullName}
        <GenderDobLast4SSNDisplay
          bloodGroup={data.bloodGroup}
          dob={data.dob}
          gender={data.gender}
          ssn={data.lastSSN}
        />
      </TableCell>
      <TableCell align="left">
        <ListDisplay
          list={[
            {
              icon: <FontAwesomeIcon icon={faMapMarkerAlt} />,
              text: <AddressDisplay address={data.address} />,
            },
          ]}
        />
      </TableCell>
      <TableCell align="left">
        {data.email || data.formattedPhone || data.phone ? (
          <ListDisplay
            list={[
              {
                icon: <FontAwesomeIcon icon={faEnvelope} />,
                text: data.email,
                tooltip: true,
                truncateTextLength: tableSize === 'small' ? 20 : 50,
              },
              {
                icon: <FontAwesomeIcon icon={faPhone} />,
                // Note: `data.phone` is depreciated. Only used here to support older data
                text: data.formattedPhone || formatPhone(data.phone),
              },
            ]}
          />
        ) : (
          '-'
        )}
      </TableCell>

      <TableCell align="right">
        <EllipseMenu>
          {showSelectOption() && (
            <EllipseMenuItem
              iconElement={
                selectedBloodDonorsMutation.isLoading ? (
                  <CircularProgress size="1rem" />
                ) : (
                  <FontAwesomeIcon icon={faCheckCircle} size="xs" />
                )
              }
              onClick={onSelectClick}
              text="Select"
            />
          )}
          <EllipseMenuItem
            iconElement={
              deleteBloodDonorMutation.isLoading ? (
                <CircularProgress size="1rem" />
              ) : (
                <FontAwesomeIcon icon={faTrashAlt} size="xs" />
              )
            }
            onClick={onDeleteClick}
            text="Delete"
          />
        </EllipseMenu>
      </TableCell>
    </TableRow>
  );
};

BloodDonorTableRow.defaultProps = {
  tableSize: 'small',
};

export default BloodDonorTableRow;
