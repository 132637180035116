import { useQueryClient } from 'react-query';

import { ReferralCaseRequestedAssignUserType } from 'enums/case';
import {
  IReferralCaseList,
  IReferralCasePatient,
  IReferralCaseRequestedAssignedUserSchema,
} from 'interfaces/cases';
import { referralCasesKeys } from 'services/cases';

import { useRelevantCombinedFilter } from '../useRelevantCombinedFilter';

export const useUpdateCaseList = () => {
  const queryClient = useQueryClient();
  const { combinedFilters: relevantFilter } = useRelevantCombinedFilter();

  const updateRelevantStatus = (
    caseId: string,
    updatedData: {
      code: string;
      name: string;
    }
  ) => {
    const queryDataRelevantFilter = queryClient.getQueryData(
      referralCasesKeys.list(relevantFilter)
    );
    if (queryDataRelevantFilter) {
      queryClient.setQueryData(
        referralCasesKeys.list(relevantFilter),
        (oldData: any) => ({
          ...oldData,
          pages: oldData?.pages?.map((old_pages: any) => ({
            ...old_pages,
            rows: old_pages?.rows?.map((e: IReferralCaseList) => {
              if (e.caseId === caseId)
                return {
                  ...e,
                  status: updatedData,
                };
              return e;
            }),
          })),
        })
      );
    }
  };

  const updateRelevantPatientDetails = (
    caseId: string,
    data: IReferralCasePatient
  ) => {
    const queryDataRelevantFilter = queryClient.getQueryData(
      referralCasesKeys.list(relevantFilter)
    );
    if (queryDataRelevantFilter) {
      queryClient.setQueryData(
        referralCasesKeys.list(relevantFilter),
        (oldData: any) => ({
          ...oldData,
          pages: oldData?.pages?.map((old_pages: any) => ({
            ...old_pages,
            rows: old_pages?.rows?.map((e: IReferralCaseList) => {
              if (e.caseId === caseId)
                return {
                  ...e,
                  patient: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                  },
                };
              return e;
            }),
          })),
        })
      );
    }
  };
  const updateRelevantServices = (
    caseId: string,
    data: { name: string; code?: string }[]
  ) => {
    const queryDataRelevantFilter = queryClient.getQueryData(
      referralCasesKeys.list(relevantFilter)
    );
    if (queryDataRelevantFilter) {
      queryClient.setQueryData(
        referralCasesKeys.list(relevantFilter),
        (oldData: any) => ({
          ...oldData,
          pages: oldData?.pages?.map((old_pages: any) => ({
            ...old_pages,
            rows: old_pages?.rows?.map((e: IReferralCaseList) => {
              if (e.caseId === caseId)
                return {
                  ...e,
                  service: data,
                };
              return e;
            }),
          })),
        })
      );
    }
  };

  const updateRelevantAssignedTo = (
    caseId: string,
    type: ReferralCaseRequestedAssignUserType,
    data: IReferralCaseRequestedAssignedUserSchema
  ) => {
    const queryDataRelevantFilter = queryClient.getQueryData(
      referralCasesKeys.list(relevantFilter)
    );
    if (queryDataRelevantFilter) {
      queryClient.setQueryData(
        referralCasesKeys.list(relevantFilter),
        (oldData: any) => ({
          ...oldData,
          pages: oldData?.pages?.map((old_pages: any) => ({
            ...old_pages,
            rows: old_pages?.rows?.map((e: IReferralCaseList) => {
              if (e.caseId === caseId)
                return {
                  ...e,
                  ...(type === ReferralCaseRequestedAssignUserType.FROM && {
                    from: {
                      ...e.from,
                      assignedTo: data,
                    },
                  }),
                  ...(type === ReferralCaseRequestedAssignUserType.TO && {
                    to: {
                      ...e.to,
                      assignedTo: data,
                    },
                  }),
                };
              return e;
            }),
          })),
        })
      );
    }
  };

  return {
    updateRelevantStatus,
    updateRelevantPatientDetails,
    updateRelevantServices,
    updateRelevantAssignedTo,
  };
};
