import {
  faEnvelope,
  faMapMarkerAlt,
  faMobileAlt,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { AddressDisplay, GenderDobLast4SSNDisplay } from 'common';
import PermissionGate from 'common/PermissionGate';
import { IDonorPatientInformation } from 'interfaces/cases';
import { formatPhone, getFullName } from 'utils/common';

import { CustomIconButton, StackIconAndTypography } from '../common';

interface ISelectedDonorCardProps {
  data: IDonorPatientInformation;
  onRemoveClick: (donor: IDonorPatientInformation) => void;
  isUserClient: boolean;
}

const SelectedDonorCard = ({
  data,
  onRemoveClick,
  isUserClient,
}: ISelectedDonorCardProps) => {
  const fullName = getFullName(data.firstName, data.lastName, data.middleName);

  return (
    <Paper
      className="bordered no-box-shadow"
      sx={{
        paddingBottom: 0,
        height: '268px',
        position: 'relative',
      }}
    >
      <Box
        alignItems="flex-start"
        display="flex"
        justifyContent="space-between"
        p={2}
        pb={0}
      >
        <Typography color="text.secondary" gutterBottom={false} variant="body2">
          Donor Name
        </Typography>
        {!isUserClient && (
          <PermissionGate>
            <CustomIconButton
              icon={faTrash}
              onClick={() => onRemoveClick(data)}
              size="small"
            />
          </PermissionGate>
        )}
      </Box>
      <Stack p={2} pt={0} spacing={1}>
        <Typography fontWeight="medium" variant="body1">
          {fullName}
        </Typography>
        <StackIconAndTypography
          icon={faMobileAlt}
          label={data?.formattedPhone || formatPhone(data.phone || '') || ''}
        />
        <StackIconAndTypography
          icon={faEnvelope}
          label={data?.email || 'N/A'}
        />
        <StackIconAndTypography
          icon={faMapMarkerAlt}
          label={<AddressDisplay address={data.address} />}
        />
      </Stack>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        paddingX={2}
        paddingY={1}
        sx={{
          background: (t) => t.palette.gray.lighter,
          cursor: 'pointer',
          position: 'absolute',
          bottom: 0,
          width: '100%',
        }}
      >
        <Typography
          gutterBottom={false}
          sx={{ color: (t) => t.palette.secondary.main }}
          variant="body2"
        >
          Blood Group
        </Typography>
        <Box
          sx={{
            '& .MuiTypography-root': {
              color: (t) => t.palette.secondary.main,
            },
          }}
        >
          <GenderDobLast4SSNDisplay bloodGroup={data.bloodGroup} />
        </Box>
      </Box>
    </Paper>
  );
};

export default SelectedDonorCard;
