import { useFormContext } from 'react-hook-form';

import {
  Box,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import AvatarPlaceholder from 'assets/images/png/avatar-placeholder.png';
import { convertFileToBase64 } from 'utils/common';

interface IProps {
  border?: boolean;
  round?: boolean;
  imageStyle?: SxProps<Theme> | undefined;
  imageContainerStyle?: SxProps<Theme> | undefined;
  imageInputId: string;
  horizontal?: boolean;
  showHelperText?: boolean;
  // width?: string;
}

const FormImagePicker = ({
  border,
  round,
  imageStyle,
  imageContainerStyle,
  imageInputId,
  horizontal,
  showHelperText = true,
}: // width,
IProps) => {
  const {
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const theme = useTheme();

  const currentImageUrl = watch('imageUrl');
  const newImageData = watch('newImageData');

  // const [error, setError] = useState<string>('');

  const onImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length < 1) {
      return;
    }

    const preview = URL.createObjectURL(e.target.files[0]);
    const img = e.target.files[0];

    if (!['image/jpeg', 'image/png'].includes(img.type)) {
      setError('image', {
        type: 'manual',
        message: 'Invalid file type. Please upload a JPG/JPEG or PNG image.',
      });
      return;
    }

    // Validate file size (2MB limit)
    if (img.size > 2 * 1024 * 1024) {
      setError('image', {
        type: 'manual',
        message: 'File size exceeds 2MB. Please upload a smaller image.',
      });
      return;
    }
    const imageData = {
      fileName: img.name,
      contentType: img.type,
      base64: await convertFileToBase64(img),
      size: img.size,
      filePath: `image/agentimage/${(+new Date())?.toString()}_${img.name}`,
    };

    setValue('newImageData', { preview, imageData });
    clearErrors('image');
  };

  const onCancelClick = () => {
    setValue('newImageData', null);
    clearErrors('image');
  };

  const getImageSrcToDisplay = () => {
    if (newImageData?.preview) {
      return newImageData.preview;
    }

    if (currentImageUrl && currentImageUrl.length) {
      return currentImageUrl;
    }

    return AvatarPlaceholder;
  };
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection={horizontal ? 'row' : 'column'}
    >
      <Box
        sx={{
          border: border ? `1px solid ${theme.palette.gray.light}` : '',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: 1,
          borderRadius: round ? '50%' : '',
          backgroundColor: theme.palette.common.white,
          overflow: 'hidden',
          width: '180px',
          height: '180px',
          lineHeight: '180px',
          ...imageContainerStyle,
        }}
      >
        <Box
          alt="thumbnail"
          component="img"
          onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = `${AvatarPlaceholder}`;
          }}
          src={getImageSrcToDisplay()}
          sx={{
            objectFit: 'contain',
            width: '100%',
            maxHeight: '100%',
            ...imageStyle,
          }}
        />
      </Box>

      <Box mt={1}>
        {newImageData ? (
          <Typography
            gutterBottom={false}
            onClick={onCancelClick}
            sx={{ cursor: 'pointer' }}
            variant="body2"
          >
            Cancel
          </Typography>
        ) : (
          <>
            <Stack gap={2} maxWidth={198} mt={2} textAlign="center">
              <Typography
                component="label"
                fontWeight="medium"
                gutterBottom={false}
                htmlFor={imageInputId}
                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                variant="body2"
              >
                {currentImageUrl ? 'Change Logo' : 'Upload Picture'}
              </Typography>
              {errors.image && typeof errors.image.message === 'string' && (
                <Typography color="error" sx={{ mt: 1 }} variant="caption">
                  {errors.image.message}
                </Typography>
              )}

              {showHelperText && (
                <Typography color="gray.light" variant="caption">
                  Max File Size Allowed 2MB, File Type Jpg, Png Only
                </Typography>
              )}
            </Stack>
            <Box
              accept=".png, .jpg, .jpeg"
              component="input"
              hidden
              id={imageInputId}
              onChange={onImageUpload}
              type="file"
            />
          </>
        )}
      </Box>
    </Box>
  );
};

FormImagePicker.defaultProps = {
  border: true,
  round: false,
  imageStyle: undefined,
  imageContainerStyle: undefined,
  horizontal: false,
  showHelperText: true,
  // width: '180px',
};

export default FormImagePicker;
