import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useHandleResetDispatch } from 'common/manage-membership/hooks/useHandleResetDispatch';
import { RadioBoxButton } from 'common/ui/RadioBoxButton';
import { ManageMembershipType } from 'enums/client-management/manage-membership';
import {
  selectManageMembershipType,
  setManageMembershipType,
} from 'stores/client-management/manage-membership';
import { useAppDispatch, useAppSelector } from 'stores/hooks';

export const ChooseMembershipType = () => {
  const dispatch = useAppDispatch();
  const manageMembershipType = useAppSelector(selectManageMembershipType);

  const { handleSwitchingMembershipType } = useHandleResetDispatch();

  const handleClick = (newOption: ManageMembershipType) => {
    dispatch(setManageMembershipType(newOption));
    handleSwitchingMembershipType();
  };

  return (
    <Box marginTop={2} marginX={2}>
      <Typography color="gray.darker" fontWeight="medium" variant="body1">
        Select Membership Type
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <RadioBoxButton
            data-cy="select-individual"
            isActive={manageMembershipType === ManageMembershipType.INDIVIDUAL}
            label={ManageMembershipType.INDIVIDUAL}
            onClick={() => handleClick(ManageMembershipType.INDIVIDUAL)}
            showCheckIcon
          />
        </Grid>
        <Grid item xs={3}>
          <RadioBoxButton
            data-cy="select-group"
            isActive={manageMembershipType === ManageMembershipType.GROUP}
            label={ManageMembershipType.GROUP}
            onClick={() => handleClick(ManageMembershipType.GROUP)}
            showCheckIcon
          />
        </Grid>
      </Grid>
    </Box>
  );
};
