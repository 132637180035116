/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { IFailedTransactionAdvancedSearchForm } from 'interfaces/billings/failed';
import { ITransactionFilter } from 'interfaces/billings/transactions';
import { IFilter } from 'interfaces/common';
import { RootState } from 'stores';
import { checkIfValuesInObjectIsEmpty } from 'utils/common';

const { DEFAULT_TABLE_FILTER } = commonConstants;

const advancedSearchDefaultValues = {
  offeringType: '',
  isAdvancedSearch: false,
};

interface State {
  filters: IFilter;
  advancedSearch: IFailedTransactionAdvancedSearchForm;
  errorMessage: string;
  isCheckoutButtonDisabled: boolean;
  failedPaymentMethod: string | null;
}

const initialState: State = {
  filters: { ...DEFAULT_TABLE_FILTER },
  advancedSearch: { ...advancedSearchDefaultValues },
  isCheckoutButtonDisabled: false,
  failedPaymentMethod: null,
  errorMessage: '',
};

export const slice = createSlice({
  name: 'failed',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (
      state,
      action: PayloadAction<Partial<ITransactionFilter>>
    ) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetAdvancedSearch: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
    },

    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },

    resetAllFilters: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },
    setCheckoutButtonDisabled: (state, action) => {
      state.isCheckoutButtonDisabled = action.payload;
    },
    setFailedPaymentMethod: (state, action) => {
      state.failedPaymentMethod = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<IFailedTransactionAdvancedSearchForm>>
    ) => {
      const advanceFilters = { ...state.advancedSearch, ...action.payload };
      const { isAdvancedSearch, ...rest } = advanceFilters;
      state.advancedSearch = {
        ...advanceFilters,
        isAdvancedSearch: !checkIfValuesInObjectIsEmpty(rest),
      };
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },
  },
});

// Actions
export const {
  changeAdvancedSearch,
  changeFilters,
  resetFilters,
  resetAdvancedSearch,
  resetAllFilters,
  setCheckoutButtonDisabled,
  setErrorMessage,
  setFailedPaymentMethod,
} = slice.actions;

// Selectors
export const selectFailedTransactionFilter = (state: RootState) =>
  state.failedTransactions.filters;
export const selectFailedTransactionAdvancedSearch = (state: RootState) =>
  state.failedTransactions.advancedSearch;
export const selectCheckoutButtonDisabled = (state: RootState) =>
  state.failedTransactions.isCheckoutButtonDisabled;
export const selectErrorMessage = (state: RootState) =>
  state.failedTransactions.errorMessage;
export const selectFailedPaymentMethod = (state: RootState) =>
  state.failedTransactions.failedPaymentMethod;

// Reducer
export default slice.reducer;
