export enum DocumentsSortBy {
  NAME = 'name',
}

export enum DocumentType {
  Client = 'Client',
  Tenant = 'Tenant',
  URI = 'URI',
  'E-SIGNATURE' = 'E-SIGNATURE',
  'REFERRAL-CASE' = 'ReferralCase',
  'CASE' = 'Case',
  'VIRTUAL-VISIT' = 'Virtual Visit',
  'TENANT' = 'Tenant',
}

export enum DocumentSubType {
  Client = 'Client',
  Document = 'Document',
  Case = 'Case',
  DOCUSIGN = 'DOCUSIGN',
}

export enum DocumentVisibility {
  PUBLIC = 'Public',
  PRIVATE = 'Private',
}
