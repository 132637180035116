import {
  faBoxesStacked,
  faBuilding,
  faCreditCard,
  faEnvelopeOpenText,
  faHandshake,
  faListCheck,
  faSliders,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { ContactType } from 'enums/settings/profile-admin';
import {
  CaseCode,
  CaseTypeSelectionCategory,
  EnrollmentCode,
  EnrollmentService,
  TenantSettingStatus,
} from 'enums/tenant-management/tenant';
import { IFormSettingField } from 'interfaces/settings/enrollment';

import uiRoutes from './uiRoutes';

export const SETTINGS_CONTENT_MAX_WIDTH = {
  sm: 568.5,
  md: 'md',
  FORM_CONFIG_PAGE_WIDTH: 700,
  CONTACT_PAGE_WIDTH: 768,
};

export const SETTINGS_BAR_PROPERTY = {
  SIDEBAR_WIDTH: '100px',
  DRAWER_WIDTH: '246px',
  HEADER_HEIGHT: '64px',
};
export const CALENDAR_PROPERTY = {
  SIDEBAR_WIDTH: '100px',
  DRAWER_WIDTH: '320PX',
  HEADER_HEIGHT: '64px',
};

export const SETTING_MENU_CODE = 'SETTING';

export const settingsRoutes = [
  {
    title: 'Company Profile',
    subtitle: [
      {
        name: 'Profile',
        to: uiRoutes.profileSettings.profile,
      },
      {
        name: 'Contact',
        to: uiRoutes.profileSettings.contact,
      },
      {
        name: 'Billings',
        to: uiRoutes.profileSettings.billings,
      },
    ],
  },
  {
    title: 'Users & Roles ',
    subtitle: [
      { name: 'Users', to: uiRoutes.profileSettings.admins },
      // { name: 'Roles', to: uiRoutes.profileSettings.roles },
    ],
  },
  {
    title: 'Communications',
    subtitle: [
      { name: 'Templates', to: uiRoutes.profileSettings.communication },
    ],
  },
  {
    title: 'Merchants & Payments',
    subtitle: [
      { name: 'Merchants & Payments', to: uiRoutes.profileSettings.merchants },
    ],
  },
  {
    title: 'Offerings and services',
    subtitle: [
      {
        name: 'Membership Offerings',
        to: uiRoutes.profileSettings.tenantMembershipOfferings,
      },
      {
        name: 'Non-Membership Offerings',
        to: uiRoutes.profileSettings.tenantAncillaryOfferings,
      },
      {
        name: 'Case Services',
        to: uiRoutes.profileSettings.service,
      },
    ],
  },
  {
    title: 'Referrals & Commissions',
    subtitle: [
      { name: 'Referrals', to: uiRoutes.profileSettings.referringAgent },
      { name: 'Commissions', to: uiRoutes.profileSettings.commissions },
    ],
  },
  {
    title: 'Workflow Status',
    subtitle: [
      { name: 'Leads', to: uiRoutes.profileSettings.leadStatus },
      { name: 'Case', to: uiRoutes.profileSettings.caseStatus },
    ],
  },
  {
    title: 'Medical Test',
    subtitle: [
      { name: 'Medical Test', to: uiRoutes.profileSettings.labService },
    ],
  },
  {
    title: 'Enrollment Page',
    subtitle: [
      { name: 'Cover Page', to: uiRoutes.profileSettings.coverPage },
      {
        name: 'Form Configuration',
        to: uiRoutes.profileSettings.formConfiguration,
      },
      {
        name: 'Text Configuration',
        to: uiRoutes.profileSettings.textConfiguration,
      },
    ],
  },
];

export const contactFormFields = {
  [ContactType.PRIMARY]: {
    helpText: 'Primary contact will be first point of contact',
    firstName: 'primaryFirstName',
    middleName: 'primaryMiddleName',
    lastName: 'primaryLastName',
    email: 'primaryEmail',
    phone: 'primaryPhone',
  },
  [ContactType.SUPPORT]: {
    helpText: 'Support contact will be second point of contact',
    firstName: 'supportFirstName',
    middleName: 'supportMiddleName',
    lastName: 'supportLastName',
    email: 'supportEmail',
    phone: 'supportPhone',
  },
};

export const FRONT_PAGE_ROUTE = [
  {
    icon: faBuilding,
    name: 'Company Profile',
    description: 'Manage Your Company Profile And Contact Information',
    to: uiRoutes.profileSettings.profile,
  },
  {
    icon: faUser,
    name: 'Users & Roles',
    description: 'Manage Your Admin Users, Their Roles, And Permissions',
    to: uiRoutes.profileSettings.admins,
  },
  {
    icon: faEnvelopeOpenText,
    name: 'Comms Templates',
    description: 'Configure Email Templates For Your Communications',
    to: uiRoutes.profileSettings.communication,
  },

  {
    icon: faCreditCard,
    name: 'Merchant & Payments',
    description: 'Enable Merchant Account To Accept Payments',
    to: uiRoutes.profileSettings.merchants,
  },
  {
    icon: faBoxesStacked,
    name: 'Offerings and services',
    description: 'Build Offerings And Services For Your Clients',
    to: uiRoutes.profileSettings.service,
  },
  {
    icon: faHandshake,
    name: 'Referrals & Commissions',
    description: 'Enable Referrals For Tracking And Commissions',
    to: uiRoutes.profileSettings.referringAgent,
  },
  {
    icon: faSliders,
    name: 'Workflow Statuses',
    description: 'Create Statuses For Different Workflows',
    to: uiRoutes.profileSettings.leadStatus,
  },
  {
    icon: faListCheck,
    name: 'Enrollment Page',
    description: 'Setup Enrollment Page For Your Clients',
    to: uiRoutes.profileSettings.textConfiguration,
  },
];

export const FormSettingsFields: IFormSettingField[] = [
  {
    field: 'How Did You Hear About Us (Referred By)',
    status: TenantSettingStatus.ENABLED,
    required: false,
    code: EnrollmentCode.REFERRED_BY,
  },
  {
    field: 'How Did You Hear About Us (Referred By) For Public Page',
    status: TenantSettingStatus.ENABLED,
    required: false,
    code: EnrollmentCode.REFERRED_BY_PUBLIC_PAGE,
  },
  {
    field: 'Email',
    status: TenantSettingStatus.ENABLED,
    required: false,
    code: EnrollmentCode.ENROLLMENT_EMAIL,
  },
  {
    field: 'Date Of Birth',
    status: TenantSettingStatus.ENABLED,
    required: false,
    code: EnrollmentCode.DATE_OF_BIRTH,
  },
  {
    field: 'Gender',
    status: TenantSettingStatus.ENABLED,
    required: false,
    subField: [
      {
        field: 'Gender',
        status: TenantSettingStatus.ENABLED,
        code: EnrollmentCode.GENDER_LABEL,
        configurable: false,
      },
      {
        field: 'Sex',
        status: TenantSettingStatus.ENABLED,
        code: EnrollmentCode.GENDER_LABEL,
        configurable: false,
      },
      {
        field: 'Male',
        status: TenantSettingStatus.ENABLED,
        code: EnrollmentCode.GENDER_OPTION,
        configurable: false,
      },
      {
        field: 'Female',
        status: TenantSettingStatus.ENABLED,
        code: EnrollmentCode.GENDER_OPTION,
        configurable: false,
      },
      {
        field: 'Others',
        status: TenantSettingStatus.ENABLED,
        code: EnrollmentCode.GENDER_OPTION,
        configurable: false,
      },
    ],
    code: EnrollmentCode.GENDER,
  },
  {
    field: 'Last Four SSN',
    status: TenantSettingStatus.ENABLED,
    required: false,
    code: EnrollmentCode.LAST_FOUR_SSN,
  },
  {
    field: 'Blood Group',
    status: TenantSettingStatus.ENABLED,
    required: false,
    code: EnrollmentCode.BLOOD_GROUP,
  },
  // {
  //   field: 'Email',
  //   status: TenantSettingStatus.ENABLED,
  //   required: false,
  //   code: formSettingCodes.EMAIL,
  // },
  {
    field: 'Phone',
    status: TenantSettingStatus.ENABLED,
    required: false,
    subField: [
      // {
      //   field: 'Allow International Phone Number',
      //   status: TenantSettingStatus.ENABLED,
      //   code: EnrollmentCode.INTERNATIONAL_ADDRESS,
      // },
      {
        field: 'Allow Send Text Notifications',
        status: TenantSettingStatus.ENABLED,
        code: EnrollmentCode.SEND_TEXT_NOTIFICATION,
      },
    ],
    code: EnrollmentCode.PHONE,
  },
  {
    field: 'Home Phone',
    status: TenantSettingStatus.ENABLED,
    required: false,
    code: EnrollmentCode.HOME_PHONE,
  },
  {
    field: 'Work Phone',
    status: TenantSettingStatus.ENABLED,
    required: false,
    code: EnrollmentCode.WORK_PHONE,
  },
  {
    field: 'Address',
    status: TenantSettingStatus.ENABLED,
    required: false,
    code: EnrollmentCode.ADDRESS,
  },
  {
    field: 'Maximum age of a child dependent',
    status: TenantSettingStatus.ENABLED,
    code: EnrollmentCode.MIN_AGE,
    hideRequiredField: true,
    value: 18,
    metaData: null,
  },
];

export const SERVICE_CONFIGURATION_FIELDS = [
  {
    label: 'Member Check-In',
    name: 'membershipName',
    isEnabled: 'isMemberCheckInEnabled',
    description: 'membershipDescription',
    buttonLabel: 'membershipButtonLabel',
    image: 'memberImage',
    code: EnrollmentService.SERVICES_MEMBER_CHECK_IN,
  },
  {
    label: 'Appointment Request',
    name: 'appointmentRequestName',
    isEnabled: 'isappointmentRequestEnabled',
    description: 'appointmentDescription',
    buttonLabel: 'appointmentRequestButtonLabel',
    image: 'appointmentImage',
    code: EnrollmentService.SERVICES_REQUEST_APPOINTMENT,
  },
  {
    label: 'Buy Services',
    name: 'buyServiceName',
    isEnabled: 'isBuyServiceEnabled',
    description: 'buyServiceDescription',
    buttonLabel: 'buyServiceButtonLabel',
    image: 'buyServiceImage',
    code: EnrollmentService.SERVICES_ADDITIONAL_SERVICES,
  },
  {
    label: 'Membership Enrollment',
    name: 'membershipEnrollmentName',
    isEnabled: 'ismembershipEnrollmentEnabled',
    description: 'membershipEnrollmentDescription',
    buttonLabel: 'membershipEnrollmentButtonLabel',
    image: 'memberShipEnrollmentImage',
    code: EnrollmentService.SERVICES_MEMBERSHIP_ENROLLMENT,
  },
  {
    label: 'Submit Request',
    name: 'submitRequestName',
    isEnabled: 'isSubmitRequestEnabled',
    description: 'submitRequestDescription',
    buttonLabel: 'submitRequestButtonLabel',
    image: 'submitRequestImage',
    code: EnrollmentService.SERVICES_SUBMIT_REQUEST,
  },
  // {
  //   label: 'Ask Question',
  //   name: 'askQuestionName',
  //   isEnabled: 'isAskQuestionEnabled',
  //   description: 'askQuestionDescription',
  //   buttonLabel: 'askQuestionButtonLabel',
  //   image: 'askQuestionImage',
  //   code: EnrollmentService.SERVICES_ASK_QUESTION,
  // },
];

export const REFERRALS_COMMISSIONS_DESCRIPTION = {
  referrals:
    'Manage your agents, and create links for them to give out to their clients',
  commissions: 'Setup can configure Agent commissions',
};

export const askQuestionSetting = {
  field: 'Minimum age of dependent',
  status: TenantSettingStatus.ENABLED,
  code: EnrollmentCode.ASK_QUESTION,
  hideRequiredField: true,
};

export const caseConfigurationSetting = [
  {
    field: 'Manage Case Type',
    status: TenantSettingStatus.ENABLED,
    code: CaseCode.CASE_TYPE_SELECTION_CATEGORY,
    hideRequiredField: true,
    value: CaseTypeSelectionCategory.SINGLE_SELECT,
  },
];
