import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Autocomplete,
  Box,
  Chip,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { IRoles } from 'interfaces/roles';
import { useAppSelector } from 'stores/hooks';
import { selectRoles } from 'stores/tenant-management/user';

// Entity can be client, tenant or agent
interface IProps {
  entity?: IRoles | null;
  autoFocusEnabled?: boolean;
  inputProps?: TextFieldProps;
  multiple?: boolean;
  onSelectHandler?: (entity: IRoles | null) => void;
  onMultiSelectHandler?: (entity: IRoles[]) => void;
  textFieldVariant?: 'standard' | 'outlined';
  limitTags?: number;
  autoFocusInput?: boolean;
  error?: boolean;
  renderTag?: boolean;
  isEditMode: boolean;
}

const UserRolesAutoComplete = ({
  error,
  autoFocusEnabled,
  entity,
  limitTags,
  inputProps,
  multiple,
  onSelectHandler,
  onMultiSelectHandler,
  textFieldVariant = 'standard',
  autoFocusInput = true,
  renderTag = true,
  isEditMode = false,
}: IProps): JSX.Element => {
  const roles = useAppSelector(selectRoles);
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<IRoles | IRoles[] | null>(null);
  const { getValues, setValue: setRolesValue } = useFormContext();
  const association = getValues('association');
  const [options, setOptions] = useState<IRoles[]>([]);

  useEffect(() => {
    if (multiple) {
      setValue(entity || null);
    }
  }, [entity, multiple]);

  useEffect(() => {
    if (roles) {
      setOptions(roles);
      let roleList = [];
      if (isEditMode) {
        const associationList = association[0]?.roles ?? [];
        roleList = roles?.filter((item) => associationList.includes(item.code));
      } else {
        roleList = roles?.filter((item) => item.code === 'TENANT_ADMIN');
      }
      setValue(roleList || null);
      setRolesValue('roles', roleList);
    }
  }, [association, isEditMode, roles, setRolesValue]);

  const handleInputChange = async (_: any, newInputValue: string) => {
    if (newInputValue?.length > 2 || !newInputValue?.length) {
      const rolesList = roles;
      const filterValue = rolesList?.filter((item) =>
        item.name.toLowerCase().includes(newInputValue.toLowerCase())
      );
      setOptions(filterValue ?? []);
    }
  };

  const onChange = async (option: IRoles | IRoles[] | null) => {
    if (Array.isArray(option)) {
      if (onMultiSelectHandler !== undefined) {
        onMultiSelectHandler(option);
      }
    } else if (onSelectHandler !== undefined) {
      onSelectHandler(option);
    }
  };

  const getOptionName = (option: IRoles) => ({
    name: option.name,
    code: option.code,
  });

  const getValue = (selectedValue: IRoles | IRoles[] | null) => {
    if (multiple) {
      if (Array.isArray(selectedValue)) {
        return [...selectedValue];
      }
      return [];
    }

    return selectedValue;
  };

  useEffect(() => {
    if (inputRef.current && autoFocusEnabled) {
      inputRef.current?.focus();
    }
  }, [autoFocusEnabled]);

  return (
    <Autocomplete
      autoComplete
      defaultValue={getValue(value)}
      disableCloseOnSelect
      filterOptions={(x) => x}
      filterSelectedOptions
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : getOptionName(option).name
      }
      id="async-entity-autocomplete"
      includeInputInList
      limitTags={limitTags}
      ListboxProps={{
        style: {
          maxHeight: '300px',
        },
      }}
      multiple={multiple}
      onChange={(_, newValue: IRoles | IRoles[] | null) => {
        onChange(newValue || null);
        setValue(newValue);
        setOptions(roles);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={handleInputChange}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      openOnFocus={false}
      options={options}
      renderInput={(params) => (
        // You can use different variations of textfield here.
        // Please refer TextfieldOverview.tsx for reference.
        <TextField
          {...params}
          {...inputProps}
          autoFocus={autoFocusInput}
          fullWidth
          InputProps={{
            error,
            ...params.InputProps,
            ...inputProps?.InputProps,
            inputRef,
            endAdornment: params.InputProps.endAdornment,
          }}
          label="Assign Roles"
          placeholder="Assign Roles"
          size={inputProps?.size ?? 'small'}
          variant={textFieldVariant}
        />
      )}
      renderOption={(props, option) => (
        /* eslint-disable react/jsx-props-no-spreading */
        <Box
          {...props}
          component="li"
          key={option._id}
          sx={{
            display: 'block !important',
            '&:hover': {
              color: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          <Stack>
            <Typography color="inherit" gutterBottom={false} variant="body2">
              {getOptionName(option).name}
            </Typography>
          </Stack>
        </Box>
      )}
      renderTags={(val, getTagProps) =>
        renderTag
          ? val.map((option: any, index): any => (
              <Chip
                label={option.name}
                size="small"
                {...getTagProps({ index })}
              />
            ))
          : null
      }
      value={multiple ? value ?? [] : value}
    />
  );
};

UserRolesAutoComplete.defaultProps = {
  error: false,
  entity: [],
  inputProps: null,
  limitTags: undefined,
  multiple: false,
  autoFocusEnabled: false,
  textFieldVariant: 'standard',
  onSelectHandler: undefined,
  onMultiSelectHandler: undefined,
  autoFocusInput: true,
  renderTag: true,
};

export default UserRolesAutoComplete;
