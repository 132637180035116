const Avatar = () => ({
  MuiAvatar: {
    styleOverrides: {
      root: {
        '& img': {
          objectFit: 'contain',
          padding: '4px',
        },
      },
    },
  },
});

export default Avatar;
