import domtoimage from 'dom-to-image';
import { IChartRawData } from 'interfaces/reports/dashboard';

import { formatDate } from './common';

/**
 * It takes an array of objects with a count and label property and returns an object with a labels and
 * data property
 * @param {IRawData[]} rawData - IRawData[] - this is the data that we get from the API, the raw data to format for charts
 * @returns {labels, data} - the chart data object
 */
export const prepareChartData = (rawData: IChartRawData[]) => {
  const filtered = rawData.filter(({ label }) => !!label);
  const data: number[] = filtered.map((x) => x.value);
  const labels: string[] = filtered.map((x) => x.label);
  return { labels, data };
};

/**
 * Returns a random RGB color with the given opacity.
 * @param {string[]} colors - an array of colors that have already been used, checks if generated color already in the colors array, and if it is, it generates a new color (recursive)
 * @param {number} borderOpacity - the opacity of the border.
 * @param {number} backgroundOpacity - the opacity of the background.
 * @returns {object} - an object with the border and background colors.
 */
export const getRandomRGBColor = ({
  colors,
  borderOpacity,
  backgroundOpacity,
}: {
  colors: string[];
  borderOpacity: number;
  backgroundOpacity: number;
}) => {
  const red = Math.floor((Math.random() * 256) / 3);
  const green = Math.floor((Math.random() * 256) / 2);
  const blue = Math.floor((Math.random() * 256) / 2);
  const backgroundColor = `rgba(${red}, ${green}, ${blue}, ${backgroundOpacity})`;
  const borderColor = `rgba(${red}, ${green}, ${blue}, ${borderOpacity})`;
  if (colors.includes(backgroundColor))
    getRandomRGBColor({ colors, borderOpacity, backgroundOpacity });
  return { borderColor, backgroundColor };
};

/**
 * * Maps the given labels to colors.
 * and one for the border
 * @param {string[]} labels - string[] - An array of labels for the chart.
 * @returns {Object} - an object containing the background colors and border colors.
 */
export const mapColorsToLabels = (labels: string[]) => {
  const backgroundColors: string[] = [];
  const borderColors: string[] = [];
  for (let i = 0; i < labels.length; i += 1) {
    const { backgroundColor, borderColor } = getRandomRGBColor({
      colors: backgroundColors,
      borderOpacity: 0.5,
      backgroundOpacity: 0.7,
    });
    backgroundColors.push(backgroundColor);
    borderColors.push(borderColor);
  }
  return { backgroundColors, borderColors };
};

/**
 * It takes a DOM element id, and takes downlodable picture snapshot
 * @param {string} domId - The id of the DOM element you want to take a snapshot of.
 */
export const getDOMSnapShot = async (domId: string) => {
  const dom = window.document.getElementById(domId);
  if (dom) {
    const dataUrl = await domtoimage.toJpeg(dom, {
      quality: 1,
    });
    const link = document.createElement('a');
    link.download = `${domId}_${formatDate(
      new Date().toLocaleDateString()
    )}.jpeg`;
    link.href = dataUrl;
    link.click();
    link.remove();
  }
};
