import { selectCaseList, selectPullRelevantSearch } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';

import { useInfiniteCaseList } from './useInfiniteCaseList';
import { useInfiniteRelevantCaseList } from './useInfiniteRelevantCaseList';

const useShowRelevantSearch = () => {
  const isPullRelevantSearch = useAppSelector(selectPullRelevantSearch);

  const { totalCasesCount, combinedFilters } = useInfiniteCaseList();

  const listData = useAppSelector(selectCaseList);

  const isRelevantCaseEnabled =
    !(totalCasesCount || listData.length) && !!combinedFilters.keyword;

  const { totalCasesCount: relevantTotalCasesCount } =
    useInfiniteRelevantCaseList();
  return {
    caseListTotalCount: totalCasesCount,
    isRelevantCaseEnabled,
    keyword: combinedFilters.keyword,
    isPullRelevantSearch,
    relevantCaseListTotalCount: relevantTotalCasesCount,
  };
};

export default useShowRelevantSearch;
