/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAdaptedTenantData, ILoginForm } from 'interfaces/auth';
import { IClient } from 'interfaces/client-management';
import { IPermission, ISettingConfiguration } from 'interfaces/common';
import { ITenant, ITenantSetting } from 'interfaces/tenant-management/tenant';
import { RootState } from 'stores';
import { getFullName } from 'utils/common';
import { decodeJwtToken } from 'utils/jwt';

interface AuthState {
  credentials: ILoginForm | null;
  activeMenu: IPermission | null;
  menus: IPermission[];
  tenantData: IAdaptedTenantData | null;
  settings: ITenantSetting | null;
  userClient: IClient | null; // holds the client (group or individual), populated for client login
  settingConfigurations: ISettingConfiguration[];
  tenantSlug: string;
  latestReleaseNote: string;
  activeTenant: ITenant | null;
}

const initialState: AuthState = {
  credentials: null,
  activeMenu: null,
  menus: [],
  tenantData: null,
  settings: null,
  userClient: null,
  settingConfigurations: [],
  tenantSlug: '',
  latestReleaseNote: '',
  activeTenant: null, // Replace tenantAssociation getter with activeTenant
};

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    clearCredentials: (state) => {
      state.credentials = null;
    },
    setActiveMenu: (
      state,
      action: PayloadAction<{ parentId: string | null; resourceId: string }>
    ) => {
      let activeMenu: IPermission | null = null;
      if (action.payload.parentId) {
        activeMenu =
          state.menus.find(
            (menu: IPermission) => menu.resourceId === action.payload.parentId
          ) || null;
      } else {
        activeMenu =
          state.menus.find(
            (menu: IPermission) => menu.resourceId === action.payload.resourceId
          ) || null;
      }
      if (activeMenu) {
        state.activeMenu = { ...activeMenu };
      }
    },
    setCredentials: (state, action: PayloadAction<ILoginForm>) => {
      state.credentials = action.payload;
    },
    setMenus: (state, action: PayloadAction<IPermission[]>) => {
      state.menus = action.payload;
    },
    setTenantSettings: (state, action: PayloadAction<ITenantSetting>) => {
      state.settings = { ...action.payload };
    },
    setTenantData: (
      state,
      action: PayloadAction<string | IAdaptedTenantData>
    ) => {
      state.tenantData =
        typeof action.payload === 'string'
          ? decodeJwtToken(action.payload.toString())
          : { ...state.tenantData, ...action.payload };
      state.tenantData.name = getFullName(
        state.tenantData?.firstName,
        state.tenantData?.lastName,
        state.tenantData?.middleName
      );
    },
    deselectActiveMenu: (state) => {
      state.activeMenu = null;
    },
    setUserClient: (state, action: PayloadAction<IClient>) => {
      state.userClient = { ...action.payload };
    },
    setSettingConfigurations: (
      state,
      action: PayloadAction<ISettingConfiguration[]>
    ) => {
      state.settingConfigurations = action.payload;
    },
    clearUserClient: (state) => {
      state.userClient = null;
    },
    setTenantSlug: (state, action: PayloadAction<string>) => {
      state.tenantSlug = action.payload;
    },
    setLatestReleaseNote: (state, action: PayloadAction<string>) => {
      state.latestReleaseNote = action.payload;
    },
    setActiveTenant: (state, action) => {
      state.activeTenant = action.payload;
    },
  },
});

// Actions
export const {
  clearCredentials,
  deselectActiveMenu,
  setActiveMenu,
  setCredentials,
  setMenus,
  setTenantData,
  setTenantSettings,
  setUserClient,
  setSettingConfigurations,
  clearUserClient,
  setTenantSlug,
  setLatestReleaseNote,
  setActiveTenant,
} = slice.actions;

// Selectors

export const selectActiveMenu = (state: RootState) => state.auth.activeMenu;
export const selectAuth = (state: RootState) => state.auth;
export const selectAuthCredentials = (state: RootState) =>
  state.auth.credentials;
export const selectAuthTenantData = (state: RootState) => state.auth.tenantData;
export const selectTenantSettings = (state: RootState) => state.auth.settings;
export const selectAuthTenantAssociation = (state: RootState) =>
  state.auth.tenantData?.tenantAssociation;
export const selectMenu = (state: RootState) => state.auth.menus;
export const selectAuthUserClient = (state: RootState) => state.auth.userClient;
export const selectAuthSettingConfigurations = (state: RootState) =>
  state.auth.settingConfigurations;
export const selectTenantSlug = (state: RootState) => state.auth.tenantSlug;
export const selectLatestReleaseNote = (state: RootState) =>
  state.auth.latestReleaseNote;
export const selectActiveTenant = (state: RootState) => state.auth.activeTenant;

// Reducer
export default slice.reducer;
