import React from 'react';
import { createRoot } from 'react-dom/client';

import { CometChat } from '@cometchat-pro/chat';
import { CometChatCalls } from '@cometchat-pro/web-calls';

import 'assets/scss/style.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import App from './App';

const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(process.env.REACT_APP_REGION ?? '')
  .autoEstablishSocketConnection(true)
  .build();

const callAppSetting = new CometChatCalls.CallAppSettingsBuilder()
  .setAppId(process.env.REACT_APP_ID ?? '')
  .setRegion(process.env.REACT_APP_REGION ?? '')
  .build();

CometChat.init(process.env.REACT_APP_ID, appSetting).then(() => {});

const appContainer = document.getElementById('root');
const root = createRoot(appContainer!);

CometChatCalls.init(callAppSetting).then(() => {});

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
