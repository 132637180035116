export enum NoteVisibility {
  PUBLIC = 'Public',
  HIDDEN = 'Hidden',
}

export enum ReferralCaseServiceType {
  SERVICE = 'Service',
  MEDICAL = 'Medical',
}

export enum ReferralCaseShareReferenceType {
  EMAIL = 'Email',
  TENANT = 'Tenant',
  FULL = 'Full',
}

export enum ReferralCaseRequestedAssignUserType {
  TO = 'to',
  FROM = 'from',
}

export enum ReferralCaseStatus {
  REQUESTED = 'Requested',
  ACKNOWLEDGED = 'Acknowledged',
  INPROGRESS = 'In Progress',
  COMPLETED = 'Completed',
  CLOSED = 'Closed',
  DELETED = 'Deleted',
  NEW = 'New',
}

export enum TaskStatus {
  SUBMITTED = 'Submitted',
  COMPLETED = 'Completed',
  REMINDED = 'Reminded',
}

export enum SelectionOptions {
  SELECTED = 'Selected',
  UNSELECTED = 'UnSelected',
}

export enum ICaseHistoryAction {
  CREATE = 'CREATE',
  FORWARD = 'FORWARD',
  UPLOAD = 'UPLOAD',
  ASSIGN = 'ASSIGN',
  SHARE = 'SHARE',
  EDIT_PATIENT = 'EDIT_PATIENT',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  ADD_PROVIDER = 'ADD_PROVIDER',
  EDIT_PROVIDER = 'EDIT_PROVIDER',
  DELETE_PROVIDER = 'DELETE_PROVIDER',
  SELECT_PROVIDER = 'SELECT_PROVIDER',
  SET_APPOINTMENT = 'SET_APPOINTMENT',
  DELETE_APPOINTMENT = 'DELETE_APPOINTMENT',
  UPDATE_STATUS = 'UPDATE_STATUS',
  UPDATE_REFERRING_PHYSICIAN = 'UPDATE_REFERRING_PHYSICIAN',
  UPDATE_SERVICE = 'UPDATE_SERVICE',
}
export enum ICaseHistoryCategory {
  CASE = 'CASE',
  NOTE = 'NOTE',
  DONOR = 'DONOR',
  FILE = 'FILE',
}
export enum EStatusModule {
  LEADS = 'leads',
  CASE = 'CASE',
}
export enum ClientCasesChoice {
  SELF = 'Self',
  DEPENDENT = 'Dependent',
}

export type ClientCasesChoices =
  | ClientCasesChoice.SELF
  | ClientCasesChoice.DEPENDENT;

export enum AlithiasCostType {
  Likely = 'Likely',
  Cash = 'Cash',
  Contract = 'Contract',
}
export enum VitafyProviderCostType {
  Claims = 'Claims', // => Likely
  Cash = 'Cash', // => Cash
  DirectContract = 'Direct Contract', // => Contract
  StateAvg = 'StateAvg',
}
export enum VitafyProviderServiceType {
  Cash = 'Cash', // => Cash
  DirectContract = 'Direct Contract', // => Contract
  InNetwork = 'In Network',
}
export enum CaseConfigurationType {
  CaseType = 'CASE_TYPE',
  ContactVia = 'CONTACT_VIA',
  ProviderCostType = 'COST_TYPE',
  ProviderIncentiveType = 'PROVIDER_INCENTIVE_TYPE',
  ServiceType = 'SERVICE_TYPE',
  Tags = 'TAGS',
  CallInPerson = 'CALL_IN_PERSON',
}

export enum ProviderSearchType {
  Smart = 'Smart',
  Manual = 'Manual',
}

export enum CaseCustomFieldsType {
  MULTI_SELECT = 'MULTI_SELECT',
  TEXT = 'TEXT',
  SELECT = 'SELECT',
  RADIO = 'RADIO',
}

export enum CaseLayoutTemplate {
  TemplateA = 'Template A',
  TemplateB = 'Template B',
}

export enum PatientContactType {
  WORK = 'Work',
  CELL = 'Cell',
  HOME = 'Home',
}

export enum CaseSharedForType {
  Partner = 'Partner',
  ThirdParty = 'ThirdParty',
  Patient = 'Patient',
}

export enum CaseMetaDataKey {
  TravelDistance = 'travelDistance',
}
