import React from 'react';

import {
  changeIsRelevantSearchEntered,
  selectIsRelevantSearchEntered,
} from 'stores/cases';
import { useAppDispatch, useAppSelector } from 'stores/hooks';

export const useCaseSearchKeywordAction = () => {
  const dispatch = useAppDispatch();
  const isRelevantSearchEntered = useAppSelector(selectIsRelevantSearchEntered);

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    keyword: string
  ) => {
    if (
      e.key === 'Enter' &&
      keyword.trim()?.length &&
      keyword.trim().length > 2
    ) {
      if (!isRelevantSearchEntered) {
        dispatch(changeIsRelevantSearchEntered(true));
      }
      return;
    }
    if (isRelevantSearchEntered) dispatch(changeIsRelevantSearchEntered(false));
  };

  return {
    handleKeyDown,
  };
};
