import regex from 'constants/regex';
import validationMessage from 'constants/validationMessage';
import {
  ClientType,
  RelationshipToPrimaryMember,
} from 'enums/client-management';
import { useCheckFormFieldSettings } from 'hooks/useCheckEnabledFields';
import {
  useAddressSchema,
  useBirthDateSchema,
  useBloodGroupSchema,
  useEmailSchema,
  useGenderSchema,
  useHomePhoneSchema,
  useLastSSNSchema,
  usePhoneSchema,
  useReferredBySchema,
  useWorkPhoneSchema,
} from 'schema/common';
import { checkIsAgeBelowMinimumAge, formatDate } from 'utils/common';
import { checkIfDateisInFuture, momentDate } from 'utils/moment';
import * as Yup from 'yup';

const { REQUIRED, EXACTLY_FOUR_DIGITS, BELOW_18, FUTURE_DATE_NOT_ALLOWED } =
  validationMessage;

export const useGetAddClientSchema = () => {
  const genderSchema = useGenderSchema();
  const dobSchema = useBirthDateSchema();
  const last4ssnSchema = useLastSSNSchema();
  const referredBySchema = useReferredBySchema();
  const phoneSchema = usePhoneSchema();
  const workPhoneSchema = useWorkPhoneSchema();
  const homePhoneSchema = useHomePhoneSchema();
  const emailSchema = useEmailSchema();
  const addressSchema = useAddressSchema();
  const bloodGroupSchema = useBloodGroupSchema();
  const isDobRequired = useCheckFormFieldSettings().required.dob;
  const { minimumAge } = useCheckFormFieldSettings().value;
  const isMinimumAgeRequirementEnabled =
    useCheckFormFieldSettings().enabled.minimumAgeRequirement;

  return Yup.object().shape({
    businessName: Yup.string()
      .nullable()
      .when('clientType', {
        is: (clientType: string) => clientType === ClientType.GROUP,
        then: Yup.string().trim().required(REQUIRED),
      }),
    referredBy: Yup.object().when('clientType', {
      is: (clientType: string) =>
        [ClientType.DEPENDENT, ClientType.GROUP_MEMBER].includes(
          clientType as ClientType
        ),
      then: Yup.object({
        id: Yup.string(),
        name: Yup.string(),
      }).nullable(),
      otherwise: referredBySchema,
    }),
    firstName: Yup.string().nullable().trim().required(REQUIRED),
    middleName: Yup.string().nullable(),
    lastName: Yup.string().nullable().trim().required(REQUIRED),
    email: emailSchema,
    phone: phoneSchema,
    home: homePhoneSchema,
    work: workPhoneSchema,

    // TODO: This format was used for phone numbers before international address was introduced so commenting this out for now as we may need this for future
    // .matches(regex.PHONE, {
    //   excludeEmptyString: true,
    //   message: INVALID,
    // })
    // .when('clientType', {
    //   is: (clientType: string) =>
    //     clientType === ClientType.GROUP ||
    //     clientType === ClientType.GROUP_MEMBER ||
    //     clientType === ClientType.PRIMARY,
    //   then: Yup.string().required(REQUIRED).matches(regex.PHONE, {
    //     excludeEmptyString: false,
    //     message: INVALID,
    //   }),
    // }),
    addressLine1: addressSchema,
    addressLine2: Yup.string().nullable(),
    city: addressSchema,
    state: addressSchema,
    zip: addressSchema,
    // zip: addressSchema.matches(regex.ZIP, {
    //   excludeEmptyString: true,
    //   message: INVALID,
    // }),
    note: Yup.string().nullable(),
    lastSSN: Yup.string().when('clientType', {
      is: ClientType.GROUP,
      then: Yup.string().nullable(),
      otherwise: last4ssnSchema.matches(regex.ONLY4Digit, {
        message: EXACTLY_FOUR_DIGITS,
        excludeEmptyString: true,
      }),
    }),

    dob: Yup.string().when(['clientType', 'relationship'], {
      is: (clientType: string, relationship: string) =>
        clientType === ClientType.DEPENDENT &&
        relationship === RelationshipToPrimaryMember.CHILD,
      then: Yup.string().test(
        'is-below-18',
        BELOW_18.replace('{{age}}', minimumAge?.toString()),
        (value, { createError }) => {
          const currentDate = formatDate(value || '');
          const birthDate = momentDate(currentDate);
          const isDateFromFuture = checkIfDateisInFuture(currentDate);

          // allow empty string if dob is not required
          if (!isDobRequired && !value?.trim()?.length) {
            return true;
          }

          if (isDateFromFuture) {
            return createError({ message: FUTURE_DATE_NOT_ALLOWED });
          }

          return isMinimumAgeRequirementEnabled
            ? checkIsAgeBelowMinimumAge({ birthDate, age: minimumAge })
            : !!value?.trim()?.length;
        }
      ),
      otherwise: Yup.string().when('clientType', {
        is: ClientType.GROUP,
        then: Yup.string().nullable(),

        otherwise: dobSchema,
      }),
    }),
    gender: Yup.string().when('clientType', {
      is: ClientType.GROUP,
      then: Yup.string().nullable(),
      otherwise: genderSchema,
    }),
    groupId: Yup.string()
      .nullable()
      .when('clientType', {
        is: (clientType: string) => clientType === ClientType.GROUP_MEMBER,
        then: Yup.string().trim().required(REQUIRED),
      }),
    groupName: Yup.string()
      .nullable()
      .when('clientType', {
        is: (clientType: string) => clientType === ClientType.GROUP_MEMBER,
        then: Yup.string().trim().required(REQUIRED),
      }),
    primaryId: Yup.string()
      .nullable()
      .when('clientType', {
        is: (clientType: string) => clientType === ClientType.DEPENDENT,
        then: Yup.string().trim().required(REQUIRED),
      }),
    primaryName: Yup.string()
      .nullable()
      .when('clientType', {
        is: (clientType: string) => clientType === ClientType.DEPENDENT,
        then: Yup.string().trim().required(REQUIRED),
      }),

    relationship: Yup.string()
      .nullable()
      .when('clientType', {
        is: (clientType: string) => clientType === ClientType.DEPENDENT,
        then: Yup.string().trim().required(REQUIRED),
      }),
    bloodGroup: Yup.string().nullable().when('clientType', {
      is: ClientType.GROUP,
      then: Yup.string().trim().nullable(),
      otherwise: bloodGroupSchema,
    }),
    textEnabled: Yup.boolean(),
    isVip: Yup.boolean(),
  });
};

export const CancelEnrollmentSchema = Yup.object().shape({
  endDate: Yup.string().required(REQUIRED),
  note: Yup.string().nullable(),
});

export const CompleteMembershipSchema = Yup.object().shape({
  endDate: Yup.string().required(REQUIRED),
  note: Yup.string().nullable(),
});
