import {
  faCalendarAlt,
  faMapMarkerAlt,
  faMobileAlt,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Divider,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { AddressDisplay } from 'common';
import { SelectionOptions } from 'enums/case';
import { IReferralCaseProviderOption } from 'interfaces/cases';
import { useSelectedProviderMutation } from 'services/cases';
import { useConfirmationModal } from 'stores/ConfirmationModal';
import { formatCurrency, formatPhone } from 'utils/common';

import {
  CustomIconButton,
  mapOldAddressImplementation,
  StackIconAndTypography,
} from './common';

interface IProps {
  data: IReferralCaseProviderOption;
  caseId: string;
}

const SelectedProviderOption = ({ data, caseId }: IProps) => {
  const confirmationModal = useConfirmationModal();
  const selectedProviderMutation = useSelectedProviderMutation();

  const theme = useTheme();
  const address = data?.address && mapOldAddressImplementation(data.address);
  const onDeleteClick = async (isSelected: boolean) => {
    const result = await confirmationModal?.openConfirmationModal({
      title: `Remove Provider`,
      isAdditionalContentRequired: false,
      content: (
        <Typography component="span">
          Are you sure you want to remove this provider {data?.name}? This
          process is irreversible. If you are not sure, you can cancel.
        </Typography>
      ),
    });

    if (result && caseId && data?.id) {
      confirmationModal?.changeSubmittingStatus(true);

      const params = { isSelected };
      selectedProviderMutation.mutate(
        {
          providerId: data.id,
          caseId: caseId!,
          params,
          type: SelectionOptions.UNSELECTED,
        },
        {
          onSettled: () => {
            confirmationModal?.changeSubmittingStatus(false);
          },
        }
      );
    }
  };
  return (
    <Paper
      sx={{
        border: `1px solid ${theme.palette.gray.lighter}`,
        paddingBottom: 0,
        height: '268px',
        background: (t) => t.palette.common.white,
        position: 'relative',
      }}
    >
      <Box
        alignItems="flex-start"
        display="flex"
        justifyContent="space-between"
        p={2}
        pb={0}
      >
        <Typography color="text.secondary" gutterBottom={false} variant="body2">
          Provider Name
        </Typography>
        <CustomIconButton
          icon={faTrash}
          onClick={() => onDeleteClick(false)}
          size="small"
        />
      </Box>
      <Stack p={2} pt={0}>
        <Typography fontWeight="medium" variant="body1">
          {data?.name}
        </Typography>
        <Stack spacing={1}>
          <StackIconAndTypography
            icon={faMapMarkerAlt}
            label={<AddressDisplay address={address} />}
          />
          <StackIconAndTypography
            icon={faMobileAlt}
            label={data?.phone ? formatPhone(data.phone) : ''}
          />
        </Stack>
        <Divider sx={{ marginY: 1 }} />
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Typography variant="caption">Bundle Cost</Typography>
          <Typography
            sx={{ fontWeight: (t) => t.typography.fontWeightMedium }}
            variant="body1"
          >
            {data?.cost ? formatCurrency(data.cost) : 'N/A'}
          </Typography>
        </Box>
      </Stack>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        sx={{
          background: (t) => t.palette.gray.lighter,
          cursor: 'pointer',
          position: 'absolute',
          bottom: 0,
          width: '100%',
        }}
      >
        <Stack alignItems="center" direction="row" padding={1} spacing={1}>
          <Box
            color={theme.palette.secondary.main}
            component={FontAwesomeIcon}
            icon={faCalendarAlt}
            size="xs"
          />

          <Typography
            gutterBottom={false}
            sx={{ color: (t) => t.palette.secondary.main }}
            variant="body2"
          >
            Set Appointment
          </Typography>
        </Stack>
      </Box>
    </Paper>
  );
};

export default SelectedProviderOption;
