import { ReferralCaseShareReferenceType } from 'enums/case';
import { IReferralCaseShareWithEmail } from 'interfaces/cases';
import { uniqBy } from 'lodash';
import { selectAuthTenantAssociation } from 'stores/auth';
import { selectCurrentReferralCase } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';

export const useGetCaseInvitees = () => {
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const access = currentReferralCase?.access as IReferralCaseShareWithEmail[];
  const tenantId = useAppSelector(selectAuthTenantAssociation)?.tenantId;

  const allInvitees = access?.filter(
    (e) =>
      (e.referenceType === ReferralCaseShareReferenceType.EMAIL ||
        e.referenceType === ReferralCaseShareReferenceType.TENANT) &&
      !!e.referenceId &&
      !!e.sharedBy?.name &&
      !e.hidden
  );
  const activeInvitees = allInvitees?.filter((e) => !e.revoked);

  // Filter out multiple records of same partner
  const filteredAllInvitees = uniqBy(allInvitees, 'email');
  const filteredActiveInvitees = uniqBy(activeInvitees, 'referenceId');

  const ownerTenant = access?.filter(
    (i) =>
      i?.referenceType === ReferralCaseShareReferenceType.TENANT &&
      !!i?.referenceId &&
      !i?.sharedBy
  );

  const invitedDate =
    access?.find((i) => i?.referenceId === tenantId)?.sharedDate || '';

  return {
    allInvitees: filteredAllInvitees,
    partnerInvitees: filteredActiveInvitees.filter(
      (item) => item.referenceType === ReferralCaseShareReferenceType.TENANT
    ),
    thirdPartyInvitees: filteredActiveInvitees.filter(
      (item) => item.referenceType === ReferralCaseShareReferenceType.EMAIL
    ),
    ownerTenant,
    invitedDate,
  };
};
