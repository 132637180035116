import { ReferenceType } from 'enums/common';
import { useUserDetailQuery } from 'services/tenant-management/users';
import { selectAuthTenantData } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { getLocal } from 'utils/storage';

/**
 * Gives tenant associations of logged in user
 *
 */
export const useGetTenantAssociationOfLoggedInUser = () => {
  const authUser = useAppSelector(selectAuthTenantData);
  const authUserId = authUser?.userId;
  const jwtToken = getLocal('jwtToken');

  const userDetailQuery = useUserDetailQuery(authUserId!, {
    enabled: !!authUserId && !!jwtToken,
  });

  return {
    isLoggedIn: !!authUserId && !!jwtToken,
    activeAssociation: authUser?.tenantAssociation,
    associations: userDetailQuery?.data?.association || [],
    authUser,
    isLoading: userDetailQuery.isLoading,
    isAdmin:
      authUser?.tenantAssociation?.referenceType === ReferenceType.TENANT,
  };
};
