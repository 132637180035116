export const layoutConstants = {
  STRIPE: {
    inputFontSize: '16px', // Stripe Card Input Font Size
  },
  CHAT: {
    chatWindowWidth: 380,
    chatWindowHeight: 500,
    reduceBy: 10,
    chatListWindowWidth: 330,
    maximumThresholdValue: 450,
  },
};
