import { useCheckRelationshipFromMembership } from './useCheckRelationshipFromMembership';
import { useGetCurrentSelectedClientAndMembershipDetails } from './useGetCurrentSelectedClientAndMembershipDetails';
import { useGetManageMembershipType } from './useGetManageMembershipType';

export const useCheckFetchingOfferingsEnabled = () => {
  const { isStatusGroup, isStatusIndividual } = useGetManageMembershipType();
  const {
    isClientTypeGroup,
    loggedInUserAsGroup,
    selectedMembershipIsOfGroupId,
  } = useGetCurrentSelectedClientAndMembershipDetails();

  const { isGroupMember } = useCheckRelationshipFromMembership();

  const isIndividualOfferingEnabled =
    isStatusIndividual &&
    !isClientTypeGroup &&
    !loggedInUserAsGroup &&
    !selectedMembershipIsOfGroupId &&
    !isGroupMember;

  const isGroupOfferingEnabled = isStatusGroup;
  return {
    isIndividualOfferingEnabled,
    isGroupOfferingEnabled,
  };
};
