import { useQuery } from 'react-query';

import * as userAPI from 'api/chat';
import { IUserFilter } from 'interfaces/tenant-management/user';

export const tenantKeys = {
  all: ['users'] as const,
  lists: () => [...tenantKeys.all, 'list'] as const,
  details: () => [...tenantKeys.all, 'detail'] as const,
  detail: (id: number | string) => [...tenantKeys.details(), id] as const,
};

export const useTenantQuery = (filters: IUserFilter, enabled: boolean) => {
  const queryKey = tenantKeys.lists();

  const queryInfo = useQuery(
    queryKey,
    () => userAPI.getTenantUsers(filters, filters?.userId ?? ''),
    { enabled }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};
