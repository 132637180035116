import { useEffect, useMemo, useState } from 'react';

import {
  faMagnifyingGlass,
  faPlusCircle,
  faSlidersH,
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { PageLimitSelect, Popover } from 'common';
import { ITenantNetwork } from 'interfaces/tenant-networks';
import { formatNumber } from 'utils/common';
import { debounce } from 'utils/lodash';

import ExcelExport from './ExcelExport';
import AdvanceFilterLayout from './layouts/advanced-filter';
import PermissionGate from './PermissionGate';
import NetworkSelect from './ui/select/NetworkSelect';
import RadiusLimitSelect from './ui/select/RadiusLimitSelect';

interface IProps {
  handleExcludeLocation?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  exportData?: boolean;
  type?:
    | 'standard'
    | 'physician'
    | 'facility'
    | 'service'
    | 'provider'
    | 'networkOffering'
    | 'networkService';
  hideSearch?: boolean;
  isExcludedLocation?: boolean;
  addButtonText?: string;
  onAddClick?: VoidFunction;
  advancedFilterElement?: {
    children: JSX.Element;
    submitText?: string;
    handleClear?: () => void;
    handleSubmit?: (event: any) => void;
    clearText?: string;
  };
  appliedFilterElement?: {
    children: JSX.Element | null;
  };
  searchKeyword: string;
  hideAdvancedFilter?: boolean;
  onSearchKeywordChange: (value: string) => void;
  pageLimit?: {
    data: number;
    onChange: (value: number) => void;
  };
  radiusLimit?: {
    data: number;
    onChange: (value: number) => void;
  } | null;
  onNetworkSelect?: {
    selectedNetworkId: string;
    networksList: ITenantNetwork[];
    onChange: (value: string) => void;
  };
  onExportAllClick?: VoidFunction;
  onExportAsExcelClick?: VoidFunction;
  onExportCurrentListAsExcelClick?: VoidFunction;
  onRefreshClick: VoidFunction;
  totalCount: number;
  title?: string;
  debounceLengthValidation?: boolean;
  exportAllButton?: boolean;
  searchBarWidth?: number;
}

const TableBanner = ({
  handleExcludeLocation,
  isExcludedLocation,
  exportData,
  addButtonText,
  advancedFilterElement,
  appliedFilterElement,
  hideSearch,
  hideAdvancedFilter,
  onAddClick,
  searchKeyword,
  onSearchKeywordChange,
  pageLimit,
  radiusLimit,
  onNetworkSelect,
  onExportAllClick,
  onExportAsExcelClick,
  onExportCurrentListAsExcelClick,
  onRefreshClick,
  totalCount,
  type,
  debounceLengthValidation,
  exportAllButton,
  searchBarWidth,
  // TODO : Need to Remove all titles
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  title,
}: IProps) => {
  const [keyword, setKeyword] = useState<string | null>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  const onAdvancedSearchFormSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (advancedFilterElement?.handleSubmit) {
      advancedFilterElement.handleSubmit(event);
      setIsPopoverOpen(false);
    }
  };

  const handleClear = () => {
    if (advancedFilterElement?.handleClear) {
      advancedFilterElement?.handleClear();
    }
    setIsPopoverOpen(false);
  };

  const debouncedSearchHandler = useMemo(
    () =>
      debounce(async (value: string) => {
        if (value.length > (!debounceLengthValidation ? 0 : 2) || !value.length)
          onSearchKeywordChange(value.trim());
      }, 300),
    [debounceLengthValidation, onSearchKeywordChange]
  );

  const searchHandler = (value: string) => {
    setKeyword(value);
    debouncedSearchHandler(value);
  };

  useEffect(() => {
    if (keyword === null) {
      setKeyword(searchKeyword);
    }
  }, [keyword, searchKeyword]);

  useEffect(() => {
    if (searchKeyword?.trim() === '') {
      setKeyword(searchKeyword);
    }
  }, [searchKeyword]);

  const getTotalResultVariant = () => {
    if (type === 'standard') {
      return (
        <Typography gutterBottom={false} variant="body2">
          {`Total Results: ${formatNumber(totalCount, true, 0, 0)}`}
        </Typography>
      );
    }
    if (type === 'facility' && totalCount > 0) {
      return (
        <Typography
          color={theme.palette.text.primary}
          fontWeight="medium"
          gutterBottom={false}
          paddingLeft={2}
          variant="body2"
        >
          {`Total Results: ${formatNumber(totalCount, true, 0, 0)}`}
        </Typography>
      );
    }
    if (type === 'physician' && totalCount > 0) {
      return (
        <Typography
          color={theme.palette.text.primary}
          fontWeight="medium"
          gutterBottom={false}
          paddingLeft={2}
          variant="body2"
        >
          {`Total Results: ${formatNumber(totalCount, true, 0, 0)}`}
        </Typography>
      );
    }
    if (type === 'service') {
      return (
        <Typography
          color="gray.darker"
          fontWeight="medium"
          gutterBottom={false}
          variant="body1"
        >
          {/* {`${formatNumber(totalCount, true, 0, 0)} Services found`} */}
          Services
        </Typography>
      );
    }
    if (type === 'provider') {
      return (
        <Typography
          color="gray.darker"
          fontWeight="medium"
          gutterBottom={false}
          variant="body1"
        >
          Providers
        </Typography>
      );
    }

    if (type === 'networkOffering') {
      return (
        <Typography
          color="gray.darker"
          fontWeight="medium"
          gutterBottom={false}
          variant="body1"
        >
          {`${formatNumber(totalCount, true, 0, 0)} Offerings found`}
        </Typography>
      );
    }
    if (type === 'networkService' && totalCount > 0) {
      return (
        <Typography
          color={theme.palette.text.primary}
          fontWeight="medium"
          gutterBottom={false}
          paddingLeft={2}
          variant="body2"
        >
          {`Total Results: ${formatNumber(totalCount, true, 0, 0)}`}
        </Typography>
      );
    }
    return '';
  };

  const totalResult = getTotalResultVariant();

  const getTotalField = () => {
    if (!hideSearch) {
      if (handleExcludeLocation) {
        return (
          <Box
            marginTop={2}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box>{totalResult}</Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isExcludedLocation}
                      onChange={handleExcludeLocation}
                      size="small"
                    />
                  }
                  label={
                    <Typography gutterBottom={false} variant="body2">
                      Exclude Location
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>
          </Box>
        );
      }
      return <Box marginTop={2}>{totalResult}</Box>;
    }

    return '';
  };

  return (
    <Box mt={0.5}>
      {/* <Typography color="gray.darker" fontWeight="medium" variant="body1">
        {title}
      </Typography> */}

      <Box alignItems="center" display="flex" justifyContent="space-between">
        <FormControl variant="standard">
          <Box className="mui__filled-input-with-icon-button-suffix-wrapper">
            {!hideSearch ? (
              <TextField
                className={`filled-variant filled-variant${
                  !isSmallerThanMd ? '--search-field' : ''
                }${advancedFilterElement ? '-filter' : ''}`}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faMagnifyingGlass} size="sm" />
                    </InputAdornment>
                  ),
                  inputProps: {
                    'data-cy': 'table-banner-search',
                  },
                }}
                onChange={(event) => searchHandler(event.target.value)}
                placeholder="Search"
                size="small"
                sx={{ minWidth: searchBarWidth }}
                value={keyword || ''}
              />
            ) : (
              totalResult
            )}

            {!hideAdvancedFilter && advancedFilterElement && (
              <Popover
                buttonIcon={<FontAwesomeIcon icon={faSlidersH} />}
                buttonSize="small"
                buttonSx={{
                  // Overridding icon button for the search field
                  color: `${theme.palette.gray.dark} !important`,
                  backgroundColor: `${theme.palette.common.white} !important`,
                  mx: 0.5,
                }}
                datacy="advance-search"
                open={isPopoverOpen}
                setOpen={setIsPopoverOpen}
                sx={{
                  minWidth: '400px',
                }}
              >
                <form onSubmit={onAdvancedSearchFormSubmit}>
                  <AdvanceFilterLayout
                    clearText={advancedFilterElement?.clearText}
                    handleClear={handleClear}
                    submitText={advancedFilterElement?.submitText}
                  >
                    {advancedFilterElement.children}
                  </AdvanceFilterLayout>
                </form>
              </Popover>
            )}
          </Box>
        </FormControl>

        <Stack direction="row" spacing={2}>
          <Tooltip title="Refresh">
            <IconButton
              aria-label="add"
              data-cy="table-banner-refresh"
              onClick={onRefreshClick}
              size="medium"
            >
              <FontAwesomeIcon icon={faSyncAlt} size="sm" />
            </IconButton>
          </Tooltip>
          {pageLimit && (
            <PageLimitSelect
              data={pageLimit.data}
              disabled={totalCount < 25}
              onChange={pageLimit.onChange}
            />
          )}
          {radiusLimit && (
            <RadiusLimitSelect
              data={radiusLimit.data}
              disabled={false}
              onChange={radiusLimit.onChange}
            />
          )}
          {onNetworkSelect && onNetworkSelect.networksList.length > 1 && (
            <NetworkSelect
              disabled={false}
              networksList={onNetworkSelect.networksList}
              onChange={onNetworkSelect.onChange}
              selectedNetworkId={onNetworkSelect.selectedNetworkId}
            />
          )}
          {exportData && (
            <ExcelExport
              exportAllButton={exportAllButton}
              onExportAllClick={onExportAllClick}
              onExportAsExcelClick={onExportAsExcelClick}
              onExportCurrentListAsExcelClick={onExportCurrentListAsExcelClick}
            />
          )}

          {!!onAddClick && (
            <PermissionGate>
              <Button
                color="primary"
                data-cy="table-banner-add"
                onClick={onAddClick}
                size="medium"
                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                type="button"
                variant="contained"
              >
                {addButtonText}
              </Button>
            </PermissionGate>
          )}
        </Stack>
      </Box>

      {appliedFilterElement?.children && (
        <Box alignItems="center" display="flex" mt={2}>
          <Typography gutterBottom={false} variant="body1">
            Filters Applied:{' '}
          </Typography>
          <Stack direction="row" ml={2} spacing={1}>
            {appliedFilterElement.children}
          </Stack>
        </Box>
      )}

      {getTotalField()}
      {/* {!hideSearch && (
        <Box marginTop={2}>{totalResult}</Box>
        // <Box display="flex" justifyContent="space-between">
        //   <Box alignItems="end" display="flex" flexDirection="row-reverse">
        //     <Typography gutterBottom={false} variant="body2">
        //       Inactive
        //     </Typography>
        //     <Switch
        //       checked={false}
        //       color="primary"
        //       onChange={(event) => console.log(event.target.checked)}
        //       sx={{ top: '7px' }}
        //     />
        //     <Typography gutterBottom={false} variant="body2">
        //       Active
        //     </Typography>
        //   </Box>
        // </Box>
      )} */}
    </Box>
  );
};

TableBanner.defaultProps = {
  addButtonText: 'Add',
  exportData: false,
  advancedFilterElement: undefined,
  appliedFilterElement: undefined,
  onAddClick: undefined,
  onExportAsExcelClick: undefined,
  onExportAllClick: undefined,
  onExportCurrentListAsExcelClick: undefined,
  radiusLimit: undefined,
  onNetworkSelect: undefined,
  pageLimit: undefined,
  hideSearch: false,
  hideAdvancedFilter: false,
  type: 'standard',
  title: '',
  debounceLengthValidation: true,
  exportAllButton: false,
  handleExcludeLocation: undefined,
  isExcludedLocation: false,
  searchBarWidth: 350,
};

export default TableBanner;
