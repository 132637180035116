import { useEffect } from 'react';

import {
  changeNoteFile,
  changeNotes,
  selectCurrentReferralCase,
  setSelectedCaseStatus,
} from 'stores/cases';
import { useAppDispatch, useAppSelector } from 'stores/hooks';

export const useResetCaseNote = () => {
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      changeNotes({
        text: '',
        editMode: { status: false, data: {} },
      })
    );
    dispatch(changeNoteFile([]));
    if (currentReferralCase?.status) {
      dispatch(
        setSelectedCaseStatus(currentReferralCase?.status?.code?.toUpperCase())
      );
    }
  }, [currentReferralCase?.status, dispatch]);
};
