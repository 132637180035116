import React, { ReactNode } from 'react';

import { DialogContent, SxProps } from '@mui/material';

export const MembershipCommonContentWrapper = ({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: SxProps;
}) => (
  <DialogContent
    dividers={false}
    sx={{ margin: 0, padding: 0, maxHeight: '74vh', ...sx }}
  >
    {children}
  </DialogContent>
);

MembershipCommonContentWrapper.defaultProps = {
  sx: {},
};
