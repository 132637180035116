import { ClientEnrollmentStatus } from 'enums/client-management';
import { DateUnit } from 'enums/moment';
import { BillingInterval } from 'enums/settings/tenant-offerings';
import UserType from 'enums/User';
import { ITenantPartial } from 'interfaces/client-management';
import { IUpdateManageMembership } from 'interfaces/client-management/manage-membership';
import moment from 'moment';
import {
  formatCurrency,
  formatDate,
  unformatCurrency,
  unformatDate,
} from 'utils/common';
import {
  addUnitToDate,
  checkIfDateisInFuture,
  formatDateView,
  getDaysInAMonth,
  getLastDateOfMonth,
  getMonthDateRange,
  momentDateOnlyIsSameOrAfter,
  momentViewFormat,
  parseZone,
  setParticularDateOfMonth,
  subtractUnitFromDate,
} from 'utils/moment';
import {
  formatBillingInterval,
  formatBillingType,
} from 'utils/tenant-management/tenant';

import {
  IAdaptedClientEnrollmentDetail,
  IClientEnrollment,
  IClientEnrollmentDetail,
  IClientEnrollmentSchema,
  IEnrollmentForm,
} from '../../interfaces/client-management/enrollment';
import { IListResponse, IResponse } from '../../interfaces/http';

export const getOfferingStatusForEnrollmentPayload = (status: string) => {
  if (
    status &&
    [
      ClientEnrollmentStatus.SUBMITTED,
      ClientEnrollmentStatus.APPROVED,
      ClientEnrollmentStatus.CANCELLED,
    ].includes(status as ClientEnrollmentStatus)
  ) {
    return {
      status,
    };
  }

  return {};
};

export const adaptClientEnrollment = (
  res: IResponse<IClientEnrollment>
): IResponse<IClientEnrollment> => ({
  ...res,
  data: {
    ...res.data,
    endDateRange: getMonthDateRange(
      res.data?.startDate?.toString() ?? '',
      3,
      false
    ),
    startDate: formatDateView(res.data?.startDate) ?? '',
    endDate: formatDateView(res.data?.endDate) ?? '',
    offerings: {
      ...res.data.offerings,
      formattedTotalCost: `$${res.data.offerings?.cost}`,
      billingInterval:
        res.data.offerings.billingInterval &&
        formatBillingInterval(res.data.offerings.billingInterval),
      billingType:
        res.data.offerings.billingType &&
        formatBillingType(res.data.offerings.billingType),
      unformattedBillingInterval: res.data.offerings.billingInterval,
      unformattedBillingType: res.data.offerings.billingType,
    },
  },
});
export const adaptAllClientEnrollment = (
  res: IResponse<IListResponse<IClientEnrollment>>
): IResponse<IListResponse<IClientEnrollment>> => ({
  ...res,
  data: {
    ...res.data,
    rows: res.data?.rows.map((d: any) => ({
      ...d,
      endDateRange: getMonthDateRange(d?.startDate?.toString() ?? '', 3, false),
      startDate: formatDateView(d.startDate) ?? '',
      endDate: formatDateView(d.endDate) ?? '',
      offerings: {
        ...d.offerings,
        formattedTotalCost: `$${d.offerings.totalCost}`,
        billingInterval:
          d.offerings.billingInterval &&
          formatBillingInterval(d.offerings.billingInterval),
        billingType:
          d.offerings.billingType && formatBillingType(d.offerings.billingType),
        unformattedBillingInterval: d.offerings.billingInterval,
        unformattedBillingType: d.offerings.billingType,
      },
    })),
  },
});

export const adaptClientEnrollmentDetail = (
  res: IResponse<IClientEnrollmentDetail>
): IResponse<IAdaptedClientEnrollmentDetail> => ({
  ...res,
  data: {
    ...res.data,
    formattedTotalAmount: formatCurrency(res.data.totalAmount) ?? '-',
    formattedSubscriptionAmount:
      formatCurrency(res.data.subscriptionAmount) ?? '-',
    startDate: formatDateView(res.data.startDate) ?? '-',
    nextBillingDate: formatDateView(res.data.nextBillingDate) ?? '-',
    enrollmentDetails: res.data.enrollmentDetails?.map((item) => ({
      ...item,
      formattedAmount: formatCurrency(item.amount) ?? '-',
    })),
    formattedClients: res.data?.clients.join(', ') ?? '-',
    formattedRegistrationFee: res.data?.enrollmentDetails?.find(
      (e) => e.registrationFee
    )?.registrationFee
      ? `$${
          res.data?.enrollmentDetails?.find((e) => e.registrationFee)
            ?.registrationFee
        }`
      : undefined,
  },
});

export const getBenefitDateRange = (startDate?: string, endDate?: string) => {
  if (startDate && endDate)
    return `${formatDateView(startDate)} - ${formatDateView(endDate)}`;

  return startDate ? formatDateView(startDate) || '-' : '-';
};

export const getTenantList = (tenants: ITenantPartial[]): any => {
  const tenantLength = tenants?.length;
  if (tenants?.length) {
    return tenants?.map(
      (item: any, index: any) =>
        `${item?.name}${
          tenantLength && tenantLength > 1 && tenantLength !== index + 1
            ? ','
            : ''
        } `
    );
  }
  return '';
};

export const formatChangeEnrollmentPayload = (submitData: IEnrollmentForm) => {
  const { startDates, ...payload } = submitData;
  const unformattedTotalCost = unformatCurrency(payload.membership.price);

  return {
    startDate: formatDate(startDates[submitData.membership?.tenantOfferingId]),
    offeringName: payload.membership.name,
    tenantOfferingId: payload.membership.tenantOfferingId,
    totalCost: +unformattedTotalCost,
    billingInterval: payload.membership.billingInterval,
    billingType: payload.membership.billingType,
    registrationFee: payload.membership.registrationFee,
  };
};

export const formatUpdateEnrollmentPayload = (
  submitData: IEnrollmentForm,
  tenantId: string,
  tenantGroupCode?: string,
  clientType?: string
) => {
  const { startDates, ...payload } = submitData;
  const unformattedTotalCost = unformatCurrency(payload.membership.price);
  const isClientTypeGroup = clientType === UserType.GROUP;
  return {
    startDate: formatDate(startDates[submitData.membership?.tenantOfferingId]),
    endDate: '',
    offeringName: payload.membership.name,
    offeringId: payload.membership.tenantOfferingId,
    cost: +unformattedTotalCost,
    billingInterval: payload.membership.billingInterval,
    billingType: payload.membership.billingType,
    registrationFee: payload.membership.registrationFee,
    hoursPurchased: payload.membership?.hoursPurchased || 0,
    tenantId,
    ...(isClientTypeGroup && { type: clientType }),
    ...(isClientTypeGroup && { tenantGroupCode }),
  };
};

/**
 * If provided date is in future, return the same date
 * else, return today's date
 *
 * @param {string} date :: should be in ISO 8601 format i.e. YYYY-MM-DDTHH:mm:ss. sssZ
 * @return {string} :: unformatted date i.e. MMDDYYYY
 *
 */
export const getBenefitStartDate = (date?: string | null) => {
  const presentDate = unformatDate(formatDateView(new Date().toISOString())!);

  if (!date) {
    return presentDate;
  }

  const isEnrolledInFuture = checkIfDateisInFuture(date);

  if (isEnrolledInFuture) {
    return unformatDate(formatDateView(date)!);
  }

  return presentDate;
};

const getDateNextMonthByDay = (
  startDate: string | Date,
  billingInterval: BillingInterval
): string | Date | null => {
  const dayOfMonth = new Date(startDate).getDate();
  let date = new Date();
  switch (billingInterval) {
    case BillingInterval.MONTHLY:
      date = addUnitToDate(startDate, 1, DateUnit.MONTHS);
      break;
    case BillingInterval.QUARTERLY:
      date = addUnitToDate(startDate, 3, DateUnit.MONTHS);
      break;
    case BillingInterval.BI_YEARLY:
      date = addUnitToDate(startDate, 6, DateUnit.MONTHS);
      break;
    case BillingInterval.YEARLY:
      date = addUnitToDate(startDate, 1, DateUnit.YEARS);
      break;
    default:
      break;
  }
  // date.setDate(dayOfMonth);
  // Setting day of month 31 on november will set the date as December 1 and like wise.
  // Hence need to set next billing date as 11/30.
  if (dayOfMonth > 28) {
    const daysInMonth = moment(date, 'YYYY-MM').daysInMonth();
    if (daysInMonth < dayOfMonth) {
      date.setDate(daysInMonth);
    }
  } else {
    date.setDate(dayOfMonth);
  }
  if (date > new Date()) {
    return parseZone(date);
  }
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return getNextBillingDate(date, billingInterval);
};

export const getNextBillingDate = (
  startDate: string | Date,
  billingInterval: BillingInterval
) => {
  // If startDate is future date, next billing date is future date
  if (new Date(startDate) > new Date()) {
    return parseZone(new Date(startDate));
  }
  return getDateNextMonthByDay(startDate, billingInterval);
};

export const getNextBillingDateAccountingLastDayOfTheMonth = ({
  newBillingDate,
  noOfDaysInFirstNextBillingDate,
  isNextBillingDateInLastDayOfMonth,
}: {
  newBillingDate: Date | string;
  noOfDaysInFirstNextBillingDate: number;
  isNextBillingDateInLastDayOfMonth: boolean;
}) => {
  const noOfDaysInNewBillingDate = getDaysInAMonth(newBillingDate);

  /**
   * Set the exact day of the first next billing date in upcoming months
   * if a particular month doesn't have that day OR the first next billing date falls on the last day of the month, set the last date of the month
   *
   * For eg:
   * first next billing date = 12/29/2022 -> 01/29/2022, 02/28/2022 (set the last date since, Feb doesn't have 29th day), 03/29/2022
   *
   */
  if (
    noOfDaysInFirstNextBillingDate > noOfDaysInNewBillingDate ||
    isNextBillingDateInLastDayOfMonth
  ) {
    return momentViewFormat(getLastDateOfMonth(new Date(newBillingDate)));
  }
  return momentViewFormat(
    setParticularDateOfMonth(
      new Date(newBillingDate),
      noOfDaysInFirstNextBillingDate
    )
  );
};

export const getNextBillingDateSelectOptions = ({
  billingInterval,
  nextBillingDate,
  benefitStartDate,
}: {
  billingInterval: BillingInterval;
  nextBillingDate: string | Date;
  benefitStartDate: string;
}) => {
  const dateOptions = [];
  let unit = '';
  /**
   * Is benefit start date same as or after next billing date?
   * 1. momentDateOnlyIsSameOrAfter('2021-09-25', '2021-09-25') = Yes => Same => Do not subtract by 1
   * 2. momentDateOnlyIsSameOrAfter('2021-10-25', '2021-09-25') = Yes => Future => Do not subtract by 1
   * 3. momentDateOnlyIsSameOrAfter('2021-08-24', '2021-09-24') = No => Past => Subtract by 1
   * 4. momentDateOnlyIsSameOrAfter('2021-08-06', '2021-09-01') = No => Past => Subtract by 1
   * If benefit start date is future date than next billing date(1), do not subtract by 1 day, else subtract 1 day.
   */
  let newBillingDate: string | Date = subtractUnitFromDate(
    nextBillingDate,
    momentDateOnlyIsSameOrAfter(benefitStartDate, nextBillingDate) ? 0 : 1,
    DateUnit.DAYS
  );

  // For eg: newBillingDate = 01/29/2021 -> 29
  const noOfDaysInFirstNextBillingDate = new Date(newBillingDate).getDate();
  // For eg: newBillingDate = 01/29/2021 -> 31 (Since, Jan has 31 days)
  const lastDateInFirstNextBillingDate = getDaysInAMonth(newBillingDate);

  const isNextBillingDateInLastDayOfMonth =
    noOfDaysInFirstNextBillingDate === lastDateInFirstNextBillingDate;

  if (billingInterval === BillingInterval.MONTHLY) {
    unit = DateUnit.MONTHS;
    for (let i = 0; i <= 5; i += 1) {
      newBillingDate = momentViewFormat(
        i === 0
          ? newBillingDate
          : addUnitToDate(newBillingDate, 1, unit as DateUnit)
      );

      newBillingDate = getNextBillingDateAccountingLastDayOfTheMonth({
        newBillingDate,
        noOfDaysInFirstNextBillingDate,
        isNextBillingDateInLastDayOfMonth,
      });

      dateOptions.push({
        label: newBillingDate,
        value: newBillingDate,
      });
    }
  } else if (billingInterval === BillingInterval.QUARTERLY) {
    unit = DateUnit.MONTHS;
    for (let i = 0; i <= 3; i += 1) {
      newBillingDate = momentViewFormat(
        i === 0
          ? newBillingDate
          : addUnitToDate(newBillingDate, 3, unit as DateUnit)
      );

      newBillingDate = getNextBillingDateAccountingLastDayOfTheMonth({
        newBillingDate,
        noOfDaysInFirstNextBillingDate,
        isNextBillingDateInLastDayOfMonth,
      });

      dateOptions.push({
        label: newBillingDate,
        value: newBillingDate,
      });
    }
  } else {
    newBillingDate = momentViewFormat(newBillingDate);
    dateOptions.push({
      label: newBillingDate,
      value: newBillingDate,
    });
  }

  return dateOptions;
};

export const formatUpdateManageMembership = ({
  offering,
  startDate,
  endDate,
  clientType,
  tenantGroupCode,
  tenantId,
  status,
}: IUpdateManageMembership): IClientEnrollmentSchema => {
  const isClientTypeGroup = clientType === UserType.GROUP;

  return {
    endDate,
    status,
    ...(startDate && { startDate }),
    offeringName: offering?.name || '',
    offeringId: offering?.tenantOfferingId,
    cost: offering?.unformattedPrice as number,
    billingInterval: offering?.billingInterval,
    billingType: offering?.billingType,
    registrationFee: offering?.unformattedRegistrationFee,
    hoursPurchased: offering?.hoursPurchased || 0,

    tenantId,
    ...(offering.status &&
      getOfferingStatusForEnrollmentPayload(offering.status)),
    ...(isClientTypeGroup && { type: clientType }),
    ...(isClientTypeGroup && { tenantGroupCode }),
  };
};
