import { useMutation, useQuery } from 'react-query';

import * as lookUpAPI from 'api/lookups';
import { ILookUpFilter } from 'interfaces/common';
import { IError } from 'interfaces/http';
import { ILookUpsTableRow } from 'interfaces/lookups';
import { useSnackbar } from 'notistack';

export const lookUpKeys = {
  all: ['look-up'] as const,
  detail: (lookupId: string) => ['look-up', lookupId] as const,
  lists: () => [...lookUpKeys.all, 'list'] as const,
  list: (filters: ILookUpFilter) =>
    [...lookUpKeys.lists(), { filters }] as const,
  search: (filters: ILookUpFilter) =>
    [...lookUpKeys.lists(), 'search', { filters }] as const,
};

export const useLookUpQuery = (
  filters: ILookUpFilter,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    lookUpKeys.list(filters),
    () => lookUpAPI.getAllLookUp(filters),
    { enabled }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useLookUpDetailQuery = (
  lookupId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    lookUpKeys.detail(lookupId),
    () => lookUpAPI.getLookUpDetail(lookupId),
    { enabled }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useCreateLookUpMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ data }: { data: ILookUpsTableRow }) => lookUpAPI.createLookUp(data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (error: IError) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    }
  );
};
export const useUpdateLookUpMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ data, id }: { data: any; id: string }) =>
      lookUpAPI.updateLookUp(data, id),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (error: IError) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    }
  );
};
