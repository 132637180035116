import { useMemo } from 'react';

import { ClientEnrollmentStatus } from 'enums/client-management';
import { IAdaptedClient } from 'interfaces/client-management';
import { IAdaptedClientEnrollmentMembership } from 'interfaces/client-management/enrollment';

export const useCheckIsSelectableMembershipView = (
  data: IAdaptedClientEnrollmentMembership[],
  clientData: IAdaptedClient | null
) => {
  const isSubmittedMembershipExists = data.some(
    (e) => e.status === ClientEnrollmentStatus.SUBMITTED && !!e.offerings.id
  );
  const isSelectableView = useMemo(
    () =>
      !data.length ||
      (data.length === 1 && isSubmittedMembershipExists) ||
      !clientData?.membership.id, // this to handle show list view on approve dependent members

    [clientData?.membership.id, data.length, isSubmittedMembershipExists]
  );

  return isSelectableView;
};
