import { faClose, faDownload, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material';
import { useFileDownloadMutation } from 'services/files';
import { truncateText } from 'utils/common';
import { isBrowserSupportedFile } from 'utils/file';

import LoadingDots from './LoadingDots';

interface IAttachmentProps {
  id?: string;
  name: string;
  path?: string;
  fullPath?: string;
  onRemoveClick?: (uuid: string) => void;
}

const Attachment = ({
  id,
  name,
  path,
  fullPath,
  onRemoveClick,
}: IAttachmentProps) => {
  const theme = useTheme();

  const fileDownloadMutation = useFileDownloadMutation();

  const viewAttachment = (
    fileName: string,
    filePath: string,
    type: 'view' | 'download'
  ) => {
    fileDownloadMutation.mutate({
      filepath: filePath,
      name: fileName,
      download: { view: type },
    });
  };

  const renderedAttachmentName = (
    <Box alignItems="center" display="flex" justifyContent="flex-start">
      <Box
        color={theme.palette.gray.main}
        component={FontAwesomeIcon}
        icon={faFile}
        paddingLeft={2}
        size="1x"
      />
      <Box marginX={2} width="100%">
        <Tooltip placement="top" title={name}>
          <Typography
            color="text.primary"
            gutterBottom={false}
            sx={{ paddingY: '16px', wordBreak: 'break-word' }}
            variant="caption"
          >
            {truncateText(name, 17)}
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  );

  const extractPartialPathFromFullPath = (url: string) => {
    const pattern = /\.com\/(.*)/;
    const match = url.match(pattern);
    return match ? match[1] : null;
  };

  const partialPath =
    path || (fullPath ? extractPartialPathFromFullPath(fullPath) : '');

  return (
    <Box className="attachment" width="100%">
      <Box
        className="attachment__content-wrapper"
        sx={{
          cursor: 'pointer',
          width: '100%',
          pointerEvents: fileDownloadMutation.isLoading ? 'none' : 'auto',
          opacity: fileDownloadMutation.isLoading ? 0.5 : 1,
        }}
      >
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          sx={{ height: '100%', width: '100%' }}
        >
          {partialPath && (
            <Box
              onClick={() =>
                viewAttachment(
                  name,
                  partialPath,
                  isBrowserSupportedFile(partialPath) ? 'view' : 'download'
                )
              }
              sx={{ flexGrow: 1, width: '100%', textDecoration: 'none' }}
            >
              {renderedAttachmentName}
            </Box>
          )}

          {partialPath && (
            <Tooltip arrow placement="top" title="Download">
              <Button
                className="attachment__icon-wrapper"
                disabled={fileDownloadMutation.isLoading}
                onClick={() => viewAttachment(name, partialPath, 'download')}
                sx={{ textDecoration: 'none' }}
              >
                {fileDownloadMutation.isLoading ? (
                  <LoadingDots pl={2} />
                ) : (
                  <Box component={FontAwesomeIcon} icon={faDownload} />
                )}
              </Button>
            </Tooltip>
          )}
          {onRemoveClick && (
            <Tooltip arrow placement="top" title="Remove">
              <Button
                className="attachment__icon-wrapper"
                onClick={() => id && onRemoveClick(id)}
              >
                <Box component={FontAwesomeIcon} icon={faClose} />
              </Button>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  );
};

Attachment.defaultProps = {
  id: '',
  onRemoveClick: undefined,
  path: '',
  fullPath: '',
};

export default Attachment;
