import { selectManageMembershipSelectedMembership } from 'stores/client-management/manage-membership';
import { useAppSelector } from 'stores/hooks';

export const useCheckRelationshipFromMembership = () => {
  const currentMembershipSelectedData = useAppSelector(
    selectManageMembershipSelectedMembership
  );

  const { details: membership } = currentMembershipSelectedData;

  const isGroup = !membership?.groupId && !membership?.primaryId;
  const isGroupMember = membership?.groupId && !membership?.primaryId;
  const isGroupMemberDependent = membership?.groupId && membership?.primaryId;

  return {
    isGroup,
    isGroupMember,
    isGroupMemberDependent,
  };
};
