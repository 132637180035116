import commonConstants from 'constants/common';
import { LookUpCategory } from 'enums/lookups';
import { IReleaseNote } from 'interfaces/lookups';
import { useLookUpQuery } from 'services/lookups';

export const useGetReleaseNote = () => {
  const releaseNoteQuery = useLookUpQuery(
    {
      category: LookUpCategory.RELEASE_NOTE,
      limit: commonConstants.PAGINATION.MAXIMUM_LIMIT,
    },
    { enabled: true }
  );
  const getSingleReleaseNote = () => {
    if (releaseNoteQuery?.data) {
      return {
        note: (releaseNoteQuery.data[0]?.metaData as IReleaseNote)?.releaseNote,
        id: releaseNoteQuery.data[0]?.lookupId,
      };
    }
    return {
      note: '',
      id: '',
    };
  };
  return {
    releaseNoteDetail: getSingleReleaseNote(),
    isLoading: releaseNoteQuery.isLoading || releaseNoteQuery.isFetching,
  };
};
