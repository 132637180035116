import { ManageMembershipActionType } from 'enums/client-management/manage-membership';

import { useMembershipAction } from './useMembershipAction';

export const useGetManageMembershipActionType = () => {
  const membershipActionType = useMembershipAction();

  const isAddOrEditNewMembership =
    membershipActionType.checkActionType(
      ManageMembershipActionType.ADD_NEW_MEMBERSHIP
    ) ||
    membershipActionType.checkActionType(
      ManageMembershipActionType.EDIT_MEMBERSHIP
    );

  const isAddNewMembership = membershipActionType.checkActionType(
    ManageMembershipActionType.ADD_NEW_MEMBERSHIP
  );

  const isEditMembership = membershipActionType.checkActionType(
    ManageMembershipActionType.EDIT_MEMBERSHIP
  );
  const isMembershipApprove = membershipActionType.checkActionType(
    ManageMembershipActionType.APPROVE
  );
  const isMembershipComplete = membershipActionType.checkActionType(
    ManageMembershipActionType.COMPLETE
  );
  const isMembershipDecline = membershipActionType.checkActionType(
    ManageMembershipActionType.DECLINE
  );
  const isMembershipChange = membershipActionType.checkActionType(
    ManageMembershipActionType.CHANGE
  );
  const isMembershipCancel = membershipActionType.checkActionType(
    ManageMembershipActionType.CANCEL
  );

  return {
    isAddOrEditNewMembership,
    isMembershipApprove,
    isMembershipComplete,
    isMembershipDecline,
    isMembershipChange,
    isMembershipCancel,
    isAddNewMembership,
    isEditMembership,
  };
};
