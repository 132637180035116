export enum TenantSortBy {
  NAME = 'name',
  EMAIL = 'email',
  WEBSITE = 'website',
  ADDRESS = 'address',
}
export enum TenantSettingStatus {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
}

export enum TenantAffiliateSettingStatus {
  Website = 'Website',
  Portal = 'Portal',
  Both = 'Both',
  None = 'None',
}

export enum TenantContact {
  PRIMARY = 'Primary',
  SUPPORT = 'Support',
  CASE = 'Case',
  BILLINGS = 'Billings',
  SCHEDULING = 'Scheduling',
}

export enum TenantCode {
  DPC = 'DPC',
  BMTCARE = 'BMTCARE',
}

export enum TenantGroupCode {
  V360 = 'V360',
  GRAITH_CARE = 'GC',
}

export enum TenantSettingsType {
  RADIO = 'Radio',
  TEXT = 'Text',
  MULTISELECT = 'Multi-select',
  SELECT = 'Select',
  NUMBER = 'Number',
}

export enum MiscellaneousCode {
  APPOINTMENT_CHECKIN = 'APPOINTMENT_CHECKIN',
  CALENDAR_EVENTS = 'CALENDAR_EVENTS',
  CLIENT_AGREEMENT = 'Client_Agreement',
  DEPENDENT_RELATIONSHIP = 'DEPENDENT_RELATIONSHIP',
  INTERNATIONAL_ADDRESS = 'INTERNATIONAL_ADDRESS',
  OTHERS_IN_GENDER = 'OTHERS_IN_GENDER',
  SHOW_QR_CODE = 'SHOW_QR_CODE',
  UI_INDIVIDUAL_MENUS_OPTIONS = 'UI_INDIVIDUAL_MENUS_OPTIONS',
  UI_GROUP_MENUS_OPTIONS = 'UI_GROUP_MENUS_OPTIONS',
  CHAT_BOT_ENABLED = 'CHAT_BOT_ENABLED',
  VIP_CLIENT = 'VIP_CLIENT',
}
export enum UsersCode {
  MOBILE_INVITATION = 'MOBILE_INVITATION',
  ADMIN_CHAT = 'ADMIN_CHAT',
}

export enum CaseCode {
  SHARE_CASE = 'SHARE_CASE',
  TENANT_SERVICE = 'TENANT_SERVICE',
  DONOR_MATCHING = 'DONOR_MATCHING',
  TENANT_SERVICE_PORTAL = 'TENANT_SERVICE_PORTAL',
  CASETIMELOG = 'CaseTimeLog',
  CREATEREFERRAL = 'CreateReferral',
  CREATECASE = 'CreateCase',
  EDITREQUESTEDTO = 'EditRequestedTo',
  GROUP_CASES = 'GROUP_CASES',
  CASE_PROVIDER_PROCEDURE = 'CASE_PROVIDER_PROCEDURE',
  CASE_TYPE = 'CASE_TYPE',
  INCLUDE_DEPENDENT_CASES_OPTION_FOR_CLIENT = 'INCLUDE_DEPENDENT_CASES_OPTION_FOR_CLIENT',
  ADD_CASE_OPTION_FOR_CLIENT = 'ADD_CASE_OPTION_FOR_CLIENT',
  CALL_TYPE = 'CALL_TYPE',
  PROVIDER_TYPE = 'PROVIDER_TYPE',
  PROVIDER_INCENTVE_TYPE = 'PROVIDER_INCENTVE_TYPE',
  LAYOUT_TEMPLATE = 'LAYOUT_TEMPLATE',
  CASE_CLONE_ENABLED = 'CASE_CLONE_ENABLED',
  CASES_TAGS = 'CASES_TAGS',
  CASES_CONTACT_VIA = 'CASES_CONTACT_VIA',
  CASES_CALL_IN_PERSON = 'CASES_CALL_IN_PERSON',
  CASE_TYPE_SELECTION_CATEGORY = 'CASE_TYPE_SELECTION_CATEGORY',
}

export enum CaseOptions {
  MEDICAL = 'Medical',
  RX = 'Rx',
  SERVICE = 'Service',
  ADVOCACY = 'Advocacy',
  BLOOD_DONOR = 'Blood Donor',
}

export enum NotificationCode {
  TESTRESULTEMAIL = 'TESTRESULTEMAIL',
  VIDEO_VISIT_EMAIL = 'VIDEO_VISIT_EMAIL',
}

export enum EnrollmentCode {
  ADDRESS = 'ADDRESS',
  PHONE = 'PHONE',
  WORK_PHONE = 'WORK_PHONE',
  HOME_PHONE = 'HOME_PHONE',
  REFERRED_BY = 'REFERRED_BY',
  REFERRED_BY_PUBLIC_PAGE = 'REFERRED_BY_PUBLIC_PAGE',
  SEND_TEXT_NOTIFICATION = 'SEND_TEXT_NOTIFICATION',
  ACCEPT_INSURANCE = 'ACCEPT_INSURANCE',
  ADD_CASE_OPTION_FOR_CLIENT = 'ADD_CASE_OPTION_FOR_CLIENT',
  ADD_DEPENDENT_OPTION_FOR_CLIENT = 'ADD_DEPENDENT_OPTION_FOR_CLIENT',
  ADVOCACYPROGRAM = 'ADVOCACYPROGRAM',
  AFFILIATE_VISIBILITY = 'AFFILIATE_VISIBILITY',
  ANCILLARY_EMAIL = 'ANCILLARY_EMAIL',
  APPROVE_MEMBERSHIP = 'APPROVE_MEMBERSHIP',
  BLOOD_GROUP = 'BLOOD_GROUP',
  CHECKINDAYSTHRESHOLD = 'CHECKINDAYSTHRESHOLD',
  CLIENT_AFFILIATE = 'CLIENT_AFFILIATE',
  CLIENT_ENROLLMENT_TYPE = 'CLIENT_ENROLLMENT_TYPE',
  CLIENT_REFERRAL_LINK = 'CLIENT_REFERRAL_LINK',
  COMPLETEMEMBERSHIP = 'COMPLETEMEMBERSHIP',
  CUSTOM_MINUTES_MEMBERSHIP = 'CUSTOM_MINUTES_MEMBERSHIP',
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  GENDER = 'GENDER',
  GENDER_OPTION = 'GENDER_OPTION',
  GENDER_LABEL = 'GENDER_LABEL',
  LAST_FOUR_SSN = 'LAST_FOUR_SSN',
  MEMBER_PROVIDER_ASSOCIATION = 'MEMBER_PROVIDER_ASSOCIATION',
  MEMBERSHIP_ID_CARD = 'MEMBERSHIP_ID_CARD',
  MINIMUM_REQUIREMENT_FEE = 'MINIMUM_REQUIREMENT_FEE',
  MODIFY_MEMBERSHIP_OPTION_FOR_CLIENT = 'MODIFY_MEMBERSHIP_OPTION_FOR_CLIENT',
  SPONSOR_ID = 'SPONSOR_ID',
  SEND_INVITE_OPTION_FOR_CLIENT = 'SEND_INVITE_OPTION_FOR_CLIENT',
  ENROLLMENT_EMAIL = 'ENROLLMENT_EMAIL',
  INTERNATIONAL_ADDRESS = 'INTERNATIONAL_ADDRESS',
  MIN_AGE = 'MIN_AGE',
  ASK_QUESTION = 'ASK_QUESTION',
}

export enum BillingCode {
  CHECKOUT = 'CHECKOUT',
  BANKING = 'BANKING',
  CARD = 'CARD',
  CASH = 'CASH',
  SPONSORACC = 'SPONSORACC',
  CHECK = 'CHECK',
  OTHERS = 'OTHERS',
  ADDTRANSPONSOR = 'ADDTRANSPONSOR', // Add transaction for sponsor (group and primary individual)
  ADDTRANMEMBER = 'ADDTRANMEMBER', // Add transaction for member (group member and dependent)
  ALLOW_BUY_MEMBERSHIP = 'ALLOW_BUY_MEMBERSHIP',
}
export enum ThirdPartyIntegrationCodes {
  DOCUSIGN_INTEGRATION = 'DOCUSIGN_INTEGRATION',
}

export const TenantSettingCodes = {
  miscellaneous: MiscellaneousCode,
  case: CaseCode,
  enrollment: EnrollmentCode,
  billings: BillingCode,
  notification: NotificationCode,
  users: UsersCode,
};

export enum EnrollmentService {
  SERVICES_MEMBER_CHECK_IN = 'SERVICES_MEMBER_CHECK_IN',
  SERVICES_REQUEST_APPOINTMENT = 'SERVICES_REQUEST_APPOINTMENT',
  SERVICES_ADDITIONAL_SERVICES = 'SERVICES_ADDITIONAL_SERVICES',
  SERVICES_MEMBERSHIP_ENROLLMENT = 'SERVICES_MEMBERSHIP_ENROLLMENT',
  SERVICES_SUBMIT_REQUEST = 'SERVICES_SUBMIT_REQUEST',
  SERVICES_ASK_QUESTION = 'SERVICES_ASK_QUESTION',
}

export enum ClientAgreementSettingOptions {
  BASIC = 'Basic',
  DOCU_SIGN = 'DocuSign',
  DISABLED = 'Disabled',
}

export enum CalendarEventSettingOptions {
  APPOINTMENT = 'Appointment',
  AVAILABLE = 'Available',
  AWAY = 'Away',
  SCHEDULE = 'Schedule',
  VIRTUAL_VISIT = 'VirtualVisit',
  TASK = 'Task',
}

export enum IndividualMenuOptions {
  'EDIT_PROFILE' = 'Edit Profile',
  'MODIFY_MEMBERSHIP' = 'Modify Membership',
  'ADD_DEPENDENTS' = 'Add Dependents',
  'SEND_INVITE' = 'Send Invite',
  'LOGIN_AS' = 'Login As',
  'NONE' = 'None',
}
export enum GroupMenuOptions {
  'EDIT_PROFILE' = 'Edit Profile',
  'MODIFY_MEMBERSHIP' = 'Modify Membership',
  'ADD_DEPENDENTS' = 'Add Dependents',
  'SEND_INVITE' = 'Send Invite',
  'LOGIN_AS' = 'Login As',
  'NONE' = 'None',
}

export enum CaseTypeSelectionCategory {
  SINGLE_SELECT = 'Single-Select',
  MULTI_SELECT = 'Multi-Select',
}
