import { useQuery } from 'react-query';

import * as clientAPI from 'api/client-management';
import { YesNo } from 'enums/common';
import {
  IClientFilters,
  IGroupAdvancedSearchForm,
} from 'interfaces/client-management';
import {
  adaptClientGroupList,
  adaptClientIndividualList,
} from 'utils/client-management';
import { unformatPhone } from 'utils/common';

export const groupKeys = {
  all: ['clients-group'] as const,
  lists: () => [...groupKeys.all, 'list'] as const,
  list: (filters: IClientFilters & IGroupAdvancedSearchForm) =>
    [...groupKeys.lists(), { filters }] as const,
  members: ({ id, isActive }: { id: string; isActive: boolean }) =>
    [...groupKeys.lists(), id, isActive] as const,
  details: () => [...groupKeys.all, 'detail'] as const,
  detail: (id: number | string) => [...groupKeys.details(), id] as const,
};

export const useClientGroupQuery = (
  filters: IClientFilters & IGroupAdvancedSearchForm,
  { enabled }: { enabled: boolean }
) => {
  const formattedFilters = {
    ...filters,
    phone: unformatPhone(filters?.phone || ''),
    ...(filters.allowTextNotification && {
      allowTextNotification: filters?.allowTextNotification === YesNo.YES,
    }),
  };
  const queryInfo = useQuery(
    groupKeys.list(filters),
    () => clientAPI.getClients(formattedFilters),
    {
      select: adaptClientGroupList,
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useClientGroupMemberQuery = (
  { clientId, isActive }: { clientId: string; isActive: boolean },
  filters: IClientFilters & IGroupAdvancedSearchForm
) => {
  const formattedFilters = {
    ...filters,
    allowTextNotification: filters?.allowTextNotification === YesNo.YES,
  };
  const queryInfo = useQuery(
    groupKeys.members({ id: clientId, isActive }),
    () => clientAPI.getClients(formattedFilters),
    {
      select: adaptClientIndividualList,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};
