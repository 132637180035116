import { Box, Typography } from '@mui/material';
import { LoadingIndicator } from 'common';
import {
  caseConfigurationSetting,
  SETTINGS_CONTENT_MAX_WIDTH,
} from 'constants/settings';
import { selectTenantSettings } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

import { CaseConfigurationForm } from './CaseConfigurationForm';

export const CaseConfigure = () => {
  const settingsData = useAppSelector(selectTenantSettings);

  const tenantCaseConfigurableSettings = settingsData?.cases?.filter(
    (item) => item.configurable?.cases
  );

  const settingFields = caseConfigurationSetting.map((item) => {
    const settingWithSameCode = tenantCaseConfigurableSettings?.find(
      (setting) => setting.code === item.code
    );
    return {
      ...settingWithSameCode,
      type: settingWithSameCode?.type,
      options: settingWithSameCode?.options,
      required: settingWithSameCode?.required || false,
      value:
        settingWithSameCode?.value === ''
          ? item.value
          : settingWithSameCode?.value,
      metaData: settingWithSameCode?.metaData || null,
    };
  });

  return (
    <Box maxWidth={SETTINGS_CONTENT_MAX_WIDTH.sm} mx="auto" pb={8}>
      <Typography fontWeight="medium" gutterBottom={false} mb={2}>
        Case Configurable Settings
      </Typography>
      {tenantCaseConfigurableSettings ? (
        <CaseConfigurationForm settingFields={settingFields} />
      ) : (
        <LoadingIndicator />
      )}
    </Box>
  );
};
