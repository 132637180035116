import { Outlet } from 'react-router-dom';

import { faCircleInfo, faFileShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Link, Typography, useTheme } from '@mui/material';
import MediumSpanText from 'common/display/MediumSpanText';
import commonConstants from 'constants/common';

interface IProps {
  email: string;
  openGenerateOTPModule: VoidFunction;
  navigateToPortal?: VoidFunction;
  text: string;
  isLoading?: boolean;
}

const MultifactorAuthenticationLayout = ({
  email,
  openGenerateOTPModule,
  navigateToPortal,
  isLoading,
  text,
}: IProps) => {
  const theme = useTheme();
  return (
    <Box>
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="center"
        margin="auto"
        sx={{
          width: { xs: 'auto', md: '600px' },
        }}
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          padding={2}
          paddingY={5}
        >
          <FontAwesomeIcon
            icon={faFileShield}
            size="3x"
            style={{ color: theme.palette.primary.main }}
          />
          <Typography sx={{ marginTop: 2 }} variant="h4">
            Secured Message
          </Typography>
          <Typography
            sx={{ marginTop: 1, marginBottom: 0, textAlign: 'center' }}
            variant="body2"
          >
            A one-time code will be sent to your email{' '}
            <MediumSpanText>({email})</MediumSpanText>
            <br />
            which will allow you to view the {text}.
          </Typography>
          <LoadingButton
            color="primary"
            loading={isLoading}
            onClick={openGenerateOTPModule}
            size="medium"
            sx={{ marginTop: 1 }}
            variant="contained"
          >
            Generate Code
          </LoadingButton>
          {navigateToPortal && (
            <>
              <Typography
                sx={{ marginTop: 1, marginBottom: 0 }}
                variant="body2"
              >
                OR
              </Typography>
              <Button
                color="primary"
                onClick={navigateToPortal}
                size="medium"
                variant="text"
              >
                Login to Vitafy Portal
              </Button>
            </>
          )}
        </Box>
      </Grid>
      <Box
        alignItems="center"
        display="flex"
        paddingX={2}
        paddingY={2}
        sx={{
          backgroundColor: theme.palette.info.lighter,
        }}
      >
        <FontAwesomeIcon
          icon={faCircleInfo}
          size="2x"
          style={{
            color: theme.palette.primary.main,
            marginRight: '1rem',
          }}
        />
        <Typography
          style={{ color: theme.palette.primary.main }}
          variant="body2"
        >
          {`This message is intended for our users. If you've mistakenly
              received this message please ignore the message and close the tab
              or`}
          <Link href={commonConstants.CONTACT_US_URL}> contact us</Link>
        </Typography>
      </Box>
      <Outlet />
    </Box>
  );
};

MultifactorAuthenticationLayout.defaultProps = {
  navigateToPortal: undefined,
  isLoading: false,
};
export default MultifactorAuthenticationLayout;
