/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  faCalendarCheck,
  faEnvelope,
} from '@fortawesome/free-regular-svg-icons';
import { faClock, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  IconButton,
  MenuItem,
  MenuListProps,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PermissionGate from 'common/PermissionGate';
import config from 'config';
import uiRoutes from 'constants/uiRoutes';
import { ReferralCaseStatus } from 'enums/case';
import ModalKey from 'enums/ModalKey';
import { CaseCode, TenantSettingStatus } from 'enums/tenant-management/tenant';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { useCheckUserIsEmail } from 'hooks/useCheckUserisEmail';
import { useGetCaseTenantSettings } from 'hooks/useGetCaseTenantSettings';
import { useRemainingTimeQuery, useUpdateStatusMutation } from 'services/cases';
import { RootState, store } from 'stores';
import { selectMenu } from 'stores/auth';
import { changeRemainingTime, selectCurrentReferralCase } from 'stores/cases';
import { useConfirmationModal } from 'stores/ConfirmationModal';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { GetRemainingTimeColor } from 'utils/cases';
import { checkRoutePathPresent } from 'utils/common';
import { UTCToLocalDateViewFormat } from 'utils/moment';

interface IReferralCaseTopProps {
  handleRefresh: VoidFunction;
}
interface IRefreshShareStatusContainerProps {
  data: string | undefined;
  handleRefresh: VoidFunction;
}
interface IDateIDContainerProps {
  caseId: string;
  createdDate: any;
}

const DateIDContainer = ({ caseId, createdDate }: IDateIDContainerProps) => (
  <>
    <Box>
      <Typography gutterBottom={false} variant="body2">
        Case ID
      </Typography>
      <Typography
        data-cy="case-case-top-caseId"
        gutterBottom={false}
        sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}
        variant="body1"
      >
        {caseId}
      </Typography>
    </Box>
    <Box>
      <Typography gutterBottom={false} variant="body2">
        Created date
      </Typography>
      <Typography
        gutterBottom={false}
        sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}
        variant="body1"
      >
        {createdDate &&
          UTCToLocalDateViewFormat(
            createdDate._d ? createdDate._d : createdDate,
            config.fullDateViewFormat
          )}
      </Typography>
    </Box>
  </>
);

const RefreshShareStatusContainer = ({
  data,
  handleRefresh,
}: IRefreshShareStatusContainerProps) => {
  const [status, setStatus] = useState<string>(
    ReferralCaseStatus.REQUESTED.toUpperCase()
  );
  const isClientSponsor = useCheckUserIsClient();
  const isUserEmail = useCheckUserIsEmail();
  const rootState: RootState = store.getState();
  const caseStore = rootState.case;
  const caseId = caseStore.selectedReferralCase?.caseId;
  const updateStatusMutation = useUpdateStatusMutation();

  const caseStatusOptions =
    caseStore.selectedReferralCase?.caseStatusOptions
      ?.slice()
      ?.sort((a, b) => a.name?.localeCompare(b.name)) || [];

  const isShareCaseEnabled =
    (useGetCaseTenantSettings(CaseCode.SHARE_CASE) as TenantSettingStatus) ===
    TenantSettingStatus.ENABLED;

  const isTimelogEnabled =
    (useGetCaseTenantSettings(CaseCode.CASETIMELOG) as TenantSettingStatus) ===
    TenantSettingStatus.ENABLED;

  const navigate = useNavigate();
  const confirmationModal = useConfirmationModal();
  const menusData = useAppSelector(selectMenu);
  const isVirtualVisitEnabled = checkRoutePathPresent(
    menusData,
    uiRoutes.virtualVisit.virtualVisit
  );
  const memberId =
    caseStore.selectedReferralCase?.patient?.relationship?.groupId ??
    caseStore.selectedReferralCase?.patient?.id;
  const remainingTimeQuery = useRemainingTimeQuery(
    { clientId: memberId!, caseId: caseId! },
    {
      enabled: !!memberId && !!caseId,
    }
  );

  const remainingTime =
    remainingTimeQuery?.data?.rows?.length && remainingTimeQuery?.data?.rows[0];

  const dispatch = useAppDispatch();
  const buttonColor = GetRemainingTimeColor();
  const theme = useTheme();
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (remainingTime) {
      dispatch(changeRemainingTime(remainingTime.totalRemainingTime));
    }
  }, [dispatch, remainingTime]);

  const onDeleteClick = async () => {
    const result = await confirmationModal?.openConfirmationModal({
      title: `Delete Referral Case`,
      content: `Changing status to delete will remove this referral case (${caseId}) permanently. Are you sure you want to continue?`,
    });

    if (result) {
      confirmationModal?.changeSubmittingStatus(true);
      return result;
    }
    return false;
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const isDelete = value === ReferralCaseStatus.DELETED.toUpperCase();
    let confirmDelete = false;
    if (isDelete) confirmDelete = await onDeleteClick();

    if (caseId) {
      if (isDelete && !confirmDelete) return;
      updateStatusMutation.mutate(
        {
          caseId,
          data: { status: value?.toUpperCase() },
        },
        {
          onSuccess: () => {
            setStatus(value as string);
          },
          onSettled: () => {
            confirmationModal?.changeSubmittingStatus(false);
          },
        }
      );
    }
  };

  const onShareClick = () => {
    navigate(`modal?type=${ModalKey.SHARE_REFERRAL_CASE}&caseId=${caseId}`);
  };

  const onTimelogClick = () => {
    navigate(`modal?type=${ModalKey.CASE_TIMELOG}&caseId=${caseId}`);
  };

  const onVirtualVisitClick = () => {
    navigate(
      `modal?type=${ModalKey.ADD_VIRTUAL_VISIT}&caseId=${caseId}&id=${caseStore.selectedReferralCase?.patient?.id}&fromCase=true`
    );
  };

  useEffect(() => {
    if (data) {
      setStatus(data?.toUpperCase());
    }
  }, [data]);

  return (
    <Stack
      direction={isSmallerThanSm ? 'column' : 'row'}
      mb={isSmallerThanSm ? 2 : 0}
      spacing={1}
    >
      <Box display="flex">
        {isVirtualVisitEnabled && (
          <Tooltip title="Virtual Visit">
            <Box>
              <IconButton
                aria-label="virtual-visit"
                data-cy="case-virtualVisit"
                onClick={onVirtualVisitClick}
                size="medium"
              >
                <FontAwesomeIcon icon={faCalendarCheck} />
                {/** TODO: // change icon */}
              </IconButton>
            </Box>
          </Tooltip>
        )}
        {isTimelogEnabled && !isUserEmail && (
          <Tooltip title="Timelog">
            <Box>
              <PermissionGate>
                <IconButton
                  aria-label="timelog"
                  data-cy="case-timeLog"
                  onClick={onTimelogClick}
                  size="medium"
                >
                  <FontAwesomeIcon
                    data-cy="case-timeLog-icon"
                    icon={faClock}
                    size="sm"
                    style={{
                      color: buttonColor,
                    }}
                  />
                </IconButton>
              </PermissionGate>
            </Box>
          </Tooltip>
        )}
        <Tooltip title="Refresh">
          <IconButton
            aria-label="add"
            data-cy="case-refresh"
            onClick={handleRefresh}
            size="medium"
          >
            <FontAwesomeIcon icon={faSyncAlt} size="sm" />
          </IconButton>
        </Tooltip>
        {isShareCaseEnabled && !isClientSponsor && (
          <Tooltip title="Invite">
            <Box>
              <PermissionGate>
                <IconButton
                  aria-label="share"
                  data-cy="case-invite"
                  onClick={onShareClick}
                  size="medium"
                >
                  <FontAwesomeIcon icon={faEnvelope} size="sm" />
                </IconButton>
              </PermissionGate>
            </Box>
          </Tooltip>
        )}
      </Box>

      <Box marginX={4} marginY={4}>
        <PermissionGate checkClickEvent={false} errorProps={{ disabled: true }}>
          <TextField
            disabled={!isUserEmail && isClientSponsor}
            inputProps={{ 'data-cy': 'case-case-top-status' }}
            label=""
            onChange={handleChange}
            select
            SelectProps={{
              MenuProps: {
                MenuListProps: {
                  'data-cy': 'case-status-container',
                } as Partial<MenuListProps>,
              },
            }}
            size="small"
            sx={{ width: '185px' }}
            value={status}
          >
            {caseStatusOptions.map(({ name, code }) => (
              <MenuItem
                data-cy={`case-case-top-${name}`}
                key={`${name}_${code}`}
                // TODO: For now we are not showing Deleted Status for SharedInviteCase
                style={{
                  display: isUserEmail && name === 'Deleted' ? 'none' : 'block',
                }}
                value={code}
              >
                {name}
              </MenuItem>
            ))}
          </TextField>
        </PermissionGate>
      </Box>
    </Stack>
  );
};

const ReferralCaseBodyTop = ({ handleRefresh }: IReferralCaseTopProps) => {
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const theme = useTheme();
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      display="flex"
      flexDirection={isSmallerThanSm ? 'column-reverse' : 'row'}
      justifyContent="space-between"
    >
      <DateIDContainer
        caseId={currentReferralCase?.caseId || ''}
        createdDate={currentReferralCase?.createdDate}
      />

      <Box>
        <RefreshShareStatusContainer
          data={currentReferralCase?.status?.code}
          handleRefresh={handleRefresh}
        />
      </Box>
    </Box>
  );
};

export default ReferralCaseBodyTop;
