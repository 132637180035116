import { TextFieldProps } from '@mui/material';
import { useGetWebsiteLink } from 'hooks/useGetWebsiteLink';

import ShareLink from './ShareLink';

type ShareDomainLinkProps = {
  link: string;
  hideCopyBtn?: boolean;
} & TextFieldProps;

const ShareDomainLink = ({
  link,
  hideCopyBtn,
  ...textFieldProps
}: ShareDomainLinkProps) => {
  const { websiteLink } = useGetWebsiteLink(link);

  return (
    <ShareLink
      hideCopyBtn={hideCopyBtn}
      link={link ? websiteLink : ''}
      {...textFieldProps}
    />
  );
};

ShareDomainLink.defaultProps = {
  hideCopyBtn: false,
};

export default ShareDomainLink;
