import { Grid } from '@mui/material';

interface IProps {
  children: React.ReactNode;
  spacing?: number;
}

const AdvancedCaseFilterFormLayout = ({ children, spacing }: IProps) => (
  <Grid container mb={2} spacing={spacing}>
    <Grid item xs={12}>
      {children}
    </Grid>
  </Grid>
);
AdvancedCaseFilterFormLayout.defaultProps = {
  spacing: 2,
};
export default AdvancedCaseFilterFormLayout;
