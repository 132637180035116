/* eslint-disable no-useless-escape */
const regex = {
  ZIP: /\b\d{5}\b/g,
  PHONE: /^[0-9]{10}$/,
  NPI: /^[0-9]{10}$/,
  EIN: /^[0-9]{9}$/,
  PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  MENTION: /@\[([^\]]+)\]\([^\)]+\)/g,
  removeBrackets: /[\])}[{("]/g, // used to remove [{()}] and "
  ONLY_DIGITS: /^[0-9]+$/,
  LINK: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  DECIMAL_WITH_TWO_POINT: /^\d*\.?\d{0,2}$/,
  HEX_CODE: /^#([0-9a-f]{3}){1,2}$/i, // supports 3 digit code as well (#333)
  ONLY4Digit: /^\d{4}$/,
  EMAIL:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  ALPHANUMERIC: /^[a-z0-9]+$/,
  ALPANUMERIC_CASE_INSENSITIVE: /^[a-z0-9\s]+$/i,
  URL: /^(https?:\/\/)?((([-a-z0-9]{1,63}\.)*?[a-z0-9]([-a-z0-9]{0,253}[a-z0-9])?\.[a-z]{2,63})|((\d{1,3}\.){3}\d{1,3}))(:\d{1,5})?((\/|\?)((%[0-9a-f]{2})|[-\w\+\.\?\/@~#&=])*)?$/,
  NO_WHITE_SPACE: /^\S*$/,
  DOMAIN_NAME_EXTRACT: /^(https?:\/\/[^:/\n?]+)/,
  TWELVE_HOUR_TIME: /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM|am|pm)$/, // Regular expression to match the 12-hour time format
  TWENTY_FOUR_HOUR_TIME:
    /^(?:(?:0?[1-9]|1[0-2]):[0-5][0-9] (?:AM|PM|am|pm))|(?:(?:[01]?[0-9]|2[0-3]):[0-5][0-9] (?:AM|PM|am|pm))$/,
  HEX_COLOR: /^#(?:[0-9a-fA-F]{3}){1,2}$/,
};

export const passwordValidationRegex: { [key: string]: RegExp } = {
  'At Least One Uppercase': /(?=.*?[A-Z])/,
  'At Least One Lowercase': /(?=.*?[a-z])/,
  'At Least One Digit': /(?=.*?[0-9])/,
  'At Least One Special Character(#?!@$%^&*-)': /(?=.*?[#?!@$%^&*-])/,
  'At Least 8 Characters': /.{8,}/,
};

export default regex;
