import { useCallback, useEffect } from 'react';

import { useGetCurrentSelectedClientAndMembershipDetails } from 'common/manage-membership/hooks/useGetCurrentSelectedClientAndMembershipDetails';
import { useGetManageMembershipActionStep } from 'common/manage-membership/hooks/useGetManageMembershipActionStep';
import { useGetManageMembershipActionType } from 'common/manage-membership/hooks/useGetManageMembershipActionType';
import { MembershipOfferingVisibility } from 'enums/client-management';
import { ManageMembershipStatus } from 'enums/client-management/manage-membership';
import { IAdaptedTenantMembershipOfferingTableRow } from 'interfaces/tenant-management/tenant/tenantMembershipOffering';
import {
  setManageMembershipSelectedOfferings,
  setManageMembershipStatus,
} from 'stores/client-management/manage-membership';
import { useAppDispatch } from 'stores/hooks';

export const usePreSelectOffering = ({
  data,
  enabled = true,
}: {
  data: IAdaptedTenantMembershipOfferingTableRow[];
  enabled?: boolean;
}) => {
  const dispatch = useAppDispatch();

  const { clientData, membership } =
    useGetCurrentSelectedClientAndMembershipDetails();
  const { isEditMembership } = useGetManageMembershipActionType();
  const { isStepFirst } = useGetManageMembershipActionStep();

  const selectOffering = useCallback(() => {
    // Used to preselect the recommended offering and manage the data for state management
    const updateRecommended = () => {
      const recommendedMembership = data.find((e) => e.isRecommended);
      if (recommendedMembership && isStepFirst) {
        // this need to be verified
        dispatch(
          setManageMembershipStatus(
            recommendedMembership.visibility ===
              MembershipOfferingVisibility.PRIVATE
              ? ManageMembershipStatus.PRIVATE
              : ManageMembershipStatus.PUBLIC
          )
        );
      }
    };
    // If user has already selected membership then preselect it
    const matchedOffering = isEditMembership
      ? data.find((e) => e.tenantOfferingId === membership?.offerings.id)
      : null;
    if (matchedOffering) {
      dispatch(
        setManageMembershipStatus(
          matchedOffering?.visibility === MembershipOfferingVisibility.PRIVATE
            ? ManageMembershipStatus.PRIVATE
            : ManageMembershipStatus.PUBLIC
        )
      );
      dispatch(
        setManageMembershipSelectedOfferings({
          selectedOffering: matchedOffering,
        })
      );
    } else {
      updateRecommended();
    }
  }, [data, dispatch, isEditMembership, isStepFirst, membership?.offerings.id]);

  useEffect(() => {
    if (data && clientData && enabled) {
      selectOffering();
    }
  }, [clientData, data, enabled, isEditMembership, selectOffering]);
};
