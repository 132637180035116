import { faHandshakeSlash, faXmark } from '@fortawesome/free-solid-svg-icons';
import {
  EnrollmentCode,
  TenantSettingStatus,
} from 'enums/tenant-management/tenant';
import { useGetEnrollmentTenantSettings } from 'hooks/useGetEnrollmentTenantSettings';

export const useGetCancelOrCompleteAction = () => {
  const isCompleteMembershipEnabled =
    (useGetEnrollmentTenantSettings(
      EnrollmentCode.COMPLETEMEMBERSHIP
    ) as TenantSettingStatus) === TenantSettingStatus.ENABLED;

  return {
    icon: isCompleteMembershipEnabled ? faHandshakeSlash : faXmark,
    isCompleteMembershipEnabled,
    label: isCompleteMembershipEnabled ? 'complete' : 'cancel',
    pastLabel: isCompleteMembershipEnabled ? 'completed' : 'cancelled',
    dateTopLabel: isCompleteMembershipEnabled
      ? ' Please select a date that you would like to complete the membership on'
      : 'Are you sure you want to cancel membership on',
    alertLabel: isCompleteMembershipEnabled
      ? 'Membership can be completed at the end of billing cycle. Please confirm to complete membership.'
      : 'Membership can be completed. Please confirm to complete membership.',
  };
};
