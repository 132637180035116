import { useEffect, useState } from 'react';

import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import commonConstants from 'constants/common';
import {
  ChangeMembershipActionType,
  ClientEnrollmentStatus,
  OfferingType,
} from 'enums/client-management';
import { IAdaptedClientEnrollmentMembership } from 'interfaces/client-management/enrollment';
import { useTenantOfferingQuery } from 'services/tenant-management/tenant/tenantMembershipOffering';
import { selectAuthTenantAssociation } from 'stores/auth';
import { setManageMembershipSelectedOfferings } from 'stores/client-management/manage-membership';
import { useAppDispatch, useAppSelector } from 'stores/hooks';

import {
  getImmediateParentId,
  useGetCurrentSelectedClientAndMembershipDetails,
} from '../hooks/useGetCurrentSelectedClientAndMembershipDetails';
import { ChangeApprovedMembershipDate } from './ChangeApprovedMembershipDate';
import { ChangeCancelledMembershipEditableDate } from './ChangeCancelledMembershipEditableDate';
import { SubmittedEditableDate } from './SubmittedEditableDate';

export const EditableBenefitStartDate = ({
  data,
  excludeActions,
}: {
  data: IAdaptedClientEnrollmentMembership;
  excludeActions?: boolean;
}) => {
  const authTenantAssociationData = useAppSelector(selectAuthTenantAssociation);

  const authTenantId = authTenantAssociationData?.tenantId;

  const isStatusSubmitted = data.status === ClientEnrollmentStatus.SUBMITTED;

  const isStatusChangeOnCancelled =
    (data.status === ClientEnrollmentStatus.CANCELLED ||
      data.status === ClientEnrollmentStatus.COMPLETED) &&
    data.actions?.includes(ChangeMembershipActionType.CHANGE);

  const isStatusChangeOnApprove =
    data.status === ClientEnrollmentStatus.APPROVED &&
    data.actions?.includes(ChangeMembershipActionType.CHANGE);

  const isEditingEnabled =
    isStatusSubmitted || isStatusChangeOnCancelled || isStatusChangeOnApprove;

  const [isEditing, setIsEditing] = useState(false);
  const [date, setDate] = useState('');
  const dispatch = useAppDispatch();

  const { membership, clientData } =
    useGetCurrentSelectedClientAndMembershipDetails();

  const primaryId = getImmediateParentId(membership);

  const clientId = clientData?.clientId;
  const isGroupMemberOrDependent =
    !!membership?.groupId || !!membership?.primaryId;

  const { data: offerings } = useTenantOfferingQuery(
    authTenantId!,
    {
      limit: commonConstants.PAGINATION.MAXIMUM_LIMIT,
      offset: commonConstants.PAGINATION.MINIMUM_OFFSET,
      type: OfferingType.MEMBERSHIP,
      recommended: true,
      parentId: primaryId!,
      clientId: clientId!,
    },
    {
      enabled: !!authTenantId && !!primaryId && isGroupMemberOrDependent,
    }
  );

  const handleEditClick = () => {
    dispatch(setManageMembershipSelectedOfferings({ details: data }));
    dispatch(
      setManageMembershipSelectedOfferings({
        startDate: data.startDate,
        endDate: data.endDate,
      })
    );
    if (isStatusChangeOnCancelled) {
      setDate(data.endDate);
    } else {
      setDate(data.startDate);
    }
    setIsEditing(true);
  };

  useEffect(() => {
    if (data.clientEnrollmentId !== membership?.clientEnrollmentId) {
      setIsEditing(false);
    }
  }, [data.clientEnrollmentId, membership?.clientEnrollmentId]);

  const matchedMembershipOffering =
    offerings?.rows.find(
      (offering) => offering?.tenantOfferingId === data?.offerings?.id
    ) || undefined;

  if (isEditing) {
    if (isStatusChangeOnApprove)
      return (
        <ChangeApprovedMembershipDate
          date={date}
          setDate={setDate}
          setIsEditing={setIsEditing}
          status={data.status}
        />
      );
    if (isStatusChangeOnCancelled) {
      return (
        <ChangeCancelledMembershipEditableDate
          date={date}
          setDate={setDate}
          setIsEditing={setIsEditing}
          status={data.status}
        />
      );
    }
    if (isStatusSubmitted) {
      return (
        <SubmittedEditableDate
          date={date}
          parentOffering={matchedMembershipOffering}
          setDate={setDate}
          setIsEditing={setIsEditing}
          status={data.status}
        />
      );
    }
  }
  const isEditable = isEditingEnabled && !excludeActions;
  return (
    <Box
      onClick={() => isEditable && handleEditClick()}
      sx={{ cursor: isEditable ? 'pointer' : 'default' }}
    >
      <Typography
        data-cy="client-modifyMembership-date"
        gutterBottom={false}
        variant="body2"
      >
        <FontAwesomeIcon icon={faCalendar} />{' '}
        {`${data.startDate ? data.startDate : 'N/A'} ${
          data?.endDate ? `- ${data.endDate}` : ''
        }`}
      </Typography>
    </Box>
  );
};

EditableBenefitStartDate.defaultProps = {
  excludeActions: false,
};
