import {
  ClientEnrollmentStatus,
  ClientType,
  OfferingType,
} from 'enums/client-management';
import { BillingType } from 'enums/settings/tenant-offerings';
import {
  EnrollmentCode,
  TenantSettingStatus,
} from 'enums/tenant-management/tenant';
import UserType from 'enums/User';
import { useCheckUserIsClientGroup } from 'hooks/useCheckUserIsClientGroup';
import { useGetCaseTenantSettings } from 'hooks/useGetCaseTenantSettings';
import { IAdaptedClientEnrollmentMembership } from 'interfaces/client-management/enrollment';
import { selectAuthTenantAssociation, selectAuthTenantData } from 'stores/auth';
import {
  selectManageMembershipActionStep,
  selectManageMembershipClient,
  selectManageMembershipSelectedMembership,
  selectManageMembershipTypeGroupDetail,
} from 'stores/client-management/manage-membership';
import { useAppSelector } from 'stores/hooks';
import {
  checkIsClientDependentOrGroupMember,
  checkIsClientGroupMember,
  checkIsClientPrimary,
  checkIsDependentOfGroupMember,
  getClientTypeFromClient,
} from 'utils/client-management';
import { checkIfDateisInFuture } from 'utils/moment';

export const getImmediateParentId = (
  membership: IAdaptedClientEnrollmentMembership | null
) => membership?.primaryId || membership?.groupId || null;

export const useGetCurrentSelectedClientAndMembershipDetails = () => {
  const clientData = useAppSelector(selectManageMembershipClient) || null;

  const actionStep = useAppSelector(selectManageMembershipActionStep);

  const currentMembershipSelectedData = useAppSelector(
    selectManageMembershipSelectedMembership
  );

  const authTenantAssociationData = useAppSelector(selectAuthTenantAssociation);

  const tenantGroupCode = authTenantAssociationData?.groupCode;

  const tenantData = useAppSelector(selectAuthTenantData);

  const loggedInUserAsGroup = useCheckUserIsClientGroup();
  const isClientPrimary = checkIsClientPrimary(clientData);
  const isClientDependentOrGroupMember =
    !!checkIsClientDependentOrGroupMember(clientData);

  const isClientGroupMember = checkIsClientGroupMember(clientData);
  const {
    details: membership,
    selectedOffering,
    ...selectedMembershipModifyingData
  } = currentMembershipSelectedData;

  const clientId = clientData?.clientId || '';
  const clientEnrollmentId =
    membership?.clientEnrollmentId || clientData?.clientEnrollmentId || '';

  const isMembershipBillingTypeFamily =
    membership?.offerings.billingType === BillingType.FAMILY;

  const clientType: ClientType | UserType = (clientData?.type ||
    UserType.INDIVIDUAL) as ClientType | UserType;

  const isClientTypeDependent = clientType === ClientType.DEPENDENT;
  const isClientTypeGroup = clientType === ClientType.GROUP;
  const billingInterval = membership?.offerings.billingInterval;

  const type = getClientTypeFromClient(clientData!);

  const isDependentOfGroupMember = checkIsDependentOfGroupMember(clientData!);

  const isActionEnabledFor =
    type === ClientType.DEPENDENT ||
    type === ClientType.PRIMARY ||
    type === ClientType.GROUP;

  const isCompleteMembershipEnabled =
    (useGetCaseTenantSettings(
      EnrollmentCode.COMPLETEMEMBERSHIP
    ) as TenantSettingStatus) === TenantSettingStatus.ENABLED;

  const isSelectedMembershipBenefitStartDateInFuture = checkIfDateisInFuture(
    currentMembershipSelectedData?.startDate || ''
  );
  const selectedGroupDetails = useAppSelector(
    selectManageMembershipTypeGroupDetail
  );

  const selectedMembershipIsOfGroupId = loggedInUserAsGroup
    ? tenantData?.tenantAssociation.referenceId
    : membership?.groupId;

  const generatePrimaryId = (): string => {
    if (selectedGroupDetails && selectedGroupDetails.clientId) {
      return selectedGroupDetails.clientId;
    }

    return (
      clientData?.relationship?.groupId ||
      clientData?.relationship?.primaryId ||
      clientData?.clientId ||
      ''
    );
  };
  const primaryId = generatePrimaryId();
  const isLimboClient =
    !membership?.offerings?.id &&
    membership?.offerings?.type !== OfferingType.ANCILLARY &&
    membership?.status === ClientEnrollmentStatus.SUBMITTED; // those client whose membership are left to chosen or not chosen(accidental/intentional).

  return {
    selectedMembershipModifyingData,
    currentSelectedStartDate: currentMembershipSelectedData.startDate,
    currentSelectedEndDate: currentMembershipSelectedData.endDate,
    clientData,
    membership,
    clientId,
    clientEnrollmentId,
    clientType,
    isClientTypeDependent,
    billingInterval,
    type,
    isActionEnabledFor,
    isDependentOfGroupMember,
    isCompleteMembershipEnabled,
    isSelectedMembershipBenefitStartDateInFuture,
    actionStep,
    isClientPrimary,
    selectedOffering,
    isMembershipBillingTypeFamily,
    isClientDependentOrGroupMember,
    selectedGroupDetails,
    selectedMembershipIsOfGroupId,
    isClientTypeGroup,
    isClientGroupMember,
    loggedInUserAsGroup,
    tenantGroupCode,
    primaryId,
    isLimboClient,
    tenantId:
      clientData?.tenantId || tenantData?.tenantAssociation.tenantId || '',
  };
};
