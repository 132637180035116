import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Typography } from '@mui/material';
import { getCase } from 'api/cases';
import { ReferenceType } from 'enums/common';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { useCheckUserIsEmail } from 'hooks/useCheckUserisEmail';
import { selectAuthTenantAssociation, selectAuthTenantData } from 'stores/auth';
import { selectCurrentReferralCase } from 'stores/cases';
import { useConfirmationModal } from 'stores/ConfirmationModal';
import { useAppSelector } from 'stores/hooks';
import { socket } from 'stores/socket-io';
import { getSession, setSession } from 'utils/storage';

const useHandleRevoke = () => {
  const [isRevokedCase, setIsRevokedCase] = useState(false);
  const [revokedCaseId, setRevokedCaseId] = useState('');

  const [searchParams] = useSearchParams();
  const fromLink = searchParams.get('fromLink');
  const isFromLink = getSession('isFromLink');

  // If one views access to the case via link in the email (i.e. by login with OTP),
  // show the revoked message replacing the case detail instead of showing it
  // in the confirmation dialog.
  useEffect(() => {
    if (fromLink === 'true') {
      setSession('isFromLink', true);
    }
  }, [fromLink]);

  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const authTenantData = useAppSelector(selectAuthTenantData);
  const loggedInUserEmail = authTenantData?.email;
  const authTenantId = useAppSelector(selectAuthTenantAssociation)?.tenantId;
  const caseId = getSession('caseId') || currentReferralCase?.caseId;

  const isUserEmail = useCheckUserIsEmail();
  const isUserClient = useCheckUserIsClient();

  const revokeModal = useConfirmationModal();

  const checkIfCaseIsRevoked = useCallback(async () => {
    if (caseId && !isUserClient) {
      const getReferralCase = await getCase(caseId);

      const isAccessRevokedForPartner =
        authTenantData?.tenantAssociation?.referenceType ===
        ReferenceType.TENANT
          ? getReferralCase?.data?.access.some(
              (accessItem) =>
                !!accessItem.revoked &&
                accessItem.referenceId === authTenantId &&
                accessItem.referenceType === ReferenceType.TENANT
            )
          : false;

      const isAccessRevokedForThirdParty =
        authTenantData?.tenantAssociation?.referenceType === ReferenceType.EMAIL
          ? getReferralCase?.data?.access.some(
              (accessItem) =>
                !!accessItem.revoked &&
                accessItem.email?.trim()?.toLowerCase() ===
                  loggedInUserEmail?.trim()?.toLowerCase() &&
                accessItem.referenceType === ReferenceType.EMAIL
            )
          : false;

      const isAccessRevoked =
        isAccessRevokedForPartner || isAccessRevokedForThirdParty;

      setIsRevokedCase(isAccessRevoked);
      if (isAccessRevoked) {
        setRevokedCaseId(caseId);
      }
    }
  }, [
    caseId,
    authTenantId,
    loggedInUserEmail,
    isUserClient,
    authTenantData?.tenantAssociation?.referenceType,
  ]);

  useEffect(() => {
    checkIfCaseIsRevoked();
  }, [checkIfCaseIsRevoked]);

  useEffect(() => {
    if (caseId) {
      socket.emit('join-room', `case-${caseId}`);
    }

    return () => {
      socket.emit('leave-room', `case-${caseId}`);
    };
  }, [caseId]);

  useEffect(() => {
    const revokedModalOpen = async () => {
      const result = await revokeModal?.openConfirmationModal({
        isAdditionalContentRequired: false,
        title: `Revoked`,
        isCancelRequired: false,
        isCloseButtonRequired: false,
        submitButtonLabel: 'Reload',
        content: (
          <Typography component="span">
            Your access to this case has been revoked. Please reload the page.
          </Typography>
        ),
      });

      if (result) {
        const { protocol, host, pathname } = window.location;
        // Construct the new URL without the query parameters
        const newUrl = `${protocol}//${host}${pathname}`;

        // Use replaceState to update the URL without reloading
        window.history.replaceState({}, document.title, newUrl);

        // Reload the page
        window.location.reload();
      }
    };

    const checkIsRevokedThroughSocket = ({ data }: { data: any }) => {
      const isCurrentlyViewingCaseRevoked = caseId === data.case.caseId;

      if (isUserClient) return false;

      if (data?.type?.trim()?.toLowerCase() === 'third-party') {
        return (
          isCurrentlyViewingCaseRevoked &&
          isUserEmail &&
          data.email?.trim()?.toLowerCase() ===
            loggedInUserEmail?.trim()?.toLowerCase()
        );
      }

      return (
        isCurrentlyViewingCaseRevoked && authTenantId === data.uninvitedTenantId
      );
    };

    socket.on('case:uninvited', async (data) => {
      const isAccessRevokedThroughSocket = checkIsRevokedThroughSocket({
        data,
      });

      /**
       * Show access revoked info in the dialog when the user is working on a case and suddenly, the access
       * to the case is revoked.
       */
      if (isAccessRevokedThroughSocket) {
        setRevokedCaseId(caseId);
        if (!isFromLink) {
          revokedModalOpen();
        }
      }
      /**
       * This is to show revoked info in the page itself when the user is working on a case and suddenly, the access
       * to the case is revoked.
       */
      if (isAccessRevokedThroughSocket && !!isFromLink) {
        setIsRevokedCase(true);
      }
    });

    return () => {
      socket.off('case:uninvited');
    };
  }, [
    caseId,
    authTenantId,
    revokeModal,
    isRevokedCase,
    isFromLink,
    isUserEmail,
    loggedInUserEmail,
    isUserClient,
  ]);

  const checkRevokeCases = isRevokedCase && !!isFromLink;

  return {
    checkRevokeCases,
    revokedCaseId,
  };
};

export default useHandleRevoke;
