import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SxProps,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { ITenantNetwork } from 'interfaces/tenant-networks';

interface IProps {
  selectedNetworkId: string;
  onChange: (value: string) => void;
  disabled: boolean;
  networksList: ITenantNetwork[];
  size?: 'small' | 'medium';
  sx?: SxProps<Theme> | undefined;
  variant?: 'standard' | 'outlined';
}

const NetworkSelect = ({
  selectedNetworkId,
  onChange,
  disabled,
  networksList,
  size,
  sx,
  variant,
}: IProps) => (
  <Box alignItems="center" display="inline-flex">
    <FormControl
      size={size || 'medium'}
      sx={{
        '& .MuiInputBase-root': {
          width: '200px !important',
        },
        ...sx,
      }}
      variant={variant || 'outlined'}
    >
      <Select
        disabled={disabled}
        id="page-limit-select"
        labelId="page-limit-select-label"
        onChange={(e) => onChange(e.target.value)}
        size={size || 'medium'}
        value={selectedNetworkId}
      >
        {networksList.map((item) => (
          <MenuItem key={item.networkId} value={item.networkId}>
            <Typography
              gutterBottom={false}
              overflow="hidden"
              textOverflow="ellipsis"
              title={item.name}
              variant="body2"
            >
              {item.name}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);

NetworkSelect.defaultProps = {
  sx: undefined,
  size: 'medium',
  variant: 'outlined',
};

export default NetworkSelect;
