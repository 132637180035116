import { useMemo } from 'react';

import { ReferenceType } from 'enums/common';
import { getCaseFilteredAdvanceSearch } from 'features/cases';
import { useCheckUserIsEmail } from 'hooks/useCheckUserisEmail';
import { selectActiveTenant } from 'stores/auth';
import { selectCaseAdvancedSearch, selectCaseFilter } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';
import { getSession } from 'utils/storage';

const useCombinedFilter = () => {
  const guestAccountShareEmail = getSession('guestEmail');
  const filters = useAppSelector(selectCaseFilter);
  const tenant = useAppSelector(selectActiveTenant);
  const advancedSearch = useAppSelector(selectCaseAdvancedSearch);

  const isUserEmail = useCheckUserIsEmail();
  const newFilter = {
    ...filters,
    referenceId: tenant?.tenantId,
    referenceType: ReferenceType.EMAIL,
    email: decodeURIComponent(guestAccountShareEmail || ''),
  };

  const adaptFilter = isUserEmail ? newFilter : filters;

  const combinedFilters = useMemo(
    () => ({
      ...adaptFilter,
      ...getCaseFilteredAdvanceSearch(advancedSearch),
      // TODO: REMOVE AFTER IMPLEMENTATION
      // limit: 50,
    }),
    [adaptFilter, advancedSearch]
  );

  return {
    combinedFilters,
  };
};

export default useCombinedFilter;
