import { VitafyProviderCostType } from 'enums/case';
import { IProviderCost } from 'interfaces/cases';
import { IRelationship } from 'interfaces/client-management/enrollment';
import { getFullName } from 'utils/common';

export const getBundleCostFromProviderCosts = (
  data: IProviderCost[]
): IProviderCost | undefined => {
  const costs =
    data && data.filter((e) => e.type !== VitafyProviderCostType.StateAvg);
  return costs?.length ? costs[0] : undefined;
};

export const getOffsetFromPageNumber = (params: {
  page: number;
  limit: number;
  offset: number;
}) => {
  let offset;
  if (params.page) offset = params.limit * params.page;
  else offset = params.offset;

  return offset;
};

export function getTotalPages(totalData: number, limit: number) {
  return Math.ceil(totalData / limit);
}

export const getLabelForCallInPerson = (user: {
  firstName: string;
  lastName: string;
  middleName?: string;
  relationship?: IRelationship;
  groupName?: string;
}) => {
  if (!user?.firstName) return '';
  const getGroupName = (name: string) => {
    if (!name) return '';
    return `- ${name}`;
  };
  return `${getFullName(
    user.firstName,
    user.lastName,
    user.middleName
  )} ${getGroupName(user.relationship?.groupName || user?.groupName || '')}`;
};
