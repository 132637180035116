import { AccessType } from 'enums/auth';
import { CountryCodes } from 'enums/country';
import { IAdaptedTenantData } from 'interfaces/auth';
import { IAdaptedTenantUser } from 'interfaces/chat';
import { IPermission } from 'interfaces/common';
import { IListResponse, IResponse } from 'interfaces/http';
import { IRoles } from 'interfaces/roles';
import {
  IAdaptedTenantTableRow,
  ITenantTableRow,
} from 'interfaces/tenant-management/tenant';
import {
  IAdaptedAssociation,
  IAdaptedUser,
  IAdaptedUserTableRow,
  IAddProfileAdminForm,
  IAddUserForm,
  IAddUserSchema,
  IProfileAdminSchema,
  IProfileAdminUsersRow,
  IUser,
  IUserTableRow,
} from 'interfaces/tenant-management/user';
import {
  adaptedPhoneExtension,
  attachAddressObject,
  capitalizeFirstLetter,
  formatDate,
  formatPhone,
  getCometChatUID,
  getFullName,
  unformatPhone,
} from 'utils/common';
import { formatDateView } from 'utils/moment';

const mapRoles = (roles: IAdaptedAssociation[]): string[] =>
  roles?.map((item) => item.code) ?? [];

export const formatUserSavePayload = (data: IAddUserForm): IAddUserSchema => {
  const payload: IAddUserSchema = {
    firstName: data.firstName,
    middleName: data.middleName,
    lastName: data.lastName,
    email: data.email,
    dob: data.dob ? formatDate(data.dob) : data.dob,
    gender: data.gender,
    phone: data.phone && unformatPhone(data.phone),
    enableImpersonation: data.enableImpersonation,
    address: attachAddressObject(data),
    countryCode: data.countryCode,
    phoneExt: data.phoneExt,
    roles: mapRoles(data.roles),
  };
  return payload;
};

export const formatTenantChatUserPayload = (
  res: IResponse<IUserTableRow>,
  tenantData: IAdaptedTenantData | null,
  isEdit = false
) => {
  const body: IAdaptedTenantUser = {
    _id: res.data.id ?? '',
    updatedAt: res.data.updatedAt,
    updatedBy: res.data.updatedBy,
    demographic: res.data.demographic,
    enableImpersonation: false,
    userId: getCometChatUID(tenantData, res.data.userId),
    isFriend: false,
    isRegistered: false,
    status: 'offline',
    isEdit,
  };
  return body;
};
export const formatProfileAdminSavePayload = (
  data: IAddProfileAdminForm,
  referenceSubType: string,
  referenceId: string,
  tenantId: string
): IProfileAdminSchema => {
  const payload: IProfileAdminSchema = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    dob: data.dob ? formatDate(data.dob) : data.dob,
    gender: data.gender,
    phone: data.phone && unformatPhone(data.phone),
    address: attachAddressObject(data),
    referenceSubType,
    groupId: referenceId,
    tenantId,
    countryCode: data.countryCode,
    phoneExt: data.phoneExt,
    textEnabled: data.textEnabled,
  };
  return payload;
};

export const adaptUserList = (
  res: IResponse<IListResponse<IUserTableRow>>,
  roles: IRoles[] | undefined,
  tenantId: string
): IResponse<IListResponse<IAdaptedUserTableRow>> => ({
  ...res,
  data: {
    ...res.data,
    rows: res.data?.rows?.map((user) => {
      const countryCode = user?.demographic?.countryCode;
      const adaptCountryCode = !countryCode ? CountryCodes.USA : countryCode;
      const isCountrySelectedUS = adaptCountryCode === CountryCodes.USA;
      const phoneExtension = !user?.demographic?.phoneExt
        ? '+1'
        : `+${user?.demographic?.phoneExt}`;
      const associationRoles = user.association
        .filter((item) => item.tenantId === tenantId)
        .map((item) => item.roles)
        .flat();

      const mapUserRoles =
        roles
          ?.filter((item) => associationRoles.includes(item.code))
          .map((item) => item.name) ?? [];

      return {
        ...user,
        demographic: {
          ...user.demographic,
          fullName: getFullName(
            user.demographic?.firstName,
            user.demographic?.lastName,
            user.demographic?.middleName
          ),
          phone: formatPhone(user.demographic?.phone),
          unFormattedPhone: user.demographic?.phone,
          formattedPhone: isCountrySelectedUS
            ? `${adaptedPhoneExtension(
                user.demographic.phone,
                phoneExtension
              )} ${formatPhone(user.demographic.phone)}`
            : `${adaptedPhoneExtension(
                user.demographic.phone,
                phoneExtension
              )} ${user.demographic.phone}`,
        },
        formattedUpdatedBy: capitalizeFirstLetter(user.updatedBy.name),
        updatedAt: formatDateView(user.updatedAt) || '-',
        roles: mapUserRoles,
      };
    }),
  },
});

export const adaptUser = (res: IResponse<IUser>): IResponse<IAdaptedUser> => ({
  ...res,
  data: {
    ...res.data,
    demographic: {
      ...res.data?.demographic,
      fullName: getFullName(
        res.data?.demographic?.firstName,
        res.data?.demographic?.lastName
      ),
      unFormattedPhone: res.data.demographic?.phone,
    },
  },
});

export const adaptUserTenantList = (
  res: IResponse<ITenantTableRow[]>
): IResponse<IAdaptedTenantTableRow[]> => ({
  ...res,
  data: res.data?.map((tenant) => ({
    ...tenant,
    contactName:
      getFullName(tenant.contactFirstName, tenant.contactLastName) || '',
    phone: formatPhone(tenant.phone),
    updatedBy: capitalizeFirstLetter(tenant.updatedBy),
    updatedAt: formatDateView(tenant.updatedAt) || '-',
  })),
});

export const adaptUserPermission = (
  res: IResponse<IPermission[]>
): IResponse<IPermission[]> => ({
  ...res,
  data: res.data?.map((permission) => ({
    ...permission,
    accessType: permission.accessType ?? '',
    checked: !!permission.accessType,
    children: permission.children?.map((item) => ({
      ...item,
      accessType: item.accessType || AccessType.WRITE,
      checked: !!item?.accessType,
    })),
  })),
});

export const adaptProfileAdminUsersList = (
  res: IResponse<IListResponse<IProfileAdminUsersRow>>
): IResponse<IListResponse<IProfileAdminUsersRow>> => ({
  ...res,
  data: {
    ...res.data,
    rows: res.data?.rows?.map((user) => ({
      ...user,
      demographic: {
        ...user.demographic,
        formattedPhone: formatPhone(user.demographic?.phone),
      },
    })),
  },
});
