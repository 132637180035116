/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { SortOrder } from 'enums/common';
import { IFilter, SortOrderType } from 'interfaces/common';
import { IIntegration } from 'interfaces/tenant-management/tenant';
import { RootState } from 'stores';

const { DEFAULT_TABLE_FILTER } = commonConstants;

interface IDocumentSort {
  sortBy: string | '';
  sortOrder: SortOrderType | '';
}

interface DocumentsState {
  filters: IFilter & { type?: string };
  sort: IDocumentSort;
  integrations: IIntegration[];
}

const sortDefaultValues = {
  sortBy: 'name',
  sortOrder: SortOrder.ASC,
};

const initialState: DocumentsState = {
  filters: { ...DEFAULT_TABLE_FILTER },
  sort: { ...sortDefaultValues },
  integrations: [],
};

export const slice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (
      state,
      action: PayloadAction<Partial<IFilter & { type: string }>>
    ) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    changeSortByAndOrder: (state, action: PayloadAction<IDocumentSort>) => {
      state.sort = {
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    },
    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },
    setIntegrations: (state, action: PayloadAction<IIntegration[]>) => {
      state.integrations = action.payload;
    },
  },
});

// Actions
export const {
  changeFilters,
  changeSortByAndOrder,
  resetFilters,
  setIntegrations,
} = slice.actions;

// Selectors
export const selectDocumentsFilter = (state: RootState) =>
  state.documents.filters;
export const selectDocumentsSort = (state: RootState) => state.documents.sort;
export const selectIntegrations = (state: RootState) =>
  state.documents.integrations;

// Reducer
export default slice.reducer;
