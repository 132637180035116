import config from 'config';
import {
  EMonth,
  EventInterval,
  IFrequency,
  IWEEKDAY,
  IWEEKDAYFULL,
  RecurringMonthType,
} from 'enums/calender';
import ModalKey from 'enums/ModalKey';
import { EventType } from 'enums/virtual-visit';
import { IEventFilter } from 'interfaces/calender';
import { getCurrentDate, MomentDate, momentTimeZone } from 'utils/moment';

export const eventType = {
  all: { display: 'All', isActive: true, value: EventType.ALL },
  appointment: {
    display: 'Appointment',
    isActive: true,
    value: EventType.APPOINTMENTS,
  },
  virtualvisit: {
    display: 'Virtual Visit',
    isActive: true,
    value: EventType.VIRTUAL_VISIT,
  },
  task: { display: 'Task', isActive: true, value: EventType.TASK },
  schedule: {
    display: 'Schedule',
    isActive: true,
    value: EventType.SCHEDULE,
  },
  available: {
    display: 'Available',
    isActive: true,
    value: EventType.AVAILABLE,
  },
  away: {
    display: 'Away',
    isActive: true,
    value: EventType.AWAY,
  },
};

export const eventModalKey = {
  EDIT_VIRTUAL_VISIT: ModalKey.EDIT_VIRTUAL_VISIT,
  ADD_SCHDEULE: ModalKey.ADD_SCHDEULE,
  ADD_APPOINTMENTS: ModalKey.ADD_APPOINTMENTS,
  ADD_TASK: ModalKey.ADD_TASK,
  ADD_VIRTUAL_VISIT: ModalKey.ADD_VIRTUAL_VISIT,
  ADD_AWAY_EVENT: ModalKey.ADD_AWAY_EVENT,
  ADD_AVAILABLE_EVENT: ModalKey.ADD_AVAILABLE_EVENT,
};

export const eventModalRoute = [
  ModalKey.EDIT_VIRTUAL_VISIT,
  ModalKey.ADD_SCHDEULE,
  ModalKey.ADD_APPOINTMENTS,
  ModalKey.ADD_TASK,
  ModalKey.ADD_VIRTUAL_VISIT,
  ModalKey.ADD_AVAILABLE_EVENT,
  ModalKey.ADD_AWAY_EVENT,
];

export const recurringInterval = [
  {
    label: 'Day',
    value: IFrequency.DAILY,
  },
  {
    label: 'Week',
    value: IFrequency.WEEKLY,
  },

  {
    label: 'Month',
    value: IFrequency.MONTHLY,
  },
];

export const DEFAULT_EVENT_FILTER: IEventFilter = {
  eventType: '',
  currentDate: getCurrentDate(config.yearMonthDateFormat),
  interval: EventInterval.MONTH,
  people: '',
  search: '',
  tz: momentTimeZone.tz.guess(),
};

export const weekNames = [
  {
    label: 'Sun',
    value: IWEEKDAY.SUN,
    fullName: IWEEKDAYFULL.SUN,
  },
  {
    label: 'Mon',
    value: IWEEKDAY.MON,
    fullName: IWEEKDAYFULL.MON,
  },
  {
    label: 'Tue',
    value: IWEEKDAY.TUE,
    fullName: IWEEKDAYFULL.TUE,
  },
  {
    label: 'Wed',
    value: IWEEKDAY.WED,
    fullName: IWEEKDAYFULL.WED,
  },
  {
    label: 'Thu',
    value: IWEEKDAY.THU,
    fullName: IWEEKDAYFULL.THU,
  },
  {
    label: 'Fri',
    value: IWEEKDAY.FRI,
    fullName: IWEEKDAYFULL.FRI,
  },
  {
    label: 'Sat',
    value: IWEEKDAY.SAT,
    fullName: IWEEKDAYFULL.SAT,
  },
];

export const monthDropDown = [
  {
    label: 'Only This Month',
    value: RecurringMonthType.ONLY_THIS_MONTH,
  },
  {
    label: 'Every Month',
    value: RecurringMonthType.REPEAT_EVERY_MONTH,
  },
];

export const everyMonthArray = Object.keys(EMonth)
  .slice(0, 12)
  .map((str) => Number(str));

const currentMonth = MomentDate().month() + 1;

export const onlyThisMonthArray = (month?: number) => [month || currentMonth];

export const recurringDefaultValue = {
  isRecurring: false,
  repeatEvery: 1,
  repeatOn: '',
  interval: IFrequency.WEEKLY,
  ends: 'never',
  endsOn: null || new Date().toString(),
  month: RecurringMonthType.REPEAT_EVERY_MONTH,
  occurrence: 1,
  isOnlyThisEvent: false,
};

export const timeArray = () => {
  const timeStr: string[] = [];

  // Generate times from 12:00 AM to 11:00 AM
  for (let hour = 0; hour < 12; hour += 1) {
    const period = 'AM';
    let hourStr;
    if (hour === 0) {
      hourStr = '12';
    } else if (hour < 10) {
      hourStr = `0${hour}`;
    } else {
      hourStr = `${hour}`;
    }
    timeStr.push(`${hourStr}:00 ${period}`);
  }

  // Generate times from 12:00 PM to 11:00 PM
  for (let hour = 12; hour < 24; hour += 1) {
    const period = 'PM';
    let hourStr;
    if (hour === 12) {
      hourStr = '12';
    } else if (hour - 12 < 10) {
      hourStr = `0${hour - 12}`;
    } else {
      hourStr = `${hour - 12}`;
    }
    timeStr.push(`${hourStr}:00 ${period}`);
  }

  return timeStr;
};

export const EVENT_REMAINDER = 10;
