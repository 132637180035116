import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import { faLocation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Autocomplete,
  AutocompleteChangeReason,
  Box,
  CircularProgress,
  debounce,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import ModalKey from 'enums/ModalKey';
import { ILocationDetail } from 'interfaces/common';
import { IAdaptedLocation, ILocationFilter } from 'interfaces/location';
import { useLocationQuery } from 'services/location';
import {
  selectSearchedPreservedData,
  setSearchedPreservedData,
} from 'stores/cases';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { getLocationDetails } from 'utils/geoLocation';

interface ILocationAutoCompleteProps {
  handleChange: (t: IAdaptedLocation) => void;
  label?: string;
  placeholder?: string;
  textFieldProps?: TextFieldProps;
  textFieldFilledVariantProps?: TextFieldProps;
  handleOnInputChange?: (
    event: SyntheticEvent<Element, Event>,
    newValue: string
  ) => void;
  selectedValue?: IAdaptedLocation | null;
  defaultValue?: IAdaptedLocation | null;
  variant?: 'filled' | 'standard';
  name?: string;
  disableClearable?: boolean;
  onCurrentLocationSelect?: (loc: ILocationDetail) => void;
  populateLatLngFromName?: boolean;
  pinCurrentLocation?: boolean;
}

const LocationAutocomplete = ({
  handleChange,
  label,
  placeholder,
  textFieldProps,
  textFieldFilledVariantProps,
  handleOnInputChange,
  // TODO: Refactor `selectedValue` and `defaultValue` to a prop
  selectedValue,
  defaultValue,
  variant,
  disableClearable,
  onCurrentLocationSelect,
  populateLatLngFromName,
  pinCurrentLocation,
}: ILocationAutoCompleteProps): JSX.Element => {
  const isFirstMount = useRef(true);

  const [open, setOpen] = useState(false);
  const [autocompleteValue, setAutocompleteValue] =
    useState<IAdaptedLocation | null>(selectedValue || defaultValue || null);
  const [filters, setFilters] = useState<ILocationFilter>({
    keyword: selectedValue?.name || defaultValue?.name || '',
  });
  const searchedData = useAppSelector(selectSearchedPreservedData);
  const dispatch = useAppDispatch();

  const locationQuery = useLocationQuery(filters);
  const themes = useTheme();
  const [searchParams] = useSearchParams();
  const currentLocation = searchParams.get('type') || '';
  const isManualSearch = currentLocation.includes(ModalKey.ADD_MANUAL_SEARCH);

  const handleInputChange = async (
    event: SyntheticEvent<Element, Event>,
    newInputValue: string
  ) => {
    if (newInputValue.length > 2 || !newInputValue.length)
      setFilters((prevState) => ({ ...prevState, keyword: newInputValue }));
  };

  const onChange = useCallback(
    async (loc: IAdaptedLocation | undefined) => {
      if (loc) handleChange(loc);
    },
    [handleChange]
  );

  const handleSelectCurrentLocationClick = () => {
    if (onCurrentLocationSelect !== undefined) {
      navigator.geolocation.getCurrentPosition(async (location) => {
        const loc = await getLocationDetails(location);
        onCurrentLocationSelect(loc);
      });
    }
  };

  // Workaround for populating lat, lng from the location name
  // Removing this will cause the API call for clients without lat, lng if
  // the patient doesn't have lat, lng
  useEffect(() => {
    if (
      isFirstMount.current &&
      selectedValue?.name &&
      locationQuery?.data &&
      locationQuery.data.length > 0 &&
      populateLatLngFromName
    ) {
      if (selectedValue?.lat && selectedValue?.lng) {
        onChange({
          ...selectedValue,
          zip: Number(selectedValue),
        });
      } else {
        onChange(locationQuery.data[0]);
      }

      isFirstMount.current = false;
    }
  }, [locationQuery?.data, selectedValue, onChange, populateLatLngFromName]);

  useEffect(() => {
    if (defaultValue?.lat || (searchedData.location as IAdaptedLocation)?.lat) {
      setAutocompleteValue(
        defaultValue?.lat
          ? defaultValue
          : (searchedData.location as IAdaptedLocation)
      );
    }
  }, [defaultValue, searchedData.location]);

  const debouncedInputChange = useMemo(
    () => debounce(handleInputChange, 300),
    []
  );

  const options: any = useMemo(
    () => locationQuery?.data || [],
    [locationQuery?.data]
  );

  return (
    <Autocomplete
      autoComplete
      disableClearable={disableClearable}
      filterOptions={(x) => x}
      filterSelectedOptions
      freeSolo
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : `${option.name}`
      }
      id="async-location-autocomplete"
      includeInputInList
      isOptionEqualToValue={(option, newValue) => option.name === newValue.name}
      loading={locationQuery.isLoading}
      onChange={(
        event: SyntheticEvent<Element, Event>,
        newValue: IAdaptedLocation | any | null,
        reason: AutocompleteChangeReason
      ) => {
        if (reason === 'clear') {
          dispatch(setSearchedPreservedData({ ...searchedData, location: '' }));
          const resetValue = {
            zip: '',
            city: '',
            st: '',
            lat: '',
            lng: '',
            name: '',
          };
          onChange(resetValue);
          setAutocompleteValue(resetValue);
        } else {
          onChange(newValue || undefined);
          setAutocompleteValue(newValue);
        }
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={(e, v) => {
        if (handleOnInputChange) handleOnInputChange(e, v);
        debouncedInputChange(e, v);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      openOnFocus={false}
      options={options}
      renderInput={(params) =>
        // You can use different variations of textfield here.
        // Please refer TextfieldOverview.tsx for reference.
        variant === 'standard' ? (
          <TextField
            {...params}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {locationQuery.isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            label={label}
            placeholder={placeholder}
            size={textFieldProps?.size ?? 'small'}
            variant="standard"
            {...textFieldProps}
          />
        ) : (
          <TextField
            {...params}
            className="filled-variant filled-variant--white"
            InputProps={{
              ...params.InputProps,
              ...(pinCurrentLocation && {
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      position: 'absolute',
                      right: isManualSearch ? 80 : 5,
                    }}
                  >
                    <Tooltip arrow placement="top" title="Use current location">
                      <IconButton
                        onClick={handleSelectCurrentLocationClick}
                        type="button"
                      >
                        <Box
                          component={FontAwesomeIcon}
                          icon={faLocation}
                          size="sm"
                          sx={{
                            color: `${themes.palette.common.black} !important`,
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }),
            }}
            placeholder={placeholder}
            size={textFieldProps?.size ?? 'small'}
            {...textFieldFilledVariantProps}
          />
        )
      }
      renderOption={(props, option) => (
        /* eslint-disable react/jsx-props-no-spreading */
        <Box
          {...props}
          component="li"
          key={option.zip}
          sx={{
            '&:hover': {
              color: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          <Typography color="inherit" gutterBottom={false} variant="body2">
            {option.name}
          </Typography>
        </Box>
      )}
      value={autocompleteValue || undefined}
    />
  );
};

LocationAutocomplete.defaultProps = {
  label: '',
  placeholder: 'Enter Location',
  textFieldProps: {},
  textFieldFilledVariantProps: {},
  handleOnInputChange: undefined,
  selectedValue: undefined,
  defaultValue: undefined,
  variant: 'standard',
  name: undefined,
  disableClearable: true,
  onCurrentLocationSelect: undefined,
  populateLatLngFromName: false,
  pinCurrentLocation: false,
};

export default LocationAutocomplete;
