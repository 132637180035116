import { useState } from 'react';

import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Drawer, IconButton, useTheme } from '@mui/material';
import { useScreenSize } from 'hooks/useScreenSize';

import NotesAndFiles from './NoteFilesTab';

const NotesFilesDrawer = () => {
  const theme = useTheme();
  const { height } = useScreenSize();
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <Box
        display="flex"
        flex={1}
        height={height}
        position="relative"
        pr={5}
        width={40}
      >
        <IconButton
          onClick={() => setOpenDrawer(true)}
          sx={{
            position: 'absolute',
            top: '50%',
            height: 20,
            width: 20,
            bgcolor: theme.palette.gray.lighter,
            padding: 2,
          }}
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </IconButton>
      </Box>
      <Drawer
        anchor="right"
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
      >
        <NotesAndFiles drawerView={openDrawer} />
      </Drawer>
    </>
  );
};

NotesFilesDrawer.defaultProps = {
  drawerView: false,
};
export default NotesFilesDrawer;
