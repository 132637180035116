export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHERS = 'Others',
}

export enum GenderShortForm {
  M = 'M',
  F = 'F',
  O = 'O',
}
export enum Email {
  vitafySupport = 'support@vitafyhealth.com',
}

export enum SiteDomain {
  domain = 'vitafyhealth.com',
}

export enum Interval {
  WEEKLY = 'Weekly',
  LAST_MONTH = 'Last Month',
  THIS_MONTH = 'This Month',
  THIS_QUARTER = 'This Quarter',
  THIS_YEAR = 'This Year',
  LAST_YEAR = 'Last Year',
  SINCE_INCEPTION = 'Inception',
  CUSTOM_DATE = 'Custom',
  CURRENT_PERIOD = 'Current Period',
  LAST_PERIOD = 'Last Period',
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum Status {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}
export enum CometChatStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum Signature {
  TYPE = 'Type',
  DRAW = 'Draw',
}

export enum ReferenceType {
  ADMIN = 'Admin',
  SLA = 'SLA',
  TENANT = 'Tenant',
  CLIENT = 'Client',
  AGENT = 'Agent',
  EMAIL = 'Email',
}

export enum ReferenceSubType {
  GROUP = 'Group',
  GROUP_MEMBER = 'Group Member',
  INDIVIDUAL = 'Individual',
  DEPENDENT = 'Dependent',
  AGENT = 'Agent',
  TENANT = 'Tenant',
  EMAIL = 'Email',
}

export enum BrowserType {
  SAFARI = 'Safari',
}

export enum TextTense {
  PAST = 'PAST',
  PRESENT = 'PRESENT',
  PRESENT_PARTICIPLE = 'PRESENT_PARTICIPLE',
}

export enum InvitationType {
  MOBILE = 'Mobile',
}

export enum ShareMode {
  FILE = 'File',
}

export enum CommunicationMedium {
  ALL = 'All',
  EMAIL = 'Email',
  SMS = 'SMS',
}

export enum OTPSourcePayload {
  SHARED_CASES = 'shared-cases',
}

export enum CommunicationEventTopic {
  CASE_ASSIGNED = 'CASE_ASSIGNED',
}

export enum Routes {
  SETTING = 'SETTING',
}

export enum YesNo {
  YES = 'Yes',
  NO = 'No',
}

export enum Environment {
  DEV = 'DEV',
  QA = 'QA',
  UAT = 'UAT',
  PROD = 'PROD',
}
