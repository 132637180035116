import { CaseSharedForType } from 'enums/case';
import { DocumentVisibility } from 'enums/documents';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { IDocument } from 'interfaces/documents';
import {
  selectActiveTenant,
  selectAuthTenantAssociation,
  selectAuthTenantData,
} from 'stores/auth';
import { selectCurrentReferralCase } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';

import { useCheckCaseReadOnlyView } from './useCheckCaseReadOnlyView';

export const useAuthorizedDocumentList = () => {
  const isUserClient = useCheckUserIsClient();

  const activeTenant = useAppSelector(selectActiveTenant);
  const authTenantAssociation = useAppSelector(selectAuthTenantAssociation);
  const authTenantData = useAppSelector(selectAuthTenantData);
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const currentReferralCaseId = currentReferralCase?.caseId || '';

  const { ownerTenant, isInvitedThirdPartyUser } = useCheckCaseReadOnlyView({
    includePatientCheck: true,
  });

  const checkIsFilePublic = ({
    file,
    caseId,
  }: {
    file: IDocument;
    caseId: string;
  }) => {
    if (
      file.associations?.some(
        (assoc) =>
          assoc.visibility === DocumentVisibility.PUBLIC && assoc.id === caseId
      )
    ) {
      return true;
    }

    return false;
  };

  const getDocumentList = (data: IDocument[]) => {
    if (ownerTenant && isUserClient) {
      return data.filter((e) => {
        if (checkIsFilePublic({ file: e, caseId: currentReferralCaseId })) {
          return true;
        }

        const hasClientAccess = e.associations.some(
          (a) =>
            a.metaData?.id === authTenantAssociation?.referenceId &&
            a.metaData?.type === CaseSharedForType.Patient
        );
        const isFileCreatedByPatient =
          isUserClient && e.createdBy?.id === authTenantData?.userId;

        return isFileCreatedByPatient || hasClientAccess;
      });
    }

    if (ownerTenant && isInvitedThirdPartyUser) {
      return data.filter((e) => {
        if (checkIsFilePublic({ file: e, caseId: currentReferralCaseId })) {
          return true;
        }

        const hasThirdPartyAccess = e.associations.some(
          (a) =>
            a.metaData?.email?.trim() === authTenantData?.email?.trim() &&
            a.metaData?.type === CaseSharedForType.ThirdParty
        );
        const isFileCreatedByInvitedThirdParty =
          isInvitedThirdPartyUser &&
          e.createdBy?.email?.trim() === authTenantData?.email?.trim() &&
          e.createdBy?.tenantId === activeTenant?.tenantId;

        return isFileCreatedByInvitedThirdParty || hasThirdPartyAccess;
      });
    }

    if (ownerTenant) {
      return data;
    }

    /**
     * the code block below is the case for partner tenants
     */
    return (
      data.filter((e: IDocument) => {
        // not all the association have visibility set, so the check is set for 'Public' (not 'Private')
        if (checkIsFilePublic({ file: e, caseId: currentReferralCaseId })) {
          return true;
        }

        // Show file if the file is uploaded in the same tenant (i.e. partner tenant)
        const isCreatedCaseFileOfTenant =
          e.createdBy?.tenantId === activeTenant?.tenantId;
        if (isCreatedCaseFileOfTenant) return true;

        // Show file if the partner tenant (tenant, NOT tenant admin) has been tagged
        const isFileForPartner =
          !isCreatedCaseFileOfTenant &&
          e.associations.some(
            (a) =>
              a?.metaData?.id === activeTenant?.tenantId &&
              a.metaData.type === CaseSharedForType.Partner
          );

        if (isCreatedCaseFileOfTenant || isFileForPartner) return true;

        return false;
      }) || []
    );
  };

  return getDocumentList;
};
