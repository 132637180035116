import { useNavigate } from 'react-router-dom';

import {
  faAddressBook,
  faCloudUpload,
  faFileClipboard,
  faLink,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import { ModalFooter } from 'common';
import LabelBoxCard from 'common/LabelBoxCard';
import menuPaths from 'constants/path';
import { selectMenu } from 'stores/auth';
import { changeFilesTabStep, selectCurrentReferralCase } from 'stores/cases';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { checkMenu, getFullName } from 'utils/common';

interface IClickBox {
  onClick: VoidFunction;
  label: string;
  icon: IconDefinition;
}

export const ClickBox = ({ onClick, label, icon }: IClickBox) => {
  const theme = useTheme();

  return (
    <Paper
      className="bordered no-box-shadow"
      data-cy={`upload-file-${label}`}
      onClick={onClick}
      sx={{
        paddingTop: 4,
        paddingBottom: 3,
        paddingX: 1,
        height: '134px',
        cursor: 'pointer',
        border: `1px solid ${theme.palette.gray.light}`,
        '&:hover': {
          boxShadow: theme.customShadows.medium,
        },
      }}
    >
      <Box textAlign="center">
        <Box>
          <FontAwesomeIcon
            icon={icon}
            size="2x"
            style={{ color: theme.palette.primary.main }}
          />
        </Box>
        <Typography variant="caption">{label}</Typography>
      </Box>
    </Paper>
  );
};

const ChooseFileUploadOptions = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedReferralCase = useAppSelector(selectCurrentReferralCase);
  const menus = useAppSelector(selectMenu);

  const handleClose = () => {
    navigate(-1);
  };

  const onOptionClick = (step: number) => {
    dispatch(changeFilesTabStep(step));
  };

  const fullName =
    selectedReferralCase &&
    selectedReferralCase.patient &&
    getFullName(
      selectedReferralCase.patient?.firstName,
      selectedReferralCase.patient?.lastName,
      selectedReferralCase.patient?.middleName
    );
  // const isUserClient = useCheckUserIsClient();

  const isDocumentMenuAccessible = checkMenu(menuPaths.documents, menus);
  return (
    <Box>
      <Box minHeight="60vh">
        <LabelBoxCard
          label="Below are the available options to upload files"
          title={`Upload files for ${fullName}`}
        />

        <Box marginBottom={5} marginTop={2} padding={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ClickBox
                icon={faCloudUpload}
                label="From Computer"
                onClick={() => onOptionClick(1)}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <ClickBox
                icon={faCloudUpload}
                label="Google Drive"
                onClick={() => onOptionClick(2)}
              />
            </Grid> */}
            {/* {!isUserClient && (
              <> */}
            <Grid item xs={6}>
              <ClickBox
                icon={faAddressBook}
                label="From Profile"
                onClick={() => onOptionClick(3)}
              />
            </Grid>
            {isDocumentMenuAccessible && (
              <Grid item xs={6}>
                <ClickBox
                  icon={faFileClipboard}
                  label="From Files"
                  onClick={() => onOptionClick(4)}
                />
              </Grid>
            )}

            <Grid item xs={6}>
              <ClickBox
                icon={faLink}
                label="From URL"
                onClick={() => onOptionClick(5)}
              />
            </Grid>
            {/* </>
            )} */}
          </Grid>
        </Box>
      </Box>

      <ModalFooter
        actionButtonType="button"
        isActionRequired={false}
        isLoading={false}
        onCancelButtonClick={handleClose}
      />
    </Box>
  );
};

export default ChooseFileUploadOptions;
