import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { useCheckFormFieldSettings } from 'hooks/useCheckEnabledFields';

/**
 * Reset `referredBy` field when changing `clientType` if client affliate is enabled (i.e. referral is client instead of agent)
 *
 * Needed to do so, because refferedBy for both client and agent use the same name and we have condition  on primary form ,reffered by can be any one
 *
 * The whole point of this custom hook is to provide proper validation when the client affiliate is enabled.
 * If the client affiliate is enabled, then the primary form has client autocomplete whereas the group form has agent autocomplete (group
 * form contains agent autocomplete only).
 * As they have same field `referredBy`, it causes form validation error.
 *
 * To combat this issue, we are reset the value of `referredBy` field when the clientType is changed.
 *
 *
 */

export const useResetReferredByOnClientTypeChange = () => {
  const checkEnabledSettings = useCheckFormFieldSettings().enabled;
  const isClientAffiliateEnabled = checkEnabledSettings?.clientAffiliate;

  const { watch, setValue, trigger } = useFormContext();
  const { clientType } = watch();

  const prevClientTypeRef = useRef();

  const dobValue = watch('dob');

  /**
   * No need to reset the field if the clientType changes from `undefined` or `""` to "Primary" or "Group".
   *
   */
  useEffect(() => {
    if (isClientAffiliateEnabled && !!prevClientTypeRef.current) {
      setValue('referredBy', {
        id: '',
        name: '',
      });
    }
    if (dobValue.length > 1) {
      trigger('dob'); // this done to revalidate dob while switching client type because logic of dob validation is different according to client type
    }
    prevClientTypeRef.current = clientType;
  }, [
    clientType,
    dobValue.length,
    isClientAffiliateEnabled,
    setValue,
    trigger,
  ]);
};
