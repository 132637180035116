import { useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, TextField, Typography } from '@mui/material';
import { useGetReleaseNote } from 'hooks/useGetReleaseNote';
import { useUpdateLookUpMutation } from 'services/lookups';
import { setLatestReleaseNote } from 'stores/auth';
import { useAppDispatch } from 'stores/hooks';
import { formatReleaseNotePayload } from 'utils/lookup';

export const ReleaseNoteContent = ({
  handleClose,
}: {
  handleClose?: VoidFunction;
}) => {
  const [releaseNote, setReleaseNote] = useState('');
  const dispatch = useAppDispatch();

  const {
    releaseNoteDetail: { id, note },
  } = useGetReleaseNote();

  const updateReleaseNoteMutation = useUpdateLookUpMutation();

  const handleAddReleaseNote = () => {
    const payload = formatReleaseNotePayload({
      releaseNote,
    });
    updateReleaseNoteMutation.mutate(
      { data: payload, id },
      {
        onSuccess() {
          dispatch(setLatestReleaseNote(releaseNote));
          handleClose?.();
        },
      }
    );
  };

  const handleOnChange = (e: string) => {
    setReleaseNote(e);
  };

  useEffect(() => {
    setReleaseNote(note);
  }, [note]);

  return (
    <Box>
      <Typography fontWeight="medium">Add release note link </Typography>
      <TextField
        fullWidth
        label="Release note link"
        onChange={(e) => handleOnChange(e.target.value)}
        value={releaseNote}
        variant="standard"
      />
      <Box mt={2} textAlign="right">
        <LoadingButton
          disabled={note === releaseNote}
          loading={updateReleaseNoteMutation.isLoading}
          onClick={handleAddReleaseNote}
          variant="contained"
        >
          Save
        </LoadingButton>
      </Box>
    </Box>
  );
};

ReleaseNoteContent.defaultProps = {
  handleClose: undefined,
};
