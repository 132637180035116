import regex from 'constants/regex';
import validationMessage from 'constants/validationMessage';
import { momentDateOnlyIsSameOrAfter } from 'utils/moment';
import * as Yup from 'yup';

const { REQUIRED, PASSWORD_NOT_MATCHED, PASSWORD_REQUIREMENT, INVALID } =
  validationMessage;

export const LoginSchema = Yup.object().shape({
  username: Yup.string().trim().required(REQUIRED),
  password: Yup.string().trim().required(REQUIRED),
  keepLoggedIn: Yup.bool(),
});
export const RescheduleRequestSchema = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .trim()
    .when('isUserLoggedIn', {
      is: (isUserLoggedIn: boolean) => !isUserLoggedIn,
      then: Yup.string()
        .trim()
        .matches(regex.EMAIL, {
          message: INVALID,
          excludeEmptyString: true,
        })
        .required(REQUIRED),
    }),
  reason: Yup.string().trim().required(REQUIRED),
  dateValue: Yup.string()
    .nullable()
    .required(REQUIRED)
    .test('checkIfDateIsValid', INVALID, (value) => {
      if (!value) return false;

      if (momentDateOnlyIsSameOrAfter(value, new Date())) return true;
      return false;
    }),
  startTimeValue: Yup.string().nullable().trim().required(REQUIRED),
  endTimeValue: Yup.string().nullable().trim().required(REQUIRED),
});

export const CancelRequestSchema = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .trim()

    .when('isUserLoggedIn', {
      is: (isUserLoggedIn: boolean) => !isUserLoggedIn,
      then: Yup.string()
        .trim()
        .matches(regex.EMAIL, {
          message: INVALID,
        })
        .required(REQUIRED),
    }),
  reason: Yup.string().trim().required(REQUIRED),
});

export const ForgetPasswordSchema = Yup.object().shape({
  username: Yup.string().trim().required(REQUIRED),
});

export const ForgetUsernameSchema = Yup.object().shape({
  email: Yup.string().trim().required(REQUIRED),
  firstName: Yup.string().trim().required(REQUIRED),
  lastName: Yup.string().trim().required(REQUIRED),
});

export const ResetPasswordSchema = Yup.object().shape({
  username: Yup.string().trim().required(REQUIRED),
  password: Yup.string().trim().required(REQUIRED).matches(regex.PASSWORD, {
    excludeEmptyString: false,
    message: PASSWORD_REQUIREMENT,
  }),
  confirmPassword: Yup.string()
    .trim()
    .required(REQUIRED)
    .oneOf([Yup.ref('password')], PASSWORD_NOT_MATCHED),
});

export const VerifyUserAccountSchema = Yup.object().shape({
  firstName: Yup.string().trim().required(REQUIRED),
  lastName: Yup.string().trim().required(REQUIRED),
  email: Yup.string()
    .nullable()
    .trim()
    .required(REQUIRED)
    .matches(regex.EMAIL, {
      message: INVALID,
    }),
});

export const ChangeCredentialSchema = Yup.object().shape({
  oldPassword: Yup.string().trim().required(REQUIRED),
  password: Yup.string().trim().required(REQUIRED).matches(regex.PASSWORD, {
    excludeEmptyString: false,
    message: PASSWORD_REQUIREMENT,
  }),
  confirmPassword: Yup.string()
    .trim()
    .required(REQUIRED)
    .matches(regex.PASSWORD, {
      excludeEmptyString: false,
      message: PASSWORD_REQUIREMENT,
    })
    .oneOf([Yup.ref('password')], PASSWORD_NOT_MATCHED),
});

export const CreateCredentialSchema = Yup.object().shape({
  password: Yup.string().trim().required(REQUIRED).matches(regex.PASSWORD, {
    excludeEmptyString: false,
    message: PASSWORD_REQUIREMENT,
  }),
  confirmPassword: Yup.string()
    .trim()
    .required(REQUIRED)
    .matches(regex.PASSWORD, {
      excludeEmptyString: false,
      message: PASSWORD_REQUIREMENT,
    })
    .oneOf([Yup.ref('password')], PASSWORD_NOT_MATCHED),
});

export const LinkAccountSchema = Yup.object().shape({
  username: Yup.string().trim().required(REQUIRED),

  password: Yup.string().trim().required(REQUIRED).matches(regex.PASSWORD, {
    excludeEmptyString: false,
    message: PASSWORD_REQUIREMENT,
  }),
});
