import {
  faCakeCandles,
  faCalendarCheck,
  faDroplet,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, useTheme } from '@mui/material';
import { IAdaptedClient } from 'interfaces/client-management';

interface IProps {
  data: IAdaptedClient | undefined;
}

const BloodGroupDOBDateDisplay = ({ data }: IProps) => {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {data?.dob && (
        <Box alignItems="center" display="flex">
          <Box alignItems="center" display="flex" width="105px">
            <FontAwesomeIcon icon={faCakeCandles} size="sm" />
            <Typography
              color={theme.palette.common.black}
              gutterBottom={false}
              pl={1}
              variant="body2"
            >
              Date of Birth:
            </Typography>
          </Box>
          <Box>
            <Typography
              color={theme.palette.common.black}
              fontWeight="medium"
              gutterBottom={false}
              pl={1}
              sx={{ textWrap: 'nowrap' }}
              variant="body2"
            >
              {data.dob}
            </Typography>
          </Box>
        </Box>
      )}

      {data?.bloodGroup && (
        <Box alignItems="center" display="flex">
          <Box alignItems="center" display="flex" width="105px">
            <FontAwesomeIcon icon={faDroplet} size="sm" />
            <Typography
              color={theme.palette.common.black}
              gutterBottom={false}
              pl={1}
              sx={{ textWrap: 'nowrap' }}
              variant="body2"
            >
              Blood Group:
            </Typography>
          </Box>
          <Box>
            <Typography
              color={theme.palette.common.black}
              fontWeight="medium"
              gutterBottom={false}
              pl={1}
              variant="body2"
            >
              {data?.bloodGroup}
            </Typography>
          </Box>
        </Box>
      )}
      {data?.membership?.startDate && (
        <Box alignItems="center" display="flex">
          <Box alignItems="center" display="flex" width="105px">
            <FontAwesomeIcon icon={faCalendarCheck} size="sm" />
            <Typography
              color={theme.palette.common.black}
              gutterBottom={false}
              pl={1}
              sx={{ textWrap: 'nowrap' }}
              variant="body2"
            >
              Benefit Date:
            </Typography>
          </Box>
          <Box>
            <Typography
              color={theme.palette.common.black}
              fontWeight="medium"
              gutterBottom={false}
              pl={1}
              variant="body2"
            >
              {data?.membership?.startDate}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default BloodGroupDOBDateDisplay;
