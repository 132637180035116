import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';
import { LoadingIndicator } from 'common';
import EmptySearchList from 'common/EmptySearchList';
import uiRoutes from 'constants/uiRoutes';
import { useFetchRelevantReferralCaseInfiniteList } from 'features/case-layout-new/hooks/useFetchRelevantReferralCaseInfiniteList';
import { useHandleSelectedCaseId } from 'features/case-layout-new/hooks/useHandleSelectedCaseId';
import useShowRelevantSearch from 'features/case-layout-new/hooks/useShowRelevantSearch';
import { IReferralCaseList } from 'interfaces/cases';
import {
  changePullRelevantSearch,
  selectCurrentReferralCase,
  selectIsRelevantSearchEntered,
  selectIsScrollingEnabled,
} from 'stores/cases';
import { useAppDispatch, useAppSelector } from 'stores/hooks';

import ReferralCaseListItem from './CaseListItem';

export const RelevantSearchLists = ({
  searchedCases,
}: {
  searchedCases: IReferralCaseList[];
}) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const isRelevantSearchEntered = useAppSelector(selectIsRelevantSearchEntered);
  const isScrollingEnabled = useAppSelector(selectIsScrollingEnabled);

  const {
    isRelevantCaseEnabled,
    keyword,
    isPullRelevantSearch,
    caseListTotalCount,
  } = useShowRelevantSearch();

  const { isLoading, data } = useFetchRelevantReferralCaseInfiniteList();

  const handleSelectedCaseId = useHandleSelectedCaseId();
  const handleChangeSelectedCaseId = (caseId: string) => {
    handleSelectedCaseId.changeSelectedCase(caseId);
  };

  const flattenedData = useMemo(() => {
    if (data) {
      const cases = data?.pages?.flatMap((item) => item.rows) || [];
      return cases?.filter(
        (e) => !searchedCases?.some((s) => s.caseId === e.caseId)
      );
    }
    return [];
  }, [data, searchedCases]);

  /**
   * Select the first case from the list (that in turn fetches detail of that case)
   */
  useEffect(() => {
    const isModelOpen = location.pathname.includes(uiRoutes.modal);
    if (
      !caseListTotalCount &&
      !isLoading &&
      flattenedData?.length &&
      !isModelOpen &&
      !currentReferralCase
    ) {
      const firstListCaseId = flattenedData[0]?.caseId;
      handleSelectedCaseId.changeSelectedCase(firstListCaseId);
    }
  }, [
    caseListTotalCount,
    currentReferralCase,
    data,
    flattenedData,
    handleSelectedCaseId,
    isLoading,
    location.pathname,
  ]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (
    !isRelevantCaseEnabled &&
    keyword &&
    !isPullRelevantSearch &&
    (isRelevantSearchEntered || isScrollingEnabled)
  ) {
    return (
      <Box display="flex" justifyContent="center" padding={2}>
        <Button
          fullWidth
          onClick={() => {
            dispatch(changePullRelevantSearch(true));
          }}
          type="button"
          variant="outlined"
        >
          Pull relevant cases
        </Button>
      </Box>
    );
  }
  if (keyword && (isRelevantCaseEnabled || isPullRelevantSearch)) {
    dispatch(changePullRelevantSearch(true));
    return (
      <Box sx={{ borderTop: (t) => `1px solid ${t.palette.gray.light}` }}>
        <Box p={2} textAlign="center">
          <Typography variant="h6">Relevant Results</Typography>
          {flattenedData.length ? (
            <Typography gutterBottom={false} variant="body2">
              Based on your search criteria, some relevant results were found.
            </Typography>
          ) : (
            ''
          )}
        </Box>
        {flattenedData.length ? (
          flattenedData?.map((el: IReferralCaseList, index: number) => (
            <ReferralCaseListItem
              data={el}
              handleChangeSelectedCaseId={handleChangeSelectedCaseId}
              // eslint-disable-next-line react/no-array-index-key
              key={`${el.caseId}-${index}`}
            />
          ))
        ) : (
          <Box
            alignItems="center"
            display="flex"
            height="100%"
            justifyContent="center"
          >
            <EmptySearchList
              avatarProps={{ sx: { width: '40px', height: '40px' } }}
              message="Based on your search criteria, no relevant results were found."
            />
          </Box>
        )}
      </Box>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
