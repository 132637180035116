import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { Box, Grid } from '@mui/material';
import { FormClientAutocomplete } from 'common';
import FormTextField from 'common/form/FormTextField';
import { DictionaryKeys } from 'constants/languageDictionary';
import UserType from 'enums/User';
import { useTenantTerminology } from 'hooks/useTenantTerminology';
import {
  IAdaptedClientGroupTableRow,
  IAdaptedClientIndividualTableRow,
  IAddClientForm,
} from 'interfaces/client-management';

import IndividualForm from './IndividualForm';

const GroupMemberForm = ({
  isClientAutocompleteDisabled,
  isSponsorIdEnabled,
}: {
  isClientAutocompleteDisabled: boolean;
  isSponsorIdEnabled?: boolean;
}): JSX.Element => {
  const { setValue } = useFormContext<IAddClientForm>();
  const [searchParams] = useSearchParams();
  const parentTenantId = searchParams.get('parentTenantId') || '';

  const terminology = useTenantTerminology([
    DictionaryKeys.PATIENT_TERMINOLOGY,
  ]);

  useEffect(() => {
    if (parentTenantId) {
      setValue('tenantId', parentTenantId);
    }
  }, [parentTenantId, setValue]);

  const onClientSelect = (
    client:
      | IAdaptedClientGroupTableRow
      | IAdaptedClientIndividualTableRow
      | null
  ) => {
    if (client) {
      setValue('tenantId', client.membership?.tenantId || '');
    }
  };

  return (
    <>
      <Box mb={2}>
        <FormClientAutocomplete
          clientSelectHandler={onClientSelect}
          disabled={isClientAutocompleteDisabled}
          label={`Select ${terminology?._PATIENT_TERMINOLOGY} *`}
          name="groupId"
          setParentClientName
          userType={UserType.GROUP}
        />
      </Box>
      <IndividualForm />
      {isSponsorIdEnabled && (
        <Grid container>
          <Grid mb={4} mt={4} xs={6}>
            <FormTextField label="Member Id" name="memberId" />
          </Grid>
        </Grid>
      )}
    </>
  );
};

GroupMemberForm.defaultProps = {
  isSponsorIdEnabled: false,
};

export default GroupMemberForm;
