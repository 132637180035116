import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import toastMessageConstant from 'constants/toastMessage';
import { useSnackbar } from 'notistack';
import QRCodePackage from 'qrcode';

const QRCode = ({
  width,
  height,
  content,
}: {
  width?: number;
  height?: number;
  content: string;
}) => {
  const [generatedQRCode, setGeneratedQRCode] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const generateQRCode = async (text: string) => {
      try {
        // Generate QR code
        const qrCodeDataUrl = await QRCodePackage.toDataURL(text);

        // Set the generated QR code data URL
        setGeneratedQRCode(qrCodeDataUrl);
      } catch (error) {
        //   console.error('Error generating QR code:', error.message);
        enqueueSnackbar(toastMessageConstant.QR_CODE_FAILED, {
          variant: 'error',
        });
      }
    };

    if (content) {
      generateQRCode(content);
    }
  }, [content, enqueueSnackbar]);
  return (
    <Box>
      {generatedQRCode && (
        <img
          alt="QR Code"
          height={`${height}px`}
          src={generatedQRCode}
          width={`${width}px`}
        />
      )}
    </Box>
  );
};
QRCode.defaultProps = {
  width: 150,
  height: 150,
};
export default QRCode;
