import { useState } from 'react';

import MultifactorAuthenticationLayout from 'common/layouts/multifactor-authentication';
import { IEmailToken } from 'interfaces/SharedResourceAuthenticator';
import { useGenerateOTP } from 'services/otp';

import { OTPVerifier } from './OTPVerifier';

interface IOTPVerificationProps {
  emailToken: IEmailToken;
  encryptedEmailToken: string;
  handleOtpVerificationSuccess: VoidFunction;
  navigateViaLogin: VoidFunction;
}

export const OTPVerification = ({
  emailToken,
  encryptedEmailToken,
  handleOtpVerificationSuccess,
  navigateViaLogin,
}: IOTPVerificationProps) => {
  const generateOTP = useGenerateOTP();

  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  const [otpGeneratedCode, setOtpGeneratedCode] = useState('');

  const { receiver } = emailToken;

  const onGenerateOtpClick = () => {
    generateOTP.mutate(
      {
        link: encryptedEmailToken,
      },
      {
        onSuccess: (res) => {
          setOtpGeneratedCode(res.data);
          setIsOTPModalOpen(true);
        },
      }
    );
  };

  if (isOTPModalOpen) {
    return (
      <OTPVerifier
        emailToken={emailToken}
        handleOTPVerificationSuccess={handleOtpVerificationSuccess}
        isResendOtpInProgress={generateOTP.isLoading}
        onBackClick={() => setIsOTPModalOpen(false)}
        onResentOtp={onGenerateOtpClick}
        otpGeneratedCode={otpGeneratedCode}
      />
    );
  }

  return (
    <MultifactorAuthenticationLayout
      email={receiver.email}
      isLoading={generateOTP.isLoading}
      navigateToPortal={navigateViaLogin}
      openGenerateOTPModule={onGenerateOtpClick}
      text="info"
    />
  );
};
