import { useNavigate } from 'react-router-dom';

import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import EmptyListImage from 'assets/images/png/emptyList.png';
import ModalKey from 'enums/ModalKey';
import { selectCurrentReferralCase } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';

import MobileProviderOption from './MobileProviderOption';
import ProviderOptionRow from './ProviderOptionRow';

export const EmptyProviderOption = () => (
  <Box
    alignItems="center"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    marginTop={5}
    padding={5}
    paddingBottom={3}
  >
    <Avatar
      alt="empty"
      src={EmptyListImage}
      sx={{ width: '60px', height: '60px', marginBottom: 3 }}
      variant="square"
    />
    <Box>
      <Typography variant="body2">No provider options available</Typography>
    </Box>
  </Box>
);

const ProviderOptions = () => {
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);

  const data = currentReferralCase?.providerOptions;

  const theme = useTheme();
  const navigate = useNavigate();
  const smBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
  const onAddClick = () => {
    navigate(
      `modal?type=${ModalKey.ADD_REFERRING_FACILITY}&caseId=${currentReferralCase?.caseId}`
    );
  };
  return (
    <Stack spacing={2}>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Typography
          color="gray.darker"
          fontWeight="medium"
          gutterBottom={false}
          variant="body1"
        >
          Provider Options
        </Typography>
        <Button
          onClick={onAddClick}
          startIcon={<FontAwesomeIcon icon={faAdd} />}
          variant="text"
        >
          Add
        </Button>
      </Box>
      {!smBreakpoint ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {data?.map((row) => (
            <MobileProviderOption data={row} />
          ))}
        </>
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Provider Name</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell>Cost</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row) => (
                <ProviderOptionRow data={row} key={row.id} />
              ))}
            </TableBody>
          </Table>
          {!data?.length && <EmptyProviderOption />}
        </TableContainer>
      )}
    </Stack>
  );
};

export default ProviderOptions;
