import apiRoutes from 'constants/apiRoutes';
import { IVerifyOTPToken } from 'interfaces/auth';
import { IOtpIncludeLink } from 'interfaces/common';
import { IResponse } from 'interfaces/http';
import { IEmailUserInfo } from 'interfaces/SharedResourceAuthenticator';
import { baseRequest } from 'utils/axios';

interface IOTPExistsResponse {
  isExist: boolean;
  code: string;
}

const { otp } = apiRoutes;
export const generateOTP = async (
  link: string,
  source: string,
  includeInLink?: IOtpIncludeLink
): Promise<IResponse<string>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: otp.generate,
    data: { link, source, includeInLink },
  });
  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};

export const checkIfOTPExists = async (
  link: string
): Promise<IResponse<IOTPExistsResponse>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: otp.exists,
    data: { link },
  });
  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};

export const decodeOTP = async (data: {
  code: string;
}): Promise<IResponse<any>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: otp.decode,
    data,
  });
  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};

export const verifyOTP = async (data: {
  otp: string;
  code: string;
}): Promise<IResponse<IVerifyOTPToken | null>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: otp.verify,
    data,
  });
  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};

export const verifyOTPV1 = async (data: {
  otp: string;
  code: string;
  user: IEmailUserInfo;
}): Promise<IResponse<IVerifyOTPToken | null>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: otp.verfiyV1,
    data,
  });
  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};
