import { useMutation } from 'react-query';

import {
  checkIfOTPExists,
  decodeOTP,
  generateOTP,
  verifyOTP,
  verifyOTPV1,
} from 'api/otp';
import { IOtpIncludeLink } from 'interfaces/common';
import { IError } from 'interfaces/http';
import { IEmailUserInfo } from 'interfaces/SharedResourceAuthenticator';
import { useSnackbar } from 'notistack';

export const useGenerateOTP = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({
      link,
      source,
      includeInLink,
    }: {
      link: string;
      source?: any;
      includeInLink?: IOtpIncludeLink;
    }) => generateOTP(link, source || '', includeInLink),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useCheckOTPExists = () =>
  useMutation(({ link }: { link: string }) => checkIfOTPExists(link));

export const useDecodeOTP = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation((code: string) => decodeOTP({ code }), {
    onError: (err: IError) => {
      enqueueSnackbar(err.message, {
        variant: 'error',
      });
    },
  });
};

export const useVerifyOTP = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation((data: { otp: string; code: string }) => verifyOTP(data), {
    onSuccess: (res) => {
      enqueueSnackbar(res.message, {
        variant: 'success',
      });
    },
    onError: (err: IError) => {
      enqueueSnackbar(err.message, {
        variant: 'error',
      });
    },
  });
};

export const useVerifyOTPV1 = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (data: { otp: string; code: string; user: IEmailUserInfo }) =>
      verifyOTPV1(data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};
