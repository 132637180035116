import { IOfferings } from 'interfaces/common';
import { IListResponse, IResponse } from 'interfaces/http';
import {
  IAddStatusForm,
  ICreateStatusSchema,
  IFetchStatusRow,
  IUpdateStatusSchema,
} from 'interfaces/status';
import { formatDateView } from 'utils/moment';

export const formatStatusSavePayload = (
  data: IAddStatusForm,
  modules: string
): ICreateStatusSchema => ({
  code: data?.statusCode,
  name: data?.title,
  module: modules,
  description: data?.description,
});
export const formatStatusEditPayload = (
  data: IAddStatusForm
): IUpdateStatusSchema => ({
  code: data?.statusCode,
  name: data?.title,
  description: data?.description,
});

export const adaptedStatusList = (
  res: IResponse<IListResponse<IFetchStatusRow>>
): IResponse<IListResponse<IFetchStatusRow>> => ({
  ...res,
  data: {
    ...res.data,
    rows: res?.data?.rows
      ?.map((data) => ({
        ...data,
        created: {
          ...data.created,
          at: formatDateView(data?.created?.at ?? ''),
        },
      }))
      .sort((a, b) => {
        // Preprocess strings to remove special characters and empty spaces
        const nameA = a.name
          .replace(/[^\w\s]/gi, '')
          .toLowerCase()
          .trim();
        const nameB = b.name
          .replace(/[^\w\s]/gi, '')
          .toLowerCase()
          .trim();
        return nameA.localeCompare(nameB);
      }),
  },
});

export const getStatusName = (status: IOfferings[], value: string) => {
  const data = status?.find((item) => item?.value === value);
  return data?.label;
};
