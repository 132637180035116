import { useEffect, useRef, useState } from 'react';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CaseCode, CaseOptions } from 'enums/tenant-management/tenant';
import { useResetCaseNote } from 'features/case-layout-new/hooks/useResetCaseNote';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { checkCaseOptionType } from 'utils/tenantSetting';

import BloodDonors from './donor-matching';
import SelectedDonor from './donor-matching/SelectedDonor';
import PatientInformation from './PatientInformation';
import ProviderOptions from './provider-options';
import ReferralCaseTop from './ReferralCaseTop';
import ReferringProvider from './ReferringProvider';
import RequestedPanel from './requested-panel';
import SelectedProvider from './SelectedProvider';
import ServiceInformation from './ServiceInformation';

interface IReferralCaseProps {
  handleGoBack?: VoidFunction;
  handleRefresh: VoidFunction;
  openMobileCase?: boolean;
}

const ReferralCase = ({
  handleGoBack,
  handleRefresh,
  openMobileCase,
}: IReferralCaseProps) => {
  const theme = useTheme();

  const mdBreakpoint = useMediaQuery(theme.breakpoints.up('md'));
  const smBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const gridRef = useRef<any>();
  const [selectedProviderGrid, setSelectedProviderGrid] = useState('');

  const caseType = checkCaseOptionType(CaseCode.CASE_TYPE);
  const isUserClient = useCheckUserIsClient();

  useEffect(() => {
    const handleResize = () => {
      const documentElement = document.getElementById('selectProvider');
      const newWidth = documentElement ? documentElement.offsetWidth : 0;
      setSelectedProviderGrid(newWidth.toString());
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useResetCaseNote();

  return (
    <Box flexGrow={1} marginBottom={8}>
      {openMobileCase && (
        <Button
          onClick={handleGoBack}
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          sx={{
            marginLeft: 1,
            marginY: 1,
          }}
          variant="text"
        >
          Go Back
        </Button>
      )}
      <Stack padding={2} spacing={3}>
        <ReferralCaseTop handleRefresh={handleRefresh} />
        <RequestedPanel />
        <Grid container ref={gridRef}>
          <Grid item md={5} paddingRight={smBreakpoint ? 0 : 3} sm={6} xs={12}>
            <PatientInformation />
          </Grid>
          <Grid item md={7} xs={12}>
            <Stack {...(!mdBreakpoint && { paddingTop: 3 })}>
              <ServiceInformation />
              <Grid container spacing={3} sx={{ paddingY: 3 }}>
                <Grid item sm={6} xs={12}>
                  {caseType === CaseOptions.BLOOD_DONOR ? (
                    <SelectedDonor />
                  ) : (
                    <Box id="selectProvider">
                      <SelectedProvider width={selectedProviderGrid} />
                    </Box>
                  )}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <ReferringProvider />
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
        {caseType === CaseOptions.BLOOD_DONOR && !isUserClient && (
          <BloodDonors />
        )}
        {caseType === CaseOptions.MEDICAL && <ProviderOptions />}
        {/* TODO Show tables after API Implementation  */}
        {/* {caseType === CaseOptions.RX && ''}
        {caseType === CaseOptions.ADVOCACY && ''}
        {caseType === CaseOptions.SERVICE && ''} */}
      </Stack>
    </Box>
  );
};

ReferralCase.defaultProps = {
  handleGoBack: undefined,
  openMobileCase: false,
};
export default ReferralCase;
