import { useMemo } from 'react';

import { Box } from '@mui/material';
import NoRecordsFoundDisplay from 'common/display/NoRecordsFoundDisplay';
import LoadingIndicator from 'common/LoadingIndicator';
import { ManageMembershipVisibilityOptions } from 'common/manage-membership/common/ManageMembershipVisibilityOptions';
import { useGetCurrentSelectedClientAndMembershipDetails } from 'common/manage-membership/hooks/useGetCurrentSelectedClientAndMembershipDetails';
import { ManageMembershipStatus } from 'enums/client-management/manage-membership';
import { IAdaptedTenantMembershipOfferingTableRow } from 'interfaces/tenant-management/tenant/tenantMembershipOffering';
import {
  selectManageMembershipSelectedMembership,
  selectManageMembershipStatus,
  selectManageMembershipTypeGroupDetail,
  setManageMembershipSelectedOfferings,
} from 'stores/client-management/manage-membership';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { getCurrentDate } from 'utils/moment';

import { OptionsListItem } from '../../common/OptionsListItem';
import { useGetTenantOfferingsOfGroup } from '../../hooks/useGetTenantOfferingsOfGroup';
import { usePreSelectOffering } from './usePreSelectOffering';

export const GroupMembershipList = () => {
  const dispatch = useAppDispatch();

  const { data, isLoading } = useGetTenantOfferingsOfGroup();

  const { isClientTypeGroup } =
    useGetCurrentSelectedClientAndMembershipDetails();

  const manageMembershipStatus = useAppSelector(selectManageMembershipStatus);

  const manageMembershipTypeGroupDetail = useAppSelector(
    selectManageMembershipTypeGroupDetail
  );

  const { details } = useAppSelector(selectManageMembershipSelectedMembership);
  const isGroupMemberOrDependent = !!details?.primaryId || !!details?.groupId;

  const handleSelectedMemberOption = (
    item: IAdaptedTenantMembershipOfferingTableRow
  ) => {
    const originallySelectedOfferings = details?.offerings.id;
    const isSelectedOfferingSameAsOriginallySelectedOffering =
      originallySelectedOfferings === item?.tenantOfferingId;
    let startDate = getCurrentDate();

    if (
      isSelectedOfferingSameAsOriginallySelectedOffering &&
      !!details?.startDate
    ) {
      startDate = details.startDate;
    } else if (
      !isSelectedOfferingSameAsOriginallySelectedOffering &&
      isGroupMemberOrDependent &&
      !!item?.startDate
    ) {
      startDate = item.startDate;
    }

    dispatch(
      setManageMembershipSelectedOfferings({
        selectedOffering: item,
        startDate,
      })
    );
  };

  const modifiedData = useMemo(() => {
    if (isClientTypeGroup) {
      let filteredData = [];
      if (manageMembershipStatus === ManageMembershipStatus.PRIVATE) {
        filteredData = data.filter(
          (e) => e.visibility === ManageMembershipStatus.PRIVATE
        );
      } else {
        filteredData = data.filter(
          (e) => e.visibility === ManageMembershipStatus.PUBLIC
        );
      }

      // return filterMembershipByEnrollment(
      //   filteredData,
      //   clientMemberships || []
      // );
      return filteredData;
    }
    return data;
  }, [data, isClientTypeGroup, manageMembershipStatus]);

  usePreSelectOffering({ data, enabled: true });

  if (isLoading && !!manageMembershipTypeGroupDetail)
    return <LoadingIndicator />;

  return (
    <Box>
      {!isLoading && data?.length > 0 && !!manageMembershipTypeGroupDetail && (
        <>
          {isClientTypeGroup && (
            <Box paddingBottom={1} paddingTop={0}>
              <ManageMembershipVisibilityOptions />
            </Box>
          )}
          {modifiedData.length ? (
            <Box
              className="scrollbar"
              marginTop={2}
              marginX={0.5}
              maxHeight="25vh"
              sx={{ overflowY: 'auto' }}
            >
              {modifiedData?.map((item) => (
                <OptionsListItem
                  handleSelectedMemberOption={handleSelectedMemberOption}
                  item={item}
                />
              ))}
            </Box>
          ) : (
            <Box>
              <NoRecordsFoundDisplay />
            </Box>
          )}
        </>
      )}
      {!isLoading && data?.length === 0 && !!manageMembershipTypeGroupDetail && (
        <Box>
          <NoRecordsFoundDisplay />
        </Box>
      )}
    </Box>
  );
};
