import { useMemo } from 'react';

import { TenantSettingsType } from 'enums/tenant-management/tenant';
import { selectTenantSettings } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

export const useGetEnrollmentTenantSettings = (
  code: string,
  metadata?: {
    getAllFields?: boolean; // sends entire fields present in the setting
  }
) => {
  const tenantSettings = useAppSelector(selectTenantSettings);

  const settingStatus = useMemo(() => {
    const codeSettings = tenantSettings?.enrollment?.find(
      (item) => item.code?.toUpperCase() === code?.toUpperCase()
    );
    if (!codeSettings) return null;

    if (metadata?.getAllFields) {
      return codeSettings;
    }

    if (codeSettings.type === TenantSettingsType.MULTISELECT) {
      return codeSettings.status.split(',').map((item) => item.trim());
    }
    return codeSettings.status;
  }, [code, tenantSettings, metadata]);

  return settingStatus;
};
