import React from 'react';

import { Box, Skeleton } from '@mui/material';

import ReferralCaseListItemLayout from '../layout/ReferralCaseListItemLayout';

const ReferralCaseListSkeleton = () => (
  <Box height="100vh">
    {Array(5)
      .fill(0)
      .map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <ReferralCaseListItemLayout key={i}>
          <Skeleton />
          <Skeleton height={18} width="80%" />
          <Skeleton height={18} width="60%" />
          <Skeleton height={18} width="60%" />
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <Skeleton height={18} width="30%" />
            <Skeleton height={18} width="30%" />
          </Box>
        </ReferralCaseListItemLayout>
      ))}
  </Box>
);

export default ReferralCaseListSkeleton;
