import { faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, SxProps, Tooltip } from '@mui/material';
import { transactionAlertMap } from 'constants/client';
import { TransactionAlertStatus } from 'enums/billings';
import { formatDateView } from 'utils/moment';

interface ITransactionStatusIndicatorProps {
  transactionDetail: { status: TransactionAlertStatus; date: string };
  containerSx?: SxProps;
}

export const TransactionStatusIndicator = ({
  transactionDetail,
  containerSx,
}: ITransactionStatusIndicatorProps) => {
  const mssgTitle = `${
    transactionAlertMap[transactionDetail?.status].text
  } on ${formatDateView(transactionDetail.date || '')}`;

  return (
    <Tooltip arrow placement="top" title={mssgTitle}>
      <span>
        <Box
          color={transactionAlertMap[transactionDetail?.status].color}
          component={FontAwesomeIcon}
          icon={faMoneyBillTransfer}
          sx={{ ...containerSx }}
        />
      </span>
    </Tooltip>
  );
};

TransactionStatusIndicator.defaultProps = {
  containerSx: undefined,
};
