import { Box, SelectChangeEvent } from '@mui/material';
import { ITenantSettingItem } from 'interfaces/tenant-management/tenant';
import { useCaseConfigurationMutation } from 'services/cases';
import {
  selectAuthTenantAssociation,
  selectTenantSettings,
  setTenantSettings,
} from 'stores/auth';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { formatCaseConfigurableSettingsPayload } from 'utils/cases';

import { CaseConfigurationRow } from './CaseConfigurationRow';

interface IProp {
  settingFields: ITenantSettingItem[];
}

export const CaseConfigurationForm = ({ settingFields }: IProp) => {
  const tenantId = useAppSelector(selectAuthTenantAssociation)?.tenantId || '';
  const settingsData = useAppSelector(selectTenantSettings);
  const caseConfigurationMutation = useCaseConfigurationMutation({
    showToasterMessageFromHover: true,
  });
  const dispatch = useAppDispatch();

  const handleDropDownChange = (
    event: SelectChangeEvent<string | number | string[]>,
    item: ITenantSettingItem
  ) => {
    const selectedCaseType = event.target.value;

    if (settingsData) {
      const updatedData = {
        ...item,
        value: selectedCaseType,
      };
      const payload = formatCaseConfigurableSettingsPayload(updatedData);
      const originalState = [...settingsData.cases];
      const shallowCopy = [...settingsData.cases];
      const index = shallowCopy.findIndex((i) => i.code === item.code);
      shallowCopy[index] = {
        ...shallowCopy[index],
        value: selectedCaseType,
      };
      dispatch(
        setTenantSettings({
          ...settingsData,
          cases: shallowCopy,
        })
      );
      caseConfigurationMutation.mutate(
        { tenantId, data: payload },
        {
          onError() {
            dispatch(
              setTenantSettings({
                ...settingsData,
                cases: originalState,
              })
            );
          },
        }
      );
    }
  };

  return (
    <Box>
      {settingFields.map((item) => (
        <CaseConfigurationRow
          data={item}
          key={item.code}
          onChange={handleDropDownChange}
        />
      ))}
    </Box>
  );
};
