import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as statusApi from 'api/status';
import { EStatusModule } from 'enums/case';
import { IError } from 'interfaces/http';
import {
  ICreateStatusSchema,
  IFetchStatusRow,
  IUpdateStatusSchema,
} from 'interfaces/status';
import { useSnackbar } from 'notistack';
import { adaptedStatusList } from 'utils/status';

export const statusKeys = {
  all: ['status'] as const,
  lists: () => [...statusKeys.all, 'list'] as const,
  list: () => [...statusKeys.lists()] as const,
  details: () => [...statusKeys.all, 'detail'] as const,
  detail: (id: string) => [...statusKeys.details(), id] as const,
};

export const statusCountkey = {
  all: ['status-count'] as const,
  count: () => [...statusCountkey.all, 'count'] as const,
};

export const useFetchStatusQuery = (moduleName: EStatusModule) => {
  const queryInfo = useQuery(
    statusKeys.lists(),
    () => statusApi.fetchStatus(moduleName),
    {
      select: adaptedStatusList,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useFetchStatusCountQuery = (
  id: string,
  modules: EStatusModule,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    statusCountkey.count(),
    () => statusApi.fetchStatusCount(id, modules),
    {
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo?.data?.data?.count,
  };
};

export const useAddStatusMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(
    ({ data }: { data: ICreateStatusSchema }) => statusApi.createStatus(data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
        const queryKey = statusKeys?.lists();
        const queryData = queryClient.getQueryData(queryKey);
        if (!queryData) return;
        queryClient.setQueryData(queryKey, (oldData: any) => ({
          ...oldData,
          data: {
            ...oldData.data,
            count: oldData.data.count + 1,
            rows: [res.data, ...oldData.data.rows],
          },
        }));
      },
      onError: (error: IError) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useDeleteStatusMutation = (id: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(
    ({
      statusId,
      newStatusId,
      modules,
    }: {
      statusId: string;
      newStatusId: string | null;
      modules: EStatusModule;
    }) => statusApi.deleteStatus(statusId, newStatusId, modules),
    {
      onSuccess: (res) => {
        const queryKey = statusKeys.lists();
        const queryData = queryClient.getQueryData(queryKey);
        if (!queryData) return;
        queryClient.setQueryData(queryKey, (oldData: any) => ({
          ...oldData,
          data: {
            ...oldData.data,
            count: oldData.data.count - 1,
            rows: oldData.data.rows.filter(
              (item: IFetchStatusRow) => item?.statusId !== id
            ),
          },
        }));
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (error: IError) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useStatusDetailQuery = (
  statusId: string,
  modules: EStatusModule,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    statusKeys.detail(statusId),
    () => statusApi.getStatusDetail(statusId, modules),
    {
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useEditStatusMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ({
      data,
      id,
      modules,
    }: {
      data: IUpdateStatusSchema;
      id: string;
      modules: EStatusModule;
    }) => statusApi.updateStatus(data, id, modules),
    {
      onSuccess: (res) => {
        const queryKey = statusKeys.lists();
        const queryData = queryClient.getQueryData(queryKey);
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
        if (!queryData) return;
        queryClient.setQueriesData(queryKey, (oldData: any) => ({
          ...oldData,
          data: {
            ...oldData.data,
            rows: oldData.data.rows.map((item: IFetchStatusRow) => {
              if (item?.code === res?.data?.code) {
                return { ...item, ...res?.data };
              }
              return item;
            }),
          },
        }));
      },
      onError: (error: IError) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    }
  );
};
