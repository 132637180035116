import apiRoutes from 'constants/apiRoutes';
import { IResponse } from 'interfaces/http';
import {
  IThirdPartyIntegration,
  IThirdPartyIntegrationPayload,
} from 'interfaces/misc';
import { baseRequest } from 'utils/axios';

// api/tenants/7EB541E4-91A9-4DEB-BB7E-55813D3CA140/integrations
const {
  tenantManagement: {
    tenant: { thirdPartyIntegrations },
  },
} = apiRoutes;
export const getThirdPartyIntegration = async (
  id: string
): Promise<IResponse<IThirdPartyIntegration[]>> => {
  const { error, response } = await baseRequest({
    method: 'GET',
    url: thirdPartyIntegrations.get.replace(':id', id),
  });
  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};
export const enableThirdPartyIntegration = async (
  id: string,
  data: IThirdPartyIntegrationPayload[]
): Promise<IResponse<IThirdPartyIntegration[]>> => {
  const { error, response } = await baseRequest({
    method: 'PUT',
    url: thirdPartyIntegrations.update.replace(':id', id),
    data,
  });
  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};
