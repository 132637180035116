import { GlobalStyles as GlobalThemeStyles, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import themeVars from 'assets/scss/_theme-vars.module.scss';

export const customScrollbar = (theme: Theme) => ({
  // Custom scrollbar
  '::-webkit-scrollbar': {
    width: '4px',
    height: '4px',
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.main,
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#888',
    borderRadius: '10px',
    border: '3px solid #f1f1f1',
  },
});

const GlobalStyles = () => {
  const theme = useTheme();

  return (
    <GlobalThemeStyles
      styles={{
        ':root': {
          // Primary and Secondary colors are kept in CSS variables
          // because they need to be dynamically set based on tenant
          '--color-primary-lighter': theme.palette.primary.lighter,
          '--color-primary-light': theme.palette.primary.light,
          '--color-primary-main': theme.palette.primary.main,
          '--color-primary-dark': theme.palette.primary.dark,
          '--color-primary-darker': theme.palette.primary.darker,

          '--color-secondary-lighter': theme.palette.secondary.lighter,
          '--color-secondary-light': theme.palette.secondary.light,
          '--color-secondary-main': theme.palette.secondary.main,
          '--color-secondary-dark': theme.palette.secondary.dark,
          '--color-secondary-darker': theme.palette.secondary.darker,

          '--color-gray-lighter': theme.palette.gray.lighter,
          '--color-gray-light': theme.palette.gray.light,
          '--color-gray-main': theme.palette.gray.main,
          '--color-gray-dark': theme.palette.gray.dark,
          '--color-gray-darker': theme.palette.secondary.darker,
          // -----------------------------------------------------
          // Custom scrollbar
          ...customScrollbar(theme),
        },

        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
          fontFamily: 'Heebo, sans-serif',
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          width: '100%',
          height: '100%',
          color: themeVars.textDark,
          scrollbarGutter: 'stable both-edges',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        '.MuiBox-root': {
          // -----------------------------------------------------
          // Custom scrollbar
          ...customScrollbar(theme),
        },
        // puts the google places autocomplete dropdown results above the bootstrap modal 1050 zindex.
        '.pac-container': {
          zIndex: '1500 !important',
        },
        // contains custom CSS for react slick
        '.react-slick': {
          '.slick-slider': {
            /**
             * Allows user to select/highlight the slider content
             */
            '-webkit-user-select': 'text',
            '-moz-user-select': 'text',
            '-ms-user-select': 'text',
            'user-select': 'text',
          },

          '.slick-custom-next': {
            cursor: 'pointer',
            position: 'absolute',
            top: '50%',
            right: '-26px',
            transform: 'translate(-50%,0)',
            zIndex: 1,
          },

          '.slick-custom-prev': {
            cursor: 'pointer',
            position: 'absolute',
            top: '50%',
            left: '0px',
            transform: 'translate(-50%,0)',
            zIndex: 1,
          },

          '.slick-custom-dots': {
            // marginBottom: '-25px',
            li: {
              margin: 0,
            },

            '.slick-active': {
              div: {
                backgroundColor: theme.palette.primary.main,
              },
            },
          },
        },
        // custom notistack (Snackbar) variant colors
        '.notistack-Snackbar': {
          '.notistack-MuiContent-success': {
            backgroundColor: theme.palette.success.dark,
            color: theme.palette.success.lighter,
          },
          '.notistack-MuiContent-error': {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.error.lighter,
          },
          '.notistack-MuiContent-info': {
            backgroundColor: theme.palette.info.dark,
            color: theme.palette.info.lighter,
          },
          '.notistack-MuiContent-warning': {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.gray.darker,
          },
          '.notistack-MuiContent-default': {
            backgroundColor: theme.palette.gray.dark,
            color: theme.palette.gray.lighter,
          },
        },
      }}
    />
  );
};

export default GlobalStyles;
