import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import * as documentAPI from 'api/documents';
import commonConstants from 'constants/common';
import toastMessageConstant from 'constants/toastMessage';
import ModalKey from 'enums/ModalKey';
import {
  IAttachDocumentPayload,
  IDocument,
  IDocumentFilter,
} from 'interfaces/documents';
import { IError } from 'interfaces/http';
import { IModalSharePayload } from 'interfaces/share-modal';
import { useSnackbar } from 'notistack';
import { RootState, store } from 'stores';

export const documentCasesKeys = {
  all: ['documents'] as const,
  lists: () => [...documentCasesKeys.all, 'list'] as const,
  list: (filters: IDocumentFilter) =>
    [...documentCasesKeys.lists(), { filters }] as const,
  details: () => [...documentCasesKeys.all, 'detail'] as const,
  detail: (id: number | string) =>
    [...documentCasesKeys.details(), id] as const,
  getESignEditLink: (id: string) => ['e-sign', 'edit', id] as const,
};

export const useDocumentListQuery = (
  filters: IDocumentFilter,
  { enabled }: { enabled: boolean }
): any => {
  // TODO: Remove any. Return of documentAPI.getDocuments should be typed.
  const queryInfo = useQuery(
    documentCasesKeys.list(filters),
    () => documentAPI.getDocuments(filters),
    {
      enabled,
    }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useDocumentQuery = (
  documentId: string,
  { enabled, associationId }: { enabled: boolean; associationId?: string }
) => {
  const queryInfo = useQuery(
    documentCasesKeys.detail(documentId),
    () => documentAPI.getDocument(documentId, associationId),
    {
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useAddDocumentMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');
  const type = searchParams.get('type');

  const rootState: RootState = store.getState();
  const documentsStore = rootState.documents;
  const documentsFilter = documentsStore.filters;
  const documentsSort = documentsStore.sort;

  return useMutation(
    ({ data }: { data: any }) => documentAPI.addDocuments(data),
    {
      onSuccess: (res, context) => {
        const queryKey = documentCasesKeys.list({
          associationId: context.data.associationId,
          ...(context.data.tenantId && {
            ...documentsFilter,
            ...documentsSort,
            tenantId: context.data.tenantId,
            listView: true,
          }),
        });
        const queryData = queryClient.getQueryData(queryKey);

        if (queryData) {
          queryClient.setQueryData(queryKey, (oldData: any) => {
            if (context.data.tenantId) {
              return {
                ...oldData,
                data: {
                  ...oldData.data,
                  rows: [...res.data, ...oldData.data.rows],
                },
              };
            }
            return {
              ...oldData,
              data: [...res.data, ...oldData.data],
            };
          });
        }
        const toastMessage =
          caseId || type === ModalKey.ADD_CLIENT
            ? toastMessageConstant.CASES.FILE_UPLOADED
            : toastMessageConstant.DOCUMENT.DOCUMENT_UPLOADED;

        enqueueSnackbar(toastMessage, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useDeleteDocumentMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const documentsStore = rootState.documents;
  const documentsFilter = documentsStore.filters;
  const documentsSort = documentsStore.sort;

  return useMutation(
    ({
      documentId,
      associationId,
    }: {
      documentId: string;
      associationId: string;
      tenantId?: string;
      type?: string;
    }) => documentAPI.deleteDocument(documentId, associationId),
    {
      onSuccess: (res, context) => {
        queryClient.setQueryData(
          documentCasesKeys.list({
            associationId: context.associationId || null,
            ...(context.tenantId && {
              ...documentsFilter,
              ...documentsSort,
              tenantId: context.tenantId,
              listView: true,
            }),
          }),
          (oldData: any) => {
            if (context.tenantId) {
              return {
                ...oldData,
                data: {
                  ...oldData.data,
                  rows: oldData.data.rows.filter(
                    (e: IDocument) => e.documentId !== res.data.documentId
                  ),
                },
              };
            }
            return {
              ...oldData,
              data: oldData.data.filter(
                (e: IDocument) => e.documentId !== res.data.documentId
              ),
            };
          }
        );

        const getMessage = (message: string) => {
          if (
            commonConstants.REFERRAL_CASE === context.type ||
            commonConstants.CLIENT === context.type
          )
            return toastMessageConstant.CASES.FILE_DELETED;
          if (message) return message;
          return toastMessageConstant.DOCUMENT.DOCUMENT_DELETED;
        };

        enqueueSnackbar(getMessage(res.message), {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useAttachDocumentMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');
  const type = searchParams.get('type');
  return useMutation(
    ({
      data,
    }: {
      data: IAttachDocumentPayload[];
      documents: IDocument[];
      associationId: string;
    }) => documentAPI.attachDocuments(data),
    {
      onSuccess: (res, context) => {
        const { documents } = context;

        const updatedDocuments = documents?.map((e) => {
          const index = res.data?.findIndex(
            (obj) => obj.documentId === e.documentId
          );

          if (index === -1) {
            return e;
          }
          return {
            ...e,
            associations: [res.data[index], ...e.associations],
          };
        });

        const queryKey = documentCasesKeys.list({
          associationId: context.associationId,
        });

        const queryData = queryClient.getQueryData(queryKey);

        if (queryData) {
          queryClient.setQueryData(queryKey, (oldData: any) => ({
            ...oldData,
            data: [...updatedDocuments, ...oldData.data],
          }));
        }

        const toastMessage =
          caseId || type === ModalKey.ADD_CLIENT
            ? toastMessageConstant.CASES.FILE_ATTACHED
            : res.message;

        enqueueSnackbar(toastMessage, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useAddDocumentTagsMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const documentsStore = rootState.documents;
  const documentsFilter = documentsStore.filters;
  const documentsSort = documentsStore.sort;

  return useMutation(
    ({
      documentId,
      data,
    }: {
      documentId: string;
      associationId: string;
      data: { tags: string[] };
    }) => documentAPI.addTags(documentId, data),
    {
      onSuccess: (res, context) => {
        queryClient.setQueryData(
          documentCasesKeys.list({
            associationId: context.associationId || null,
            ...(context.associationId && {
              ...documentsFilter,
              ...documentsSort,
              tenantId: context.associationId,
              listView: true,
            }),
          }),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              rows: oldData.data.rows.map((x: IDocument) => {
                if (x.documentId === res.data.documentId) {
                  return {
                    ...x,
                    tags: res.data.tags,
                    updatedBy: res.data.updatedBy,
                    updatedAt: res.data.updatedAt,
                  };
                }
                return x;
              }),
            },
          })
        );

        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useRenameDocumentMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');
  const type = searchParams.get('type');

  const rootState: RootState = store.getState();
  const documentsStore = rootState.documents;
  const documentsFilter = documentsStore.filters;
  const documentsSort = documentsStore.sort;

  return useMutation(
    ({
      documentId,
      data,
    }: {
      documentId: string;
      associationId: string;
      data: { name: string };
      tenantId?: string;
      type?: string;
      isNotListView?: boolean;
    }) => documentAPI.renameDocument(documentId, data),
    {
      onSuccess: (res, context) => {
        const query = {
          associationId: context.associationId || null,
          ...(context.associationId && {
            ...(!context.isNotListView && {
              ...documentsFilter,
              ...documentsSort,
              tenantId: context.associationId,
              listView: true,
            }),
          }),
        };
        queryClient.setQueryData(
          documentCasesKeys.list(query),
          (oldData: any) => {
            if (!context.isNotListView) {
              return {
                ...oldData,
                data: {
                  ...oldData.data,
                  rows: oldData.data.rows.map((x: IDocument) => {
                    if (x.documentId === res.data.documentId) {
                      return {
                        ...x,
                        name: res.data.name,
                        updatedBy: res.data.updatedBy,
                        updatedAt: res.data.updatedAt,
                      };
                    }
                    return x;
                  }),
                },
              };
            }
            return {
              ...oldData,
              data: oldData.data.map((x: IDocument) => {
                if (x.documentId === res.data.documentId) {
                  return {
                    ...x,
                    name: res.data.name,
                    updatedBy: res.data.updatedBy,
                    updatedAt: res.data.updatedAt,
                  };
                }
                return x;
              }),
            };
          }
        );

        const toastMessage =
          caseId || type === ModalKey.ADD_CLIENT
            ? toastMessageConstant.CASES.FILE_RENAMED
            : toastMessageConstant.DOCUMENT.DOCUMENT_RENAMED;
        enqueueSnackbar(toastMessage, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useShareDocumentMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({ documentId, data }: { documentId: string; data: IModalSharePayload }) =>
      documentAPI.shareDocuments(documentId, data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(toastMessageConstant.CASES.FILE_SHARED ?? res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useConvertToESignMutation = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    ({ documentId, name }: { documentId: string; name: string }) =>
      documentAPI.convertToESign(documentId, { name }),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useGetESignEditLinkQuery = (
  documentId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    documentCasesKeys.getESignEditLink(documentId),
    () => documentAPI.getESignEditLink(documentId),
    {
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};
