import { useEffect } from 'react';

import { TenantOfferingCategoryFull } from 'enums/settings/tenant-offerings';
import {
  selectManageMembershipSelectedMembership,
  setManageMembershipSelectedOfferings,
} from 'stores/client-management/manage-membership';
import { useAppDispatch, useAppSelector } from 'stores/hooks';

import { useCheckFetchingOfferingsEnabled } from './useCheckFetchingOfferingsEnabled';

export const useEffectChangeMembershipDetailsForGroup = () => {
  const dispatch = useAppDispatch();
  const { isGroupOfferingEnabled } = useCheckFetchingOfferingsEnabled();

  const currentMembershipSelectedData = useAppSelector(
    selectManageMembershipSelectedMembership
  );

  const { selectedOffering } = currentMembershipSelectedData;
  useEffect(() => {
    if (
      selectedOffering?.category === TenantOfferingCategoryFull.INDIVIDUAL &&
      isGroupOfferingEnabled
    ) {
      dispatch(
        setManageMembershipSelectedOfferings({ selectedOffering: null })
      );
    }
  }, [dispatch, isGroupOfferingEnabled, selectedOffering?.category]);
};
