import { useEffect } from 'react';

import { useClientEnrollmentMembershipQuery } from 'services/client-management/enrollment';
import {
  changeCasePatientMembership,
  selectCurrentReferralCase,
} from 'stores/cases';
import { useAppDispatch, useAppSelector } from 'stores/hooks';

export const useCancelledMembershipDetails = () => {
  const dispatch = useAppDispatch();
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const patientId = currentReferralCase?.patient?.id;
  const primaryId = currentReferralCase?.patient?.relationship?.groupId || '';
  const isPatientOwnCase = patientId === primaryId;
  const {
    data: clientEnrollmentMembershipData,
    isLoading: clientEnrollmentMembershipLoading,
  } = useClientEnrollmentMembershipQuery(patientId!, {
    enabled: !!patientId && !isPatientOwnCase,
  });
  useEffect(() => {
    if (
      clientEnrollmentMembershipData?.rows.length &&
      !clientEnrollmentMembershipLoading
    ) {
      dispatch(
        changeCasePatientMembership(clientEnrollmentMembershipData?.rows)
      );
    }
  }, [
    clientEnrollmentMembershipData?.rows,
    clientEnrollmentMembershipLoading,
    dispatch,
  ]);
};
