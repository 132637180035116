import React from 'react';

import {
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableCell, TableRow } from '@mui/material';
import { AddressDisplay, ListDisplay } from 'common';
import { IReferralCaseProviderOption } from 'interfaces/cases';
import { formatCurrency, formatPhone } from 'utils/common';

import { mapOldAddressImplementation } from '../common';
import ProviderOptionMenu from './ProviderOptionMenu';

const verticalAlign = { verticalAlign: 'top' };
const ProviderOptionRow = ({ data }: { data: IReferralCaseProviderOption }) => {
  const address = data.address && mapOldAddressImplementation(data.address);

  return (
    <TableRow className="row" key={data.id}>
      <TableCell component="th" scope="data" sx={verticalAlign}>
        {data?.name}
      </TableCell>
      <TableCell align="left" sx={verticalAlign}>
        <ListDisplay
          list={[
            {
              icon: <FontAwesomeIcon icon={faMapMarkerAlt} />,
              text: <AddressDisplay address={address} />,
            },
          ]}
        />
      </TableCell>
      <TableCell align="left" sx={verticalAlign}>
        {data?.email || data?.phone ? (
          <ListDisplay
            list={[
              {
                icon: <FontAwesomeIcon icon={faEnvelope} />,
                text: data.email,
              },
              {
                icon: <FontAwesomeIcon icon={faPhone} />,
                text: formatPhone(data.phone || ''),
              },
            ]}
          />
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell sx={verticalAlign}>
        {data?.cost ? formatCurrency(data?.cost) : 'N/A'}
      </TableCell>
      <TableCell align="right">
        <ProviderOptionMenu data={data} />
      </TableCell>
    </TableRow>
  );
};

export default ProviderOptionRow;
