import { MenuItem, MenuListProps, SelectProps, TextField } from '@mui/material';
import DatePicker from 'common/DatePicker';
import { ClientEnrollmentStatus } from 'enums/client-management';
import { IMembershipDateProps } from 'interfaces/client-management/manage-membership';
import { useUpdateClientEnrollmentMutation } from 'services/client-management/enrollment';
import { formatDateView } from 'utils/moment';

import { useGetCurrentSelectedClientAndMembershipDetails } from '../hooks/useGetCurrentSelectedClientAndMembershipDetails';
import { useGetEndDateOptions } from '../hooks/useGetEndDateOptions';
import { useHandleInlineDateChange } from '../hooks/useHandleInlineDateChange';
import { EditableDetailLayout } from './EditableDetailLayout';

export const ChangeCancelledMembershipEditableDate = ({
  setIsEditing,
  setDate,
  date,
}: IMembershipDateProps) => {
  const { handleCancel, handleDate, handleSave } = useHandleInlineDateChange({
    setIsEditing,
    setDate,
  });
  const endDateOptions = useGetEndDateOptions();

  const {
    currentSelectedEndDate,
    isSelectedMembershipBenefitStartDateInFuture,
  } = useGetCurrentSelectedClientAndMembershipDetails();

  const updateClientEnrollmentMutation = useUpdateClientEnrollmentMutation();

  const handleSaveAction = () => {
    handleSave({
      status: ClientEnrollmentStatus.CANCELLED,
      endDate: '',
      ...(isSelectedMembershipBenefitStartDateInFuture && {
        startDate: currentSelectedEndDate || '',
        endDate: '',
      }),
      ...(!isSelectedMembershipBenefitStartDateInFuture && {
        endDate: currentSelectedEndDate || '',
      }),
    });
  };

  return (
    <EditableDetailLayout
      handleCancel={handleCancel}
      handleSave={handleSaveAction}
      isLoading={updateClientEnrollmentMutation.isLoading}
      isSaveDisabled={false}
    >
      {endDateOptions.length > 0 ? (
        <TextField
          data-cy="client-membership-dropdown-input"
          // InputProps={{
          //   ...(currentSelectedEndDate && {
          //     endAdornment: (
          //       <InputAdornment position="end" style={{ marginLeft: '0.5rem' }}>
          //         <IconButton
          //           aria-label="clear end date"
          //           edge="end"
          //           onClick={clearEndDate}
          //           style={{ marginLeft: '-2.6rem' }}
          //         >
          //           <FontAwesomeIcon fontSize={16} icon={faClose} />
          //         </IconButton>
          //       </InputAdornment>
          //     ),
          //   }),
          // }}
          onChange={(e) => handleDate({ endDate: e.target.value })}
          select
          SelectProps={
            {
              'data-cy': 'client-membership-before',
              MenuProps: {
                MenuListProps: {
                  'data-cy': `client-membership-cancel-container`,
                } as Partial<MenuListProps>,
              },
            } as Partial<SelectProps<unknown>>
          }
          size="small"
          sx={{ width: 120 }}
          value={currentSelectedEndDate}
          variant="standard"
          //   onBlur={handleSave} // Save on blur
        >
          {endDateOptions.map((x: any) => (
            <MenuItem value={x.value}>{x.label}</MenuItem>
          ))}
        </TextField>
      ) : (
        <DatePicker
          inputProps={{
            variant: 'standard',
            'data-cy': `client-membership-cancel-date-input`,
          }}
          label="End Date"
          onChange={(d) =>
            handleDate({ endDate: formatDateView(d as string) } || '')
          }
          value={date}
        />
      )}
    </EditableDetailLayout>
  );
};
