/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddDependentActionType } from 'enums/client-management/add-dependent';
import { IAdaptedClient } from 'interfaces/client-management';
import { IAddDependentState } from 'interfaces/client-management/add-dependent';
import { IAdaptedClientEnrollmentMembership } from 'interfaces/client-management/enrollment';
import { IAdaptedTenantMembershipOfferings } from 'interfaces/tenant-management/tenant/tenantMembershipOffering';
import { RootState } from 'stores';
import { getCurrentDate } from 'utils/moment';

const initialState: IAddDependentState = {
  actionType: AddDependentActionType.SEARCH,
  primaryData: null,
  searchedKeyword: '',
  dependentDetail: null,
  selectedSearchedClient: null,
  membership: null,
  selectedOffering: null,
  selectedStartDate: getCurrentDate(),
  invalidSelectedDate: false,
};

export const slice = createSlice({
  name: 'addDependent',
  initialState,
  reducers: {
    setAddDependentActionType: (
      state,
      action: PayloadAction<AddDependentActionType | null>
    ) => {
      state.actionType = action.payload;
    },
    setAddDependentSearchedKeyword: (state, action: PayloadAction<string>) => {
      state.searchedKeyword = action.payload;
    },
    setAddDependentSearchedClientDetail: (
      state,
      action: PayloadAction<IAdaptedClient | null>
    ) => {
      state.selectedSearchedClient = action.payload;
    },
    setAddDependentDetail: (
      state,
      action: PayloadAction<IAdaptedClient | null>
    ) => {
      state.dependentDetail = action.payload;
    },
    setAddDependentPrimaryDetail: (
      state,
      action: PayloadAction<IAdaptedClient | null>
    ) => {
      state.primaryData = action.payload;
    },
    setAddDependentMembership: (
      state,
      action: PayloadAction<IAdaptedClientEnrollmentMembership | null>
    ) => {
      state.membership = action.payload;
    },
    setAddDependentSelectedOffering: (
      state,
      action: PayloadAction<IAdaptedTenantMembershipOfferings | null>
    ) => {
      state.selectedOffering = action.payload;
    },
    setAddDependentSelectedStartDate: (
      state,
      action: PayloadAction<string>
    ) => {
      state.selectedStartDate = action.payload;
    },
    setAddDependentInvalidSelectedDate: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.invalidSelectedDate = action.payload;
    },

    resetAddDependent: (state) => {
      state.actionType = initialState.actionType;
      state.searchedKeyword = initialState.searchedKeyword;
      state.selectedSearchedClient = initialState.selectedSearchedClient;
      state.dependentDetail = initialState.dependentDetail;
      state.primaryData = initialState.primaryData;
      state.membership = initialState.membership;
      state.selectedOffering = initialState.selectedOffering;
      state.selectedStartDate = initialState.selectedStartDate;
      state.invalidSelectedDate = initialState.invalidSelectedDate;
    },
  },
});

// Actions
export const {
  setAddDependentInvalidSelectedDate,
  setAddDependentSelectedStartDate,
  setAddDependentSelectedOffering,
  setAddDependentMembership,
  setAddDependentActionType,
  resetAddDependent,
  setAddDependentSearchedKeyword,
  setAddDependentDetail,
  setAddDependentPrimaryDetail,
  setAddDependentSearchedClientDetail,
} = slice.actions;

// Selectors
export const selectAddDependentActionType = (state: RootState) =>
  state.addDependent.actionType;
export const selectAddDependentSearchedKeyword = (state: RootState) =>
  state.addDependent.searchedKeyword;
export const selectAddDependentDetail = (state: RootState) =>
  state.addDependent.dependentDetail;
export const selectAddDependentPrimaryDetail = (state: RootState) =>
  state.addDependent.primaryData;
export const selectAddDependentMembership = (state: RootState) =>
  state.addDependent.membership;
export const selectAddDependentSelectedOffering = (state: RootState) =>
  state.addDependent.selectedOffering;
export const selectAddDependentSelectedSearchedClient = (state: RootState) =>
  state.addDependent.selectedSearchedClient;
export const selectAddDependentSelectedStartDate = (state: RootState) =>
  state.addDependent.selectedStartDate;
export const selectAddDependentInvalidSelectedDate = (state: RootState) =>
  state.addDependent.invalidSelectedDate;

// Reducer
export default slice.reducer;
