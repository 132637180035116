import { Box, Typography } from '@mui/material';
import { ReferenceType } from 'enums/common';
import { IDonorPatientInformation } from 'interfaces/cases';
import { selectCurrentReferralCase } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';

import { BloodDonorTable } from './BloodDonorTable';

export const BloodDonorDisplay = () => {
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);

  const data =
    currentReferralCase?.options?.filter(
      (e: IDonorPatientInformation) => e.type === ReferenceType.CLIENT
    ) || [];

  if (!data.length) {
    return (
      <Box>
        <Typography
          sx={{
            alignItems: 'center',
            padding: 3,
            textAlign: 'center',
            display: 'block',
          }}
          variant="caption"
        >
          Blood donors not available.
        </Typography>
      </Box>
    );
  }

  return <BloodDonorTable donors={data} />;
};
