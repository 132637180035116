import { selectAuthTenantAssociation } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { selectLangDict } from 'stores/language-dictionary';
import { getTextFromLangDict } from 'utils/languageDictionary';

export const useTextFromLanguageDictionary = ({ keys }: { keys: string[] }) => {
  const langDict = useAppSelector(selectLangDict);
  const authTenantAssociation = useAppSelector(selectAuthTenantAssociation);

  return keys.map((item) =>
    getTextFromLangDict(langDict?.dict, {
      key: item,
      tenantCode: authTenantAssociation?.code || '',
      groupCode: authTenantAssociation?.groupCode || '',
    })
  );
};
