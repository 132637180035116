import { useRef } from 'react';
import { useDispatch } from 'react-redux';

import { faMessage } from '@fortawesome/free-regular-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, TextField, useTheme } from '@mui/material';
import NoRecordsFoundDisplay from 'common/display/NoRecordsFoundDisplay';
import LoadingIndicator from 'common/LoadingIndicator';
import { ITenantUser } from 'interfaces/chat';
import {
  selectIsLoading,
  selectTeanantConversationList,
  setSearchTenant,
} from 'stores/chat';
import { useAppSelector } from 'stores/hooks';

import ChatListItem from './ChatListItem';

const ChatList = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const scrollRef: HTMLDivElement | any = useRef(null);
  const tenantList = useAppSelector(selectTeanantConversationList);
  const isLoading = useAppSelector(selectIsLoading);

  const onChangeSearch = async (text: string) => {
    dispatch(setSearchTenant(text));
  };

  if (isLoading)
    return (
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <LoadingIndicator />
      </Box>
    );

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      height="100%"
      overflow="auto"
      p={1}
    >
      <TextField
        fullWidth
        InputProps={{
          startAdornment: (
            <FontAwesomeIcon color={theme.palette.gray.main} icon={faSearch} />
          ),
        }}
        onChange={(e) => {
          onChangeSearch(e.target.value);
        }}
        placeholder="Search user"
        sx={{
          pb: theme.spacing(2),
          pt: theme.spacing(1),
        }}
      />
      {tenantList.length ? (
        <Box pb={10} ref={scrollRef}>
          {tenantList?.map((item: ITenantUser) => (
            <ChatListItem item={item} key={item.userId} />
          ))}
        </Box>
      ) : (
        <Box
          alignItems="center"
          display="flex"
          height="100%"
          justifyContent="center"
        >
          <NoRecordsFoundDisplay
            icon={faMessage}
            iconSize="4x"
            text="No users yet"
          />
        </Box>
      )}
    </Box>
  );
};

export default ChatList;
