import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Box, Grid, Stack, Typography } from '@mui/material';
import {
  FormAddressInput,
  FormCheckbox,
  FormGenderInput,
  FormMaskedDateInput,
  FormMaskedLast4SSNInput,
  FormMaskedPhoneInput,
  FormSelectTextField,
  FormTextfield,
} from 'common';
import FormPhoneCountryInput from 'common/form/FormPhoneCountryInput';
import FormSwitch from 'common/form/FormSwitch';
import FormTagInput from 'common/form/FormTagInput';
import { bloodGroupOptions } from 'constants/client';
import { DictionaryKeys } from 'constants/languageDictionary';
import { BloodGroup } from 'enums/client-management';
import {
  MiscellaneousCode,
  TenantSettingStatus,
} from 'enums/tenant-management/tenant';
import { useCheckFormFieldSettings } from 'hooks/useCheckEnabledFields';
import { useCheckUserIsAgent } from 'hooks/useCheckUserIsAgent';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { useGetMiscellaneousTenantSettings } from 'hooks/useGetMiscellaneousTenantSettings';
import { useTextFromLanguageDictionary } from 'hooks/useTextFromLanguageDictionary';
import 'react-phone-input-2/lib/style.css';
import { useGetAddClientSchema } from 'schema/client-management';

interface IProps {
  allDisabled?: boolean;
}

// Common form, used for PrimaryForm, GroupMemberForm and DependentForm
const IndividualForm = ({ allDisabled }: IProps): JSX.Element => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const phoneValue = watch('phone');

  const isUserAgent = useCheckUserIsAgent();
  const isUserClient = useCheckUserIsClient();
  const hideAddNoteField = isUserClient || isUserAgent;
  const bloodGroup = watch('bloodGroup');

  const [allowTextNotificationsLabel] = useTextFromLanguageDictionary({
    keys: [DictionaryKeys.ALLOW_TEXT_NOTIFICATIONS_CHECKBOX_LABEL],
  });

  const isInternationalAddressEnabled =
    useGetMiscellaneousTenantSettings(
      MiscellaneousCode.INTERNATIONAL_ADDRESS
    ) === TenantSettingStatus.ENABLED;

  const isPhoneValid =
    useGetAddClientSchema().fields.phone.isValidSync(phoneValue);
  const isClientLoggedIn = useCheckUserIsClient();

  const isBloodTypeVerifiedDisabled = useCallback(() => {
    if (!bloodGroup || bloodGroup === BloodGroup.UNK || isClientLoggedIn) {
      return true;
    }
    return false;
  }, [bloodGroup, isClientLoggedIn]);

  useEffect(() => {
    if (!isPhoneValid) {
      setValue('textEnabled', false);
    }
    if (isBloodTypeVerifiedDisabled()) {
      setValue('isBloodTypeVerified', false);
    }
  }, [isBloodTypeVerifiedDisabled, isPhoneValid, setValue]);

  const checkEnabledSettings = useCheckFormFieldSettings().enabled;
  const checkRequiredField = useCheckFormFieldSettings().required;
  const isVipSettingsEnabled =
    useGetMiscellaneousTenantSettings(MiscellaneousCode.VIP_CLIENT) ===
    TenantSettingStatus.ENABLED;
  const isBloodGroupRequired = checkRequiredField.bloodGroup;

  const checkReqiredSettings = useCheckFormFieldSettings().required;

  const isEmailRequiredFields = checkReqiredSettings.email;

  const renderedVIPCheckbox = isVipSettingsEnabled ? (
    <FormCheckbox label="VIP" name="isVip" />
  ) : null;

  return (
    <>
      <Typography color="gray.darker" fontWeight="medium" variant="body1">
        Personal Information
      </Typography>
      <Grid container mb={2} spacing={2}>
        <Grid item xs={4}>
          <FormTextfield
            disabled={allDisabled}
            inputProps={{
              'data-cy': 'clients-first-name',
            }}
            label="First Name"
            name="firstName"
            required
          />
        </Grid>
        <Grid item xs={4}>
          <FormTextfield
            disabled={allDisabled}
            inputProps={{
              'data-cy': 'clients-middle-name',
            }}
            label="Middle Name"
            name="middleName"
          />
        </Grid>
        <Grid item xs={4}>
          <FormTextfield
            disabled={allDisabled}
            inputProps={{
              'data-cy': 'clients-last-name',
            }}
            label="Last Name"
            name="lastName"
            required
          />
        </Grid>
      </Grid>
      <Grid container mb={2} spacing={2}>
        {checkEnabledSettings.dob && (
          <Grid item xs={3}>
            <FormMaskedDateInput
              disabled={allDisabled}
              inputProps={{
                'data-cy': 'date-input',
              }}
              isConfigurableThroughSetting
              showHelperText={errors?.dob?.type !== ('required' as any)}
            />
            {renderedVIPCheckbox}
          </Grid>
        )}

        {checkEnabledSettings.last4ssn && (
          <Grid item xs={3}>
            <FormMaskedLast4SSNInput
              disabled={allDisabled}
              inputProps={{
                'data-cy': 'last-four-ssn',
              }}
              isConfigurableThroughSetting
            />
            {!checkEnabledSettings.dob ? renderedVIPCheckbox : null}
          </Grid>
        )}

        {checkEnabledSettings.bloodGroup && (
          <Grid item xs={2}>
            <FormSelectTextField
              data={bloodGroupOptions}
              disabled={allDisabled}
              inputProps={{
                'data-cy': 'blood-group',
              }}
              isRequired={isBloodGroupRequired}
              label="Blood Group"
              name="bloodGroup"
            />
            <FormCheckbox
              disabled={isBloodTypeVerifiedDisabled() || allDisabled}
              label="Blood type verified"
              name="isBloodTypeVerified"
            />
          </Grid>
        )}
        {checkEnabledSettings.gender && (
          <Grid item xs={checkEnabledSettings.bloodGroup ? 4 : 6}>
            <FormGenderInput
              isConfigurableThroughSetting
              isDisabled={allDisabled}
            />
          </Grid>
        )}
      </Grid>

      {!checkEnabledSettings.dob && !checkEnabledSettings.last4ssn
        ? renderedVIPCheckbox
        : null}

      <Typography color="gray.darker" fontWeight="medium" variant="body1">
        Contact Information
      </Typography>
      <Grid container mb={2} spacing={2}>
        {/* <Grid item xs={12}>
          <FormCommunicationMediumSelect
            isRequired
            label="Communication Medium"
            name="communicationMedium"
          />
        </Grid> */}
        {checkEnabledSettings.email && (
          <Grid item xs={6}>
            <FormTextfield
              disabled={allDisabled}
              inputProps={{
                'data-cy': 'clients-email',
              }}
              label="Email"
              name="email"
              required={isEmailRequiredFields}
            />
          </Grid>
        )}

        {checkEnabledSettings.phone && (
          <Grid item xs={6}>
            <Stack justifyContent="flex-end">
              {isInternationalAddressEnabled ? (
                <FormPhoneCountryInput
                  data-cy="clients-phone-country-custom-input"
                  disabled={allDisabled}
                  isRequired={checkRequiredField.phone}
                  label="Cell Phone"
                  name="phone"
                />
              ) : (
                <FormMaskedPhoneInput
                  disabled={allDisabled}
                  inputProps={{
                    'data-cy': 'clients-phone-country-input',
                  }}
                  isRequired={checkRequiredField.phone}
                  label="Cell Phone"
                  name="phone"
                />
              )}

              {(checkEnabledSettings.textNotification &&
                !checkEnabledSettings.phone) ||
                (checkEnabledSettings.textNotification && (
                  <Box display="flex" justifyContent="flex-start" mt={0.5}>
                    <FormSwitch
                      color="primary"
                      data-cy="text-enabled"
                      disabled={!phoneValue || !isPhoneValid || allDisabled}
                      label={allowTextNotificationsLabel}
                      name="textEnabled"
                    />
                  </Box>
                ))}
            </Stack>
          </Grid>
        )}
      </Grid>

      <Grid container mb={2} spacing={2}>
        {isInternationalAddressEnabled && checkEnabledSettings.homePhone && (
          <Grid item pt={1} xs={6}>
            <FormPhoneCountryInput
              cCode="hCode"
              data-cy="case-phone-country-custom-input"
              disabled={allDisabled}
              ext="homeExt"
              isRequired={checkRequiredField.homePhone}
              label="Home Phone"
              name="home"
              resetAddress={false}
            />
          </Grid>
        )}

        {!isInternationalAddressEnabled && checkEnabledSettings.homePhone && (
          <Grid item xs={6}>
            <FormMaskedPhoneInput
              disabled={allDisabled}
              inputProps={{
                'data-cy': 'case-phone-country-input',
              }}
              isRequired={checkRequiredField.homePhone}
              label="Home Phone"
              name="home"
            />
          </Grid>
        )}

        {isInternationalAddressEnabled && checkEnabledSettings.workPhone && (
          <Grid item pt={1} xs={6}>
            <FormPhoneCountryInput
              cCode="wCode"
              data-cy="case-phone-country-custom-input"
              disabled={allDisabled}
              ext="workExt"
              isRequired={checkRequiredField.workPhone}
              label="Work Phone"
              name="work"
              resetAddress={false}
            />
          </Grid>
        )}

        {!isInternationalAddressEnabled && checkEnabledSettings.workPhone && (
          <Grid item xs={6}>
            <FormMaskedPhoneInput
              disabled={allDisabled}
              inputProps={{
                'data-cy': 'case-phone-country-input',
              }}
              isRequired={checkRequiredField.workPhone}
              label="Work Phone"
              name="work"
            />
          </Grid>
        )}
      </Grid>

      {checkEnabledSettings.address && (
        <Grid mt={-2}>
          <FormAddressInput
            allDisabled={allDisabled}
            isConfigurableThroughSetting
          />
        </Grid>
      )}

      <Grid item mt={2} xs={12}>
        <FormTagInput
          disabled={allDisabled}
          inputProps={{
            'data-cy': 'clients-tag',
          }}
          label="Add Tags"
          name="tags"
          showHelperText
          size="small"
        />
      </Grid>
      {!hideAddNoteField && (
        <Grid mt={2}>
          <FormTextfield
            disabled={allDisabled}
            inputProps={{
              'data-cy': 'clients-note',
            }}
            label="Add Note"
            multiline
            name="note"
            rows={4}
          />
        </Grid>
      )}
    </>
  );
};

IndividualForm.defaultProps = {
  allDisabled: false,
};

export default IndividualForm;
