import { ClientType } from 'enums/client-management';
import { selectAuthUserClient } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

import { useCheckUserIsClient } from './useCheckUserIsClient';

export const useCheckUserIsClientDependentOrGroupMember = () => {
  const userData = useAppSelector(selectAuthUserClient);
  const isUserClient = useCheckUserIsClient();

  return !!(
    isUserClient &&
    ((userData?.relationship?.groupId &&
      userData?.relationship?.type === ClientType.PRIMARY) ||
      (userData?.relationship?.primaryId &&
        userData?.relationship?.type !== ClientType.PRIMARY))
  );
};
