import React, { useRef, useState } from 'react';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';

import {
  faArrowDown,
  faArrowUp,
  faComment,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { getTaggableUsers } from 'api/cases';
import PermissionGate from 'common/PermissionGate';
import commonConstants from 'constants/common';
import { useCaseOwnerAdminsWithPublicNote } from 'features/case-layout-new/hooks/useCaseOwnerAdminsWithPublicNote';
import { useCaseOwnerTenant } from 'features/case-layout-new/hooks/useCaseOwnerTenant';
import { useCheckCaseReadOnlyView } from 'features/case-layout-new/hooks/useCheckCaseReadOnlyView';
import { useGetCaseInvitees } from 'features/case-layout-new/hooks/useGetCaseInvitees';
import { useTaggerAdmin } from 'features/case-layout-new/hooks/useTaggerAdmin';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { useCheckUserIsTenantAdmin } from 'hooks/useCheckUserIsTenantAdmin';
import { IIsEditModeDefaultValueProps, ITaggableUser } from 'interfaces/cases';
import { useAddNoteMutation, useUpdateNoteMutation } from 'services/cases';
import { selectAuthTenantData, selectAuthUserClient } from 'stores/auth';
import {
  changeNoteBoxExpanded,
  changeNotes,
  selectCurrentReferralCase,
  selectCurrentReferralCaseNoteBoxExpanded,
  selectCurrentReferralCaseNotes,
} from 'stores/cases';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { removeDuplicatesFromArrayOfObjects } from 'utils/misc';

const editModeDefaultValue = {
  status: false,
  data: null,
};
interface IMentioned {
  display: string;
  id: string;
}

export const handleReactMentionKeyDown = (
  event:
    | React.KeyboardEvent<HTMLTextAreaElement>
    | React.KeyboardEvent<HTMLInputElement>
) => {
  if (event.key === 'x' && event.ctrlKey) {
    // Prevent the default behavior of Ctrl+X
    event.preventDefault();
  }
};
const MentionTextField = ({ noteBoxWidth }: { noteBoxWidth: number }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [mentioned, setMentioned] = useState<IMentioned[]>([]);
  const taggableUsersRef = useRef<ITaggableUser[]>([]);

  const currentReferralCaseNotes = useAppSelector(
    selectCurrentReferralCaseNotes
  );
  const authTenantData = useAppSelector(selectAuthTenantData);
  const authClientUser = useAppSelector(selectAuthUserClient);
  const isNoteBoxExpanded = useAppSelector(
    selectCurrentReferralCaseNoteBoxExpanded
  );
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);

  const loggedInUserId = authTenantData?.userId || '';
  const loggedInUserEmail = authTenantData?.email;
  const loggedInClientId = authClientUser?.clientId;
  const { text: message, editMode } = currentReferralCaseNotes;
  const caseId = currentReferralCase?.caseId;

  const isUserAdmin = useCheckUserIsTenantAdmin();
  const isUserClient = useCheckUserIsClient();
  const { isInvitedThirdPartyUser } = useCheckCaseReadOnlyView({
    includePatientCheck: true,
  });
  const caseOwnerAdminsWithPublicNote = useCaseOwnerAdminsWithPublicNote();
  const taggerAdmins = useTaggerAdmin();
  const caseOwner = useCaseOwnerTenant();
  const { partnerInvitees } = useGetCaseInvitees();

  const changeMessage = (text: string) => {
    dispatch(
      changeNotes({
        text,
      })
    );
  };
  const changeEditMode = (e: IIsEditModeDefaultValueProps) => {
    dispatch(changeNotes({ editMode: e }));
  };
  const changeExpandMode = (mode: boolean) => {
    dispatch(changeNoteBoxExpanded(mode));
  };

  const textChange = (
    e: { target: { value: string } },
    newValue: string,
    newPlainTextValue: string,
    mentions: IMentioned[]
  ) => {
    changeMessage(e.target.value);
    setMentioned(mentions);
  };

  const renderSuggestion = (e: SuggestionDataItem) => (
    <div>
      <p className="mb-0 lh-1"> {e.display}</p>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        {/* e.id consists of email_tenantId */}
        <small className="text-muted">{e.id.toString().split('____')[0]}</small>
        <small className="text-muted">{e.tenantName}</small>
      </Box>
    </div>
  );

  const addNoteMutation = useAddNoteMutation();
  const updateNoteMutation = useUpdateNoteMutation();

  // eslint-disable-next-line consistent-return
  const handleSubmit = () => {
    if (!message.trim().length) return;
    if (!caseId) return;

    const taggableUsers = taggableUsersRef.current || [];

    const sendEmailList = taggableUsers
      .filter((el) => mentioned.some((e) => e.id === el.email_tenantId))
      .map((e) => ({
        display: e.name,
        id: e.email,
        type: e.type,
        tenantId: e.tenantId || '',
        userId: e.userId || '',
        ...(e.type === commonConstants.PATIENT && { isTaggedMember: true }),
      }));

    const sendEmailListWithoutDuplicates = removeDuplicatesFromArrayOfObjects(
      sendEmailList,
      'id'
    );

    let cleanedMessage = message;

    mentioned.forEach((mention: IMentioned) => {
      /**
       * Since, our id is composite of email___tenantId,
       * we need to remove `___tenantId` from the message/text.
       */
      cleanedMessage = cleanedMessage.replaceAll(
        mention.id,
        mention.id.toString().split('____')[0]
      );
    });

    if (editMode.status) {
      updateNoteMutation.mutate(
        {
          caseId,
          noteId: editMode.data!.id!,
          data: {
            note: cleanedMessage,
            sendEmailList: sendEmailListWithoutDuplicates,
          },
        },
        {
          onSuccess: () => {
            changeEditMode(editModeDefaultValue);
            changeMessage('');
          },
        }
      );
    } else {
      addNoteMutation.mutate(
        {
          caseId,
          data: {
            note: cleanedMessage,
            sendEmailList: sendEmailListWithoutDuplicates,
          },
        },
        {
          onSuccess: () => {
            changeMessage('');
          },
        }
      );
    }
  };

  const handleCancel = () => {
    changeMessage('');
    changeEditMode(editModeDefaultValue);
  };

  if (!noteBoxWidth) return <> </>;

  return (
    <Box>
      <Box padding={2} width={`${noteBoxWidth}px`}>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Stack direction="row" spacing={1}>
            <FontAwesomeIcon
              icon={faComment}
              size="lg"
              style={{
                color: theme.palette.primary.main,
                fontWeight: theme.typography.fontWeightRegular,
              }}
            />
            <Typography
              gutterBottom={false}
              sx={{ fontWeight: (t) => t.typography.fontWeightMedium }}
            >
              {editMode.status ? 'Edit Note' : 'Add Note'}
            </Typography>
          </Stack>
          <IconButton onClick={() => changeExpandMode(!isNoteBoxExpanded)}>
            <FontAwesomeIcon
              icon={isNoteBoxExpanded ? faArrowDown : faArrowUp}
              style={{
                fontSize: '0.6em',
                fontWeight: theme.typography.fontWeightRegular,
              }}
            />
          </IconButton>
        </Box>

        <Box
          className={`add-note-section ${
            isNoteBoxExpanded ? 'note-expand' : ''
          }`}
          paddingY={2}
          width="100%"
        >
          <PermissionGate
            checkClickEvent={false}
            errorProps={{ disabled: true }}
          >
            <MentionsInput
              allowSpaceInQuery
              className="mention"
              data-cy="case-mention-input"
              forceSuggestionsAboveCursor
              onChange={textChange}
              onKeyDown={handleReactMentionKeyDown}
              singleLine={false}
              value={message}
            >
              <Mention
                appendSpaceOnAdd
                className="text-link"
                data={async (search, callback) => {
                  const data = await getTaggableUsers(
                    currentReferralCase?.caseId || '',
                    {
                      for: isUserAdmin ? 'admin' : 'client',
                      keyword: search,
                    }
                  );

                  const fetchedUsers = data.data?.map((item) => ({
                    ...item,
                    email_tenantId: `${item.email}____${item.tenantId}`,
                  }));

                  /**
                   * Admin users with public note is computed in the UI and then, appended to the suggestions.
                   * So, we need to filter them by keyword manually.
                   */
                  const filteredCaseOwnerAdminsWithPublicNote =
                    caseOwnerAdminsWithPublicNote?.filter(
                      (admin) =>
                        admin.name
                          ?.toLowerCase()
                          ?.includes(search?.toLowerCase()) ||
                        admin.email
                          ?.toLowerCase()
                          ?.includes(search?.toLowerCase())
                    );

                  /**
                   * Admin users who have tagged the user is computed in the UI too and hence, the manual filter
                   */
                  const filteredTaggerAdmins = taggerAdmins?.filter(
                    (admin) =>
                      admin.name
                        ?.toLowerCase()
                        ?.includes(search?.toLowerCase()) ||
                      admin.email
                        ?.toLowerCase()
                        ?.includes(search?.toLowerCase())
                  );

                  taggableUsersRef.current = removeDuplicatesFromArrayOfObjects(
                    [
                      ...taggableUsersRef.current,
                      ...fetchedUsers,
                      ...filteredCaseOwnerAdminsWithPublicNote,
                      ...filteredTaggerAdmins,
                    ],
                    'email_tenantId'
                  );

                  let taggableUsers = removeDuplicatesFromArrayOfObjects(
                    [
                      ...fetchedUsers,
                      ...filteredCaseOwnerAdminsWithPublicNote,
                      ...filteredTaggerAdmins,
                    ]
                      ?.map((user) => ({
                        display: user.name,
                        // Need to include tenantId as well as a  single user can be associated in multiple tenants
                        id: `${user.email}____${user.tenantId}`,
                        userId: user.userId || '',
                        tenantId: user.tenantId || '',
                        tenantName: user.tenantName,
                      }))
                      ?.filter((user) => {
                        // remove self in the tag suggestion
                        let filterOutCheck = user.userId !== loggedInUserId;

                        if (isUserClient) {
                          filterOutCheck =
                            filterOutCheck && user.userId !== loggedInClientId;
                        }

                        // third party user doesn't have its own userId (it's using generic guest userId)
                        if (isInvitedThirdPartyUser) {
                          filterOutCheck =
                            filterOutCheck &&
                            user.id
                              ?.split('____')?.[0]
                              ?.trim()
                              ?.toLowerCase() !==
                              loggedInUserEmail?.trim()?.toLowerCase();
                        }

                        return filterOutCheck;
                      }),
                    'id'
                  );

                  /**
                   * Filter out admins from partner tenants whose access have been revoked
                   */
                  taggableUsers = taggableUsers
                    .filter(
                      (user) =>
                        user.tenantId === caseOwner?.referenceId || // include users from the owner tenant
                        partnerInvitees?.some(
                          (pInv) =>
                            pInv.referenceId === user.tenantId && !pInv.revoked
                        )
                    )
                    .filter((i) => !!i.display);

                  callback(taggableUsers);
                }}
                // markup='@__display__'
                renderSuggestion={renderSuggestion}
                trigger="@"
              />
            </MentionsInput>
          </PermissionGate>
        </Box>

        <Box alignItems="center" display="flex" justifyContent="space-between">
          <PermissionGate>
            <LoadingButton
              color="primary"
              data-cy="case-mention-button"
              disabled={!message}
              loading={
                addNoteMutation.isLoading || updateNoteMutation.isLoading
              }
              onClick={handleSubmit}
              size="medium"
              type="submit"
              variant="contained"
            >
              Submit
            </LoadingButton>
          </PermissionGate>

          {editMode.status && (
            <Button onClick={handleCancel} size="medium" variant="outlined">
              Cancel
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MentionTextField;
