import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Drawer,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { SETTINGS_BAR_PROPERTY } from 'constants/settings';

import LayoutRoute from './LayoutRoute';

const SettingsLayout = () => {
  const theme = useTheme();

  const [mobileOpen, setMobileOpen] = useState(false);
  const smallerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const handleDrawerToggle = () => {
    if (smallerThanMd) setMobileOpen(!mobileOpen);
  };
  const { DRAWER_WIDTH, HEADER_HEIGHT, SIDEBAR_WIDTH } = SETTINGS_BAR_PROPERTY;
  return (
    <Box ml={{ base: 0, md: DRAWER_WIDTH }}>
      <Drawer
        onClose={handleDrawerToggle}
        open={smallerThanMd ? mobileOpen : true}
        PaperProps={{
          sx: {
            width: DRAWER_WIDTH,
            top: {
              base: 0,
              md: `calc(${HEADER_HEIGHT} + 10px)`,
            },
            left: { base: 0, md: SIDEBAR_WIDTH },
            pl: 2,
          },
        }}
        variant={smallerThanMd ? 'temporary' : 'permanent'}
      >
        <LayoutRoute />
      </Drawer>
      <Box>
        <Outlet />
      </Box>
      {smallerThanMd && (
        <IconButton
          onClick={handleDrawerToggle}
          sx={{
            display: { md: 'none' },
            left: `calc(${SIDEBAR_WIDTH} + 10px)`,
            position: 'fixed',
            bottom: 20,
          }}
        >
          <Box color="primary.main" component={FontAwesomeIcon} icon={faAdd} />
        </IconButton>
      )}
    </Box>
  );
};

export default SettingsLayout;
