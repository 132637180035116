/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import config from 'config';
import regex from 'constants/regex';
import { IReferralCaseNoteHistory } from 'interfaces/cases';
import { truncateText } from 'utils/common';
import { formatDateView } from 'utils/moment';
import { parseHtml } from 'utils/parseHtml';

interface IProps {
  details: IReferralCaseNoteHistory;
}

const NotesHistoryItem = ({ details }: IProps) => {
  const [minLength] = useState(100);
  const [maxLength, setMaxLength] = useState(0);

  const [showMore, setShowMore] = useState(false);
  const { from, date, updatedBy } = details;

  const mentionRegex = regex.MENTION;
  const getHtmlMessage = (message: string) =>
    message?.replace(mentionRegex, '<span class="text-link">$1</span>');

  useEffect(() => {
    if (from.note) setMaxLength(from.note.length);
  }, [from.note]);

  return (
    <Box>
      <Typography variant="caption">
        {updatedBy.name} on{' '}
        {formatDateView(date as string, config.fullDateViewFormat)}
      </Typography>
      <Box>
        <Typography
          component="span"
          dangerouslySetInnerHTML={{
            __html: parseHtml(
              truncateText(
                getHtmlMessage(from.note),
                showMore ? maxLength + 33 : minLength,
                ' ...'
              )
            ),
          }}
          data-cy="case-note-history-popover-details"
          fontWeight="medium"
          gutterBottom={false}
          sx={{
            textAlign: 'start !important',
          }}
          variant="body2"
        />{' '}
        {/* 33 is being added to compensate for the added html tag on
                  getHtmlMessage */}
        {!(maxLength < 97) && maxLength + 33 > minLength && (
          <Box display="inline">
            {!showMore ? (
              <IconButton
                aria-label="delete"
                className="badge-button"
                data-cy="case-note-history-popover-more"
                onClick={() => setShowMore(true)}
                size="small"
              >
                More
              </IconButton>
            ) : (
              <IconButton
                aria-label="delete"
                className="badge-button"
                data-cy="case-note-history-popover-less"
                onClick={() => setShowMore(false)}
                size="small"
              >
                Less
              </IconButton>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default NotesHistoryItem;
