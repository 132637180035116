/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ManageMembershipActionStep,
  ManageMembershipActionType,
  ManageMembershipStatus,
  ManageMembershipType,
} from 'enums/client-management/manage-membership';
import { Status } from 'enums/common';
import { IAdaptedClient } from 'interfaces/client-management';
import { IAdaptedClientEnrollmentMembership } from 'interfaces/client-management/enrollment';
import {
  IChangeMembershipSelectedOfferings,
  IManageMembershipState,
} from 'interfaces/client-management/manage-membership';
import { RootState } from 'stores';

const initialState: IManageMembershipState = {
  manageMembershipActionType: null,
  manageMembershipActionStep: ManageMembershipActionStep.INITIAL,
  manageMembershipType: ManageMembershipType.INDIVIDUAL,
  manageMembershipStatus: ManageMembershipStatus.PUBLIC,
  manageMembershipClient: null,
  manageMembershipTypeGroupDetail: null,
  manageMembershipClientMemberships: null,
  manageMembershipSelectedMembership: {
    details: null,
    selectedOffering: null,
    startDate: null,
    endDate: null,
    note: null,
  },
  membershipListStatus: Status.ACTIVE,
  invalidSelectedDate: false,
  isAddPayment: false,
};

export const slice = createSlice({
  name: 'manageMembership',
  initialState,
  reducers: {
    setManageMembershipActionType: (
      state,
      action: PayloadAction<ManageMembershipActionType | null>
    ) => {
      state.manageMembershipActionType = action.payload;
    },
    setManageMembershipActionStep: (
      state,
      action: PayloadAction<ManageMembershipActionStep>
    ) => {
      state.manageMembershipActionStep = action.payload;
    },
    setManageMembershipType: (
      state,
      action: PayloadAction<ManageMembershipType>
    ) => {
      state.manageMembershipType = action.payload;
    },
    setManageMembershipStatus: (
      state,
      action: PayloadAction<ManageMembershipStatus>
    ) => {
      state.manageMembershipStatus = action.payload;
    },
    setManageMembershipClient: (
      state,
      action: PayloadAction<IAdaptedClient>
    ) => {
      state.manageMembershipClient = action.payload;
    },
    setManageMembershipTypeGroupDetail: (
      state,
      action: PayloadAction<IAdaptedClient | null>
    ) => {
      state.manageMembershipTypeGroupDetail = action.payload;
    },
    setManageMembershipClientMemberships: (
      state,
      action: PayloadAction<IAdaptedClientEnrollmentMembership[] | null>
    ) => {
      state.manageMembershipClientMemberships = action.payload;
    },

    setManageMembershipSelectedOfferings: (
      state,
      action: PayloadAction<Partial<IChangeMembershipSelectedOfferings>>
    ) => {
      state.manageMembershipSelectedMembership = {
        ...state.manageMembershipSelectedMembership,
        ...action.payload,
      };
    },

    setManageMembershipInvalidSelectedDate: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.invalidSelectedDate = action.payload;
    },
    setMembershipListStatus: (state, action: PayloadAction<Status>) => {
      state.membershipListStatus = action.payload;
    },
    // const [isAddPayment, setIsAddPayment] = useState(false);
    setIsAddPayment: (state, action: PayloadAction<boolean>) => {
      state.isAddPayment = action.payload;
    },
    resetManageMembershipSelectedOfferings: (state) => {
      state.manageMembershipSelectedMembership =
        initialState.manageMembershipSelectedMembership;
    },

    resetManageMembership: (state) => {
      state.manageMembershipActionType =
        initialState.manageMembershipActionType;

      state.manageMembershipActionStep =
        initialState.manageMembershipActionStep;
      state.manageMembershipType = initialState.manageMembershipType;
      state.manageMembershipStatus = initialState.manageMembershipStatus;
      state.manageMembershipClient = initialState.manageMembershipClient;
      state.manageMembershipTypeGroupDetail =
        initialState.manageMembershipTypeGroupDetail;
      state.manageMembershipSelectedMembership =
        initialState.manageMembershipSelectedMembership;
      state.manageMembershipClientMemberships =
        initialState.manageMembershipClientMemberships;
      state.membershipListStatus = initialState.membershipListStatus;
      state.invalidSelectedDate = initialState.invalidSelectedDate;
      state.isAddPayment = initialState.isAddPayment;
    },
  },
});

// Actions
export const {
  setManageMembershipInvalidSelectedDate,
  setManageMembershipClientMemberships,
  resetManageMembershipSelectedOfferings,
  setManageMembershipTypeGroupDetail,
  setManageMembershipActionStep,
  setManageMembershipActionType,
  setManageMembershipType,
  setManageMembershipStatus,
  setManageMembershipClient,
  setManageMembershipSelectedOfferings,
  resetManageMembership,
  setMembershipListStatus,
  setIsAddPayment,
} = slice.actions;

// Selectors
export const selectManageMembershipActionType = (state: RootState) =>
  state.manageMembership.manageMembershipActionType;
export const selectManageMembershipType = (state: RootState) =>
  state.manageMembership.manageMembershipType;
export const selectManageMembershipStatus = (state: RootState) =>
  state.manageMembership.manageMembershipStatus;
export const selectManageMembershipClient = (state: RootState) =>
  state.manageMembership.manageMembershipClient;
export const selectManageMembershipTypeGroupDetail = (state: RootState) =>
  state.manageMembership.manageMembershipTypeGroupDetail;
export const selectManageMembershipSelectedMembership = (state: RootState) =>
  state.manageMembership.manageMembershipSelectedMembership;
export const selectManageMembershipActionStep = (state: RootState) =>
  state.manageMembership.manageMembershipActionStep;
export const selectManageMembershipClientMemberships = (state: RootState) =>
  state.manageMembership.manageMembershipClientMemberships;

export const selectMembershipListStatus = (state: RootState) =>
  state.manageMembership.membershipListStatus;

export const selectManageMembershipInvalidSelectedDate = (state: RootState) =>
  state.manageMembership.invalidSelectedDate;
export const selectIsAddPayment = (state: RootState) =>
  state.manageMembership.isAddPayment;
// Reducer
export default slice.reducer;
