export function hasAccess(authRoute: string, uiRoute: string): boolean {
  // Split the routes into their path segments
  const baseSegments = authRoute.split('/').filter(Boolean);
  const targetSegments = uiRoute.split('/').filter(Boolean);

  // If authRoute has more segments than uiRoute, access is not allowed
  if (baseSegments.length > targetSegments.length) {
    return false;
  }

  // Compare each segment of the base route with the corresponding segment in the target route
  for (let i = 0; i < baseSegments.length; i += 1) {
    if (baseSegments[i] !== targetSegments[i]) {
      return false;
    }
  }

  // If all segments match, access is allowed
  return true;
}
