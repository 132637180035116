import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as clientAPI from 'api/client-management';
import { DictionaryKeys } from 'constants/languageDictionary';
import UserType from 'enums/User';
import useClientListQueryKey from 'hooks/useClientListQueryKey';
import { useTenantTerminology } from 'hooks/useTenantTerminology';
import {
  IClientEnrollmentCancelSchema,
  IClientEnrollmentDetailFilter,
  IClientEnrollmentMembership,
  IClientEnrollmentMembershipQuery,
  IClientEnrollmentSchema,
  IEnrollmentAdvanceFilterWithInterval,
  IOfferingsForm,
} from 'interfaces/client-management/enrollment';
import { IReceiveRevisedBillingPayload } from 'interfaces/client-management/manage-membership';
import { IError } from 'interfaces/http';
import { useSnackbar } from 'notistack';
import { selectChangeMembershipClientData } from 'stores/client-management';
import { useAppSelector } from 'stores/hooks';
import { adaptClientEnrollmentMembership } from 'utils/client-management';
import {
  adaptAllClientEnrollment,
  adaptClientEnrollment,
  adaptClientEnrollmentDetail,
} from 'utils/client-management/enrollment';

import { clientKeys } from '..';

export const enrollmentKeys = {
  all: ['clients-enrollment'] as const,
  details: () => [...enrollmentKeys.all, 'detail'] as const,
  detail: (id: number | string) => [...enrollmentKeys.details(), id] as const,
  enrollmentDetails: () =>
    [...enrollmentKeys.all, 'enrollment-detail'] as const,
  enrollmentDetail: (id: number | string) =>
    [...enrollmentKeys.enrollmentDetails(), id] as const,
};

export const enrollmentMembershipKeys = {
  all: ['clients-enrollment-membership'] as const,
  details: () => [...enrollmentMembershipKeys.all, 'detail'] as const,
  detail: (id: number | string) =>
    [...enrollmentMembershipKeys.details(), id] as const,
};

export const useApproveClientEnrollmentMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { groupQueryKey, individualQueryKey } = useClientListQueryKey();

  return useMutation(
    ({
      clientId,
      enrollmentId,
      body,
    }: {
      clientId: string;
      enrollmentId: string;
      body: IOfferingsForm;
      clientType: UserType;
    }) => clientAPI.approveClientEnrollment(clientId, enrollmentId, body),
    {
      onSuccess: (res, context) => {
        const { clientType } = context;

        if (!clientType) return;

        const queryKey =
          clientType === UserType.GROUP ? groupQueryKey : individualQueryKey;
        const queryData = queryClient.getQueryData(queryKey);

        if (!queryData) return;

        // Here, we are invalidating queries instead of queryClient.setQueryData because,
        // other items in the list should also be updated in this case i.e. when the primary member
        // is approved, all of its dependents should be approved too.
        queryClient.invalidateQueries(queryKey);

        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError, context) => {
        const { clientType } = context;
        const queryKey =
          clientType === UserType.GROUP ? groupQueryKey : individualQueryKey;
        const queryData = queryClient.getQueryData(queryKey);
        if (!queryData) return;

        queryClient.invalidateQueries(queryKey);

        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useCancelClientEnrollmentMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { groupQueryKey, individualQueryKey } = useClientListQueryKey();
  return useMutation(
    ({
      clientId,
      enrollmentId,
      data,
    }: {
      clientId: string;
      enrollmentId: string;
      data: IClientEnrollmentCancelSchema;
      clientType: UserType;
    }) => clientAPI.cancelClientEnrollment(clientId, enrollmentId, data),
    {
      onSuccess: (res, context) => {
        const { clientType } = context;

        if (!clientType) return;

        const queryKey =
          clientType === UserType.GROUP ? groupQueryKey : individualQueryKey;
        const queryData = queryClient.getQueryData(queryKey);

        if (!queryData) return;

        // Here, we are invalidating queries instead of queryClient.setQueryData because,
        // other items in the list should also be updated.
        queryClient.invalidateQueries(queryKey);

        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useDeclineClientEnrollmentMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { groupQueryKey, individualQueryKey } = useClientListQueryKey();
  return useMutation(
    ({
      clientId,
      enrollmentId,
      data,
    }: {
      clientId: string;
      enrollmentId: string;
      data: IClientEnrollmentCancelSchema;
      clientType: UserType;
    }) => clientAPI.declineClientEnrollment(clientId, enrollmentId, data),
    {
      onSuccess: (res, context) => {
        const { clientType } = context;

        if (!clientType) return;

        const queryKey =
          clientType === UserType.GROUP ? groupQueryKey : individualQueryKey;
        const queryData = queryClient.getQueryData(queryKey);

        if (!queryData) return;

        // Here, we are invalidating queries instead of queryClient.setQueryData because,
        // other items in the list should also be updated.
        queryClient.invalidateQueries(queryKey);

        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useCompleteClientEnrollmentMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { groupQueryKey, individualQueryKey } = useClientListQueryKey();
  return useMutation(
    ({
      clientId,
      enrollmentId,
      data,
    }: {
      clientId: string;
      enrollmentId: string;
      data: IClientEnrollmentCancelSchema;
      clientType: UserType;
    }) => clientAPI.completeClientEnrollment(clientId, enrollmentId, data),
    {
      onSuccess: (res, context) => {
        const { clientType } = context;

        if (!clientType) return;

        const queryKey =
          clientType === UserType.GROUP ? groupQueryKey : individualQueryKey;
        const queryData = queryClient.getQueryData(queryKey);

        if (!queryData) return;

        // Here, we are invalidating queries instead of queryClient.setQueryData because,
        // other items in the list should also be updated.
        queryClient.invalidateQueries(queryKey);

        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useClientEnrollmentQuery = (
  clientId: string,
  clientEnrollmentId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    enrollmentKeys.detail(clientId),
    () => clientAPI.getClientEnrollment(clientId, clientEnrollmentId),
    {
      enabled,
      select: adaptClientEnrollment,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};
export const useAllClientEnrollmentQuery = (
  clientId: string,
  params: IEnrollmentAdvanceFilterWithInterval,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    enrollmentKeys.detail(clientId),
    () => clientAPI.getAllClientEnrollment(clientId, params),
    {
      enabled,
      select: adaptAllClientEnrollment,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useClientEnrollmentMembershipQuery = (
  clientId: string,
  { enabled }: { enabled: boolean },
  params?: IClientEnrollmentMembershipQuery
) => {
  const queryInfo = useQuery(
    enrollmentMembershipKeys.detail(clientId),
    () => clientAPI.getClientEnrollmentMembership(clientId, params),
    {
      enabled,
      select: adaptClientEnrollmentMembership,
      cacheTime: 0,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useClientEnrollmentDetailQuery = (
  clientId: string,
  enrollmentId: string,
  queryParams: IClientEnrollmentDetailFilter,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    enrollmentKeys.enrollmentDetail(clientId),
    () =>
      clientAPI.getClientEnrollmentDetail(clientId, enrollmentId, queryParams),
    {
      enabled,
      select: adaptClientEnrollmentDetail,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useUpdateClientEnrollmentMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { groupQueryKey, individualQueryKey } = useClientListQueryKey();

  const terminology = useTenantTerminology([
    DictionaryKeys.PATIENT_TERMINOLOGY,
  ]);

  return useMutation(
    ({
      clientId,
      enrollmentId,
      data,
    }: {
      clientId: string;
      enrollmentId: string;
      data: IClientEnrollmentSchema;
      clientType: UserType;
    }) => clientAPI.updateClientEnrollment(clientId, enrollmentId, data),
    {
      onSuccess: (res, context) => {
        enqueueSnackbar(
          res.message.replace(
            'Client',
            terminology?._PATIENT_TERMINOLOGY as string
          ),
          {
            variant: 'success',
          }
        );

        const { clientType } = context;

        if (!clientType) return;

        const queryKey =
          clientType === UserType.GROUP ? groupQueryKey : individualQueryKey;
        const queryData = queryClient.getQueryData(queryKey);

        const enrollmentQueryKey = enrollmentMembershipKeys.detail(
          context.clientId
        );
        const enrollmentQuery = queryClient.getQueryData(enrollmentQueryKey);
        if (enrollmentQuery) {
          queryClient.invalidateQueries(enrollmentQueryKey);
          // queryClient.setQueryData(enrollmentQueryKey, (oldData: any) => {
          //   if (!oldData) return oldData;
          //   return {
          //     ...oldData,
          //     data: {
          //       ...oldData.data,
          //       rows: oldData.data.rows.map((item: any) => {
          //         if (item.clientEnrollmentId !== context.enrollmentId)
          //           return item;
          //         return {
          //           ...item,
          //           ...(context.data.startDate && {
          //             startDate: context.data.startDate,
          //           }),
          //           ...(context.data.endDate && {
          //             endDate: context.data.endDate,
          //           }),
          //         };
          //       }),
          //     },
          //   };
          // });
        }
        if (!queryData) return;

        // Here, we are invalidating queries instead of queryClient.setQueryData because,
        // other items in the list should also be updated.
        queryClient.invalidateQueries(queryKey);

        // Need to invalidate client detail so that the membership chosen is reflected instantly.
        queryClient.invalidateQueries(clientKeys.detail(context.clientId));
        queryClient.invalidateQueries(enrollmentKeys.detail(context.clientId));
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useRestartClientEnrollmentMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { groupQueryKey, individualQueryKey } = useClientListQueryKey();

  return useMutation(
    ({
      clientId,
      enrollmentId,
      data,
    }: {
      clientId: string;
      enrollmentId: string;
      data: IClientEnrollmentSchema;
      clientType: UserType;
    }) => clientAPI.restartClientEnrollment(clientId, enrollmentId, data),
    {
      onSuccess: (res, context) => {
        const { clientType } = context;

        if (!clientType) return;

        const queryKey =
          clientType === UserType.GROUP ? groupQueryKey : individualQueryKey;
        const queryData = queryClient.getQueryData(queryKey);

        if (!queryData) return;

        // Here, we are invalidating queries instead of queryClient.setQueryData because,
        // other items in the list should also be updated.
        queryClient.invalidateQueries(queryKey);

        // Need to invalidate client detail so that the membership chosen is reflected instantly.
        queryClient.invalidateQueries(clientKeys.detail(context.clientId));
        queryClient.invalidateQueries(enrollmentKeys.detail(context.clientId));

        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useChangeClientEnrollmentMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { groupQueryKey, individualQueryKey } = useClientListQueryKey();

  return useMutation(
    ({
      clientId,
      enrollmentId,
      data,
    }: {
      clientId: string;
      enrollmentId: string;
      data: IClientEnrollmentSchema;
      clientType: UserType;
    }) => clientAPI.changeClientEnrollment(clientId, enrollmentId, data),
    {
      onSuccess: (res, context) => {
        const { clientType } = context;

        if (!clientType) return;

        const queryKey =
          clientType === UserType.GROUP ? groupQueryKey : individualQueryKey;
        const queryData = queryClient.getQueryData(queryKey);

        if (!queryData) return;

        // Here, we are invalidating queries instead of queryClient.setQueryData because,
        // other items in the list should also be updated.
        queryClient.invalidateQueries(queryKey);

        // Need to invalidate client detail so that the membership chosen is reflected instantly.
        queryClient.invalidateQueries(clientKeys.detail(context.clientId));
        queryClient.invalidateQueries(enrollmentKeys.detail(context.clientId));

        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useNewChangeClientEnrollmentMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { groupQueryKey, individualQueryKey } = useClientListQueryKey();

  return useMutation(
    ({
      clientId,
      enrollmentId,
      data,
    }: {
      clientId: string;
      enrollmentId: string;
      data: any;
    }) => clientAPI.changeClientEnrollment(clientId, enrollmentId, data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
        const data = res?.data as IClientEnrollmentMembership;
        const clientType = data?.client?.type;
        if (!clientType) {
          return;
        }

        const queryKey =
          clientType === UserType.GROUP ? groupQueryKey : individualQueryKey;
        const queryData = queryClient.getQueryData(queryKey);

        if (!queryData) {
          return;
        }

        queryClient.setQueryData(queryKey, (oldData: any) => ({
          ...oldData,
          data: {
            ...oldData.data,
            rows: oldData.data.rows.map((item: any) => {
              if (item.clientId !== data.clientId) return item;
              return {
                ...item,
                membership: {
                  ...item.membership,
                  id: data.offerings.id,
                  offeringName: data.offerings.name,
                  status: data.status,
                  startDate: data.startDate,
                  endDate: data.endDate,
                  totalCost: data.offerings.totalCost,
                },
              };
            }),
          },
        }));
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useReceiveRevisedBillingDetails = () =>
  useMutation(
    ({
      clientId,
      enrollmentId,
      data,
    }: {
      clientId: string;
      enrollmentId: string;
      data: IReceiveRevisedBillingPayload;
    }) => clientAPI.receiveRevisedBillingDetails(clientId, enrollmentId, data)
  );

export const useAddClientEnrollmentMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { groupQueryKey, individualQueryKey } = useClientListQueryKey();

  return useMutation(
    ({
      clientId,
      enrollmentId,
      data,
    }: {
      clientId: string;
      enrollmentId: string;
      data: any;
    }) => clientAPI.addClientEnrollment(clientId, enrollmentId, data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });

        const data = res.data as IClientEnrollmentMembership;
        const clientType = data?.client?.type;
        if (!clientType) {
          return;
        }

        const queryKey =
          clientType === UserType.GROUP ? groupQueryKey : individualQueryKey;

        // Here, we are invalidating queries instead of queryClient.setQueryData because,
        // other items in the list should also be updated.
        queryClient.invalidateQueries(queryKey);
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useNewCompleteClientEnrollmentMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const clientData = useAppSelector(selectChangeMembershipClientData) || null;

  const { groupQueryKey, individualQueryKey } = useClientListQueryKey();
  return useMutation(
    ({
      clientId,
      enrollmentId,
      data,
    }: {
      clientId: string;
      enrollmentId: string;
      data: IClientEnrollmentCancelSchema;
    }) => clientAPI.completeClientEnrollment(clientId, enrollmentId, data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });

        const data = res.data as IClientEnrollmentMembership;
        const clientType = data?.client?.type || clientData?.type;
        if (!clientType) {
          return;
        }

        const queryKey =
          clientType === UserType.GROUP ? groupQueryKey : individualQueryKey;

        // Here, we are invalidating queries instead of queryClient.setQueryData because,
        // other items in the list should also be updated.
        queryClient.invalidateQueries(queryKey);
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};
