const toastMessageConstant = {
  TOKEN_EXPIRED: 'Session expired. Please log in to continue.',
  AUTH: {
    LINK_EXPIRED:
      'Your activation link has expired, please request a new link from your service provider',
    INVALID_LINK: 'Your activation link is invalid, please use valid link',
    ACTIVATE_SUCCESSFUL: 'You account has been successfully activated',
  },
  CASES: {
    ADD_REFERRING_PHYSICIAN: 'Referring Physician Added Successfully',
    ADD_NEW_CASE: 'Case Added Successfully',
    ADD_NEW_NOTE: 'Note Added Successfully',
    ADD_NEW_FILE: 'File Added Successfully',
    ADD_NEW_TIMELOG: 'Timelog Added Successfully',
    ADD_DONOR: 'Blood Donors Added Successfully',
    ADD_TASK: 'Task Added Successfully',
    CHANGE_STATUS: 'Status Changed Successfully',
    CHANGE_REFERRING_PHYSICIAN: 'Referring Physician Changed Successfully',
    CHANGE_TENANT: 'Tenant Changed Successfully',
    CASE_CLONED: 'Case Cloned Successfully',
    DELETE_NEW_TIMELOG: 'Timelog deleted Successfully',
    DELETED_NOTE: 'Note Deleted Successfully',
    DELETED_TASK: 'Task Deleted Successfully',
    DONOR_DELETED: 'Blood Donor Deleted Successfully',
    REFERRING_PROVIDER_DELETED: 'Provider Deleted Successfully',
    UPDATE_STATE_AVERAGE: 'State Average Updated Successfully',
    DONOR_REMOVED: 'Blood Donor Removed Successfully',
    SERVICE_REMOVED: 'Service Removed Successfully',
    PROVIDER_REMOVED: 'Provider Removed Successfully',
    PROCEDURE_REMOVED: 'Procedure Removed Successfully',
    PROVIDER_SELECTED: 'Provider Selected Successfully',
    PROVIDER_CREATED: 'Provider Created Successfully',
    PROVIDER_UPDATED: 'Provider Updated Successfully',
    DONOR_SELECTED: 'Blood Donor Selected Successfully',
    EDIT_REFERRING_PHYSICIAN: 'Referring Physician Updated Successfully',
    FILE_UPLOADED: 'File Uploaded Successfully',
    FILE_ATTACHED: 'File Attached Successfully',
    FILE_RENAMED: 'File Renamed Successfully',
    FILE_DELETED: 'File Deleted Successfully',
    FILE_SHARED: 'File Shared Successfully',
    NOTIFY: 'User Notified Successfully',
    REMOVE_REFERRING_PHYSICIAN: 'Referring Physician Removed Successfully',
    SHARED: 'Case Shared Successfully',
    UNSHARED: 'Access to case revoked successfully',
    UPDATE_CASE_INFO: '{{CASE_INFO}} Updated Successfully',
    UPDATE_SERVICE: 'Service Updated Successfully',
    UPDATE_PROCEDURE: 'Procedure Updated Successfully',
    ADD_SERVICE: 'Service Added Successfully',
    ADD_PROCEDURE: 'Procedure Added Successfully',
    UPDATE_PATIENT: '{{patient_terminology}} Information Updated Successfully',
    UPDATE_NOTE: 'Note Updated Successfully',
    UPDATE_NEW_TIMELOG: 'Timelog Updated Successfully',
    USER_ASSIGN: 'User Assigned Successfully',
    SELECT_DEPENDENT_REQUIRED: 'Dependent not selected',
  },
  CHAT: {
    DELETE_CONVERSATION_FAILED: 'Failed to delete conversation',
  },
  DOCUMENT: {
    DOCUMENT_UPLOADED: 'Document Uploaded Successfully',
    DOCUMENT_RENAMED: 'Document Renamed Successfully',
    DOCUMENT_DELETED: 'Document Deleted Successfully',
    DOWNLOAD_UNSUCCESS: 'This file cannot be downloaded',
  },
  EXPORT: {
    NO_LIST_TO_EXPORT: 'No list to export',
  },
  ADMIN: {
    DELETE: 'Admin Deleted Successfully',
  },
  VIRTUAL_VISIT: {
    UNABLE_TO_END_MEETING: 'Something went wrong. Cannot end the meeting.',
    UNEXPECTED_ERROR: "Could'nt start the meeting. Please try again later",
    COPIED: 'Link Copied!',
    UNABLE_TO_START_MEETING:
      'Cannot start the meeting. It is already in progress or has been cancelled or completed',
  },
  LEAD: {
    UNABLE_TO_EDIT_NOTE: 'You cannot edit this note',
    UNABLE_TO_DELETE_NOTE: 'You cannot delete this note',
  },
  INVALID_MAINTENANCE_CODE: 'Invalid secret key',
  QR_CODE_FAILED: 'Failed to generate QR Code',
  MEMBERSHIP: {
    BILLING_NOT_AVAILABLE: 'Billing information not available.',
  },
  FORM_CONFIGURATION: {
    ATLEAST_TWO_OPTION: 'You need to select at least 2 option',
    ASK_QUESTION: 'Ask question {name} successfully!',
  },
};

export default toastMessageConstant;
