import React from 'react';

import { Box, Skeleton, Stack } from '@mui/material';

import ReferralCaseFileNoteLayout from '../layout/ReferralCaseFileNoteLayout';
import ReferralCaseNoteFileSkeleton from './ReferralCaseNoteFileSkeleton';

const ReferralCaseSkeleton = () => (
  <>
    <Box flexGrow={1}>
      <Stack padding={2} spacing={-4}>
        <Box
          alignItems="center"
          display="center"
          justifyContent="space-between"
        >
          <Box>
            <Skeleton height={18} width="80px" />
            <Skeleton height={28} width="100px" />
          </Box>
          <Box>
            <Skeleton height={18} width="80px" />
            <Skeleton height={28} width="100px" />
          </Box>
          <Skeleton height={60} width="160px" />
        </Box>
        <Skeleton height={220} width="100%" />
        <Box>
          <Stack direction="row" spacing={2}>
            <Skeleton height={620} sx={{ marginTop: '-120px' }} width="40%" />
            <Stack spacing={-14} width="60%">
              <Skeleton height={220} sx={{ marginTop: '-32px' }} width="100%" />
              <Stack direction="row" spacing={3}>
                <Skeleton height={380} width="50%" />
                <Skeleton height={380} width="50%" />
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Skeleton height={420} sx={{ marginTop: '-120px' }} width="100%" />
        </Box>
      </Stack>
    </Box>
    <ReferralCaseFileNoteLayout>
      <ReferralCaseNoteFileSkeleton />
    </ReferralCaseFileNoteLayout>
  </>
);

export default ReferralCaseSkeleton;
