import { DictionaryKeys } from 'constants/languageDictionary';
import { ArrayToEnumObjectType, EnumValues } from 'interfaces/common';

import { useTextFromLanguageDictionary } from './useTextFromLanguageDictionary';

export const useTenantTerminology = <
  T extends EnumValues<typeof DictionaryKeys>[]
>(
  keys: T
): ArrayToEnumObjectType<T> => {
  const [...terminology] = useTextFromLanguageDictionary({ keys });

  const getObj = () => {
    const obj: Record<string, string> = {};
    for (let i = 0; i < terminology.length; i += 1) {
      obj[keys[i]] = terminology[i];
    }
    return obj;
  };

  return getObj() as ArrayToEnumObjectType<T>;
};
