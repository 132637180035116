/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  faFileCirclePlus,
  faSlidersH,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Popover } from 'common';
import EmptySearchList from 'common/EmptySearchList';
import LoadingDots from 'common/LoadingDots';
import PermissionGate from 'common/PermissionGate';
import { EStatusModule } from 'enums/case';
import ModalKey from 'enums/ModalKey';
import {
  CaseCode,
  EnrollmentCode,
  TenantSettingStatus,
} from 'enums/tenant-management/tenant';
import { useCaseSearchKeywordAction } from 'features/case-layout-new/hooks/useCaseSearchKeywordAction';
import useShowRelevantSearch from 'features/case-layout-new/hooks/useShowRelevantSearch';
import { RelevantSearchLists } from 'features/case-layout-new/templateA/left-content/RelevantSearchLists';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { useCheckUserIsClientDependentOrGroupMember } from 'hooks/useCheckUserIsClientDependentOrGroupMember';
import { useCheckUserIsClientGroup } from 'hooks/useCheckUserIsClientGroup';
import { useCheckUserIsClientPrimary } from 'hooks/useCheckUserIsClientPrimary';
import { useCheckUserIsEmail } from 'hooks/useCheckUserisEmail';
import { useGetCaseTenantSettings } from 'hooks/useGetCaseTenantSettings';
import {
  IReferralCaseAdvancedSearchForm,
  IReferralCaseList,
} from 'interfaces/cases';
import { InputProps } from 'lib/util-types';
import { useFetchStatusQuery } from 'services/status';
import {
  changeAdvancedSearch,
  changeIsRelevantSearchEntered,
  changeIsScrollingEnabled,
  changeLoadMore,
  changeReferenceFile,
  changeReferenceNote,
  changeReferralCaseList,
  resetFiltersWithoutKeyword,
  selectCaseAdvancedSearch,
  selectCaseList,
  selectCurrentReferralCase,
  selectLoadMore,
} from 'stores/cases';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { debounce, omit } from 'utils/lodash';
import { cleanFilters } from 'utils/misc';

import AdvancedFilter from './AdvanceFilter';
import AppliedFilter from './AppliedFilter';
import ReferralCaseListLayout from './layout/ReferralCaseListLayout';
import ReferralCaseListItem from './ReferralCaseListItem';
import ReferralCaseListSkeleton from './skeleton/ReferralCaseListSkeleton';

interface IReferralCaseListProps {
  selectedMobileCase?: VoidFunction;
  handleChangeSelectedCaseId: (caseId: string) => void;
  handleScroll: (e: React.UIEvent<HTMLElement>) => void;
  isFetching: boolean;
  scrollToTop: boolean;
  isRecentSearchEmpty: boolean;
  onAdvancedSearchFormSubmit: (
    event: React.SyntheticEvent,
    formData: IReferralCaseAdvancedSearchForm
  ) => void;
  onSearchKeywordChange: (keyword: string) => void;
  handleOnlyMyCase: (onlyMyCases: boolean) => void;
  setScrollToTop: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoadMoreDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

const ReferralCaseList = ({
  selectedMobileCase,
  handleScroll,
  handleChangeSelectedCaseId,
  isFetching,
  isRecentSearchEmpty,
  onAdvancedSearchFormSubmit,
  onSearchKeywordChange,
  handleOnlyMyCase,
  scrollToTop,
  setScrollToTop,
  setIsLoadMoreDisabled,
  isLoading,
}: IReferralCaseListProps) => {
  const searchBarRef = useRef<HTMLDivElement>(null);
  const scrollBarRef = useRef<HTMLDivElement>(null);
  const advanceOptionRef = useRef<HTMLDivElement>(null);
  const caseQuery = useFetchStatusQuery(EStatusModule.CASE);
  const caseStatus = caseQuery?.data?.rows || [];
  const isUserClientPrimary = useCheckUserIsClientPrimary();

  const isLoadMore = useAppSelector(selectLoadMore);

  const { isPullRelevantSearch, isRelevantCaseEnabled } =
    useShowRelevantSearch();

  const isDependentCaseEnabled =
    (useGetCaseTenantSettings(
      CaseCode.INCLUDE_DEPENDENT_CASES_OPTION_FOR_CLIENT
    ) as TenantSettingStatus) === TenantSettingStatus.ENABLED;

  const isClientIndividualDependents =
    useCheckUserIsClientDependentOrGroupMember();

  const IncludeOnlyMyCasesOption = () => {
    if (!isDependentCaseEnabled && isUserClientPrimary) return true;
    if (isClientIndividualDependents || isUserClientPrimary) return true;

    return false;
  };

  const isUserClient = useCheckUserIsClient();
  const isUserEmail = useCheckUserIsEmail();
  const isUserGroup = useCheckUserIsClientGroup();
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);

  const isAddCaseEnabledForClient =
    (useGetCaseTenantSettings(
      EnrollmentCode.ADD_CASE_OPTION_FOR_CLIENT
    ) as TenantSettingStatus) === TenantSettingStatus.ENABLED;

  const navigate = useNavigate();

  const advanceFilterHeight = advanceOptionRef.current
    ? advanceOptionRef.current.offsetHeight + 15
    : 0;

  const topHeight =
    searchBarRef.current &&
    searchBarRef.current.offsetHeight +
      180 +
      advanceFilterHeight +
      (IncludeOnlyMyCasesOption() ? -42 : 0);

  const [keyword, setKeyword] = useState('');

  const dispatch = useAppDispatch();
  const listData = useAppSelector(selectCaseList);

  const [checked, setChecked] = React.useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const advancedSearch = useAppSelector(selectCaseAdvancedSearch);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    handleOnlyMyCase(event.target.checked);
    dispatch(changeReferenceNote(''));
    dispatch(changeReferenceFile(''));
    dispatch(changeIsRelevantSearchEntered(false));
    dispatch(changeIsScrollingEnabled(false));
  };

  const advancedFiltersToDisplay = useMemo(
    () =>
      cleanFilters(omit(advancedSearch, ['onlyMyCases'])).filter(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([e, v]: any) => v?.id || (typeof v === 'string' && v)
      ),
    [advancedSearch]
  );

  const onDeleteFilter = (filter: string) => {
    const adaptLabel = (label: string) => {
      if (label === 'Created by') {
        return 'requestedBy';
      }
      if (label === 'Assigned to') {
        return 'requestedTo';
      }
      return label;
    };

    setIsLoadMoreDisabled(false);
    dispatch(changeLoadMore(false));
    dispatch(changeIsScrollingEnabled(false));
    setScrollToTop(true);
    dispatch(resetFiltersWithoutKeyword());
    dispatch(changeReferralCaseList([]));
    dispatch(
      changeAdvancedSearch({
        [adaptLabel(filter) as 'requestedBy' | 'requestedTo' | 'status']: '',
      })
    );
  };

  const debouncedSearchHandler = useRef(
    debounce(async (value: string) => {
      if (value.length > 2 || !value.length) onSearchKeywordChange(value);
    }, 300)
  ).current;

  const searchHandler = (value: string) => {
    setKeyword(value);
    dispatch(changeReferenceNote(''));
    dispatch(changeReferenceFile(''));
    debouncedSearchHandler(value);
  };

  const handleAddCase = () => {
    navigate(
      `modal?type=${
        isUserClient ? ModalKey.ADD_PATIENT_CASE : ModalKey.ADD_CASE
      }&caseId=${currentReferralCase?.caseId}`
    );
  };

  const showAddCaseOption = () => {
    if (isUserClient && !isAddCaseEnabledForClient) {
      return false;
    }
    return !isUserGroup && !isUserEmail;
  };

  const [searchParams] = useSearchParams();
  const fromEmail = searchParams.get('fromEmail');
  const fromLink = searchParams.get('fromLink');
  const caseIdFromEmail = searchParams.get('caseId');
  const caseSearchKeywordAction = useCaseSearchKeywordAction();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    caseSearchKeywordAction.handleKeyDown(e, keyword);
  };

  useEffect(() => {
    if (caseIdFromEmail && (fromEmail || fromLink)) {
      setKeyword(caseIdFromEmail);
    }
  }, [caseIdFromEmail, dispatch, fromEmail, fromLink]);

  useEffect(
    () => () => {
      debouncedSearchHandler.cancel();
    },
    [debouncedSearchHandler]
  );

  useEffect(() => {
    if (advancedSearch.onlyMyCases) setChecked(advancedSearch.onlyMyCases);
  }, [advancedSearch.onlyMyCases]);

  useEffect(() => {
    if (scrollToTop) scrollBarRef?.current?.scrollTo(0, 0);
  }, [scrollToTop]);

  return (
    <ReferralCaseListLayout>
      <Box padding={2}>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Typography
            color="gray.darker"
            fontWeight="medium"
            gutterBottom={false}
            variant="body1"
          >
            Cases
          </Typography>
          {showAddCaseOption() && (
            <PermissionGate>
              <Button
                data-cy="case-add"
                onClick={handleAddCase}
                size="small"
                startIcon={<FontAwesomeIcon icon={faFileCirclePlus} />}
                variant="outlined"
              >
                Add Case
              </Button>
            </PermissionGate>
          )}
        </Box>

        <Stack direction="row" spacing={2}>
          {/* TODO: This search is similar to what we have in table banner. Make a common component. */}
          <FormControl sx={{ width: '100%' }} variant="standard">
            <Box
              className="mui__filled-referral-input"
              ref={searchBarRef}
              sx={{ width: '100%', marginY: 1 }}
            >
              <TextField
                className="filled-variant"
                fullWidth
                inputProps={{
                  'data-cy': 'case-search',
                }}
                onChange={(event) => searchHandler(event.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Search"
                size="small"
                value={keyword}
              />
              {!isUserClient && (
                <Popover
                  buttonIcon={<FontAwesomeIcon icon={faSlidersH} />}
                  buttonSize="small"
                  buttonSx={{
                    // Overridding icon button for the search field
                    color: (theme) => `${theme.palette.gray.dark} !important`,
                    backgroundColor: (theme) =>
                      `${theme.palette.common.white} !important`,
                  }}
                  datacy="case"
                  open={isPopoverOpen}
                  setOpen={setIsPopoverOpen}
                  sx={{
                    width: `${searchBarRef?.current?.clientWidth}px`,
                  }}
                >
                  <Box>
                    <AdvancedFilter
                      caseStatus={caseStatus}
                      onAdvancedSearchFormSubmit={onAdvancedSearchFormSubmit}
                      setIsPopoverOpen={setIsPopoverOpen}
                    />
                  </Box>
                </Popover>
              )}
            </Box>
          </FormControl>
        </Stack>

        {advancedFiltersToDisplay.length ? (
          <Box mt={2} ref={advanceOptionRef}>
            <Typography gutterBottom={false} variant="body1">
              Filters Applied:{' '}
            </Typography>
            <Stack direction="row" display="flex" flexWrap="wrap" mb={1} ml={1}>
              {advancedFiltersToDisplay.length > 0 ? (
                <AppliedFilter
                  filters={advancedFiltersToDisplay}
                  onDelete={onDeleteFilter}
                  status={caseStatus}
                />
              ) : null}
            </Stack>
          </Box>
        ) : null}
        {!IncludeOnlyMyCasesOption() && !isUserEmail && (
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                inputProps={
                  {
                    'data-cy': 'case-onlyMyCase',
                  } as InputProps
                }
                onChange={handleChange}
                size="small"
              />
            }
            label={
              <Typography gutterBottom={false} variant="body2">
                Only my cases
              </Typography>
            }
          />
        )}
      </Box>
      {isLoading && !listData.length && <ReferralCaseListSkeleton />}

      {listData?.length || (!listData?.length && isRelevantCaseEnabled) ? (
        <Box
          className="scrollbar"
          height={`calc(100vh - ${topHeight}px)`}
          onScroll={(e) => {
            if (!isPullRelevantSearch) handleScroll(e);
          }}
          overflow="hidden auto"
          ref={scrollBarRef}
        >
          {isRecentSearchEmpty && (
            <EmptySearchList
              avatarProps={{ sx: { width: '40px', height: '40px' } }}
              message="Based on your search criteria, no matching results were found."
            />
          )}
          {!isRecentSearchEmpty &&
            listData?.map((el: IReferralCaseList) => (
              <ReferralCaseListItem
                data={el}
                handleChangeSelectedCaseId={handleChangeSelectedCaseId}
                key={el.caseId}
                {...(selectedMobileCase && { selectedMobileCase })}
              />
            ))}

          {isFetching && !isPullRelevantSearch && <LoadingDots />}

          {!isLoadMore && (
            <RelevantSearchLists searchedCases={listData || []} />
          )}
        </Box>
      ) : !listData.length || isRecentSearchEmpty ? (
        <Box height="100vh">
          <EmptySearchList
            avatarProps={{ sx: { width: '40px', height: '40px' } }}
            message="No referral case available"
          />
        </Box>
      ) : (
        <ReferralCaseListSkeleton />
      )}
    </ReferralCaseListLayout>
  );
};
ReferralCaseList.defaultProps = {
  selectedMobileCase: undefined,
};
export default ReferralCaseList;
