import { useNavigate } from 'react-router-dom';

import {
  faBirthdayCake,
  faEdit,
  faEnvelope,
  faMapMarkerAlt,
  faMobileAlt,
  faNoteSticky,
} from '@fortawesome/free-solid-svg-icons';
import {
  Avatar,
  Box,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { TransactionStatusIndicator } from 'common/display/TransactionStatusIndicator';
import PermissionGate from 'common/PermissionGate';
import { DictionaryKeys } from 'constants/languageDictionary';
import { TransactionAlertStatus } from 'enums/billings';
import { PatientContactType } from 'enums/case';
import ModalKey from 'enums/ModalKey';
import { usePatientInformation } from 'features/case-layout-new/hooks/usePatientInformation';
import { selectAuthTenantAssociation } from 'stores/auth';
import { selectCurrentReferralCase } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';
import { selectLangDict } from 'stores/language-dictionary';
import { formatClientIndividualParent } from 'utils/client-management';
import {
  formatDate,
  formatPhone,
  getFullName,
  sliceText,
  truncateText,
} from 'utils/common';
import { getTextFromLangDict } from 'utils/languageDictionary';

import PatientPNG from '../../assets/images/svg/patient.png';
import {
  CustomIconButton,
  mapOldAddressImplementation,
  StackIconAndTypography,
  StackIconAndTypographySeparateHover,
} from './common';

const PatientInformation = () => {
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const data = currentReferralCase?.patient;
  const themes = useTheme();
  const navigate = useNavigate();
  const isSmallerThanSm = useMediaQuery(themes.breakpoints.down('sm'));
  const authTenantAssocation = useAppSelector(selectAuthTenantAssociation);
  const langDict = useAppSelector(selectLangDict);
  const title = getTextFromLangDict(langDict?.dict, {
    groupCode: authTenantAssocation?.groupCode || '',
    key: DictionaryKeys.PATIENT_TERMINOLOGY,
    tenantCode: authTenantAssocation?.code || '',
  });
  const adaptEmail =
    data && (isSmallerThanSm ? truncateText(data?.email) : data?.email);

  const address =
    data && mapOldAddressImplementation(data?.address || undefined, data);
  const onEditClick = () => {
    navigate(
      `modal?type=${ModalKey.EDIT_CASE_PATIENT}&caseId=${currentReferralCase?.caseId}`
    );
  };
  const cellPhone =
    data?.phones?.find((phone) => phone.type === PatientContactType.CELL)
      ?.value || data?.phone;

  const { isPrimaryPatient } = usePatientInformation();
  const primaryParent = isPrimaryPatient
    ? formatClientIndividualParent(data?.parent)
    : '';

  return (
    <Box sx={{ position: 'relative' }}>
      <Typography color="gray.darker" fontWeight="medium" variant="body1">
        {title} Information
      </Typography>
      <Paper
        className="bordered no-box-shadow"
        sx={{
          height: '424px',
        }}
      >
        <Box position="relative">
          <Box
            left="50%"
            position="absolute"
            sx={{
              transform: 'translate(-50%,-50%)',
              width: '100%',
              height: '60px',
              textAlign: 'center',
            }}
            top="30%"
          >
            <Box
              alignItems="center"
              display="flex"
              gap={1}
              justifyContent="center"
            >
              <Typography
                color="text.primary"
                data-cy="case-patient-name"
                fontWeight="medium"
                gutterBottom={false}
                variant="h6"
              >
                {getFullName(data?.firstName, data?.lastName, data?.middleName)}
              </Typography>
              {data?.lastMembershipTransactionDetail?.status ===
                TransactionAlertStatus.FAILED && (
                <TransactionStatusIndicator
                  transactionDetail={data.lastMembershipTransactionDetail}
                />
              )}
            </Box>
            <Box>
              <Typography
                color="text.secondary"
                data-cy="case-patient-clientType"
                gutterBottom={false}
                textAlign="center"
                variant="body2"
              >
                {primaryParent}
              </Typography>
            </Box>
          </Box>
          <Box position="absolute" right="4%" top="8%">
            <PermissionGate>
              <CustomIconButton
                datacy="case-editPatient"
                icon={faEdit}
                onClick={onEditClick}
                size="small"
              />
            </PermissionGate>
          </Box>
          <Box
            height="152px"
            sx={{
              backgroundColor: (theme) => theme.palette.gray.lighter,
              borderRadius: '5px 5px 0px 0px',
            }}
          />

          <Avatar
            src={PatientPNG}
            sx={{
              position: 'absolute',
              top: '80%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              width: '110px',
              height: '110px',
              border: (theme) => `5px solid ${theme.palette.common.white}`,
            }}
          />
          <Box height="50px" />
        </Box>

        <Stack spacing={1} sx={{ padding: 2, paddingTop: 5 }}>
          <StackIconAndTypography
            icon={faMobileAlt}
            label={cellPhone ? formatPhone(cellPhone) : ''}
          />
          <StackIconAndTypography
            dataCy="case-patient-info-email"
            icon={faEnvelope}
            label={adaptEmail || 'N/A'}
          />
          <StackIconAndTypography
            icon={faBirthdayCake}
            label={
              ((data?.dob?.length || 0) > 8
                ? data?.dob
                : formatDate(data?.dob || '')) || 'N/A'
            }
          />
          {/* TODO: Add Payment Type */}
          {/* <StackIconAndTypography icon={faMoneyBill} label="N/A" /> */}
          {address?.city || address?.state || address?.zip ? (
            <StackIconAndTypography
              icon={faMapMarkerAlt}
              label={
                address && `${address.city}, ${address.state} ${address.zip}`
              }
            />
          ) : (
            <StackIconAndTypography icon={faMapMarkerAlt} label="N/A" />
          )}
          {data?.originalNote && (
            <StackIconAndTypographySeparateHover
              datacy="case-patient-info-clientNote"
              icon={faNoteSticky}
              iconLabel="Client Note"
              label={sliceText(data?.originalNote, 35) || ''}
              originalLabel={data?.originalNote}
            />
          )}
          {data?.note && (
            <StackIconAndTypographySeparateHover
              datacy="case-patient-info-adminNote"
              icon={faNoteSticky}
              iconLabel="Admin Note"
              label={sliceText(data?.note, 35) || ''}
              originalLabel={data?.note}
            />
          )}
        </Stack>
      </Paper>
    </Box>
  );
};

export default PatientInformation;
