import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { changeReferenceFile } from 'stores/cases';
import { useAppDispatch } from 'stores/hooks';

/**
 * Sets reference file if present in the link (normally used to highlight the file)
 */
export const useSetReferenceFileFromLink = () => {
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const fileIdFromEmail = searchParams.get('fileId');

  useEffect(() => {
    if (fileIdFromEmail) {
      dispatch(changeReferenceFile(fileIdFromEmail));
    }
  }, [dispatch, fileIdFromEmail]);
};
