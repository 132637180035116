import { EnrollmentCode } from 'enums/tenant-management/tenant';
import { ITenantSettingItem } from 'interfaces/tenant-management/tenant';
import {
  checkIsTenantSettingEnabled,
  checkIsTenantSettingRequired,
} from 'utils/tenantSetting';

import { useGetEnrollmentTenantSettings } from './useGetEnrollmentTenantSettings';

export const useCheckFormFieldSettings = () => {
  const genderSetting = useGetEnrollmentTenantSettings(EnrollmentCode.GENDER, {
    getAllFields: true,
  }) as ITenantSettingItem;
  const dobSetting = useGetEnrollmentTenantSettings(
    EnrollmentCode.DATE_OF_BIRTH,
    { getAllFields: true }
  ) as ITenantSettingItem;
  const sendTextNotificationSetting = useGetEnrollmentTenantSettings(
    EnrollmentCode.SEND_TEXT_NOTIFICATION,
    { getAllFields: true }
  ) as ITenantSettingItem;
  const addressSetting = useGetEnrollmentTenantSettings(
    EnrollmentCode.ADDRESS,
    { getAllFields: true }
  ) as ITenantSettingItem;
  const referredBySetting = useGetEnrollmentTenantSettings(
    EnrollmentCode.REFERRED_BY,
    { getAllFields: true }
  ) as ITenantSettingItem;
  const last4SSNSetting = useGetEnrollmentTenantSettings(
    EnrollmentCode.LAST_FOUR_SSN,
    { getAllFields: true }
  ) as ITenantSettingItem;
  const phoneSetting = useGetEnrollmentTenantSettings(EnrollmentCode.PHONE, {
    getAllFields: true,
  }) as ITenantSettingItem;
  const workPhoneSetting = useGetEnrollmentTenantSettings(
    EnrollmentCode.WORK_PHONE,
    {
      getAllFields: true,
    }
  ) as ITenantSettingItem;
  const homePhoneSetting = useGetEnrollmentTenantSettings(
    EnrollmentCode.HOME_PHONE,
    {
      getAllFields: true,
    }
  ) as ITenantSettingItem;
  const emailSettings = useGetEnrollmentTenantSettings(
    EnrollmentCode.ENROLLMENT_EMAIL,
    { getAllFields: true }
  ) as ITenantSettingItem;
  const bloodGroupSetting = useGetEnrollmentTenantSettings(
    EnrollmentCode.BLOOD_GROUP,
    { getAllFields: true }
  ) as ITenantSettingItem;
  const clientAffiliateSetting = useGetEnrollmentTenantSettings(
    EnrollmentCode.CLIENT_AFFILIATE,
    {
      getAllFields: true,
    }
  ) as ITenantSettingItem;
  const genderLabelSettings = useGetEnrollmentTenantSettings(
    EnrollmentCode.GENDER_LABEL,
    {
      getAllFields: true,
    }
  ) as ITenantSettingItem;
  const genderOptionSettings = useGetEnrollmentTenantSettings(
    EnrollmentCode.GENDER_OPTION,
    {
      getAllFields: true,
    }
  ) as ITenantSettingItem;
  const miniumAgeSettings = useGetEnrollmentTenantSettings(
    EnrollmentCode.MIN_AGE,
    {
      getAllFields: true,
    }
  ) as ITenantSettingItem;

  return {
    enabled: {
      gender: checkIsTenantSettingEnabled(genderSetting),
      dob: checkIsTenantSettingEnabled(dobSetting),
      textNotification: checkIsTenantSettingEnabled(
        sendTextNotificationSetting
      ),
      address: checkIsTenantSettingEnabled(addressSetting),
      referredBy: checkIsTenantSettingEnabled(referredBySetting),
      last4ssn: checkIsTenantSettingEnabled(last4SSNSetting),
      phone: checkIsTenantSettingEnabled(phoneSetting),
      workPhone: checkIsTenantSettingEnabled(workPhoneSetting),
      homePhone: checkIsTenantSettingEnabled(homePhoneSetting),
      email: checkIsTenantSettingEnabled(emailSettings),
      bloodGroup: checkIsTenantSettingEnabled(bloodGroupSetting),
      clientAffiliate: checkIsTenantSettingEnabled(clientAffiliateSetting),
      minimumAgeRequirement: checkIsTenantSettingEnabled(miniumAgeSettings),
    },
    required: {
      gender: checkIsTenantSettingRequired(genderSetting),
      dob: checkIsTenantSettingRequired(dobSetting),
      address: checkIsTenantSettingRequired(addressSetting),
      textNotification: checkIsTenantSettingRequired(
        sendTextNotificationSetting
      ),
      referredBy: checkIsTenantSettingRequired(referredBySetting),
      last4ssn: checkIsTenantSettingRequired(last4SSNSetting),
      phone: checkIsTenantSettingRequired(phoneSetting),
      workPhone: checkIsTenantSettingRequired(workPhoneSetting),
      homePhone: checkIsTenantSettingRequired(homePhoneSetting),
      email: checkIsTenantSettingRequired(emailSettings),
      bloodGroup: checkIsTenantSettingRequired(bloodGroupSetting),
      clientAffiliate: checkIsTenantSettingRequired(clientAffiliateSetting),
    },
    value: {
      genderLabel: genderLabelSettings?.value as string,
      genderOption: genderOptionSettings?.value as string[],
      minimumAge: (miniumAgeSettings?.value as number) || 18,
    },
  };
};
