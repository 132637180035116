import { FilterChip } from 'common';
import commonConstants from 'constants/common';
import { DictionaryKeys } from 'constants/languageDictionary';
import { Interval } from 'enums/common';
import { useTenantTerminology } from 'hooks/useTenantTerminology';

interface IProps {
  filters: [string, unknown][];
  onDelete: (label: string) => void;
}

const UserAppliedFilter = ({ filters, onDelete }: IProps): JSX.Element => {
  const patientTerminology = useTenantTerminology([
    DictionaryKeys.PATIENT_TERMINOLOGY,
  ])._PATIENT_TERMINOLOGY;

  return (
    <>
      {filters.map(([k, v]) => {
        // eslint-disable-next-line no-param-reassign
        if (v === Interval.WEEKLY) v = 'This Week';
        if (k !== commonConstants.IS_ADVANCED_SEARCH) {
          return (
            <FilterChip
              key={k}
              label={k === 'member' ? patientTerminology : k}
              onDelete={onDelete}
              value={v as string}
            />
          );
        }
        return '';
      })}
    </>
  );
};

export default UserAppliedFilter;
