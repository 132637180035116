import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { CaseConfigurationType, ReferralCaseStatus } from 'enums/case';
/* eslint-disable no-param-reassign */
import {
  IAdaptedReferralCase,
  ICaseConfiguration,
  ICloneCaseConfig,
  IIsEditModeDefaultValueProps,
  IReferralCaseAdvancedSearchForm,
  IReferralCaseList,
} from 'interfaces/cases';
import { IReferralCaseTimelog } from 'interfaces/cases/index';
import { IAdaptedClientEnrollmentMembership } from 'interfaces/client-management/enrollment';
import { IFilter } from 'interfaces/common';
import { IDocument } from 'interfaces/documents';
import { IAdaptedLocation } from 'interfaces/location';
import {
  IAdaptedNetworkOfferingsTableRow,
  INetworkOfferingsTableRow,
  INoteFiles,
} from 'interfaces/networks/network-offerings';
import { INetworkPhysicianTableRow } from 'interfaces/networks/network-physician';
import { RootState } from 'stores';
import { IOfferingsFilter } from 'stores/networks/network-offerings';

interface IProperties {
  height: number;
  width?: number;
}
interface ICaseProperties {
  banner: IProperties;
  additionalFields: IProperties;
}

const { DEFAULT_TABLE_FILTER, DEFAULT_AUTOCOMPLETE_RADIUS } = commonConstants;

const advancedSearchDefaultValues = {
  status: '',
  From: { id: '', name: '' },
  To: { id: '', name: '' },
  onlyMyCases: false,
  dob: '',
  tags: [],
};

const defaultPageNumber = 0;
interface CaseState {
  list: IReferralCaseList[];
  timelog: IReferralCaseTimelog[];
  selectedReferralCase: IAdaptedReferralCase | null;
  isPullRelevantSearch: boolean;
  isRelevantSearchEntered: boolean;
  isScrollingEnabled: boolean;
  filters: IFilter;
  advancedSearch: IReferralCaseAdvancedSearchForm;
  remainingTime: number;
  documents: IDocument[] | null;
  files: {
    step: number;
  };
  notes: {
    editMode: IIsEditModeDefaultValueProps;
    text: string;
  };
  isNoteBoxExpanded: boolean;
  isAdditionalCaseDetailFormOpen: boolean;
  referenceNote: string;
  referenceFile: string;
  referenceTask: string;
  selectedPhysician: INetworkPhysicianTableRow | null;
  selectedOffering: INetworkOfferingsTableRow | null;
  isLoadMore: boolean;
  selectedCaseIsFetching: boolean;
  scrollToTop: boolean;
  selectedCaseStatus: string;
  caseConfigurationMap: Record<
    CaseConfigurationType,
    ICaseConfiguration[]
  > | null;
  selectedProviderCode: string;
  noteFiles: INoteFiles[];
  currentPage: number;
  emptyCaseList: boolean;
  cloneConfig: ICloneCaseConfig;
  properties: ICaseProperties;
  travelDistance: number | string;
  preservedSearchedData: {
    keyword: string;
    location: IAdaptedLocation | string;
  };
  casePatientMembership: IAdaptedClientEnrollmentMembership[];
  manualSearchFilters: IOfferingsFilter;
  isManualSearched: boolean;
  manualSearchCount: number;
  providerLists: IAdaptedNetworkOfferingsTableRow[] | [];
  isManualSearchLoading: boolean;
  isCaseListingFilterExpanded: boolean;
  callInPerson: { [key: string]: string } | null;
  isFileNotSupported: boolean;
  isFileBeingRenamed: boolean;
}

const initialState: CaseState = {
  isPullRelevantSearch: false,
  isRelevantSearchEntered: false,
  isScrollingEnabled: false,
  list: [],
  timelog: [],
  selectedReferralCase: null,
  filters: { ...DEFAULT_TABLE_FILTER },
  advancedSearch: { ...advancedSearchDefaultValues },
  remainingTime: 0,
  documents: null,
  files: {
    step: 0,
  },
  notes: {
    editMode: { status: false, data: null },
    text: '',
  },
  isNoteBoxExpanded: false,
  isAdditionalCaseDetailFormOpen: true,
  referenceNote: '',
  referenceFile: '',
  referenceTask: '',
  selectedPhysician: null,
  selectedOffering: null,
  isLoadMore: false,
  selectedCaseIsFetching: false,
  scrollToTop: false,
  selectedCaseStatus: ReferralCaseStatus.REQUESTED.toUpperCase(),
  caseConfigurationMap: null,
  selectedProviderCode: '',
  noteFiles: [],
  currentPage: defaultPageNumber,
  emptyCaseList: false,
  cloneConfig: {
    notes: false,
    files: false,
  },
  properties: {
    banner: {
      height: 0,
      width: 0,
    },
    additionalFields: {
      height: 0,
      width: 0,
    },
  },
  travelDistance: DEFAULT_AUTOCOMPLETE_RADIUS,
  preservedSearchedData: { keyword: '', location: '' },
  casePatientMembership: [],
  manualSearchFilters: {
    ...DEFAULT_TABLE_FILTER,
    latitude: '',
    longitude: '',
    radius: commonConstants.DEFAULT_RADIUS,
    zipCode: '',
    manualSearch: true,
  },
  isManualSearched: false,
  manualSearchCount: 0,
  providerLists: [],
  isManualSearchLoading: false,
  isCaseListingFilterExpanded: true,
  callInPerson: null,
  isFileNotSupported: false,
  isFileBeingRenamed: false,
};

export const slice = createSlice({
  name: 'case',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (state, action: PayloadAction<Partial<IFilter>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },
    resetFiltersWithoutKeyword: (state) => {
      state.filters = {
        ...DEFAULT_TABLE_FILTER,
        keyword: state.filters.keyword,
      };
    },

    changeAdvancedSearchWithResetPagination: (
      state,
      action: PayloadAction<Partial<IReferralCaseAdvancedSearchForm>>
    ) => {
      state.filters = {
        ...state.filters,
        limit: DEFAULT_TABLE_FILTER.limit,
        offset: DEFAULT_TABLE_FILTER.offset,
      };
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },
    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<IReferralCaseAdvancedSearchForm>>
    ) => {
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },
    changeProperties: (
      state,
      action: PayloadAction<Partial<ICaseProperties>>
    ) => {
      state.properties = { ...state.properties, ...action.payload };
    },

    changeCloneActions: (
      state,
      action: PayloadAction<Partial<ICloneCaseConfig>>
    ) => {
      state.cloneConfig = { ...state.cloneConfig, ...action.payload };
    },

    resetAdvancedSearch: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
    },

    changeReferralCaseList: (state, action) => {
      state.list = action.payload;
    },
    changeCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    changeEmptyCaseList: (state, action) => {
      state.emptyCaseList = action.payload;
    },
    resetCurrentPage: (state) => {
      state.currentPage = defaultPageNumber;
    },
    updateReferralCaseList: (state, action) => {
      state.list = [...state.list, ...action.payload];
    },
    changeSelectedReferralCaseList: (state, action) => {
      state.selectedReferralCase = action.payload;
    },
    setSelectedPhysicianInCases: (
      state,
      action: PayloadAction<INetworkPhysicianTableRow>
    ) => {
      state.selectedPhysician = action.payload;
    },
    setSelectedOfferingInCases: (
      state,
      action: PayloadAction<INetworkOfferingsTableRow>
    ) => {
      state.selectedOffering = action.payload;
    },
    clearSelectedPhysicianInCases: (state) => {
      state.selectedPhysician = null;
    },
    clearSelectedOfferingInCases: (state) => {
      state.selectedOffering = null;
    },
    changeSelectedReferralCaseTimelog: (state, action) => {
      state.timelog = action.payload;
    },
    changePullRelevantSearch: (state, action) => {
      state.isPullRelevantSearch = action.payload;
    },
    changeIsRelevantSearchEntered: (state, action) => {
      state.isRelevantSearchEntered = action.payload;
    },
    changeIsScrollingEnabled: (state, action) => {
      state.isScrollingEnabled = action.payload;
    },
    changeRemainingTime: (state, action) => {
      state.remainingTime = action.payload;
    },
    changeFilesTabStep: (state, action) => {
      state.files.step = action.payload;
    },
    changeDocuments: (state, action) => {
      state.documents = action.payload;
    },
    changeCasePatientMembership: (state, action) => {
      state.casePatientMembership = action.payload;
    },
    changeReferenceNote: (state, action) => {
      state.referenceNote = action.payload;
    },
    changeReferenceFile: (state, action) => {
      state.referenceFile = action.payload;
    },
    changeReferenceTask: (state, action) => {
      state.referenceTask = action.payload;
    },
    changeLoadMore: (state, action) => {
      state.isLoadMore = action.payload;
    },
    changeSelectedCaseIsFetching: (state, action) => {
      state.selectedCaseIsFetching = action.payload;
    },
    changeNotes: (state, action) => {
      state.notes = {
        ...state.notes,
        ...action.payload,
      };
    },
    changeNoteBoxExpanded: (state, action) => {
      state.isNoteBoxExpanded = action.payload;
    },
    changeAdditionalCaseDetailFormOpen: (state, action) => {
      state.isAdditionalCaseDetailFormOpen = action.payload;
    },
    changeScrollToTop: (state, action) => {
      state.scrollToTop = action.payload;
    },
    setSelectedCaseStatus: (state, action: PayloadAction<string>) => {
      state.selectedCaseStatus = action.payload;
    },
    setSelectedContactVia: (state, action: PayloadAction<string>) => {
      if (state.selectedReferralCase) {
        state.selectedReferralCase.contactVia = action.payload;
      }
    },
    setSelectedMetaData: (
      state,
      action: PayloadAction<{ data: string | string[]; code: string }>
    ) => {
      if (state.selectedReferralCase) {
        state.selectedReferralCase.metaData = {
          ...state.selectedReferralCase.metaData,
          [action.payload.code]: action.payload.data,
        };
      }
    },
    setTravelDistance: (
      state,
      action: PayloadAction<{ data: string | number }>
    ) => {
      state.travelDistance = action.payload.data;
    },
    setSearchedPreservedData: (
      state,
      action: PayloadAction<{
        keyword: string;
        location: IAdaptedLocation | string;
      }>
    ) => {
      state.preservedSearchedData = {
        ...state.preservedSearchedData,
        location: action.payload.location,
        keyword: action.payload.keyword,
      };
    },
    changeProviderCode: (state, action) => {
      state.selectedProviderCode = action.payload;
    },
    setCaseConfigurationMap: (
      state,
      action: PayloadAction<ICaseConfiguration[]>
    ) => {
      const config: any = {};
      Object.values(CaseConfigurationType).forEach((type) => {
        config[type] = action.payload
          .filter((item) => item.type === type)
          ?.sort((a, b) => a.name?.localeCompare(b.name));
      });
      state.caseConfigurationMap = config;
    },
    changeNoteFile: (state, action) => {
      state.noteFiles = action.payload;
    },
    setManualSearchFilters: (
      state,
      action: PayloadAction<IOfferingsFilter>
    ) => {
      state.manualSearchFilters = action.payload;
    },
    setIsManualSearched: (state, action) => {
      state.isManualSearched = action.payload;
    },
    setManualCount: (state, action) => {
      state.manualSearchCount = action.payload;
    },
    setProviderLists: (state, action) => {
      state.providerLists = action.payload;
    },
    setManualSearchLoading: (state, action) => {
      state.isManualSearchLoading = action.payload;
    },
    resetManualState: (state) => {
      state.providerLists = [];
      state.isManualSearchLoading = false;
      state.manualSearchCount = 0;
      state.isManualSearched = false;
    },
    toggleIsCaseListingFilterExpanded: (state) => {
      state.isCaseListingFilterExpanded = !state.isCaseListingFilterExpanded;
    },
    setLatestCallInPerson: (state, action) => {
      state.callInPerson = action.payload;
    },
    setFileNotSupported: (state, action) => {
      state.isFileNotSupported = action.payload;
    },
    setFileBeingRenamed: (state, action) => {
      state.isFileBeingRenamed = action.payload;
    },
  },
});

// Actions
export const {
  changeIsRelevantSearchEntered,
  changeIsScrollingEnabled,
  changePullRelevantSearch,
  changeCasePatientMembership,
  changeProperties,
  changeCloneActions,
  changeScrollToTop,
  changeSelectedCaseIsFetching,
  changeReferralCaseList,
  changeSelectedReferralCaseList,
  changeSelectedReferralCaseTimelog,
  changeFilters,
  resetFiltersWithoutKeyword,
  changeAdvancedSearch,
  resetFilters,
  changeAdvancedSearchWithResetPagination,
  resetAdvancedSearch,
  updateReferralCaseList,
  changeRemainingTime,
  changeFilesTabStep,
  changeDocuments,
  changeNotes,
  changeNoteBoxExpanded,
  changeAdditionalCaseDetailFormOpen,
  changeReferenceNote,
  changeReferenceFile,
  changeReferenceTask,
  setSelectedPhysicianInCases,
  clearSelectedPhysicianInCases,
  clearSelectedOfferingInCases,
  changeLoadMore,
  setSelectedOfferingInCases,
  setSelectedCaseStatus,
  setCaseConfigurationMap,
  setSelectedContactVia,
  changeProviderCode,
  changeNoteFile,
  changeCurrentPage,
  resetCurrentPage,
  changeEmptyCaseList,
  setSelectedMetaData,
  setTravelDistance,
  setSearchedPreservedData,
  setManualCount,
  setManualSearchFilters,
  setIsManualSearched,
  setProviderLists,
  setManualSearchLoading,
  resetManualState,
  toggleIsCaseListingFilterExpanded,
  setLatestCallInPerson,
  setFileNotSupported,
  setFileBeingRenamed,
} = slice.actions;

// Selectors
export const selectCaseFilter = (state: RootState) => state.case.filters;
export const selectCaseMetaData = (state: RootState) =>
  state.case.selectedReferralCase?.metaData;
export const selectCaseAdvancedSearch = (state: RootState) =>
  state.case.advancedSearch;
export const selectCase = (state: RootState) => state.case;
export const selectCaseList = (state: RootState) => state.case.list;
export const selectCurrentReferralCase = (state: RootState) =>
  state.case.selectedReferralCase;
export const selectCurrentReferralCaseTimeLog = (state: RootState) =>
  state.case.timelog;
export const selectCurrentReferralCaseRemainingTime = (state: RootState) =>
  state.case.remainingTime;
export const selectCurrentReferralCaseFilesTabStep = (state: RootState) =>
  state.case.files.step;
export const selectCurrentReferralCaseDocuments = (state: RootState) =>
  state.case.documents;
export const selectCurrentReferralCaseNotes = (state: RootState) =>
  state.case.notes;
export const selectCurrentReferralCaseNoteBoxExpanded = (state: RootState) =>
  state.case.isNoteBoxExpanded;
export const selectAdditionalCaseDetailFormOpen = (state: RootState) =>
  state.case.isAdditionalCaseDetailFormOpen;
export const selectCurrentReferralCaseReference = (state: RootState) =>
  state.case.referenceNote;
export const selectCurrentReferralCaseReferenceFile = (state: RootState) =>
  state.case.referenceFile;
export const selectCurrentReferralCaseReferenceTask = (state: RootState) =>
  state.case.referenceTask;
export const selectSelectedPhysician = (state: RootState) =>
  state.case.selectedPhysician;
export const selectSelectedOffering = (state: RootState) =>
  state.case.selectedOffering;
export const selectLoadMore = (state: RootState) => state.case.isLoadMore;
export const selectScrollToTop = (state: RootState) => state.case.scrollToTop;
export const selectSelectedCaseIsFetching = (state: RootState) =>
  state.case.selectedCaseIsFetching;
export const selectSelectedCaseStatus = (state: RootState) =>
  state.case.selectedCaseStatus;
export const selectCaseConfigurationMap = (state: RootState) =>
  state.case.caseConfigurationMap;
export const selectProviderCode = (state: RootState) =>
  state.case.selectedProviderCode;
export const selectNoteFiles = (state: RootState) => state.case.noteFiles;
export const selectCurrentPage = (state: RootState) => state.case.currentPage;
export const selectEmptyCaseList = (state: RootState) =>
  state.case.emptyCaseList;
export const selectPullRelevantSearch = (state: RootState) =>
  state.case.isPullRelevantSearch;
export const selectIsRelevantSearchEntered = (state: RootState) =>
  state.case.isRelevantSearchEntered;
export const selectIsScrollingEnabled = (state: RootState) =>
  state.case.isScrollingEnabled;
export const selectCloneConfig = (state: RootState) => state.case.cloneConfig;
export const selectCasePatientMembership = (state: RootState) =>
  state.case.casePatientMembership;
export const selectCaseProperties = (state: RootState) => state.case.properties;
export const selectTravelDistance = (state: RootState) =>
  state.case.travelDistance;
export const selectSearchedPreservedData = (state: RootState) =>
  state.case.preservedSearchedData;
export const selectManualSearchFilters = (state: RootState) =>
  state.case.manualSearchFilters;
export const selectManualSearchCount = (state: RootState) =>
  state.case.manualSearchCount;
export const selectIsManualSearched = (state: RootState) =>
  state.case.isManualSearched;
export const selectProviderLists = (state: RootState) =>
  state.case.providerLists;
export const selectManualSearchLoading = (state: RootState) =>
  state.case.isManualSearchLoading;
export const selectIsCaseListingFilterExpanded = (state: RootState) =>
  state.case.isCaseListingFilterExpanded;
export const selectLatestCallInPerson = (state: RootState) =>
  state.case.callInPerson;
export const selectFileNotSupported = (state: RootState) =>
  state.case.isFileNotSupported;
export const selectFileBeingRenamed = (state: RootState) =>
  state.case.isFileBeingRenamed;

// Reducer
export default slice.reducer;
