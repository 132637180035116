import React from 'react';

import { MenuItem, TextField } from '@mui/material';
import { ManageMembershipStatus } from 'enums/client-management/manage-membership';
import {
  selectManageMembershipStatus,
  setManageMembershipStatus,
} from 'stores/client-management/manage-membership';
import { useAppDispatch, useAppSelector } from 'stores/hooks';

export const SelectMembershipOptions = () => {
  const dispatch = useAppDispatch();

  const manageMembershipStatus = useAppSelector(selectManageMembershipStatus);
  const setSelectedMembership = (e: ManageMembershipStatus) => {
    dispatch(setManageMembershipStatus(e));
  };

  return (
    <TextField
      data-cy="client-manageMembership-membership-options"
      fullWidth
      onChange={(e) =>
        setSelectedMembership(e.target.value as ManageMembershipStatus)
      }
      select
      size="small"
      value={manageMembershipStatus}
      variant="standard"
    >
      <MenuItem
        data-cy={`client-manageMembership-membership-options-${ManageMembershipStatus.PUBLIC}`}
        value={ManageMembershipStatus.PUBLIC}
      >
        {ManageMembershipStatus.PUBLIC}
      </MenuItem>
      <MenuItem
        data-cy={`client-manageMembership-membership-options-${ManageMembershipStatus.PRIVATE}`}
        value={ManageMembershipStatus.PRIVATE}
      >
        {ManageMembershipStatus.PRIVATE}
      </MenuItem>
    </TextField>
  );
};
