import { useEffect } from 'react';

import { TenantOfferingCategoryFull } from 'enums/settings/tenant-offerings';
import { IAdaptedClient } from 'interfaces/client-management';
import { useClientDetailQuery } from 'services/client-management';
import {
  selectManageMembershipClient,
  selectManageMembershipTypeGroupDetail,
  setManageMembershipTypeGroupDetail,
} from 'stores/client-management/manage-membership';
import { useAppDispatch, useAppSelector } from 'stores/hooks';

import { useGetCurrentSelectedClientAndMembershipDetails } from './useGetCurrentSelectedClientAndMembershipDetails';
import { useGetManageMembershipActionType } from './useGetManageMembershipActionType';

export const useUpdateGroupDetails = () => {
  const dispatch = useAppDispatch();

  const clientData = useAppSelector(selectManageMembershipClient) || null;

  const { selectedOffering, membership } =
    useGetCurrentSelectedClientAndMembershipDetails();
  const selectedGroupDetail = useAppSelector(
    selectManageMembershipTypeGroupDetail
  );

  const { isEditMembership } = useGetManageMembershipActionType();

  const isClientGroupMember = !!membership?.groupId;

  const { data: groupDetail } = useClientDetailQuery(
    (membership?.groupId as string)!,
    {
      enabled: !!isClientGroupMember,
    }
  );

  useEffect(() => {
    if (
      groupDetail &&
      !selectedGroupDetail &&
      isClientGroupMember &&
      clientData &&
      selectedOffering?.category === TenantOfferingCategoryFull.GROUP &&
      isEditMembership
    ) {
      dispatch(
        setManageMembershipTypeGroupDetail(groupDetail as IAdaptedClient)
      );
    }
  }, [
    clientData,
    dispatch,
    groupDetail,
    isClientGroupMember,
    isEditMembership,
    selectedGroupDetail,
    selectedOffering?.category,
  ]);

  useEffect(() => {
    if (clientData && groupDetail && isClientGroupMember && isEditMembership) {
      dispatch(
        setManageMembershipTypeGroupDetail(groupDetail as IAdaptedClient)
      );
    }
  }, [
    clientData,
    dispatch,
    groupDetail,
    isClientGroupMember,
    isEditMembership,
  ]);
};
