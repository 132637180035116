import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

interface IProps {
  text: string;
  isIcon?: boolean;
  isTooltip?: boolean;
  label?: string;
}

export const CopyText = ({ text, isIcon, label, isTooltip }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const onCopyClick = () => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar('Copied to clipboard!', {
      variant: 'success',
    });
  };

  return (
    <Box paddingTop={0.5}>
      <Tooltip arrow open={isTooltip} placement="top" title="Copy to clipboard">
        {isIcon ? (
          <IconButton
            aria-label="Copy to clipboard"
            onClick={onCopyClick}
            type="button"
          >
            <FontAwesomeIcon icon={faCopy} size="sm" />
          </IconButton>
        ) : (
          <Typography
            color={(t) => t.palette.primary.main}
            gutterBottom={false}
            onClick={onCopyClick}
            sx={{ cursor: 'pointer' }}
            variant="caption"
          >
            {label}
          </Typography>
        )}
      </Tooltip>
    </Box>
  );
};

CopyText.defaultProps = {
  isIcon: false,
  isTooltip: false,
  label: 'Copy my member referral link',
};
