import UserType from 'enums/User';

export enum ManageMembershipActionType {
  APPROVE = 'APPROVE',
  DECLINE = 'DECLINE',
  CHANGE = 'CHANGE',
  CANCEL = 'CANCEL',
  COMPLETE = 'COMPLETE',
  ADD_NEW_MEMBERSHIP = 'ADD_NEW_MEMBERSHIP',
  EDIT_MEMBERSHIP = 'EDIT_MEMBERSHIP',
}

export enum ManageMembershipActionStep {
  INITIAL = 'INITIAL',
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  THIRD = 'THIRD',
  FINAL = 'FINAL',
}
export enum ManageMembershipType {
  INDIVIDUAL = UserType.INDIVIDUAL,
  GROUP = UserType.GROUP,
}
export enum ManageMembershipStatus {
  PRIVATE = 'Private',
  PUBLIC = 'Public',
}
