import React from 'react';
import NumberFormat from 'react-number-format';

import { TextField, TextFieldProps } from '@mui/material';

interface ICustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const CustomNumberFormat = React.forwardRef<NumberFormat<any>, ICustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        allowNegative={false}
        getInputRef={ref}
        isNumericString
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        prefix="$"
        thousandSeparator
      />
    );
  }
);

type Props = {
  name?: string;
  label?: string;
  isRequired?: boolean;
  showHelperText?: boolean;
  fullWidth?: boolean;
} & TextFieldProps;

const MaskedCurrencyInput = ({
  label,
  isRequired,
  showHelperText,
  fullWidth,
  ...others
}: Props) => (
  <TextField
    {...others}
    fullWidth={fullWidth}
    id="masked-price"
    InputProps={{
      inputComponent: CustomNumberFormat as any,
      inputProps: { min: 0, ...others.InputProps?.inputProps },
    }}
    label={isRequired ? `${label} *` : label}
    variant="standard"
  />
);

MaskedCurrencyInput.defaultProps = {
  name: 'price',
  label: 'Price',
  isRequired: false,
  showHelperText: false,
  fullWidth: true,
};

export default MaskedCurrencyInput;
