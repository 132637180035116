import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import commonConstants from 'constants/common';

const { STATUS } = commonConstants;

interface IProps {
  onChange: (value: string | boolean) => void;
  disabled?: boolean;
  returnBooleanValue?: boolean;
}

const ActiveInactiveSelect = ({
  onChange,
  disabled,
  returnBooleanValue,
}: IProps) => {
  const [searchParams] = useSearchParams();
  const defaultStatusFilterFromParams = searchParams.get('defaultStatusFilter');

  const [value, setValue] = useState(STATUS[0]);

  const onChangeRef = useRef(onChange);

  /**
   * Set default active/inactive filter from search param
   */
  useEffect(() => {
    if (defaultStatusFilterFromParams) {
      setValue(defaultStatusFilterFromParams);
      onChangeRef.current(defaultStatusFilterFromParams === STATUS[0]);
    }
  }, [defaultStatusFilterFromParams]);

  const handleChange = (e: SelectChangeEvent<string>) => {
    setValue(e.target.value);
    let result;
    if (returnBooleanValue) {
      result = e.target.value === STATUS[0];
    } else {
      result = e.target.value;
    }
    return onChange(result);
  };
  return (
    <Box alignItems="center" display="inline-flex">
      <FormControl
        className="select-text-variant"
        size="small"
        sx={{
          '& .MuiInputBase-root': {
            width: `100px !important`,
          },
        }}
      >
        <Select
          data-cy="client-expand-status-select"
          disabled={disabled}
          id="status-select"
          labelId="status-select-label"
          onChange={handleChange}
          value={value}
        >
          {STATUS.map((item) => (
            <MenuItem data-cy={`client-expand-${item}`} key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

ActiveInactiveSelect.defaultProps = {
  returnBooleanValue: false,
  disabled: false,
};

export default ActiveInactiveSelect;
