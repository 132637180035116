import { useEffect } from 'react';

import { setMenus, setTenantData } from 'stores/auth';
import { useAppDispatch } from 'stores/hooks';
import { getLocal, getSession } from 'utils/storage';

const MenuBuilder = () => {
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  // const location = useLocation();

  useEffect(() => {
    const getMenusFunction = async () => {
      const menuItems = getLocal('menus', true);
      const tenantData = getSession('jwtToken') || getLocal('jwtToken');
      if (menuItems) dispatch(setMenus(menuItems));
      if (tenantData) dispatch(setTenantData(tenantData));
    };

    getMenusFunction();
    // Why is this used here? Commented for now as it's giving error on page reload on modal routes
    // navigate(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default MenuBuilder;
