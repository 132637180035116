import { Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import { useCheckCaseReadOnlyView } from 'features/case-layout-new/hooks/useCheckCaseReadOnlyView';

import RequestedFrom from './RequestedFrom';
import RequestedTo from './RequestedTo';

const RequestedPanel = () => {
  const theme = useTheme();
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { isOwnerTenantAdmin, isAdminOfPartnerInvitee } =
    useCheckCaseReadOnlyView({
      includePatientCheck: true,
    });

  const isEnabled = isAdminOfPartnerInvitee || isOwnerTenantAdmin;

  return (
    <Paper className="bordered no-box-shadow">
      <Grid
        container
        padding={isSmallerThanSm ? 2 : 3}
        spacing={isSmallerThanSm ? 2 : 0}
        sx={{ background: (t) => t.palette.gray.lighter }}
      >
        <Grid item md={6} sm={10}>
          <RequestedFrom isDisabled={!isEnabled} />
        </Grid>
        <Grid item md={6} sm={10}>
          <RequestedTo isDisabled={!isEnabled} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RequestedPanel;
