import { useMemo } from 'react';

import commonConstants from 'constants/common';
import { PaymentMethod, PaymentSourceStatus } from 'enums/Payment';
import { usePaymentSourceQuery } from 'services/payment';
import { selectAuthUserClient } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

export const useCheckIsPrimaryCardOrBankPresent = () => {
  const authUserClient = useAppSelector(selectAuthUserClient);
  const clientId = authUserClient?.clientId;
  const tenantId = authUserClient?.tenantId;

  const paymentSourceQuery = usePaymentSourceQuery(
    {
      limit: commonConstants.PAGINATION.MAXIMUM_LIMIT,
      offset: commonConstants.PAGINATION.MINIMUM_OFFSET,
      clientId,
      tenantId,
    },
    {
      enabled: !!clientId && !!tenantId,
    }
  );

  const paymentSourceExists = useMemo(() => {
    if (paymentSourceQuery.data) {
      const primarySourceExists = paymentSourceQuery.data?.rows?.some(
        (item) =>
          item.status === PaymentSourceStatus.PRIMARY &&
          (item.paymentMode === PaymentMethod.CARD ||
            item.paymentMode === PaymentMethod.BANK)
      );

      return primarySourceExists;
    }

    return false;
  }, [paymentSourceQuery]);

  return paymentSourceExists;
};
