import PerfectScrollbar from 'react-perfect-scrollbar';

import { DictionaryKeys } from 'constants/languageDictionary';
import { useCheckTimeLogModuleIsAccessible } from 'hooks/useCheckTimeLogModuleIsAccessible';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { useTenantTerminology } from 'hooks/useTenantTerminology';
import { IPermission } from 'interfaces/common';
import { selectActiveMenu, selectMenu } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { getModifiedPermissionWithPatientTerminology } from 'utils/auth';

import SidebarMenu from './SidebarMenu';

const SidebarMenuList = () => {
  const activeMenu = useAppSelector(selectActiveMenu);
  const allMenus = useAppSelector(selectMenu);

  const isUserClient = useCheckUserIsClient();
  const patientTerminology = useTenantTerminology([
    DictionaryKeys.PATIENT_TERMINOLOGY,
  ])._PATIENT_TERMINOLOGY;

  const { isTimeSheetModuleAccessible } = useCheckTimeLogModuleIsAccessible();

  let menus = getModifiedPermissionWithPatientTerminology({
    allMenus,
    patientTerminology,
  }) as IPermission[];

  /**
   * Remove 'Dashboard' menu for non-client users
   *
   */
  if (!isUserClient) {
    menus = menus.filter((item) => item.code !== 'DASHBOARD');
  }

  if (!isTimeSheetModuleAccessible) {
    menus = menus.filter((item) => item.code !== 'TIMESHEET');
  }

  return (
    <PerfectScrollbar>
      {menus?.map((menu: IPermission) => (
        <SidebarMenu
          isActive={activeMenu?.resourceId === menu.resourceId}
          key={menu.resourceId}
          menu={menu as IPermission}
        />
      ))}
    </PerfectScrollbar>
  );
};

export default SidebarMenuList;
