import React, { useRef, useState } from 'react';

import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Typography } from '@mui/material';
import { LoadingIndicator } from 'common';
import { getHtmlSnapShot } from 'utils/html-to-image';

import MembershipIdCard from './MembershipIDCardDetails';

interface IProps {
  clientId: string;
  clientName: string;
  label?: string;
  removeDownloadOption?: boolean;
}
const MembershipIDCard = ({
  clientId,
  clientName,
  label,
  removeDownloadOption,
}: IProps) => {
  const ref = useRef(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadImage = () => {
    if (isDownloading && !clientId) return;
    setIsDownloading(true);
    getHtmlSnapShot(ref, `my-card-${clientName}` || '', setIsDownloading);
  };

  return (
    <Box>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mb={2}
      >
        <Box
          sx={removeDownloadOption ? { marginTop: 1, paddingBottom: 1 } : {}}
        >
          <Typography fontWeight="medium" gutterBottom={false} variant="body1">
            {label}
          </Typography>
        </Box>
        {!removeDownloadOption && (
          <Box>
            <IconButton disabled={isDownloading} onClick={handleDownloadImage}>
              {isDownloading ? (
                <Box alignItems="center" display="flex" justifyContent="center">
                  <LoadingIndicator containerHeight="15px" size="1rem" />
                </Box>
              ) : (
                <Box
                  color="primary.main"
                  component={FontAwesomeIcon}
                  icon={faDownload}
                />
              )}
            </IconButton>
          </Box>
        )}
      </Box>
      <Box ref={ref}>
        <MembershipIdCard clientId={clientId} />
      </Box>
    </Box>
  );
};
MembershipIDCard.defaultProps = {
  label: 'My Card',
  removeDownloadOption: false,
};
export default MembershipIDCard;
