import { useReferralCaseListInfiniteQuery } from 'services/cases';

import { useRelevantCombinedFilter } from './useRelevantCombinedFilter';
import useShowRelevantSearch from './useShowRelevantSearch';

export const useFetchRelevantReferralCaseInfiniteList = () => {
  const { isRelevantCaseEnabled, isPullRelevantSearch } =
    useShowRelevantSearch();

  const { combinedFilters } = useRelevantCombinedFilter();

  const referralCaseListQuery = useReferralCaseListInfiniteQuery(
    combinedFilters,
    {
      enabled:
        !!combinedFilters.keyword &&
        (isRelevantCaseEnabled || isPullRelevantSearch),
    }
  );

  return referralCaseListQuery;
};
