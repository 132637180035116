import React from 'react';

import { Typography } from '@mui/material';
import FormBanner from 'common/form/FormBanner';
import { selectManageMembershipClient } from 'stores/client-management/manage-membership';
import { useAppSelector } from 'stores/hooks';

export const TopInformationView = () => {
  const clientData = useAppSelector(selectManageMembershipClient) || null;

  return (
    <FormBanner
      description={`Please select a new membership and start date
from below:`}
      title={
        <Typography
          color="gray.darker"
          fontWeight="medium"
          gutterBottom={false}
          variant="body1"
        >
          You are adding a new membership for{' '}
          {clientData?.fullName
            ? clientData?.fullName
            : clientData?.businessName}
          .
        </Typography>
      }
    />
  );
};
