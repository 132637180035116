import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { checkResourceAccess } from 'api/resource';
import { switchTenant } from 'api/tenant-management/tenant';
import NoteLayout from 'common/layouts/email-notes';
import LoadingIndicator from 'common/LoadingIndicator';
import uiRoutes from 'constants/uiRoutes';
import { useGetParamsForTenantSwitch } from 'hooks/useGetParamsForTenantSwitch';
import { useGetTenantAssociationOfLoggedInUser } from 'hooks/useGetTenantAssociationOfLoggedInUser';
import { IEmailToken } from 'interfaces/SharedResourceAuthenticator';
import { useDecodeOTP } from 'services/otp';
import { useCheckResourceAccessMutation } from 'services/resource';
import { useAppDispatch } from 'stores/hooks';
import { setAuthState } from 'utils/auth';
import {
  clearLocal,
  clearSession,
  getSession,
  setSession,
} from 'utils/storage';

import { OTPVerification } from './OTPVerification';
import { UnauthorizedResourceAccessDisplay } from './UnauthorizedResourceAccessDisplay';
import { useGetResourceLink } from './useGetResourceLink';
import { useHandleResourceOTPVerification } from './useHandleResourceOTPVerification';

export const SharedResourceAuthenticator = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const [emailToken, setEmailToken] = useState<IEmailToken | null>(null);
  const [hasAccess, setHasAccess] = useState<boolean>(false);
  const [doesLoggedInUserHaveAccess, setDoesLoggedInUserHaveAccess] = useState<
    'NO' | null
  >(null);
  const [isLoadingForLogin, setIsLoadingForLogin] = useState(true);
  // const [isLoadingForNonLogin, setIsLoadingForNonLogin] = useState(false);
  /**
   * Note: isLoadingForNonLogin is set in session as a wordaround because the component gets unmounted
   * after verifying the OTP (need to figure out why), resulting in resetting of the state which results
   * in briefly showing the OTP verification screen instead of a loader.
   */
  const isLoadingInSession =
    getSession('isLoadingForShareResourceAuthenticator') || false;

  const { mutate: decodeOTPMutate } = useDecodeOTP();
  const { handleOtpVerificationSuccess } = useHandleResourceOTPVerification({
    emailToken,
  });
  const { getResourceLink } = useGetResourceLink({ emailToken: emailToken! });
  const { getParamsForTenantSwitch } = useGetParamsForTenantSwitch();

  const getParamsForTenantSwitchRef = useRef(getParamsForTenantSwitch);

  const {
    mutate: checkResourceAccessMutate,
    isLoading: isCheckResourceAccessMutationLoading,
    isSuccess: isCheckResourceAccessMutationSuccess,
  } = useCheckResourceAccessMutation();

  const {
    isLoggedIn,
    authUser,
    activeAssociation,
    associations,
    isLoading: isUserDetailFetching,
  } = useGetTenantAssociationOfLoggedInUser();

  /**
   * Decodes the token included in the query param
   */
  useEffect(() => {
    if (!token || emailToken) return;

    decodeOTPMutate(token, {
      onSuccess: (res) => {
        const decodedData: IEmailToken = res.data;
        setEmailToken(decodedData);
      },
    });
  }, [token, decodeOTPMutate, isLoggedIn, emailToken]);

  const doesLoggedInUserHasAccessToTenant = useMemo(() => {
    if (!isLoggedIn || !emailToken) return false;

    return associations?.some(
      (item) => item.tenantId === emailToken.receiver.tenantId
    );
  }, [isLoggedIn, emailToken, associations]);

  /**
   * if there is a logged-in user present
   * 1. switch to the correct tenant if required
   * 2. check access
   *      a. hasAccess = true -> navigate to the desired route
   *      b. hasAccess = false -> show OTP screen
   *
   */
  useEffect(() => {
    const verifyAccessForLoggedInUser = async () => {
      try {
        setIsLoadingForLogin(true);

        const checkAccessResponse = await checkResourceAccess(emailToken!);
        if (checkAccessResponse.data.hasAccess) {
          if (emailToken?.receiver.tenantId !== activeAssociation?.tenantId) {
            const newTenantId = emailToken!.receiver.tenantId;
            const mutationParams = getParamsForTenantSwitchRef.current({
              newTenantId,
            });
            const switchTenantResponse = await switchTenant(
              authUser!.userId,
              newTenantId,
              mutationParams
            );
            clearLocal();
            clearSession();

            setAuthState({
              token: switchTenantResponse.data.token,
              permissions: switchTenantResponse.data.permissions,
              dispatch,
            });
          }

          const { loginViewRedirectURL } = getResourceLink();
          navigate(loginViewRedirectURL);
          window.location.reload();
        } else {
          setDoesLoggedInUserHaveAccess('NO');
        }
      } finally {
        setIsLoadingForLogin(false);
      }
    };

    if (
      emailToken &&
      activeAssociation &&
      associations &&
      doesLoggedInUserHasAccessToTenant &&
      authUser &&
      doesLoggedInUserHaveAccess !== 'NO' &&
      isLoadingInSession !== 'YES'
    ) {
      verifyAccessForLoggedInUser();
    } else {
      setIsLoadingForLogin(false);
    }
  }, [
    emailToken,
    activeAssociation,
    associations,
    doesLoggedInUserHasAccessToTenant,
    doesLoggedInUserHaveAccess,
    authUser,
    getResourceLink,
    navigate,
    dispatch,
    isLoadingInSession,
  ]);

  const onOtpVerificationSuccess = async () => {
    setSession('isLoadingForShareResourceAuthenticator', 'YES');

    await handleOtpVerificationSuccess();

    checkResourceAccessMutate(
      { data: emailToken! },
      {
        onSuccess: (res) => {
          setHasAccess(res.data.hasAccess);
          if (res.data.hasAccess) {
            const link = getResourceLink();
            setSession('isLoadingForShareResourceAuthenticator', 'NO');
            navigate(link.minimalViewRedirectURL);
            window.location.reload();
          } else {
            setSession('isLoadingForShareResourceAuthenticator', 'NO');
            navigate(uiRoutes.unauthorized);
          }
        },
      }
    );
  };

  const navigateViaLogin = () => {
    const { loginViewRedirectURL } = getResourceLink();
    navigate(`/${uiRoutes.auth.login}?returnUrl=${loginViewRedirectURL}`);
  };

  if (
    isCheckResourceAccessMutationLoading ||
    isLoadingForLogin ||
    isLoadingInSession === 'YES' ||
    isUserDetailFetching
  ) {
    return <LoadingIndicator containerHeight="100vh" />;
  }

  if (!hasAccess && isCheckResourceAccessMutationSuccess) {
    return (
      <NoteLayout>
        <UnauthorizedResourceAccessDisplay />
      </NoteLayout>
    );
  }

  if (
    emailToken &&
    token &&
    (!doesLoggedInUserHasAccessToTenant ||
      (doesLoggedInUserHasAccessToTenant &&
        doesLoggedInUserHaveAccess === 'NO'))
  ) {
    return (
      <NoteLayout>
        <OTPVerification
          emailToken={emailToken}
          encryptedEmailToken={token}
          handleOtpVerificationSuccess={onOtpVerificationSuccess}
          navigateViaLogin={navigateViaLogin}
        />
      </NoteLayout>
    );
  }

  return <LoadingIndicator containerHeight="100vh" />;
};
