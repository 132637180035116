import { ReferralCaseShareReferenceType } from 'enums/case';
import { ReferenceType } from 'enums/common';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { useCheckUserIsEmail } from 'hooks/useCheckUserisEmail';
import { useCheckUserIsTenantAdmin } from 'hooks/useCheckUserIsTenantAdmin';
import { selectActiveTenant } from 'stores/auth';
import { selectCurrentReferralCase } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';

interface IProps {
  includePatientCheck: boolean;
}

export const useCheckCaseReadOnlyView = ({ includePatientCheck }: IProps) => {
  const isInvitedThirdPartyUser = useCheckUserIsEmail(); // if true => case invited to third party user
  const isTenantAdmin = useCheckUserIsTenantAdmin();

  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const activeTenant = useAppSelector(selectActiveTenant);

  const isUserClient = useCheckUserIsClient();

  const checkIfUserIsAdminOfInvitedPartner = () => {
    if (!currentReferralCase || !activeTenant) {
      return false;
    }

    if (
      currentReferralCase.access?.find(
        (item) =>
          item.referenceType === ReferenceType.TENANT &&
          item.referenceId === activeTenant.tenantId &&
          !item.revoked
      )?.sharedBy?.id
    ) {
      return true;
    }

    return false;
  };

  const checkOwnerTenant = () => {
    if (!currentReferralCase || !activeTenant) {
      return false;
    }

    const isOwnerTenantAccess = currentReferralCase.access?.find(
      (item) =>
        item.referenceType === ReferenceType.TENANT &&
        item.referenceId === activeTenant.tenantId &&
        item.type === ReferralCaseShareReferenceType.FULL
    );
    const isOwnerTenant =
      isOwnerTenantAccess && !isOwnerTenantAccess?.sharedBy?.id;

    if (isOwnerTenant) return true;
    return false;
  };

  const checkIsReadOnly = () => {
    const isInvitee =
      isInvitedThirdPartyUser || checkIfUserIsAdminOfInvitedPartner();

    if (isUserClient && includePatientCheck) {
      return isInvitee || isUserClient;
    }

    return isInvitee;
  };

  return {
    isUserClient,
    isReadOnly: checkIsReadOnly(),
    isInvitedThirdPartyUser,
    ownerTenant: checkOwnerTenant(),
    isOwnerTenantAdmin: isTenantAdmin && checkOwnerTenant(),
    isAdminOfPartnerInvitee: checkIfUserIsAdminOfInvitedPartner(),
  };
};
