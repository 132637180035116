import { IAdaptedAssociation } from 'interfaces/tenant-management/user';
import { selectAuthTenantData } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

// Check user is superAdmin from client association
export const useCheckIsSuperAdmin = (association: IAdaptedAssociation[]) => {
  const tenantId =
    useAppSelector(selectAuthTenantData)?.tenantAssociation?.tenantId;

  const isSuperAdmin = association?.find(
    (item) => item.referenceId === tenantId
  )?.isSuperAdmin;
  return isSuperAdmin || false;
};
