import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { SelectMembershipOptions } from './SelectMembershipOptions';

export const ManageMembershipVisibilityOptions = () => (
  <Grid container display="flex" justifyContent="space-between" spacing={2}>
    <Grid item xs={8}>
      <Box alignItems="center" display="flex" height="100%" width="100%">
        <Typography fontWeight="medium" gutterBottom={false} variant="body2">
          Membership Options
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={4}>
      <Grid
        alignItems="center"
        container
        display="flex"
        justifyContent="space-between"
      >
        <Grid item sx={{ textAlign: 'right', paddingRight: '0.5rem' }} xs={3}>
          <Typography variant="caption">Show</Typography>
        </Grid>
        <Grid item xs={9}>
          <SelectMembershipOptions />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);
