import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import uiRoutes from 'constants/uiRoutes';
import { getLocal } from 'utils/storage';

const AuthChecker = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const code = searchParams.get('code');
  const isUserLoggedIn = getLocal('jwtToken');
  const otpSetOnLocal = getLocal('otpVerified');

  const isOPTMatches = () => code === otpSetOnLocal;

  const authenticationRoute = `${uiRoutes.authenticate}?code=${code}&returnUrl=${pathname}`;

  if (isUserLoggedIn && isOPTMatches()) return <Outlet />;

  return <Navigate to={authenticationRoute} />;
};

export default AuthChecker;
