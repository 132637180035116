import React from 'react';

import { Box } from '@mui/material';

const LoadingDots = ({ padding, pl }: { padding?: number; pl?: number }) => (
  <Box
    display="flex"
    justifyContent="center"
    padding={padding}
    paddingLeft={pl}
  >
    <Box className="dot-flashing" />
  </Box>
);
LoadingDots.defaultProps = {
  padding: 2,
  pl: 2,
};

export default LoadingDots;
