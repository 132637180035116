import { ManageMembershipType } from 'enums/client-management/manage-membership';
import { selectManageMembershipType } from 'stores/client-management/manage-membership';
import { useAppSelector } from 'stores/hooks';

export const useGetManageMembershipType = () => {
  const manageMembershipType = useAppSelector(selectManageMembershipType);

  const isStatusIndividual =
    manageMembershipType === ManageMembershipType.INDIVIDUAL;

  const isStatusGroup = manageMembershipType === ManageMembershipType.GROUP;

  return {
    isStatusIndividual,
    isStatusGroup,
  };
};
