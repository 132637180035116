import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Drawer, Link, useMediaQuery, useTheme } from '@mui/material';
import VitafyLogo from 'assets/images/svg/vitafy-logo-partial.svg';
import { Logo, SidebarMenuList } from 'common';
import uiRoutes from 'constants/uiRoutes';
import { useCheckUserIsEmail } from 'hooks/useCheckUserisEmail';
import { deselectActiveMenu, selectAuthTenantAssociation } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

const Sidebar = () => {
  const authTenantAssociationData = useAppSelector(selectAuthTenantAssociation);
  const dispatch = useDispatch();
  const logo =
    authTenantAssociationData?.metaData?.tenantLogoUrl ||
    authTenantAssociationData?.metaData?.logoUrl;
  const theme = useTheme();
  const smBreakPoint = useMediaQuery(theme.breakpoints.down('sm'));
  const isUserEmail = useCheckUserIsEmail();

  return (
    <Box
      className={
        smBreakPoint && isUserEmail ? 'sidebar-mobile-responsive' : 'sidebar'
      }
    >
      <Drawer
        open
        PaperProps={{
          sx: {
            width: '100px',
            bgcolor: 'gray.lighter',
            border: 'none',
          },
        }}
        variant="persistent"
      >
        <Box
          alignItems="center"
          display="flex"
          flexShrink={0}
          height={80}
          justifyContent="center"
          mb={1}
          mt={1}
        >
          <Link
            component={RouterLink}
            onClick={() => {
              dispatch(deselectActiveMenu());
            }}
            sx={{
              width: '70%',
              height: '70%',
            }}
            to={uiRoutes.index}
          >
            <Logo
              alt={`${authTenantAssociationData?.displayName}-logo`}
              fallbackImg={VitafyLogo}
              src={logo || VitafyLogo}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          </Link>
        </Box>
        <SidebarMenuList />
      </Drawer>
    </Box>
  );
};

export default Sidebar;
