import { FilterChip } from 'common';
import { IFetchStatusRow } from 'interfaces/status';

interface IProps {
  filters: [string, any][];
  onDelete: (label: string) => void;
  status?: IFetchStatusRow[];
}

const AppliedFilter = ({ filters, onDelete, status }: IProps): JSX.Element => {
  const getStatusName = (value: string) => {
    const data = status?.find((item) => item.code === value);
    return data?.name || '';
  };
  return (
    <>
      {filters.map(([k, v]) => {
        if (k === 'status') {
          // eslint-disable-next-line no-param-reassign
          v = getStatusName(v);
        }
        const adaptLabel = (label: string) => {
          if (label === 'requestedBy') {
            return 'Created by';
          }
          if (label === 'requestedTo') {
            return 'Assigned to';
          }
          return label;
        };

        return (
          <FilterChip
            key={k}
            label={adaptLabel(k)}
            onDelete={onDelete}
            value={(v?.name || (typeof v === 'string' && v) || '') as string}
          />
        );
      })}
    </>
  );
};

AppliedFilter.defaultProps = {
  status: null,
};

export default AppliedFilter;
