import { useMemo } from 'react';

import { BillingInterval } from 'enums/settings/tenant-offerings';
import { selectManageMembershipSelectedMembership } from 'stores/client-management/manage-membership';
import { useAppSelector } from 'stores/hooks';
import {
  getNextBillingDate,
  getNextBillingDateSelectOptions,
} from 'utils/client-management/enrollment';

export const useGetEndDateOptions = () => {
  const { details: membership } = useAppSelector(
    selectManageMembershipSelectedMembership
  );

  const endDateOptions = useMemo(() => {
    const billingInterval = membership?.offerings?.billingInterval;
    const startDate = membership?.startDate;

    // Dropdown option is not shown if the billing interval is 'One Time'
    if (billingInterval === BillingInterval.ONE_TIME) {
      return [];
    }

    if (!(billingInterval && startDate)) return [];

    const nextBillingDate = getNextBillingDate(
      startDate,
      billingInterval as BillingInterval
    );

    return getNextBillingDateSelectOptions({
      billingInterval: billingInterval as BillingInterval,
      nextBillingDate: nextBillingDate as Date | string,
      benefitStartDate: startDate,
    });
  }, [membership?.offerings?.billingInterval, membership?.startDate]);

  return endDateOptions;
};
