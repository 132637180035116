import uiRoutes from 'constants/uiRoutes';
import { EmailType } from 'enums/SharedResourceAuthenticator';
import { IEmailToken } from 'interfaces/SharedResourceAuthenticator';

export const useGetResourceLink = ({
  emailToken,
}: {
  emailToken: IEmailToken | null;
}) => {
  const getLinkForTagNote = (token: IEmailToken) => ({
    minimalViewRedirectURL: `/notes?caseId=${token.metadata.caseId}&messageId=${token.metadata.noteId}`,
    loginViewRedirectURL: `${uiRoutes.cases.detail}?caseId=${token.metadata.caseId}&messageId=${token.metadata.noteId}&fromLink=true`,
  });

  const getLinkForShareFile = (token: IEmailToken) => ({
    minimalViewRedirectURL: `/notes?caseId=${token.metadata.caseId}&fileId=${token.metadata.fileId}`,
    loginViewRedirectURL: `${uiRoutes.cases.detail}?caseId=${token.metadata.caseId}&fileId=${token.metadata.fileId}&fromLink=true`,
  });

  const getLinkForShareCase = (token: IEmailToken) => ({
    minimalViewRedirectURL: `${uiRoutes.cases.minimalView}?caseId=${token.metadata.caseId}`,
    loginViewRedirectURL: `${uiRoutes.cases.detail}?caseId=${token.metadata.caseId}&fromLink=true`,
  });

  const getResourceLink = (): {
    loginViewRedirectURL: string;
    minimalViewRedirectURL: string;
  } => {
    switch (emailToken?.type) {
      case EmailType.TAG_NOTE:
        return getLinkForTagNote(emailToken);
      case EmailType.SHARE_CASE_FILE:
        return getLinkForShareFile(emailToken);
      case EmailType.SHARE_CASE:
        return getLinkForShareCase(emailToken);
      default:
        return {
          loginViewRedirectURL: '',
          minimalViewRedirectURL: '',
        };
    }
  };

  return {
    getResourceLink,
  };
};
