import { useFormContext } from 'react-hook-form';

import { TextFieldProps } from '@mui/material';
import { ReferringAgentAutocomplete } from 'common';
import { useCheckFormFieldSettings } from 'hooks/useCheckEnabledFields';
import { IClientAgent } from 'interfaces/client-management';
import { IAdaptedContactTableRow } from 'interfaces/settings/contacts';

type Props = {
  name: string;
  disabled?: boolean;
  showHelperText?: boolean;
  contactSelectHandler?: (contact?: IAdaptedContactTableRow) => void;
  isConfigurableThroughSetting?: boolean;
} & TextFieldProps;

const FormReferringAgentAutocomplete = ({
  name,
  disabled,
  showHelperText,
  contactSelectHandler,
  label,
  isConfigurableThroughSetting,
  ...others
}: Props): JSX.Element => {
  const methods = useFormContext();
  const {
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const referredBy: IClientAgent = watch(name);

  const handleSelectReferringAgent = (contact?: IAdaptedContactTableRow) => {
    setValue(
      name,
      contact
        ? { id: contact.contactId, name: contact.fullName }
        : { id: '', name: '' }
    );
    contactSelectHandler?.(contact);
  };

  const checkRequiredField = useCheckFormFieldSettings().required;
  const isReferredByRequired =
    checkRequiredField.referredBy && isConfigurableThroughSetting;

  return (
    <ReferringAgentAutocomplete
      disabled={disabled}
      inputProps={{
        ...others,
        label: `${label}${isReferredByRequired ? ' *' : ''}`,
        error: !!errors[name],
        helperText: showHelperText
          ? (errors[name]?.message as React.ReactNode)
          : null,
      }}
      label="Referred By"
      onSelectHandler={handleSelectReferringAgent}
      selectedValue={referredBy?.id || ''}
    />
  );
};

FormReferringAgentAutocomplete.defaultProps = {
  contactSelectHandler: null,
  disabled: false,
  showHelperText: false,
  isConfigurableThroughSetting: false,
};

export default FormReferringAgentAutocomplete;
