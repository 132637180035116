import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import uiRoutes from 'constants/uiRoutes';
import { useAppSelector } from 'stores/hooks';
import { selectStripe } from 'stores/Payment';

interface Props {
  children: JSX.Element;
}

export const StripeWrapper = ({ children }: Props) => {
  const stripeDetail = useAppSelector(selectStripe);

  // The CometChat call doesnot starts when it is wrapped with Stripe Element
  // The exact reason is not know for why that happens
  // So this is a workaround by disabling Stripe for CometChat call
  const isStripeDisabledRoute = window.location.pathname.includes(
    uiRoutes.virtualVisit.clientVirtualVisit.replace(':sessionId', '')
  );

  if (stripeDetail?.publishableKey && !isStripeDisabledRoute) {
    const stripePromise = loadStripe(stripeDetail.publishableKey);

    return <Elements stripe={stripePromise}>{children}</Elements>;
  }

  return children;
};
