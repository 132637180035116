import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import uiRoutes from 'constants/uiRoutes';

/**
 * Wrapper for case route
 * If coming from an email link, send to OTP authenticate route
 *
 */
export const CaseAuthTenantCheck = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const caseId = searchParams.get('caseId');
  const messageId = searchParams.get('messageId');
  const fromEmail = searchParams.get('fromEmail');
  const codeLink = searchParams.get('link');

  // If coming from an email link, send to OTP authenticate route
  const isEmailNote = !!caseId && !!messageId && !!fromEmail && !!codeLink;

  if (isEmailNote) {
    return (
      <Navigate
        replace
        state={{ from: location }}
        to={`${uiRoutes.noteAuthenticate}${location.search}`}
      />
    );
  }

  return children;
};
