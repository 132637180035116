import { useSearchParams } from 'react-router-dom';

import uiRoutes from 'constants/uiRoutes';
import { selectMenu } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

import { PreOTPAuthenticateWrapper } from './PreOTPAuthenticateWrapper';

interface ICasePreOTPAuthenticateWrapperProps {
  children: JSX.Element;
}

export const CaseNoteTaggedOTPAuthenticateWrapper = ({
  children,
}: ICasePreOTPAuthenticateWrapperProps) => {
  const [searchParams] = useSearchParams();

  const caseId = searchParams.get('caseId');
  const messageId = searchParams.get('messageId');
  const fileId = searchParams.get('fileId');
  const taskId = searchParams.get('taskId');

  const getRedirectPath = () => {
    let path = `${uiRoutes.cases.detail}?caseId=${caseId}&fromLink=true`;
    if (messageId) {
      path = `${path}&messageId=${messageId}`;
    }
    if (fileId) {
      path = `${path}&fileId=${fileId}`;
    }

    if (taskId) {
      path = `${path}&taskId=${taskId}`;
    }
    return path;
  };

  const redirectPath = getRedirectPath();

  const allmenus = useAppSelector(selectMenu);
  const hasCaseMenuAccess = allmenus?.some(
    (item) => item.code === 'CASES' || item.routePath === uiRoutes.cases.detail
  );

  return (
    <PreOTPAuthenticateWrapper
      caseId={caseId}
      hasMenuAccess={hasCaseMenuAccess}
      redirectPath={redirectPath}
    >
      {children}
    </PreOTPAuthenticateWrapper>
  );
};
