import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { removeLocal, setLocal } from 'utils/storage';

interface IURLAuthenticateWrapper {
  children: JSX.Element;
}

/**
 * Supports auth for routes that have JWT Token in the query param
 *
 */
export const URLAuthenticateWrapper = ({
  children,
}: IURLAuthenticateWrapper) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const jwtToken = searchParams.get('token');

  const jwtTokenInURLRef = useRef(false);

  const decodedToken = jwtToken ? decodeURIComponent(jwtToken) : jwtToken;

  /**
   * Gets token from url query param and sets it in local storage
   * Plus, removes the token from the local storage on unmount.
   */
  useEffect(() => {
    if (decodedToken) {
      setLocal('jwtToken', decodedToken);
      searchParams.delete('token');
      setSearchParams(searchParams);
      jwtTokenInURLRef.current = true;
    }

    return () => {
      if (jwtTokenInURLRef.current) {
        removeLocal('jwtToken');
        jwtTokenInURLRef.current = false;
      }
    };
  }, [decodedToken, searchParams, setSearchParams]);

  return children;
};
