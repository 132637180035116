import { Box, Tooltip, Typography } from '@mui/material';
import { TaskStatusDisplay } from 'common/display/TaskStatusDisplay';
import config from 'config';
import { ITask } from 'interfaces/cases';
import { truncateText } from 'utils/common';
import { formatDateView, UTCToLocalDateViewFormat } from 'utils/moment';

export const TaskDetail = ({ task }: { task: ITask }) => (
  <Box p={2}>
    <Box>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Box alignItems="center" display="flex" gap={2}>
          <Tooltip arrow placement="top" title={task.title}>
            <Typography gutterBottom={false} variant="h6">
              {truncateText(task.title)}
            </Typography>
          </Tooltip>
          <TaskStatusDisplay status={task.status} />
        </Box>

        <Box display="flex" gap={1}>
          Due Date:{' '}
          <Typography fontWeight="medium">
            {formatDateView(task.dueDate)} -{' '}
            {UTCToLocalDateViewFormat(task.dueDate, config.timeOnlyView)}
          </Typography>
        </Box>
      </Box>
      <Typography>
        Description: {task.description === '' ? 'N/A' : task.description}
      </Typography>
    </Box>
  </Box>
);
