import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { Alert, Box, Grid, Typography } from '@mui/material';
import {
  FormAddressInput,
  FormCheckbox,
  FormGenderInput,
  FormMaskedDateInput,
  FormMaskedPhoneInput,
  FormTextfield,
} from 'common';
import UserRolesAutoComplete from 'common/autocomplete/UserRolesAutoComplete';
import FormPhoneCountryInput from 'common/form/FormPhoneCountryInput';
import ModalKey from 'enums/ModalKey';
import {
  MiscellaneousCode,
  TenantSettingStatus,
} from 'enums/tenant-management/tenant';
import { useCheckUserIsSuperAdmin } from 'hooks/useCheckUserIsSuperAdmin';
import { useGetMiscellaneousTenantSettings } from 'hooks/useGetMiscellaneousTenantSettings';
import { selectAuthTenantData } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

interface IProps {
  isAddAdminUser: boolean;
  isEditMode?: boolean;
  isUserSuperAdmin?: boolean;
}

const AddUserForm = ({
  isAddAdminUser,
  isEditMode = false,
  isUserSuperAdmin = false,
}: IProps): JSX.Element => {
  const isLoggedInUserSuperAdmin = useCheckUserIsSuperAdmin();
  const isInternationalAddressEnabled =
    useGetMiscellaneousTenantSettings(
      MiscellaneousCode.INTERNATIONAL_ADDRESS
    ) === TenantSettingStatus.ENABLED;

  const methods = useFormContext();
  const tenantData = useAppSelector(selectAuthTenantData);
  const [searchParams] = useSearchParams();

  const userId = searchParams.get('id');

  const { setValue, getValues, watch } = methods;

  const isSuperAdminSelected = watch('isSuperAdmin');
  const modalType = searchParams.get('type');
  const isProfileAdmin =
    modalType === ModalKey.ADD_PROFILE_ADMIN ||
    modalType === ModalKey.EDIT_PROFILE_ADMIN;

  const isUserSameAsLoggedInUser = tenantData?.userId === userId;

  const isSuperAdminSelectable =
    isAddAdminUser && !isProfileAdmin && !isUserSameAsLoggedInUser;

  const showRolesField =
    isAddAdminUser &&
    (!isEditMode || !isLoggedInUserSuperAdmin || !isUserSuperAdmin);

  return (
    <Box>
      <Typography fontWeight="medium">Personal Information</Typography>
      <Grid container mb={2} spacing={2}>
        <Grid item xs={4}>
          <FormTextfield label="First Name" name="firstName" required />
        </Grid>
        <Grid item xs={4}>
          <FormTextfield label="Middle Name" name="middleName" />
        </Grid>
        <Grid item xs={4}>
          <FormTextfield label="Last Name" name="lastName" required />
        </Grid>
      </Grid>
      <Grid columnSpacing={2} container mb={2}>
        <Grid item xs={6}>
          <FormMaskedDateInput />
        </Grid>
        <Grid item xs={6}>
          <FormGenderInput />
        </Grid>
      </Grid>
      <Box>
        {isSuperAdminSelectable && (
          <FormCheckbox label="Super Admin" name="isSuperAdmin" />
        )}
        {isSuperAdminSelected && (
          <Alert severity="info" sx={{ mb: isSuperAdminSelected ? 2 : 0 }}>
            Super admins have full access to all the settings.
          </Alert>
        )}
      </Box>
      {isSuperAdminSelected ||
        (isAddAdminUser && !isProfileAdmin && (
          <Grid columnSpacing={2} mb={2} mt={2}>
            <UserRolesAutoComplete
              autoFocusInput={false}
              entity={getValues('roles')}
              error={false}
              isEditMode={isEditMode}
              multiple
              onMultiSelectHandler={(value) => setValue('roles', value)}
            />
            <Typography gutterBottom={false} variant="caption">
              Multiple roles can be assigned to user
            </Typography>
          </Grid>
        ))}

      <Typography fontWeight="medium" gutterBottom={false}>
        Contact Information
      </Typography>
      <Grid columnSpacing={2} container mb={2} mt={2}>
        <Grid item xs={6}>
          <FormTextfield label="Email" name="email" required />
        </Grid>
        {isInternationalAddressEnabled ? (
          <Grid item xs={6}>
            <FormPhoneCountryInput />
          </Grid>
        ) : (
          <Grid item xs={6}>
            <FormMaskedPhoneInput />
          </Grid>
        )}
      </Grid>
      {!isAddAdminUser && (
        <Grid container mb={2}>
          <Grid item xs={12}>
            <FormCheckbox
              label="Enable Impersonation"
              name="enableImpersonation"
            />
          </Grid>
        </Grid>
      )}
      <FormAddressInput />
    </Box>
  );
};

AddUserForm.defaultProps = {
  isEditMode: false,
  isUserSuperAdmin: false,
};

export default AddUserForm;
