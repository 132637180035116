/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { SortOrder } from 'enums/common';
import { NetworkFacilitySortBy } from 'enums/networks/network-facility';
import { SortOrderType } from 'interfaces/common';
import { IPhysicianFilter } from 'interfaces/networks/network-physician';
import { RootState } from 'stores';

const { DEFAULT_TABLE_FILTER } = commonConstants;

interface INetworkFacilitySort {
  sortBy: NetworkFacilitySortBy;
  sortOrder: SortOrderType;
}

interface NetworkPhysicianState {
  filters: IPhysicianFilter;
  sort: INetworkFacilitySort;
}

const initialState: NetworkPhysicianState = {
  filters: { ...DEFAULT_TABLE_FILTER, networkCode: '' },
  sort: {
    sortBy: NetworkFacilitySortBy.NAME,
    sortOrder: SortOrder.ASC,
  },
};

export const slice = createSlice({
  name: 'network-physician',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (
      state,
      action: PayloadAction<Partial<IPhysicianFilter>>
    ) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER, networkCode: '' };
    },

    changeSortByAndOrder: (
      state,
      action: PayloadAction<INetworkFacilitySort>
    ) => {
      state.sort = {
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    },
  },
});

// Actions
export const { changeFilters, resetFilters, changeSortByAndOrder } =
  slice.actions;

// Selectors
export const selectNetworkPhysicianFilter = (state: RootState) =>
  state.networkPhysician.filters;
export const selectNetworkPhysicianSort = (state: RootState) =>
  state.networkPhysician.sort;
// Reducer
export default slice.reducer;
