import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Alert, Box, Typography } from '@mui/material';
import LoadingIndicator from 'common/LoadingIndicator';
import paymentConstants from 'constants/payment';
import {
  setCheckoutButtonDisabled,
  setFailedPaymentMethod,
} from 'stores/billings/failed';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { selectCardConnect } from 'stores/Payment';

import FilledCardDetail from './FilledCardDetail';

const { CARD_INPUT_PARAMETERS, ENCODED_STYLES } = paymentConstants.cardConnect;

interface IProps {
  tokenFormName?: string;
  expiryFormName?: string;
}

const CardPayment = ({
  tokenFormName = 'cardToken',
  expiryFormName = 'cardExpiry',
}: IProps) => {
  const cardConnectInfo = useAppSelector(selectCardConnect);

  const { setValue, watch } = useFormContext();

  const [isLoading, setIsLoading] = useState(true);
  const [isInputRequired, setIsInputRequired] = useState(false);
  const [isInputInvalid, setIsInputInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useAppDispatch();

  const cardToken = watch(tokenFormName);
  const cardExpiry = watch(expiryFormName);

  const clearErrorMessage = () => {
    setIsInputRequired(false);
    setIsInputInvalid(false);
    setErrorMessage('');
  };

  const listener = (event: any) => {
    if (typeof event.data === 'string') {
      // 1004 -> Card Number Required
      // 1005 -> CVC Required
      // 1006 -> Expiry Required

      // 1001 -> Invalid Card Number
      // 1002 -> Invalid CVC
      // 1003 -> Invalid Expiry
      try {
        const response = JSON.parse(event.data);

        if (response.errorCode !== '0') {
          const inputRequired =
            response.errorCode === '1004' ||
            response.errorCode === '1005' ||
            response.errorCode === '1006';
          const inputInvalid =
            response.errorCode === '1001' ||
            response.errorCode === '1002' ||
            response.errorCode === '1003';

          setIsInputRequired(inputRequired);
          setIsInputInvalid(inputInvalid);

          if (inputInvalid) {
            setErrorMessage(response.errorMessage);
          }
        } else {
          setValue('cardToken', response.token);
          setValue('cardExpiry', response.expiry);
          clearErrorMessage();
        }
      } catch (e) {
        // eslint-disable-next-line no-console
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      window.removeEventListener('message', listener);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const resetCardInformation = () => {
    setValue('cardToken', '');
    setValue('cardExpiry', '');
    setValue('agreeTerms', false);
    dispatch(setCheckoutButtonDisabled(false));
    dispatch(setFailedPaymentMethod(null));
  };

  const renderedCardInput = (
    <>
      {isLoading && <LoadingIndicator containerHeight="5vh" />}
      <Box
        component="iframe"
        frameBorder="0"
        height={isLoading ? 0 : '70px'}
        id="tokenframe"
        name="tokenframe"
        onLoad={() => setIsLoading(false)}
        scrolling="no"
        src={`${cardConnectInfo.baseUrl}/itoke/ajax-tokenizer.html?&${CARD_INPUT_PARAMETERS}&css=${ENCODED_STYLES}`}
        title="iframe"
        width="100%"
      />

      {isInputRequired && (
        <Alert severity="error">Please fill the card information.</Alert>
      )}

      {isInputInvalid && (
        <Alert severity="error">
          Please make sure that the card information is correct: {errorMessage}
        </Alert>
      )}
    </>
  );

  return (
    <Box>
      <Typography
        color="text.secondary"
        fontWeight={(theme) => theme.typography.fontWeightMedium}
        gutterBottom={false}
        mt={2}
        variant="body1"
      >
        Card Information
      </Typography>
      {cardToken && cardExpiry ? (
        <FilledCardDetail
          cardExpiry={cardExpiry}
          cardToken={cardToken}
          onEditClick={resetCardInformation}
        />
      ) : (
        renderedCardInput
      )}
    </Box>
  );
};

CardPayment.defaultProps = {
  tokenFormName: 'cardToken',
  expiryFormName: 'cardExpiry',
};

export default CardPayment;
