import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import NoRecordsFoundDisplay from 'common/display/NoRecordsFoundDisplay';
import LoadingIndicator from 'common/LoadingIndicator';
import ActiveInactiveSelect from 'common/ui/select/ActiveInactiveSelect';
import commonConstants from 'constants/common';
import {
  ActionFrom,
  ChangeMembershipActionType,
  ClientEnrollmentStatus,
} from 'enums/client-management';
import { Status } from 'enums/common';
import ModalKey from 'enums/ModalKey';
import { EnrollmentCode } from 'enums/tenant-management/tenant';
import { useCheckPermissionToModifyMembership } from 'hooks/useCheckPermissionToModifyMembership';
import { useGetSupportContact } from 'hooks/useGetSupportContact';
import { IAdaptedClientEnrollmentMembership } from 'interfaces/client-management/enrollment';
import { useClientEnrollmentMembershipQuery } from 'services/client-management/enrollment';
import {
  selectChangeMembershipClientData,
  setChangeMembershipStep,
  setCurrentMembershipSelectedData,
} from 'stores/client-management';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { checkIsEnrollmentSettingEnabled } from 'utils/tenantSetting';

import ChangeMembershipTableRow from './ChangeMembershipTableRow';

const ChangeMembershipList = ({ clientId }: { clientId: string }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const actionFrom = searchParams.get('actionFrom');
  const actionType = searchParams.get('actionType');
  const clientStatus = searchParams.get('status');
  const clientEnrollmentId = searchParams.get('clientEnrollmentId');

  const [membershipActivity, setMembershipActivity] = useState<boolean>(true);
  const [
    filteredClientEnrollmentMembershipData,
    setFilteredClientEnrollmentMembershipData,
  ] = useState<IAdaptedClientEnrollmentMembership[]>([]);
  const isActionFromClient = actionFrom === ActionFrom.CLIENT_LIST;

  const clientData = useAppSelector(selectChangeMembershipClientData) || null;

  const isCompleteMembershipEnabled = checkIsEnrollmentSettingEnabled(
    EnrollmentCode.COMPLETEMEMBERSHIP
  );

  const clientEnrollmentMembershipQuery = useClientEnrollmentMembershipQuery(
    clientId!,
    {
      enabled: !!clientId,
    }
  );

  const isModifyMembershipAllowed = useCheckPermissionToModifyMembership();
  const supportContactEmail = useGetSupportContact();

  const clientEnrollmentMembershipData =
    clientEnrollmentMembershipQuery.data?.rows || [];

  const backupClientEnrollmentData = clientEnrollmentMembershipData;

  useEffect(() => {
    setFilteredClientEnrollmentMembershipData(
      backupClientEnrollmentData.filter((x) =>
        x.isActiveStatus?.includes(
          membershipActivity ? Status.ACTIVE : Status.INACTIVE
        )
      )
    );
  }, [backupClientEnrollmentData, clientStatus, membershipActivity]);

  const totalCount = clientEnrollmentMembershipQuery.data?.count || 0;
  const isLoading =
    clientEnrollmentMembershipQuery.isLoading ||
    clientEnrollmentMembershipQuery.isFetching ||
    isActionFromClient;

  // Use Effect for approving membership from client ellipsis list
  useEffect(() => {
    if (
      isActionFromClient &&
      actionType === ChangeMembershipActionType.APPROVE &&
      clientEnrollmentMembershipData.length > 0
    ) {
      const submittedStatusEnrollment =
        clientEnrollmentMembershipData.filter(
          (x) =>
            x.status === ClientEnrollmentStatus.SUBMITTED && !!x.offerings.id
        ) || [];

      // If client has only one enrollment with status "Submitted" we navigate directly to approve membership component
      if (submittedStatusEnrollment?.length === 1) {
        dispatch(
          setCurrentMembershipSelectedData(submittedStatusEnrollment[0])
        );
        dispatch(
          setChangeMembershipStep({
            step: 2,
            actionType: ChangeMembershipActionType.APPROVE,
          })
        );
      } else {
        // else navigate to listing component
        navigate(
          `${location.pathname}?type=${
            ModalKey.CHANGE_MEMBERSHIP
          }&clientId=${clientId!}&clientEnrollmentId=${clientEnrollmentId}&status=${clientStatus}&actionFrom=${
            ActionFrom.CLIENT_LIST_MULTIPLE
          }&defaultStatusFilter=${commonConstants.STATUS[1]}`
        );
        dispatch(
          setChangeMembershipStep({
            step: 0,
            actionType: null,
          })
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActionFromClient, clientEnrollmentMembershipData.length, dispatch]);

  // Use Effect for declining membership from client ellipsis list
  useEffect(() => {
    if (
      isActionFromClient &&
      actionType === ChangeMembershipActionType.DECLINE &&
      clientEnrollmentMembershipData.length > 0
    ) {
      const submittedStatusEnrollment =
        clientEnrollmentMembershipData.filter(
          (x) =>
            x.status === ClientEnrollmentStatus.SUBMITTED && !!x.offerings.id
        ) || [];

      // If client has only one enrollment with status "Submitted" we navigate directly to decline membership component
      if (submittedStatusEnrollment?.length === 1) {
        dispatch(
          setCurrentMembershipSelectedData(submittedStatusEnrollment[0])
        );
        dispatch(
          setChangeMembershipStep({
            step: 5,
            actionType: ChangeMembershipActionType.DECLINE,
          })
        );
      } else {
        // else navigate to listing component
        navigate(
          `${location.pathname}?type=${
            ModalKey.CHANGE_MEMBERSHIP
          }&clientId=${clientId!}&clientEnrollmentId=${clientEnrollmentId}&status=${clientStatus}&actionFrom=${
            ActionFrom.CLIENT_LIST_MULTIPLE
          }&defaultStatusFilter=${commonConstants.STATUS[1]}`
        );
        dispatch(
          setChangeMembershipStep({
            step: 0,
            actionType: null,
          })
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActionFromClient, clientEnrollmentMembershipData.length, dispatch]);

  const onAddMembershipClick = () => {
    if (clientEnrollmentMembershipData.length > 0) {
      dispatch(
        setCurrentMembershipSelectedData(clientEnrollmentMembershipData[0])
      );
    }
    dispatch(
      setChangeMembershipStep({
        step: 1,
        actionType: ChangeMembershipActionType.ADD,
      })
    );
  };

  const MODAL_BANNER_SUBTITLE = isModifyMembershipAllowed
    ? 'Please select the one you would like to modify.'
    : "Please click 'Add Membership' button to buy/add a new membership.";

  const INTO_ALERT_CONTENT = isModifyMembershipAllowed ? (
    `Note: Membership can only be
  ${isCompleteMembershipEnabled ? 'completed' : 'cancelled'} or
  modified based on the benefit start and end date.`
  ) : (
    <>
      If you would like to cancel/switch/modify membership, please reach out to
      our support at &nbsp;
      <Typography
        component="a"
        display="inline"
        href={`mailto:${supportContactEmail}`}
        variant="body2"
      >
        {supportContactEmail}.
      </Typography>
    </>
  );

  return (
    <>
      {!isLoading && (
        <Box
          sx={{
            maxHeight: '60vh',
            overflowY: 'auto',
          }}
        >
          <Box padding={2} sx={{ background: (t) => t.palette.gray.lighter }}>
            <Grid
              container
              display="flex"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item xs={8}>
                <Typography
                  color="gray.darker"
                  fontWeight="medium"
                  variant="body1"
                >
                  Below are the list of membership associated with{' '}
                  {clientData?.fullName
                    ? clientData?.fullName
                    : clientData?.businessName}
                  .
                </Typography>
                <Typography variant="body2">{MODAL_BANNER_SUBTITLE}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Box display="flex" justifyContent="end">
                  <Button
                    data-cy="client-add-membership"
                    onClick={onAddMembershipClick}
                    size="medium"
                    startIcon={<FontAwesomeIcon icon={faAdd} />}
                    variant="contained"
                  >
                    Add Membership
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box marginBottom={2}>
            <Box>
              <Box
                alignItems="center"
                display="flex"
                flexDirection="row-reverse"
                pr={2}
              >
                <ActiveInactiveSelect
                  onChange={(e) => setMembershipActivity(e as boolean)}
                  returnBooleanValue
                />

                {/* Commented for now (Toggle Button if required in next time)
                  <Typography gutterBottom={false} variant="body2">
                    Inactive
                  </Typography>
                  <Switch
                    checked={membershipActivity}
                    color="primary"
                    onChange={(event) =>
                      setMembershipActivity(event.target.checked)
                    }
                  />
                  <Typography gutterBottom={false} variant="body2">
                    Active
                  </Typography> */}
              </Box>
            </Box>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Membership & Fees</TableCell>
                    <TableCell>Benefit Dates</TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ChangeMembershipTableRow
                    data={filteredClientEnrollmentMembershipData}
                  />
                </TableBody>
              </Table>
              {filteredClientEnrollmentMembershipData.length < 1 && (
                <NoRecordsFoundDisplay />
              )}
            </TableContainer>
          </Box>
          <Box marginBottom={2} padding={2}>
            <Alert severity="info" sx={{ marginBottom: '0 !important' }}>
              {INTO_ALERT_CONTENT}
            </Alert>
          </Box>
        </Box>
      )}
      {totalCount < 1 && !isLoading && <NoRecordsFoundDisplay />}
      {isLoading && <LoadingIndicator />}
    </>
  );
};

export default ChangeMembershipList;
