import { Avatar, Box, Paper, Stack, Typography } from '@mui/material';
import EmptyCardImage from 'assets/images/png/emptyCard.png';
import useBloodDonor from 'features/case-layout-new/hooks/blood-donar/useBloodDonor';

import SelectedDonorCard from './SelectedDonorCard';

const EmptySelectedProvider = () => (
  <Paper
    className="bordered no-box-shadow"
    sx={{
      paddingBottom: 0,
    }}
  >
    <Stack
      height="268px"
      spacing={2}
      sx={{ alignItems: 'center', padding: 3, textAlign: 'center' }}
    >
      <Avatar
        alt="empty"
        src={EmptyCardImage}
        sx={{ width: '80px', height: '80px' }}
        variant="square"
      />
      <Typography variant="body2">
        Blood Donor information not available.
      </Typography>
      <Typography variant="caption">
        You can select a blood donor from the donor options below.
      </Typography>
    </Stack>
  </Paper>
);

const SelectedDonor = () => {
  const { selectedDonor, onDeleteClick, isUserClient } = useBloodDonor();

  if (!selectedDonor) {
    return <EmptySelectedProvider />;
  }

  return (
    <Box>
      <SelectedDonorCard
        data={selectedDonor}
        isUserClient={isUserClient}
        onRemoveClick={onDeleteClick}
      />
    </Box>
  );
};

export default SelectedDonor;
