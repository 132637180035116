import { useEffect, useRef } from 'react';

import { IAdaptedTenantMembershipOfferingTableRow } from 'interfaces/tenant-management/tenant/tenantMembershipOffering';
import { setManageMembershipSelectedOfferings } from 'stores/client-management/manage-membership';
import { useAppDispatch } from 'stores/hooks';

import { useGetCurrentSelectedClientAndMembershipDetails } from './useGetCurrentSelectedClientAndMembershipDetails';

export const useOptionsListItem = (
  item: IAdaptedTenantMembershipOfferingTableRow,
  offeringId: string
) => {
  const itemRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { selectedOffering } =
    useGetCurrentSelectedClientAndMembershipDetails();

  useEffect(() => {
    if (
      offeringId === item.tenantOfferingId ||
      (!selectedOffering && item.isRecommended)
    ) {
      dispatch(
        setManageMembershipSelectedOfferings({ selectedOffering: item })
      );
    }
  }, [dispatch, item, offeringId, selectedOffering]);

  useEffect(() => {
    if (offeringId === item.tenantOfferingId) {
      itemRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [offeringId, item.tenantOfferingId]);

  return { itemRef, offeringId };
};
