import regex from 'constants/regex';
import validationMessage from 'constants/validationMessage';
import * as Yup from 'yup';

const { REQUIRED } = validationMessage;

export const URIDocumentSchema = Yup.object().shape({
  name: Yup.string().nullable().trim().required(REQUIRED),
  path: Yup.string()
    .nullable()
    .trim()
    .matches(regex.LINK, {
      excludeEmptyString: false,
      message: 'Please enter a valid URL',
    })
    .required(REQUIRED),
});

export const PreConvertToESignatureSchema = Yup.object().shape({
  name: Yup.string().trim().required(REQUIRED),
});
