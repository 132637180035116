import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Card,
  IconButton,
  Radio,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import paymentConstants from 'constants/payment';
import { GatewayType, PaymentMethod, PaymentSourceStatus } from 'enums/Payment';
import { IAdaptedPaymentSource } from 'interfaces/payment';
import {
  getBankAccountNumberDisplayText,
  getCardExpiryText,
  getCardNumberDisplayText,
} from 'utils/payment';

const { gatewayMetadata } = paymentConstants;

interface IProps {
  data: IAdaptedPaymentSource;
  isSetAsPrimaryDisabled: boolean;
  isChecked?: boolean;
  onChangePrimaryCardHandler?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onDeleteHandler?: VoidFunction;
  showLocation?: boolean;
}

const PaymentInformationCardDisplay = ({
  data,
  isSetAsPrimaryDisabled,
  isChecked,
  onChangePrimaryCardHandler,
  onDeleteHandler,
  showLocation,
}: IProps) => {
  const { paymentMode } = data;

  const renderedCardContent = (
    <Stack gap={1}>
      <Typography color="text.secondary" gutterBottom={false} variant="body2">
        {!showLocation && 'Card:'} {getCardNumberDisplayText(data.last4Digit)}
      </Typography>
      <Typography color="text.secondary" gutterBottom={false} variant="body2">
        Expires: {getCardExpiryText(data.expiryMonth, data.expiryYear)}
      </Typography>
    </Stack>
  );

  const renderedBankContent = (
    <Typography color="text.secondary" gutterBottom={false} variant="body2">
      {!showLocation && 'Bank:'}{' '}
      {getBankAccountNumberDisplayText(data.last4Digit)}
    </Typography>
  );

  const renderedCashOrCheckContent = (
    <Typography color="text.secondary" gutterBottom={false} variant="body2">
      Payment Method: {paymentMode}
    </Typography>
  );

  const isPrimaryPaymentSource = data.status === PaymentSourceStatus.PRIMARY;

  const showGatewayType =
    data.paymentMode === PaymentMethod.CARD ||
    data.paymentMode === PaymentMethod.BANK;
  const gatewayTypeDisplayText = showGatewayType
    ? gatewayMetadata[data.gatewayType as GatewayType]?.displayText || ''
    : '';

  return (
    <Card className="payment-information-card" sx={{ height: 142 }}>
      <Box
        alignItems="center"
        className="card-header"
        display="flex"
        justifyContent="space-between"
      >
        {onChangePrimaryCardHandler ? (
          <Box alignItems="center" display="flex">
            <Radio
              checked={isChecked}
              disabled={isSetAsPrimaryDisabled}
              inputProps={{ 'aria-label': data.paymentMode }}
              name="primaryPaymentInformation"
              onChange={onChangePrimaryCardHandler}
              size="small"
              value={data.id}
            />
            <Typography
              gutterBottom={false}
              sx={{
                fontWeight: (theme) => theme.typography.fontWeightMedium,
              }}
              variant="body2"
            >
              Set as Primary{' '}
              {gatewayTypeDisplayText ? `(${gatewayTypeDisplayText})` : ''}
            </Typography>
          </Box>
        ) : (
          <Typography
            gutterBottom={false}
            ml={1.25}
            py={1}
            sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}
            variant="body2"
          >
            {paymentMode}{' '}
            {isPrimaryPaymentSource &&
              `(Primary${
                gatewayTypeDisplayText ? `- ${gatewayTypeDisplayText}` : ''
              })`}
          </Typography>
        )}

        {!isPrimaryPaymentSource && onDeleteHandler && (
          <Box display="flex" flexGrow={1} justifyContent="flex-end">
            <IconButton
              aria-label="Delete"
              onClick={onDeleteHandler}
              size="small"
              sx={{
                color: (theme) => theme.palette.gray.main,
                backgroundColor: (theme) => theme.palette.common.white,
                '&:hover': {
                  color: (theme) => theme.palette.error.main,
                },
              }}
            >
              <FontAwesomeIcon icon={faTrash} size="xs" />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box className="card-content">
        {paymentMode === PaymentMethod.CARD && renderedCardContent}
        {paymentMode === PaymentMethod.BANK && renderedBankContent}
        {(paymentMode === PaymentMethod.CASH ||
          paymentMode === PaymentMethod.CHECK ||
          paymentMode === PaymentMethod.OTHERS) &&
          renderedCashOrCheckContent}

        {showLocation && data.location && (
          <Tooltip arrow placement="top" title={data.location}>
            <Typography
              className="text-overflow-ellipses"
              color="text.secondary"
              display="block"
              gutterBottom={false}
              mt={1.5}
              variant="caption"
            >
              Location: {data.location}
            </Typography>
          </Tooltip>
        )}

        <Typography color="text.secondary" variant="caption">
          Created by: {data.formattedCreatedBy} on {data.formattedCreatedAt}
        </Typography>
      </Box>
    </Card>
  );
};

PaymentInformationCardDisplay.defaultProps = {
  isChecked: false,
  onChangePrimaryCardHandler: undefined,
  onDeleteHandler: undefined,
  showLocation: false,
};

export default PaymentInformationCardDisplay;
