import { useQuery } from 'react-query';

import * as clientAPI from 'api/client-management';
import { YesNo } from 'enums/common';
import {
  IClientFilters,
  IIndividualAdvancedSearchForm,
} from 'interfaces/client-management';
import {
  adaptClientIndividualList,
  adaptClientIndividualOfferingsList,
} from 'utils/client-management';
import { unformatPhone } from 'utils/common';

export const individualKeys = {
  all: ['clients-individual'] as const,
  lists: () => [...individualKeys.all, 'list'] as const,
  list: (filters: IClientFilters & IIndividualAdvancedSearchForm) =>
    [...individualKeys.lists(), { filters }] as const,
  offerings: (filters: IClientFilters) =>
    [...individualKeys.lists(), { filters }] as const,
  members: ({ id, isActive }: { id: string; isActive: boolean }) =>
    [...individualKeys.lists(), id, isActive] as const,
  details: () => [...individualKeys.all, 'detail'] as const,
  detail: (id: number | string) => [...individualKeys.details(), id] as const,
};

export const useClientIndividualQuery = (
  filters: IClientFilters & IIndividualAdvancedSearchForm,
  { enabled }: { enabled: boolean }
) => {
  const formattedFilters = {
    ...filters,
    phone: unformatPhone(filters?.phone || ''),
    ...(filters.allowTextNotification && {
      allowTextNotification: filters?.allowTextNotification === YesNo.YES,
    }),
  };
  const queryInfo = useQuery(
    individualKeys.list(filters),
    () => clientAPI.getClients(formattedFilters),
    {
      select: adaptClientIndividualList,
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useClientIndividualMemberQuery = (
  {
    clientId,
    isActive = false,
    enabled = true,
  }: { clientId: string; isActive?: boolean; enabled?: boolean },
  filters: IClientFilters & IIndividualAdvancedSearchForm
) => {
  const queryInfo = useQuery(
    individualKeys.members({ id: clientId, isActive }),
    () => clientAPI.getClients(filters),
    {
      select: adaptClientIndividualList,
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useClientIndividualOfferingsQuery = (
  clientId: string,
  filters: IClientFilters,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    individualKeys.offerings(filters),
    () => clientAPI.getClientDashboardSummary(clientId, filters),
    {
      enabled,
      select: adaptClientIndividualOfferingsList,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};
