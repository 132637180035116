import { useState } from 'react';

import { OTPVerificationInputDisplay } from 'common/otp/OTPVerificationInputDisplay';
import commonConstants from 'constants/common';
import { IEmailToken } from 'interfaces/SharedResourceAuthenticator';
import { useVerifyOTPV1 } from 'services/otp';
import { setTenantData } from 'stores/auth';
import { useAppDispatch } from 'stores/hooks';
import { setAuthState } from 'utils/auth';
import { decodeJwtToken } from 'utils/jwt';

interface IOTPVerifier {
  emailToken: IEmailToken;
  otpGeneratedCode: string;
  onBackClick: VoidFunction;
  onResentOtp: VoidFunction;
  isResendOtpInProgress: boolean;
  handleOTPVerificationSuccess: VoidFunction;
}

export const OTPVerifier = ({
  emailToken,
  otpGeneratedCode,
  onBackClick,
  onResentOtp,
  isResendOtpInProgress,
  handleOTPVerificationSuccess,
}: IOTPVerifier) => {
  const dispatch = useAppDispatch();
  const verifyOTP = useVerifyOTPV1();

  const [otpValue, setOtpValue] = useState<string>('');

  const handleSubmit = (data: { otp: string; code: string }) => {
    verifyOTP.mutate(
      { ...data, user: emailToken.receiver },
      {
        onSuccess: async (response) => {
          if (response.success) {
            setAuthState({
              token: response?.data?.token || '',
              permissions: response.data?.permissions || [],
              dispatch,
              storageType: 'SESSION',
            });

            if (response?.data?.token) {
              const decodedToken = decodeJwtToken(response?.data?.token);
              dispatch(setTenantData(decodedToken));
            }

            handleOTPVerificationSuccess();
          }
        },
      }
    );
  };

  const onOtpInputChange = (value: string) => {
    if (
      value.length === commonConstants.TWO_FACTOR_AUTHENTICATION_CODE_DIGITS
    ) {
      handleSubmit({ otp: value, code: otpGeneratedCode });
    }
    setOtpValue(value);
  };

  return (
    <OTPVerificationInputDisplay
      email={emailToken.receiver.email}
      isOtpVerificationInProgress={verifyOTP.isLoading}
      isResendOtpInProgress={isResendOtpInProgress}
      onBackClick={onBackClick}
      onOtpInputChange={onOtpInputChange}
      onResendOtp={onResentOtp}
      otpValue={otpValue}
    />
  );
};
