export enum EmailType {
  TAG_NOTE = "TAG_NOTE",
  SHARE_CASE = "SHARE_CASE",
  SHARE_CASE_FILE = "SHARE_CASE_FILE"
}

export enum EmailUserType {
  PATIENT = "PATIENT",
  ADMIN = "ADMIN",
  THIRD_PARTY = "THIRD_PARTY",
  TENANT = "TENANT",
  AGENT = "AGENT",
}

export enum Module {
  CASE = "CASE",
}
