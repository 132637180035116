/* eslint-disable guard-for-in */
/* eslint-disable sort-imports */
import { Theme, useTheme } from '@mui/material';
import config from 'config';
import {
  AlithiasCostType,
  ReferralCaseServiceType,
  ReferralCaseStatus,
  VitafyProviderCostType,
  VitafyProviderServiceType,
} from 'enums/case';
import { BloodGroup } from 'enums/client-management';
import { ReferenceType } from 'enums/common';
import { CountryCodes } from 'enums/country';
import { EmailUserType } from 'enums/SharedResourceAuthenticator';
import {
  IReferralCasesProcedureInformationSchema as CasesProcedureInformationSchema,
  IReferralCasesServiceInformationSchema as CasesServiceInformationSchema,
  IAdaptedReferralCase,
  IAdaptedReferralCaseList,
  IAdaptedReferralCasePatient,
  IAdaptedTask,
  IAddReferralCaseForm,
  IAddReferralCasePayload,
  ICaseGroupSelect,
  ICaseProviderCostSchema,
  IGenericTimelog,
  IReferralCase,
  IReferralCaseList,
  IReferralCasePatientInformationForm,
  IReferralCasePatientInformationSchema,
  IReferralCaseProcedureInformationForm,
  IReferralCaseProviderOption,
  IReferralCaseProviderOptionFrom,
  IReferralCaseProviderOptionPayload,
  IReferralCaseService,
  IReferralCaseTimelog,
  IReferralCaseTimelogExtraOptions,
  IReferralCaseTimelogSchema,
  IReferringPhysicianForm,
  ITask,
  ITaskForm,
} from 'interfaces/cases';
import { ISmartSearchProvider } from 'interfaces/cases/provider';
import {
  IAdaptedClient,
  IAdaptedClientIndividualTableRow,
  IAdaptedClientTableRow,
} from 'interfaces/client-management';
import { IRelationship } from 'interfaces/client-management/enrollment';
import { IListResponse, IResponse } from 'interfaces/http';
import { IEmailToken } from 'interfaces/SharedResourceAuthenticator';
import { IFetchStatusRow } from 'interfaces/status';
import { ITenantSettingItem } from 'interfaces/tenant-management/tenant';
import { ITenantService } from 'interfaces/tenant-management/tenant/tenantService';
import moment from 'moment';
import { selectCurrentReferralCaseRemainingTime } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';
import {
  adaptedPhoneExtension,
  formatDate,
  formatPhone,
  getContactNumbers,
  getFormattedContactNumber,
  sliceText,
  unformatDate,
  unformatPhone,
} from 'utils/common';
import { isAllFieldsEmpty, orderBy } from 'utils/lodash';
import {
  UTCToLocalDateViewFormat,
  formatDateView,
  UTCToLocalDateViewFormat as utcToLocalDateViewFormat,
} from 'utils/moment';

// TODO: create services interface
export const getFormattedServicesName = (
  services: {
    name: string;
    code?: string;
  }[]
): string => {
  if (!services) return '-';
  if (Array.isArray(services))
    return services.map((service) => service?.name ?? '').join(', ');
  return '-';
};

export const formatReferralCaseProcedurePayload = (
  data: IReferralCaseProcedureInformationForm
): CasesProcedureInformationSchema[] => {
  let payload: CasesProcedureInformationSchema[];
  const allProcedureFieldsEmpty = isAllFieldsEmpty(data);
  const editedData = {
    name: data.procedureName || '',
    primaryCode: data.procedurePrimaryCode || '',
    secondaryCode: data.procedureSecondaryCode || '',
    description: data.procedureDescription || '',
    whatsIncluded: data.whatsIncluded || '',
  };
  if (data.procedureId) {
    payload = [
      {
        id: data.procedureId,
        ...editedData,
        type: ReferralCaseServiceType.MEDICAL,
      },
    ];
  } else if (!allProcedureFieldsEmpty) {
    payload = [
      {
        id: '',
        ...editedData,
        type: ReferralCaseServiceType.MEDICAL,
      },
    ];
  } else {
    payload = [];
  }
  return payload;
};
export const formatReferralCaseServicePayload = (
  data: ITenantService[]
): CasesServiceInformationSchema[] => {
  const payload: CasesServiceInformationSchema[] = data.map((e) => ({
    id: e.id,
    name: e.name,
    code: e.code,
    description: e.description,
    type: ReferralCaseServiceType.SERVICE,
  }));

  return payload;
};

export const formatReferralCaseClientPayload = (
  data: IAdaptedClientTableRow & { groupId: string; notes?: { note: string }[] }
) => {
  const patient = {
    id: data.clientId,
    firstName: data.firstName,
    lastName: data.lastName,
    dob: data.dob || '',
    ssn: data.lastSSN,
    gender: data.gender,
    email: data.email,
    phone: unformatPhone(data.phone || ''),
    addressLine1: data?.address?.addressLine1,
    addressLine2: data?.address?.addressLine2,
    city: data?.address?.city,
    state: data?.address?.state,
    zip: data?.address?.zip,
    note: data.note,
    countryCode: data?.countryCode,
    phoneExt: data?.phoneExt,
    clientEnrollmentId: data?.clientEnrollmentId,
  };
  return {
    status: data.status || ReferralCaseStatus.REQUESTED.toUpperCase(),
    patient,
    service: [],
    groupId: data.groupId,
    notes: data.notes || [],
  };
};

export const formatReferralCasePayload = (
  data: IAddReferralCaseForm,
  formatDob?: boolean // Format dob is add..since the dob needs to be formatted on new-case-layout and not in old case
): IAddReferralCasePayload => {
  const patient = {
    id: data.patientId,
    firstName: data.firstName,
    lastName: data.lastName,
    dob: formatDob ? formatDate(data.dob ?? '') : data.dob,
    ssn: data.ssn,
    gender: data.gender,
    email: data.email,
    address: {
      line1: data.addressLine1,
      line2: data.addressLine2,
      city: data.city,
      state: data.state,
      zip: data.zip,
      lat: data.lat,
      lng: data.lng,
    },
    note: data.note,
    clientEnrollmentId: data?.clientEnrollmentId,
    tpa: data?.tpa,
    groupName: data?.groupName,
    companyId: data?.companyId || '',
    groupId: data?.groupId || '',
    accumulator: data?.accumulator || ({} as any),
    ...getFormattedContactNumber(data),
    isVip: data?.isVip || false,
  };
  // This is to handle the services from the multiple chip options
  const service: IReferralCaseService[] =
    data?.service?.map((s) => ({
      id: s?.serviceId || '',
      name: s?.serviceName || '',
      code: s?.code || '',
      type: ReferralCaseServiceType.SERVICE,
    })) || [];

  const procedureFormData = {
    id: '',
    name: data?.procedureName || '',
    primaryCode: data?.procedurePrimaryCode || '',
    secondaryCode: data?.procedureSecondaryCode || '',
    description: data?.procedureDescription || '',
    whatsIncluded: data?.whatsIncluded || '',
  };
  const allProcedureFieldsEmpty = isAllFieldsEmpty(procedureFormData);

  const getProcedureData = () => {
    if (data?.procedure?.length) {
      return data?.procedure?.map((item) => ({
        id: item.id || '',
        name: data?.procedureName || item?.name || '',
        primaryCode: data?.procedurePrimaryCode || item?.primaryCode || '',
        secondaryCode:
          data?.procedureSecondaryCode || item?.secondaryCode || '',
        description: data?.procedureDescription || item?.description || '',
        whatsIncluded: data?.whatsIncluded || '',
        type: ReferralCaseServiceType.MEDICAL,
      }));
    }
    if (!allProcedureFieldsEmpty) {
      return [
        {
          ...procedureFormData,
          type: ReferralCaseServiceType.MEDICAL,
        },
      ];
    }
    return [];
  };

  const procedure = getProcedureData();

  const referral = {
    physician: data.physician,
    // facility: {},
  };

  return {
    status: data.status,
    patient,
    service: [...procedure, ...service],
    referral,
    groupId: data.groupId,

    ...(patient.tpa && { tpa: patient.tpa }),
    ...(patient.companyId && { groupId: patient.companyId }),
    ...(patient.groupName && { groupName: patient.groupName }),
    ...(data?.dictionary && {
      dictionary: data.dictionary,
    }),
  };
};

export const formatReferralCaseTimelogPayload = (
  data: IGenericTimelog,
  extraOptions: IReferralCaseTimelogExtraOptions
): IReferralCaseTimelogSchema => {
  const payload = {
    user: data.user,
    date: formatDate(data.date),
    duration: data.duration,
    isLocked: false,
    status: 'Active',
    metaData: {
      client: data.patientId,
      isAfterHour: data.ah,
      ...(extraOptions.groupId && { groupId: extraOptions.groupId }),
    },
  };
  return payload;
};

export const formatUpdateReferralCaseTimelogPayload = (
  data: IGenericTimelog,
  prevData: IReferralCaseTimelog
): IReferralCaseTimelogSchema => {
  const payload = {
    ...prevData,
    user: {
      ...prevData.user,
      ...data.user,
    },
    date: formatDate(data.date),
    duration: data.duration,
    metaData: {
      ...prevData.metaData,
      isAfterHour: data.ah,
    },
  };
  return payload;
};

export const getStatusNameByCode = (
  code: string,
  status: IFetchStatusRow[]
) => {
  if (status) {
    const data = status.find((item) => item.code === code);
    return data?.name;
  }
  return '';
};

export const adaptReferralCaseList = (
  res: IResponse<IListResponse<IReferralCaseList>>
): IResponse<IListResponse<IAdaptedReferralCaseList>> => ({
  ...res,
  data: {
    ...res.data,
    rows: res.data?.rows?.map((x) => ({
      ...x,
      formattedServiceName: getFormattedServicesName(x.service),
      formattedCreatedDate: utcToLocalDateViewFormat(
        x.createdDate,
        config.fullDateViewFormat
      ),
      formattedClosedDate: utcToLocalDateViewFormat(
        x.closedDate,
        config.fullDateViewFormat
      ),
    })),
  },
});

export const adaptReferralCase = (
  res: IResponse<IReferralCase>
): IResponse<IAdaptedReferralCase> => {
  const countryCode = res?.data?.patient?.countryCode;
  const adaptCountryCode = !countryCode ? CountryCodes.USA : countryCode;
  const isCountrySelectedUS = adaptCountryCode === CountryCodes.USA;
  const phone = res?.data?.patient?.phone || '';
  const phoneExtension = res?.data?.patient?.phoneExt;
  const adaptPhoneExtension = !phoneExtension ? '+1' : `+${phoneExtension}`;
  return {
    ...res,
    data: {
      ...res.data,
      ...(res.data.patient && {
        patient: {
          ...res.data.patient,
          lastMembershipTransactionStatus:
            res.data.patient?.alerts?.transaction?.membership?.status,
          lastMembershipTransactionDetail:
            res.data.patient?.alerts?.transaction?.membership,
        },
      }),
      providerOptions: res.data.providerOptions.map((e) => ({
        ...e,
        address: {
          ...e.address,
          addressLine1: e.address?.line1,
          addressLine2: e.address?.line2,
        },
      })),
      formattedFullDateFormat:
        res.data?.createdDate &&
        UTCToLocalDateViewFormat(
          res.data?.createdDate?._d
            ? res.data?.createdDate?._d
            : res.data?.createdDate,
          config.fullDateViewFormat
        ),
      formattedPhone: isCountrySelectedUS
        ? `${adaptedPhoneExtension(phone, adaptPhoneExtension)} ${formatPhone(
            phone
          )}`
        : `${adaptedPhoneExtension(phone, adaptPhoneExtension)} ${phone}`,
    },
  };
};

export const GetRemainingTimeColor = () => {
  const theme = useTheme();
  const remainingTime = useAppSelector(selectCurrentReferralCaseRemainingTime);
  const isGreaterThan30 = remainingTime > 30;
  const isBetween0and30 = remainingTime > 0 && remainingTime <= 30;

  // eslint-disable-next-line no-nested-ternary
  const buttonColor = isBetween0and30
    ? theme.palette.warning.main
    : isGreaterThan30
    ? theme.palette.success.main
    : theme.palette.error.main;
  return buttonColor;
};

export const adaptTaskList = (
  res: IResponse<IListResponse<ITask>>
): IResponse<IListResponse<IAdaptedTask>> => ({
  ...res,
  data: {
    ...res.data,
    rows: res.data?.rows.map((x) => {
      const names = x?.assignedTo?.map((y) => y.name)?.join(', ');
      return {
        ...x,
        formattedDueDate:
          formatDateView(x.dueDate, config.fullDateViewFormat) ?? '',
        formattedAssignedNames: names ?? '',
        formattedTitle: sliceText(x?.title, 50) ?? '',
      };
    }),
  },
});

export const formatTaskPayload = (data: ITaskForm) => {
  const payload = {
    title: data.title,
    description: data.description,
    assignedTo: data?.assignedTo
      ? data.assignedTo.map((x) => ({
          id: x.id,
          name: x.value,
          email: x.email,
        }))
      : [],
    dueDate: data.dueDate,
    reference: {
      id: data.caseId ?? '',
      type: 'referralCase',
    },
    status: data.status,
  };
  return payload;
};

export const adaptCaseTimelog = (
  res: IResponse<IReferralCaseTimelog[]>
): IResponse<IReferralCaseTimelog[]> => ({
  ...res,
  data: res.data.map((e) => ({
    ...e,
    date: unformatDate(e.date || '') || '',
  })),
});

export const getSortedTimelog = (log: IGenericTimelog[]) => {
  const mappedLog = log.map((e) => ({
    ...e,
    date: moment(formatDate(e.date)).toDate(),
  }));

  const ordered = orderBy(mappedLog, 'date', 'desc');
  return ordered.map((e) => ({
    ...e,
    date: unformatDate(formatDateView(e.date || '') || ''),
  }));
};

export const formatDonorInformation = (
  data: IAdaptedClientIndividualTableRow[]
) => {
  const patients = data.map((d) => ({
    type: ReferenceType.CLIENT,
    firstName: d.firstName,
    middleName: d.middleName,
    lastName: d.lastName,
    dob: d.dob,
    phone: d.formattedPhones?.phone || '',
    formattedPhone: d.formattedPhones?.formattedPhone,
    gender: d.gender,
    email: d.email,
    lastSSN: d.lastSSN,
    address: d.address,
    bloodGroup: d.bloodGroup,
    id: d.clientId,
  }));

  return patients;
};

export const getMatchingDonorBloodTypes = (type: string) => {
  switch (type) {
    case BloodGroup.A_POSITIVE:
      return [
        BloodGroup.A_POSITIVE,
        BloodGroup.A_NEGATIVE,
        BloodGroup.O_POSITIVE,
        BloodGroup.O_NEGATIVE,
      ];
    case BloodGroup.A_NEGATIVE:
      return [BloodGroup.A_NEGATIVE, BloodGroup.O_NEGATIVE];
    case BloodGroup.B_POSITIVE:
      return [
        BloodGroup.B_POSITIVE,
        BloodGroup.B_NEGATIVE,
        BloodGroup.O_POSITIVE,
        BloodGroup.O_NEGATIVE,
      ];
    case BloodGroup.B_NEGATIVE:
      return [BloodGroup.B_NEGATIVE, BloodGroup.O_NEGATIVE];
    case BloodGroup.AB_POSITIVE:
      return [
        BloodGroup.A_POSITIVE,
        BloodGroup.O_POSITIVE,
        BloodGroup.B_POSITIVE,
        BloodGroup.AB_POSITIVE,
        BloodGroup.A_NEGATIVE,
        BloodGroup.O_NEGATIVE,
        BloodGroup.B_NEGATIVE,
        BloodGroup.AB_NEGATIVE,
      ];
    case BloodGroup.AB_NEGATIVE:
      return [
        BloodGroup.AB_NEGATIVE,
        BloodGroup.A_NEGATIVE,
        BloodGroup.B_NEGATIVE,
        BloodGroup.O_NEGATIVE,
      ];
    case BloodGroup.O_POSITIVE:
      return [BloodGroup.O_POSITIVE, BloodGroup.O_NEGATIVE];
    case BloodGroup.O_NEGATIVE:
      return [BloodGroup.O_NEGATIVE];
    default:
      return type;
  }
};

export const formatAddReferringPhysicianPayload = (
  data: IReferringPhysicianForm
) => {
  const payload = {
    physician: {
      firstName: data.firstName || '',
      middleName: data.middleName || '',
      lastName: data.lastName || '',
      npi: data.physicianNPI || '',
      designation: data.designation || '',
      dob: data.dob || '',
      lastSSN: data?.lastSSN || '',
      gender: data?.gender || '',
      primaryTaxonomy: [{ name: data.primaryTaxonomy }],
    },
    facility: {
      address: {
        line1: data.addressLine1 || '',
        line2: data.addressLine2 || '',
        city: data.city || '',
        state: data.state || '',
        zip: data.zip || '',
      },
      email: data.email || '',
      phone: data.phone || '',
      npi: data.facilityNPI || '',
      ein: data.ein || '',
      fax: data.fax || '',
      name: data.name || '',
    },
  };
  return payload;
};
export const formatAddProviderPayload = (
  data: IReferralCaseProviderOptionFrom
): IReferralCaseProviderOptionPayload => {
  const payload = {
    address: {
      line1: data.addressLine1 || '',
      line2: data.addressLine2 || '',
      city: data.city || '',
      state: data.state || '',
      zip: data.zip || '',
    },
    email: data.email || '',
    phone: data.phone || '',
    note: data.note || '',
    npi: data.npi || '',
    name: data.name || '',
    ein: data?.ein || '',
    quality: 0,
    serviceOption: VitafyProviderServiceType.InNetwork,
    ...(data?.location && { location: data.location }),
    costs: [
      {
        type: VitafyProviderCostType.StateAvg,
        amount: 0,
      },
    ],
    incentive: {
      type: '',
      amount: 0,
    },
  };
  return payload;
};
export const formatProviderPayload = (
  data: IReferralCaseProviderOptionFrom,
  prevData: IReferralCaseProviderOption
): IReferralCaseProviderOptionPayload => {
  const payload = {
    ...prevData,
    address: {
      line1: data.addressLine1 || '',
      line2: data.addressLine2 || '',
      city: data.city || '',
      state: data.state || '',
      zip: data.zip || '',
    },
    email: data.email || '',
    phone: data.phone || '',
    note: data.note || '',
    npi: data.npi || '',
    name: data.name || '',
    ein: data.ein || '',
  };
  return payload;
};
export const formatProviderCostPayload = (
  data: ICaseProviderCostSchema,
  prevData: IReferralCaseProviderOption
): IReferralCaseProviderOptionPayload => {
  const stateAverageData =
    prevData?.costs?.filter(
      (e) => e.type === VitafyProviderCostType.StateAvg
    ) || [];
  const payload = {
    ...prevData,
    costs: [
      ...stateAverageData,
      {
        amount: Number(data.cost) || 0,
        type: data.costType?.value || '',
      },
    ],
    incentive: {
      amount: Number(data.incentiveValue) || 0,
      type: data.incentiveType?.value || '',
    },
    serviceOption: data.serviceOption?.value || '',
  };
  return payload;
};

const mapAlithiasToVitafyServiceType = (type: string) => {
  if (type === AlithiasCostType.Cash) {
    return VitafyProviderServiceType.Cash;
  }
  if (type === AlithiasCostType.Contract)
    return VitafyProviderServiceType.DirectContract;

  return VitafyProviderServiceType.InNetwork;
};

const mapALithiasToVitafyCostType = (type: string) => {
  if (type === AlithiasCostType.Likely) return VitafyProviderCostType.Claims;
  if (type === AlithiasCostType.Cash) {
    return VitafyProviderCostType.Cash;
  }
  if (type === AlithiasCostType.Contract)
    return VitafyProviderCostType.DirectContract;
  return '';
};

export const formatAddProviderFromSmartSearchPayload = (
  data: ISmartSearchProvider[]
) => {
  const payload = data.map((e) => ({
    address: {
      line1: e?.facility?.address?.addressLine1 || '',
      line2: e?.facility?.address?.addressLine2 || '',
      city: e?.facility?.address?.city || '',
      state: e?.facility?.address?.state || '',
      zip: e?.facility?.address?.zip || '',
    },
    ...(e?.facility?.location && { location: e.facility.location }),
    email: e?.facility?.email || '',
    phone: e?.facility?.phone || '',
    note: '',
    npi: e?.facility?.npi || '',
    fax: e?.facility?.fax || '',
    name: e?.facility?.displayName || e?.facility?.businessName || '',
    id: e._id,
    quality: e.quality || 0,
    serviceOption: mapAlithiasToVitafyServiceType(e?.price?.type || ''),
    // searchType: ProviderSearchType.Smart,
    costs: [
      {
        type: VitafyProviderCostType.StateAvg,
        amount: e.providerStateAverage || 0,
      },
      {
        type: mapALithiasToVitafyCostType(e.price?.type || ''),
        amount: e?.price?.amount || 0,
      },
    ],
    incentive: {
      type: '',
      amount: e?.incentiveValue || 0,
    },
  }));

  return payload;
};
export const adaptCaseGroupSelect = (res: IResponse<ICaseGroupSelect>) => ({
  ...res,
  data: {
    ...res.data,
    details: res.data?.details?.map((x) => ({
      ...x,
      label: `${x.name} - ${x.type}`,
      value: x.clientId,
    })),
  },
});

export const getFormattedStatus = (status: string, theme: Theme) => {
  switch (status) {
    case ReferralCaseStatus.REQUESTED.toUpperCase():
      return {
        color: theme.palette.info.main,
        backgroundColor: theme.palette.info.lighter,
      };
    case ReferralCaseStatus.ACKNOWLEDGED.toUpperCase():
      return {
        color: theme.palette.info.dark,
        backgroundColor: theme.palette.info.lighter,
      };
    case ReferralCaseStatus.INPROGRESS.toUpperCase():
      return {
        color: theme.palette.info.dark,
        backgroundColor: theme.palette.info.lighter,
      };
    case ReferralCaseStatus.COMPLETED.toUpperCase():
      return {
        color: theme.palette.success.main,
        backgroundColor: theme.palette.success.lighter,
      };
    case ReferralCaseStatus.CLOSED.toUpperCase():
      return {
        color: theme.palette.gray.main,
        backgroundColor: theme.palette.gray.lighter,
      };
    case ReferralCaseStatus.DELETED.toUpperCase():
      return {
        color: theme.palette.error.main,
        backgroundColor: theme.palette.error.lighter,
      };
    default:
      return {
        color: theme.palette.info.main,
        backgroundColor: theme.palette.info.lighter,
      };
  }
};

export const handleNullableString = (value?: string) =>
  !value || value === 'null' ? '' : value;

export const formatCasePatientUpdatePayload = (
  data: IReferralCasePatientInformationForm,
  additionalDetails?: {
    clientId?: string;
    dictionary?: { key: string; value: string[] }[];
    relationship?: IRelationship;
    parent?: any;
  }
) => {
  const { phones } = getFormattedContactNumber(data);
  const dob = formatDate(data.dob ?? '');
  const payload: IReferralCasePatientInformationSchema = {
    firstName: data.firstName,
    lastName: data.lastName,
    ssn: data.ssn,
    gender: data.gender,
    email: data.email,
    address: {
      line1: data.addressLine1,
      line2: data.addressLine2,
      city: data.city,
      state: data.state,
      zip: data.zip,
      lat: data.lat,
      lng: data.lng,
    },
    note: data.note,
    bloodGroup: data.bloodGroup,
    groupName: data?.groupName,
    phones,
    dob,
    ...(additionalDetails?.clientId && { id: additionalDetails?.clientId }),
    ...(additionalDetails?.dictionary && {
      dictionary: additionalDetails?.dictionary,
    }),
    ...(additionalDetails?.relationship && {
      relationship: additionalDetails.relationship,
      parent: additionalDetails.parent,
    }),
    isVip: data?.isVip || false,
  };

  return payload;
};

/**
 * Extract caseType and callRegarding
 *
 * @input
 * "Benefit Support | Ancillary Policies,Benefit Support | Appointment Scheduling"
 *
 * @output
 * caseType: "Benefit Support"
 * callRegarding: "Ancillary Policies, Appointment Scheduling"
 *
 */
export const extraCaseTypeFromCallRegarding = (caseTypes: string) => {
  const caseTypesSet = new Set<string>();
  const callRegardingSet = new Set<string>();

  const pairs = caseTypes.split(',');
  pairs.forEach((pair) => {
    const [caseType, callRegarding] = pair.split('|').map((s) => s.trim());
    caseTypesSet.add(caseType);
    callRegardingSet.add(callRegarding);
  });

  const caseType = Array.from(caseTypesSet).join(', ');
  const callRegarding = Array.from(callRegardingSet).join(', ');

  return { caseType, callRegarding };
};

export const getPatientFormData = (
  data: IAdaptedReferralCasePatient | IAdaptedClient
) => {
  const phones = getContactNumbers(data.phones ?? []);
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    dob: data.dob ? unformatDate(data.dob) : data.dob,
    ssn:
      (data as IAdaptedReferralCasePatient)?.ssn ||
      (data as IAdaptedClient)?.lastSSN,
    gender: data?.gender,
    email: data?.email,
    bloodGroup: data?.bloodGroup,
    // line1 and line 2 might be deprecated if the new referral case is integrated
    addressLine1:
      data?.address?.line1 ||
      (data?.address as any)?.addressLine1 ||
      (data as IAdaptedReferralCasePatient)?.addressLine1 ||
      '',
    addressLine2:
      data?.address?.line2 ||
      (data?.address as any)?.addressLine2 ||
      (data as IAdaptedReferralCasePatient)?.addressLine2 ||
      '',
    city: data.address?.city || (data as IAdaptedClient)?.address?.city || '',
    state:
      data.address?.state || (data as IAdaptedClient)?.address?.state || '',
    zip: data.address?.zip || (data as IAdaptedClient)?.address?.zip || '',
    lat:
      (data as IAdaptedReferralCasePatient)?.lat?.toString() ||
      (data as IAdaptedClient)?.address?.lat?.toString() ||
      '',
    lng:
      (data as IAdaptedReferralCasePatient)?.lng?.toString() ||
      (data as IAdaptedClient)?.address?.lng?.toString() ||
      '',
    note: data.note,
    companyId:
      data?.relationship?.groupId ||
      (data as IAdaptedReferralCasePatient)?.companyId ||
      '',
    groupName:
      (data as IAdaptedReferralCasePatient)?.relationship?.grandParentName ||
      (data as IAdaptedReferralCasePatient)?.groupName ||
      (data as IAdaptedClient)?.relationship?.groupName ||
      '',
    isVip: data?.isVip || false,
    ...phones,
  };
};

export const formatCaseConfigurableSettingsPayload = (
  data: ITenantSettingItem
) => ({
  settings: [
    {
      code: data.code,
      value: data.value,
    },
  ],
});

export const prepareDataForTagNote = (emailToken: IEmailToken) => {
  const { sender, receiver, metadata } = emailToken;

  const userTypeToReferenceTypeMap = {
    [EmailUserType.ADMIN]: ReferenceType.TENANT,
    [EmailUserType.TENANT]: ReferenceType.TENANT,
    [EmailUserType.PATIENT]: ReferenceType.CLIENT,
    [EmailUserType.THIRD_PARTY]: ReferenceType.EMAIL,
    [EmailUserType.AGENT]: ReferenceType.AGENT,
  };

  const userTypeToSenderReferenceId = {
    [EmailUserType.ADMIN]: sender.tenantId,
    [EmailUserType.TENANT]: sender.tenantId,
    [EmailUserType.PATIENT]: sender.clientId,
    [EmailUserType.THIRD_PARTY]: sender.tenantId,
    [EmailUserType.AGENT]: sender.tenantId,
  };

  const userTypeToReceiverReferenceId = {
    [EmailUserType.ADMIN]: receiver.tenantId,
    [EmailUserType.TENANT]: receiver.tenantId,
    [EmailUserType.PATIENT]: receiver.clientId,
    [EmailUserType.THIRD_PARTY]: receiver.email,
    [EmailUserType.AGENT]: sender.tenantId,
  };

  return {
    referenceType: 'ReferralCase',
    referenceId: metadata.caseId,
    email: receiver.email,
    id: receiver.userId,
    tenantId: receiver.tenantId,
    name: receiver.name,
    patientName: '',
    requestedDate: '',
    requestedReferenceType: userTypeToReferenceTypeMap[sender.type],
    requestedReferenceId: userTypeToSenderReferenceId[sender.type],
    requestedTenantId: sender.tenantId,
    requestedUserId: sender.userId,
    patientId: '',
    userReference: {
      referenceType: userTypeToReferenceTypeMap[receiver.type],
      referenceId: userTypeToReceiverReferenceId[receiver.type],
    },
    taggedUser: {
      name: sender.name,
      email: sender.email,
      id: sender.userId,
    },
    userId: receiver.userId,
    type: 'TAG_NOTE',
  };
};

export const prepareDataForShareFile = (emailToken: IEmailToken) => {
  const { sender, receiver, metadata } = emailToken;

  const userTypeToReferenceTypeMap = {
    [EmailUserType.ADMIN]: ReferenceType.TENANT,
    [EmailUserType.TENANT]: ReferenceType.TENANT,
    [EmailUserType.PATIENT]: ReferenceType.CLIENT,
    [EmailUserType.THIRD_PARTY]: ReferenceType.EMAIL,
    [EmailUserType.AGENT]: ReferenceType.AGENT,
  };

  const userTypeToReceiverReferenceId = {
    [EmailUserType.ADMIN]: receiver.tenantId,
    [EmailUserType.TENANT]: receiver.tenantId,
    [EmailUserType.PATIENT]: receiver.clientId,
    [EmailUserType.THIRD_PARTY]: receiver.email,
    [EmailUserType.AGENT]: sender.tenantId,
  };

  return {
    lastName: receiver.lastName,
    firstName: receiver.firstName,
    name: receiver.name,
    email: receiver.email,
    type: 'SHARE_FILE',
    documentId: metadata.fileId,
    referenceId: metadata.fileAssociationUUID,
    associationId: metadata.fileAssociationUUID,
    tenantId: sender.tenantId,
    userReference: {
      referenceType: userTypeToReferenceTypeMap[receiver.type],
      referenceId: userTypeToReceiverReferenceId[receiver.type],
    },
    taggedUser: {
      name: sender.name,
      email: sender.email,
      id: sender.userId,
      tenantid: sender.tenantId,
    },
    clientId: '',
    requestedDate: '',
    patientName: '',
  };
};

export const prepareDataForShareCase = (emailToken: IEmailToken) => {
  const { receiver, metadata } = emailToken;

  const userTypeToReferenceTypeMap = {
    [EmailUserType.TENANT]: ReferenceType.TENANT,
    [EmailUserType.THIRD_PARTY]: ReferenceType.EMAIL,
  };

  const userTypeToReceiverReferenceId = {
    [EmailUserType.TENANT]: receiver.tenantId,
    [EmailUserType.THIRD_PARTY]: receiver.email,
  };

  const receiverType = receiver.type as
    | EmailUserType.TENANT
    | EmailUserType.THIRD_PARTY;

  return {
    type: 'SHARED_CASE',
    caseId: metadata.caseId,
    email: receiver.tenantId,
    name: receiver.name,
    // firstName: 'ReforMedicine, S.C.',
    // lastName: 'ReforMedicine, S.C.',
    referenceType: 'ReferralCase',
    referenceId: metadata.caseId,
    tenantId: receiver.tenantId,
    userReference: {
      referenceId: userTypeToReceiverReferenceId[receiverType],
      referenceType: userTypeToReferenceTypeMap[receiverType],
    },
  };
};
