import { TenantGroupCode } from 'enums/tenant-management/tenant';
import { selectAuthTenantAssociation } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

import { useCheckUserIsTenantAdmin } from './useCheckUserIsTenantAdmin';

export const useCheckUserIsV360Admin = () => {
  const tenantAssociation = useAppSelector(selectAuthTenantAssociation);
  const tenantCode = tenantAssociation?.code;
  const isV360Admin =
    useCheckUserIsTenantAdmin() && tenantCode === TenantGroupCode.V360;
  return isV360Admin;
};
