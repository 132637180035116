import { SyntheticEvent, useEffect, useMemo, useState } from 'react';

// TODO: To use Existing tenantAutoComplete
import {
  Autocomplete,
  Box,
  CircularProgress,
  debounce,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import commonConstants from 'constants/common';
import { IFilter } from 'interfaces/common';
import { IAdaptedTenantTableRow } from 'interfaces/tenant-management/tenant';
import { IAssignedTenantFilter } from 'interfaces/tenant-management/user';
import { useTenantQuery } from 'services/tenant-management/tenant';

const { DEFAULT_TABLE_FILTER } = commonConstants;

interface ITenantAutoCompleteProps {
  handleChange: (t: IAdaptedTenantTableRow) => void;
  label?: string;
  placeholder?: string;
  textFieldProps?: TextFieldProps;
  handleOnInputChange?: (
    event: SyntheticEvent<Element, Event>,
    newValue: string
  ) => void;
  selectedValue?: string;
  inputProps?: TextFieldProps & { 'data-cy'?: string };
}

const TenantAutocomplete = ({
  handleChange,
  label,
  placeholder,
  textFieldProps,
  handleOnInputChange,
  selectedValue,
  inputProps,
}: ITenantAutoCompleteProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<IAdaptedTenantTableRow | null>(null);
  const [filters, setFilters] = useState<IFilter & IAssignedTenantFilter>({
    ...DEFAULT_TABLE_FILTER,
    assignTenants: 0,
  });

  const tenantsQuery = useTenantQuery(filters);

  const handleInputChange = async (
    event: SyntheticEvent<Element, Event>,
    newInputValue: string
  ) => {
    if (newInputValue.length > 2 || !newInputValue.length)
      setFilters((prevState) => ({ ...prevState, keyword: newInputValue }));
  };
  const onChange = async (tenant: IAdaptedTenantTableRow | undefined) => {
    if (tenant) handleChange(tenant);
  };

  const debouncedInputChange = useMemo(
    () => debounce(handleInputChange, 300),
    []
  );

  const options = useMemo(
    () => tenantsQuery?.data?.rows || [],
    [tenantsQuery?.data?.rows]
  );

  useEffect(() => {
    if (selectedValue && options) {
      const v = options.find((e) => e.tenantId === selectedValue);
      if (v) setValue(v);
    } else {
      setValue(null);
    }
  }, [options, selectedValue]);
  return (
    <Autocomplete
      autoComplete
      filterOptions={(x) => x}
      filterSelectedOptions
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : `${option.businessName}`
      }
      id="async-tenant-autocomplete"
      includeInputInList
      isOptionEqualToValue={(option, newValue) =>
        option.tenantId === newValue.tenantId
      }
      loading={tenantsQuery.isLoading}
      onChange={(
        event: SyntheticEvent<Element, Event>,
        newValue: IAdaptedTenantTableRow | null
      ) => {
        // setOptions(newValue ? [newValue, ...options] : options);
        onChange(newValue || undefined);
        setValue(newValue);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={(e, v) => {
        if (handleOnInputChange) handleOnInputChange(e, v);
        debouncedInputChange(e, v);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      openOnFocus={false}
      options={options}
      renderInput={(params) => (
        // You can use different variations of textfield here.
        // Please refer TextfieldOverview.tsx for reference.
        <TextField
          {...params}
          {...inputProps}
          fullWidth
          InputProps={{
            ...params.InputProps,
            ...inputProps?.InputProps,
            endAdornment: (
              <>
                {tenantsQuery.isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            ...params.inputProps,
            ...inputProps?.inputProps,
          }}
          label={label}
          placeholder={placeholder}
          size="small"
          variant="standard"
          {...textFieldProps}
        />
      )}
      renderOption={(props, option) => (
        /* eslint-disable react/jsx-props-no-spreading */
        <Box
          {...props}
          component="li"
          key={option.tenantId}
          sx={{
            '&:hover': {
              color: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          <Typography color="inherit" gutterBottom={false} variant="body2">
            {option.businessName}
          </Typography>
        </Box>
      )}
      value={value}
    />
  );
};

TenantAutocomplete.defaultProps = {
  label: '',
  placeholder: 'Type name or email of tenant',
  textFieldProps: {},
  handleOnInputChange: undefined,
  selectedValue: undefined,
  inputProps: null,
};
export default TenantAutocomplete;
