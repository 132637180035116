import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  enableThirdPartyIntegration,
  getThirdPartyIntegration,
} from 'api/thirdparty-integration';
import { IError } from 'interfaces/http';
import {
  IThirdPartyIntegration,
  IThirdPartyIntegrationPayload,
} from 'interfaces/misc';
import { useSnackbar } from 'notistack';

export const thirdPartyIntegrationkeys = {
  all: ['third-party-integration'] as const,
  lists: () => [...thirdPartyIntegrationkeys.all, 'list' as const],
};

export const useThirdPartyIntegrationQuery = ({
  tenantId,
  enabled,
}: {
  tenantId: string;
  enabled: boolean;
}) => {
  const queryInfo = useQuery(
    thirdPartyIntegrationkeys.lists(),
    () => getThirdPartyIntegration(tenantId),
    {
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useEnableThirdPartyIntegrationMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(
    ({
      data,
      tenantId,
    }: {
      data: IThirdPartyIntegrationPayload[];
      tenantId: string;
    }) => enableThirdPartyIntegration(tenantId, data),
    {
      onSuccess(res) {
        const queryKey = thirdPartyIntegrationkeys.lists();
        const queryData = queryClient.getQueryData(queryKey);
        if (!queryData) return;
        queryClient.setQueryData(queryKey, (oldData: any) => ({
          ...oldData,
          data: oldData.data.map((item: IThirdPartyIntegration) => {
            const sameId = res?.data?.find((data) => data.code === item.code);
            if (sameId) {
              return { ...item, isEnabled: sameId.isEnabled };
            }
            return item;
          }),
        }));
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};
