import { selectAuth } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

export const useGetAccessType = ({
  parentCode,
  childCode,
}: {
  parentCode: string;
  childCode?: string;
}) => {
  const authData = useAppSelector(selectAuth);
  const allMenus = authData.menus;
  const parentResource = allMenus.find((item) => item.code === parentCode);

  const isChildExists = !!parentResource?.children?.length;

  return {
    parentResourceAccessType: parentResource?.accessType,
    ...(isChildExists &&
      childCode && {
        childResourceAccessType: parentResource.children?.find(
          (i) => i.code === childCode
        )?.accessType,
      }),
  };
};
