import {
  ManageMembershipActionStep,
  ManageMembershipActionType,
} from 'enums/client-management/manage-membership';
import {
  selectManageMembershipActionStep,
  selectManageMembershipActionType,
} from 'stores/client-management/manage-membership';
import { useAppSelector } from 'stores/hooks';

export const useMembershipAction = () => {
  const manageMembershipActionType = useAppSelector(
    selectManageMembershipActionType
  );
  const manageMembershipActionStep = useAppSelector(
    selectManageMembershipActionStep
  );

  const checkActionType = (type: ManageMembershipActionType | null) =>
    manageMembershipActionType === type;

  const checkActionStep = (step: ManageMembershipActionStep | null) =>
    manageMembershipActionStep === step;

  return {
    actionType: manageMembershipActionType,
    actionStep: manageMembershipActionStep,
    checkActionType,
    checkActionStep,
  };
};
