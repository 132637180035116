import DOMPurify from 'dompurify';
import _truncate from 'lodash/truncate';

// This is commented for now it may be used for future

// export const parseHtml = (html: string, chars?: number) => {
//   if (chars) {
//     return _truncate(DOMPurify.sanitize(html), { length: chars });
//   }
//   return DOMPurify.sanitize(html);
// };

export const parseHtml = (html: string, chars?: number) => {
  // Define a hook to modify the attributes or styles of <a> and <p> tags
  DOMPurify.addHook('afterSanitizeAttributes', (node) => {
    if (node.nodeName.toLowerCase() === 'a') {
      // Allow target="_blank" in <a> tags
      node.setAttribute('target', '_blank');
      // Optionally, you can set rel="noopener noreferrer" for security
      node.setAttribute('rel', 'noopener noreferrer');
    } else if (node.nodeName.toLowerCase() === 'p') {
      // Apply custom styles to <p> tags
      node.setAttribute('style', 'margin:16px'); // Example styles
    }
  });

  if (chars) {
    return _truncate(DOMPurify.sanitize(html), { length: chars });
  }
  return DOMPurify.sanitize(html);
};
