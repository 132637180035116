import { useMemo } from 'react';

import { selectCaseFilter } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';

export const useRelevantCombinedFilter = () => {
  const filters = useAppSelector(selectCaseFilter);

  const combinedFilters = useMemo(
    () => ({
      ...filters,
      relevantSearch: true,
      limit: 100,
    }),
    [filters]
  );

  return { combinedFilters };
};
