import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Box,
  Popover,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AvatarPlaceholder from 'assets/images/svg/vitafy-logo-partial.svg';
import commonConstants from 'constants/common';
import uiRoutes from 'constants/uiRoutes';
import { TenantStatus } from 'enums/auth';
import { useCheckUserIsClientGroup } from 'hooks/useCheckUserIsClientGroup';
import { useGetParamsForTenantSwitch } from 'hooks/useGetParamsForTenantSwitch';
import { useRevertImpersonateMutation } from 'services/auth';
import { useSwitchTenantMutation } from 'services/tenant-management/tenant';
import {
  useAssignedTenantQuery,
  useUserRolesQuery,
} from 'services/tenant-management/users';
import {
  selectAuthTenantAssociation,
  selectAuthTenantData,
  selectAuthUserClient,
} from 'stores/auth';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { setAuthState } from 'utils/auth';
import { truncateText } from 'utils/common';
import { clearLocal, clearSession, getLocal, removeLocal } from 'utils/storage';

import { HeaderPopover } from './HeaderPopover';

const HeaderProfile = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const revertImpersonateMutation = useRevertImpersonateMutation();

  const tenantData = useAppSelector(selectAuthTenantData);
  const authUserClient = useAppSelector(selectAuthUserClient);
  const authTenantAssociation = useAppSelector(selectAuthTenantAssociation);
  const tenantId = authTenantAssociation?.tenantId || '';
  const isUserClientGroup = useCheckUserIsClientGroup();
  const userId = tenantData?.userId ?? '';
  const authData = useAppSelector(selectAuthTenantData);

  const { getParamsForTenantSwitch } = useGetParamsForTenantSwitch();
  const getParamsForTenantSwitchRef = useRef(getParamsForTenantSwitch);

  const smBreakPoint = useMediaQuery(theme.breakpoints.down('sm'));

  const open = Boolean(anchorEl);
  const id = open ? 'header-profile-popover' : undefined;

  // const params: IUserTenantsFilter = {
  //   referenceType: isClientSponsor ? ReferenceType.CLIENT : undefined,
  //   referenceId:
  //     isClientSponsor && authTenantAssociation
  //       ? authTenantAssociation.referenceId
  //       : undefined,
  // };

  const tenantQuery = useAssignedTenantQuery(
    userId,
    {
      enabled: !!userId,
    }
    // params
  );

  const userRolesQuery = useUserRolesQuery(userId, {
    enabled: !!userId,
  });

  const tenantMutation = useSwitchTenantMutation();

  const tenantList = useMemo(
    () =>
      tenantQuery?.data
        ?.filter((f) => f.status === TenantStatus.ACTIVE)
        .map((item) => ({
          // eslint-disable-next-line no-underscore-dangle
          id: item._id,
          label: item.displayName || item.businessName,
          tenantId: item.tenantId,
          code: item.code,
          referenceId: item?.referenceId,
        })),
    [tenantQuery?.data]
  );
  const uniqueTenantList = tenantList?.filter(
    (item, index, self) =>
      index === self.findIndex((t) => t.tenantId === item.tenantId)
  );
  const userRolesList = userRolesQuery?.data?.roles?.filter(
    (i) => i.status === TenantStatus.ACTIVE && i.tenantId === tenantId
  );

  /**
   * A bug is present in the app where the tenants for the tenant switch is not fetched properly.
   * This bug occurs occassionally.
   *
   * Fetching the tenant list every time the popover is opened is just a workaround, not the actual solution.
   *
   */
  useEffect(() => {
    if (open) {
      tenantQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => setAnchorEl(null);

  const onTenantSwitch = (newTenantId: string, _newReferenceId?: string) => {
    const mutationParams = getParamsForTenantSwitchRef.current({ newTenantId });

    tenantMutation.mutate(
      { userId, tenantId: newTenantId, params: mutationParams },
      {
        onSuccess: (response) => {
          if (response.success) {
            setAuthState({
              token: response.data.token,
              permissions: response.data.permissions,
              dispatch,
            });
            // Note: the user is navigated to the base route (`/`) for now.
            // Ideally, the user should be navigated to the same route from which the tenant swtich was done and
            // should be taken to the base route only if there is no access to the current route.

            // Disabled route check
            // const availableRoutes = getRoutePathsFromPermissions(
            //   response.data.permissions
            // );
            // const routeToNavigate =
            //   checkIfCurrentRouteIsAccessible(availableRoutes);
            // navigate(
            //   routeToNavigate.canAccessRoute
            //     ? routeToNavigate.route
            //     : uiRoutes.index
            // );

            navigate(uiRoutes.index);
            window.location.reload();
            handleClose();
          }
        },
      }
    );
  };

  const onRolesSwitch = (
    label: string,
    referenceId: string,
    referenceSubType: string
  ) => {
    const mutationParams = {
      referenceId,
      referenceType: label,
      referenceSubType,
    };
    tenantMutation.mutate(
      {
        userId: authData?.userId || '',
        tenantId: authTenantAssociation?.tenantId || '',
        params: mutationParams,
      },
      {
        onSuccess: (response) => {
          if (response.success) {
            setAuthState({
              token: response.data.token,
              permissions: response.data.permissions,
              dispatch,
            });
            navigate(uiRoutes.index);
            window.location.reload();
            handleClose();
          }
        },
      }
    );
  };

  const showRevertImpersonate = () => {
    const isOldTokenExists = getLocal(commonConstants.OLD_TOKEN_REFERENCE);
    if (isOldTokenExists) return true;
    return false;
  };

  const handleRevertImpersonate = () => {
    const oldToken = getLocal(commonConstants.OLD_TOKEN_REFERENCE);
    revertImpersonateMutation.mutate(
      { data: { token: oldToken } },
      {
        onSuccess: (response) => {
          if (response?.data?.token) {
            setAuthState({
              token: response.data.token,
              permissions: response.data.permissions,
              dispatch,
            });
            navigate(uiRoutes.index);
            removeLocal(commonConstants.OLD_TOKEN_REFERENCE);
            removeLocal(commonConstants.IMPERSONATED_BY);
            window.location.reload();
            handleClose();
          }
        },
      }
    );
  };
  const handleLogout = () => {
    clearLocal();
    clearSession();
    navigate(uiRoutes.auth.logout);
  };

  const openPopOver = (event: React.MouseEvent<HTMLDivElement>) =>
    setAnchorEl(event.currentTarget);

  const getHeaderProfileName = (impersonate = false) => {
    if (showRevertImpersonate() && impersonate) {
      return `Welcome ${getLocal(commonConstants.IMPERSONATED_BY)}`;
    }
    if (isUserClientGroup && authUserClient?.businessName) {
      return `${tenantData?.name} (${authUserClient.businessName})`;
    }
    return tenantData?.name;
  };
  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        onClick={openPopOver}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: theme.palette.gray.lighter,
            opacity: [0.9, 0.8, 0.7],
          },
          padding: '2px 15px 2px 15px',
          // marginRight: smBreakPoint ? '0px' : '52px',
        }}
      >
        {tenantData && (
          <>
            <Avatar
              alt={
                isUserClientGroup
                  ? authUserClient?.businessName
                  : tenantData?.name
              }
              src={AvatarPlaceholder}
              sx={{ alignSelf: 'center' }}
            />
            <div
              style={{
                marginLeft: 10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Tooltip placement="top" title={getHeaderProfileName() || ''}>
                <Typography
                  color={theme.palette.text.primary}
                  data-cy="tenant-name"
                  fontWeight={theme.typography.fontWeightMedium}
                  gutterBottom={false}
                  variant={smBreakPoint ? 'caption' : 'body1'}
                >
                  {truncateText(
                    getHeaderProfileName(showRevertImpersonate()) || '',
                    25
                  )}
                </Typography>
              </Tooltip>

              {showRevertImpersonate() ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Box component={FontAwesomeIcon} icon={faEye} size="xs" />
                  <Tooltip placement="top" title={getHeaderProfileName() || ''}>
                    <Typography
                      fontWeight={theme.typography.fontWeightMedium}
                      gutterBottom={false}
                      variant="body2"
                    >
                      {truncateText(getHeaderProfileName() || '', 25)}
                    </Typography>
                  </Tooltip>
                </Box>
              ) : (
                <Typography
                  gutterBottom={false}
                  variant={smBreakPoint ? 'caption' : 'body2'}
                >
                  {tenantData?.tenantAssociation?.displayName}
                </Typography>
              )}
            </div>
          </>
        )}
      </Box>
      <Popover
        anchorEl={anchorEl}
        id={id}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            mt: 6,
            p: 2,
            minWidth: 250,
            overflow: 'inherit',
            boxShadow: theme.customShadows.high,
          },
        }}
      >
        <HeaderPopover
          handleLogout={handleLogout}
          handleRevertImpersonate={handleRevertImpersonate}
          isImpersonated={showRevertImpersonate()}
          isRevertLoading={revertImpersonateMutation.isLoading}
          isRolesSwitched={tenantMutation.isSuccess}
          onRolesSwitch={onRolesSwitch}
          onTenantSwitch={onTenantSwitch}
          tenantList={uniqueTenantList ?? []}
          userRoles={userRolesList || []}
        />
      </Popover>
    </>
  );
};

export default HeaderProfile;
