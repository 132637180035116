export enum MessageStatus {
  SENT = 'SENT',
  UNSENT = 'UNSENT',
  DELEVIRED = 'DELEVIRED',
  SEEN = 'SEEN',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  SENDING = 'SENDING',
}

export enum TenantConnStatus {
  AVAILABLE = 'available',
  OFFLINE = 'offline',
}
