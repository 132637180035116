export enum AccessType {
  WRITE = 'Write',
  READ = 'Read',
  DENY = 'Deny',
}
export enum MenuResourceCodes {
  BILLING = 'BILLING',
  SETTING_VITAFY_BILLING = 'SETTING-VITAFY-BILLING',
  DASHBOARD = 'DASHBOARD',
  SETTING = 'SETTING',
  SETTING_ADMIN_ROLES = 'SETTING_ADMIN_ROLES',
  SETTING_CONTACT = 'SETTING_CONTACT',
  SETTING_COMMS_TEMPLATE = 'SETTING-COMMS-TEMPLATE',
  SETTING_INTEGRATIONS = 'SETTING_INTEGRATIONS',
}

export enum TenantStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  INVITED = 'INVITED',
}
