import React, { useEffect } from 'react';

import { MembershipCommonContentWrapper } from 'common/manage-membership/common/MembershipCommonContentWrapper';
import { useCheckGroupMembership } from 'common/manage-membership/hooks/useCheckGroupMembership';
import { useGetCurrentSelectedClientAndMembershipDetails } from 'common/manage-membership/hooks/useGetCurrentSelectedClientAndMembershipDetails';
import { useGetManageMembershipActionType } from 'common/manage-membership/hooks/useGetManageMembershipActionType';
import { ManageMembershipType } from 'enums/client-management/manage-membership';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { setManageMembershipType } from 'stores/client-management/manage-membership';
import { useAppDispatch } from 'stores/hooks';

import { AddMembershipAlertView } from './AddMembershipAlertView';
import { ChooseMembershipType } from './ChooseMembershipType';
import { ChooseStartDate } from './ChooseStartDate';
import { MembershipOptions } from './MembershipOptions';
import { TopInformationView } from './TopInformationView';

export const MembershipSelectableView = ({
  excludeTopInformation,
  excludeAlert,
}: {
  excludeTopInformation?: boolean;
  excludeAlert?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const isUserClient = useCheckUserIsClient();
  const {
    selectedMembershipIsOfGroupId,
    isClientTypeGroup,
    loggedInUserAsGroup,
  } = useGetCurrentSelectedClientAndMembershipDetails();

  const { isEditMembership } = useGetManageMembershipActionType();

  const { groupMembership } = useCheckGroupMembership();

  useEffect(() => {
    if (
      (!!selectedMembershipIsOfGroupId && isEditMembership) ||
      isClientTypeGroup ||
      loggedInUserAsGroup
    ) {
      dispatch(setManageMembershipType(ManageMembershipType.GROUP));
    }
  }, [
    dispatch,
    isClientTypeGroup,
    isEditMembership,
    loggedInUserAsGroup,
    selectedMembershipIsOfGroupId,
  ]);

  const displayMembershipType = () => {
    if (
      !isEditMembership &&
      !isClientTypeGroup &&
      !loggedInUserAsGroup &&
      !(isUserClient && !groupMembership)
    )
      return <ChooseMembershipType />;
    return '';
  };

  return (
    <MembershipCommonContentWrapper>
      {!excludeTopInformation && <TopInformationView />}
      {displayMembershipType()}
      <MembershipOptions />
      <ChooseStartDate />
      {!excludeAlert && <AddMembershipAlertView />}
    </MembershipCommonContentWrapper>
  );
};

MembershipSelectableView.defaultProps = {
  excludeTopInformation: false,
  excludeAlert: false,
};
