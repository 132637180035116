import { ClientEnrollmentStatus } from 'enums/client-management';
import { IAdaptedClientEnrollmentMembership } from 'interfaces/client-management/enrollment';
import { IAdaptedTenantMembershipOfferingTableRow } from 'interfaces/tenant-management/tenant/tenantMembershipOffering';

export const filterMembershipByEnrollment = (
  dataToFilter: IAdaptedTenantMembershipOfferingTableRow[],
  clientMemberships: IAdaptedClientEnrollmentMembership[]
) =>
  dataToFilter.filter((e) => {
    const previousCancelledOrDeclined = clientMemberships.some(
      (enrollment) =>
        enrollment?.offerings?.id === e.tenantOfferingId &&
        [
          ClientEnrollmentStatus.DECLINED,
          ClientEnrollmentStatus.CANCELLED,
          ClientEnrollmentStatus.COMPLETED,
        ].includes(enrollment.status as ClientEnrollmentStatus)
    );
    return !previousCancelledOrDeclined;
  });

export function sortDataMembership(
  data: IAdaptedClientEnrollmentMembership[]
): IAdaptedClientEnrollmentMembership[] {
  // Define the order of statuses
  const statusOrder: { [key: string]: number } = {
    Submitted: 1,
    Approved: 2,
    Cancelled: 3,
    Declined: 4,
  };

  if (data.length === 0) {
    // If the data array is empty, return an empty array
    return [];
  }
  // Create a new array with date strings converted to Date objects
  const dataWithDates = data.map((entry) => ({
    ...entry,
    // ADDED THIS CONSTANT DATE IF THE START DATE IS NOT AVAILABLE. THIS HELPS TO SHOW THE MEMBERSHIP ON LAST OF THE LIST
    date: new Date(entry.startDate || new Date('01/01/2020')),
  }));

  // Sort the data
  dataWithDates.sort((a, b) => {
    const statusComparison = statusOrder[a.status] - statusOrder[b.status];
    if (statusComparison !== 0) {
      return statusComparison;
    }
    return a.date.getTime() - b.date.getTime();
  });

  // Convert dates back to strings for the final output
  const sortedData = dataWithDates.map((entry) => ({
    ...entry,
    date: entry.date.toISOString().split('T')[0], // Format as "YYYY-MM-DD"
  }));
  return sortedData;
}
