import { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';

import { faAdd, faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Fab, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ServiceCasePlaceholderImg from 'assets/images/jpg/service-case.jpg';
import commonConstants from 'constants/common';
import { ReferenceType } from 'enums/common';
import ModalKey from 'enums/ModalKey';
import {
  CaseCode,
  EnrollmentCode,
  TenantSettingStatus,
  UsersCode,
} from 'enums/tenant-management/tenant';
import { useCancelledMembershipDetails } from 'features/case-layout-new/hooks/useCancelledMembershipDetails';
import useHandleRevoke from 'features/case-layout-new/hooks/useHandleRevoke';
import { useSetReferenceFileFromLink } from 'features/case-layout-new/hooks/useSetReferenceFileFromLink';
import { useSetReferenceNoteFromLink } from 'features/case-layout-new/hooks/useSetReferenceNoteFromLink';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { useCheckUserIsClientDependentOrGroupMember } from 'hooks/useCheckUserIsClientDependentOrGroupMember';
import { useCheckUserIsClientGroup } from 'hooks/useCheckUserIsClientGroup';
import { useCheckUserIsClientPrimary } from 'hooks/useCheckUserIsClientPrimary';
import { useCheckUserIsEmail } from 'hooks/useCheckUserisEmail';
import { useGetCaseTenantSettings } from 'hooks/useGetCaseTenantSettings';
import { IReferralCaseAdvancedSearchForm } from 'interfaces/cases';
import {
  useReferralCaseListQuery,
  useReferralCaseQuery,
  useReferralCaseTimelogQuery,
  useRemainingTimeQuery,
} from 'services/cases';
import { selectTenantSettings } from 'stores/auth';
import {
  changeAdvancedSearch,
  changeAdvancedSearchWithResetPagination,
  changeCasePatientMembership,
  changeFilters,
  changeIsRelevantSearchEntered,
  changeIsScrollingEnabled,
  changeLoadMore,
  changePullRelevantSearch,
  changeReferralCaseList,
  changeRemainingTime,
  changeSelectedReferralCaseList,
  changeSelectedReferralCaseTimelog,
  resetAdvancedSearch,
  resetFilters,
  selectCaseAdvancedSearch,
  selectCaseFilter,
  selectCaseList,
  selectLoadMore,
  updateReferralCaseList,
} from 'stores/cases';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { getTenantFromToken } from 'utils/jwt';
import { getSession, setSession } from 'utils/storage';
import { checkIsUsersSettingEnabled } from 'utils/tenantSetting';

import NotesAndFiles from './NoteFilesTab';
import NotesFilesDrawer from './NotesFilesDrawer';
import ReferralCase from './ReferralCase';
import ReferralCaseList from './ReferralCaseList';
import ReferralCaseSkeleton from './skeleton/ReferralCaseSkeleton';

export const getCaseFilteredAdvanceSearch = (
  advanceOptions: IReferralCaseAdvancedSearchForm
) => ({
  ...(advanceOptions.includeDependentCases && {
    includeDependentCases: advanceOptions.includeDependentCases,
  }),
  ...(advanceOptions.generalSearch && {
    generalSearch: advanceOptions.generalSearch,
    // while searching by keyword i.e case id atlas search takes time (indexing latency)
    // so to use the general mongodb search generalSearch is provider
  }),
  ...(advanceOptions.status && { status: advanceOptions.status }),
  ...(advanceOptions.requestedBy?.id && {
    requestedBy: advanceOptions.requestedBy.id,
  }),
  ...(advanceOptions.requestedTo?.id && {
    requestedTo: advanceOptions.requestedTo.id,
  }),
  ...(advanceOptions.onlyMyCases && {
    onlyMyCases: advanceOptions.onlyMyCases,
  }),
  ...(advanceOptions.dob && {
    dob: advanceOptions.dob,
  }),
  ...(advanceOptions.tags && {
    tags: (advanceOptions.tags as string[]).join(', '),
  }),
});

const Cases = () => {
  const filters = useAppSelector(selectCaseFilter);
  const advancedSearch = useAppSelector(selectCaseAdvancedSearch);

  const tenantSettings = useAppSelector(selectTenantSettings);
  const isAdminChatEnabled = checkIsUsersSettingEnabled(UsersCode.ADMIN_CHAT, {
    tenantSettings,
  });
  const [currentQueryParameters, setSearchParams] = useSearchParams();
  const newQueryParameters: URLSearchParams = new URLSearchParams();
  const [memberId, setMemberId] = useState('');
  const [openDrawer, SetOpenDrawer] = useState(false);
  const [isRecentSearchEmpty, setRecentSearchEmpty] = useState(false);
  const isLoadMore = useAppSelector(selectLoadMore);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const [isLoadMoreLoader, setIsLoadMoreLoader] = useState(false);
  // TO Handle if data is present in initial load but not in scroll
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [scrollToTop, setScrollToTop] = useState(false);
  const tenant = getTenantFromToken();

  const sharedCasesEmail = currentQueryParameters.get('email');
  const guestAccountShareEmail = getSession('guestEmail');

  // To Disable the scroll property if the data is empty
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState(false);

  const [openMobileCase, setOpenMobileCase] = useState(false);

  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  });

  const theme = useTheme();
  const mdBreakpoint = useMediaQuery(theme.breakpoints.up('md'));
  const smBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
  const dispatch = useAppDispatch();

  const isUserClient = useCheckUserIsClient();
  const isUserClientPrimary = useCheckUserIsClientPrimary();

  const isAddCaseEnabledForClient =
    (useGetCaseTenantSettings(
      EnrollmentCode.ADD_CASE_OPTION_FOR_CLIENT
    ) as TenantSettingStatus) === TenantSettingStatus.ENABLED;
  const isDependentCaseEnabled =
    (useGetCaseTenantSettings(
      CaseCode.INCLUDE_DEPENDENT_CASES_OPTION_FOR_CLIENT
    ) as TenantSettingStatus) === TenantSettingStatus.ENABLED;

  const isClientIndividualDependents =
    useCheckUserIsClientDependentOrGroupMember();

  // This condition and Referral case list  to include only my cases are different
  // we include only for dependents only not for primary members
  const IncludeOnlyMyCasesOption = useCallback(() => {
    if (!isDependentCaseEnabled && isUserClientPrimary) return true;
    if (isClientIndividualDependents) return true;

    return false;
  }, [
    isClientIndividualDependents,
    isDependentCaseEnabled,
    isUserClientPrimary,
  ]);

  const isClientGroup = useCheckUserIsClientGroup();
  const isUserEmail = useCheckUserIsEmail();

  const newFilter = {
    ...filters,
    referenceId: tenant.tenantId,
    referenceType: ReferenceType.EMAIL,
    email: decodeURIComponent(guestAccountShareEmail || ''),
  };

  const adaptFilter = isUserEmail ? newFilter : filters;
  const isIncludeDependentCases = useCallback(() => {
    if (
      isUserClientPrimary &&
      !advancedSearch.includeDependentCases &&
      isDependentCaseEnabled
    )
      return true;
    return false;
  }, [
    advancedSearch.includeDependentCases,
    isDependentCaseEnabled,
    isUserClientPrimary,
  ]);

  const combinedFilters = useMemo(
    () => ({
      ...adaptFilter,
      ...getCaseFilteredAdvanceSearch(advancedSearch),
      ...(IncludeOnlyMyCasesOption() && {
        onlyMyCases: true,
      }),
      ...(isIncludeDependentCases() && {
        includeDependentCases: true,
      }),

      ...(isClientGroup && {
        isGroupAdmin: true,
      }),
    }),
    [
      IncludeOnlyMyCasesOption,
      adaptFilter,
      advancedSearch,
      isClientGroup,
      isIncludeDependentCases,
    ]
  );

  const referralCaseListQuery = useReferralCaseListQuery(combinedFilters, {
    enabled: true,
  });

  const referralCaseId = currentQueryParameters.get(
    commonConstants.CASE_ID_QUERY_PARAMETER
  );

  const [searchParams] = useSearchParams();
  const fromEmail = searchParams.get('fromEmail');
  const caseIdFromEmail = searchParams.get('caseId');
  const fromLink = searchParams.get('fromLink');
  const generalSearch = searchParams.get('generalSearch');

  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      // For Future Reference included height also
      y: window.innerHeight,
    });
  // eslint-disable-next-line no-return-assign
  useEffect(() => (window.onresize = updateSize), []);

  useEffect(() => {
    if (caseIdFromEmail && (fromEmail || fromLink || generalSearch)) {
      dispatch(changeFilters({ keyword: caseIdFromEmail }));
      dispatch(
        changeAdvancedSearch({
          generalSearch: true,
        })
      );
    }
  }, [caseIdFromEmail, dispatch, fromEmail, fromLink, generalSearch]);

  const caseListData = useAppSelector(selectCaseList);

  useEffect(() => {
    if (isIncludeDependentCases()) {
      dispatch(
        changeAdvancedSearchWithResetPagination({
          includeDependentCases: true,
        })
      );
    }
  }, [
    advancedSearch.includeDependentCases,
    dispatch,
    isDependentCaseEnabled,
    isIncludeDependentCases,
    isUserClientPrimary,
  ]);

  useEffect(() => {
    if (!referralCaseListQuery.isLoading) {
      setIsLoadMoreLoader(false);
      setScrollToTop(false);

      if (caseListData.length === referralCaseListQuery.data?.count) {
        setIsLoadMoreDisabled(true);
        dispatch(changeLoadMore(false));
        // setRecentSearchEmpty(true);
        return;
      }

      if (
        !referralCaseListQuery?.data?.rows?.length &&
        !isLoadMore &&
        caseListData.length &&
        (filters.keyword || advancedSearch.onlyMyCases)
      ) {
        if (
          caseListData.length &&
          !referralCaseListQuery?.data?.rows?.length &&
          referralCaseListQuery?.data?.count
        ) {
          setIsLoadMoreDisabled(true);
          dispatch(updateReferralCaseList(referralCaseListQuery?.data?.rows));
          dispatch(changeLoadMore(false));
          return;
        }

        dispatch(changeReferralCaseList(referralCaseListQuery.data?.rows));
        setRecentSearchEmpty(true);
        setIsLoadMoreDisabled(true);
        dispatch(changeLoadMore(false));
        return;
      }
      if (
        !referralCaseListQuery?.data?.rows?.length &&
        !isLoadMore &&
        !caseListData?.length
      ) {
        dispatch(changeReferralCaseList(referralCaseListQuery?.data?.rows));
        setRecentSearchEmpty(true);
        setIsLoadMoreDisabled(true);
        dispatch(changeLoadMore(false));
        return;
      }

      if (!referralCaseListQuery?.data?.rows?.length) {
        if (isLoadMore) {
          setIsLoadMoreDisabled(true);
          dispatch(updateReferralCaseList(referralCaseListQuery?.data?.rows));
          dispatch(changeLoadMore(false));
          return;
        }
      }

      if (referralCaseListQuery?.data?.rows?.length) {
        setIsDataAvailable(true);
        if (isLoadMore) {
          setIsLoadMoreDisabled(false);
          dispatch(changeLoadMore(false));
          dispatch(updateReferralCaseList(referralCaseListQuery?.data?.rows));
          return;
        }

        dispatch(changeLoadMore(false));
        setIsLoadMoreDisabled(false);
        dispatch(changeReferralCaseList(referralCaseListQuery?.data?.rows));
      }

      // if the tenant has no case at first , it doesn't run on use of advance filter and keyword search and only my case
      if (!referralCaseId || filters.keyword || isAdvancedSearch) {
        if (!referralCaseListQuery?.data?.rows?.length) {
          dispatch(changeLoadMore(false));
          setRecentSearchEmpty(true);
          return;
        }
        dispatch(changeLoadMore(false));
        setRecentSearchEmpty(false);
        const caseId =
          referralCaseListQuery.data?.rows?.length &&
          referralCaseListQuery?.data?.rows[0]?.caseId;

        if (caseId) {
          newQueryParameters.set(
            commonConstants.CASE_ID_QUERY_PARAMETER,
            caseId
          );
          setSearchParams(newQueryParameters);
        }
      }
      if (!generalSearch) {
        dispatch(
          changeAdvancedSearch({
            generalSearch: false,
          })
        );
      }
      dispatch(changeLoadMore(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralCaseListQuery.data]);

  const referralCaseQuery = useReferralCaseQuery(referralCaseId!, {
    enabled: !!referralCaseId,
  });

  const referralCaseTimelogQuery = useReferralCaseTimelogQuery(
    referralCaseId!,
    {
      enabled: !!referralCaseId,
    }
  );

  useCancelledMembershipDetails();
  // const memberId = referralCaseQuery?.data?.patient?.id;
  const remainingTimeQuery = useRemainingTimeQuery(
    { clientId: memberId!, caseId: referralCaseId! },
    {
      enabled: !!memberId && !!referralCaseId,
    }
  );
  const remainingTime =
    remainingTimeQuery?.data?.rows?.length && remainingTimeQuery?.data?.rows[0];

  useEffect(() => {
    if (remainingTime) {
      dispatch(changeRemainingTime(remainingTime.totalRemainingTime));
    } else {
      dispatch(changeRemainingTime(0));
    }
  }, [dispatch, remainingTime]);

  useEffect(() => {
    if (referralCaseQuery.data && !referralCaseQuery.isLoading) {
      setMemberId(
        referralCaseQuery?.data?.patient?.relationship?.groupId ??
          referralCaseQuery?.data?.patient?.id ??
          ''
      );
      dispatch(changeSelectedReferralCaseList(referralCaseQuery.data));
    }
  }, [
    dispatch,
    referralCaseQuery.data,
    referralCaseQuery.isLoading,
    remainingTimeQuery,
  ]);

  useEffect(() => {
    if (memberId) {
      remainingTimeQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId]);

  useEffect(() => {
    if (referralCaseTimelogQuery.data && !referralCaseTimelogQuery.isLoading) {
      dispatch(
        changeSelectedReferralCaseTimelog(referralCaseTimelogQuery.data)
      );
    }
  }, [
    dispatch,
    referralCaseTimelogQuery.data,
    referralCaseTimelogQuery.isLoading,
  ]);

  useEffect(
    // Clear filters and advance filter on unmount
    () => () => {
      dispatch(changeLoadMore(false));
      dispatch(resetAdvancedSearch());
      dispatch(resetFilters());
      setIsDataAvailable(false);
      setIsLoadMoreDisabled(false);
      dispatch(changeSelectedReferralCaseList(null));
      dispatch(changeCasePatientMembership([]));
      dispatch(changeIsRelevantSearchEntered(false));
      dispatch(changePullRelevantSearch(false));
      dispatch(changeIsScrollingEnabled(false));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!guestAccountShareEmail) {
      setSession('guestEmail', sharedCasesEmail);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedMobileCase = () => {
    setOpenMobileCase(!openMobileCase);
  };

  useHandleRevoke();

  const handleGoBack = () => {
    setOpenMobileCase(!openMobileCase);
  };

  const handleChangeSelectedCaseId = (caseId: string) => {
    dispatch(changeSelectedReferralCaseList({ caseId }));
    newQueryParameters.set(commonConstants.CASE_ID_QUERY_PARAMETER, caseId);
    setSearchParams(newQueryParameters);
  };

  const handleRefresh = () => {
    referralCaseQuery.refetch();
    referralCaseTimelogQuery.refetch();
  };

  const handleListScroll = (e: React.UIEvent<HTMLElement>) => {
    const { scrollHeight, scrollTop } = e.currentTarget;
    // const currentHeight = Math.ceil(scrollTop + 840);
    const currentHeight = Math.ceil(scrollTop + 980);
    if (
      currentHeight >= scrollHeight &&
      !isLoadMoreDisabled &&
      !referralCaseListQuery.isLoading
    ) {
      setIsLoadMoreLoader(true);
      dispatch(changeLoadMore(true));
      dispatch(changeIsScrollingEnabled(true));
      dispatch(changeFilters({ offset: filters.limit + filters.offset }));
    }
  };
  const onAdvancedSearchFormSubmit = async (
    event: React.SyntheticEvent,
    formData: IReferralCaseAdvancedSearchForm
  ) => {
    setScrollToTop(true);
    event.preventDefault();
    dispatch(changeLoadMore(false));
    dispatch(changeIsScrollingEnabled(false));
    setIsAdvancedSearch(true);
    if (
      advancedSearch.status !== formData.status ||
      advancedSearch.requestedBy !== formData.requestedBy ||
      advancedSearch.requestedTo !== formData.requestedTo
    ) {
      dispatch(changeReferralCaseList([]));
    }

    dispatch(
      changeAdvancedSearchWithResetPagination({
        status: formData.status,
        requestedBy: formData.requestedBy,
        requestedTo: formData.requestedTo,
      })
    );
  };

  const onSearchKeywordChange = (keyword: string) => {
    dispatch(changeFilters({ keyword, offset: 0 }));
    dispatch(changePullRelevantSearch(false));
    dispatch(changeIsScrollingEnabled(false));
  };

  const handleOnlyMyCase = (onlyMyCases: boolean) => {
    setScrollToTop(true);
    dispatch(changeLoadMore(false));
    dispatch(changeIsScrollingEnabled(false));
    setIsAdvancedSearch(true);
    dispatch(changePullRelevantSearch(false));
    dispatch(
      changeAdvancedSearchWithResetPagination({
        onlyMyCases,
      })
    );
  };

  const navigate = useNavigate();
  const handleAddCase = () => {
    navigate(
      `modal?type=${
        isUserClient ? ModalKey.ADD_PATIENT_CASE : ModalKey.ADD_CASE
      }`
    );
  };

  const showAddCaseOption = () => {
    if (isUserClient && !isAddCaseEnabledForClient) {
      return false;
    }

    return !isClientGroup;
  };

  useSetReferenceNoteFromLink();
  useSetReferenceFileFromLink();

  if (
    referralCaseListQuery.isFetched &&
    !isDataAvailable &&
    !filters.keyword &&
    !advancedSearch.onlyMyCases &&
    !advancedSearch.status &&
    !advancedSearch.requestedBy?.id &&
    !advancedSearch.requestedTo?.id &&
    !referralCaseListQuery?.data?.rows?.length
  ) {
    return (
      <div className="service-case-placeholder-image-wrapper">
        <div
          className="service-case-placeholder-image"
          style={{ backgroundImage: `url(${ServiceCasePlaceholderImg})` }}
        />
        <div className="message-box">
          <Stack direction={isClientGroup ? 'row' : 'column'} spacing={1}>
            <Typography color="gray.darker" fontWeight="medium" variant="body1">
              Case not available.
            </Typography>
            {showAddCaseOption() && (
              <Box display="flex" justifyContent="center">
                <Button
                  onClick={handleAddCase}
                  startIcon={<FontAwesomeIcon icon={faAdd} />}
                  sx={{ marginBottom: 1 }}
                  variant="contained"
                >
                  Add
                </Button>
              </Box>
            )}

            {isClientGroup && (
              <Typography
                color="gray.darker"
                fontWeight="medium"
                textAlign="center"
                variant="body1"
              >
                Please contact your advocate.
              </Typography>
            )}
          </Stack>
        </div>
        <Outlet />
      </div>
    );
  }
  return (
    <>
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          position="relative"
          width="100%"
        >
          {(smBreakpoint || (!openMobileCase && !smBreakpoint)) && (
            <ReferralCaseList
              handleChangeSelectedCaseId={handleChangeSelectedCaseId}
              handleOnlyMyCase={handleOnlyMyCase}
              handleScroll={handleListScroll}
              isFetching={referralCaseQuery.isFetching || isLoadMoreLoader}
              isLoading={referralCaseListQuery.isLoading}
              isRecentSearchEmpty={isRecentSearchEmpty}
              onAdvancedSearchFormSubmit={onAdvancedSearchFormSubmit}
              onSearchKeywordChange={onSearchKeywordChange}
              scrollToTop={scrollToTop}
              setIsLoadMoreDisabled={setIsLoadMoreDisabled}
              setScrollToTop={setScrollToTop}
              {...(!smBreakpoint && {
                selectedMobileCase,
              })}
            />
          )}

          {(smBreakpoint || openMobileCase) && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {referralCaseQuery.data &&
              !referralCaseQuery.isLoading &&
              !referralCaseQuery.isFetching ? (
                <>
                  <ReferralCase
                    handleRefresh={handleRefresh}
                    {...(!smBreakpoint && {
                      handleGoBack,
                      openMobileCase,
                    })}
                  />
                  {mdBreakpoint && size.x > 1280 ? (
                    <NotesAndFiles />
                  ) : (
                    isAdminChatEnabled && <NotesFilesDrawer />
                  )}
                </>
              ) : (
                <ReferralCaseSkeleton />
              )}
            </>
          )}
        </Box>
        {size.x <= 1280 && !isAdminChatEnabled && (
          <Box bottom="20px" position="fixed" right="10px" zIndex={999}>
            <Fab
              aria-label="add"
              color="primary"
              onClick={() => SetOpenDrawer(!openDrawer)}
              size="medium"
            >
              <FontAwesomeIcon
                icon={faStickyNote}
                size="lg"
                style={{
                  color: theme.palette.common.white,
                }}
              />
            </Fab>
          </Box>
        )}
      </Box>
      <Outlet />
    </>
  );
};

export default Cases;
