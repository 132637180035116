import regex from 'constants/regex';
import ModalKey from 'enums/ModalKey';
import { FalsyType, IAddress, IAddressOptional } from 'interfaces/common';
import { getSession } from 'utils/storage';

import {
  checkIfObjHasAllEmptyOrNullValues,
  removeFileExtension,
} from './common';

export const getModalRoute = (modalKey: ModalKey) => `modal?type=${modalKey}`;

export const getInputLabel = (label: string, isRequired: boolean) =>
  isRequired ? `${label} *` : label;

/**
 * Combine two filter obj1.
 * Mostly used while while combining table default filter object (limit, offset, keyword)
 * with advanced filter obj.
 *
 * Returns table default filter object only if advanced filter is not used.
 *
 * @param filterObj1
 * @param filterObj2
 * @returns
 */
export const combineFilters = (filterObj1: any, filterObj2: any) => {
  if (!checkIfObjHasAllEmptyOrNullValues(filterObj2)) {
    return {
      ...filterObj1,
      ...filterObj2,
    };
  }
  return filterObj1;
};

/**
 * Clean filter obj
 *
 * @example:
 * Input: {
    email: "",
    name: "John Cena",
    phone: ""
  };
 *
 * Output: [[name, "John Cena"]]
 *
 * @param filterObj
 * @returns
 */
export const cleanFilters = (filterObj: any) =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.entries(filterObj).filter(([_, v]) => !!v);

/**
 * Checks if the value is falsy and returns the replaceWith string if it is.
 * @param {unknown} value - the value to check for falsiness
 * @param {string} [replaceWith='-'] - the string to replace the falsy value with, default value is "-"
 * @returns {unknown} - the value if it is not falsy, otherwise the replaceWith string
 */
export const checkFalsyAndReplace = ({
  value,
  replaceWith = '-',
}: {
  value: string | number | FalsyType;
  replaceWith?: string;
}): string | number => {
  if (value) return value;
  return replaceWith;
};

/**
 *
 * @returns
 *
 * 12:00AM - 12:00PM - Good Morning
 * 12:00PM - 6:00PM - Good Afternoon
 * 6:00PM - 12:00AM - Good Evening
 *
 */
export const getGreetingMessageBasedOnTime = () => {
  const today = new Date();
  const curHr = today.getHours();

  let greetingMessage = '';

  if (curHr < 12) {
    greetingMessage = 'Good Morning';
  } else if (curHr < 18) {
    greetingMessage = 'Good Afternoon';
  } else {
    greetingMessage = 'Good Evening';
  }

  return greetingMessage;
};

export const clearBrowserCaches = () => {
  if (caches) {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
};

/**
 * The function compares two semantic version numbers and returns true if the first one is greater than
 * the second one.
 * @param {string} versionA - `versionA` is a string representing a version number in the format of
 * "major.minor.patch". For example, "1.2.3".
 * @param {string} versionB - `versionB` is a string representing a version number that we want to
 * compare against `versionA`. It is used as a reference point to determine if `versionA` is greater
 * than `versionB`.
 * @returns The function `semverGreaterThan` returns a boolean value. It returns `true` if `versionA`
 * is greater than `versionB`, and `false` otherwise.
 */
export const semverGreaterThan = (versionA: string, versionB: string) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

/**
 * The function takes in an object of parameters, checks if any of the values are arrays, and joins them into a comma-separated string.
 * @param {any} params - The `params` parameter is an object that contains key-value pairs, where the keys are strings and the values can be of any type, including arrays.
 * @returns The `newParams` object with any array values converted to comma-separated strings.
 */
export const parseParams = (params: any) => {
  const newParams = params;
  const keys = Object.keys(newParams);

  keys.forEach((key) => {
    const isParamTypeArray = Array.isArray(newParams[key]);

    if (isParamTypeArray) {
      newParams[key] = newParams[key].join(',');
    }
  });
  return newParams;
};

export const getSuggestedDocuSignDocumentName = (name: string) =>
  `${removeFileExtension(name)}_e_signature`;

export const checkIsMaintenance = () => {
  const maintenanceBypassCode =
    getSession('maintenance_bypass_code') ===
    process.env.REACT_APP_MAINTENANCE_PAGE_BYPASS_CODE;

  return (
    process.env.REACT_APP_SHOW_MAINTENANCE_PAGE === 'true' &&
    !maintenanceBypassCode
  );
};

/**
 * The `flattenObject` function flattens a nested object into a single-level object.
 * @param {any} obj - The `flattenObject` function takes an object `obj` as a parameter and flattens it
 * by converting nested objects into properties of the top-level object.
 * @returns The `flattenObject` function returns a new object that is a flattened version of the input
 * object `obj`.
 */
export const flattenObject = (obj: any) => {
  let flatObj = {};
  Object.keys(obj).forEach((item) => {
    if (typeof obj[item] === 'object' && obj[item] !== null) {
      flatObj = { ...flatObj, ...obj[item] };
    } else {
      flatObj = { ...flatObj, [item]: obj[item] };
    }
  });
  return flatObj;
};

export const formatAddressInSingleLine = (
  address: IAddressOptional | IAddress
) => {
  if (!address) return '-';
  const { addressLine1, addressLine2, city, state, zip } = address;

  if (!addressLine1 && !addressLine2 && !city && !state && !zip) {
    return '-';
  }
  return `${addressLine1
    ? `${addressLine1}${addressLine2 || city || zip || state ? ', ' : ''}`
    : ''
    }${addressLine2 ? `${addressLine2}${city || zip || state ? ', ' : ''} ` : ''
    }${city ? `${city}${state || zip ? ', ' : ''}` : ''}${state ? `${state} ` : ''
    }${zip ? `${zip}` : ''}`;
};

export function removeDuplicatesFromArrayOfObjects(
  arr: any[],
  prop: keyof any
): any[] {
  const unique: Record<string, any> = {};
  arr.forEach((item) => {
    unique[item[prop] as string] = item;
  });
  return Object.values(unique);
}

export const validateHexColor = (colorCode: string) => {
  const hexCodeRegex = new RegExp(regex.HEX_COLOR);
  return hexCodeRegex.test(colorCode)
}