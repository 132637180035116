import React, { useEffect, useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import FormBanner from 'common/form/FormBanner';
import LoadingIndicator from 'common/LoadingIndicator';
import { useCheckIsSelectableMembershipView } from 'common/manage-membership/hooks/useCheckIsSelectableMembershipView';
import { useGetClientEnrollmentMembership } from 'common/manage-membership/hooks/useGetClientEnrollmentMembership';
import { useGetCurrentSelectedClientAndMembershipDetails } from 'common/manage-membership/hooks/useGetCurrentSelectedClientAndMembershipDetails';
import { useGetManageMembershipActionType } from 'common/manage-membership/hooks/useGetManageMembershipActionType';
import ModalFooter from 'common/ui/modal/ModalFooter';
import { TenantOfferingCategoryFull } from 'enums/settings/tenant-offerings';
import UserType from 'enums/User';
import {
  IAdaptedClientGroup,
  IAdaptedClientIndividual,
} from 'interfaces/client-management';
import { IAdaptedClientEnrollmentMembership } from 'interfaces/client-management/enrollment';
import { useUpdateClientEnrollmentMutation } from 'services/client-management/enrollment';
import { selectAuthTenantAssociation } from 'stores/auth';
import {
  resetManageMembershipSelectedOfferings,
  selectManageMembershipInvalidSelectedDate,
  setManageMembershipClientMemberships,
  setManageMembershipSelectedOfferings,
} from 'stores/client-management/manage-membership';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { formatUpdateManageMembership } from 'utils/client-management/enrollment';
import { getCurrentDate, isValidDate, momentDateIsBefore } from 'utils/moment';

import { AddEditMembershipListView } from './AddEditMembershipListView';
import { AddEditMembershipSelectableView } from './AddEditMembershipSelectableView';

interface IProps {
  handleCancelButtonClick: VoidFunction;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}

export const EditAddMembership = ({
  handleCancelButtonClick,
  setActiveTab,
}: IProps) => {
  const dispatch = useAppDispatch();

  const authTenantAssociationData = useAppSelector(selectAuthTenantAssociation);

  const tenantGroupCode = authTenantAssociationData?.groupCode;

  const {
    currentSelectedStartDate,
    selectedOffering,
    clientId,
    clientEnrollmentId,
    clientType,
    clientData,
    isClientGroupMember,
    membership: membershipDetails,
  } = useGetCurrentSelectedClientAndMembershipDetails();

  const invalidSelectedDate = useAppSelector(
    selectManageMembershipInvalidSelectedDate
  );
  const { data, isLoading } = useGetClientEnrollmentMembership();
  const { isEditMembership } = useGetManageMembershipActionType();

  const filteredData = data.filter((e) => !!e.offerings.id);

  const isSelectableView = useCheckIsSelectableMembershipView(
    filteredData,
    clientData
  );

  const updateClientEnrollmentMutation = useUpdateClientEnrollmentMutation();

  const handleActionClick = () => {
    if (!isSelectableView) {
      setActiveTab((prevState) => prevState + 1);
      return;
    }
    if (!currentSelectedStartDate || !selectedOffering) return;
    const payload = formatUpdateManageMembership({
      offering: selectedOffering,
      startDate: currentSelectedStartDate,
      clientType,
      tenantGroupCode,
      tenantId:
        clientData?.tenantId || authTenantAssociationData?.tenantId || '',
    });
    // Handles for group member dependents
    if (selectedOffering.groupId) {
      payload.relationship = {
        groupId: selectedOffering?.groupId,
        groupName: selectedOffering?.groupName,
        groupEnrollmentId: selectedOffering?.groupEnrollmentId,
        primaryEnrollmentId: selectedOffering?.clientEnrollmentId,
      };
    } else if (
      // Handles for group member
      selectedOffering.startDate &&
      selectedOffering.clientEnrollmentId &&
      selectedOffering.category === TenantOfferingCategoryFull.GROUP
    ) {
      payload.relationship = {
        groupId: membershipDetails?.groupId,
        groupName: membershipDetails?.groupName,
        groupEnrollmentId: selectedOffering.clientEnrollmentId,
      };
    } else if (
      // Handle for dependent
      selectedOffering.startDate &&
      selectedOffering.clientEnrollmentId &&
      selectedOffering.category !== TenantOfferingCategoryFull.GROUP
    ) {
      payload.relationship = {
        primaryEnrollmentId: selectedOffering.clientEnrollmentId,
      };
    }
    updateClientEnrollmentMutation.mutate(
      {
        clientId: clientId!,
        enrollmentId: clientEnrollmentId!,
        data: payload,
        clientType: clientType as UserType,
      },
      {
        onSuccess: () => {
          setActiveTab((prevState) => prevState + 1);
        },
      }
    );
  };

  const getActionButtonLabel = () => {
    if (!isSelectableView) return 'Next';
    return 'Save';
  };

  const isSelectedOfferingAndMembershipOfferingSame =
    selectedOffering?.tenantOfferingId === membershipDetails?.offerings?.id;

  const isEnabled = useMemo(() => {
    if (!isSelectableView) return true;

    const isDateValid = currentSelectedStartDate
      ? isValidDate(currentSelectedStartDate)
      : false;

    const baseCondition =
      !!selectedOffering && isDateValid && !invalidSelectedDate;

    if (isEditMembership) {
      const isSameOfferingBefore =
        isSelectedOfferingAndMembershipOfferingSame &&
        membershipDetails?.startDate === currentSelectedStartDate;

      if (selectedOffering?.startDate && currentSelectedStartDate) {
        const isDateIsBeforeThePrimaryMembership = momentDateIsBefore(
          currentSelectedStartDate,
          selectedOffering?.startDate
        );
        return (
          baseCondition &&
          !isDateIsBeforeThePrimaryMembership &&
          !isSameOfferingBefore
        );
      }
      return baseCondition && !isSameOfferingBefore;
    }
    return baseCondition;
  }, [
    currentSelectedStartDate,
    invalidSelectedDate,
    isEditMembership,
    isSelectableView,
    isSelectedOfferingAndMembershipOfferingSame,
    membershipDetails?.startDate,
    selectedOffering,
  ]);

  useEffect(() => {
    if (data.length) {
      dispatch(setManageMembershipClientMemberships(data));
      let membership = null;
      let startDate = getCurrentDate();

      const matchedClientEnrollment = data.find(
        (e) => clientData?.clientEnrollmentId === e.clientEnrollmentId
      ) as IAdaptedClientEnrollmentMembership;

      if (matchedClientEnrollment) {
        membership = matchedClientEnrollment;
        startDate = membership?.startDate
          ? membership?.startDate
          : getCurrentDate();
      } else if (
        clientData?.relationship?.groupId ||
        clientData?.relationship?.primaryId
      ) {
        membership = data.find(
          (e) =>
            e.groupId === clientData?.relationship?.groupId ||
            e.primaryId === clientData?.relationship?.primaryId
        ) as IAdaptedClientEnrollmentMembership;
        startDate = membership?.startDate
          ? membership?.startDate
          : getCurrentDate();
      } else {
        // if the client details are already exists then membership details will be in last of the index of membership list.
        const index = data.length - 1;
        membership = data[index] as IAdaptedClientEnrollmentMembership;
        startDate = data[index]?.startDate
          ? data[index]?.startDate
          : getCurrentDate();
      }
      dispatch(
        setManageMembershipSelectedOfferings({
          details: membership,
          startDate,
        })
      );
    }
  }, [
    clientData?.clientEnrollmentId,
    clientData?.relationship?.groupId,
    clientData?.relationship?.primaryId,
    data,
    dispatch,
    isClientGroupMember,
  ]);

  useEffect(
    () => () => {
      dispatch(resetManageMembershipSelectedOfferings());
    },
    [dispatch]
  );

  if (isLoading) return <LoadingIndicator />;

  return (
    <Box>
      <FormBanner
        description={
          isSelectableView
            ? 'Please select the one you would like to modify.'
            : ''
        }
        title={
          <Typography
            color="gray.darker"
            fontWeight="medium"
            gutterBottom={false}
            variant="body1"
          >
            Membership associated with{' '}
            {(clientData as IAdaptedClientGroup)?.businessName
              ? (clientData as IAdaptedClientGroup)?.businessName
              : (clientData as IAdaptedClientIndividual)?.fullName}
            .
          </Typography>
        }
      />

      {!isSelectableView ? (
        <AddEditMembershipListView data={filteredData} />
      ) : (
        <AddEditMembershipSelectableView />
      )}
      <ModalFooter
        actionButtonLabel={getActionButtonLabel()}
        actionButtonType="button"
        cancelButtonType="cancel"
        isCancelRequired={false}
        isDisabled={!isEnabled}
        isLoading={updateClientEnrollmentMutation.isLoading}
        onActionButtonClick={handleActionClick}
        onCancelButtonClick={handleCancelButtonClick}
      />
    </Box>
  );
};
