import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { Grid, MenuListProps } from '@mui/material';
import { FormClientAutocomplete, FormSelectTextField } from 'common';
import FormTextField from 'common/form/FormTextField';
import { relationshipOptions } from 'constants/client';
import { DictionaryKeys } from 'constants/languageDictionary';
import {
  ClientType,
  RelationshipToPrimaryMember,
} from 'enums/client-management';
import { MiscellaneousCode } from 'enums/tenant-management/tenant';
import UserType from 'enums/User';
import { useTenantTerminology } from 'hooks/useTenantTerminology';
import {
  IAdaptedClientGroupTableRow,
  IAdaptedClientIndividualTableRow,
  IAddClientForm,
} from 'interfaces/client-management';
import { useClientDetailQuery } from 'services/client-management';
import { getMiscellaneousTenantSetting } from 'utils/tenantSetting';

import IndividualForm from './IndividualForm';

interface IProps {
  isEditMode: boolean;
  isClientAutocompleteDisabled: boolean;
  isSponsorIdEnabled?: boolean;
}

const DependentForm = ({
  isEditMode,
  isClientAutocompleteDisabled,
  isSponsorIdEnabled,
}: IProps): JSX.Element => {
  const { setValue, getValues } = useFormContext<IAddClientForm>();

  const [searchParams] = useSearchParams();
  const clientTypeFromParams = searchParams.get('clientType');
  const parentIdFromParams = searchParams.get('parentId');
  const parentTenantId = searchParams.get('parentTenantId') || '';

  const clientDetailQuery = useClientDetailQuery(parentIdFromParams!, {
    enabled: !!parentIdFromParams,
  });

  const dependentRelationships = getMiscellaneousTenantSetting(
    MiscellaneousCode.DEPENDENT_RELATIONSHIP
  ) as string[];

  useEffect(() => {
    if (parentTenantId) {
      setValue('tenantId', parentTenantId);
    }
  }, [parentTenantId, setValue]);

  useEffect(() => {
    if (dependentRelationships.length === 1) {
      setValue('relationship', dependentRelationships[0]);
    }
  }, [dependentRelationships, setValue]);

  const populateContactFields = ({
    email,
    phone,
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    lat,
    lng,
  }: {
    email?: string;
    phone?: string;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    zip?: string;
    lat?: string | number;
    lng?: string | number;
  }) => {
    setValue('email', email ?? '');
    setValue('phone', phone ?? '');
    setValue('addressLine1', addressLine1 ?? '');
    setValue('addressLine2', addressLine2 ?? '');
    setValue('city', city ?? '');
    setValue('state', state ?? '');
    setValue('zip', zip ?? '');
    setValue('lat', lat ?? '');
    setValue('lng', lng ?? '');
  };

  // Prepoulate the phone and address when the user tries to add dependent from ellipse menu
  // But if there is existing data in these fields, keep them.
  useEffect(() => {
    if (
      clientDetailQuery.data &&
      clientTypeFromParams === ClientType.DEPENDENT
    ) {
      const {
        phone: parentPhone,
        address: parentAddress,
        locationDetails,
        email: parentEmail,
      } = clientDetailQuery.data;
      const {
        email,
        phone,
        addressLine1,
        addressLine2,
        city,
        state,
        zip,
        lat,
        lng,
      } = getValues();

      populateContactFields({
        email: email || (parentEmail ?? ''),
        phone: phone || (parentPhone ?? ''),
        addressLine1: addressLine1 || (parentAddress?.addressLine1 ?? ''),
        addressLine2: addressLine2 || (parentAddress?.addressLine2 ?? ''),
        city: city || (parentAddress?.city ?? ''),
        state: state || (parentAddress?.state ?? ''),
        zip: zip || (parentAddress?.zip ?? ''),
        lat: lat || (locationDetails?.coordinates[1] ?? ''),
        lng: lng || (locationDetails?.coordinates[0] ?? ''),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientDetailQuery.data]);

  const onClientSelect = (
    client:
      | IAdaptedClientGroupTableRow
      | IAdaptedClientIndividualTableRow
      | null
  ) => {
    if (client) {
      const {
        email,
        phone,
        addressLine1,
        addressLine2,
        city,
        state,
        zip,
        lat,
        lng,
      } = getValues();
      setValue('tenantId', client.membership?.tenantId || '');
      populateContactFields({
        email: client.email || email,
        phone: client.phone || phone,
        addressLine1: client.address?.addressLine1 || addressLine1,
        addressLine2: client.address?.addressLine2 || addressLine2,
        city: client.address?.city || city,
        state: client.address?.state || state,
        zip: client.address?.zip || zip,
        lat: client.address?.lat || lat,
        lng: client.address?.lng || lng,
      });
    }
  };

  const relationshipOptionsForDependent = useMemo(
    () =>
      dependentRelationships.map((x) =>
        relationshipOptions.find(
          (y) =>
            y.value !== RelationshipToPrimaryMember.PRIMARY && y.value === x
        )
      ),
    [dependentRelationships]
  );

  const terminology = useTenantTerminology([
    DictionaryKeys.PATIENT_TERMINOLOGY,
  ]);

  return (
    <>
      <Grid container mb={2} spacing={2}>
        <Grid item xs={6}>
          <FormSelectTextField
            data={relationshipOptionsForDependent}
            data-cy="client-primary-member-field"
            disabled={isEditMode}
            inputProps={{ 'data-cy': 'client-primary-member' }}
            isRequired
            label={`Relationship to primary ${terminology?._PATIENT_TERMINOLOGY.toLocaleLowerCase()}`}
            name="relationship"
            SelectProps={{
              MenuProps: {
                MenuListProps: {
                  'data-cy': 'client-primary-member-container',
                } as Partial<MenuListProps>,
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormClientAutocomplete
            clientSelectHandler={onClientSelect}
            disabled={isClientAutocompleteDisabled}
            label={`Select primary ${terminology?._PATIENT_TERMINOLOGY.toLowerCase()} *`}
            name="primaryId"
            placeholder="Type name or email to search"
            setParentClientName
            showPrimaryMembersOnly
            userType={UserType.INDIVIDUAL}
          />
        </Grid>
      </Grid>

      <IndividualForm />
      {isSponsorIdEnabled && (
        <Grid container>
          <Grid mb={4} mt={4} xs={6}>
            <FormTextField
              label={`${terminology?._PATIENT_TERMINOLOGY} ID`}
              name="memberId"
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

DependentForm.defaultProps = {
  isSponsorIdEnabled: false,
};

export default DependentForm;
