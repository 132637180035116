import config, { dateTimeFormatConfig } from 'config';
import { Interval } from 'enums/common';
import { DateUnit } from 'enums/moment';
import { IntervalType, ISelectOptionProps } from 'interfaces/common';
import moment, { DurationInputArg2, Moment } from 'moment';
import momentTimeZone from 'moment-timezone';

import { formatDate } from './common';

export const formatDateView = (
  date: string,
  dateFormat = config.dateViewFormat
) => {
  if (date && !moment(date).isValid()) return date;

  if (date) {
    return moment(date)
      .format(dateFormat)
      .toString()
      .replace('am', 'AM')
      .replace('pm', 'PM');
  }

  return null;
};

export const formatUTCDateView = (
  date: string,
  dateFormat = config.dateViewFormat
) => {
  if (date && !moment(date).isValid()) return date;
  if (date) {
    return moment(date).utc().format(dateFormat);
  }
  return null;
};

// TODO: this function works similar to `formatDateView`. Refactor them to a single function.
export const momentViewFormat = (
  date: Date | string,
  dateFormat = config.dateViewFormat
): string => {
  if (date && !moment(date).isValid()) return date.toString();

  return moment(date).format(dateFormat);
};

export const validateDob = (dob: string, minAge = 0, maxAge = 120) => {
  let isValidDob = false;

  if (
    moment(dob, config.dateViewFormat).isBefore() &&
    moment().diff(moment(dob, config.dateViewFormat), 'years') <= maxAge
  ) {
    isValidDob = true;
  }

  if (
    minAge > 0 &&
    moment().diff(moment(dob, config.dateViewFormat), 'years') >= minAge
  ) {
    isValidDob = true;
  }

  return isValidDob;
};

/**
 * Get current date (today's date)
 *
 * @param {string} format
 * @returns
 */
export const getCurrentDate = (format = config.dateViewFormat) =>
  moment().format(format);
export const getCurrentTime = (format = config.timeFormat) =>
  formatDateView(moment().format(format));

export const checkIfDateIsValid = (
  date: string,
  format = config.dateViewFormat
) => {
  if (format === config.dateViewFormat && date?.length !== 8) {
    return false;
  }
  return moment(date, format).isValid();
};

export const isValidDate = (date: string, format = config.dateViewFormat) =>
  moment(moment(date), format).isValid();

export const compareDate = (startDate: string, endDate: string) =>
  moment(startDate).isSame(endDate);

/**
 * Get next months for select input field
 *
 * @example
 * Input: date = '01/01/2022', until = 2
 * Output: [{'label':'01/01/2022','value':'01/01/2022'},
 * {'label':'01/02/2022','value':'01/02/2022'},
 * {'label':'01/03/2022','value':'01/03/2022'}
 * ]
 *
 * @param {string} date
 * @param {number} until
 * @returns
 */
export const getMonthDateRange = (
  date: string,
  until: number,
  includeCurrentDate?: boolean
): ISelectOptionProps[] => {
  const getCurrentDay = moment(date).format('D');

  const dateRange = [
    {
      label: moment(date).subtract(1, 'day').format('L'),
      value: moment(date).subtract(1, 'day').format('L'),
    },
  ];
  let range = Array(until).fill(0);
  range = range.map((_, index) => {
    const month = index + 1;
    const getDay = moment(date).add(month, 'M').format('D');
    const subtractDay = getCurrentDay === getDay;

    return {
      label: moment(date)
        .add(month, 'M')
        .subtract(subtractDay ? 1 : 0, 'day')
        .format('L'),
      value: moment(date)
        .add(month, 'M')
        .subtract(subtractDay ? 1 : 0, 'day')
        .format('L'),
    };
  });
  return includeCurrentDate ? dateRange.concat(range) : range;
};

/**
 *
 * @param date :: should be in ISO 8601 format i.e. YYYY-MM-DDTHH:mm:ss. sssZ
 */
export const checkIfDateisInFuture = (date: string) => {
  const today = new Date();
  return new Date(date) > today;
};

/**
 *
 * @param date :: should be in ISO 8601 format i.e. YYYY-MM-DDTHH:mm:ss. sssZ
 */
export const checkIfDateisInPast = (date: string) => {
  const today = new Date();
  return new Date(date) < today;
};

/**
 * checks if date is current day
 * Today's Date: 08/20/2022
 * Input: 2022-08-20T18:31:43.404Z
 * Output: false
 * @param {string} date
 */
export const momentIsToday = (date: string) =>
  moment(date).isSame(moment(), 'day');

/**
 * checks if date is current day
 * Today's Date: 08/20/2022
 * Input: 2022-08-20T18:31:43.404Z
 * Output: true
 * @param {string} date
 */
export const momentIsThisYear = (date: string) =>
  moment(date).isSame(moment(), 'year');

// Get Date's Display Format according to date received.
export const getDateFormat = (date: string) => {
  let dateDisplayFormat: string;
  const isToday = momentIsToday(date);
  const isThisYear = momentIsThisYear(date);

  if (isToday) dateDisplayFormat = config.timeOnlyView;
  else if (isThisYear) dateDisplayFormat = config.emailListDateFormatView;
  else dateDisplayFormat = config.emailListDateFormatViewWithYear;
  return dateDisplayFormat;
};

export const getWeekStartDate = (start = 0) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const day = today.getDay() - start;
  const date = today.getDate() - day;

  const startDate = new Date(today.setDate(date));
  return startDate;
};

export const getDateRange = (
  interval: IntervalType
): {
  startDate: string;
  endDate: string;
} => {
  let startDate = new Date();
  let endDate = new Date();

  if (interval === Interval.LAST_MONTH) {
    startDate = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1);
    endDate = new Date(endDate.getFullYear(), endDate.getMonth(), 0);
  } else if (interval === Interval.LAST_YEAR) {
    startDate = new Date(startDate.getFullYear() - 1, 0, 1);
    endDate = new Date(endDate.getFullYear(), 0, 0);
  } else if (interval === Interval.SINCE_INCEPTION) {
    startDate = new Date(2011, 0, 1);
  } else if (interval === Interval.THIS_MONTH) {
    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
  } else if (interval === Interval.THIS_QUARTER) {
    startDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth() - (startDate.getMonth() % 3),
      1
    );
  } else if (interval === Interval.THIS_YEAR) {
    startDate = new Date(startDate.getFullYear(), 0, 1);
  } else if (interval === Interval.WEEKLY) {
    startDate = getWeekStartDate();
  }
  return {
    startDate: formatDateView(startDate.toDateString()) ?? 'N/A',
    endDate: formatDateView(endDate.toDateString()) ?? 'N/A',
  };
};

export const UTCToLocalDateViewFormat = (
  utcDateTime: string,
  dateFormat = config.dateViewFormat
) => {
  if (!utcDateTime) return utcDateTime;
  let formattedDate = moment.utc(utcDateTime);

  formattedDate = moment(formattedDate).local();

  return formattedDate.format(dateFormat);
};

export const getAge = (dob: string, dateFormat = config.dateViewFormat) =>
  moment().diff(moment(dob, dateFormat), 'years');

export const parseZone = (
  date: string | Date | Moment,
  dateFormat = config.dateViewFormat
) => {
  if (!date) return null;
  return moment.parseZone(date).format(dateFormat);
};

export const addUnitToDate = (
  date: string | Date,
  amount: number,
  unit: DateUnit
) => moment(date).add(amount, unit).toDate();

export const subtractUnitFromDate = (
  date: string | Date,
  amount: number,
  unit: DateUnit
) => moment(date).subtract(amount, unit).toDate();

export const momentDateOnlyIsSameOrAfter = (
  date1: string | Date,
  date2: string | Date
) => moment(date1).isSameOrAfter(parseZone(moment(date2)));

export const momentDateIsBefore = (
  afterDate: string | Date,
  beforeDate: string | Date
) => moment(afterDate).isBefore(parseZone(moment(beforeDate)));

export const momentDateOnlyIsSameOrBefore = (
  afterDate: string | Date,
  beforeDate: string | Date
) => moment(afterDate).isSameOrBefore(parseZone(moment(beforeDate)));

export const momentDateOnlyIsAfter = (
  date1: string | Date,
  date2: string | Date
) => moment(date1).isAfter(parseZone(moment(date2)));

export const validateDayForTimelogUpdate = (date: string) => {
  const dayNumber = moment().weekday();
  if (dayNumber >= 4) {
    const thisWeek = moment.utc(date).isSame(moment(), 'week');
    if (!thisWeek) return false;
    return true;
  }
  const lastWeek = moment
    .utc(date)
    .isSame(moment().subtract(1, 'week'), 'week');
  const thisWeek = moment.utc(date).isSame(moment(), 'week');
  if (!thisWeek && !lastWeek) return false;
  return true;
};
export const getMomentDate = (date: string | Date) => moment(date);

export const momentDateIsBetweenTwoDates = (
  date1: string | Date,
  date2: string | Date
) => moment(getCurrentDate()).isBetween(date1, date2, undefined, '[]');

export const MomentDate = (date?: string) => {
  if (date) return moment(date);
  return moment();
};

export const checkDateWithinXDays = (date: string, numberOfDays: number) => {
  const currentDate = getCurrentDate();
  const subtractedDate = moment(currentDate)
    .subtract(numberOfDays, 'd')
    .format(config.dateViewFormat);
  return moment(date).isBetween(subtractedDate, currentDate, undefined, '[]');
};

export const getDaysInAMonth = (date: string | Date) =>
  moment(date).daysInMonth();

export const getLastDateOfMonth = (date: Date) => {
  const daysInMonth = moment(date, config.yearAndMonthFormat).daysInMonth();
  return moment(date.setDate(daysInMonth)).toDate();
};

export const getMonth = (date: Date | string) => moment(new Date(date)).month();

export const setParticularDateOfMonth = (date: Date, day: number) =>
  moment(date.setDate(day)).toDate();

export const formatDateToYYYYMMDD = (date: string) => {
  if (date !== '') {
    return moment(new Date(date)).format(config.yearMonthDateFormat);
  }
  return '';
};

export const formatToISODate = (date: string) =>
  date !== ''
    ? new Date(formatDateToYYYYMMDD(formatDate(date)))?.toISOString()
    : '';

export const formatISOToDate = (
  date: string,
  { format = config.dateViewFormat }: { format: string }
) => {
  if (!date) return date;
  return date !== '' ? moment(date?.split('T')[0]).format(format) : '';
};

export const formatISOToFullDateViewFormat = (
  date: string,
  { format = config.dateViewFormat }: { format: string }
) => {
  if (!date) return date;
  return date !== '' ? moment(date).format(format) : '';
};

// 02/20/2023 -> 20th
export const getNthDayofTheMonth = (date?: string) => {
  const givenDate = date ? new Date(date) : new Date();
  return moment(givenDate).format('Do');
};

// 02/20/2023 -> February
export const getMonthOftheYearInWords = (date?: string) => {
  const givenDate = date ? new Date(date) : new Date();
  return moment(givenDate).format('MMMM');
};

// 02/20/2023 -> 2023
export const getYear = (date?: string) => {
  const givenDate = date ? new Date(date) : new Date();
  return moment(givenDate).format('YYYY');
};

export const getMinute = (date?: string) => moment(date).minutes();

export const getTime = (date?: string) => moment(date).valueOf();

export const momentDate = (
  date?: string,
  format = config.momentDateTimeFormat
) => moment(date, format).toDate();

export const getDateDuration = (start: string, end: string) => {
  const startTime = moment(start);
  const endTime = moment(end);
  const duration = moment.duration(endTime.diff(startTime)).asHours();

  if (duration >= 1) {
    const hours = Math.floor(duration);
    return `${hours} hr(s)`;
  }
  const minutes = Math.round(duration * 60);
  return `${minutes} min(s)`;
};

/**
 * Format Time
 * 
 * @example
 * Input {
    time:123232323
 * }
    
 Output:'1:00 PM'
* */

export const formatTimeStampToString = (time: number) => {
  const date = new Date(time * 1000);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? 'PM' : 'AM';

  // Add leading zero for single-digit hours and minutes
  const formattedHours = (hours % 12 === 0 ? 12 : hours % 12)
    .toString()
    .padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  const formattedTime = `${formattedHours}:${formattedMinutes} ${amOrPm}`;
  return formattedTime;
};

export const isBefore = (startDate: Date, endDate = moment()) =>
  moment(startDate).isBefore(endDate);

export const isDateValid = (date: string) => moment(date).isValid();

export const getTimeAgoString = (lastActiveAt: number) => {
  try {
    const date = moment(lastActiveAt * 1000);

    return moment(date.format('YYYY-MM-DD HH:mm:ss')).fromNow();
  } catch (error) {
    return '';
  }
};

export const compareTimeStamp = (timestamp1: number, timestamp2: number) => {
  const sentAtDate = moment.unix(timestamp1).format('YYYY/MM/DD');
  const currentDate = moment.unix(timestamp2).format('YYYY/MM/DD');
  return sentAtDate === currentDate;
};

export const extractManualDateTime = (data: string | Date) => {
  // Parse the ISO string using Moment.js
  const timeStringUTC = moment(data).utc().format('HH:mm:ss');
  const datePartUTC = moment(data).utc().format('YYYY-MM-DD');

  const momentDateFormat = moment(data);

  const isTimeExactly12 = timeStringUTC === '00:00:00';

  // Extract date and time components
  const datePart = momentDateFormat.format('YYYY-MM-DD');
  const timePart = momentDateFormat.format('hh:mm')?.replace(':', '');
  const AMorPMPart = momentDateFormat.format('A');

  return {
    datePart,
    timePart,
    AMorPMPart,
    isTimeExactly12,
    datePartUTC,
  };
};

/**
 * Used for checking if given AM or PM is valid for given time
 * @param string
 * Input : 12:10 AM
 * Output: true | false
 */
export const isMeridiemValid = (value: string) => {
  const [hourStr, minuteStr] = value!.split(':');
  const meridiem = minuteStr?.split(' ')[1]?.toLowerCase();
  const hour = parseInt(hourStr, 10);
  if (!meridiem) return false;
  return (
    (hour === 0 && (meridiem === 'am' || meridiem === 'AM')) ||
    (hour >= 12 && (meridiem === 'pm' || meridiem === 'am')) ||
    (hour <= 12 &&
      hour !== 0 &&
      (meridiem === 'am' ||
        meridiem === 'AM' ||
        meridiem === 'pm' ||
        meridiem === 'PM'))
  );
};

export const isStartTimeGreaterThanEndTime = ({
  startTime,
  endTime,
}: {
  startTime: string;
  endTime: string;
}) => {
  const st = moment(startTime, 'hh:mm A').valueOf();
  const et = moment(endTime, 'hh:mm A').valueOf();
  return st > et;
};

export const addAdditionalMinutes = (
  time: string,
  timeToAdd?: number,
  unit?: DurationInputArg2
) =>
  moment(time, dateTimeFormatConfig.twelveHourTimeFormat)
    .add(timeToAdd || 1, unit || 'hour')
    .format(dateTimeFormatConfig.twelveHourTimeFormat);

// Function to round to the nearest hour
export function roundToNearestHour(timeString: string) {
  // Parse the input time string
  const time = moment(timeString, 'hh:mm A');

  // Get the minutes part
  const minutes = time.minutes();

  if (minutes > 0) {
    time.add(1, 'hour');
  }

  // Set minutes and seconds to zero
  time.minutes(0).seconds(0);

  // Return the rounded time as a formatted string
  return time.format('hh:mm A');
}

export const roundToNearestHourFromCurrentTime = () =>
  roundToNearestHour(
    MomentDate().format(dateTimeFormatConfig.twelveHourTimeFormat)
  );

export const getLocalTimeFromUnixTimeStamp = ({
  timeStamp,
  format,
}: {
  timeStamp: string | number;
  format: string;
}) => moment.unix(Number(timeStamp)).local().format(format);

export { moment, momentTimeZone };
