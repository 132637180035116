import { Chip, SxProps, Typography } from '@mui/material';
import { capitalizeFirstLetter } from 'utils/common';
import { startCase } from 'utils/lodash';

interface Props {
  sx?: SxProps;
  label: string;
  value: string;
  capitalize?: boolean;
  onDelete: (label: string) => void;
  modifyLabelCasing?: boolean;
}
/**
 * @depreciated
 */
const FilterChip = ({
  sx,
  label,
  value,
  onDelete,
  capitalize,
  modifyLabelCasing,
}: Props) => {
  const toDisplayLabel = startCase(label);

  const getDisplayLabel = () => {
    if (!modifyLabelCasing) return label;

    if (capitalize) {
      return capitalizeFirstLetter(toDisplayLabel);
    }

    return toDisplayLabel;
  };

  return (
    <Chip
      label={
        <Typography
          data-cy={`appliedFilter-${label}`}
          gutterBottom={false}
          variant="body2"
        >
          {getDisplayLabel()}:{' '}
          <Typography
            component="span"
            fontWeight={(theme) => theme.typography.fontWeightMedium}
            gutterBottom={false}
            variant="body2"
          >
            {value}
          </Typography>
        </Typography>
      }
      onDelete={() => onDelete(label)}
      size="small"
      sx={{ ...sx }}
    />
  );
};

FilterChip.defaultProps = {
  sx: {
    height: 30,
  },
  capitalize: true,
  modifyLabelCasing: true,
};

export default FilterChip;
