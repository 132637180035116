import { useEffect, useState } from 'react';

import DatePicker from 'common/DatePicker';
import { IMembershipDateProps } from 'interfaces/client-management/manage-membership';
import { useUpdateClientEnrollmentMutation } from 'services/client-management/enrollment';
import {
  formatDateView,
  getMomentDate,
  isValidDate,
  momentDateOnlyIsSameOrAfter,
} from 'utils/moment';

import { useHandleInlineDateChange } from '../hooks/useHandleInlineDateChange';
import { EditableDetailLayout } from './EditableDetailLayout';

export const SubmittedEditableDate = ({
  setIsEditing,
  setDate,
  date,
  status,
  parentOffering,
}: IMembershipDateProps) => {
  const [startDate, setStartDate] = useState('');
  const updateClientEnrollmentMutation = useUpdateClientEnrollmentMutation();
  const [invalidDate, setInvalidDate] = useState(false);
  const { handleCancel, handleSave } = useHandleInlineDateChange({
    setIsEditing,
    setDate,
  });

  const handleSaveAction = () => {
    // For Submitted status
    if (startDate && startDate !== date && !invalidDate) {
      handleSave({
        status,
        startDate,
        endDate: '',
      });
    }
  };

  const handleSaveDisable = () => {
    if (
      startDate &&
      isValidDate(startDate) &&
      startDate !== date &&
      !parentOffering
    ) {
      if (invalidDate) setInvalidDate(false);
      return false;
    }
    if (
      startDate &&
      isValidDate(startDate) &&
      startDate !== date &&
      parentOffering &&
      parentOffering?.startDate &&
      momentDateOnlyIsSameOrAfter(startDate, parentOffering?.startDate)
    ) {
      if (invalidDate) setInvalidDate(false);

      return false;
    }
    return true;
  };

  const handleSelectedDate = (selectedDate: string) => {
    if (!selectedDate) {
      setInvalidDate(true);
    } else {
      const formattedDate = formatDateView(selectedDate as string) || '';
      const isDateValid = isValidDate(formattedDate);
      if (isDateValid) {
        setInvalidDate(false);
      }
      setStartDate(formattedDate);
    }
  };

  useEffect(() => {
    if (date) {
      setStartDate(date);
    }
  }, [date]);

  useEffect(() => {
    if (startDate) {
      setInvalidDate(false);
    }
  }, [startDate]);
  return (
    <EditableDetailLayout
      handleCancel={handleCancel}
      handleSave={handleSaveAction}
      isLoading={updateClientEnrollmentMutation.isLoading}
      isSaveDisabled={handleSaveDisable()}
    >
      <DatePicker
        autoFocus
        disabled={updateClientEnrollmentMutation.isLoading}
        handleChange={(d) => handleSelectedDate(d as string)}
        inputProps={{
          variant: 'standard',
          'data-cy': `client-membership-submitted-date-input`,
        }}
        label="Start Date"
        minDate={
          parentOffering?.startDate
            ? getMomentDate(parentOffering?.startDate)
            : undefined
        }
        onError={(e) => {
          if (!e) {
            setInvalidDate(true);
          } else {
            setInvalidDate(false);
          }
        }}
        value={startDate}
      />
    </EditableDetailLayout>
  );
};
