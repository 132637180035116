export enum SponsorSortBy {
  SPONSOR = 'sponsor',
  TOTAL_REMAINING_TIME = 'totalRemainingTime',
  TOTAL_AFTER_TIME = 'totalAfterHours',
}
export enum ExportView {
  DETAILED_VIEW = 'detailedView',
  LIST_VIEW = 'listView',
}

export enum ExportType {
  EXPORT_ALL = 'export-all',
  EXPORT_CURRENT = 'export-current',
}
