import { useCallback, useEffect } from 'react';

import { useTheme } from '@mui/material';
import commonConstants from 'constants/common';
import { flowiseChatBot } from 'constants/flowiseAIChat';
import {
  MiscellaneousCode,
  TenantSettingStatus,
  UsersCode,
} from 'enums/tenant-management/tenant';
// eslint-disable-next-line import/extensions
import chatbot from 'flowise-embed/dist/web.js';
import { selectTenantSettings } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { checkIsUsersSettingEnabled } from 'utils/tenantSetting';

import UserAvatar from '../assets/images/png/avatar-bw-placeholder.png';
import VitafyLogo from '../assets/images/svg/vitafy-logo-partial.svg';
import { useCheckUserIsClient } from './useCheckUserIsClient';
import { useGetMiscellaneousTenantSettings } from './useGetMiscellaneousTenantSettings';

const { VITAFY_URL } = commonConstants;

export const useChatBotInitialize = () => {
  const theme = useTheme();

  const tenantSettings = useAppSelector(selectTenantSettings);

  const isChatBotEnabled =
    useGetMiscellaneousTenantSettings(MiscellaneousCode.CHAT_BOT_ENABLED) ===
    TenantSettingStatus.ENABLED;

  const isAdminChatEnabled = checkIsUsersSettingEnabled(UsersCode.ADMIN_CHAT, {
    tenantSettings,
  });

  const userIsClient = useCheckUserIsClient();

  const showAdminChatWidget = isAdminChatEnabled && !userIsClient;

  /**
   * Changes powered by text to 'vSuite'
   */
  const changePoweredByText = useCallback(() => {
    const chatbotElementShadowRoot =
      document.getElementsByTagName('flowise-chatbot')?.[0]?.shadowRoot;

    if (chatbotElementShadowRoot) {
      const chatWidgetButton: HTMLAnchorElement =
        chatbotElementShadowRoot.querySelector('[part="button"]')!;

      if (chatWidgetButton?.style) {
        chatWidgetButton.style.backgroundColor = theme.palette.common.white;
        const svgIcon = chatWidgetButton.querySelector('svg');
        if (svgIcon?.style) {
          svgIcon.style.fill = theme.palette.gray.dark;
        }
      }

      chatWidgetButton?.addEventListener('click', () => {
        setTimeout(() => {
          const poweredByTextLink = chatbotElementShadowRoot.getElementById(
            'lite-badge'
          ) as HTMLAnchorElement;

          if (poweredByTextLink) {
            poweredByTextLink.href = VITAFY_URL;
            const text = poweredByTextLink.querySelector('span');
            if (text) {
              text.textContent = ' vSuite';
            }
          }
        }, 300);
      });
    }
  }, [theme.palette]);

  const handleInitilaizeChatBot = useCallback(() => {
    if (isChatBotEnabled) {
      const chatbotConfig = {
        chatflowid: process.env.REACT_APP_CHAT_FLOW_ID!,
        apiHost: process.env.REACT_APP_CHAT_API_HOST!,
        chatflowConfig: {},
        theme: {
          button: {
            class: 'flowsize-chat-button',
            id: 'flowsize-chat-button',
            backgroundColor: theme.palette.primary.main,
            height: '60px !important',
            right: !showAdminChatWidget ? 10 : 70,
            bottom: 20,
            iconColor: theme.palette.getContrastText(
              theme.palette.primary.main
            ),
            customIconSrc: VitafyLogo,
          },

          chatWindow: {
            textColor: theme.palette.common.white,
            showTitle: true,
            title: flowiseChatBot.title,
            // backgroundColor: '#000000',
            welcomeMessage: flowiseChatBot.welcomeMessage,
            backgroundColor: theme.palette.common.white,
            height: 500,
            width: 400,
            fontSize: 16,
            poweredByTextColor: '#303235',
            botMessage: {
              backgroundColor: '#f7f8ff',
              textColor: '#303235',
              showAvatar: true,
              avatarSrc: VitafyLogo,
            },
            userMessage: {
              backgroundColor: '#3B81F6',
              textColor: theme.palette.common.white,
              showAvatar: true,
              avatarSrc: UserAvatar,
            },
            textInput: {
              placeholder: 'Type your question',
              backgroundColor: theme.palette.common.white,
              textColor: '#303235',
              sendButtonColor: '#3B81F6',
            },
          },
        },
      };

      chatbot.init(chatbotConfig);

      changePoweredByText();
    }
    return null;
  }, [
    isChatBotEnabled,
    theme.palette,
    showAdminChatWidget,
    changePoweredByText,
  ]);

  useEffect(() => {
    handleInitilaizeChatBot();
  }, [handleInitilaizeChatBot]);
};
