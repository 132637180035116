import { useSearchParams } from 'react-router-dom';

import uiRoutes from 'constants/uiRoutes';
import { selectMenu } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

import { PreOTPAuthenticateWrapper } from './PreOTPAuthenticateWrapper';

interface ICasePreOTPAuthenticateWrapperProps {
  children: JSX.Element;
}

export const CasePreOTPAuthenticateWrapper = ({
  children,
}: ICasePreOTPAuthenticateWrapperProps) => {
  const [searchParams] = useSearchParams();

  const caseId = searchParams.get('caseId');
  const redirectPath = `${uiRoutes.cases.detail}?caseId=${caseId}&fromLink=true`;

  const allmenus = useAppSelector(selectMenu);
  const hasCaseMenuAccess = allmenus?.some(
    (item) => item.code === 'CASES' || item.routePath === uiRoutes.cases.detail
  );

  return (
    <PreOTPAuthenticateWrapper
      caseId={caseId}
      hasMenuAccess={hasCaseMenuAccess}
      redirectPath={redirectPath}
    >
      {children}
    </PreOTPAuthenticateWrapper>
  );
};
