import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CometChat } from '@cometchat-pro/chat';
import { faClose, faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Badge,
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import commonConstants from 'constants/common';
import { layoutConstants } from 'constants/layout';
import messageConstant from 'constants/message';
import { ICometChatMediaFile } from 'interfaces/chat';
import { enqueueSnackbar } from 'notistack';
import { useTenantQuery } from 'services/chat';
import { CometChatService } from 'services/virtual-visit/comet-chat/comet-chat-service';
import {
  selectActiveTenantList,
  selectCloseWindow,
  selectListener,
  selectTenantId,
  selectUnreadMessage,
  setCloseWindow,
  setConversationId,
  setIsLoading,
  setTenantList,
  setUnreadMessage,
  setUpdateTenantConversationList,
  setUpdateUserOnMsg,
} from 'stores/chat';
import { useAppSelector } from 'stores/hooks';
import {
  selectUserAdvancedSearch,
  selectUserFilter,
  selectUserSort,
} from 'stores/tenant-management/user';
import { fetchUnreadMessage, filterDuplicates } from 'utils/chat';
import { combineFilters } from 'utils/misc';

import TenantAvatar from './Avatar';
import ChatList from './ChatList';
import ChatWindow from './ChatWindow';

const ChatComponent = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const advancedSearch = useAppSelector(selectUserAdvancedSearch);
  const activeTenantsList = useAppSelector(selectActiveTenantList);
  const unreadMessageCount = useAppSelector(selectUnreadMessage);
  const sort = useAppSelector(selectUserSort);
  const listener = useAppSelector(selectListener);
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const isCloseWindow = useAppSelector(selectCloseWindow);

  const filters = useAppSelector(selectUserFilter);
  const combinedFilters = combineFilters(
    { ...filters, ...sort },
    advancedSearch
  );

  const tenantId = useAppSelector(selectTenantId);

  const { data, isLoading } = useTenantQuery(
    {
      ...combinedFilters,
      userId: tenantId,
    },
    !!tenantId
  );

  const tenantList: any = data;

  useEffect(() => {
    // Fetch total cometchat users in qa and dev..and show messages
    // Show Comet chat quota full message
    const devEnv = process.env.REACT_APP_ENVIRONMENT;

    if (devEnv === 'DEV' || devEnv === 'QA') {
      const usersRequest = new CometChat.UsersRequestBuilder()
        .setLimit(30)
        .build();

      usersRequest.fetchNext().then(
        (userList) => {
          const showWarning = userList?.length >= 23;
          if (showWarning) {
            enqueueSnackbar('Comet chat users quota full', {
              variant: 'error',
            });
          }
        },
        () => {}
      );
    }

    if (isCloseWindow) {
      setIsWindowOpen(false);
    }
  }, [isCloseWindow]);

  useEffect(() => {
    // Listen for incoming message
    CometChatService.listenForMessage({
      onTextMessageReceived: (message: CometChat.TextMessage | any) => {
        const uid = message.sender.uid ?? '';

        const activeTenantIndex = activeTenantsList.findIndex(
          (item) => item.userId === uid
        );

        // If tenant is active and minimized window than donot show read count on message button..instead show on the tenant window
        if (activeTenantIndex !== -1) {
          return;
        }

        // If chat window is closed than show unread messages count
        if (!isWindowOpen) {
          CometChat.getUnreadMessageCountForAllUsers().then((unReadCount) => {
            dispatch(setUnreadMessage(unReadCount));
          });
        }

        dispatch(
          setConversationId({
            userId: uid,
            conversationId: message.conversationId,
          })
        );

        dispatch(
          setUpdateTenantConversationList({
            userId: uid,
            conversationId: message.conversationId,
            text: message.text,
          })
        );

        dispatch(
          setUpdateUserOnMsg({
            id: uid,
            message: message.text,
            updatedAt: message.updatedAt,
          })
        );
      },
      onMediaReceived: (message: ICometChatMediaFile) => {
        // If chat window is closed than show unread messages count
        if (!isWindowOpen) {
          CometChat.getUnreadMessageCountForAllUsers().then((unReadCount) => {
            dispatch(setUnreadMessage(unReadCount));
          });
        }
        const uid = message.sender.uid ?? '';
        dispatch(
          setConversationId({
            userId: uid,
            conversationId: message.conversationId,
          })
        );

        dispatch(
          setUpdateTenantConversationList({
            userId: uid,
            conversationId: message.conversationId,
            messageType: 'File',
          })
        );

        dispatch(
          setUpdateUserOnMsg({
            id: uid,
            message: messageConstant.FILE_RECEIVED,
            updatedAt: message.updatedAt,
          })
        );
      },
      listenerID: tenantId ?? '',
    });
    return () => CometChat.removeMessageListener('');
  }, [dispatch, tenantId, listener, isWindowOpen, activeTenantsList]);

  useEffect(() => {
    if (tenantList?.length) {
      dispatch(setTenantList(filterDuplicates(tenantList, tenantId)));
      dispatch(setIsLoading(false));
      fetchUnreadMessage();
    } else if (!isLoading && tenantId) {
      dispatch(setIsLoading(false));
    }
  }, [dispatch, isLoading, tenantId, tenantList]);

  return (
    <>
      <Box
        bottom={0}
        display="flex"
        flex={1}
        height="90%"
        position="fixed"
        right={isWindowOpen ? '1%' : '5%'}
        zIndex={commonConstants.MAX_ZINDEX}
      >
        <Box
          bottom={0}
          display="flex"
          flexDirection="row"
          height={layoutConstants.CHAT.chatWindowHeight}
          justifyContent="flex-end"
          position="absolute"
          right="100%"
          width="auto"
        >
          {activeTenantsList.map((item) => (
            <ChatWindow item={item} key={item.userId} />
          ))}
        </Box>
        <Box
          bgcolor={theme.palette.common.white}
          boxShadow="0px 0px 6px #00000029"
          id="mainwindow"
          overflow="hidden"
          position="relative"
          sx={{
            transition: isWindowOpen ? '0.5s ease' : '',
          }}
          top={isWindowOpen ? 0 : '100%'}
          width={isWindowOpen ? layoutConstants.CHAT.chatListWindowWidth : 0}
        >
          <Stack
            alignItems="center"
            bgcolor={theme.palette.primary.main}
            borderRadius=" 4px 4px 0px 0px"
            direction="row"
            gap={1}
            height={theme.spacing(6)}
            justifyContent="space-between"
            p={2}
            sx={{ cursor: 'pointer' }}
          >
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              onClick={() => setIsWindowOpen(!isWindowOpen)}
              width="100%"
            >
              <TenantAvatar height={30} item={null} width={30} />
              <Typography
                color={theme.palette.common.white}
                ml={1}
                mr={2}
                pt={0.5}
              >
                Messaging
              </Typography>
              {unreadMessageCount?.length > 0 && (
                <Badge
                  badgeContent={unreadMessageCount?.length}
                  color="error"
                />
              )}
            </Box>
            <Box display="flex" gap={0}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setIsWindowOpen(!isWindowOpen);
                }}
                size="small"
                sx={{
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  },
                }}
              >
                <FontAwesomeIcon
                  color={theme.palette.common.white}
                  cursor="pointer"
                  icon={faClose}
                />
              </IconButton>
            </Box>
          </Stack>
          <Divider />
          <ChatList />
        </Box>
      </Box>
      {!isWindowOpen && (
        <IconButton
          className="snackbar-close-button"
          onClick={() => {
            dispatch(setCloseWindow(false));
            setIsWindowOpen(!isWindowOpen);
          }}
          size="1x"
          sx={{
            zIndex: commonConstants.MAX_ZINDEX,
            position: 'fixed',
            bottom: 20,
            right: 10,
            backgroundColor: theme.palette.primary.main,
            padding: 2,
            boxShadow: theme.customShadows.medium,
            cursor: 'pointer',
            ':hover': {
              backgroundColor: theme.palette.primary.main,
            },
          }}
        >
          <Badge
            badgeContent={unreadMessageCount?.length}
            color="error"
            sx={{ position: 'absolute', top: 10, right: 10 }}
          />
          <FontAwesomeIcon
            color={theme.palette.common.white}
            icon={faMessage}
            size="sm"
          />
        </IconButton>
      )}
    </>
  );
};

export default ChatComponent;
