import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, useTheme } from '@mui/material';
import { ModalFooter } from 'common';
import FileDropzone from 'common/dropzone';
import { defaultMaxFileSize } from 'constants/file';
import { MAX_FILE_SIZE } from 'constants/virtualVisit';
import { MessageStatus } from 'enums/chat';
import { ITenantUser } from 'interfaces/chat';
import { IFileSchema } from 'interfaces/documents';
import { IFileUpload } from 'interfaces/virtual-visit';
import { IResponseMessage } from 'interfaces/virtual-visit/comet-chat';
import { useSnackbar } from 'notistack';
import { useAddDocumentMutation } from 'services/documents';
import { CometChatService } from 'services/virtual-visit/comet-chat/comet-chat-service';
import { selectFileBeingRenamed, selectFileNotSupported } from 'stores/cases';
import {
  selectTenantId,
  setConversationId,
  setRegisterUser,
} from 'stores/chat';
import { useAppSelector } from 'stores/hooks';
import { uploadFileChat } from 'utils/chat';
import { getFullName } from 'utils/common';
import { getCurrentTime } from 'utils/moment';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  item: ITenantUser | null;
  eventID: string;
  handleClose: () => void;
  callBack: (data: IResponseMessage) => any;
}

const ChatUploadDocument = ({
  item,
  eventID,
  handleClose = () => {},
  callBack = () => {},
}: Props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const timeStamp = getCurrentTime();
  const { enqueueSnackbar } = useSnackbar();
  const tenantId = useAppSelector(selectTenantId);

  const isFilesNotSupported = useAppSelector(selectFileNotSupported);
  const isFilesBeingRenamed = useAppSelector(selectFileBeingRenamed);

  const [selectedFiles, setSelectedFiles] = useState<IFileSchema[]>([]);
  const addDocumentMutation = useAddDocumentMutation();
  const onFileChange = (files: IFileSchema[]) => {
    if (files[0]?.isErrorSize && files[0]?.isErrorType) {
      return;
    }
    const data = files.map((e: IFileSchema) => {
      const { isErrorSize, isErrorType, ...rest } = e;
      return rest;
    });
    setSelectedFiles(data);
  };
  const totalFileSize = selectedFiles.reduce(
    (prev, curr) => prev + curr.size,
    0
  );
  const isFileSizeExceeds = totalFileSize > defaultMaxFileSize;
  const isDisabled = () => {
    if (isFilesNotSupported) return true;
    if (isFilesBeingRenamed) return true;
    const containError = selectedFiles.find((e) => e.isErrorType);
    if (!selectedFiles.length || containError || isFileSizeExceeds) return true;
    return false;
  };

  const createSendMessage = (value: ITenantUser | null, uid: string) => {
    CometChatService.createUser(
      value?.userId ?? '',
      getFullName(value?.demographic?.firstName, value?.demographic?.lastName)
    ).finally(async () => {
      try {
        dispatch(setRegisterUser(value?.userId ?? ''));
        // establish a connection between two users..call this api to send and accept friend request between two users
        await CometChatService.addFriend(tenantId ?? '', value?.userId ?? '');
        await CometChatService.addFriend(value?.userId ?? '', tenantId ?? '');
        uploadFileChat(selectedFiles[0], eventID)
          .then(
            // used any because of  Property 'data' is protected in type 'MediaMessage' but public in type 'IFileUpload' and used outside the block.
            async (message: IFileUpload | any) => {
              callBack({
                text: message.title,
                isSentByMe: true,
                isTextMessage: false,
                time: timeStamp,
                sender: message?.sender?.name,
                title: message.title,
                type: message?.data?.attachments[0]?.extension,
                fileName: message.fileName,
                status: MessageStatus.SUCCESS,
                uid,
                messageId: message.id,
                conversationId: message.conversationId,
              });
              dispatch(
                setConversationId({
                  userId: value?.userId ?? '',
                  conversationId: message.conversationId,
                })
              );
            }
          )
          .catch(() => callBack({ status: MessageStatus.FAILED, uid }));
      } catch (error) {
        callBack({ status: MessageStatus.FAILED, uid });
      }
    });
  };

  const handleActionButtonClick = () => {
    if (selectedFiles && selectedFiles.length < 2) {
      const uid = uuidv4();
      handleClose();
      callBack({ status: MessageStatus.SENDING, uid, conversationId: '' });
      if (!item?.isRegistered || !item.isFriend) {
        createSendMessage(item, uid);
      } else {
        uploadFileChat(selectedFiles[0], eventID)
          .then(
            // used any because of  Property 'data' is protected in type 'MediaMessage' but public in type 'IFileUpload' and used outside the block.
            async (message: IFileUpload | any) => {
              callBack({
                messageId: message.id,
                text: message.title,
                isSentByMe: true,
                isTextMessage: false,
                time: timeStamp,
                sender: message?.sender?.name,
                title: message.title,
                type: message?.data?.attachments[0]?.extension,
                fileName: message.fileName,
                status: MessageStatus.SUCCESS,
                uid,
                conversationId: message.conversationId,
              });
            }
          )
          .catch(() => callBack({ status: MessageStatus.FAILED, uid }));
      }
    } else {
      enqueueSnackbar('You can only upload one file at a time.', {
        variant: 'error',
      });
    }
  };

  return (
    <Box>
      <Box minHeight="60vh" padding={2}>
        <FileDropzone
          isFileSizeExceeds={isFileSizeExceeds}
          maxSize={MAX_FILE_SIZE}
          onChange={onFileChange}
        />
      </Box>
      <ModalFooter
        actionButtonLabel="Upload"
        actionButtonType="button"
        cancelButtonType="back"
        isDisabled={isDisabled()}
        isLoading={addDocumentMutation.isLoading}
        onActionButtonClick={handleActionButtonClick}
        onCancelButtonClick={handleClose}
      />
    </Box>
  );
};

export default ChatUploadDocument;
