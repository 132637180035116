import { ManageMembershipActionStep } from 'enums/client-management/manage-membership';

import { useMembershipAction } from './useMembershipAction';

export const useGetManageMembershipActionStep = () => {
  const membershipAction = useMembershipAction();

  const isStepInitial = membershipAction.checkActionStep(
    ManageMembershipActionStep.INITIAL
  );

  const isStepFirst = membershipAction.checkActionStep(
    ManageMembershipActionStep.FIRST
  );
  const isStepSecond = membershipAction.checkActionStep(
    ManageMembershipActionStep.SECOND
  );
  const isStepThird = membershipAction.checkActionStep(
    ManageMembershipActionStep.THIRD
  );
  const isStepFinal = membershipAction.checkActionStep(
    ManageMembershipActionStep.FINAL
  );

  const actionButtonLabel = isStepFinal ? 'Done' : 'Continue';
  return {
    isStepInitial,
    isStepFirst,
    isStepSecond,
    isStepThird,
    isStepFinal,
    actionButtonLabel,
  };
};
