import apiRoutes from 'constants/apiRoutes';
import { EStatusModule } from 'enums/case';
import { IListResponse, IResponse } from 'interfaces/http';
import {
  ICreateStatusResponse,
  ICreateStatusSchema,
  IFetchStatusRow,
  IStatusCount,
  IUpdateStatusSchema,
} from 'interfaces/status';
import { baseRequest as BaseRequest, IBaseRequest } from 'utils/axios';

const {
  status: { create, getAll, getstatusCount, remove, getOne, edit },
} = apiRoutes;

export const createStatus = async (
  data: ICreateStatusSchema
): Promise<IResponse<ICreateStatusResponse>> => {
  const { response, error } = await BaseRequest({
    method: 'POST',
    url: create,
    data,
  });
  if (error) {
    throw new Error(error.message);
  }
  return response.data;
};
export const fetchStatus = async (
  moduleName: EStatusModule
): Promise<IResponse<IListResponse<IFetchStatusRow>>> => {
  const { error, response } = await BaseRequest({
    method: 'GET',
    url: getAll,
    params: { module: moduleName },
  });
  if (error) {
    throw new Error(error.message);
  }
  return response.data;
};

export const fetchStatusCount = async (
  id: string,
  modules: EStatusModule
): Promise<IResponse<IStatusCount>> => {
  const { error, response } = await BaseRequest({
    method: 'GET',
    url: getstatusCount.replace(':statusId', id),
    params: { module: modules },
  });
  if (error) {
    throw new Error(error.message);
  }
  return response.data;
};

export const deleteStatus = async (
  id: string,
  statusId: string | null,
  modules: EStatusModule
): Promise<IResponse<IFetchStatusRow>> => {
  const options: IBaseRequest = {
    method: 'DELETE',
    url: remove.replace(':statusId', id),
    params: { module: modules },
  };

  if (statusId) {
    options.data = { statusId };
  }

  const { error, response } = await BaseRequest(options);
  if (error) {
    throw new Error(error.message);
  }
  return response.data;
};

export const getStatusDetail = async (
  id: string,
  modules: EStatusModule
): Promise<IResponse<IFetchStatusRow>> => {
  const { error, response } = await BaseRequest({
    method: 'GET',
    url: getOne.replace(':statusId', id),
    params: { module: modules },
  });
  if (error) {
    throw new Error(error.message);
  }
  return response.data;
};

export const updateStatus = async (
  data: IUpdateStatusSchema,
  id: string,
  modules: EStatusModule
): Promise<IResponse<IFetchStatusRow>> => {
  const { error, response } = await BaseRequest({
    method: 'PUT',
    url: edit.replace(':statusId', id),
    data,
    params: { module: modules },
  });
  if (error) {
    throw new Error(error.message);
  }
  return response.data;
};
