import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Stack,
  SxProps,
  Typography,
  TypographyProps,
} from '@mui/material';

interface IProps {
  text?: string;
  containerSx?: SxProps;
  iconSize?: SizeProp | undefined;
  textProps?: TypographyProps;
  button?: boolean;
  icon?: IconDefinition;
  onClickButton?: () => void;
}

const NoRecordsFoundDisplay = ({
  text,
  containerSx,
  iconSize,
  textProps,
  button = false,
  icon = faFileAlt,
  onClickButton = () => {},
}: IProps): JSX.Element => (
  <Box
    sx={{
      backgroundColor: (theme) => theme.palette.common.white,
      padding: 2,
      ...containerSx,
    }}
  >
    <Stack direction="column" spacing={1}>
      <FontAwesomeIcon icon={icon} size={iconSize} />
      <Typography
        data-cy="no-record-found"
        display="block"
        gutterBottom={false}
        textAlign="center"
        {...textProps}
      >
        {text}
      </Typography>
      {button && (
        <Button onClick={onClickButton} variant="outlined">
          Send a message{' '}
        </Button>
      )}
    </Stack>
  </Box>
);

NoRecordsFoundDisplay.defaultProps = {
  text: 'No Records Found',
  containerSx: null,
  iconSize: 'lg',
  textProps: undefined,
  button: false,
  icon: faFileAlt,
  onClickButton: () => {},
};

export default NoRecordsFoundDisplay;
