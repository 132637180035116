import { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link, useLocation } from 'react-router-dom';

import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useGetSettingMenus } from 'hooks/useGetSettingMenus';
import {
  selectAuthSettingConfigurations,
  setSettingConfigurations,
} from 'stores/auth';
import { useAppDispatch, useAppSelector } from 'stores/hooks';

const LayoutRoute = () => {
  const location = useLocation();
  const theme = useTheme();
  const activeRoute = (to: string) => location.pathname === to;

  const dispatch = useAppDispatch();
  const authSettingConfigurations = useAppSelector(
    selectAuthSettingConfigurations
  );
  const settingMenus = useGetSettingMenus();

  useEffect(() => {
    if (!authSettingConfigurations.length && settingMenus.length)
      dispatch(setSettingConfigurations(settingMenus));
  }, [authSettingConfigurations.length, dispatch, settingMenus]);

  return (
    <Box maxHeight="85vh" sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
      <PerfectScrollbar>
        {authSettingConfigurations.map((data, index) => (
          <Box key={`${data.name}_${data.route}`} mt={index === 0 ? 0 : 3}>
            <Typography fontWeight="medium">{data.name}</Typography>
            <Stack gap={2} mt={data.children.length === 1 ? 1 : 2}>
              {data.children &&
                data.children.map((child) => (
                  <Box
                    alignItems="center"
                    borderRight={
                      activeRoute(child.routePath || '')
                        ? `3px solid ${theme.palette.secondary.main}`
                        : 'none'
                    }
                    component={Link}
                    display="flex"
                    key={child.resourceId}
                    position="relative"
                    sx={{ textDecoration: 'none' }}
                    to={child.routePath || ''}
                  >
                    <Typography
                      color="gray.dark"
                      fontWeight={
                        activeRoute(child.routePath || '') ? 'medium' : 'normal'
                      }
                      pl={2}
                      variant="body2"
                    >
                      {child.name}
                    </Typography>
                  </Box>
                ))}
            </Stack>
          </Box>
        ))}
      </PerfectScrollbar>
    </Box>
  );
};

export default LayoutRoute;
