import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import uiRoutes from 'constants/uiRoutes';
import { selectMenu } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { getRouteToMenuMap } from 'utils/auth';
import { hasAccess } from 'utils/routeGaurd';

import LoadingIndicator from './LoadingIndicator';

interface IRouteGuardProps {
  children: JSX.Element;
  route: string;
}

/**
 * Protects route by checking the available permissions/menus
 */
export const RouteGuard = ({ children, route }: IRouteGuardProps) => {
  const menus = useAppSelector(selectMenu);
  const routeToMenuMap = useMemo(() => getRouteToMenuMap(menus ?? []), [menus]);

  if (!menus?.length) {
    return <LoadingIndicator />;
  }

  const hasMenuAccess = Object.keys(routeToMenuMap)?.some((item) =>
    hasAccess(item, route)
  );

  if (!hasMenuAccess) {
    return <Navigate replace to={uiRoutes.index} />;
  }

  return children;
};
