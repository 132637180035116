/* eslint-disable no-underscore-dangle */
import { useSearchParams } from 'react-router-dom';

import {
  Box,
  Chip,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { VipCrown } from 'common/VipCrown';
import commonConstants from 'constants/common';
import { DictionaryKeys } from 'constants/languageDictionary';
import { IReferralCaseList, IRequestedTo } from 'interfaces/cases';
import { selectAuthTenantAssociation } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { selectLangDict } from 'stores/language-dictionary';
import { getFormattedStatus } from 'utils/cases';
import { getFullName } from 'utils/common';
import { getTextFromLangDict } from 'utils/languageDictionary';
import { formatDateView } from 'utils/moment';

interface IReferralCaseListItemProps {
  data: IReferralCaseList;
  selectedMobileCase?: VoidFunction;
  handleChangeSelectedCaseId: (caseId: string) => void;
}

const ReferralCaseListItem = ({
  data,
  selectedMobileCase,
  handleChangeSelectedCaseId,
}: IReferralCaseListItemProps) => {
  const [queryParameters] = useSearchParams();
  const referralCaseId = queryParameters.get(
    commonConstants.CASE_ID_QUERY_PARAMETER
  );
  const theme = useTheme();
  const smBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
  const xsBreakpoint = useMediaQuery(theme.breakpoints.up('xs'));
  const mdBreakpoint = useMediaQuery(theme.breakpoints.up('md'));

  const formattedStatus = getFormattedStatus(data.status?.code, theme);
  const authTenantAssocation = useAppSelector(selectAuthTenantAssociation);
  const langDict = useAppSelector(selectLangDict);
  const memberName = getTextFromLangDict(langDict?.dict, {
    groupCode: authTenantAssocation?.groupCode || '',
    key: DictionaryKeys.PATIENT_TERMINOLOGY,
    tenantCode: authTenantAssocation?.code || '',
  });
  const services = data.service;
  const service = services?.length ? services : undefined;
  const joinedServiceName = service
    ?.map((s) => `${s?.name} ${s?.code ? `(${s?.code})` : ''}`)
    .join(', ');
  const authTenantId = authTenantAssocation?.tenantId;

  const to = Array.isArray(data.to) ? data.to : [data.to];

  const assignedTo =
    ((to as any) ?? []).find(
      (tenant: IRequestedTo) => tenant.id === authTenantId
    )?.assignedTo?.name ?? data.from.name;

  return (
    <Stack
      onClick={() => {
        if (selectedMobileCase) selectedMobileCase();
        if (referralCaseId !== data.caseId)
          handleChangeSelectedCaseId(data.caseId);
      }}
      padding={2}
      spacing={0.5}
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        cursor: 'pointer',
        backgroundColor:
          referralCaseId === data.caseId
            ? (t) => t.palette.primary.lighter
            : 'inherit',
        '&:hover': {
          backgroundColor: (t) => t.palette.primary.lighter,
        },
      }}
    >
      <Typography
        gutterBottom={false}
        sx={{
          fontWeight: (t) => t.typography.fontWeightMedium,
          display: 'flex',
        }}
      >
        {memberName}:&nbsp;
        {data?.patient ? (
          <Box display="flex" gap={1}>
            <Typography
              component="span"
              sx={{
                fontWeight: (t) => t.typography.fontWeightMedium,
              }}
            >
              {getFullName(data.patient.firstName, data.patient.lastName)}
            </Typography>
            {data.patient?.isVip && <VipCrown />}
          </Box>
        ) : null}
      </Typography>
      <Box className="text-overflow-ellipses">
        <Typography component="span" variant="body2">
          <Tooltip title={joinedServiceName || ''}>
            <span>{joinedServiceName}</span>
          </Tooltip>
        </Typography>
      </Box>
      <Typography color="text.secondary" gutterBottom={false} variant="body2">
        Created by:&nbsp;
        <Typography
          className="glow-span"
          component="span"
          data-cy="case-caselist-createdBy"
          variant="body2"
        >
          {data.from?.assignedTo?.name
            ? data.from.assignedTo.name
            : data?.from?.name}
        </Typography>
      </Typography>
      <Typography color="text.secondary" gutterBottom={false} variant="body2">
        Assigned to:&nbsp;
        <Typography
          className="glow-span"
          component="span"
          data-cy="case-caselist-assignedTo"
          variant="body2"
        >
          {assignedTo}
        </Typography>
      </Typography>
      <Box
        sx={
          !mdBreakpoint && smBreakpoint && xsBreakpoint
            ? { display: 'block' }
            : {
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
              }
        }
      >
        <Typography
          color="text.secondary"
          gutterBottom={false}
          variant="caption"
        >
          Created Date:&nbsp;
          <Typography component="span" gutterBottom={false} variant="body2">
            {data.createdDate &&
              formatDateView(
                data.createdDate._d ? data.createdDate._d : data.createdDate
              )}
          </Typography>
        </Typography>

        <Chip
          className="chip-badge"
          data-cy="case-chip"
          label={data.status?.name}
          sx={{
            color: `${formattedStatus.color} !important`,
            borderColor: `${formattedStatus.color} !important`,
            backgroundColor: `${formattedStatus.backgroundColor} !important`,
          }}
        />
      </Box>
    </Stack>
  );
};
ReferralCaseListItem.defaultProps = {
  selectedMobileCase: undefined,
};

export default ReferralCaseListItem;
