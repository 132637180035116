import { useCallback, useEffect } from 'react';

import { TenantOfferingCategoryFull } from 'enums/settings/tenant-offerings';
import {
  selectManageMembershipSelectedMembership,
  setManageMembershipSelectedOfferings,
} from 'stores/client-management/manage-membership';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { checkIfDateisInPast, getCurrentDate } from 'utils/moment';

export const useSetValidateDateForMembership = () => {
  const dispatch = useAppDispatch();

  const { selectedOffering, details } = useAppSelector(
    selectManageMembershipSelectedMembership
  );

  const isGroupMemberOrDependent =
    !!details?.primaryId ||
    !!details?.groupId || // respective above two condition determines the group member or dependent while editing membership
    (selectedOffering?.category === TenantOfferingCategoryFull.GROUP &&
      !!selectedOffering?.clientEnrollmentId); // this is required while converting the individual or dependent to group members (Manage membership => Group)

  const handleValidDate = useCallback(() => {
    if (!selectedOffering?.startDate || !isGroupMemberOrDependent) {
      return;
    }

    const isMatchedMembershipOffering =
      selectedOffering?.tenantOfferingId === details?.offerings?.id;

    if (isMatchedMembershipOffering && details) {
      const { startDate } = details;

      dispatch(
        setManageMembershipSelectedOfferings({
          startDate,
        })
      );
    } else {
      const startDate = selectedOffering.startDate || getCurrentDate();

      if (checkIfDateisInPast(startDate)) {
        const currentDate = getCurrentDate();
        dispatch(
          setManageMembershipSelectedOfferings({
            startDate: currentDate,
          })
        );
      } else {
        dispatch(
          setManageMembershipSelectedOfferings({
            startDate,
          })
        );
      }
    }
  }, [
    details,
    dispatch,
    isGroupMemberOrDependent,
    selectedOffering?.startDate,
    selectedOffering?.tenantOfferingId,
  ]);

  /**
   * In case of group member or dependent, set start date based on primary's start date as follows
   * 1. If primary's start date is in present/future -> set the start date as the primary's start date
   * 2. If primary's start date is in past -> set the today's date as start date
   *
   * However, if the group member or dependent has selected a benefit start date of its own, set that date.
   */
  useEffect(() => {
    handleValidDate();
  }, [handleValidDate]);
};
