import { selectCloneConfig, selectCurrentReferralCase } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';

export const useCloneCasePreviewConfig = () => {
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const cloneConfig = useAppSelector(selectCloneConfig);

  const isClonedCasePrevNotePreview =
    !!currentReferralCase?.clonedFrom && cloneConfig.notes;
  const isClonedCasePrevFilePreview =
    !!currentReferralCase?.clonedFrom && cloneConfig.files;

  return {
    isClonedCasePrevNotePreview,
    clonedFrom: currentReferralCase?.clonedFrom,
    isClonedCasePrevFilePreview,
  };
};
