import { useSearchParams } from 'react-router-dom';

import { Box, Chip, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { VipCrown } from 'common/VipCrown';
import { dateTimeFormatConfig } from 'config';
import commonConstants from 'constants/common';
import { IReferralCaseList, IRequestedTo } from 'interfaces/cases';
import { selectAuthTenantAssociation } from 'stores/auth';
import { setLatestCallInPerson } from 'stores/cases';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { getFormattedStatus } from 'utils/cases';
import { getFullName } from 'utils/common';
import { UTCToLocalDateViewFormat } from 'utils/moment';

interface IReferralCaseListItemProps {
  data: IReferralCaseList;
  handleChangeSelectedCaseId: (caseId: string) => void;
}

const CaseListItem = ({
  data,
  handleChangeSelectedCaseId,
}: IReferralCaseListItemProps) => {
  const [queryParameters] = useSearchParams();
  const referralCaseId = queryParameters.get(
    commonConstants.CASE_ID_QUERY_PARAMETER
  );
  const theme = useTheme();
  const formattedStatus = getFormattedStatus(data.status?.code, theme);
  const services = data.service;
  const service = services?.length ? services : undefined;
  const dispatch = useAppDispatch();
  const authTenantId = useAppSelector(selectAuthTenantAssociation)?.tenantId;

  const joinedServiceName = service
    ?.map((s) => `${s?.name} ${s?.code ? `(${s?.code})` : ''}`)
    .join(', ');

  const to = Array.isArray(data?.to) ? data.to : [data.to];

  const assignedToTenant = ((to as any) ?? [])?.find(
    (tenant: IRequestedTo) => tenant?.id === authTenantId
  );

  const assignedTo =
    assignedToTenant?.assignedTo?.name ??
    assignedToTenant?.name ??
    data?.from?.name;

  return (
    <Stack
      onClick={() => {
        dispatch(setLatestCallInPerson(null));
        if (referralCaseId !== data.caseId)
          handleChangeSelectedCaseId(data.caseId);
      }}
      padding={2}
      spacing={0.5}
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        cursor: 'pointer',
        backgroundColor:
          referralCaseId === data.caseId
            ? (t) => t.palette.primary.lighter
            : 'inherit',
        '&:hover': {
          backgroundColor: (t) => t.palette.primary.lighter,
        },
      }}
    >
      <Box display="flex" flexDirection="column">
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Typography
            className="text-overflow-ellipses"
            gutterBottom={false}
            maxWidth={150}
            sx={{
              fontWeight: (t) => t.typography.fontWeightMedium,
            }}
          >
            <Box alignItems="flex-end" display="flex" gap={1}>
              <Typography
                component="span"
                gutterBottom={false}
                sx={{
                  fontWeight: (t) => t.typography.fontWeightMedium,
                }}
                variant="body2"
              >
                {getFullName(data?.patient?.firstName, data?.patient?.lastName)}
              </Typography>
              {data?.patient?.isVip && <VipCrown />}
            </Box>
          </Typography>
          <Typography component="span" gutterBottom={false} variant="caption">
            {data.createdDate &&
              UTCToLocalDateViewFormat(
                data.createdDate._d ? data.createdDate._d : data.createdDate,
                dateTimeFormatConfig.fullDateViewFormat
              )}
          </Typography>
        </Box>
        {joinedServiceName && (
          <Box className="text-overflow-ellipses" mb={0.5}>
            <Typography component="span" variant="body2">
              <Tooltip title={joinedServiceName || ''}>
                <span>{joinedServiceName}</span>
              </Tooltip>
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        alignItems="flex-end"
        display="flex"
        gap={1}
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={0.5}
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {data?.caseType && data?.caseType?.length > 0 && (
            <Typography
              className="text-overflow-ellipses"
              color="text.secondary"
              gutterBottom={false}
              variant="caption"
            >
              Case type:&nbsp;
              <Typography
                className="glow-span"
                component="span"
                data-cy="case-caselist-assignedTo"
                gutterBottom={false}
                variant="caption"
              >
                {data.caseType}
              </Typography>
            </Typography>
          )}
          <Typography
            className="text-overflow-ellipses"
            color="text.secondary"
            gutterBottom={false}
            variant="caption"
          >
            Created by:&nbsp;
            <Typography
              className="glow-span"
              component="span"
              data-cy="case-caselist-createdBy"
              variant="caption"
            >
              {data.from?.assignedTo?.name
                ? data.from.assignedTo.name
                : data?.from?.name}
            </Typography>
          </Typography>
          <Typography
            className="text-overflow-ellipses"
            color="text.secondary"
            gutterBottom={false}
            variant="caption"
          >
            Assigned to:&nbsp;
            <Typography
              className="glow-span"
              component="span"
              data-cy="case-caselist-assignedTo"
              variant="caption"
            >
              {assignedTo}
            </Typography>
          </Typography>
        </Box>
        <Chip
          className="chip-badge"
          data-cy="case-chip"
          label={data.status?.name}
          sx={{
            color: `${formattedStatus.color} !important`,
            borderColor: `${formattedStatus.color} !important`,
            backgroundColor: `${formattedStatus.backgroundColor} !important`,
          }}
        />
      </Box>
    </Stack>
  );
};

export default CaseListItem;
