import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  faCheckCircle,
  faEdit,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { EllipseMenu, EllipseMenuItem } from 'common';
import { SelectionOptions } from 'enums/case';
import ModalKey from 'enums/ModalKey';
import { IReferralCaseProviderOption } from 'interfaces/cases';
import {
  useDeleteReferringProviderMutation,
  useSelectedProviderMutation,
} from 'services/cases';
import { selectCurrentReferralCase } from 'stores/cases';
import { useConfirmationModal } from 'stores/ConfirmationModal';
import { useAppSelector } from 'stores/hooks';

const ProviderOptionMenu = ({
  data,
}: {
  data: IReferralCaseProviderOption;
}) => {
  const navigate = useNavigate();
  const confirmationModal = useConfirmationModal();
  const deleteReferringProviderMutation = useDeleteReferringProviderMutation();
  const selectedProviderMutation = useSelectedProviderMutation();
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);

  const onDeleteClick = async () => {
    const result = await confirmationModal?.openConfirmationModal({
      title: `Delete Provider`,
      isAdditionalContentRequired: false,
      content: (
        <Typography component="span">
          Are you sure you want to delete this provider {data.name}? This
          process is irreversible. If you are not sure, you can cancel.
        </Typography>
      ),
    });

    if (result && currentReferralCase?.caseId) {
      confirmationModal?.changeSubmittingStatus(true);

      deleteReferringProviderMutation.mutate(
        { providerId: data.id || '', caseId: currentReferralCase.caseId! },
        {
          onSettled: () => {
            confirmationModal?.changeSubmittingStatus(false);
          },
        }
      );
    }
  };
  const onSelectClick = async (isSelected: boolean) => {
    const result = await confirmationModal?.openConfirmationModal({
      title: `Select Provider`,
      isAdditionalContentRequired: false,
      content: (
        <Typography component="span">
          Are you sure you want to select this provider {data.name}? If you are
          not sure, you can cancel.
        </Typography>
      ),
    });

    if (result && currentReferralCase?.caseId && data.id) {
      confirmationModal?.changeSubmittingStatus(true);

      const params = { isSelected };
      selectedProviderMutation.mutate(
        {
          providerId: data.id,
          caseId: currentReferralCase.caseId!,
          params,
          type: SelectionOptions.SELECTED,
        },
        {
          onSettled: () => {
            confirmationModal?.changeSubmittingStatus(false);
          },
        }
      );
    }
  };
  const onEditClick = () => {
    navigate(
      `modal?type=${ModalKey.EDIT_REFERRING_FACILITY}&caseId=${currentReferralCase?.caseId}&providerId=${data.id}`
    );
  };
  return (
    <EllipseMenu>
      <EllipseMenuItem
        iconElement={<FontAwesomeIcon icon={faEdit} size="xs" />}
        onClick={onEditClick}
        text="Edit"
      />
      {!data.isSelected && (
        <EllipseMenuItem
          iconElement={<FontAwesomeIcon icon={faCheckCircle} size="xs" />}
          onClick={() => onSelectClick(true)}
          text="Select"
        />
      )}
      <EllipseMenuItem
        iconElement={<FontAwesomeIcon icon={faTrashAlt} size="xs" />}
        onClick={onDeleteClick}
        text="Delete"
      />
    </EllipseMenu>
  );
};

export default ProviderOptionMenu;
