import { useSearchParams } from 'react-router-dom';

import ModalKey from 'enums/ModalKey';
import { CaseCode, TenantSettingStatus } from 'enums/tenant-management/tenant';
import { useGetCaseTenantSettings } from 'hooks/useGetCaseTenantSettings';

export const useServiceMode = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const isEditMode = type === ModalKey.EDIT_CASE_SERVICE;

  const isProviderServiceEnabled =
    useGetCaseTenantSettings(CaseCode.CASE_PROVIDER_PROCEDURE) ===
    TenantSettingStatus.ENABLED;
  return {
    isEditMode,
    isProviderServiceEnabled,
  };
};
