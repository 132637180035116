import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';

export const UnauthorizedResourceAccessDisplay = () => (
  <Box display="flex" flexDirection="column" justifyContent="center" p={4}>
    <FontAwesomeIcon color="red" icon={faCircleXmark} size="2x" />
    <Box alignItems="center" display="flex" flexDirection="column" mt={3}>
      <Typography fontWeight="bold" variant="h6">
        Unauthorized Access
      </Typography>
      <Typography>You do not have access to view this information.</Typography>
    </Box>
  </Box>
);
