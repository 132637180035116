import { useEffect, useState } from 'react';

import {
  faEdit,
  faEye,
  faEyeSlash,
  faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import PermissionGate from 'common/PermissionGate';
import config from 'config';
import commonRegex from 'constants/regex';
import { NoteVisibility } from 'enums/case';
import { useCheckCaseReadOnlyView } from 'features/case-layout-new/hooks/useCheckCaseReadOnlyView';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { useCheckUserIsTenantAdmin } from 'hooks/useCheckUserIsTenantAdmin';
import { IReferralNote } from 'interfaces/cases';
import {
  useChangeVisibilityNoteMutation,
  useDeleteNoteMutation,
  useNoteHistoryQuery,
} from 'services/cases';
import { selectAuthTenantData } from 'stores/auth';
import { selectCurrentReferralCase } from 'stores/cases';
import { useConfirmationModal } from 'stores/ConfirmationModal';
import { useAppSelector } from 'stores/hooks';
import { truncateText } from 'utils/common';
import { formatDateView, UTCToLocalDateViewFormat } from 'utils/moment';
import { parseHtml } from 'utils/parseHtml';

import NotesHistoryPopover from './NotesHistoryPopover';

interface INoteListPros {
  data: IReferralNote;
  handleEditMode: (data: IReferralNote) => void;
  isActive: boolean;
}

const NoteList = ({ data, handleEditMode, isActive }: INoteListPros) => {
  const appTheme = useTheme();
  const primaryColor = appTheme.palette.primary.main;
  const fontWeight = appTheme.typography.fontWeightRegular;

  const [minLength] = useState(100);
  const [maxLength, setMaxLength] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [show, setShow] = useState(false);
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const loggedInUser = useAppSelector(selectAuthTenantData);
  const caseId = currentReferralCase?.caseId;
  const deleteNoteMutation = useDeleteNoteMutation();
  const changeVisibilityNoteMutation = useChangeVisibilityNoteMutation();
  const deleteConfirmationModal = useConfirmationModal();
  const isUserClient = useCheckUserIsClient();
  const isUserTenantAdmin = useCheckUserIsTenantAdmin();
  const { ownerTenant } = useCheckCaseReadOnlyView({
    includePatientCheck: true,
  });

  const isNoteDeleted = data?.isDeleted === 1;
  const mentionRegex = commonRegex.MENTION;
  const isCaseOwnerAdmin = ownerTenant && isUserTenantAdmin;

  const getHtmlMessage = (message: string) =>
    message?.replace(mentionRegex, '<span class="text-link">$1</span>');

  const handleDeleteNote = async () => {
    if (caseId) {
      const result = await deleteConfirmationModal?.openConfirmationModal({
        title: `Delete Note`,
        content: `Do you want to delete this note?`,
      });
      if (result) {
        deleteConfirmationModal?.changeSubmittingStatus(true);
        deleteNoteMutation.mutate(
          { caseId, noteId: data.id },
          {
            onSettled: () => {
              deleteConfirmationModal?.changeSubmittingStatus(false);
            },
          }
        );
      }
    }
  };

  const handleVisibilityNote = (visibility: boolean) => {
    const visibilityData = {
      id: data.id,
      accessType: visibility ? NoteVisibility.PUBLIC : NoteVisibility.HIDDEN,
      note: data.note,
    };
    if (caseId)
      changeVisibilityNoteMutation.mutate({
        caseId,
        noteId: data.id,
        data: visibilityData,
      });
  };

  const handleEdit = () => {
    handleEditMode(data);
  };

  useEffect(() => {
    if (data.accessType) {
      setShow(data.accessType === NoteVisibility.PUBLIC);
    }
  }, [data.accessType]);

  useEffect(() => {
    if (data.note) setMaxLength(data.note.length);
  }, [data.note]);

  const deletedNote = `Note deleted by ${data?.updatedBy?.name} on ${
    data?.updatedBy?.date
      ? UTCToLocalDateViewFormat(
          data?.updatedBy?.date,
          config.fullDateViewFormat
        )
      : ''
  }`;

  const noteId = data?.id;
  const getNoteHistoryQuery = useNoteHistoryQuery(caseId || '', noteId, {
    enabled: !!caseId && !!noteId,
  });

  const notesArray = getNoteHistoryQuery?.data;
  const lengthNotesArray = notesArray?.length || 0;

  const isNoteCreatedByClient =
    loggedInUser?.userId === data?.createdBy?.id ||
    loggedInUser?.tenantAssociation.referenceId === data?.createdBy?.id;

  const showNoteHistory = () => {
    if (isUserClient && !isNoteCreatedByClient) return false;
    if (isUserClient && isNoteCreatedByClient && lengthNotesArray) return true;
    if (!isNoteDeleted && data.isEdited) return true;
    if (!lengthNotesArray) return false;
    return false;
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        backgroundColor: (theme) =>
          isActive ? theme.palette.gray.lighter : 'inherit',
        paddingLeft: 1,
        paddingRight: 1,
        paddingY: 0.5,
      }}
    >
      <Avatar />
      <Stack className="hover-stack" paddingTop={0.8} width="calc(100% - 40px)">
        <Box className="hover-stack-box">
          <Typography
            gutterBottom={false}
            sx={{
              fontWeight: (theme) => theme.typography.fontWeightMedium,
              color: (theme) =>
                isNoteDeleted
                  ? theme.palette.text.disabled
                  : theme.palette.common.black,
            }}
            variant="body2"
          >
            {data?.createdBy?.name}
          </Typography>
          <Box
            className={
              !isNoteDeleted && isNoteCreatedByClient ? 'hover-display-box' : ''
            }
            sx={{ display: 'none', paddingLeft: 1, gap: 0.5 }}
          >
            <PermissionGate>
              <IconButton
                data-cy="case-note-edit"
                onClick={handleEdit}
                size="small"
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  size="xs"
                  style={{
                    color: primaryColor,
                    fontWeight,
                  }}
                />
              </IconButton>
            </PermissionGate>
            <PermissionGate>
              <IconButton
                data-cy="case-note-delete"
                onClick={handleDeleteNote}
                size="small"
              >
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  size="xs"
                  style={{
                    color: primaryColor,
                    fontWeight,
                  }}
                />
              </IconButton>
            </PermissionGate>
            {isCaseOwnerAdmin && (
              <PermissionGate>
                <Tooltip
                  title={show ? 'Set note as private' : 'Set note as public'}
                >
                  <IconButton
                    data-cy="case-note-show"
                    onClick={() => {
                      setShow(!show);
                      handleVisibilityNote(!show);
                    }}
                    size="small"
                  >
                    <FontAwesomeIcon
                      icon={show ? faEye : faEyeSlash}
                      size="xs"
                      style={{
                        color: primaryColor,
                        fontWeight,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </PermissionGate>
            )}
          </Box>
        </Box>
        <Box alignItems="center" display="flex" gap={1}>
          <Typography
            gutterBottom={false}
            sx={{
              color: (theme) =>
                isNoteDeleted
                  ? theme.palette.text.disabled
                  : theme.palette.text.primary,
            }}
            variant="body2"
          >
            {data?.createdBy?.date &&
              formatDateView(
                data.createdBy.date,
                config.fullDateViewFormat
              )}{' '}
          </Typography>
          {showNoteHistory() && (
            <NotesHistoryPopover caseId={caseId || ''} noteId={data?.id} />
          )}
        </Box>
        <Typography
          component="span"
          dangerouslySetInnerHTML={{
            __html: parseHtml(
              truncateText(
                getHtmlMessage(isNoteDeleted ? deletedNote : data?.note),
                showMore ? maxLength + 33 : minLength,
                ' ...'
              )
            ),
          }}
          data-cy="note-details"
          gutterBottom={false}
          sx={{
            color: (theme) =>
              isNoteDeleted
                ? theme.palette.error.main
                : theme.palette.common.black,
            paddingLeft: 0.2,
            overflowWrap: 'break-word',
          }}
          variant="body2"
        />
        {/* 33 is being added to compensate for the added html tag on
        getHtmlMessage */}
        {!(maxLength < 97) && maxLength + 33 > minLength && (
          <Box display="inline">
            {!showMore ? (
              <IconButton
                aria-label="delete"
                className="badge-button"
                data-cy="case-note-details-show-more"
                onClick={() => setShowMore(true)}
                size="small"
              >
                More
              </IconButton>
            ) : (
              <IconButton
                aria-label="delete"
                className="badge-button"
                data-cy="case-note-details-show-less"
                onClick={() => setShowMore(false)}
                size="small"
              >
                Less
              </IconButton>
            )}
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default NoteList;
