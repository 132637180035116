import React, { ReactNode } from 'react';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IReferralCaseFileNoteLayoutProps {
  children: ReactNode;
  drawerView?: boolean;
}

const getFixedWidth = (xlBreakpoint: boolean, largeBreakpoint: boolean) => {
  if (xlBreakpoint) return '550px';
  if (largeBreakpoint) return '368px';
  return '300px';
};

const ReferralCaseFileNoteLayout = ({
  children,
  drawerView,
}: IReferralCaseFileNoteLayoutProps) => {
  const theme = useTheme();
  const xlBreakpoint = useMediaQuery(theme.breakpoints.up('xl'));
  const largeBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));

  const fixedWidth = getFixedWidth(xlBreakpoint, largeBreakpoint);

  return (
    <Box flexShrink={0} position="relative" width={fixedWidth}>
      <Box
        sx={{
          position: 'fixed',
          top: drawerView ? 0 : '65px',
          minWidth: fixedWidth,
          maxWidth: fixedWidth,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

ReferralCaseFileNoteLayout.defaultProps = {
  drawerView: false,
};
export default ReferralCaseFileNoteLayout;
