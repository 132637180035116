import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Box, FormControl, FormHelperText, Typography } from '@mui/material';
import { RadioBoxButton } from 'common/ui/RadioBoxButton';
import { DEFAULT_GENDER } from 'constants/common';
import { useCheckFormFieldSettings } from 'hooks/useCheckEnabledFields';
import { GenderType } from 'interfaces/common';
import { getInputLabel } from 'utils/misc';

interface IGenderRadioButtonProps {
  type: string;
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
  dataCy?: string;
}

const GenderRadioButton = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (props: IGenderRadioButtonProps, ref) => {
    const { type, value, onChange, isDisabled = false, dataCy } = props;

    return (
      <RadioBoxButton
        data-cy={dataCy}
        isActive={value === type}
        isDisabled={isDisabled}
        label={type}
        onClick={() => onChange(value === type ? ('' as GenderType) : type)}
        showCheckIcon={false}
      />
    );
  }
);

GenderRadioButton.defaultProps = {
  isDisabled: false,
  dataCy: '',
};

interface IProps {
  isRequired?: boolean;
  isDisabled?: boolean;
  showOthers?: boolean;
  labelProp?: string;
  isConfigurableThroughSetting?:boolean;
}

const FormGenderInput = ({
  isRequired = false,
  isDisabled = false,
  labelProp,
  isConfigurableThroughSetting = false,
}: IProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const genderSetting = useCheckFormFieldSettings();

  const label = genderSetting?.value?.genderLabel || 'Gender';
  const genderOptions = genderSetting?.value?.genderOption || DEFAULT_GENDER;

  const checkRequiredField = useCheckFormFieldSettings().required;
  /**
   * Required rule from the tenant setting is given priority
   */
  const isFieldRequired = isConfigurableThroughSetting
    ? checkRequiredField?.gender
    : isRequired;

  return (
    <Box>
      <Typography
        color={errors.gender ? 'error' : undefined}
        gutterBottom={false}
        sx={{ display: 'block', marginTop: '-4px', mb: 0.5 }}
        variant="caption"
      >
        {getInputLabel(label, isFieldRequired) || labelProp}
      </Typography>

      <FormControl sx={{ width: '100%' }} variant="outlined">
        <Controller
          control={control}
          name="gender"
          render={({ field }) => (
            <Box display="flex" gap={1}>
              {genderOptions.map((gender) => (
                <GenderRadioButton
                  dataCy={`gender-${gender.toLowerCase()}`}
                  isDisabled={isDisabled}
                  key={gender}
                  type={gender}
                  {...field}
                />
              ))}
            </Box>
          )}
        />
        {!!errors.gender && (
          <FormHelperText error>
            {errors.gender.message?.toString()}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

FormGenderInput.defaultProps = {
  isRequired: false,
  isDisabled: false,
  showOthers: false,
  labelProp: '',
  isConfigurableThroughSetting:false,
};

export default FormGenderInput;
