import { Typography } from '@mui/material';
import { IAddressOptional } from 'interfaces/common';

interface IProps {
  address?: IAddressOptional;
  showInSingleLine?: boolean;
}

export const AddressDisplay = ({ address, showInSingleLine }: IProps) => {
  if (!address) {
    return <>N/A</>;
  }

  const { addressLine1, addressLine2, city, state, zip } = address;

  if (!addressLine1 && !city && !state && !zip) {
    return <>N/A</>;
  }

  if (showInSingleLine) {
    return (
      <>
        {addressLine1 ? `${addressLine1}, ` : ''}
        {addressLine2 ? `${addressLine2}, ` : ''}
        {city ? `${city}, ` : ''}
        {state ? `${state} ` : ''}
        {zip}`;
      </>
    );
  }

  return (
    <Typography component="span" gutterBottom={false} variant="body2">
      {address.addressLine1 && (
        <Typography component="span" gutterBottom={false} variant="body2">
          {address.addressLine1}
          {address.city && <br />}
        </Typography>
      )}

      {address.city}
      {(address.state || address.zip) && (
        <Typography component="span" gutterBottom={false} variant="body2">
          ,{address.city && ' '}
          {!address.city && <br />}
        </Typography>
      )}
      <Typography component="span" gutterBottom={false} variant="body2">
        {address.state}
        {address.state && ' '}
        {address.zip}
      </Typography>
    </Typography>
  );
};

AddressDisplay.defaultProps = {
  address: null,
  showInSingleLine: false,
};

export default AddressDisplay;
