import { Theme } from '@mui/material/styles';
import themeVars from 'assets/scss/_theme-vars.module.scss';
import { customScrollbar } from 'theme/GlobalStyles';

const Dialog = (theme: Theme) => ({
  MuiDialog: {
    styleOverrides: {
      root: {},
      paperWidthSm: {
        maxWidth: themeVars.modalMaxWidthSm,
      },
      paperWidthMd: {
        maxWidth: themeVars.modalMaxWidthMd,
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        '&.MuiDialogTitle-root': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          ...theme.typography.h6,
          marginBottom: 0,

          '& .MuiIconButton-root': {
            color: theme.palette.common.white,
          },
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: theme.spacing(2),
        maxHeight: '60vh',
        overflowY: 'auto',
        // -----------------------------------------------------
        // Custom scrollbar
        ...customScrollbar(theme),
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.gray.lighter,

        '&.flex-space-between': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        },

        '&.flex-end': {
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        },
      },
    },
  },
});

export default Dialog;
