import {
  ManageMembershipActionStep,
  ManageMembershipStatus,
} from 'enums/client-management/manage-membership';
import {
  resetManageMembershipSelectedOfferings,
  setManageMembershipActionStep,
  setManageMembershipActionType,
  setManageMembershipSelectedOfferings,
  setManageMembershipStatus,
} from 'stores/client-management/manage-membership';
import { useAppDispatch } from 'stores/hooks';

export const useHandleResetDispatch = () => {
  const dispatch = useAppDispatch();

  const handleFinalSuccessStep = () => {
    dispatch(resetManageMembershipSelectedOfferings());
    dispatch(setManageMembershipStatus(ManageMembershipStatus.PUBLIC));
    dispatch(setManageMembershipActionStep(ManageMembershipActionStep.FINAL));
  };
  const handleInitialToMainView = () => {
    dispatch(setManageMembershipActionType(null));
    dispatch(setManageMembershipStatus(ManageMembershipStatus.PUBLIC));
    dispatch(resetManageMembershipSelectedOfferings());
  };
  const handleSwitchingMembershipType = () => {
    dispatch(setManageMembershipStatus(ManageMembershipStatus.PUBLIC));
    dispatch(setManageMembershipSelectedOfferings({ selectedOffering: null }));
  };

  return {
    handleFinalSuccessStep,
    handleInitialToMainView,
    handleSwitchingMembershipType,
  };
};
