import { useEffect, useState } from 'react';

import {
  faEdit,
  faSave,
  faTimes,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { IFileSchema } from 'interfaces/documents';
import { selectFileBeingRenamed, setFileBeingRenamed } from 'stores/cases';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import {
  getFileNameWithExtension,
  getFilenameWithoutExtension,
} from 'utils/file';

const DropZoneFileList = ({
  file,
  handleFileDelete,
  handleFileEdit,
}: {
  file: IFileSchema;
  handleFileDelete: (id: string) => void;
  handleFileEdit: (file: any) => void;
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [name, setName] = useState('');
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const isFilesBeingRenamed = useAppSelector(selectFileBeingRenamed);

  const borderColor =
    file.isErrorType || file.isErrorSize
      ? theme.palette.error.main
      : theme.palette.gray.light;
  const handleSave = () => {
    const modifiedName = getFileNameWithExtension(file.name, name);
    const info = {
      ...file,
      name: modifiedName,
    };
    setIsEditMode(false);
    handleFileEdit(info);
    dispatch(setFileBeingRenamed(false));
  };
  const handleEdit = () => {
    setIsEditMode(true);
    dispatch(setFileBeingRenamed(true));
  };

  const handleCancel = () => {
    setIsEditMode(false);
    dispatch(setFileBeingRenamed(false));
  };

  useEffect(() => {
    if (isEditMode) {
      const nameWithoutExtension = getFilenameWithoutExtension(file.name);
      setName(nameWithoutExtension);
    }
  }, [file.name, isEditMode]);
  return (
    <Box minHeight={50}>
      <Box
        component="li"
        display="flex"
        justifyContent="space-between"
        key={file.id}
      >
        <Box
          sx={{
            ...(!isEditMode && {
              borderBottom: `1px solid ${borderColor}`,
            }),
            textAlign: 'left',
          }}
          width="95%"
        >
          {isEditMode ? (
            <TextField
              autoFocus
              fullWidth
              name="name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              variant="standard"
            />
          ) : (
            <Typography mb={0} variant="body2">
              {file.name}
            </Typography>
          )}
        </Box>
        <Box textAlign="center" width="12%">
          {isEditMode ? (
            <>
              {name && (
                <IconButton onClick={handleSave} sx={{ padding: 1 }}>
                  <FontAwesomeIcon icon={faSave} size="xs" />
                </IconButton>
              )}
              <IconButton onClick={handleCancel} sx={{ padding: 1 }}>
                <FontAwesomeIcon icon={faTimes} size="xs" />
              </IconButton>
            </>
          ) : (
            <>
              <Tooltip placement="bottom" title="Edit Filename">
                <IconButton
                  disabled={!!isFilesBeingRenamed}
                  onClick={handleEdit}
                  sx={{ padding: 1 }}
                >
                  <FontAwesomeIcon icon={faEdit} size="xs" />
                </IconButton>
              </Tooltip>
              <Tooltip placement="bottom" title="Delete File">
                <IconButton
                  disabled={!!isFilesBeingRenamed}
                  onClick={() => handleFileDelete(file.id)}
                  sx={{ padding: 1 }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} size="xs" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      </Box>
      {file.isErrorType && (
        <Typography color="error" variant="caption">
          File type not supported
        </Typography>
      )}
      {file.isErrorSize && !file.isErrorType && (
        <Typography color="error" variant="caption">
          File size is greater than 25 MB
        </Typography>
      )}
    </Box>
  );
};

export default DropZoneFileList;
