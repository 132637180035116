import { useLocation } from 'react-router-dom';

import { weekNames } from 'constants/calender';
import { DECIMAL_DIGITS } from 'constants/common';
import regex from 'constants/regex';
import { AccessType } from 'enums/auth';
import { PatientContactType } from 'enums/case';
import { SiteDomain } from 'enums/common';
import { CountryCodes } from 'enums/country';
import { IAdaptedTenantData } from 'interfaces/auth';
import { IClientAgreementOption } from 'interfaces/client-agreement';
import {
  IAddress,
  IAddressOptional,
  IClientContact,
  IClientSplittedContact,
  IPermission,
  IPermissionChangerHandlerMeta,
  ISavePermissionSchema,
} from 'interfaces/common';
import moment from 'moment';

import { toBase64 } from './file';
import { convertHtmlToPdfJs, getFileSize } from './html2Pdf';
import { cloneDeep } from './lodash';

export const capitalizeFirstLetter = (s: string) => {
  if (!s) return '';
  const text = s.toLowerCase();
  return text.charAt(0).toUpperCase() + text.slice(1);
};
export const checkRoutePathPresent = (
  menus: IPermission[],
  routePath: string
): boolean =>
  /**
   * @example
   * routePath('/virtual-visit')
   *  (menu:[{routePath:'/virtual-visit'}])=> returns true
   */
  menus.some((menu) => {
    if (menu.routePath === routePath) {
      return true;
    }

    if (menu.children && menu.children.length > 0) {
      return checkRoutePathPresent(menu.children, routePath);
    }

    return false;
  });
export const capitalizeText = (s: string) => {
  // Capitalize letter if multiple words are present
  if (typeof s !== 'string') {
    return '-';
  }

  if (!s) return '';
  const text = s.toLowerCase();
  const words = text.split(' ');

  if (words.length === 2) {
    const capitalizedText = `${capitalizeFirstLetter(
      words[0]
    )} ${capitalizeFirstLetter(words[1])}`;
    return capitalizedText;
  }

  return capitalizeFirstLetter(s);
};

export const getFirstLetterOfFirstAndLastName = (name: string) => {
  if (!name) return '';
  const nameArray = name.split(' ');
  return `${nameArray[0]?.[0]}${nameArray[1]?.[0] || ''}`;
};
export const convertToPlainString = (
  value: string,
  reverse = false
): string => {
  /**
   * @example
   *  (value:IN_PROGRESS)=> returns IN PROGRESS
   */
  if (!value) return '';

  const prefixRegex = /^[^a-zA-Z0-9]+/;
  let modifiedString = value.replace(prefixRegex, '').replace(/_/g, ' ');

  if (reverse) {
    modifiedString = modifiedString.replace(/ /g, '_');
  } else {
    modifiedString = modifiedString.replace(/_|-/g, ' ');
  }

  return modifiedString;
};
export const formatCurrency = (
  value: string | number,
  elseNull = true,
  min = 2,
  max = 2
): string => {
  if (typeof value === 'string' && Number.isNaN(Number(value))) {
    return '-';
  }

  if (value || value === 0) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: min,
      maximumFractionDigits: max,
    }).format(value as number);
  }

  if (!elseNull) {
    return formatCurrency(0);
  }

  return '-';
};

export const unformatCurrency = (value: string | number) => {
  if (typeof value === 'number') return value;

  if (!value) return 0;

  return Number(value.replace(/[^0-9.-]+/g, ''));
};

/**
 * Takes in a number and returns a string with the number formatted with the
 * given number of minimum and maximum fraction digits.
 * @param {number} value - the number to format
 * @param {boolean} [elseNull=true] - if true, returns 0 if value is null or undefined
 * @param {number} [minimumFractionDigits=2] - the minimum number of fraction digits
 * @param {number} [maximumFractionDigits=2] - the maximum number of fraction digits
 * @returns {string | null} - the formatted number, or null if value is null or undefined
 */
export const formatNumber = (
  value: number,
  elseNull = true,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
): string | null => {
  if (value || value === 0) {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits,
      maximumFractionDigits,
    }).format(value);
  }
  if (elseNull) {
    return formatNumber(0);
  }
  return null;
};

export const tinymceToolbars =
  'undo redo | formatpainter casechange blocks | bold italic | ' +
  'alignleft aligncenter alignright alignjustify | ' +
  'bullist numlist checklist outdent indent |' +
  'forecolor';

export const tinymcePluginsList = [
  'advlist',
  'lists',
  'link',
  'image',
  'textcolor',
  'colorpicker',
  'wordcount',
];

export const tinymceFontSizeFormats =
  '8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 19pt 20pt 24pt 30pt 36pt';

export const convertToProperCase = (camelText: any) => {
  if (camelText.toLowerCase() === 'displaylocation') {
    return 'Location';
  }
  if (camelText === 'lastSsn') {
    return 'LastSSN';
  }
  if (camelText === 'regularHours') {
    return 'Regular Hours (mins)';
  }
  if (camelText === 'afterHours') {
    return 'After Hours (mins)';
  }
  if (camelText === 'totalAfterHours') {
    return 'Total After Hours (mins)';
  }
  if (camelText === 'totalPurchasedTime') {
    return 'Total Purchase Time (mins)';
  }
  if (camelText === 'totalRegularHours') {
    return 'Total Regular Hours Used (mins)';
  }
  if (camelText === 'totalRemainingTime') {
    return 'Total Regular Hours Remaining (mins)';
  }
  if (camelText === 'testDate') {
    return 'Test Date';
  }
  if (camelText === 'lastFourSSN') {
    return 'Last Four SSN';
  }
  const seperated = camelText.replace(/([A-Z])/g, ' $1');
  const titleCased = seperated.charAt(0).toUpperCase() + seperated.slice(1);
  return titleCased;
};

export const formatPhone = (unformattedPhone: string) => {
  let phone = unformattedPhone;
  if (phone) {
    const x = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    if (!x) return '-';
    // eslint-disable-next-line prefer-template
    phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    return phone || '';
  }
  return 'N/A';
};

export const unformatPhone = (formattedPhone: string) => {
  if (!formattedPhone) {
    return formattedPhone;
  }

  return formattedPhone.replace(/[^\d]/g, '');
};

// Created this function to not showing phone extension when phone does not exists
export const adaptedPhoneExtension = (
  phone: string,
  phoneExtension: string
) => {
  if (phone) {
    return phoneExtension;
  }
  return '';
};

/**
 * Check if every values of an object is empty or null
 *
 * @param {any} obj
 * @returns {boolean}
 */
export const checkIfObjHasAllEmptyOrNullValues = (obj: any) =>
  Object.values(obj).every((x) => x === null || x === '');

/**
 * Attach address object. Use this utility for attaching address object.
 *
 * @example
 * Input ->  {firstName: 'John', addressLine1: '123 Street'}
 * Output -> {address: {addressLine1: '123 Street', addressLine2: '', city: '', state: '', zip: ''}}
 *
 * @param {IAddressOptional} data
 * @returns {IAddress | undefined}
 */
export const attachAddressObject = ({
  addressLine1 = '',
  addressLine2 = '',
  city = '',
  state = '',
  zip = '',
  lat = '',
  lng = '',
}: IAddressOptional): IAddress | undefined => {
  if (addressLine1 || addressLine2 || city || state || zip || lat || lng) {
    return {
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
      lat,
      lng,
    };
  }
  return undefined;
};

export const sliceText = (text: string, length: number): string =>
  text?.length > length ? `${text.slice(0, length)}...` : text;

/**
 * Get full name
 *
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} middleName
 */
export const getFullName = (
  firstName?: string,
  lastName?: string,
  middleName?: string
): string => {
  const fName = firstName || '';
  const mName = middleName || '';
  const lName = lastName || '';

  const fullName = `${fName}${mName ? ` ${mName} ` : ' '}${lName}`;

  return fullName.trim();
};

/**
 * Get total number of pages for pagination
 *
 * @param {number} totalCount
 * @param {number} pageLimit
 * @returns {number} :: total number of pages
 */
export const getTotalPagesCount = (totalCount: number, pageLimit: number) =>
  Math.ceil(totalCount / pageLimit);

export const getAgeRange = (minAge: number, maxAge: number) => {
  if (minAge && maxAge) return `${minAge} to ${maxAge}`;
  if (!minAge && maxAge) return `0 to ${maxAge}`;
  if (minAge && !maxAge) return `${minAge} and up`;
  return 'N/A';
};
export const getQuantityRange = (minQty: number, maxQty: number) => {
  if (minQty && maxQty) return `${minQty} to ${maxQty}`;
  if (!minQty && maxQty) return `0 to ${maxQty}`;
  if (minQty && !maxQty) return `${minQty} and up`;
  return '0';
};

export const convertStringToNumber = (keys: string[], payload: any) =>
  keys.reduce((accumulator: any, currentValue: any) => {
    accumulator[currentValue] = +payload[currentValue];
    return accumulator;
  }, {});

export const formatPrice = (price: string) =>
  price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/**
 * Unformat date to digit only string
 * Useful for setting default value in form
 *
 * Input: 01/01/1995
 * Output: 01011995
 *
 * @param date
 * @returns
 */
export const unformatDate = (date: string) => {
  if (!date) return '';
  return date.replaceAll('/', '');
};

/**
 * Format date
 * Date in payload should be transformed in 'MM/DD/YYYY' format
 *
 * Input: 01011995
 * Output: 01/01/1995
 *
 * @param {string} date
 * @returns
 */
export const formatDate = (date: string) => {
  if (!date) return '';
  return `${date.substring(0, 2)}/${date.substring(2, 4)}/${date.substring(
    4,
    date.length
  )}`;
};

export const capitalizeFirstLetterOfEachWord = (string: string) => {
  if (!string) return string;
  return string.toLowerCase().split(' ').map(capitalizeFirstLetter).join(' ');
};

export const permissionChangeHandler = (
  {
    existingPermissions,
    newValue,
  }: {
    existingPermissions: IPermission[];
    newValue: boolean;
  },
  {
    resourceId,
    isChildren,
    hasChildren,
    parentId,
  }: IPermissionChangerHandlerMeta,
  callbackFunction: (newPermissions: IPermission[]) => void
) => {
  // Case 1: Update a child permission
  if (isChildren && parentId) {
    const tempPermissions: IPermission[] = cloneDeep(existingPermissions);

    const parentPermissionIndex = tempPermissions.findIndex(
      (item) => item.resourceId === parentId
    );

    const permission = tempPermissions[parentPermissionIndex];

    tempPermissions[parentPermissionIndex] = {
      ...tempPermissions[parentPermissionIndex],
      checked: permission.checked || newValue,
      accessType: AccessType.WRITE,
      children: tempPermissions[parentPermissionIndex]?.children?.map(
        (item) => {
          if (item.resourceId !== resourceId) return item;
          return {
            ...item,
            checked: newValue,
            accessType: newValue ? AccessType.WRITE : undefined,
          };
        }
      ),
    };

    callbackFunction(tempPermissions);
  }

  // Case 2: Update a parent that has children
  if (hasChildren) {
    const tempPermissions: IPermission[] = cloneDeep(existingPermissions);

    const parentPermissionIndex = tempPermissions.findIndex(
      (item) => item.resourceId === resourceId
    );
    tempPermissions[parentPermissionIndex] = {
      ...tempPermissions[parentPermissionIndex],
      checked: newValue,
      accessType: newValue ? AccessType.WRITE : undefined,
    };

    tempPermissions[parentPermissionIndex] = {
      ...tempPermissions[parentPermissionIndex],
      children: tempPermissions[parentPermissionIndex]?.children?.map(
        (item) => ({
          ...item,
          checked: newValue,
        })
      ),
    };

    callbackFunction(tempPermissions);
  }

  // Case 3: Update a parent that doesn't have a children
  if (!isChildren && !hasChildren) {
    const tempPermissions: IPermission[] = cloneDeep(existingPermissions);

    const parentPermissionIndex = tempPermissions.findIndex(
      (item) => item.resourceId === resourceId
    );

    tempPermissions[parentPermissionIndex] = {
      ...tempPermissions[parentPermissionIndex],
      checked: newValue,
      accessType: newValue ? AccessType.WRITE : undefined,
    };

    callbackFunction(tempPermissions);
  }
};

/**
 * Build payload for save tenant permissions
 *
 * @example
 *
 * Input:
 * [
      {
          "resourceId": "a",
          "name": "a name"
          "checked": true,
          "children": [
              {
                  "name": "a1 name"
                  "resourceId": "a1",
                  "checked": true
              }
          ]
      },
      {
          "resourceId": "b",
          "name": "b name"
          "checked": false,
          "children": []
      },
      {
          "resourceId": "c",
          "name": "c name"
          "checked": true,
          "children": []
      }
  ]

  Output:
  [{ resourceId: "a1 name", resourceName: 'a name' }, { resourceId: "c", resourceName: 'c name' }]
 *
 *
 * @param {IPermission[]} data
 * @returns {ISavePermissionSchema[]}
 */
export const formatSavePermissionsPayload = (
  data: IPermission[]
): ISavePermissionSchema[] => {
  const permissions: ISavePermissionSchema[] = [];
  data.forEach((parent) => {
    if (parent.children?.length) {
      let isChildSelected = false;

      parent.children.forEach((child) => {
        if (child.checked) {
          permissions.push({
            resourceId: child.resourceId,
            resourceName: child.name,
            accessType: child.accessType ?? undefined,
          });

          isChildSelected = true;
        }
      });

      // if any child is selected/checked, include parent resource in the payload
      if (isChildSelected) {
        permissions.push({
          resourceId: parent.resourceId,
          resourceName: parent.name,
          accessType: parent.accessType,
        });
      }
    } else if (parent.checked && !parent.children?.length) {
      permissions.push({
        resourceId: parent.resourceId,
        resourceName: parent.name,
        accessType: parent.accessType,
      });
    }
  });

  return permissions;
};

export const convertFileToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve((reader.result as string).split(',')[1]);
    reader.onerror = (error) => reject(error);
  });

// truncates the string adding the specified string at the end
// thisisalongemail@noveltytechnology.com -> thisisalo...
export const truncateText = (str: string, length?: number, ending?: string) => {
  let l = length;
  let e = ending;
  if (l == null) {
    l = 25;
  }
  if (e == null) {
    e = '...';
  }
  if (str?.length > l) {
    return str.substring(0, l - e.length) + e;
  }
  return str || '';
};

export const convertHtmlToPdf = async (
  element: any, // any will be changed
  fileName: string,
  option: IClientAgreementOption
) => {
  const agreementData = await convertHtmlToPdfJs(element, option);
  const base64Data = await toBase64(agreementData);
  const fileSize = getFileSize(base64Data);

  const body = {
    base64: base64Data,
    size: fileSize,
    contentType: 'application/pdf',
    filePath: fileName,
    isPublic: true,
  };
  return body;
};

export const formatEncodedParam = (param: string) =>
  param?.replace(/ /g, '+') ?? '';

/**
 * It takes an array of objects, and returns the total of a property of each object
 * @param  - `totalBy` is the key of the object that you want to total up.
 * @returns total
 */
export const getTotalFromObjectArray = ({
  totalBy,
  format,
  array,
}: {
  totalBy: string;
  format: 'number' | 'currency';
  array: any[];
}) => {
  const total = array.reduce((prev, curr) => prev + curr[totalBy], 0);

  return format === 'currency'
    ? formatCurrency(total, true, 0, 0) ?? 'N/A'
    : total;
};

export const splitDate = (date: string, index: number, noSplit = false) => {
  if (noSplit) {
    return new Date(date)?.toISOString();
  }
  return moment(date)?.format()?.split('T')[index];
};

export const removeEmptyValuesFromObject = (obj: object) => {
  const newObj = { ...obj } as object | any;

  Object.keys(obj).forEach((key) => {
    if (
      newObj[key] === '' ||
      newObj[key] === null ||
      newObj[key] === undefined
    ) {
      delete newObj[key];
    }
  });

  return newObj;
};

export const checkIfValuesInObjectIsEmpty = (objectName: any) =>
  Object.values(objectName).every((x) => x === null || x === '');

export const convertMilesToMetre = (miles: number) => miles * 1609.34;

export const convertBytesToMB = (bytes: number) =>
  `${(bytes / (1024 * 1024)).toFixed(2)} MB`;

export const hideAllButLastDigits = (
  number: string,
  visibleDigitsOption = 4
): string => {
  const visibleDigits =
    'X'.repeat(Math.max(0, number.length - visibleDigitsOption)) +
    number.substring(Math.max(0, number.length - visibleDigitsOption));
  const lastIndexofX = visibleDigits.lastIndexOf('X');
  const lastVisibleDigits = visibleDigits.substring(
    lastIndexofX - 1,
    number.length
  );
  return lastVisibleDigits;
};

export const copyTextToClipboard = (text: string) => {
  if (navigator && window.isSecureContext) {
    return navigator.clipboard.writeText(text);
  }
  return Error();
};

/**
 * The function takes a full name as input and returns an object containing
 * the first name, middle name (if available), and last name.
 * @param {string} name - The `name` parameter is a string that represents a full name.
 * @returns an object with three properties: `firstName`, `middleName`, and `lastName`.
 */
export const getSplitNamesFromFullName = (name: string) => {
  if (!name) return '';
  const splittedNames = name.split(' ');
  const firstName = splittedNames[0] ?? '';
  const middleName = splittedNames.length === 2 ? '' : splittedNames[1];
  const lastName = splittedNames.at(-1) ?? '';
  return {
    firstName,
    middleName,
    lastName,
  };
};

export const getMonthNameByIndex = (index: number) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  if (index >= 0 && index < months.length) {
    return months[index];
  }
  return months[0];
};

export const getDayOfWeekFromIndex = (index: number) => {
  if (index < 0 || index > 6) {
    throw new Error('Invalid day index. Day index must be between 0 and 6.');
  }
  const daysOfWeek = weekNames.filter((item) => item.value);

  return daysOfWeek[index];
};
/**
 * Replace special characters and spaces with underscores
 *
 * @param {string} input :: Input string
 * @returns {string}
 */
export const replaceSpecialCharsWithUnderscore = (input: string): string =>
  input.replace(/[^\w\s]/g, '_').replace(/\s+/g, '_');

/**
 * Removes file extension from file name
 *
 * @param {string} name
 * @returns {string}
 *
 * @input :: image.jpg
 * @output :: image
 *
 */
export const removeFileExtension = (name: string) => {
  if (!name) return '';

  const lastDotIndex = name.lastIndexOf('.');

  if (lastDotIndex !== -1) {
    return name.substring(0, lastDotIndex);
  }

  return name;
};

/**
 * Add two pixels
 *
 * @param {string} pixelValue1
 * @param {string} pixelValue2
 * @returns {string}
 *
 * @input :: ('20px', '5px')
 * @output :: '25px'
 *
 */
export const addPixels = (pixelValue1: string, pixelValue2: string) => {
  // Remove 'px' and parse the numeric parts
  const num1 = parseInt(pixelValue1.replace('px', ''), 10);
  const num2 = parseInt(pixelValue2.replace('px', ''), 10);

  // Check if parsing was successful
  if (!Number.isNaN(num1) && !Number.isNaN(num2)) {
    // Add the numbers and concatenate 'px' to the result
    const sum = num1 + num2;
    return `${sum}px`;
  }
  // Handle invalid input
  return 'Invalid input. Please provide valid pixel values.';
};

export const getSiteUrl = (slug: string, host = `.${SiteDomain.domain}`) =>
  `https://${slug}${host}`;

export const getSlugFromHost = (url: string): string => {
  const subDomain = url.split('.')[0];
  return url.includes('-') ? subDomain.split('-')[1] : subDomain;
};

export const extractDomain = (url: string): string | null => {
  const domainRegex = regex.DOMAIN_NAME_EXTRACT;
  const matches: RegExpMatchArray | null = url.match(domainRegex);
  if (matches && matches.length > 1) {
    return matches[1];
  }
  return null;
};

export const isNullOrUndefined = (value: any) =>
  value === null || value === undefined;

export const bytesToKB = (
  bytes: number | undefined,
  decimalPlaces = 2
): number => {
  if (!bytes) return 0;
  const kb = bytes / 1024;
  return Number(kb.toFixed(decimalPlaces));
};

export const getFirstItemInArray = (item: Array<any> | undefined): any => {
  if (item?.length) {
    return item[0];
  }
  return null;
};

export function parseNumber(input: string | number): number | null {
  // If input is already a number, return it
  if (typeof input === 'number') {
    return Number.isNaN(input) ? null : input;
  }

  // Define the regular expression pattern to match numbers
  const pattern = /\d+/;
  // Use match() to extract the first number from the input string
  const match: RegExpMatchArray | null = String(input).match(pattern);
  // If a match is found, convert the number from string to integer and return it, otherwise return null
  return match ? parseInt(match[0], 10) : null;
}

export const checkIsAgeBelowMinimumAge = ({
  birthDate,
  age = 18,
}: {
  birthDate: Date;
  age?: number;
}) => moment().diff(moment(birthDate), 'years') < age;

const getPhoneCode = (key: string) => {
  switch (key) {
    case PatientContactType.HOME:
      return 'hCode';
    case PatientContactType.WORK:
      return 'wCode';
    default:
      return 'countryCode';
  }
};
const getPhoneExt = (key: string) => {
  switch (key) {
    case PatientContactType.HOME:
      return 'homeExt';
    case PatientContactType.WORK:
      return 'workExt';
    default:
      return 'phoneExt';
  }
};

const getPhoneValue = (key: string) => {
  switch (key) {
    case PatientContactType.HOME:
      return 'home';
    case PatientContactType.WORK:
      return 'work';
    default:
      return 'phone';
  }
};
const getFormattedPhoneValue = (key: string) => {
  switch (key) {
    case PatientContactType.HOME:
      return 'formattedHome';
    case PatientContactType.WORK:
      return 'formattedWork';
    default:
      return 'formattedPhone';
  }
};

export const getContactNumbers = (
  contacts: IClientContact[]
): IClientSplittedContact => {
  const data = contacts.reduce((acc, contact) => {
    const type = contact.type?.toLowerCase();

    const extension = contact.extension || '1';
    const countryCode = contact.countryCode || CountryCodes.USA;
    const isCountrySelectedUS = countryCode === CountryCodes.USA;

    const contactValue = contact.value;
    if (type) {
      return {
        ...acc,
        [getFormattedPhoneValue(contact.type)]: isCountrySelectedUS
          ? `+${adaptedPhoneExtension(contactValue, extension)} ${formatPhone(
              contactValue
            )}`
          : `+${adaptedPhoneExtension(
              contactValue,
              extension
            )} ${contactValue}`,
        [getPhoneValue(contact.type)]: contactValue || '',
        [getPhoneCode(contact.type)]: countryCode,
        [getPhoneExt(contact.type)]: extension,
      };
    }
    return acc;
  }, {} as IClientSplittedContact);
  return data;
};

export const getFormattedContactNumber = (data: any) => ({
  phones: [
    {
      type: PatientContactType.HOME,
      value: unformatPhone(data.home || null),
      extension: data.homeExt || null,
      countryCode: data.hCode || null,
    },
    {
      type: PatientContactType.CELL,
      value: unformatPhone(data.phone || null),
      extension: data.phoneExt || null,
      countryCode: data.countryCode || null,
    },
    {
      type: PatientContactType.WORK,
      value: unformatPhone(data.work || null),
      extension: data.workExt || null,
      countryCode: data.wCode || null,
    },
  ],
});

export const checkIsAccessTypeWrite = (accessType: AccessType) =>
  accessType === AccessType.WRITE;
export const getMinValueBasedOnDecimalDigits = (digit: number) => {
  const mapper = {
    [DECIMAL_DIGITS.NUMBER]: 0.01,
  };
  return mapper[digit];
};

export const generateRandomCryptoId = () =>
  window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16);

export const prependHttpsToLink = (website: string) => {
  const https = 'https://';

  if (website && !website.startsWith(https)) {
    return `${https}${website}`;
  }

  return website;
};
/**
 * Use this to mask acountNumber, caseId, etc.
 *
 * Input: {text: '123456', charToShow: 3 }
 * Output: '****456'
 *
 */
export const maskString = ({
  text,
  charToShow,
  maskChar = '*',
}: {
  text: string;
  charToShow: number;
  maskChar?: string;
}) => {
  if (!text) return '';
  return (
    `${maskChar}`.repeat(text.length - charToShow) + text.slice(-charToShow)
  );
};

// Check  Menu Access
export const checkMenu = (path: string, menus: IPermission[]) => {
  if (!menus) return false;
  const menu = menus.some((e) => e.routePath === path);
  return menu;
};

export const checkPermission = (
  activeMenu: IPermission | null,
  accessType: AccessType = AccessType.READ
) => {
  const currentPath = window.location.pathname;

  if (!activeMenu) return false;
  if (activeMenu.children?.length) {
    const activeChildMenu = activeMenu.children.find(
      (e) => e.routePath === currentPath
    );
    return activeChildMenu?.accessType === accessType;
  }
  return activeMenu.accessType === accessType;
};

export const getCometChatUID = (
  tenantData: IAdaptedTenantData | null,
  uid?: string
) => {
  const code = tenantData?.tenantAssociation.code;
  return `${code}-${uid}`;
};

export function caseInsensitiveReplace(
  text: string,
  oldStr: string,
  newStr: string
): string {
  // Create a regular expression with the 'i' flag for case-insensitive matching
  const regexExp = new RegExp(oldStr, 'gi'); // 'g' for global, 'i' for case-insensitive
  return text.replaceAll(regexExp, newStr);
}
