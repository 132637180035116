import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';
import { SelectionOptions } from 'enums/case';
import ModalKey from 'enums/ModalKey';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { IDonorPatientInformation } from 'interfaces/cases';
import { useSelectedBloodDonorsMutation } from 'services/cases';
import { selectCurrentReferralCase } from 'stores/cases';
import { useConfirmationModal } from 'stores/ConfirmationModal';
import { useAppSelector } from 'stores/hooks';
import { getFullName } from 'utils/common';

const useBloodDonor = () => {
  const navigate = useNavigate();

  const isUserClient = useCheckUserIsClient();
  const confirmationModal = useConfirmationModal();

  const currentReferralCase = useAppSelector(selectCurrentReferralCase);

  const selectedBloodDonorsMutation = useSelectedBloodDonorsMutation();

  const selectedDonor = currentReferralCase?.options?.find(
    (el: IDonorPatientInformation) => el.isSelected
  );

  const onAddClick = () => {
    navigate(
      `modal?type=${ModalKey.BLOOD_DONOR}&caseId=${currentReferralCase?.caseId}`
    );
  };

  const onDeleteClick = async (donorToRemove: IDonorPatientInformation) => {
    const fullName = getFullName(
      donorToRemove.firstName,
      donorToRemove.lastName,
      donorToRemove.middleName
    );

    const result = await confirmationModal?.openConfirmationModal({
      title: `Delete Selected Blood Donor`,
      isAdditionalContentRequired: false,
      content: (
        <Typography component="span">
          {`Are you sure you want to delete this donor ${fullName}? This process is irreversible. If you are not sure, you can cancel.`}
        </Typography>
      ),
    });

    if (result && currentReferralCase?.caseId) {
      confirmationModal?.changeSubmittingStatus(true);

      selectedBloodDonorsMutation.mutate(
        {
          donorId: donorToRemove.uuid || '',
          caseId: currentReferralCase.caseId!,
          type: SelectionOptions.UNSELECTED,
        },
        {
          onSettled: () => {
            confirmationModal?.changeSubmittingStatus(false);
          },
        }
      );
    }
  };

  return {
    isUserClient,
    selectedDonor,
    onAddClick,
    onDeleteClick,
  };
};

export default useBloodDonor;
