import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { changeReferenceNote } from 'stores/cases';
import { useAppDispatch } from 'stores/hooks';

/**
 * Sets reference note if present in the link (normally used to highlight the note)
 */
export const useSetReferenceNoteFromLink = () => {
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const noteIdFromEmail = searchParams.get('messageId');

  useEffect(() => {
    if (noteIdFromEmail) {
      dispatch(changeReferenceNote(noteIdFromEmail));
    }
  }, [dispatch, noteIdFromEmail]);
};
