import Slider from 'react-slick';

import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import EmptyCardImage from 'assets/images/png/emptyCard.png';
import { IReferralCaseProviderOption } from 'interfaces/cases';
import { selectCurrentReferralCase } from 'stores/cases';
import { useAppSelector } from 'stores/hooks';

import SelectedProviderOption from './SelectedProviderOption';

const EmptySelectedProvider = () => (
  <Paper
    className="bordered no-box-shadow"
    sx={{
      paddingBottom: 0,
      background: (t) => t.palette.common.white,
    }}
  >
    <Stack
      height="268px"
      spacing={2}
      sx={{ alignItems: 'center', padding: 3, textAlign: 'center' }}
    >
      <Avatar
        alt="empty"
        src={EmptyCardImage}
        sx={{ width: '80px', height: '80px' }}
        variant="square"
      />
      <Typography variant="body2">
        Provider information not available.
      </Typography>
      <Typography variant="caption">
        You can select a provider from the provider options below.
      </Typography>
    </Stack>
  </Paper>
);

interface IProps {
  width: string;
}

const SampleNextArrow = (props: any) => {
  const { onClick } = props;
  return (
    <Box className="slick-custom-next">
      <IconButton className="shadowed" onClick={onClick} size="small">
        <FontAwesomeIcon icon={faChevronRight} />
      </IconButton>
    </Box>
  );
};

const SamplePrevArrow = (props: any) => {
  const { onClick } = props;
  return (
    <Box className="slick-custom-prev">
      <IconButton className="shadowed" onClick={onClick} size="small">
        <FontAwesomeIcon icon={faChevronLeft} />
      </IconButton>
    </Box>
  );
};
const SelectedProvider = ({ width }: IProps) => {
  const currentReferralCase = useAppSelector(selectCurrentReferralCase);
  const data = currentReferralCase?.providerOptions?.filter(
    (el: IReferralCaseProviderOption) => el.isSelected
  );

  if (!data?.length) {
    return <EmptySelectedProvider />;
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <Box
      className="react-slick"
      sx={{
        width: `${width}px`,
        height: '268px',
        margin: 'auto',
      }}
    >
      <Slider {...settings}>
        {data.map((e) => (
          <div>
            <SelectedProviderOption
              caseId={currentReferralCase?.caseId || ''}
              data={e}
            />
          </div>
        ))}
      </Slider>
    </Box>
  );
};

export default SelectedProvider;
