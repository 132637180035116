import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Badge,
  Box,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { IActiveTenantUser } from 'interfaces/chat';
import { getFullName } from 'utils/common';

import TenantAvatar from './Avatar';

const ChatWindowHeader = ({
  item,
  isWindowOpen,
  onPressIcon,
  onPressHeader,
  isNewWindow = false,
}: {
  item: IActiveTenantUser | null;
  isWindowOpen: boolean;
  onPressIcon: () => void;
  onPressHeader: () => void;
  isNewWindow?: boolean;
}) => {
  const theme = useTheme();

  // Comment this code until issue resolved from CometChat
  // const onPressDelete = async () => {
  //   const result = await deleteConfirmationModal?.openConfirmationModal({
  //     isAdditionalContentRequired: false,
  //     title: `Delete Conversation`,
  //     content: (
  //       <Typography component="span">
  //         Are you sure you want to delete this Conversation with{' '}
  //         <Typography
  //           component="span"
  //           sx={{ fontWeight: (t) => t.typography.fontWeightMedium }}
  //         >
  //           {getFullName(
  //             item?.demographic?.firstName,
  //             item?.demographic?.lastName,
  //             item?.demographic?.middleName ?? ''
  //           )}
  //           ?
  //         </Typography>
  //       </Typography>
  //     ),
  //   });
  //   if (result) {
  //     deleteConfirmationModal?.changeSubmittingStatus(true);
  //     const uid = item?.userId?.toLowerCase() ?? '';
  //     await CometChatService.deleteConversation(uid);
  //     CometChatService.unfriendUser(tenantId, uid)
  //       .then(() => {
  //         deleteConfirmationModal?.changeSubmittingStatus(false);
  //         dispatch(setDeleteConversation(uid));
  //       })
  //       .catch(() => {
  //         deleteConfirmationModal?.changeSubmittingStatus(false);
  //         enqueueSnackbar(
  //           toastMessageConstant.CHAT.DELETE_CONVERSATION_FAILED,
  //           {
  //             variant: 'error',
  //           }
  //         );
  //       });
  //   }
  // };

  return (
    <Box
      alignItems="center"
      borderBottom={1}
      borderColor={theme.palette.gray.lighter}
      display="flex"
      flexDirection="row"
      height={theme.spacing(7)}
      justifyContent="space-between"
      px={1}
      sx={{
        cursor: 'pointer',
        borderBottomWidth: 2,
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        onClick={onPressHeader}
        width="100%"
      >
        {!isNewWindow ? (
          <>
            <TenantAvatar item={item} />
            <Box alignItems="center" display="flex" flexDirection="row" ml={1}>
              <Typography
                fontWeight="medium"
                gutterBottom={false}
                noWrap
                textTransform="capitalize"
                variant="body1"
              >
                {getFullName(
                  item?.demographic?.firstName,
                  item?.demographic?.lastName,
                  ''
                )}
              </Typography>
              {!isWindowOpen && (
                <Box mx={2}>
                  <Badge
                    badgeContent={item?.unreadMessagesCount ?? 0}
                    color="error"
                  />
                </Box>
              )}
            </Box>
          </>
        ) : (
          <Typography
            fontWeight="medium"
            gutterBottom={false}
            textTransform="capitalize"
            variant="body2"
          >
            New Message
          </Typography>
        )}
      </Stack>
      <Box alignItems="center" display="flex" flexDirection="row">
        {/* Comment this code until issue resolved from CometChat
        
        {showMenu && ( 
          <EllipseMenu>
            <EllipseMenuItem
              iconElement={<FontAwesomeIcon icon={faTrashCan} size="xs" />}
              onClick={onPressDelete}
              text="Delete Conversation"
            />
          </EllipseMenu>
        )} */}
        <IconButton onClick={onPressIcon} size="medium">
          <FontAwesomeIcon color={theme.palette.primary.main} icon={faClose} />
        </IconButton>
      </Box>
    </Box>
  );
};

ChatWindowHeader.defaultProps = {
  isNewWindow: false,
};

export default ChatWindowHeader;
