import apiRoutes from 'constants/apiRoutes';
import { IFilter } from 'interfaces/common';
import { IListResponse, IResponse } from 'interfaces/http';
import { IUserTableRow } from 'interfaces/tenant-management/user';
import { baseRequest } from 'utils/axios';

const { chat } = apiRoutes;

export const getTenantUsers = async (
  params: IFilter,
  userId: string
): Promise<IResponse<IListResponse<IUserTableRow>>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: chat.getAll.replace(':userId', userId),
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
