import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { AuthLayout, DashboardLayout, UiGuidelinesLayout } from 'common';
import AgreementSentSuccess from 'common/AgreementSentSuccess';
//  TODO: Reverting for user verify for now
// import VerifyUserLayout from 'common/layouts/auth/verify-user';
import { IdleTimerWrapper } from 'common/IdleTimerWrapper';
import NoteLayout from 'common/layouts/email-notes';
import ReceiptLayout from 'common/layouts/receipt';
import { CaseAuthTenantCheck } from 'common/pre-otp-authenticate/CaseAuthTenantCheck';
import { CaseNoteTaggedOTPAuthenticateWrapper } from 'common/pre-otp-authenticate/CaseNoteTaggedOTPAuthenticateWrapper';
import { CasePreOTPAuthenticateWrapper } from 'common/pre-otp-authenticate/CasePreOTPAuthenticateWrapper';
import { RedirectToIndexIfLoggedIn } from 'common/RedirectToIndexIfLoggedIn';
import { RouteGuard } from 'common/RouteGuard';
import { SharedResourceAuthenticator } from 'common/share-resource-authenticator/SharedResourceAuthenticator';
import { UnauthorizedResourceAccessDisplay } from 'common/share-resource-authenticator/UnauthorizedResourceAccessDisplay';
import { URLAuthenticateWrapper } from 'common/URLAuthenticateWrapper';
import { secretKey } from 'constants/secretKey';
import uiRoutes from 'constants/uiRoutes';
import { ForgetUsername } from 'features/auth/ForgotUsername';
import AuthChecker from 'features/authenticate/AuthChecker';
import { TasklistView } from 'features/email-notes-files/tasks/TaskListView';
import Home from 'features/home';
import { CaseConfigure } from 'features/settings/case/caseConfiguration';
import SettingsLayout from 'features/settings/layout';
import { getLocal, getSession } from 'utils/storage';
import { checkTenantIsV360 } from 'utils/tenant-management/tenant';

import { lazyImportWithRetry } from '../common/LazyLoader';

// Lazy load route level components (for bundle splitting)

const ActivateAccount = lazyImportWithRetry(
  () => import('features/auth/ActivateAccount')
);
//  TODO: Reverting for user verify for now
// const ActivateAccountSignup = lazyImportWithRetry(
//   () => import('features/auth/verify-user/activate-account-signup')
// );
const ActivityLog = lazyImportWithRetry(() => import('features/activity-log'));
const Authenticate = lazyImportWithRetry(
  () => import('features/authenticate/Authenticate')
);
const Appointment = lazyImportWithRetry(() => import('features/appointments'));
const Admins = lazyImportWithRetry(() => import('features/settings/admins'));
const BillingInvoice = lazyImportWithRetry(
  () => import('features/invoice/billings-invoice/BillingInvoice')
);
const Billings = lazyImportWithRetry(
  () => import('features/settings/profile/billings')
);
const ClientGroup = lazyImportWithRetry(() => import('features/client-groups'));
const ClientIndividual = lazyImportWithRetry(
  () => import('features/client-individuals')
);
const ClientVirtualVisit = lazyImportWithRetry(
  () => import('features/virtual-visit/comet-chat')
);

const CommissionReport = lazyImportWithRetry(
  () => import('features/reports/commission-report')
);
const Commissions = lazyImportWithRetry(
  () => import('features/settings/profile/referring-agents/commissions')
);
const Calender = lazyImportWithRetry(() => import('features/calender'));
const VirtualVisit = lazyImportWithRetry(
  () => import('features/virtual-visit')
);
const Communication = lazyImportWithRetry(
  () => import('features/settings/communication')
);

const Cases = lazyImportWithRetry(
  () => import('features/case-layout-new/Cases')
);
const CaseListing = lazyImportWithRetry(
  () => import('features/case-listing/CaseListingPage')
);
const CaseStatus = lazyImportWithRetry(
  () => import('features/settings/profile/case/index')
);

const ClientAgreement = lazyImportWithRetry(
  () => import('features/client-agreement')
);
const ConvertToESignSuccess = lazyImportWithRetry(
  () => import('features/documents/documents/ConvertToESignSuccess')
);
const Dashboard = lazyImportWithRetry(() => import('features/dashboard'));
const Documents = lazyImportWithRetry(
  () => import('features/documents/documents')
);
const ConvertToESign = lazyImportWithRetry(
  () => import('features/documents/documents/ConvertToESign')
);

const CasePatientReport = lazyImportWithRetry(
  () => import('features/case-layout-new/patient/CasePatientReport')
);
const EditCommunicationTemplate = lazyImportWithRetry(
  () => import('features/settings/communication/edit-communication-template')
);
const ESignatures = lazyImportWithRetry(
  () => import('features/documents/e-signatures')
);
const ESignatureSubmitRedirectView = lazyImportWithRetry(
  () => import('features/documents/documents/ESignatureSubmitRedirectView')
);
const FailedTransactions = lazyImportWithRetry(
  () => import('features/billings/failed')
);
const FormConfiguration = lazyImportWithRetry(
  () => import('features/settings/enrollment/form-configuration')
);
const Merchants = lazyImportWithRetry(
  () => import('features/settings/merchants')
);
const TinyUrl = lazyImportWithRetry(() => import('features/misc/tiny-url'));
const Inbox = lazyImportWithRetry(() => import('features/comms/inbox'));
const LabService = lazyImportWithRetry(
  () => import('features/settings/lab-service')
);
const Login = lazyImportWithRetry(() => import('features/auth/Login'));

const Logout = lazyImportWithRetry(() => import('features/auth/Logout'));
const ModalSwitch = lazyImportWithRetry(() => import('./ModalSwitch'));
const OfferingNetworks = lazyImportWithRetry(
  () => import('features/networks/network-offerings')
);
const Outbox = lazyImportWithRetry(() => import('features/comms/outbox'));
const NoMatch = lazyImportWithRetry(() => import('features/misc/no-match'));
const Profile = lazyImportWithRetry(() => import('features/settings/profile'));
const Tiles = lazyImportWithRetry(async () => ({
  default: (await import('features/settings/tiles')).Tiles,
}));
const ReportDashboard = lazyImportWithRetry(
  () => import('features/reports/dashboard')
);
const FacilityNetworks = lazyImportWithRetry(
  () => import('features/networks/network-facilities')
);
const FrontPage = lazyImportWithRetry(
  () => import('features/settings/front-page')
);
const PhysicianNetworks = lazyImportWithRetry(
  () => import('features/networks/network-physicians')
);
const ServiceNetworks = lazyImportWithRetry(
  () => import('features/networks/network-services')
);
const TenantMembershipOffering = lazyImportWithRetry(
  () => import('features/settings/tenant-membership-offerings')
);
const ReportPrintPreview = lazyImportWithRetry(
  () => import('features/reports/dashboard/ReportPrintPreview')
);
const Role = lazyImportWithRetry(
  () => import('features/settings/admins/roles')
);
const ResetPassword = lazyImportWithRetry(
  () => import('features/auth/ResetPassword')
);
const SalesByRep = lazyImportWithRetry(
  () => import('features/reports/sales-by-rep')
);
const SalesByCompany = lazyImportWithRetry(
  () => import('features/reports/sales-by-company')
);
const ServiceConfiguration = lazyImportWithRetry(
  () => import('features/settings/enrollment/service-configuration')
);
const Tenant = lazyImportWithRetry(
  () => import('features/tenant-management/tenant')
);
const TenantAncillaryOfferings = lazyImportWithRetry(
  () => import('features/settings/tenant-ancillary-offerings')
);
const TextConfiguration = lazyImportWithRetry(
  () => import('features/settings/enrollment/text-configuration')
);
const Transactions = lazyImportWithRetry(
  () => import('features/billings/transactions')
);

const TransactionReceiptAuthenticate = lazyImportWithRetry(
  () => import('features/receipt/transaction/TransactionReceiptAuthenticate')
);

const TestReport = lazyImportWithRetry(() => import('features/test-report'));
const TransactionReceipt = lazyImportWithRetry(
  () => import('features/receipt/transaction/TransactionReceipt')
);
const NoteAuthenticate = lazyImportWithRetry(
  () => import('features/email-notes-files/NoteAuthenticate')
);
const EmailNotesFiles = lazyImportWithRetry(
  () => import('features/email-notes-files')
);
const UiGuidelines = lazyImportWithRetry(
  () => import('features/ui-guidelines')
);
const UpcomingTransactions = lazyImportWithRetry(
  () => import('features/billings/upcoming')
);
const User = lazyImportWithRetry(
  () => import('features/tenant-management/user')
);

const ProfileSettings = lazyImportWithRetry(
  () => import('features/profile-settings')
);
const Contact = lazyImportWithRetry(
  () => import('features/settings/profile/Contact')
);
const ReferringAgents = lazyImportWithRetry(
  () => import('features/settings/profile/referring-agents')
);
const Services = lazyImportWithRetry(
  () => import('features/settings/profile/tenant-services/index')
);
const LeadStatus = lazyImportWithRetry(
  () => import('features/settings/profile/status/index')
);

const TestResultDetails = lazyImportWithRetry(
  () => import('features/test-report/details/TestResultDetails')
);

const Activity = lazyImportWithRetry(
  () => import('features/timesheet/activity')
);

const AdvocateCases = lazyImportWithRetry(
  () => import('features/reports/advocate-cases')
);

const TimesheetByAdvocate = lazyImportWithRetry(
  () => import('features/timesheet/advocate')
);

const CasesMinimalView = lazyImportWithRetry(
  () => import('features/case-layout-new/CasesMinimalView')
);

const TimesheetBySponsor = lazyImportWithRetry(
  () => import('features/timesheet/sponsor')
);
const TimesheetByClient = lazyImportWithRetry(async () => ({
  default: (await import('features/timesheet/by-client/TimeSheetByClientPage'))
    .TimeSheetByClientPage,
}));

const ThirdPartyIntegration = lazyImportWithRetry(
  () => import('features/settings/third-party-integration')
);
//  TODO: Reverting for user verify for now
// const UserExistsLinkAccount = lazyImportWithRetry(
//   () => import('features/auth/verify-user/user-exists')
// );

// const VerifyUserAccount = lazyImportWithRetry(
//   () => import('features/auth/verify-user/verify-user-exists')
// );

const MyTimesheet = lazyImportWithRetry(
  () => import('features/timesheet/my-timesheet')
);

const Invoices = lazyImportWithRetry(
  () => import('features/billings/invoices')
);

const InitiateVirtualVisit = lazyImportWithRetry(
  () => import('features/virtual-visit/comet-chat')
);

const QuestionnaireResponse = lazyImportWithRetry(
  () => import('features/quesitonnaire-respondent')
);

const Leads = lazyImportWithRetry(
  () => import('features/leads/leads-individuals')
);

const QuestionnaireAuthenticate = lazyImportWithRetry(
  () =>
    import(
      'features/quesitonnaire-respondent/QuestionnaireRespondentAuthenticate'
    )
);

const SharedReferralCaseAuthenticate = lazyImportWithRetry(
  () =>
    import('features/cases/shared-referral-case/SharedReferralCaseAuthenticate')
);

const StartSigning = lazyImportWithRetry(
  () => import('features/documents/documents/StartSigning')
);

const ClientsReport = lazyImportWithRetry(
  () => import('features/reports/clients-new')
);
const ClientsReportTable = lazyImportWithRetry(
  () => import('features/reports/clients-new/reports-view/report-table')
);
const ClientsReportFullScreenPreview = lazyImportWithRetry(
  () =>
    import(
      'features/reports/clients-new/reports-view/report-fullscreen-preview'
    )
);
const CoverPage = lazyImportWithRetry(
  () => import('features/settings/enrollment/cover-page')
);

const VirtualVisitLobbyPage = lazyImportWithRetry(
  () => import('features/virtual-visit/virtual-visit/LobbyPage')
);
const VirtualVisitConfirmationPage = lazyImportWithRetry(
  () => import('features/virtual-visit/virtual-visit/ConfirmationPage')
);
const VirtualVisitRequestPage = lazyImportWithRetry(
  () => import('features/virtual-visit/virtual-visit/RequestReschedule')
);
const VirtualVisitRequestCancelPage = lazyImportWithRetry(
  () => import('features/virtual-visit/virtual-visit/RequestCancel')
);

const UserAlreadyExists = lazyImportWithRetry(
  () => import('common/UserAlreadyExistsMessage')
);

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  // const permissions = useMemo(() => getLocal('menus', true), []);

  const caseId = searchParams.get('caseId');
  const messageId = searchParams.get('messageId');
  const fromEmail = searchParams.get('fromEmail');
  const codeLink = searchParams.get('link');

  const isEmailNote = !!caseId && !!messageId && !!fromEmail && !!codeLink;

  const isAuthenticated = !!getLocal('jwtToken');

  // Disabled route permission check for now

  // const availableRoutes = useMemo(
  //   () => getRoutePathsFromPermissions(permissions),
  //   [permissions]
  // );

  // const { canAccessRoute } = checkIfCurrentRouteIsAccessible(availableRoutes);

  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    const returnUrl = location.search
      ? encodeURIComponent(`${location.pathname}${location.search}`)
      : `${location.pathname}`;

    return (
      <Navigate
        replace
        state={{ from: location }}
        to={
          isEmailNote
            ? `${uiRoutes.noteAuthenticate}${location.search}`
            : `/${uiRoutes.auth.login}?returnUrl=${returnUrl}`
        }
      />
    );
  }

  // if (!canAccessRoute) {
  //   return <Navigate replace to={uiRoutes.index} />;
  // }

  return children;
};

const RequireQuestionnaireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const { respondentId, code } = useParams();

  const isAuthenticated = !!getLocal('jwtToken');
  const isQuestionnaireResponse = !!respondentId && !!code;
  const showQuestionnaire = getSession('showQuestionnaire');

  if (showQuestionnaire === secretKey.QUESTIONNAIRE_KEY) return children;

  if (!isAuthenticated) {
    return (
      <Navigate
        replace
        state={{ from: location }}
        to={
          isQuestionnaireResponse
            ? `${uiRoutes.questionnaireResponseAuthenticate}${location.search}&respondentId=${respondentId}`
            : `/${uiRoutes.auth.login}`
        }
      />
    );
  }
  return children;
};

const RequireVitafy360Auth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const isUserVitafy360 = checkTenantIsV360();

  if (!isUserVitafy360) {
    return <Navigate replace state={{ from: location }} to="/" />;
  }
  return children;
};

const RequireSessionAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const isAuthenticated = !!getSession('jwtToken');

  if (!isAuthenticated) {
    return (
      <Navigate
        replace
        state={{ from: location }}
        to={`/${uiRoutes.auth.login}`}
      />
    );
  }
  return children;
};

const Router = (): JSX.Element => (
  <Routes>
    <Route element={<ClientAgreement />} path={uiRoutes.clientAgreement}>
      <Route element={<ModalSwitch />} path={uiRoutes.modal} />
    </Route>
    <Route element={<ReceiptLayout />}>
      <Route
        element={
          <RequireQuestionnaireAuth>
            <QuestionnaireResponse />
          </RequireQuestionnaireAuth>
        }
        path={uiRoutes.questionnaireResponse}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
    </Route>

    <Route element={<NoteLayout />}>
      <Route
        element={<TransactionReceiptAuthenticate />}
        path={uiRoutes.transcationReceiptAuthenticate}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
    </Route>

    <Route element={<NoteLayout />}>
      <Route
        element={<QuestionnaireAuthenticate />}
        path={uiRoutes.questionnaireResponseAuthenticate}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
    </Route>
    <Route element={<NoteLayout />}>
      <Route
        element={
          <CasePreOTPAuthenticateWrapper>
            <SharedReferralCaseAuthenticate />
          </CasePreOTPAuthenticateWrapper>
        }
        path={uiRoutes.referralCases.share}
      />
    </Route>

    <Route>
      <Route element={<ModalSwitch />} path={uiRoutes.modal} />
    </Route>
    <Route element={<ReceiptLayout />}>
      <Route element={<TestResultDetails />} path={uiRoutes.testResult} />
    </Route>

    <Route
      element={<UserAlreadyExists />}
      path={uiRoutes.auth.userExistsLinkAccount}
    />
    <Route element={<ForgetUsername />} path={uiRoutes.auth.forgetUsername} />

    <Route element={<RedirectToIndexIfLoggedIn />}>
      <Route element={<Login />} path={uiRoutes.auth.login}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
    </Route>
    <Route element={<AuthLayout />}>
      <Route
        element={<ActivateAccount />}
        path={uiRoutes.auth.activateAccount}
      />
      <Route element={<Logout />} path={uiRoutes.auth.logout} />
      <Route element={<ResetPassword />} path={uiRoutes.auth.resetPassword} />
    </Route>
    <Route
      element={
        <IdleTimerWrapper>
          <RequireAuth>
            <DashboardLayout />
          </RequireAuth>
        </IdleTimerWrapper>
      }
    >
      <Route
        element={
          <RouteGuard route={uiRoutes.appointmentCheckin}>
            <Appointment />
          </RouteGuard>
        }
        path={uiRoutes.appointmentCheckin}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route element={<Home />} path={uiRoutes.index} />
      <Route
        element={
          <RouteGuard route={uiRoutes.activityLog}>
            <ActivityLog />
          </RouteGuard>
        }
        path={uiRoutes.activityLog}
      />
      <Route
        element={
          <RouteGuard route={uiRoutes.dashboard}>
            <Dashboard />
          </RouteGuard>
        }
        path={uiRoutes.dashboard}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={
          <RouteGuard route={uiRoutes.groups}>
            <ClientGroup />
          </RouteGuard>
        }
        path={uiRoutes.groups}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={
          <RequireVitafy360Auth>
            <ClientGroup />
          </RequireVitafy360Auth>
        }
        path={uiRoutes.tenantManagement.allGroups}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route
        element={
          <RouteGuard route={uiRoutes.documents.documents}>
            <Documents />
          </RouteGuard>
        }
        path={uiRoutes.documents.documents}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={
          <RouteGuard route={uiRoutes.documents.eSignatures}>
            <ESignatures />
          </RouteGuard>
        }
        path={uiRoutes.documents.eSignatures}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={<ConvertToESign />}
        path={uiRoutes.documents.convertToESignature}
      />
      <Route
        element={
          <RouteGuard route={uiRoutes.individuals}>
            <ClientIndividual />
          </RouteGuard>
        }
        path={uiRoutes.individuals}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={
          <RequireVitafy360Auth>
            <ClientIndividual />
          </RequireVitafy360Auth>
        }
        path={uiRoutes.tenantManagement.allIndividuals}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route
        element={
          <RouteGuard route={uiRoutes.cases.detail}>
            <CaseAuthTenantCheck>
              <Cases />
            </CaseAuthTenantCheck>
          </RouteGuard>
        }
        path={uiRoutes.cases.detail}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={
          <RouteGuard route={uiRoutes.cases.list}>
            <CaseListing />
          </RouteGuard>
        }
        path={uiRoutes.cases.list}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route
        element={
          <RouteGuard route={uiRoutes.reports.commissionReport}>
            <CommissionReport />
          </RouteGuard>
        }
        path={uiRoutes.reports.commissionReport}
      />
      <Route
        element={
          <RouteGuard route={uiRoutes.reports.commissionReport}>
            <CommissionReport />
          </RouteGuard>
        }
        path={uiRoutes.reports.commissionReport}
      />
      <Route
        element={
          <RouteGuard route={uiRoutes.calender}>
            <Calender />
          </RouteGuard>
        }
        path={uiRoutes.calender}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route
        element={
          <RouteGuard route={uiRoutes.leads}>
            <Leads />
          </RouteGuard>
        }
        path={uiRoutes.leads}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={
          <RouteGuard route={uiRoutes.tenantManagement.tenants}>
            <Tenant />
          </RouteGuard>
        }
        path={uiRoutes.tenantManagement.tenants}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={
          <RouteGuard route={uiRoutes.settings}>
            <FrontPage />
          </RouteGuard>
        }
        path={uiRoutes.settings}
      />
      <Route
        element={
          <RouteGuard route={uiRoutes.settings}>
            <SettingsLayout />
          </RouteGuard>
        }
        path={uiRoutes.settings}
      >
        <Route
          element={
            <RouteGuard route={uiRoutes.profileSettings.profile}>
              <Profile />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.profile}
        >
          <Route element={<ModalSwitch />} path={uiRoutes.modal} />
        </Route>
        <Route
          element={
            <RouteGuard route={uiRoutes.profileSettings.tiles}>
              <Tiles />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.tiles}
        />
        <Route
          element={
            <RouteGuard route={uiRoutes.profileSettings.billings}>
              <Billings />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.billings}
        >
          <Route element={<ModalSwitch />} path={uiRoutes.modal} />
        </Route>
        <Route
          element={
            <RouteGuard route={uiRoutes.profileSettings.admins}>
              <Admins />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.admins}
        >
          <Route element={<ModalSwitch />} path={uiRoutes.modal} />
        </Route>
        <Route
          element={
            <RouteGuard
              route={uiRoutes.profileSettings.tenantMembershipOfferings}
            >
              <TenantMembershipOffering />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.tenantMembershipOfferings}
        >
          <Route element={<ModalSwitch />} path={uiRoutes.modal} />
        </Route>
        <Route
          element={
            <RouteGuard
              route={uiRoutes.profileSettings.editCommunicationTemplate}
            >
              <EditCommunicationTemplate />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.editCommunicationTemplate}
        />
        <Route
          element={
            <RouteGuard route={uiRoutes.profileSettings.contact}>
              <Contact />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.contact}
        >
          <Route element={<ModalSwitch />} path={uiRoutes.modal} />
        </Route>
        <Route
          element={
            <RouteGuard route={uiRoutes.profileSettings.referringAgent}>
              <ReferringAgents />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.referringAgent}
        >
          <Route element={<ModalSwitch />} path={uiRoutes.modal} />
        </Route>
        <Route
          element={
            <RouteGuard route={uiRoutes.profileSettings.service}>
              <Services />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.service}
        >
          <Route element={<ModalSwitch />} path={uiRoutes.modal} />
        </Route>
        <Route
          element={
            <RouteGuard route={uiRoutes.profileSettings.leadStatus}>
              <LeadStatus />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.leadStatus}
        >
          <Route element={<ModalSwitch />} path={uiRoutes.modal} />
        </Route>

        <Route
          element={
            <RouteGuard route={uiRoutes.profileSettings.caseStatus}>
              <CaseStatus />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.caseStatus}
        >
          <Route element={<ModalSwitch />} path={uiRoutes.modal} />
        </Route>

        <Route
          element={
            <RouteGuard route={uiRoutes.profileSettings.communication}>
              <Communication />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.communication}
        >
          <Route element={<ModalSwitch />} path={uiRoutes.modal} />
        </Route>
        <Route
          element={
            <RouteGuard
              route={uiRoutes.profileSettings.tenantAncillaryOfferings}
            >
              <TenantAncillaryOfferings />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.tenantAncillaryOfferings}
        >
          <Route element={<ModalSwitch />} path={uiRoutes.modal} />
        </Route>
        <Route
          element={
            <RouteGuard route={uiRoutes.profileSettings.labService}>
              <LabService />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.labService}
        >
          <Route element={<ModalSwitch />} path={uiRoutes.modal} />
        </Route>
        <Route
          element={
            <RouteGuard route={uiRoutes.profileSettings.textConfiguration}>
              <TextConfiguration />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.textConfiguration}
        />
        <Route
          element={
            <RouteGuard route={uiRoutes.profileSettings.formConfiguration}>
              <FormConfiguration />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.formConfiguration}
        />
        <Route
          element={
            <RouteGuard route={uiRoutes.profileSettings.coverPage}>
              <CoverPage />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.coverPage}
        />
        <Route
          element={
            <RouteGuard route={uiRoutes.profileSettings.case}>
              <CaseConfigure />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.case}
        />
        <Route
          element={
            <RouteGuard route={uiRoutes.profileSettings.roles}>
              <Role />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.roles}
        >
          <Route element={<ModalSwitch />} path={uiRoutes.modal} />
        </Route>
        <Route
          element={
            <RouteGuard route={uiRoutes.profileSettings.serviceConfiguration}>
              <ServiceConfiguration />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.serviceConfiguration}
        />
        <Route
          element={
            <RouteGuard route={uiRoutes.profileSettings.integration}>
              <ThirdPartyIntegration />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.integration}
        />
        <Route
          element={
            <RouteGuard route={uiRoutes.profileSettings.roles}>
              <Role />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.roles}
        >
          <Route element={<ModalSwitch />} path={uiRoutes.modal} />
        </Route>
        <Route
          element={
            <RouteGuard route={uiRoutes.profileSettings.merchants}>
              <Merchants />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.merchants}
        />
        <Route
          element={
            <RouteGuard route={uiRoutes.profileSettings.commissions}>
              <Commissions />
            </RouteGuard>
          }
          path={uiRoutes.profileSettings.commissions}
        />
      </Route>

      <Route
        element={
          <RouteGuard route={uiRoutes.networks.facilityNetworks}>
            <FacilityNetworks />
          </RouteGuard>
        }
        path={uiRoutes.networks.facilityNetworks}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={
          <RouteGuard route={uiRoutes.virtualVisit.virtualVisit}>
            <VirtualVisit />
          </RouteGuard>
        }
        path={uiRoutes.virtualVisit.virtualVisit}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={
          <RouteGuard route={uiRoutes.networks.offeringNetworks}>
            <OfferingNetworks />
          </RouteGuard>
        }
        path={uiRoutes.networks.offeringNetworks}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={
          <RouteGuard route={uiRoutes.networks.physicianNetworks}>
            <PhysicianNetworks />
          </RouteGuard>
        }
        path={uiRoutes.networks.physicianNetworks}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={
          <RouteGuard route={uiRoutes.networks.serviceNetworks}>
            <ServiceNetworks />
          </RouteGuard>
        }
        path={uiRoutes.networks.serviceNetworks}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={
          <RouteGuard route={uiRoutes.comms.outbox}>
            <Outbox />
          </RouteGuard>
        }
        path={uiRoutes.comms.outbox}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route
        element={
          <RouteGuard route={uiRoutes.comms.inbox}>
            <Inbox />
          </RouteGuard>
        }
        path={uiRoutes.comms.inbox}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={
          <RouteGuard route={uiRoutes.reports.advocateCases}>
            <AdvocateCases />
          </RouteGuard>
        }
        path={uiRoutes.reports.advocateCases}
      />
      <Route
        element={
          <RouteGuard route={uiRoutes.reports.dashboard}>
            <ReportDashboard />
          </RouteGuard>
        }
        path={uiRoutes.reports.dashboard}
      />
      <Route
        element={
          <RouteGuard route={uiRoutes.reports.salesByRep}>
            <SalesByRep />
          </RouteGuard>
        }
        path={uiRoutes.reports.salesByRep}
      />
      <Route
        element={
          <RouteGuard route={uiRoutes.reports.salesByCompany}>
            <SalesByCompany />
          </RouteGuard>
        }
        path={uiRoutes.reports.salesByCompany}
      />
      <Route
        element={
          <RouteGuard route={uiRoutes.reports.clients.index}>
            <ClientsReport />
          </RouteGuard>
        }
        path={uiRoutes.reports.clients.index}
      />
      <Route
        element={
          <RouteGuard route={uiRoutes.billings.transactions}>
            <Transactions />
          </RouteGuard>
        }
        path={uiRoutes.billings.transactions}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={
          <RouteGuard route={uiRoutes.testReport}>
            <TestReport />
          </RouteGuard>
        }
        path={uiRoutes.testReport}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route
        element={
          <RouteGuard route={uiRoutes.billings.upcoming}>
            <UpcomingTransactions />
          </RouteGuard>
        }
        path={uiRoutes.billings.upcoming}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={
          <RouteGuard route={uiRoutes.billings.invoices}>
            <Invoices />
          </RouteGuard>
        }
        path={uiRoutes.billings.invoices}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route
        element={
          <RouteGuard route={uiRoutes.billings.failedTransactions}>
            <FailedTransactions />
          </RouteGuard>
        }
        path={uiRoutes.billings.failedTransactions}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route
        element={
          <RouteGuard route={uiRoutes.tenantManagement.users}>
            <User />
          </RouteGuard>
        }
        path={uiRoutes.tenantManagement.users}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route element={<ProfileSettings />} path={uiRoutes.myProfile}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route
        element={
          <RouteGuard route={uiRoutes.timesheet.byActivity}>
            <Activity />
          </RouteGuard>
        }
        path={uiRoutes.timesheet.byActivity}
      />
      <Route
        element={
          <RouteGuard route={uiRoutes.timesheet.byAdvocate}>
            <TimesheetByAdvocate />
          </RouteGuard>
        }
        path={uiRoutes.timesheet.byAdvocate}
      />
      <Route
        element={
          <RouteGuard route={uiRoutes.timesheet.bySponsor}>
            <TimesheetBySponsor />
          </RouteGuard>
        }
        path={uiRoutes.timesheet.bySponsor}
      />

      <Route element={<MyTimesheet />} path={uiRoutes.timesheet.myTimesheet} />
      <Route
        element={<TimesheetByClient />}
        path={uiRoutes.timesheet.byClient}
      />
      <Route
        element={
          <RouteGuard route={uiRoutes.timesheet.myTimesheet}>
            <MyTimesheet />
          </RouteGuard>
        }
        path={uiRoutes.timesheet.myTimesheet}
      />
    </Route>
    <Route element={<UiGuidelinesLayout />}>
      <Route element={<UiGuidelines />} path={uiRoutes.uiGuidelines} />
    </Route>

    <Route
      element={<ConvertToESignSuccess />}
      path={uiRoutes.documents.convertToESignatureSuccess}
    />

    <Route
      element={<ESignatureSubmitRedirectView />}
      path={uiRoutes.documents.eSignatureSubmitRedirect}
    />

    <Route
      element={<InitiateVirtualVisit />}
      path={uiRoutes.virtualVisit.startVirtualVisit}
    >
      <Route element={<ModalSwitch />} path={uiRoutes.modal} />
    </Route>
    <Route path={uiRoutes.virtualVisit.clientVirtualVisit}>
      <Route
        element={<ClientVirtualVisit />}
        path={uiRoutes.virtualVisit.clientVirtualVisit}
      />
      <Route element={<ModalSwitch />} path={uiRoutes.modal} />
    </Route>
    <Route element={<ReceiptLayout />}>
      <Route
        element={
          <URLAuthenticateWrapper>
            <TransactionReceipt />
          </URLAuthenticateWrapper>
        }
        path={uiRoutes.receipt}
      />
    </Route>
    <Route element={<ReceiptLayout />}>
      <Route element={<BillingInvoice />} path={uiRoutes.invoice} />
    </Route>

    <Route element={<NoteLayout />}>
      <Route element={<TasklistView />} path={uiRoutes.tasks} />

      <Route
        element={
          <CaseNoteTaggedOTPAuthenticateWrapper>
            <NoteAuthenticate />
          </CaseNoteTaggedOTPAuthenticateWrapper>
        }
        path={uiRoutes.noteAuthenticate}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route element={<Authenticate />} path={uiRoutes.authenticate}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route element={<EmailNotesFiles />} path={uiRoutes.notes}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
    </Route>
    <Route
      element={
        <RequireSessionAuth>
          <CasesMinimalView />
        </RequireSessionAuth>
      }
      path={uiRoutes.cases.minimalView}
    >
      <Route element={<ModalSwitch />} path={uiRoutes.modal} />
    </Route>

    <Route element={<AuthChecker />}>
      <Route element={<NoteLayout />}>
        <Route element={<CasePatientReport />} path={uiRoutes.caseReport} />
      </Route>
    </Route>

    <Route
      element={<ReportPrintPreview />}
      path={uiRoutes.reports.printPreview}
    />
    <Route
      element={<ClientsReportFullScreenPreview />}
      path={uiRoutes.reports.clients.fullScreenPreview}
    />
    <Route
      element={<ClientsReportTable />}
      path={uiRoutes.reports.clients.viewData}
    />
    <Route
      element={<AgreementSentSuccess />}
      path={uiRoutes.clientSuccessAgreement}
    />
    <Route
      element={<VirtualVisitLobbyPage />}
      path={uiRoutes.virtualVisit.lobby}
    >
      <Route element={<ModalSwitch />} path={uiRoutes.modal} />
    </Route>
    <Route
      element={<VirtualVisitConfirmationPage />}
      path={uiRoutes.virtualVisit.confirmation}
    >
      <Route element={<ModalSwitch />} path={uiRoutes.modal} />
    </Route>
    <Route
      element={<VirtualVisitRequestPage />}
      path={uiRoutes.virtualVisit.rescheduleRequest}
    />
    <Route
      element={<VirtualVisitRequestCancelPage />}
      path={uiRoutes.virtualVisit.cancelRequest}
    />

    <Route element={<StartSigning />} path={uiRoutes.startSigning} />

    <Route element={<SharedResourceAuthenticator />} path={uiRoutes.share} />
    <Route
      element={
        <NoteLayout>
          <UnauthorizedResourceAccessDisplay />
        </NoteLayout>
      }
      path={uiRoutes.unauthorized}
    />

    {/* Tiny url hash route. This route should be at the end before no match (or wildcard) route */}
    <Route element={<TinyUrl />} path={uiRoutes.tinyUrl} />
    {/* No match found should have the lowest precedence */}
    <Route element={<NoMatch />} path="*" />
  </Routes>
);

export default Router;
