import { faArrowLeft, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingIndicator } from 'common';
import MediumSpanText from 'common/display/MediumSpanText';
import CodeInput from 'common/ui/code-input';

interface OTPVerificationInputDisplayProps {
  email: string;
  isOtpVerificationInProgress: boolean;
  isResendOtpInProgress: boolean;
  otpValue: string;
  onOtpInputChange: (otpValue: string) => void;
  onResendOtp: VoidFunction;
  onBackClick: VoidFunction;
}

export const OTPVerificationInputDisplay = ({
  email,
  isOtpVerificationInProgress,
  isResendOtpInProgress,
  otpValue,
  onOtpInputChange,
  onResendOtp,
  onBackClick,
}: OTPVerificationInputDisplayProps) => (
  <>
    <Stack
      alignItems="center"
      display="flex"
      justifyContent="center"
      py={3}
      spacing={2}
    >
      <Typography style={{ textAlign: 'center' }} variant="body2">
        We have sent code to your email <MediumSpanText>{email}</MediumSpanText>
      </Typography>

      <CodeInput onChange={onOtpInputChange} value={otpValue} />
      {isOtpVerificationInProgress ? (
        <LoadingIndicator containerHeight="100px" />
      ) : (
        <List
          sx={{
            listStyleType: 'disc',
            '& .MuiListItem-root': {
              display: 'list-item',
            },
          }}
        >
          <ListItem disableGutters disablePadding>
            <Typography gutterBottom={false} variant="body2">
              The code is valid for{' '}
              <Typography
                component="span"
                fontWeight="medium"
                sx={{
                  color: 'text.primary',
                }}
                variant="inherit"
              >
                5 minutes
              </Typography>
            </Typography>
          </ListItem>
          <ListItem disableGutters disablePadding>
            <Typography gutterBottom={false} variant="body2">
              Didn&apos;t receive the code?{' '}
              <Button
                disabled={isResendOtpInProgress}
                onClick={onResendOtp}
                size="medium"
                sx={{ px: 0, color: 'secondary.main' }}
                variant="text"
              >
                Resend
              </Button>
            </Typography>
          </ListItem>
        </List>
      )}

      <Button
        onClick={onBackClick}
        size="medium"
        startIcon={<Box component={FontAwesomeIcon} icon={faArrowLeft} />}
        sx={{
          color: 'secondary.main',
          textDecoration: 'none',
        }}
        variant="text"
      >
        Back
      </Button>
    </Stack>
    <Divider sx={{ width: '100%' }} />

    <Box display="flex" p={2} sx={{ justifyContent: 'center' }}>
      <FontAwesomeIcon icon={faCircleInfo} />

      <Typography paddingLeft={1} variant="body2">
        Please check spam for OTP if you cannot find it in your mail inbox.
      </Typography>
    </Box>
  </>
);
