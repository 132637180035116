import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  faClose,
  faDownload,
  faEdit,
  faEye,
  faSave,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { EllipseMenu, EllipseMenuItem, LoadingIndicator } from 'common';
import LoadingDots from 'common/LoadingDots';
import config from 'config';
import commonConstants from 'constants/common';
import toastMessageConstant from 'constants/toastMessage';
import { DocumentType } from 'enums/documents';
import {
  IAdaptedClientGroup,
  IAdaptedClientIndividual,
} from 'interfaces/client-management';
import { IDocument } from 'interfaces/documents';
import { useSnackbar } from 'notistack';
import {
  useDeleteDocumentMutation,
  useRenameDocumentMutation,
} from 'services/documents';
import { useFileDownloadMutation } from 'services/files';
import { selectAuthTenantData } from 'stores/auth';
import { selectEditClientData } from 'stores/client-management';
import { useConfirmationModal } from 'stores/ConfirmationModal';
import { useAppSelector } from 'stores/hooks';
import { sliceText } from 'utils/common';
import {
  downloadFromURI,
  getFileNameWithExtension,
  getFilenameWithoutExtension,
  isBrowserSupportedFile,
} from 'utils/file';
import { UTCToLocalDateViewFormat } from 'utils/moment';

interface IProps {
  data: IDocument;
}

const FileTableRow = ({ data }: IProps): JSX.Element => {
  const [isRename, setIsRename] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const tenantData = useAppSelector(selectAuthTenantData)?.userId || '';

  const editClientData = useAppSelector(
    selectEditClientData
  ) as IAdaptedClientGroup & IAdaptedClientIndividual;

  const isDocumentTypeURI = data.type === DocumentType.URI;
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const clientIdFromParams = searchParams.get('id');

  const fileDownloadMutation = useFileDownloadMutation();

  const deleteConfirmationModal = useConfirmationModal();
  const renameDocumentMutation = useRenameDocumentMutation();

  const deleteDocumentMutation = useDeleteDocumentMutation();

  const isDocumentIsUploadedBySelf = tenantData === data.createdBy?.id;

  const handleURIError = () => {
    enqueueSnackbar(toastMessageConstant.DOCUMENT.DOWNLOAD_UNSUCCESS, {
      variant: 'error',
    });
  };

  const onDownloadClick = async (view: string) => {
    if (isDocumentTypeURI) {
      if (view === 'view') {
        window.open(data.path, '_blank', 'noreferrer');
      } else {
        await downloadFromURI({
          name: data.name,
          path: data.path,
          handleError: handleURIError,
        });
      }
    } else {
      fileDownloadMutation.mutate({
        filepath: data.path,
        name: data.name,
        download: { view },
      });
    }
  };
  const handleDelete = async () => {
    // await because result depends on confirmation model action
    const result = await deleteConfirmationModal?.openConfirmationModal({
      isAdditionalContentRequired: false,
      title: `Delete File`,
      content: (
        <Typography component="span">
          Are you sure you want to delete this
          <Typography
            component="span"
            sx={{ fontWeight: (t) => t.typography.fontWeightMedium }}
          >
            {' '}
            {data.name}{' '}
          </Typography>
          file?
        </Typography>
      ),
    });
    if (result) {
      deleteConfirmationModal?.changeSubmittingStatus(true);
      deleteDocumentMutation.mutate(
        {
          associationId:
            editClientData?.clientId || (clientIdFromParams! as string),
          documentId: data.documentId,
          type: commonConstants.CLIENT,
        },
        {
          onSettled: () => {
            deleteConfirmationModal?.changeSubmittingStatus(false);
          },
        }
      );
    }
  };

  const handleRename = () => {
    if (!name.trim()) return;
    renameDocumentMutation.mutate(
      {
        documentId: data.documentId,
        associationId:
          editClientData?.clientId || (clientIdFromParams! as string),
        isNotListView: true,
        data: {
          name: isDocumentTypeURI
            ? name.trim()
            : getFileNameWithExtension(data.name, name.trim()),
        },
      },
      {
        onSettled: () => {
          setIsRename(false);
        },
      }
    );
  };
  const isSaveDisabled = () => {
    if (isDocumentTypeURI) {
      return data.name !== name && name.length;
    }
    return getFilenameWithoutExtension(data.name) !== name && name.length;
  };
  const extensionTrimmedName = () => {
    setName(
      isDocumentTypeURI ? data.name : getFilenameWithoutExtension(data.name)
    );
  };

  const onRenameClick = () => {
    extensionTrimmedName();
    setIsRename(true);
  };
  const handleClose = () => {
    setName(data.name);
    setIsRename(false);
  };
  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        pointerEvents: fileDownloadMutation.isLoading ? 'none' : 'auto',
        opacity: fileDownloadMutation.isLoading ? 0.5 : 1,
      }}
    >
      <TableCell>
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          justifyContent="flex-start"
          sx={{
            '&:hover': {
              '.text-file-glow': {
                color: (t) => t.palette.secondary.main,
                cursor: 'pointer',
              },
            },
          }}
          width="90%"
        >
          {isRename ? (
            <Box alignItems="center" display="flex">
              <TextField
                fullWidth
                name="name"
                onChange={(e) => {
                  setName(e.target.value.trim());
                }}
                size="small"
                value={name}
                variant="standard"
              />
              <Box alignItems="center" display="flex">
                {isSaveDisabled() ? (
                  <IconButton onClick={handleRename}>
                    <FontAwesomeIcon icon={faSave} size="xs" />
                  </IconButton>
                ) : (
                  ''
                )}
                <IconButton onClick={handleClose}>
                  <FontAwesomeIcon icon={faClose} size="xs" />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Tooltip title={data.name}>
              <Typography
                className="text-file-glow"
                gutterBottom={false}
                onClick={() =>
                  onDownloadClick(
                    isBrowserSupportedFile(data.path, data.type)
                      ? 'view'
                      : 'download'
                  )
                }
                sx={{ fontWeight: (t) => t.typography.fontWeightMedium }}
                variant="body2"
              >
                <Box display="flex">
                  {sliceText(data.name, 30)}
                  {fileDownloadMutation.isLoading && (
                    <LoadingDots padding={0} pl={3} />
                  )}
                </Box>
              </Typography>
            </Tooltip>
          )}
        </Box>
      </TableCell>
      <TableCell>{data.createdBy?.name}</TableCell>
      <TableCell>
        {UTCToLocalDateViewFormat(
          data.createdAt as string,
          config.fullDateViewFormat
        )}
      </TableCell>

      <TableCell align="right">
        <EllipseMenu>
          {isDocumentTypeURI ? (
            <EllipseMenuItem
              iconElement={<FontAwesomeIcon icon={faEye} size="xs" />}
              onClick={() => onDownloadClick('view')}
              text="View"
            />
          ) : (
            <EllipseMenuItem
              iconElement={
                !fileDownloadMutation.isLoading ? (
                  <FontAwesomeIcon icon={faDownload} size="xs" />
                ) : (
                  <LoadingIndicator containerHeight="15px" size="1rem" />
                )
              }
              onClick={() => onDownloadClick('download')}
              text="Download"
            />
          )}

          <EllipseMenuItem
            iconElement={<FontAwesomeIcon icon={faEdit} size="xs" />}
            onClick={onRenameClick}
            text="Rename"
          />

          {isDocumentIsUploadedBySelf && (
            <EllipseMenuItem
              iconElement={<FontAwesomeIcon icon={faTrashAlt} size="xs" />}
              onClick={handleDelete}
              text="Delete"
            />
          )}
        </EllipseMenu>
      </TableCell>
    </TableRow>
  );
};

export default FileTableRow;
