import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import uiRoutes from 'constants/uiRoutes';
import { getLocal, removeLocal } from 'utils/storage';

export const RedirectToIndexIfLoggedIn = () => {
  const navigate = useNavigate();

  const isAuthenticated = !!getLocal('jwtToken');

  useEffect(() => {
    try {
      if (isAuthenticated) {
        navigate(`${uiRoutes.index}`);
      }
    } catch (e) {
      removeLocal('jwtToken');
    }
  }, [isAuthenticated, navigate]);

  return <Outlet />;
};
