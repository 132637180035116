import apiRoutes from 'constants/apiRoutes';
import { IResponse } from 'interfaces/http';
import { IEmailToken } from 'interfaces/SharedResourceAuthenticator';
import { baseRequest } from 'utils/axios';

const { resources } = apiRoutes;

export const checkResourceAccess = async (
  data: IEmailToken
): Promise<IResponse<{
  hasAccess: boolean
}>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: resources.checkResourceAccess,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
