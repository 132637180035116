import apiRoutes from 'constants/apiRoutes';
import { IResponse } from 'interfaces/http';
import { ITinyUrl, ITinyUrlResponse } from 'interfaces/misc';
import { baseRequest } from 'utils/axios';

export const validateTinyUrl = async (
  hash: string
): Promise<IResponse<ITinyUrl>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: apiRoutes.tinyUrl.validateUrl,
    data: {
      hash,
    },
  });

  if (error) {
    throw new Error(error.message);
  }

  return response.data;

  /**
   * Note: Uncomment below if you need to redirect to localhost after getting actual url from tiny url
   */
  // return {
  //   ...response.data,
  //   data: {
  //     ...response.data.data,
  //     originalUrl: response.data.data.originalUrl.replace(
  //       'https://app.vitafyhealth.com/',
  //       'http://localhost:4200/'
  //     ),
  //   },
  // };
};

export const generateTinyUrl = async (
  originalUrl: string
): Promise<IResponse<ITinyUrlResponse>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: apiRoutes.tinyUrl.generateUrl,
    data: {
      originalUrl,
    },
  });

  if (error) {
    throw new Error(error.message);
  }
  return response.data;
};
