import { useMemo } from 'react';

import { ReferenceType } from 'enums/common';
import { useUserRolesQuery } from 'services/tenant-management/users';
import { selectAuthTenantData } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { getLocal } from 'utils/storage';

export const useGetParamsForTenantSwitch = () => {
  const authUser = useAppSelector(selectAuthTenantData);
  const authUserId = authUser?.userId;
  const jwtToken = getLocal('jwtToken');

  const userRolesQuery = useUserRolesQuery(authUserId!, {
    enabled: !!authUserId && !!jwtToken,
  });
  const userRoles = useMemo(
    () => userRolesQuery?.data?.roles || [],
    [userRolesQuery?.data?.roles]
  );

  const getParamsForTenantSwitch = ({
    newTenantId,
  }: {
    newTenantId: string;
  }) => {
    const roles = userRoles?.filter((a) => a.tenantId === newTenantId);
    const adminRole = roles?.find(
      (i) =>
        i.tenantId === newTenantId && i.referenceType === ReferenceType.TENANT
    );
    const reference = adminRole ?? roles?.[0];
    return {
      referenceType: reference?.referenceType,
      referenceId: reference?.referenceId,
      referenceSubType: reference?.referenceSubType,
    };
  };

  return {
    getParamsForTenantSwitch,
  };
};
