import { useState } from 'react';

import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';

import QRCodeDisplayCard from './QRCodeDisplayCard';
import { QRCodeDisplayModel } from './QRCodeDisplayModel';

interface IProps {
  clientId: string;
  clientName: string;
  label?: string;
  removeDownloadOption?: boolean;
}

export const ReferralQRCodeCard = ({
  clientId,
  clientName,
  label,
  removeDownloadOption,
}: IProps) => {
  const [openDownloadModel, setOpenDownloadModel] = useState(false);
  const handleDownloadImage = () => {
    setOpenDownloadModel(true);
  };
  const resetModelOpen = () => {
    setOpenDownloadModel(false);
  };
  return (
    <Box>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mb={2}
      >
        <Box
          sx={removeDownloadOption ? { marginTop: 1, paddingBottom: 1 } : {}}
        >
          <Typography fontWeight="medium" gutterBottom={false} variant="body1">
            {label}
          </Typography>
        </Box>
        {!removeDownloadOption && (
          <Box>
            <Tooltip title="Preview to download">
              <IconButton onClick={handleDownloadImage}>
                <Box
                  color="primary.main"
                  component={FontAwesomeIcon}
                  icon={faEye}
                />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Box>
        <QRCodeDisplayCard clientId={clientId} />
      </Box>
      {openDownloadModel && (
        <QRCodeDisplayModel
          clientId={clientId}
          clientName={clientName}
          openModel={openDownloadModel}
          resetModelOpen={resetModelOpen}
        />
      )}
    </Box>
  );
};

ReferralQRCodeCard.defaultProps = {
  label: 'My referral code',
  removeDownloadOption: false,
};
