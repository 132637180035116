// For both client groups and individuals

import apiRoutes from 'constants/apiRoutes';
import { IAddTransactionSchema } from 'interfaces/billings/transactions';
import {
  IAddClientSchema,
  ICheckClientExistsSchema,
  IClient,
  IClientDetailFilter,
  IClientExistsResponse,
  IClientFilters,
  IClientGroup,
  IClientIndividual,
  IClientTableRow,
  IGroupAdvancedSearchForm,
} from 'interfaces/client-management';
import {
  IClientDashboardSummary,
  IClientEnrollment,
  IClientEnrollmentCompleteSchema,
  IClientEnrollmentDetail,
  IClientEnrollmentDetailFilter,
  IClientEnrollmentMembership,
  IClientEnrollmentMembershipQuery,
  IClientEnrollmentResponse,
  IClientEnrollmentSchema,
  IEnrollmentAdvanceFilterWithInterval,
  IEnrollmentApproveResponse,
  IEnrollmentCancelResponse,
  IEnrollmentCompleteResponse,
  IEnrollmentDeclineResponse,
  IOfferingsForm,
} from 'interfaces/client-management/enrollment';
import {
  IReceiveRevisedBilling,
  IReceiveRevisedBillingPayload,
} from 'interfaces/client-management/manage-membership';
import { IListResponse, IResponse } from 'interfaces/http';
import { baseRequest } from 'utils/axios';

import { IClientEnrollmentCancelSchema } from '../../interfaces/client-management/enrollment';

const { clientManagement } = apiRoutes;

export const addClient = async (
  data: IAddClientSchema
): Promise<IResponse<IClientGroup | IClientIndividual>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: clientManagement.client.add,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const editClient = async (
  clientId: string,
  data: IAddClientSchema
): Promise<IResponse<IClientGroup | IClientIndividual>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: clientManagement.client.edit.replace(':id', clientId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const approveClientEnrollment = async (
  clientId: string,
  enrollmentId: string,
  body: IOfferingsForm
): Promise<IResponse<IEnrollmentApproveResponse>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: clientManagement.client.approveEnrollment
      .replace(':id', clientId)
      .replace(':enrollmentId', enrollmentId),
    data: body,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const cancelClientEnrollment = async (
  clientId: string,
  enrollmentId: string,
  data: IClientEnrollmentCancelSchema
): Promise<IResponse<IEnrollmentCancelResponse>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: clientManagement.client.cancelEnrollment
      .replace(':id', clientId)
      .replace(':enrollmentId', enrollmentId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const declineClientEnrollment = async (
  clientId: string,
  enrollmentId: string,
  data: IClientEnrollmentCancelSchema
): Promise<IResponse<IEnrollmentDeclineResponse>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: clientManagement.client.declineEnrollment
      .replace(':id', clientId)
      .replace(':enrollmentId', enrollmentId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const completeClientEnrollment = async (
  clientId: string,
  enrollmentId: string,
  data: IClientEnrollmentCompleteSchema
): Promise<
  IResponse<IEnrollmentCompleteResponse | IClientEnrollmentMembership>
> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: clientManagement.client.completeEnrollment
      .replace(':id', clientId)
      .replace(':enrollmentId', enrollmentId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getClients = async (
  params: IClientFilters & IGroupAdvancedSearchForm
): Promise<IResponse<IListResponse<IClientTableRow>>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: clientManagement.client.getAll,
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const checkClientsExists = async (
  data: ICheckClientExistsSchema
): Promise<IResponse<IClientExistsResponse>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: clientManagement.client.clientExits,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getClientDashboardSummary = async (
  clientId: string,
  params: IClientFilters
): Promise<IResponse<IClientDashboardSummary>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: clientManagement.client.summary.replace(':id', clientId!),
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getClientDetail = async (
  clientId: string,
  queryParams?: IClientDetailFilter
): Promise<IResponse<IClient>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: clientManagement.client.getOne.replace(':id', clientId),
    params: queryParams,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getClientEnrollment = async (
  clientId: string,
  clientEnrollmentId: string
): Promise<IResponse<IClientEnrollment>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: clientManagement.client.getEnrollment.replace(':id', clientId),
    params: { clientEnrollmentId },
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
export const getAllClientEnrollment = async (
  clientId: string,
  params: IEnrollmentAdvanceFilterWithInterval
): Promise<IResponse<IListResponse<IClientEnrollment>>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: clientManagement.client.getAllMemberships.replace(':id', clientId),
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getClientEnrollmentMembership = async (
  clientId: string,
  params?: IClientEnrollmentMembershipQuery
): Promise<IResponse<IListResponse<IClientEnrollmentMembership>>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: clientManagement.client.getAllMemberships.replace(':id', clientId),
    ...(params && { params }),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getClientEnrollmentDetail = async (
  clientId: string,
  enrollmentId: string,
  queryParams: IClientEnrollmentDetailFilter
): Promise<IResponse<IClientEnrollmentDetail>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: clientManagement.client.getEnrollmentDetail
      .replace(':id', clientId)
      .replace(':enrollmentId', enrollmentId),
    params: queryParams,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const updateClientEnrollment = async (
  clientId: string,
  enrollmentId: string,
  data: IClientEnrollmentSchema
): Promise<IResponse<IClientEnrollmentResponse>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: clientManagement.client.updateEnrollment
      .replace(':id', clientId)
      .replace(':enrollmentId', enrollmentId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const restartClientEnrollment = async (
  clientId: string,
  enrollmentId: string,
  data: IClientEnrollmentSchema
): Promise<
  IResponse<IClientEnrollmentResponse | IClientEnrollmentMembership>
> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: clientManagement.client.restartEnrollment
      .replace(':id', clientId)
      .replace(':enrollmentId', enrollmentId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const receiveRevisedBillingDetails = async (
  clientId: string,
  enrollmentId: string,
  data: IReceiveRevisedBillingPayload
): Promise<IResponse<IReceiveRevisedBilling>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: clientManagement.client.revisedBilling
      .replace(':id', clientId)
      .replace(':enrollmentId', enrollmentId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
export const addClientEnrollment = async (
  clientId: string,
  enrollmentId: string,
  data: IClientEnrollmentSchema
): Promise<
  IResponse<IClientEnrollmentResponse | IClientEnrollmentMembership>
> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: clientManagement.client.addClientEnrollment
      .replace(':id', clientId)
      .replace(':enrollmentId', enrollmentId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const changeClientEnrollment = async (
  clientId: string,
  enrollmentId: string,
  data: IClientEnrollmentSchema
): Promise<
  IResponse<IClientEnrollmentResponse | IClientEnrollmentMembership>
> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: clientManagement.client.changeEnrollment
      .replace(':id', clientId)
      .replace(':enrollmentId', enrollmentId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const addTransaction = async (
  clientId: string,
  payload: IAddTransactionSchema
): Promise<IResponse<any>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: clientManagement.client.addClientAncillaryEnrollments.replace(
      ':clientId',
      clientId
    ),
    data: payload,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
