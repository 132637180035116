import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { IDocument } from 'interfaces/documents';

import FileTableRow from './FileTableRow';

interface IProps {
  files: IDocument[];
}

const File = ({ files }: IProps) => (
  <Box>
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width={230}>Name</TableCell>
            <TableCell align="left">Uploaded by</TableCell>
            <TableCell align="left">Date</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((row) => (
            <FileTableRow data={row} key={row.documentId} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
);

export default File;
