import { SiteDomain } from 'enums/common';
import { selectActiveTenant } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

export const useGetWebsiteLink = (link?: string) => {
  const env = process.env.REACT_APP_ENVIRONMENT;
  const tenantDetail = useAppSelector(selectActiveTenant);
  const slug = tenantDetail?.slug ?? '';

  const prefixSlug = env !== 'PROD' ? `${env?.toLowerCase()}-` : ''; // append env to domain only if env is not prod

  return {
    websiteLink: `https://${prefixSlug}${slug}.${SiteDomain.domain}${
      link || ''
    }`,
  };
};
