import {
  Box,
  FormGroup,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { TenantSettingsType } from 'enums/tenant-management/tenant';
import { ITenantSettingItem } from 'interfaces/tenant-management/tenant';

interface IProps {
  data: ITenantSettingItem;
  onChange: (
    event: SelectChangeEvent<string | number | string[]>,
    data: ITenantSettingItem
  ) => void;
}
export const CaseConfigurationRow = ({ data, onChange }: IProps) => (
  <Box>
    <Box alignItems="center" display="flex" justifyContent="space-between">
      <Typography>{data.name}</Typography>
      <Box>
        <FormGroup>
          {data.type === TenantSettingsType.SELECT ? (
            <Select
              onChange={(event) => onChange(event, data)}
              sx={{ width: 130, marginLeft: 'auto' }}
              value={data.value}
            >
              {data.options?.map((opt: string) => (
                <MenuItem key={opt} sx={{ width: 115 }} value={opt}>
                  {opt}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Box />
          )}
        </FormGroup>
      </Box>
    </Box>
  </Box>
);
