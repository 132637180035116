import { Avatar, Box } from '@mui/material';
import Popover from 'common/ui/Popover';
import { useCheckUserIsV360Admin } from 'hooks/useCheckUserIsV360Admin';

import releaseicon from '../../../assets/images/png/release-note.png';
import { ReleaseNoteContent } from './ReleaseNoteContent';

export const ReleaseNotePopUp = () => {
  const isUserV360Admin = useCheckUserIsV360Admin();

  if (!isUserV360Admin) return null;

  const triggerElement = (
    <Avatar src={releaseicon} sx={{ height: 40, width: 40 }} />
  );

  return (
    <Box>
      <Popover
        closeOnMouseLeave={false}
        sx={{ width: 300 }}
        triggerElement={triggerElement}
      >
        <ReleaseNoteContent />
      </Popover>
    </Box>
  );
};
