import { ReactNode } from 'react';

import { Box, useMediaQuery, useTheme } from '@mui/material';

const checkWithWithBreakPoint = ({
  largeBreakpoint,
  mdBreakpoint,
  smBreakpoint,
}: {
  largeBreakpoint: boolean;
  mdBreakpoint: boolean;
  smBreakpoint: boolean;
}) => {
  if (largeBreakpoint || mdBreakpoint) return '320px';

  if (smBreakpoint) return '220px';
  return '100%';
};

const ReferralCaseListLayout = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  const largeBreakpoint = useMediaQuery(theme.breakpoints.up('xl'));
  const mdBreakpoint = useMediaQuery(theme.breakpoints.up('md'));
  const smBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
  const listFixedWith = checkWithWithBreakPoint({
    largeBreakpoint,
    mdBreakpoint,
    smBreakpoint,
  });

  return (
    <Box flexShrink={0} position="relative" width={listFixedWith} zIndex={2}>
      <Box
        sx={{
          background: (t) => t.palette.common.white,
          position: smBreakpoint ? 'fixed' : 'inherit',
          top: '65px',
          width: listFixedWith,
          maxHeight: '100vh',
          borderRight: (t) => `2px solid ${t.palette.gray.lighter}`,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ReferralCaseListLayout;
